import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import TopHeader from "../Home/TopHeader";

import { IconArrowBack } from "@tabler/icons-react";

const MailCampaignSideBar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const navigateTo = (path) => {
    closeSidebar();
    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isActiveRoute = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      {/* <TopHeader /> */}
      <button
        onClick={toggleSidebar}
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="default-sidebar"
        className={` top-0 left-0 z-40 w-64 h-screen transition-transform  ${
          isSidebarOpen ? "fixed -translate-x-0" : "-translate-x-full"
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="donationSidebar   py-4 overflow-y-auto ">
          <div className="flex items-center">
            <Link to="/services-dashboard">
              <button className="btn-style-1 text-white hover:bg-blue-600  rounded-full">
                <svg
                  enableBackground="new 0 0 256 256"
                  height="40px"
                  viewBox="0 0 256 256"
                  width="40px"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <path
                    fill="white"
                    d="M203.313,128c0,3.534-2.865,6-6.4,6H74.878l47.141,45.956c2.538,2.463,2.597,6.616,0.138,9.149  c-1.256,1.295-2.925,1.995-4.594,1.995c-1.606,0-3.216-0.576-4.456-1.782l-58.466-56.737c-1.244-1.206-1.943-2.855-1.943-4.587  s0.699-3.385,1.943-4.591l58.466-56.743c2.528-2.459,6.584-2.401,9.05,0.139c2.459,2.534,2.4,6.789-0.138,9.251L74.878,122h122.034  C200.447,122,203.313,124.466,203.313,128z M256,128C256,57.422,198.578,0,128,0S0,57.422,0,128s57.422,128,128,128  S256,198.578,256,128z M243.2,128c0,63.521-51.679,115.2-115.2,115.2S12.8,191.521,12.8,128S64.479,12.8,128,12.8  S243.2,64.479,243.2,128z"
                  />
                </svg>
              </button>
            </Link>
            {/* <Link to="/services-dashboard">
              <img
                src="/assets/images/main-logo.svg"
                alt="Logo"
                className="h-10 px-6 my-5 sm:mx-0 cursor-pointer"
                onClick={closeSidebar}
              />
            </Link> */}
          </div>
          {/* </Link> */}
          <ul className="space-y-4 font-medium ">
            <li id="mailingList">
              <a
                href="#"
                onClick={() => navigateTo("/mailingList")}
                className={`flex items-center p-2 px-6 text-gray-900  dark:text-white hover:bg-blue-700 group ${
                  (isActiveRoute("/mailingList") ||
                    isActiveRoute("/emailList")) &&
                  "bg-blue-700"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M12 12.713L1.447 6.105A1 1 0 0 1 2 5h20a1 1 0 0 1 .553 1.105L12 12.713zm11-2.007V19a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V10.706l10 5.84 10-5.84z" />
                </svg>
                <span className="ms-3">Mailing List</span>
              </a>
            </li>
            <li id="campaignList">
              <a
                href="#"
                onClick={() => navigateTo("/campaignList")}
                className={`flex items-center p-2 text-gray-900 px-6 dark:text-white hover:bg-blue-700 group ${
                  isActiveRoute("/campaignList") && "bg-blue-700"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-list"
                >
                  <line x1="8" y1="6" x2="21" y2="6"></line>
                  <line x1="8" y1="12" x2="21" y2="12"></line>
                  <line x1="8" y1="18" x2="21" y2="18"></line>
                  <line x1="3" y1="6" x2="3" y2="6"></line>
                  <line x1="3" y1="12" x2="3" y2="12"></line>
                  <line x1="3" y1="18" x2="3" y2="18"></line>
                </svg>
                <span className="ms-3">My Campaigns</span>
              </a>
            </li>

            <li id="createCampaign">
              <a
                href="#"
                onClick={() => navigateTo("/myCampaign")}
                className={`flex items-center p-2 text-gray-900 px-6 dark:text-white hover:bg-blue-700 group ${
                  isActiveRoute("/myCampaign") && "bg-blue-700"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M12 2a10 10 0 1 1-10 10A10 10 0 0 1 12 2zm0 18a8 8 0 1 0-8-8 8 8 0 0 0 8 8zm1-13h-2v6h2zm-1 8a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 12 15z" />
                </svg>
                <span className="ms-3">Create Campaigns</span>
              </a>
            </li>

            <li id="templateList">
              <a
                href="#"
                onClick={() => navigateTo("/templateList")}
                className={`flex items-center p-2 text-gray-900 px-6 dark:text-white hover:bg-blue-700 group ${
                  isActiveRoute("/templateList") && "bg-blue-700"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 14H4V6h16zm-4-7h-4v-2h4zm-6 0H6v-2h4zm0 4H6v-2h4zm6 0h-4v-2h4z" />
                </svg>
                <span className="ms-3">Email templates</span>
              </a>
            </li>

            {/* <li>
              <a
                href="#"
                onClick={() => navigateTo("/")}
                className={`flex items-center p-2 text-gray-900 px-6 dark:text-white hover:bg-blue-700 group ${
                  isActiveRoute("/") && "bg-blue-700"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M12 12.713L1.447 6.105A1 1 0 0 1 2 5h20a1 1 0 0 1 .553 1.105L12 12.713zm-10 4.98l5.486-5.486a.5.5 0 0 1 .707 0l4.243 4.243a.5.5 0 0 0 .707 0l7.536-7.535 1.414 1.414-8.95 8.95a.5.5 0 0 1-.707 0l-4.243-4.243a.5.5 0 0 0-.707 0L2 18.693z" />
                </svg>
                <span className="ms-3">Email Verification</span>
              </a>
            </li> */}

            {/* <li>
              <a
                href="#"
                onClick={() => navigateTo("/")}
                className={`flex items-center p-2 text-gray-900 px-6 dark:text-white hover:bg-blue-700 group ${
                  isActiveRoute("/") && "bg-blue-700"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M10.5 2a1 1 0 0 1 1 1v.613a8.973 8.973 0 0 1 2 0V3a1 1 0 1 1 2 0v.613a8.973 8.973 0 0 1 1.89.776l.433-.5a1 1 0 0 1 1.497 1.32l-.433.5a8.992 8.992 0 0 1 1.11 1.89l.5-.433a1 1 0 1 1 1.32 1.497l-.5.433a8.973 8.973 0 0 1 0 2l.5.433a1 1 0 1 1-1.32 1.497l-.5-.433a8.992 8.992 0 0 1-1.11 1.89l.433.5a1 1 0 1 1-1.497 1.32l-.433-.5a8.973 8.973 0 0 1-1.89.776V21a1 1 0 1 1-2 0v-.613a8.973 8.973 0 0 1-2 0V21a1 1 0 1 1-2 0v-.613a8.973 8.973 0 0 1-1.89-.776l-.433.5a1 1 0 0 1-1.497-1.32l.433-.5a8.992 8.992 0 0 1-1.11-1.89l-.5.433a1 1 0 1 1-1.32-1.497l.5-.433a8.973 8.973 0 0 1 0-2l-.5-.433a1 1 0 1 1 1.32-1.497l.5.433a8.992 8.992 0 0 1 1.11-1.89l-.433-.5a1 1 0 1 1 1.497-1.32l.433.5A8.973 8.973 0 0 1 9 3.613V3a1 1 0 0 1 1-1zm4.878 11.122a3.5 3.5 0 1 0-4.95 0 3.5 3.5 0 0 0 4.95 0zm-4.24-2.49a2.5 2.5 0 1 1 3.535 3.535l-.708-.707a1.5 1.5 0 0 0-2.121-2.122l-.707-.706zm1.768 4.244a2.5 2.5 0 1 1-3.535-3.536l.707.708a1.5 1.5 0 0 0 2.121 2.121l.707.707zm7.72-10.34L19 4.561V5a7 7 0 1 1-6.879-6 7.005 7.005 0 0 1 6.999 6.88V5.561l.878-.878a.5.5 0 1 1 .707.707z" />
                </svg>
                <span className="ms-3">How we work</span>
              </a>
            </li> */}

            {/* <li>
              <a
                href="#"
                onClick={() => navigateTo("/services-dashboard")}
                className={`flex items-center p-2 px-6 text-gray-900  dark:text-white  group ${
                  isActiveRoute("/service-dashboard") && "bg-blue-700"
                }`}
              >
                <img
                  src="/assets/images/back.png"
                  alt="Logo"
                  className="h-20 w-50 mx-auto"
                />
              </a>
            </li> */}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default MailCampaignSideBar;
