import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tour from "reactour"; // Assuming you're using reactour or similar library
import axios from "axios";
import { TQ_URL } from "../../utility/baseUrl";

import CreateMailingList from "./CreateMailingList";
import AddEmailsMailList from "./AddEmailsMailList";

const stepStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
};

const EmailCampaignTour = ({ mailListCount = null }) => {
  console.log(mailListCount);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [modalopen, setModalOpen] = useState(false);
  const [modalEmailOpen, setModalEmailOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    Number(localStorage.getItem("tourStepEmail")) || 0
  );
  //   const [currentStep, setCurrentStep] = useState(0);

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const mailingListCount = localStorage.getItem("mailingListCount");
  const partnerId = partnerData.partnerId;
  const moduleId = 4;
  var flagPrev = false;

  const navigate = useNavigate();
  //   const steps = [
  //     {
  //       //0
  //       selector: "#mailingList",
  //       content: "Click here to get the mailing name list",
  //       style: { background: "#dff9fb" },
  //     },
  //     //1
  //     {
  //       selector: "#displayMailingList",
  //       content: "On this screen you will get the mailing list ",
  //       style: { background: "#dff9fb" },
  //     },

  //     //2
  //     {
  //       selector: "#createMailingListButton",
  //       content: "Click here to create the mailing list name",
  //       style: { background: "#dff9fb" },
  //     },

  //     //3
  //     {
  //       selector: "#showModalAddMailingList",
  //       content: "By filling this filed you can create the mailing list name.",
  //       style: { background: "#dff9fb" },
  //     },

  //     //4

  //     {
  //       selector: "#addEmailsModal",
  //       content: "Click here to add emails to mailing list",
  //       style: { background: "#dff9fb" },
  //     },
  //     //5
  //     {
  //       selector: "#addEmailsShowModal",
  //       content:
  //         "Here you can add emails by two ways first by uploading the file and adding manually.",
  //       style: { background: "#dff9fb" },
  //     },

  //     //6
  //     {
  //       selector: "#campaignList",
  //       content: "Click here to add to get campaign list.",
  //       style: { background: "#dff9fb" },
  //     },

  //     //7
  //     {
  //       selector: "#createCampaignButton",
  //       content: "By clicking this you can create the new campaing",
  //       style: { background: "#dff9fb" },
  //     },
  //     //8
  //     {
  //       selector: "#createCampaign", // New step targeting the donation option
  //       content: "Click here to create new campaign",
  //       style: { background: "#dff9fb" },
  //     },

  //     //9
  //     {
  //       selector: "#infoAboutCampaing", // New step targeting the donation option
  //       content:
  //         "This is first step to create campaign where u need to add basic infomation to create campaign.",
  //       style: { background: "#dff9fb" },
  //     },

  //     //10
  //     {
  //       selector: "#templateList",
  //       content:
  //         "Here you will get the list of all templates created by the user",
  //       style: { background: "#dff9fb" },
  //     },
  //     //11
  //     {
  //       selector: "#emailTemplateList",
  //       content:
  //         "Here you will get the list of all templates created by the user",
  //       style: { background: "#dff9fb" },
  //     },
  //     //12
  //     {
  //       selector: "#addNewTemplate",
  //       content:
  //         "This is template editor where user can design the template according to the requirement",
  //       style: { background: "#dff9fb" },
  //     },

  //     // //13
  //     // {
  //     //   selector: "#addNewTemplateEditor",
  //     //   content:
  //     //     "This is template editor where user can design the template according to the requirement",
  //     //   style: { background: "#dff9fb" },
  //     // },
  //   ];

  const steps = [
    //0
    {
      selector: "#mailingList",
      content: (
        <div className="step-content">
          <span className="emoji-large">📋</span>
          <div>Click here to view the mailing name list.</div>
          <div>Manage your mailing lists easily!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //1
    {
      selector: "#displayMailingList",
      content: (
        <div className="step-content">
          <span className="emoji-large">📜</span>
          <div>
            Here, you can view the mailing list with all available details.
          </div>
          <div>Stay organized!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //2
    {
      selector: "#createMailingListButton",
      content: (
        <div className="step-content">
          <span className="emoji-large">➕</span>
          <div>Click this button to create a new mailing list name.</div>
          <div>Expand your lists!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //3
    {
      selector: "#showModalAddMailingList",
      content: (
        <div className="step-content">
          <span className="emoji-large">✍️</span>
          <div>Fill in the fields to create a new mailing list name.</div>
          <div>Start building your audience!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //4
    {
      selector: "#addEmailsModal",
      content: (
        <div className="step-content">
          <span className="emoji-large">📧</span>
          <div>Click here to add emails to your mailing list.</div>
          <div>Keep your contacts up-to-date!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //5
    {
      selector: "#addEmailsShowModal",
      content: (
        <div className="step-content">
          <span className="emoji-large">🗂️</span>
          <div>
            Add emails either by uploading a file or adding them manually.
          </div>
          <div>Flexible options for your convenience!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //6
    {
      selector: "#campaignList",
      content: (
        <div className="step-content">
          <span className="emoji-large">📊</span>
          <div>Click here to view the list of campaigns.</div>
          <div>Manage your campaigns with ease!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //7
    {
      selector: "#createCampaignButton",
      content: (
        <div className="step-content">
          <span className="emoji-large">🚀</span>
          <div>Click here to create a new campaign.</div>
          <div>Start reaching your audience!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //8
    {
      selector: "#createCampaign",
      content: (
        <div className="step-content">
          <span className="emoji-large">🆕</span>
          <div>Click here to start creating a new campaign.</div>
          <div>Bring your ideas to life!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //9
    {
      selector: "#infoAboutCampaing",
      content: (
        <div className="step-content">
          <span className="emoji-large">ℹ️</span>
          <div>
            This is the first step in creating a campaign, where you'll add
            basic information.
          </div>
          <div>Get started with the essentials!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //10
    {
      selector: "#templateList",
      content: (
        <div className="step-content">
          <span className="emoji-large">📑</span>
          <div>
            Here you'll find a list of all templates created by the user.
          </div>
          <div>Choose the one that fits your campaign!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //11
    {
      selector: "#emailTemplateList",
      content: (
        <div className="step-content">
          <span className="emoji-large">💼</span>
          <div>
            This section shows all the email templates created by the user.
          </div>
          <div>Find and use your templates with ease!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //12
    {
      selector: "#addNewTemplate",
      content: (
        <div className="step-content">
          <span className="emoji-large">🎨</span>
          <div>
            This is the template editor where you can design templates as per
            your requirements.
          </div>
          <div>Create visually stunning templates!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
  ];

  //   const handleNextStep = (stepIndex) => {
  //     if (currentStep !== stepIndex) {
  //       setCurrentStep(stepIndex);
  //       localStorage.setItem("tourStepEmail", stepIndex);

  //       //Navigate to the correct page based on step index
  //       if (stepIndex === 0) {
  //         navigate("/mailingList"); // Navigate to Page 1
  //       } else if (stepIndex === 3) {
  //         setModalOpen(true);
  //       } else if (stepIndex === 4) {
  //         setModalOpen(false);
  //       } else if (stepIndex === 5) {
  //         setModalEmailOpen(true);
  //       } else if (stepIndex === 6) {
  //         setModalEmailOpen(false);
  //         navigate("/campaignList"); // Navigate to Page 2
  //       } else if (stepIndex === 8) {
  //         navigate("/myCampaign"); // Navigate to Page 3
  //       } else if (stepIndex === 10) {
  //         navigate("/templateList"); // Navigate to Page 3
  //       }
  //     }
  //   };

  const handleNextStep = (stepIndex) => {
    if (currentStep !== stepIndex) {
      let nextStep = stepIndex;
      console.log(nextStep);

      // Skip steps 4 and 5 if transitioning from step 3
      if (mailingListCount == 0 && (stepIndex === 4 || stepIndex === 5)) {
        console.log(mailListCount);
        console.log(stepIndex);
        if (!flagPrev) nextStep = 6; // Skip to step 6
        else nextStep = 3;
      }

      setCurrentStep(nextStep);
      localStorage.setItem("tourStepEmail", nextStep);

      //Navigate to the correct page based on step index
      if (nextStep === 0) {
        navigate("/mailingList"); // Navigate to Page 1
      } else if (nextStep === 3) {
        setModalOpen(true);
      } else if (nextStep === 4) {
        setModalOpen(false);
      } else if (nextStep === 5) {
        setModalEmailOpen(true);
      } else if (nextStep === 6) {
        setModalEmailOpen(false);
        navigate("/campaignList"); // Navigate to Page 2
      } else if (nextStep === 8) {
        navigate("/myCampaign"); // Navigate to Page 3
      } else if (nextStep === 10) {
        navigate("/templateList"); // Navigate to Page 3
      }
    }
  };

  //   const handlePrevStep = () => {
  //     console.log("prev step called....");
  //     if (currentStep > 0) {
  //       setCurrentStep((prevStep) => prevStep - 1);
  //       localStorage.setItem("tourStepEmail", currentStep - 1);
  //       if (currentStep === 3) {
  //         navigate("/mailingList"); // Navigate to Page 1
  //       } else if (currentStep === 3) {
  //         setModalOpen(false);
  //       } else if (currentStep === 4) {
  //         setModalOpen(true);
  //       } else if (currentStep === 5) {
  //         setModalEmailOpen(false);
  //       } else if (currentStep == 7) {
  //         navigate("/mailinglist");
  //         setModalEmailOpen(true);
  //       } else if (currentStep === 8) {
  //         navigate("/campaignList"); // Navigate to Page 2
  //       } else if (currentStep === 10) {
  //         navigate("/myCampaign"); // Navigate to Page 3
  //       }
  //     }
  //   };

  const handlePrevStep = () => {
    console.log("prev step called....");

    if (currentStep > 0) {
      let prevStep = currentStep - 1;

      console.log(prevStep);
      //   const mailingListCount = localStorage.getItem("mailingListCount");

      // Skip steps 4 and 5 if coming from step 6 when mailListCount is 0
      if (
        parseInt(mailingListCount) === 0 &&
        (prevStep === 4 || prevStep === 5)
      ) {
        flagPrev = true;
        prevStep = 3; // Directly jump to step 3 if steps 4 and 5 are skipped
        //   handleNextStep(3); // Call handleNextStep with updated step index of 3
        //  return; // Exit to avoid calling further logic
      }

      setCurrentStep(prevStep);
      localStorage.setItem("tourStepEmail", prevStep);

      // Handle navigation and modal control based on the updated prevStep
      if (prevStep === 2) {
        navigate("/mailingList"); // Navigate to Page 1
        setModalOpen(false);
      } else if (prevStep === 3) {
        setModalOpen(true); // Reopen modal at step 4
        if (mailingListCount == 0) navigate("/mailingList");
      } else if (prevStep === 4) {
        setModalEmailOpen(false); // Close email modal at step 5
      } else if (prevStep === 5) {
        navigate("/mailinglist");
        setModalEmailOpen(true); // Open email modal when navigating back to step 6
        // setTimeout(() => {
        //   navigate("/mailinglist");
        // }, 100); // Adjust delay as needed
      } else if (prevStep === 7) {
        navigate("/campaignList"); // Navigate to Page 2 on step 8
      } else if (prevStep === 9) {
        navigate("/myCampaign"); // Navigate to Page 3 on step 10
      }
    }
  };

  //   useEffect(() => {
  //     // Navigate to the correct page on initial render based on current step
  //     handleNextStep(currentStep);
  //   }, [currentStep]);

  useEffect(() => {
    console.log(currentStep);
  }, [currentStep]);

  const handleFinishRedirection = async () => {
    console.log("finish clicked");
    localStorage.removeItem("tourStepEmail");

    try {
      // Call the API to mark the module as finished
      const response = await axios.put(
        `${TQ_URL}/complete/${partnerId}/${moduleId}`
      );

      if (response.status === 200) {
        console.log("Module marked as complete");

        // Redirect to the services dashboard after completion
        navigate("/services-dashboard");
      } else {
        console.error("Failed to complete the module", response.data);
      }
    } catch (error) {
      console.error("Error marking module as complete:", error);
    }
  };
  const handleCloseRequest = async () => {
    console.log("close clicked");
    localStorage.removeItem("tourStepEmail");

    try {
      // Call the API to mark the module as finished
      const response = await axios.put(
        `${TQ_URL}/complete/${partnerId}/${moduleId}`
      );

      if (response.status === 200) {
        console.log("Module marked as complete");
        setIsTourOpen(false);
      } else {
        console.error("Failed to complete the module", response.data);
      }
    } catch (error) {
      console.error("Error marking module as complete:", error);
    }
  };

  //   const revisedSteps =
  //     mailListCount !== null && mailListCount != 0
  //       ? steps
  //       : steps.filter((step, index) => index !== 4 && index !== 5); // Skip step 4 if mailingCount is 0 or null

  const filteredSteps =
    mailingListCount === 0
      ? steps.filter((_, index) => index !== 4 && index !== 5)
      : steps;

  return (
    <>
      <div className="my-page-tour">
        <Tour
          steps={filteredSteps}
          isOpen={isTourOpen}
          startAt={currentStep}
          onRequestClose={handleCloseRequest}
          nextButton={<button className="tour-button">Next ➡️</button>}
          prevButton={
            <button className="tour-button" onClick={handlePrevStep}>
              ⬅️ Back
            </button>
          }
          getCurrentStep={(stepIndex) => handleNextStep(stepIndex)}
          lastStepNextButton={
            <button className="tour-button" onClick={handleFinishRedirection}>
              Finish 🎉
            </button>
          }
          onBeforeClose={() => console.log("Tour closed")}
        />
      </div>

      {modalopen && <CreateMailingList onClose={() => setModalOpen(false)} />}
      {modalEmailOpen && (
        <AddEmailsMailList
          onClose={() => setModalEmailOpen(false)}
          mailingListId={0}
        />
      )}
    </>
  );
};

export default EmailCampaignTour;
