import React, { useMemo, useState, useEffect } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Switch, Box, Button, Tooltip, Text } from "@mantine/core";
import { Modal as NewModal } from "@mantine/core";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import Modal from "react-modal"; // Assuming you are using react-modal for modals
import { Triangle } from "react-loader-spinner";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import {
  getRequestWithTokenWithoutDataPartner,
  getRequestWithTokenWithData,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import axios from "axios";

const initialAdminData = [
  {
    id: 1,
    name: "John Doe",
    email: "john.doe@example.com",
    contact: "+1-234-567-890",
    status: "Active",
    actStatus: true,
  },
  {
    id: 2,
    name: "Jane Smith",
    email: "jane.smith@example.com",
    contact: "+1-987-654-321",
    status: "Inactive",
    actStatus: false,
  },
  {
    id: 3,
    name: "Emily Johnson",
    email: "emily.johnson@example.com",
    contact: "+1-456-789-012",
    status: "Pending",
    actStatus: true,
  },
];

const ManageSubAdmin = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [adminData, setAdminData] = useState(initialAdminData);
  const [isRefetching, setIsRefetching] = useState(false);

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isOpen, setIsOpen] = useState(false); // Modal open state
  const [isLoading, setIsLoading] = useState(false); // Loading state for modal
  const [animationClass, setAnimationClass] = useState("modal-swipe-right"); // Default animation for first page
  const [selectedAdmin, setSelectedAdmin] = useState(null); // To track which admin is being toggled
  const [confirmationOpen, setConfirmationOpen] = useState(false); // Modal visibility
  const [rowCount, setRowCount] = useState(0);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId;
  const [isError, setIsError] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm();

  const [isFormSubmitted, setFormSubmitted] = useState(false);

  // Function to open modal
  const openModal = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (!adminData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getSubAdminList();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting, //refetch when sorting changes
    columnFilters,
  ]);

  const getSubAdminList = async () => {
    const url = new URL(`${TQ_URL}/getAllSubAdminList`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));

    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );

      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log(response.datalist);

      setAdminData(dataWithSerialNumber);
      setRowCount(response.totalCount);
      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the subadmin list:", error);
      setIsError(true);
      setIsLoading(false);
      setIsRefetching(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleTryAgain = () => {
    setEmail("");
    setFormSubmitted(false);
  };

  const closeModal = () => {
    setIsOpen(false);
    setFormSubmitted(false);
  };

  const handleToggleStatus = async (id) => {
    if (!selectedAdmin) return;

    const dataToSend = {
      subAdminId: selectedAdmin.subAdminId,
      actStatus: !selectedAdmin.actStatus,
    };
    console.log(selectedAdmin);
    
    

    try {
      await axios.put(`${TQ_URL}/updateActStatus`, dataToSend); // Adjust the endpoint as necessary
      setAdminData((prevData) =>
        prevData.map((admin) =>
          admin.id === selectedAdmin.id
            ? { ...admin, actStatus: !selectedAdmin.actStatus }
            : admin
        )
      );
      setConfirmationOpen(false);
      setSelectedAdmin(null);
    } catch (error) {
      console.error("Error updating admin status:", error);
      // Optionally, show an error message to the user
    }

    setConfirmationOpen(false); // Close the modal after confirmation
  };

  //   const handleStatusClick = (fundraiser) => {
  //     // setSelectedFundraiser(fundraiser);
  //     console.log(fundraiser);
  //     // setIsActivating(fundraiser.status === "Inactive");
  //     // setIsModalOpen(true);
  //   };

  const openConfirmation = (admin) => {
    setSelectedAdmin(admin);
    setConfirmationOpen(true);
  };
  const columns = useMemo(() => [
    {
      accessorKey: "id", // key for the column data
      header: "ID", // column header name
      enableColumnFilter: false, // disable column filter for this field
      enableSorting: false, // disable sorting for this column
    },
    {
      accessorKey: "subAdminName", // column key for accessing admin name
      header: "Name", // column header
    },
    {
      accessorKey: "subAdminEmail", // column key for accessing email
      header: "Email", // column header
    },
    {
      accessorKey: "contactNumber", // column key for accessing contact
      header: "Contact", // column header
      Cell: ({ row }) => row.original.contactNumber || "---",
      sortable: true,
    },
    {
      accessorKey: "statusName", // column key for status
      header: "Status", // column header
      Cell: ({ cell }) => (
        <Box
          sx={(theme) => ({
            color:
              cell.getValue() === "Active"
                ? theme.colors.green[9] // Green color for Active status
                : cell.getValue() === "Inactive"
                ? "#FF0000" // Red color for Inactive status
                : "#FFA200", // Orange color for other statuses
            fontWeight: "bold", // Make text bold (optional)
            textAlign: "center",
          })}
        >
          {cell.getValue()}
        </Box>
      ),
    },
    // {
    //   accessorKey: "actStatus",
    //   header: "Active Status",
    //   Cell: ({ cell }) => (
    //     <Switch
    //       checked={cell.getValue()}
    //       onChange={() => openConfirmation(cell.row.original)} // Open confirmation modal before toggling
    //       onLabel="ON"
    //       offLabel="OFF"
    //       sx={(theme) => ({
    //         "& .mantine-Switch-thumb": {
    //           backgroundColor: cell.getValue()
    //             ? theme.colors.green[6]
    //             : theme.colors.red[6], // Thumb color based on status
    //         },
    //         "& .mantine-Switch-track": {
    //           backgroundColor: cell.getValue()
    //             ? theme.colors.green[2]
    //             : theme.colors.red[2], // Track color based on status
    //         },
    //       })}
    //     />
    //   ),
    // },

    {
      accessorKey: "actStatus",
      header: "Status",
      enableSorting: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      Cell: ({ row }) => {
        const isActive = row.original.actStatus === true;
        return (
          <Tooltip
            label={
              isActive
                ? "Click to deactivate the subAdmin"
                : "Click to activate the subAdmin "
            }
            position="top"
            withArrow
          >
            <Android12Switch
              checked={isActive}
              onChange={() => openConfirmation(row.original)}
              sx={{ m: 1 }} // Styling
            />
          </Tooltip>
        );
      },
    },
  ]);

  const table = useMantineReactTable({
    columns,
    data: adminData,
    manualPagination: true,
    manualSorting: true,

    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  const handleSendLink = async (data) => {
    try {
      const emailList = Array.isArray(data.emails) ? data.emails : [data.email]; // Convert single email to array

      setEmail(data.email);
      const currentURL = window.location.origin;
      console.log("Current URL:", currentURL);
      const url = `${TQ_URL}/sendAddSubadminLink`;
      const response = await getRequestWithTokenWithData(url, {
        emails: emailList,
        path: `${currentURL}/addSubAdminDetails`,
        partnerId: partnerData.partnerId,
      });
      console.log(response);

      if (response.url != "") setFormSubmitted(true);
      reset();

      toast.success("Add SubAdmin Link send To privided email.", {
        position: "top-right",
      });
    } catch (error) {
      console.error("Failed To send the link", error.response.data.message);
      toast.error("Failed To send the link", {
        position: "top-right",
      });
    }
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  const renderContent = () => {
    return (
      <>
        {!isFormSubmitted ? (
          <div className="max-w-lg w-full">
            <h1 className="text-xl font-bold mb-4 text-center">Add SubAdmin</h1>
            <form onSubmit={handleSubmit(handleSendLink)}>
              {/* Name Field */}
              <div className="mb-6">
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: "Name is required" }}
                  render={({ field }) => (
                    <div>
                      <input
                        {...field}
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.name && "border-red-500"
                        }`}
                        placeholder="Enter Name"
                      />
                      {errors.name && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.name.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>

              {/* Email Field */}
              <div className="mb-6">
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <div>
                      <input
                        {...field}
                        type="email"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.email && "border-red-500"
                        }`}
                        placeholder="Enter Email ID"
                      />
                      {errors.email && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>

              {/* Send Link Button */}
              <div className="flex justify-center mt-10">
                <button
                  type="submit"
                  className={`w-full text-white p-2 px-6 rounded-md btn-style-1 ${
                    !isValid && "opacity-50 cursor-not-allowed"
                  }`}
                  disabled={!isValid}
                >
                  Send Link
                </button>
              </div>
            </form>

            <ToastContainer theme="colored" />
          </div>
        ) : (
          <div className="max-w-lg w-full bg-white shadow-lg rounded-lg p-6">
            <h1 className="text-2xl font-bold mb-6 text-center text-gray-800">
              🎉 Sub-Admin Added!
            </h1>
            <p className="text-center text-lg text-gray-700">
              <b className="text-green-600">
                We've sent a sub-admin invitation to{" "}
                <span className="text-green-700">{email}</span>.
              </b>
              Please ask them to check their inbox and follow the instructions
              to complete their registration.
            </p>

            {/* <div className="flex justify-center mt-8">
              <img
                src="https://www.example.com/invitation-email-graphic.png"
                alt="Invitation Sent"
                className="w-24 h-24"
              />
            </div> */}

            <p className="text-center text-sm text-gray-600 mt-6">
              Haven't received the email? Check your spam folder or{" "}
              <a
                href="#"
                className="underline text-blue-600 font-medium hover:text-blue-700 transition-colors duration-300"
                onClick={handleTryAgain}
              >
                click here to resend
              </a>
              .
            </p>

            <div className="mt-8 text-center">
              <button
                onClick={handleTryAgain}
                className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition-colors duration-300"
              >
                Resend Invitation
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    // <div style={{ padding: "20px" }}>
    //   <h2>Admin Details</h2>
    //   <MantineReactTable table={table} />
    // </div>

    <>
      <div>
        <DonorManagementTop />
        <div className="flex flex-col md:flex-row">
          <main className="w-full verify-user">
            <div className="mx-auto shadow-md p-4 md:p-8">
              <div className="flex flex-col md:flex-row justify-between items-center">
                {/* Title */}
                <div className="text-center md:text-left mb-4 md:mb-0">
                  <h2 className="text-2xl font-bold">
                    Manage SubAdmin Details
                  </h2>
                </div>

                {/* Add SubAdmin Button */}
                <div className="text-center md:text-right">
                  <button
                    className="w-full lg:w-auto btn-style-1 text-white py-2 px-4 rounded"
                    onClick={openModal}
                  >
                    +Add SubAdmin
                  </button>
                </div>
              </div>
            </div>
            <div className="overflow-x-auto px-4 md:px-8 py-8 container mx-auto">
              <MantineReactTable table={table} />
            </div>
          </main>
        </div>
      </div>

      {/* Modal for Adding SubAdmin */}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Onboarding Process Modal"
        shouldCloseOnOverlayClick={false}
        className={`onboarding-modal bg-white p-6 rounded-lg transition-all duration-700 ease-in-out ${animationClass}`}
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-700 ease-in-out"
      >
        <button
          onClick={closeModal}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none text-2xl "
          aria-label="Close modal"
        >
          &times; {/* You can use an icon here or any other symbol */}
        </button>

        {renderContent()}

        {/* Show loading when data is being saved */}
        {isLoading && (
          <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
            <Triangle
              height="120"
              width="120"
              color="blue"
              ariaLabel="loading"
            />
            <p className="text-gray-300 mt-4">
              Please wait, your data is being saved...
            </p>
          </div>
        )}
      </Modal>

      <NewModal
        opened={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        title="Confirm Action"
      >
        <Text>
          {selectedAdmin?.actStatus
            ? `Are you sure you want to deactivate ${selectedAdmin?.name}?`
            : `Are you sure you want to activate ${selectedAdmin?.name}?`}
        </Text>
        <Box mt="lg" sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="gray"
            onClick={() => setConfirmationOpen(false)}
            mr="sm"
          >
            Cancel
          </Button>
          <Button
            color={selectedAdmin?.actStatus ? "red" : "green"}
            onClick={() => handleToggleStatus(selectedAdmin?.subAdminId)}
          >
            {selectedAdmin?.actStatus ? "Deactivate" : "Activate"}
          </Button>
        </Box>
      </NewModal>
    </>
  );
};

export default ManageSubAdmin;
