import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { Triangle } from "react-loader-spinner";
import axios from "axios";
import { TQ_URL, TQ_URL_USPROJECT } from "../../utility/baseUrl";
import {
  getRequestWithTokenWithDataWithAuth,
} from "../../utility/apiRequest";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const schema = yup.object().shape({
  individualName: yup.string().required("Individual's name is required"),
  address: yup.string().required("Address is required"),
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  fundraiserGoal: yup
    .number()
    .required("Fundraiser goal is required")
    .positive("Fundraiser goal should be positive or non-zero")
    .typeError("Fundraiser goal Amount should not be Empty"),
  targetDate: yup.date().required("Target Date is required"),
});

const EditFundraiser = () => {
  const { state } = useLocation();
  const { fundraiserId } = state || {};
  const [fundraiserData, setFundraiserData] = useState(null);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSliderModal, setShowSliderModal] = useState(false);
  const [previewFiles, setPreviewFiles] = useState([]);
  const navigate = useNavigate();
  const fileInputRef = useRef();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchUpdatedData = async (fundraiserId) => {
    try {
      const response = await getRequestWithTokenWithDataWithAuth(
        `${TQ_URL}/getFundraiserProfileDetailsToEdit`,
        { fundraiserId }
      );
      return response.data;
    } catch (error) {
      toast.error("Error fetching updated data.");
      setTimeout(() => navigate('/fundraiserhistory'), 2000);
    }
  };

  useEffect(() => {
    if (fundraiserId) {
      fetchUpdatedData(fundraiserId).then((data) => {
        if (data) {
          setFundraiserData(data);
        }
      });
    }
  }, [fundraiserId]);

  useEffect(() => {
    if (fundraiserData) {
      setValue("individualName", fundraiserData.fundraiserName);
      setValue("address", fundraiserData.address);
      setValue("title", fundraiserData.title);
      setValue("description", fundraiserData.desc);
      setValue("fundraiserGoal", fundraiserData.goalAmt);
      setValue("targetDate", fundraiserData.targetDate);
      setUploadedDocs(fundraiserData.docNamesList || []);
      setUploadedFiles(fundraiserData.docNamesList || []);
      setPreviewFiles(fundraiserData.imageList || []);
    }
  }, [fundraiserData, setValue]);

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => file.size <= 5 * 1024 * 1024);
    setUploadedDocs((prevDocs) => [...prevDocs, ...validFiles.map((file) => file.name)]);
    setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    setPreviewFiles((prevPreview) => [
      ...prevPreview,
      ...validFiles.map((file) => URL.createObjectURL(file)),
    ]);
  };

  const handleRemoveDocument = (index) => {
    setUploadedDocs((prevDocs) => prevDocs.filter((_, i) => i !== index));
    setPreviewFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const openSliderModal = () => setShowSliderModal(true);
  const closeSliderModal = () => setShowSliderModal(false);

  const handleUpdateFundraiser = async (data) => {
    if (uploadedFiles.length === 0) {
      toast.error("Please select documents to upload.");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("fundraiserId", fundraiserData.fundraiserId);
    formData.append("fundraiserName", data.individualName);
    formData.append("address", data.address);
    formData.append("desc", data.description);
    formData.append("title", data.title);
    formData.append("goalAmt", parseFloat(data.fundraiserGoal));
    formData.append("targetDate", data.targetDate);
    uploadedFiles.forEach((file) => formData.append("file", file));

    try {
      await axios.put(`${TQ_URL_USPROJECT}/editFundraiserDetails`, formData);
      toast.success("Fundraiser updated successfully!");
      fetchUpdatedData(fundraiserId);
    } catch (error) {
      toast.error("Error updating fundraiser.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="container mx-auto px-6 py-12">
        <h2 className="text-center text-3xl font-bold mb-8">Edit Fundraiser</h2>
        {loading ? (
          <div className="flex justify-center">
            <Triangle height="120" width="120" color="blue" ariaLabel="loading" />
          </div>
        ) : (
          <form onSubmit={handleSubmit(handleUpdateFundraiser)} className="space-y-6 bg-white shadow-lg rounded-lg p-8">
            <div className="flex flex-col space-y-4">
              <div>
                <label className="block text-lg font-semibold mb-1">Individual's Name</label>
                <input
                  {...register("individualName")}
                  type="text"
                  className={`block w-full p-2 border rounded-lg ${errors.individualName ? "border-red-500" : "border-gray-300"}`}
                  placeholder="Enter name"
                />
                {errors.individualName && <p className="text-red-500 text-sm">{errors.individualName.message}</p>}
              </div>

              <div>
                <label className="block text-lg font-semibold mb-1">Address</label>
                <input
                  {...register("address")}
                  type="text"
                  className={`block w-full p-2 border rounded-lg ${errors.address ? "border-red-500" : "border-gray-300"}`}
                  placeholder="Enter address"
                />
                {errors.address && <p className="text-red-500 text-sm">{errors.address.message}</p>}
              </div>

              <div>
                <label className="block text-lg font-semibold mb-1">Title</label>
                <input
                  {...register("title")}
                  type="text"
                  className={`block w-full p-2 border rounded-lg ${errors.title ? "border-red-500" : "border-gray-300"}`}
                  placeholder="Enter title"
                />
                {errors.title && <p className="text-red-500 text-sm">{errors.title.message}</p>}
              </div>

              <div>
                <label className="block text-lg font-semibold mb-1">Description</label>
                <textarea
                  {...register("description")}
                  rows={4}
                  className={`block w-full p-2 border rounded-lg ${errors.description ? "border-red-500" : "border-gray-300"}`}
                  placeholder="Enter description"
                />
                {errors.description && <p className="text-red-500 text-sm">{errors.description.message}</p>}
              </div>

              <div>
                <label className="block text-lg font-semibold mb-1">Fundraiser Goal</label>
                <Controller
                  name="fundraiserGoal"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="number"
                      className={`block w-full p-2 border rounded-lg ${errors.fundraiserGoal ? "border-red-500" : "border-gray-300"}`}
                      placeholder="Enter fundraiser goal"
                    />
                  )}
                />
                {errors.fundraiserGoal && <p className="text-red-500 text-sm">{errors.fundraiserGoal.message}</p>}
              </div>

              <div>
                <label className="block text-lg font-semibold mb-1">Target Date</label>
                <Controller
                  name="targetDate"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="date"
                      className={`block w-full p-2 border rounded-lg ${errors.targetDate ? "border-red-500" : "border-gray-300"}`}
                      placeholder="Select target date"
                    />
                  )}
                />
                {errors.targetDate && <p className="text-red-500 text-sm">{errors.targetDate.message}</p>}
              </div>

              <div>
                <label className="block text-lg font-semibold mb-1">Upload Documents</label>
                <div className="flex flex-wrap gap-4">
                  {previewFiles.map((file, index) => (
                    <div key={index} className="relative">
                      <img src={file} alt={`preview-${index}`} className="w-24 h-24 object-cover rounded-lg" />
                      <button
                        type="button"
                        onClick={() => handleRemoveDocument(index)}
                        className="absolute top-1 right-1 text-white bg-red-500 rounded-full w-6 h-6 flex items-center justify-center"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="mt-4"
                  onChange={handleFileInputChange}
                  multiple
                  accept=".jpg,.jpeg,.png,.pdf"
                />
              </div>

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => navigate('/fundraiserhistory')}
                  className="bg-gray-300 text-gray-700 px-6 py-2 rounded-lg"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
                >
                  Update Fundraiser
                </button>
              </div>
            </div>
          </form>
        )}
      </div>

      <Footer />

      {showSliderModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
            <button onClick={closeSliderModal} className="absolute top-2 right-2 text-2xl text-red-600">
              &times;
            </button>
            <Slider {...{ dots: true, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1 }}>
              {previewFiles.map((file, index) => (
                <div key={index}>
                  <img src={file} alt={`preview-${index}`} className="w-full h-auto" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}

      <ToastContainer theme="colored" />
    </>
  );
};

export default EditFundraiser;
