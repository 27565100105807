import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { postWithTokenWithData } from '../../utility/apiRequest'; // Adjust the import path accordingly
import { TQ_URL } from '../../utility/baseUrl';

const FB_APP_ID = '830389442285126';
const FB_REDIRECT_URI = 'https://0476-58-84-62-63.ngrok-free.app/auth/facebook/callback';

const FacebookCallback = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const mounted = useRef(false); // Ref to track if the component has mounted

    const handleRedirect = async () => {
        const hash = window.location.hash;
        
        const endPoint = "/api/facebook/save"
        const url = `${TQ_URL}${endPoint}`; 
        if (hash) {
            const params = new URLSearchParams(hash.replace('#', ''));
            const shortLivedToken = params.get('access_token');
            if (shortLivedToken) {
                const partnerData = JSON.parse(localStorage.getItem('partnerData')); // Retrieve partnerData from localStorage

                const facebookData = {
                    partnerId: partnerData.partnerId, // Use partnerId from partnerData
                    channel: 'Facebook',
                    shortLivedToken: shortLivedToken,
                };

                try {
                    await postWithTokenWithData(url, facebookData);
                    navigate('/social-media-campaign');
                } catch (error) {
                    console.error('Error saving data to backend:', error);
                    setLoading(false);
                }
            } else {
                setLoading(false);
                console.error('No short-lived token found in the URL.');
            }
        } else {
            setLoading(false);
            console.error('No hash found in the URL.');
        }
    };

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            handleRedirect();
        }
    }, []);

    return (
        <div>
            {loading ? <p>Loading...</p> : <p>Error occurred during Facebook authentication. Please try again.</p>}
        </div>
    );
};

export default FacebookCallback;
