import React from "react";
import { useEffect, useState, forwardRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-input-mask";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
} from "../../utility/apiRequest";
import { TQ_URL, TQ_URL_USPROJECT } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Modal, Button } from '@mantine/core';

const CustomInput = forwardRef((props, ref) => (
  <InputMask {...props} inputRef={ref} />
));
const AddSubAdmin = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  let extractedtoken = urlParams.get("tokenLink");
  let partnerId = urlParams.get("pid");

  const [stateName, setStateName] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [countryFlag, setCountryFlag] = useState(false);
  const [zipcode, setZipcode] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [countdown, setCountdown] = useState(3); // Timer countdown


  console.log("pid: ", partnerId);

  if (extractedtoken && extractedtoken.endsWith("/")) {
    extractedtoken = extractedtoken.slice(0, -1); // Remove the trailing slash
  }

  if (partnerId && partnerId.endsWith("/")) {
    partnerId = partnerId.slice(0, -1); // Remove the trailing slash
  }

  console.log(extractedtoken);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    watch,
    reset,
    setValue
  } = useForm({
    mode: "onChange", // Enable onChange mode to update the isValid flag
  });
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [verifiedMsg, setVerifiedMsg] = useState("");

  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [userType, setUserType] = useState("");

  const handleCaptchaResponseChange = (response) => {
    setCaptchaResponse(response);
    setVerifiedMsg("");
  };

  const newPassword = watch("newPassword", "");
  const confirmPassword = watch("confirmPassword", "");

  const handleShowPassword = (field) => {
    if (field === "newPassword") {
      setShowNewPassword(!showNewPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  useEffect(() => {
    const userTypeCookie = Cookies.get("UserType");
    setUserType(userTypeCookie);
    getSubadminEmailFromToken()


    
  }, []);

  const getSubadminEmailFromToken = async () => {
    try {
      const url = `${TQ_URL}/getSubadminEmailFromToken`;
      
      // Make the request with the token
      const response = await getRequestWithTokenWithData(url, {
        tokenURL: extractedtoken,
      });
      
      console.log(response);
  
      if (response === "Not found") {
        // Show toast if the email is not found
        toast.error("Email not found. Please contact the admin.", {
          position: "top-right",
        });
      } else {
       
        setValue("adminEmail", response);
      }
  
    } catch (error) {
      // Handle error if the request fails
      console.error("Error fetching subadmin email:", error);
      toast.error("An error occurred while fetching the subadmin email.", {
        position: "top-right",
      });
    }
  };
  

  const handleSave = async (data) => {
    console.log(data);
    // return;
    const url = `${TQ_URL}/addSubAdminDetails`;
    if (data.newPassword != data.confirmPassword) {
      toast.error("Password and confirmPassword does not match. ", {
        position: "top-right",
      });
      return;
    } else {
      try {
        if (!captchaResponse) {
          setVerifiedMsg("Please complete the reCAPTCHA verification.");
          //  toast.error("Please complete the reCAPTCHA verification.");
          return;
        }

        setVerifiedMsg("");

        const response = await getRequestWithTokenWithData(url, {
          tokenURL: extractedtoken,
          adminName: data.adminName,
          adminEmail: data.adminEmail,
          adminContact: data.contactNumber,
          password: data.newPassword,
          partnerId: parseInt(partnerId),
          address : data.address,
          city: data.city,
          state: data.state,
          country: data.country,
          zipcode: data.zipcode
        });
        console.log(response);
        if (response.message == "Invalid token.") {
          toast.error("Invalid URL!", {
            position: "top-right",
          });
        } else if (response.message == "Token expired.") {
          toast.error("Token expired.", {
            position: "top-right",
          });
        } else if (
          response.message == "Email already exists for this partner."
        ) {
          toast.error("Email already exists for this partner.", {
            position: "top-right",
          });
        } else {
          toast.success("Details Saved Successfully!.", {
            position: "top-right",
          });
          setModalOpen(true);
          reset();
        }
      } catch (error) {
        console.error(
          "Error in fetching link for set new password.:",
          error.response.data.message
        );
        toast.error(error.response.data.message, {
          position: "top-right",
        });
      }
    }
  };

  useEffect(() => {
    if (modalOpen && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      
      
      

      return () => clearTimeout(timer);
    }
    setCookie("Individual")
    if (countdown === 0) {
      console.log("iside counter--->");
      
      navigate('/ngo-login'); // Redirect to login screen when countdown reaches 0
    }
  }, [modalOpen, countdown, navigate]);

  const setCookie = (userType) => {
    const isLocalhost = window.location.hostname.includes("localhost");
    // const domain = isLocalhost ? "localhost" : ".prabalta.org";
    const domain = isLocalhost ? "localhost" : "prabalta.org";
    const path = isLocalhost ? "/" : "/prabaltatwo";

    Cookies.set("UserType", userType, {
      domain: domain,
      path: path,
      secure: !isLocalhost, // Use secure only in production
      sameSite: isLocalhost ? "Lax" : "None", // 'None' allows cross-site cookies, 'Lax' for local
      expires: 1, // 1-day expiry
    });

  }


  const zipCodeDetails = async (event) => {
    const zipcode = event.target.value;

    if (!zipcode) {
      setNotFoundMessage("Zip Code is required");
      setStateName("");
      setCityName("");
      setValue("state", "");
      setValue("country", "");
      setCountryFlag(false);
      return;
    }

    if (zipcode.length === 5) {
      try {
        const response = await fetch(`${TQ_URL}/getAddressDetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            zipcode: zipcode,
          }),
        });

        if (response.status === 200) {
          const res = await response.json();

          // Handle both "Success" and "Succsess"
          if (res.Status === "Success" || res.Status === "Succsess") {
            setStateName(res.State.state);
            setCityName(res.State.city);
            setZipcode(zipcode);
            setCountryFlag(true);
            setNotFoundMessage("");
            setValue("state", res.State.state);
            setValue("city", res.State.city);
            setValue("country", "USA");
          } else {
            setNotFoundMessage("Incorrect Zip Code");
            setStateName("");
            setCityName("");
            setCountryFlag(false);
          }
        }
      } catch (error) {
        console.error("Error fetching zip code details:", error);
      }
    } else {
      setStateName("");
      setCityName("");
      setValue("state", "");
      setValue("city", "");
      setValue("country", "");
      setCountryFlag(false);
      setNotFoundMessage("Incorrect Zip Code");
    }
  };

  

  return (
    <div className="flex min-h-screen">
      {/* Left Section */}
      <div className="change-pw-bg flex-1 bg-cover bg-center relative fade-in-left hidden md:block ">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute text-left m-20">
          <Link to="/">
            <img
              src="assets/images/main-logo.svg"
              alt="Logo"
              className="h-10 z-10"
            />
          </Link>
        </div>
        <div className="absolute inset-0 flex flex-col items-start justify-end px-20">
          <h3 className="txt-style-2 mb-5">Welcome to the Team:</h3>
          <h3 className="txt-style-1 mb-16">
            Add a New Subadmin
            <br />
            and Enhance Our Mission
          </h3>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex-1 flex items-center justify-center p-10 fade-in-right fade-in-right">
        <div className="max-w-lg w-full">
          
          {modalOpen ? (
      <div className="text-center">
        {/* Success Icon */}
        <div className="flex justify-center mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            width="48"
            viewBox="0 0 48 48"
            fill="green"
          >
            <path d="M20.55 32.35L35.85 17.05L33.7 14.9L20.55 28.05L14.3 21.8L12.15 23.95L20.55 32.35ZM24 44Q19.45 44 15.275 42.25Q11.1 40.5 8.175 37.575Q5.25 34.65 3.5 30.475Q1.75 26.3 1.75 21.75Q1.75 17.2 3.5 13.025Q5.25 8.85 8.175 5.925Q11.1 3 15.275 1.25Q19.45 -0.5 24 -0.5Q28.55 -0.5 32.725 1.25Q36.9 3 39.825 5.925Q42.75 8.85 44.5 13.025Q46.25 17.2 46.25 21.75Q46.25 26.3 44.5 30.475Q42.75 34.65 39.825 37.575Q36.9 40.5 32.725 42.25Q28.55 44 24 44Z"/>
          </svg>
        </div>
        
        {/* Success Message */}
        <h1 className="text-2xl font-bold text-green-600">Registration done successfully!</h1>
        <p className="text-xl mt-4">Redirecting in {countdown} seconds...</p>
      </div>
    ) : <><h1 className="txt-style-3 text-center">ADD NEW ADMIN</h1>
          <form onSubmit={handleSubmit(handleSave)}>
            {/* Admin Name Field */}
            <div className="mb-6">
              <Controller
                control={control}
                name="adminName"
                rules={{ required: "Admin Name is required" }}
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    id="adminName"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.adminName && "border-red-500"
                      }`}
                    placeholder="Admin Name"
                    required
                  />
                )}
              />
              {errors.adminName && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.adminName.message}
                </p>
              )}
            </div>

            {/* Admin Email Field */}
            <div className="mb-6">
              <Controller
                control={control}
                name="adminEmail"
                
                rules={{
                  required: "Admin Email is required",
                  pattern: {
                    value: /^\S+@\S+\.\S+$/,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <input
                    disabled
                    {...field}
                    type="email"
                    id="adminEmail"
                   
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.adminEmail && "border-red-500"
                      }`}
                    placeholder="Admin Email"
                    required
                  />
                )}
              />
              {errors.adminEmail && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.adminEmail.message}
                </p>
              )}
            </div>

            {/* Contact Number Field (Optional) */}
            <div className="mb-6">
              <Controller
                control={control}
                name="contactNumber"
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    mask="(999) 999-9999"
                    placeholder="Contact Number"
                    id="contactNumber"
                    alwaysShowMask={false}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  />
                )}
              />
            </div>

            {/* Password Field */}
            <div className="mb-6 relative">
              <Controller
                control={control}
                name="newPassword"
                rules={{ required: "Password is required" }}
                render={({ field }) => (
                  <div>
                    <input
                      {...field}
                      type={showNewPassword ? "text" : "password"}
                      id="newPassword"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-10 ${errors.newPassword && "border-red-500"
                        }`}
                      placeholder="Password"
                      required
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <button
                        type="button"
                        onClick={() => handleShowPassword("newPassword")}
                        className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer h-10"
                      >
                        {showNewPassword ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <path
                              d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                              stroke="#000000"
                              strokeWidth="0.72"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <path
                              d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12"
                              stroke="#000000"
                              strokeWidth="0.72"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              />
              {errors.newPassword && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.newPassword.message}
                </p>
              )}
            </div>

            {/* Confirm Password Field */}
            <div className="mb-6 relative">
              <Controller
                control={control}
                name="confirmPassword"
                rules={{ required: "Confirm Password is required" }}
                render={({ field }) => (
                  <div>
                    <input
                      {...field}
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirmPassword"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-10 ${errors.confirmPassword && "border-red-500"
                        }`}
                      placeholder="Confirm Password"
                      required
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <button
                        type="button"
                        onClick={() => handleShowPassword("confirmPassword")}
                        className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer h-10"
                      >
                        {showConfirmPassword ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <path
                              d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                              stroke="#000000"
                              strokeWidth="0.72"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                              stroke="#000000"
                              strokeWidth="0.72"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <path
                              d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                              stroke="#000000"
                              strokeWidth="0.72"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.confirmPassword.message}
                </p>
              )}
              {confirmPassword !== "" && newPassword !== confirmPassword && (
                <p className="text-red-500 text-sm mt-1">
                  Passwords do not match
                </p>
              )}

              {/* Address Field */}
              <div className="mb-6 mt-6">
                <Controller
                  control={control}
                  name="address"
                  rules={{ required: "Address is required" }}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      id="address"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.address && "border-red-500"
                        }`}
                      placeholder="Address"
                      required
                    />
                  )}
                />
                {errors.address && (
                  <p className="text-red-500 text-sm mt-1">{errors.address.message}</p>
                )}
              </div>

              {/* Zipcode Field */}
              <div className="mb-6">
                <Controller
                  control={control}
                  name="zipcode"

                  rules={{
                    
                    maxLength:5
                  }}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      id="zipcode"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.zipcode && "border-red-500"
                        }`}
                      placeholder="Zipcode"
                      required
                      maxLength={5}
                      onChange={(e) => {
                        field.onChange(e); // Update form state
                        zipCodeDetails(e);  // Call the function to fetch zip code details
                      }}
                    />
                  )}
                />
               
                {cityName && stateName && countryFlag && (
                  <div className="text-bold mt-1">
                    <em>
                      <b>{`${cityName}, ${stateName}, USA`}</b>
                    </em>
                  </div>
                )}
                {notFoundMessage && (
                  <p className="text-red-500 text-sm mt-1">{notFoundMessage}</p>
                )}
              </div>
            </div>

            {/* CAPTCHA */}

            <ReCAPTCHA
              sitekey="6LeaQ4QpAAAAACwPHgef7yz2B3tgaNqtNwsevXi9"
              onChange={handleCaptchaResponseChange}
            />
            <span className="text-red-500 text-sm mt-1">{verifiedMsg}</span>

            {/* Submit Button */}
            <div className="flex justify-center mt-10">
              <button
                type="submit"
                className={`w-full  text-white p-2 px-12 rounded-md btn-style-1 ${(!isValid || !isDirty || newPassword !== confirmPassword) &&
                  "opacity-50 cursor-not-allowed"
                  }`}
                disabled={
                  !isValid || !isDirty || newPassword !== confirmPassword
                }
              >
                Submit
              </button>
            </div>
          </form>
          </>
}
          <ToastContainer theme="colored" />
         
        </div>
      </div>
    </div>
  );
};

export default AddSubAdmin;
