import React, { useState } from "react";
import { Link } from "react-router-dom";

const SuccessPartner = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen ">
      {/* Left Section */}
      <div className="md:flex-1 bg-cover bg-center relative fade-in-left verify-addr-bg">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0 md:flex flex-col items-center justify-center md:px-20 px-5 ">
          <div className="w-full">
            <div className="success-message">
              <div className="text-center md:text-left">
                <img
                  src="assets/images/stars.svg"
                  alt="Logo"
                  className="h-4 mx-auto sm:mx-0"
                />
              </div>
              <h1 className="txt-style-5">Congratulations !</h1>

              <h1 className="txt-style-6">
                You are now a part of <b>Prabalta.</b>
              </h1>
              <p className="para-style-1 text-center">
                Explore our initiatives and join us in creating a better world
                together.
              </p>
              <div className="flex  justify-between mt-5">
                <Link to="/services-dashboard">
                  <button className=" text-white px-10 py-2 rounded-sm  btn-style-1 ms-5">
                    Confirm
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPartner;
