import React, { useEffect, useState } from "react";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import AnalyticsSidebar from "./AnalyticsSidebar";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Tooltip as RxTooltip, Box } from "@mantine/core";
import { TQ_URL } from "../../utility/baseUrl";
import {
  getRequestWithOutToken,
  getRequestWithTokenWithoutDataPartner,
} from "../../utility/apiRequest";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const FundraiserPerformance = () => {
  const navigate = useNavigate();
  const [fundraiserInfo, setFundraiserInfo] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [statusFilter, setStatusFilter] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [sorting, setSorting] = useState([]);

  const [value, setValue] = React.useState(0);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchText, setSearchText] = useState("");
  const [activeTab] = useState(1);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const [setDonorData] = useState([]);

  useEffect(() => {
    if (!fundraiserInfo.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getFundraiserList();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting, //refetch when sorting changes
    // activeTab,
    columnFilters,
    statusFilter,
  ]);

  const formatCurrency = (value) => {
    if (!value) return "";
    const amount = parseFloat(value);
    if (isNaN(amount)) return "";
    return `$${amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const getFundraiserList = async () => {
    const url = new URL(`${TQ_URL}/getAllFundraiserDetail`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("status", `${statusFilter}`);
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);

    try {
      const response = await getRequestWithOutToken(url.toString());

      console.log(response);

      console.log(response.dataList);

      const dataWithSerialNumber = response.dataList.datalist.map(
        (item, index) => ({
          ...item,
          id: index + 1,
        })
      );

      console.log("This is Data", dataWithSerialNumber);

      setFundraiserInfo(dataWithSerialNumber);
      console.log(fundraiserInfo);
      setRowCount(response.totalCount);

      setIsRefetching(false);
    } catch (error) {
      if (
        error.response &&
        error.response.dataList &&
        error.response.dataList.message
      ) {
        let errorMessage = error.response.dataList.message;
        //   toast.error("Error fetching live feeds: " + errorMessage);
      } else {
        console.error("Error fetching live feeds:", error);
        // toast.error("Error fetching live feeds: " + error.message);
      }
      console.error("Error in fetching the fundraiser data:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    //  setIsRefetching(false);
  };

  const columns = [
    {
      accessorKey: "id",
      header: "Sr. no",
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: "fundraiserName",
      header: "Fundraiser Name",
      sortable: true,
      Cell: ({ row }) => {
        const title = row.original.fundraiserName;
        return (
          <RxTooltip
            label={title || ""}
            styles={(theme) => ({
              tooltip: {
                backgroundColor: theme.colors.blue[9],
                color: theme.white,
              },
            })}
          >
            <span>
              {title && title.length > 15
                ? `${title.substring(0, 15)}...`
                : title}
            </span>
          </RxTooltip>
        );
      },
    },
    {
      accessorKey: "targetAmount",
      header: "Target amount",
      sortable: true,
      Cell: ({ cell }) => <Box>{formatCurrency(cell.getValue())}</Box>,
    },
    {
      accessorKey: "totalMoneyRaised",
      header: "Amount generate",
      sortable: true,
      Cell: ({ cell }) => <Box>{formatCurrency(cell.getValue())}</Box>,
    },
    {
      accessorKey: "cause",
      header: "Cause Type",
      sortable: true,

      Cell: ({ row }) => {
        const cause = row.original.cause;
        return (
          <RxTooltip
            label={cause || ""}
            styles={(theme) => ({
              tooltip: {
                backgroundColor: theme.colors.blue[9],
                color: theme.white,
              },
            })}
          >
            <span>
              {cause && cause.length > 15
                ? `${cause.substring(0, 15)}...`
                : cause}
            </span>
          </RxTooltip>
        );
      },
    },
    {
      accessorKey: "createdBy",
      header: "Created by",
      sortable: true,
    },
    {
      accessorKey: "createdDate",
      header: "Created date",
      sortable: true,
    },
    {
      accessorKey: "targetDate",
      header: "Target date",
      sortable: true,
    },
  ];

  const table = useMantineReactTable({
    columns,
    data: fundraiserInfo,

    initialState: { showColumnFilters: false },
    rowCount,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,

    onColumnFiltersChange: setColumnFilters,

    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const rowData = row.original;
        console.log(rowData.fundraiserId);
        const fid = rowData.fundraiserId;
        navigate("/fundraiserPerformanceDetails", { state: { fid } });
      },
      sx: { cursor: "pointer" },
    }),

    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      console.log("searchText:", searchText);

      const url = new URL(`${TQ_URL}/searchFundraiser`);
      url.search = new URLSearchParams();
      url.searchParams.set("start", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("keyword", `${searchText}`);
      url.searchParams.set("partnerId", `${partnerData.partnerId}`);

      try {
        const response = await getRequestWithTokenWithoutDataPartner(
          url.toString()
        );

        //  setDonationData(response);
        console.log(response);

        console.log(response.dataList);

        const dataWithSerialNumber = response.dataList.map((item, index) => ({
          ...item,
          id: index + 1,
        }));

        console.log(response.dataList);

        setFundraiserInfo(dataWithSerialNumber);
        setRowCount(response.totalCount);

        setIsRefetching(false);
      } catch (error) {
        console.error("Error in fetching the searched donor's history:", error);
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
  };

  const getStatusValue = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value>>", selectedValue);
    setStatusFilter(selectedValue);
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed h-full md:overflow-y-auto">
          <AnalyticsSidebar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px] overflow-x-hidden">
          <div className="mx-auto shadow-md">
            <div className="md:flex justify-between items-center container px-10 md:py-6 py-6">
              <h2 className="text-2xl font-bold">Analytics and Reports</h2>
            </div>

            {/* Flex container for search and status dropdown */}
            <div className="flex flex-col md:flex-row justify-between items-center container px-10">
              <div className="w-full md:w-auto mb-4 md:mb-0">
                <input
                  type="text"
                  placeholder="Search..."
                  className="py-2 pr-8 pl-4 block w-full rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <div className="relative flex items-center w-full md:w-auto">
                <select
                  onChange={getStatusValue}
                  value={statusFilter}
                  className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full max-sm:py-3 p-3"
                >
                  <option value="1">Active</option>
                  <option value="5">Complete</option>
                </select>
              </div>
            </div>

            <div className="container px-10 py-5">
              <MantineReactTable table={table} />
            </div>
          </div>
        </main>
      </div>
      <ToastContainer theme="colored" />
    </>
  );
};

export default FundraiserPerformance;
