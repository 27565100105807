import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./privacy.css"; // Ensure you import the CSS with scroll-margin-top

const LegalPolicy = () => {
    const [activeSection, setActiveSection] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    // Scroll to section and center content
    const handleScrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (!section) return; // If section doesn't exist, return early.

        // Get the sticky header height
        const header = document.querySelector(".sticky-header");
        const headerHeight = header ? header.offsetHeight : 0;

        // Get the position of the section, and adjust it to account for the header height
        const sectionPosition = section.getBoundingClientRect().top + window.scrollY;

        // Scroll to the position, considering the header's height
        window.scrollTo({
            top: sectionPosition - headerHeight - 20, // Add 20px for extra margin
            behavior: "smooth",
        });

        // Update the active section for the Table of Contents highlight
        setActiveSection(sectionId);
    };

    // Generate className for active TOC link
    const isActiveLink = (id) => (activeSection === id ? "text-blue-700 font-bold" : "text-blue-500 hover:text-blue-700");

    return (
        <div>
            {/* Header */}
            <Header />

            {/* Main Content */}
            <div className="privacy-container p-10 bg-gray-50 text-gray-800">
                <div className="max-w-7xl mx-auto">
                    {/* Title Section */}
                    <div className="mb-12 text-center">
                        <h1 className="text-4xl font-extrabold text-gray-900 mb-4">Law Enforcement and Government Demands Policy</h1>
                        <p className="text-lg text-gray-500 mt-2">Effective Date: 1 September 2024</p>
                    </div>

                    <div className="flex flex-col lg:flex-row">
                        {/* Table of Contents */}
                        <aside className="w-full lg:w-1/4 bg-white p-6 shadow-lg mb-10 lg:mb-0 rounded-lg lg:sticky lg:top-20 max-h-[calc(100vh-100px)] overflow-y-auto">
                            <h2 className="text-2xl font-semibold mb-6 text-gray-800">Table of Contents</h2>
                            <ul className="space-y-4">
                                <li>
                                    <button onClick={() => handleScrollToSection("introduction")} className={`${isActiveLink("introduction")} block text-left w-full`}>
                                        I. Introduction
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection("general-information")} className={`${isActiveLink("general-information")} block text-left w-full`}>
                                        II. General Information
                                    </button>
                                </li>
                               
                                <li>
                                    <button onClick={() => handleScrollToSection("information-available")} className={`${isActiveLink("information-available")} block text-left w-full`}>
                                        III. Information Available from Prabalta.org
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection("guidelines-for-requesting-info")} className={`${isActiveLink("guidelines-for-requesting-info")} block text-left w-full`}>
                                        IV. Guidelines for Requesting Information from Prabalta.org
                                    </button>
                                </li>

                                <li>
                                    <button onClick={() => handleScrollToSection("exigent-circumstances")} className={`${isActiveLink("exigent-circumstances")} block text-left w-full`}>
                                       V. Exigent Circumstances Procedures
                                    </button>
                                </li>




                            </ul>
                        </aside>

                        {/* Privacy Content */}
                        <main className="w-full lg:w-3/4 p-8 bg-white shadow-lg rounded-lg lg:ml-10">
                            <section id="introduction">
                                <h2 className="text-2xl font-extrabold mb-4">I. Introduction</h2>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    Prabalta.org and its subsidiaries and affiliates (collectively, <em>“Prabalta.org”</em>) are committed to maintaining the privacy of its users and employees (collectively, <em>“Data Subjects”</em>) and adhering to the law and data privacy regulations that apply in the many jurisdictions in which it operates. Prabalta.org is also committed to making reasonable efforts to cooperate with legally valid court orders, subpoenas, search warrants, or other information requests issued by law enforcement or official representatives of government agencies (collectively <em>“Requests”</em>). This Law Enforcement and Government Demands Policy (<em>“Policy”</em>) establishes the principles that Prabalta.org will follow when responding to such Requests.
                                </p>
                            </section>

                            <section id="general-information">
                                <h2 className="text-2xl font-extrabold mb-4">II. General Information</h2>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    Data Subject information is held by Prabalta.org in accordance with Prabalta.org’s Privacy Notice and Terms of Service. Prabalta.org is committed to maintaining the privacy of Data Subjects. Moreover, Prabalta.org is committed to the importance of trust and transparency pertaining to how we use and disclose such data. As stated in our Privacy Notice, we may transfer, disclose and preserve Data Subject information at the request of courts, law enforcement, governmental or public authorities, tax authorities, or authorized third parties, if and to the extent we are required or permitted to do so by law or where disclosure is reasonably necessary to: (i) comply with our legal obligations, (ii) comply with a valid legal request (such as a subpoena or court order) or to respond to claims asserted against us, (iii) respond to a valid legal request relating to a criminal investigation to address alleged or suspected illegal activity, or to respond to or address any other activity that may expose us or any other of our users or members of the public to imminent harm, legal or regulatory liability, (iv) enforce and administer our Terms of Service or other policies, or (v) protect the rights, property or personal safety of Prabalta.org, its affiliates, its employees, its users, or members of the public.
                                </p>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    Prabalta.org maintains a centralized process for receiving, tracking, processing, and responding to Requests from law enforcement or government agencies. Our legal department reviews and evaluates all Requests received, and Requests which Prabalta.org determines to have no valid legal basis or considers to be unclear, inappropriate or over-broad are objected to, challenged or otherwise rejected.
                                </p>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    Nothing within this Policy is intended to create any enforceable rights against Prabalta.org, and Prabalta.org’s policies may be updated or changed in the future without notice to law enforcement or government agencies. The latest version of this Policy will be available on the Prabalta.org website.
                                </p>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    If you have further questions, please contact us by emailing <a href="mailto:support@prabalta.org" className="text-blue-500 hover:underline">support@prabalta.org</a>.
                                </p>
                            </section>

                            <section id="general-information">
                                <h2 className="text-2xl font-extrabold mb-4">II. General Information</h2>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    Data Subject information is held by Prabalta.org in accordance with Prabalta.org’s Privacy Notice and Terms of Service. Prabalta.org is committed to maintaining the privacy of Data Subjects. Moreover, Prabalta.org is committed to the importance of trust and transparency pertaining to how we use and disclose such data. As stated in our Privacy Notice, we may transfer, disclose and preserve Data Subject information at the request of courts, law enforcement, governmental or public authorities, tax authorities, or authorized third parties, if and to the extent we are required or permitted to do so by law or where disclosure is reasonably necessary to: (i) comply with our legal obligations, (ii) comply with a valid legal request (such as a subpoena or court order) or to respond to claims asserted against us, (iii) respond to a valid legal request relating to a criminal investigation to address alleged or suspected illegal activity, or to respond to or address any other activity that may expose us or any other of our users or members of the public to imminent harm, legal or regulatory liability, (iv) enforce and administer our Terms of Service or other policies, or (v) protect the rights, property or personal safety of Prabalta.org, its affiliates, its employees, its users, or members of the public.
                                </p>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    Prabalta.org maintains a centralized process for receiving, tracking, processing, and responding to Requests from law enforcement or government agencies. Our legal department reviews and evaluates all Requests received, and Requests which Prabalta.org determines to have no valid legal basis or considers to be unclear, inappropriate or over-broad are objected to, challenged, or otherwise rejected.
                                </p>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    Nothing within this Policy is intended to create any enforceable rights against Prabalta.org, and Prabalta.org’s policies may be updated or changed in the future without notice to law enforcement or government agencies. The latest version of this Policy will be available on the Prabalta.org website.
                                </p>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    If you have further questions, please contact us by emailing <a href="mailto:support@prabalta.org" className="text-blue-500 hover:underline">support@prabalta.org</a>.
                                </p>
                            </section>

                            <section id="information-available">
                                <h2 className="text-2xl font-extrabold mb-4">III. Information Available from Prabalta.org</h2>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    This section covers the general types of information which are and are not available from Prabalta.org.
                                </p>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    Prabalta.org may produce the following information in response to a legally valid Request to the extent it has such information available in its records:
                                </p>
                                <ul className="list-disc ml-6 text-base text-gray-600 mb-8 leading-relaxed">
                                    <li>Name, email address, IP address, and phone number associated with a Data Subject who created a particular fundraiser;</li>
                                    <li>The content of a fundraiser, including previous versions or edits made to a particular fundraiser;</li>
                                    <li>Information regarding the amount of money raised by a particular fundraiser;</li>
                                    <li>Information regarding the timing and amount of any funds withdrawn by a fundraiser organizer or beneficiary;</li>
                                    <li>Name, email address, and IP address for any Data Subjects who donate to a fundraiser, as well as the donation amount and donation date;</li>
                                    <li>Information concerning the relevant third-party payment processor relating to a particular fundraiser.</li>
                                </ul>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    Prabalta.org may minimize, redact, or otherwise limit the information produced in response to a Request as appropriate and necessary to comply with the Request and applicable law.

                                    Prabalta.org is not a financial institution, bank or payment processor and does not hold funds itself.  Accordingly, Prabalta.org is not in possession, custody or control of its users’ bank account statements, complete banking or credit card information, digital payment information, account opening documentation, full account numbers or any other information typically held by financial institutions or payment processors.  Prabalta.org is also not able to search for responsive records using such financial information.  For bank account and related information, Prabalta.org can provide you the name of the relevant third-party payment processor and you can contact them for additional information.
                                </p>
                            </section>


                            <section id="guidelines-for-requesting-info">
                                <h2 className="text-2xl font-extrabold mb-4">IV. Guidelines for Requesting Information from Prabalta.org</h2>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    Law enforcement and government agencies should be as specific as possible when fashioning their Requests to avoid misinterpretation, objection, challenge and/or rejection of an unclear, inappropriate, or overbroad request. All Requests should include the legal basis for the request by citing the specific provision of domestic law which authorizes the collection of information from a third-party entity such as Prabalta.org for the purposes of prevention, detection, or investigation of offenses, or the relevant provision of the applicable data privacy law or regulation. Requests that do not have a legal basis, are overbroad, vague, do not provide sufficient information to identify responsive information, or that are otherwise inappropriate will be rejected.
                                </p>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    When submitting Requests, the requestor should identify the specific user or fundraiser at issue. When possible, the requester should provide unique identifiers associated with a user or fundraiser such as (1) the fundraiser title; (2) the fundraiser link; (3) the user’s full name; and (4) the user’s email address. Names alone, without additional identifiers, are often not unique identifiers and Prabalta.org may not be able to identify responsive records using names alone. Please note that Prabalta.org is unable to conduct searches using dates of birth, addresses, social security numbers, or bank account numbers.
                                </p>
                            </section>

                            <section id="exigent-circumstances">
                                <h2 className="text-2xl font-extrabold mb-4">V. Exigent Circumstances Procedures</h2>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    Exigent Circumstances are those in which the law enforcement or government agency provides sufficient information such that Prabalta.org believes in good faith that an emergency involving imminent threat of death or serious injury to any person, terrorism, cyber-terrorism or kidnapping requires disclosure without delay of information relating to the Exigent Circumstances. A requestor seeking to demonstrate that Exigent Circumstances exist must be able to sufficiently verify his/her identity and the nature of the Exigent Circumstances.
                                </p>
                                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                                    Requests for information in Exigent Circumstances without an otherwise legally valid subpoena, search warrant, court order, or information request may be submitted to Prabalta.org via its Law Enforcement Requests for Records form. Prabalta.org will evaluate the Exigent Circumstances and seek additional verification, if needed. If Prabalta.org, after reasonable diligence, believes there are Exigent Circumstances, then Prabalta.org will respond to such requests for information as promptly as possible. The information disclosed will be limited to that necessary to specifically address the Exigent Circumstances.
                                </p>
                            </section>


                            <ul className="list-disc ml-6 text-base text-gray-600 mb-8 leading-relaxed">
                                <li>
                                    <strong>Requests from the United States, United Kingdom, European Union Countries, and Australia:</strong>
                                    Prabalta.org will accept service of Requests electronically submitted via Prabalta.org’s Law Enforcement Requests for Records form, provided that such requests are received from an official law enforcement email account, and all fields in the form are completed. No physical delivery is necessary if submitted via the web form.
                                </li>
                                <li>
                                    <strong>Alternatively, Requests may be served on Prabalta.org’s registered agent for service of process at:</strong>
                                    <ul className="list-disc ml-6">
                                        <li>Corporation Service Company which will do business in California as CSC-Lawyers Incorporating Service</li>
                                        <li>2710 Gateway Oaks Drive, Suite 150N Sacramento, CA 95833</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Elsewhere in the World:</strong>
                                    Law enforcement or government agencies outside of the United States, United Kingdom, Australia, or the European Union must utilize mutual legal assistance treaties (“MLATs”), the Hague Evidence Convention, or other comparable arrangements under applicable law.
                                </li>
                                <li>
                                    <strong>Witness Testimony:</strong>
                                    Prabalta.org will not waive service requirements for Requests seeking witness testimony, nor accept service for such requests via electronic means. All Requests for witness testimony must be served through Prabalta.org’s registered agent for service of process and properly domesticated pursuant to applicable law. Prabalta.org will resist Requests for witness testimony served with fewer than 14 days' notice.
                                </li>
                            </ul>



                        </main>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />

            {/* Toast notifications */}
            <ToastContainer />
        </div>
    );
};

export default LegalPolicy;
