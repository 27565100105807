import React, { useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import axios from "axios";
import { TQ_URL } from "../../utility/baseUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequestWithTokenWithoutDataPartner } from "../../utility/apiRequest";
import { ToastContainer, toast } from "react-toastify";
import SpamWarningModal from "./SpamWarningModal";


export default function UnlayerEditor() {
  const { state } = useLocation();
  const [isChanged, setIsChanged] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId;
  const templateId = state?.id; // get templateId from state
  const source = state?.source || "";
  const [templateDetails, setTemplateDetails] = useState(null);
  const [editorReady, setEditorReady] = useState(false);
  const [initialState, setInitialState] = useState({});
  const emailEditorRef = useRef(null);
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const [isConfirmedSave, setIsConfirmedSave] = useState(false);

  const [showSpamWarning, setShowSpamWarning] = useState(false);
  const [spammyWordsList, setSpammyWordsList] = useState([]);

  useEffect(() => {
    if (templateId) {
      fetchTemplateDetails();
    }
  }, [templateId]);

  useEffect(() => {
    if (editorReady && templateDetails) {
      const editor = emailEditorRef.current.editor;
      console.log("Setting template details in editor:", templateDetails);
      const { templateHtml, templateComponent } = templateDetails;

      if (templateComponent) {
        const design = JSON.parse(templateComponent);
        editor.loadDesign(design);
      }

      setInitialState({ html: templateHtml, component: templateComponent });
    }
  }, [editorReady, templateDetails]);

  const fetchTemplateDetails = async () => {
    const url = new URL(`${TQ_URL}/getTemplateDetails`);
    url.searchParams.set("templateId", templateId);
    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );
      console.log("Fetched template details:", response);
      if (response && response.templateData) {
        setTemplateDetails(response.templateData);
      } else {
        console.error("Template data not found in response");
      }
    } catch (error) {
      console.error("Error fetching template details:", error);
    }
  };

  const onLoad = () => {
    setEditorReady(true);
    console.log("Editor loaded successfully.");
  };

  const handleSaveWithSpam = async () => {
    setShowSpamWarning(false);
    await saveTemplate(4); // Call saveTemplate with status for saving
  };

  const handleEditContent = () => {
    setShowSpamWarning(false);
    // Logic to allow the user to edit content
  };

  const checkForSpam = async () => {
    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;

      //  const spammyWords = ["Buy now", "Free", "Act now", "Limited offer"];
      const lowerCaseHtml = html.toLowerCase();

      const response = await fetch('/spammyWords.json');
      const spammyWordsData = await response.json();
      const spammyWords = spammyWordsData.words;

      const foundSpammyWords = spammyWords.filter((word) =>
        new RegExp(`\\b${word.toLowerCase()}\\b`).test(lowerCaseHtml)
      );

      if (foundSpammyWords.length > 0) {
        setSpammyWordsList(foundSpammyWords);
        setShowSpamWarning(true);
        setIsConfirmedSave(false); // Reset confirmation state
      } else {
        // No spammy words, proceed to save template
        await saveTemplate(4); // Status for saving
      }
    });
  };

  const saveTemplate = async (status) => {
    // if (isSaving) return; // Prevent multiple save operations
    // if (!isConfirmedSave) return; // Only proceed if the save is confirmed

    // setIsSaving(true); // Set saving flag

    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;

      const currentState = { html, component: JSON.stringify(design) };

      if (!html.trim() || html.length < 10) {
        toast.info(
          "Email content is too short or empty. Please add more content."
        );
        setIsSaving(false); // Reset saving flag
        return;
      }

      console.log("Saving template:", { html, design });

      if (templateId) {
        try {
          console.log("inside edit template");
          const response = await axios.post(`${TQ_URL}/editTemplate`, {
            templateName: "template",
            templateHtml: html,
            templateComponent: JSON.stringify(design),
            partnerId: partnerId,
            templateId: templateDetails.templateId,
            status: status,
          });

          console.log("Template Edited successfully:", response);

          if (response.status === 200) {
            toast.success("Template Edited successfully");

            if (status === 5 || source !== "newCampaign") {
              setTimeout(() => {
                navigate("/templateList");
              }, 2000);
            } else {
              setTimeout(() => {
                navigate("/myCampaign?form=contact");
              }, 2000);
              localStorage.setItem("templateId", templateDetails.templateId);
            }
          } else {
            toast.error("Failed To edit template.");
          }
        } catch (error) {
          console.error("Error in Edit template:", error);
          toast.error("Something went wrong");
        }
      } else {
        try {
          console.log("inside save template");
          const response = await axios.post(`${TQ_URL}/saveTemplate`, {
            templateHtml: html,
            templateComponent: JSON.stringify(design),
            partnerId: partnerId,
            status: status,
          });

          console.log("Template saved successfully:", response);
          console.log("Response data:", response.data);
          if (response.status === 200) {
            toast.success("Template Saved Successfully.");
            if (status === 5 || source !== "newCampaign") {
              setTimeout(() => {
                navigate("/templateList");
              }, 2000);
            } else {
              setTimeout(() => {
                navigate("/myCampaign?form=contact");
              }, 2000);
              localStorage.setItem("templateId", response.data.templateId);
            }
          } else {
            toast.error("Failed to save template.");
            setTimeout(() => {
              navigate(
                `/myCampaign?form=subscription&templateId=${response.data.templateId}`
              );
            }, 2000);
          }
        } catch (error) {
          console.error("Error saving template:", error);
          toast.error("Something went wrong");
        }
      }
    });
  };

  const handleSaveDraft = () => {
    console.log("Saving data as draft...");
    setIsChanged(false);
    setShowConfirmation(false);

    saveTemplate(5);
  };

  const handleNavigate = () => {
    if (isChanged) {
      setShowConfirmation(true);
    } else {
      navigate("/templateList");
    }
  };

  const handleNotSaveChanges = () => {
    setShowConfirmation(false);
    navigate("/templateList");
  };

  return (
    <>
      <div className="editor-container-fullscreen">
        <div id="addNewTemplateEditor" className="flex justify-end p-2">
          <button
            className="text-white p-2 rounded-md btn-style-1 ml-2 mb-2"
            onClick={() => setShowConfirmation(true)}
          >
            Previous
          </button>

          {showConfirmation && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded shadow-md">
                <p className="mb-4">
                  You have unsaved changes. Do you want to save as a draft?
                </p>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                  onClick={handleSaveDraft}
                >
                  Yes, Save as Draft
                </button>
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                  onClick={handleNotSaveChanges}
                >
                  No, Discard Changes
                </button>
              </div>
            </div>
          )}
          <button
            className="text-white p-2 rounded-md btn-style-1 ml-2 mb-2"
            onClick={checkForSpam}
          >
            Save And Continue
          </button>
        </div>

        <EmailEditor
          ref={emailEditorRef}
          onLoad={onLoad}
          style={{ height: "calc(100vh - 50px)", width: "100%", zIndex: 1 }}
        />
      </div>

      {showSpamWarning && (
        <SpamWarningModal
          spammyWords={spammyWordsList}
          onContinue={handleSaveWithSpam}
          onChangeContent={handleEditContent}
        />
      )}
      <ToastContainer theme="colored" />
    </>
  );
}
