import React, { useEffect } from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { useNavigate } from "react-router-dom";

const HowWeWork = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const handleCreateFundraiserClick = () => {
    if (localStorage.getItem("accessToken") !== null) {
      navigate("/createfundraiser");
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <Header />

      {/* Original Banner Section */}
      <div className="how-we-work-banner-section text-white 2xl:px-20 md:px-10 px-4 flex justify-between w-full flex-col md:flex-row">
        <div className="flex items-center fade-in-left">
          <div>
            <h1 className="banner-text-2 md:w-[%] md:leading-[5rem] leading-[2.8rem]">
              How it Works{" "}
              {/* <span className="yellow-texture-bg ">for a Worthy Cause? </span> */}
            </h1>
            <p className="banner-text-3 md:w-[100%] md:leading-[5rem] leading-[2.8rem]">
              Empowering Communities Through <br />
              <span className="yellow-texture-bg">Every Donation</span>
            </p>
            <p className="banner-text-1 font-semibold font-family-poppins mt-5">
              Turning Generosity into Action: See How We Make It Happen!
            </p>

            {/* Donate Now Button */}
            {/* <button
              onClick={handleCreateFundraiserClick}
              className="btn-style-1 text-white text-md py-2 px-6 rounded !font-semibold my-4 after-arrow"
            >
              Donate here
            </button> */}
          </div>
        </div>
      </div>

      {/* Improved Donor Section with GIF */}
      <section className="bg-gradient-to-r from-blue-50 to-white py-20 px-6 md:px-20">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="relative flex items-center justify-center">
            <img
              src="assets/images/Charity-rafiki.png"
              alt="Donor Process GIF"
              className="rounded-lg shadow-xl w-[80%] h-auto object-contain transform hover:scale-105 transition duration-300 ease-in-out"
            />
          </div>
          <div className="space-y-6 text-left">
            <h2 className="text-4xl font-bold text-blue-900 hover:text-blue-700 transition-colors duration-200">
              Are You a Donor?
            </h2>
            <p className="text-lg text-gray-600">
              Supporting a cause is simple. Here’s how you can get started:
            </p>
            <ul className="list-none space-y-4 text-lg text-gray-700">
              <li className="flex items-center hover:text-blue-600 transition duration-300 ease-in-out">
                <span className="text-blue-500 mr-3 hover:scale-110 transform transition duration-300 ease-in-out">
                  ✓
                </span>
                Browse or search for fundraisers and select one to support.
              </li>
              <li className="flex items-center hover:text-blue-600 transition duration-300 ease-in-out">
                <span className="text-blue-500 mr-3 hover:scale-110 transform transition duration-300 ease-in-out">
                  ✓
                </span>
                Input your transaction details to complete the donation.
              </li>
              <li className="flex items-center hover:text-blue-600 transition duration-300 ease-in-out">
                <span className="text-blue-500 mr-3 hover:scale-110 transform transition duration-300 ease-in-out">
                  ✓
                </span>
                Provide payment information, like credit card details.
              </li>
              <li className="flex items-center hover:text-blue-600 transition duration-300 ease-in-out">
                <span className="text-blue-500 mr-3 hover:scale-110 transform transition duration-300 ease-in-out">
                  ✓
                </span>
                Receive an email confirmation for your successful donation.
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* Improved Recurring Donor Section */}
      <section className="bg-gradient-to-l from-blue-50 to-white py-20 px-6 md:px-20">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="space-y-6 text-left">
            <h2 className="text-4xl font-bold text-blue-900 hover:text-blue-700 transition-colors duration-200">
              Recurring Donor?
            </h2>
            <p className="text-lg text-gray-600">
              Become a recurring donor and make a continuous impact:
            </p>
            <ul className="list-none space-y-4 text-lg text-gray-700">
              <li className="flex items-center hover:text-blue-600 transition duration-300 ease-in-out">
                <span className="text-blue-500 mr-3 hover:scale-110 transform transition duration-300 ease-in-out">
                  ✓
                </span>
                Log in to the website to manage your recurring donations.
              </li>
              <li className="flex items-center hover:text-blue-600 transition duration-300 ease-in-out">
                <span className="text-blue-500 mr-3 hover:scale-110 transform transition duration-300 ease-in-out">
                  ✓
                </span>
                Choose donation frequency and fill in the required details.
              </li>
              <li className="flex items-center hover:text-blue-600 transition duration-300 ease-in-out">
                <span className="text-blue-500 mr-3 hover:scale-110 transform transition duration-300 ease-in-out">
                  ✓
                </span>
                Set the donation amount and payment preferences.
              </li>
              <li className="flex items-center hover:text-blue-600 transition duration-300 ease-in-out">
                <span className="text-blue-500 mr-3 hover:scale-110 transform transition duration-300 ease-in-out">
                  ✓
                </span>
                Receive confirmations for every successful subscription.
              </li>
            </ul>
          </div>
          <div className="relative flex items-center justify-center">
            <img
              src="assets/images/Charity-cuate.png"
              alt="Recurring Donor"
              className="rounded-lg shadow-xl w-[80%] h-auto object-contain transform hover:scale-105 transition duration-300 ease-in-out"
            />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default HowWeWork;
