import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Triangle } from "react-loader-spinner";

// Custom validation method
yup.addMethod(yup.string, "requiredIfUserType", function (userType) {
  return this.test(
    "required-if-user-type",
    "Organization Name is Required *",
    function (value) {
      const { userType: currentUserType } = this.options.context;
      if (
        (currentUserType === "Partner" || currentUserType === "Organization") &&
        !value
      ) {
        return false;
      }
      return true;
    }
  );
});

const schema = yup.object().shape({
  address: yup.string().required("Address is Required * "),
  city: yup.string().required("City is Required * "),
  state: yup.string().required("State is Required * "),
  zipcode: yup
    .number()
    .required("Zip Postal Code is Required * ")
    .positive("Zip Postal should be positive or non-zero")
    .typeError("Zip Postal should not be Empty"),
  country: yup.string().required("Country is Required *"),
  organizationName: yup.string().requiredIfUserType(),
});

const NgoAddressDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let emailAddress = new URLSearchParams(location.search).get("email");
  let userTypeParam = new URLSearchParams(location.search).get("userType");

  const [stateName, setStateName] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [countryFlag, setCountryFlag] = useState(false);
  const [userType, setUserType] = useState(
    Cookies.get("UserType") || userTypeParam || ""
  );
  const [flag, setFlag] = useState();
  const [loading, setLoading] = useState(false);
  const [isZipCodeFetched, setIsZipCodeFetched] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    context: { userType },
    defaultValues: { userType },
  });

  useEffect(() => {
    const fetchDataFromStorage = async () => {
      try {
        const storedData = localStorage.getItem("userData");
        const orgFlag = localStorage.getItem("OauthFlag");
        setFlag(orgFlag);
        setValue("organizationName", orgFlag === "1" ? "" : " ");
        if (storedData) {
          const userData = JSON.parse(storedData);
          setUserType(userData.userType);
        }
      } catch (error) {
        console.error("Error fetching and setting userType:", error);
      }
    };

    fetchDataFromStorage();
  }, [setValue]);

  // Monitor zip code changes and call zipCodeDetails function
  useEffect(() => {
    const subscription = watch((value) => {
      const zipCode = value.zipcode;
      if (zipCode?.length === 5 && !isZipCodeFetched) {
        zipCodeDetails({ target: { value: zipCode } });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isZipCodeFetched]);

  const onSubmitHandler = async (data) => {
    if (!emailAddress) {
      const storedData = localStorage.getItem("userData");
      if (storedData) {
        const userData = JSON.parse(storedData);
        emailAddress = userData.email;
      }
    }

    setLoading(true);
    try {
      const response = await fetch(`${TQ_URL}/saveAddressDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: emailAddress,
          address: data.address,
          city: data.city,
          state: data.state,
          country: data.country,
          postalCode: data.zipcode,
          userType: userType,
          organizationName:
            userType === "Partner" || userType === "Organization"
              ? data.organizationName
              : "",
        }),
      });

      const res = await response.json();

      console.log(res.addressDetail);

      if (response.status === 200) {
        if (userType === "Partner" || userType === "Organization") {
          updateLocalStorage(data.organizationName);
        } else {
          toast.success("Details saved successfully!", {
            position: "top-right",
          });

          localStorage.setItem(
            "userAddressDetails",
            JSON.stringify({ addressDetails: res.addressDetail })
          );

          setTimeout(() => {
            navigate(`/success-user`);
          }, 4000);
        }
      } else if (
        response.status === 400 &&
        res.message &&
        res.message.length > 0
      ) {
        let errorMsg = "";
        res.message.forEach((msg) => {
          errorMsg += ` ${msg}`;
        });
        toast.error("Failed to Save Address Details", {
          position: "top-right",
        });
      } else {
        toast.error("Failed to save the details", { position: "top-right" });
      }
    } catch (error) {
      console.error("Error during registration:", error);
      toast.error("Failed to save the details", { position: "top-right" });
    } finally {
      setLoading(false);
    }
  };

  const updateLocalStorage = (organizationName) => {
    toast.success("Details saved successfully!", { position: "top-right" });
    if (organizationName !== " ") {
      const storedData = localStorage.getItem("partnerData");
      if (storedData) {
        const partnerData = JSON.parse(storedData);
        partnerData.orgName = organizationName;
        localStorage.setItem("partnerData", JSON.stringify(partnerData));
      }
    }
    setTimeout(() => {
      navigate(`/success-partner`);
    }, 4000);
  };

  const zipCodeDetails = async (event) => {
    const zipcode = event.target.value;
    if (!zipcode) {
      setNotFoundMessage("Zip Postal Code is required");
      setStateName("");
      setCityName("");
      setCountryFlag(false);
      setIsZipCodeFetched(false);
      return;
    }
    if (zipcode.length === 5) {
      try {
        const response = await fetch(`${TQ_URL}/getAddressDetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ zipcode: zipcode }),
        });

        if (response.status === 200) {
          const res = await response.json();
          if (res.Status === "Succsess") {
            setStateName(res.State.state);
            setCityName(res.State.city);
            setValue("state", res.State.state);
            setValue("city", res.State.city);
            setValue("country", "United States");
            setNotFoundMessage("");
            setCountryFlag(true);
            setIsZipCodeFetched(true); // Ensure API call doesn't happen again for the same zipcode
          } else {
            setNotFoundMessage("Incorrect Zip Code");
            setStateName("");
            setCountryFlag(false);
            setIsZipCodeFetched(false);
          }
        }
      } catch (error) {
        console.error("Error making post request:", error);
        setIsZipCodeFetched(false);
      }
    } else {
      setStateName("");
      setCountryFlag(false);
      setValue("state", "");
      setValue("city", "");
      setValue("country", "");
      setCityName("");
      setNotFoundMessage("Incorrect Zip Code");
      setIsZipCodeFetched(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {loading && (
        <div className="fixed top-0 left-0 right-0 flex items-center justify-center h-full bg-black bg-opacity-50 z-50">
          <Triangle
            height="120"
            width="120"
            color="blue"
            ariaLabel="triangle-loading"
            visible={true}
          />
        </div>
      )}
      <div className="md:flex-1 bg-cover bg-center relative fade-in-left create-profile-bg hideLeft">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0 flex flex-col items-start justify-start md:px-20 px-5 mt-28">
          <h3 className="text-white txt-style-1 mb-5">
            Work For charity? <br />
            Sign in for a Charity Account?
          </h3>
          <h3 className="text-white txt-style-2 mb-10">
            Add Address
            <br />
            Details
          </h3>
        </div>
      </div>

      <div className="md:flex-1 flex items-center justify-start p-10 sm:p-10 marginInlineStart md:ms-10 max-w-screen-lg mx-auto w-full h-full fade-in-right">
        <div className="max-w-full w-full sm:max-w-lg 2xl:max-w-2xl">
          <div className="text-center md:text-left mb-10 sm:mb-12">
            <Link to="/">
              <img
                src="assets/images/main-logo.svg"
                alt="Logo"
                className="h-10 mx-auto sm:mx-0"
              />
            </Link>
          </div>

          <form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="off">
            {(userType === "Partner" || userType === "Organization") &&
            flag === "1" ? (
              <div className="mb-6">
                <input
                  {...register("organizationName")}
                  placeholder="Organization Name *"
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.organizationName && "border-red-500"
                  }`}
                  autoComplete="new-password"
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.organizationName?.message}
                </p>
              </div>
            ) : null}

            <div className="mb-6">
              <input
                {...register("address")}
                placeholder="Address *"
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.address && "border-red-500"
                }`}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.address?.message}
              </p>
            </div>

            <div className="mb-6">
              <input
                {...register("zipcode")}
                placeholder="Zip Postal Code *"
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                onBlur={zipCodeDetails}
                type="number"
                maxLength={6}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.zipcode && stateName == null ? "border-red-500" : ""
                }`}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.zipcode?.message}
              </p>

              {!errors.zipcode?.message && (
                <p className="text-red-500 text-sm mt-1">{notFoundMessage}</p>
              )}
            </div>
            <div className="mb-6">
              <input
                {...register("city")}
                placeholder="City *"
                type="text"
                value={cityName}
                readOnly
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.city && stateName == null ? "border-red-500" : ""
                }`}
                autoComplete="new-password"
              />
              <p
                className={`text-red-500 text-sm mt-1 ${cityName && "hidden"}`}
              >
                {errors.city?.message}
              </p>
            </div>
            <div className="mb-6">
              <input
                {...register("state")}
                placeholder="State *"
                type="text"
                readOnly
                value={stateName}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.state && stateName == null ? "border-red-500" : ""
                }`}
                autoComplete="new-password"
              />
              <p
                className={`text-red-500 text-sm mt-1 ${stateName && "hidden"}`}
              >
                {errors.state?.message}
              </p>
            </div>

            <div className="flex items-center my-8">
              <label
                htmlFor="countries"
                className="block me-8 text-sm font-medium text-gray-400"
              >
                Country*
              </label>
              <select
                {...register("country", { required: "Country is Required *" })}
                id="countries"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 ${
                  errors.state && !countryFlag ? "border-red-500" : ""
                }`}
                value={countryFlag ? "United States" : ""}
                disabled
                autoComplete="new-password"
              >
                <option value="">Select Country</option>
                <option value="United States">United States</option>
                <option value="Canada">Canada</option>
                <option value="France">France</option>
                <option value="Germany">Germany</option>
              </select>
            </div>
            <p
              className={`text-red-500 text-sm mt-1 ${countryFlag && "hidden"}`}
            >
              {errors.country?.message}
            </p>

            <div className="flex justify-between mt-10 space-x-3">
              <div>
                <button
                  type="submit"
                  className="text-white px-6 py-2 rounded-sm btn-style-1"
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
          <ToastContainer theme="colored" />
        </div>
      </div>
    </div>
  );
};

export default NgoAddressDetails;
