import React, { useState, useEffect  } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./privacy.css"; // Ensure you import the CSS with scroll-margin-top

const Privacy = () => {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // Scroll to section and center content
  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (!section) return; // If section doesn't exist, return early.

    // Get the sticky header height
    const header = document.querySelector(".sticky-header");
    const headerHeight = header ? header.offsetHeight : 0;

    // Get the position of the section, and adjust it to account for the header height
    const sectionPosition = section.getBoundingClientRect().top + window.scrollY;

    // Scroll to the position, considering the header's height
    window.scrollTo({
      top: sectionPosition - headerHeight - 20, // Add 20px for extra margin
      behavior: "smooth",
    });

    // Update the active section for the Table of Contents highlight
    setActiveSection(sectionId);
  };



  // Generate className for active TOC link
  const isActiveLink = (id) => (activeSection === id ? "text-blue-700 font-bold" : "text-blue-500 hover:text-blue-700");

  return (
    <div>
      {/* Header */}
      <Header />

      {/* Main Content */}
      <div className="privacy-container p-10 bg-gray-50 text-gray-800">
        <div className="max-w-7xl mx-auto">
          {/* Title Section */}
          <div className="mb-12 text-center">
            <h1 className="text-4xl font-extrabold text-gray-900 mb-4">Privacy Notice</h1>
            <p className="text-lg text-gray-500 mt-2">Effective Date: 1 September 2024</p>
          </div>

          <div className="flex flex-col lg:flex-row">
            {/* Table of Contents */}
            <aside className="w-full lg:w-1/4 bg-white p-6 shadow-lg mb-10 lg:mb-0 rounded-lg lg:sticky lg:top-20 max-h-[calc(100vh-100px)] overflow-y-auto">
              <h2 className="text-2xl font-semibold mb-6 text-gray-800">Table of Contents</h2>
              <ul className="space-y-4">
                <li className="break-words">
                  <button onClick={() => handleScrollToSection("privacy-notice")} className={`${isActiveLink("privacy-notice")} block text-left w-full`}>
                    Our Privacy Notice
                  </button>
                </li>
                <li className="break-words">
                  <button onClick={() => handleScrollToSection("information-we-collect")} className={`${isActiveLink("information-we-collect")} block text-left w-full`}>
                    Information We Collect
                  </button>
                </li>
                <li className="break-words">
                  <button onClick={() => handleScrollToSection("our-use-of-info")} className={`${isActiveLink("our-use-of-info")} block text-left w-full`}>
                    Our Use of Information
                  </button>
                </li>
                <li className="break-words">
                  <button onClick={() => handleScrollToSection("our-disclosure-of-info")} className={`${isActiveLink("our-disclosure-of-info")} block text-left w-full`}>
                    Our Disclosure of Information
                  </button>
                </li>
                <li className="break-words">
                  <button onClick={() => handleScrollToSection("volunteered-information")} className={`${isActiveLink("volunteered-information")} block text-left w-full`}>
                    Volunteered Information
                  </button>
                </li>
                <li className="break-words">
                  <button onClick={() => handleScrollToSection("online-analytics-and-tailored-advertising")} className={`${isActiveLink("online-analytics-and-tailored-advertising")} block text-left w-full`}>
                    Online Analytics & Tailored Advertising
                  </button>
                </li>
                <li>
                  <button onClick={() => handleScrollToSection("children")} className={isActiveLink("children")}>
                    Children
                  </button>
                </li>

                <li>
                  <button onClick={() => handleScrollToSection("links-to-other-websites")} className={isActiveLink("links-to-other-websites")}>
                    Links to Other Websites
                  </button>
                </li>

                <li>
                  <button onClick={() => handleScrollToSection("security")} className={isActiveLink("security")}>
                    Security
                  </button>
                </li>

                <li>
                  <button onClick={() => handleScrollToSection("retention-of-your-information")} className={isActiveLink("retention-of-your-information")}>
                    Retention of Your Information
                  </button>
                </li>

                <li>
                  <button onClick={() => handleScrollToSection("cross-border-data-transfers")} className={isActiveLink("cross-border-data-transfers")}>
                    Cross Border Data Transfers
                  </button>
                </li>

                <li>
                  <button onClick={() => handleScrollToSection("users-outside-us")} className={`${isActiveLink("users-outside-us")} block text-left w-full`}>
                    Users From Outside the United States
                  </button>
                </li>

                <li>
                  <button onClick={() => handleScrollToSection("your-choices")} className={`${isActiveLink("your-choices")} block text-left w-full`}>
                    Your Choices
                  </button>
                </li>
                <li>
                  <button onClick={() => handleScrollToSection("regional-privacy-disclosures")} className={`${isActiveLink("regional-privacy-disclosures")} block text-left w-full`}>
                    Regional Privacy Disclosures
                  </button>
                </li>
                <li>
                  <button onClick={() => handleScrollToSection("other-terms")} className={`${isActiveLink("other-terms")} block text-left w-full`}>
                    Other Terms
                  </button>
                </li>
                <li>
                  <button onClick={() => handleScrollToSection("changes-to-privacy-notice")} className={`${isActiveLink("changes-to-privacy-notice")} block text-left w-full`}>
                    Changes to Prabalta.org’s Privacy Notice
                  </button>
                </li>
                <li>
                  <button onClick={() => handleScrollToSection("contacting-prabalta")} className={`${isActiveLink("contacting-prabalta")} block text-left w-full`}>
                    Contacting Prabalta.org
                  </button>
                </li>










              </ul>
            </aside>


            {/* Privacy Content with Margin for Distance */}
            <main className="w-full lg:w-3/4 p-8 bg-white shadow-lg rounded-lg lg:ml-10">
              <section id="privacy-notice">
                <h2 className="text-2xl font-extrabold mb-4">Our Privacy Notice</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Prabalta.org and its affiliates and subsidiaries, including Classy, Inc. (“Classy”), Prabalta.org Australia Pty Ltd. and Prabalta.org Ireland, Limited (collectively, “Prabalta.org”, “we”, “our” and “us”) provide this Privacy Notice (“Notice”) to explain how we collect, use, and disclose information about you. This Notice applies to data collected by us offline and for all our products or services on which we make this Notice available – for example, when you use Prabalta.org or the Classy Platform or our mobile applications or when you raise funds on Prabalta.org for your campaigns or donate to one of our non-profit partners through websites powered by Classy (collectively, the “Services”).
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Before you access, use, or submit any information through or in connection with the Services, please carefully review this Notice. By using any part of the Services, you acknowledge that you have read and understood that we may collect, use, and disclose information about you as outlined in this Notice. To the extent allowed by law, the English version of this Notice is binding, and its translations in other languages are for convenience only; in case of discrepancies between the English version of this Notice and its translations, the English version shall prevail.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  This Notice does not cover the privacy practices of either (i) organisers of Prabalta.org fundraisers (collectively, “Organisers”), (ii) beneficiaries of fundraisers (collectively, “Beneficiaries”), or (iii) non-profit organisations that use the Classy platform (collectively, “Classy Clients”). We are not responsible for the privacy practices of Organisers, Beneficiaries, or Classy Clients, and their handling of your information may be subject to their own privacy statements. This Notice also does not cover our data practices when we act as a “processor” or “service provider” on behalf of Classy Clients. In those circumstances, we process information about you on behalf of the Classy Client, and you should consult the privacy statement of the Classy Client for information on how they process information about you.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  If you are a resident of California, the California Consumer Privacy Act of 2018 provides you with additional rights. Please view our “California Privacy Notice” to learn more. For any other specific US state privacy law-related matters, please refer to the section below titled “Regional Privacy Disclosures” and “Your Choices”.
                </p>
              </section>

              {/* Information We Collect Section */}
              <section id="information-we-collect">
                <h3 className="text-xl font-semibold mb-4">Information We Collect</h3>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  As further described below, we collect information in multiple ways, including when you provide information directly to us, when we collect information about you from other parties, and when we automatically collect information from your browser or device (e.g. by logging how you interact with our Services).
                </p>

                <h4 className="text-lg font-semibold mb-4">A. Information That You Provide Directly to Us:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We and our vendors may collect information from you directly in various ways, such as when you:
                  <ul className="list-disc ml-6">
                    <li>Open an account or otherwise register for the Services;</li>
                    <li>Create a fundraiser on the Services;</li>
                    <li>Create a peer-to-peer, crowdfunding, or team page;</li>
                    <li>Create, register for, or purchase tickets to non-profit events, merchandise, and/or other activities;</li>
                    <li>Set up withdrawals or transfers;</li>
                    <li>Donate to a fundraiser, organisation, or cause;</li>
                    <li>Post comments, leave feedback, send thank-you notes, or otherwise communicate with other users through our Services;</li>
                    <li>Fill out a survey or questionnaire or provide us with feedback regarding the Services;</li>
                    <li>Request certain features (e.g. newsletters, updates, and other products);</li>
                    <li>Sign up for contests, awards, and events hosted by us;</li>
                    <li>Communicate with us, such as for reporting a fundraiser, troubleshooting, or support services.</li>
                  </ul>
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  The information you provide us directly may relate to you or others and may include, without limitation:
                </p>

                <h4 className="text-lg font-semibold mb-4">Account Registration Information:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Such as your name, email address, login details, postcode, country, phone number, and certain other information, including any information you choose to provide us (e.g. profile picture). If you use the Services as an organisation (e.g. a Classy Client or any other registered non-profit), you may also provide us with your organisation’s name, role/title, tax identification number, and other information about your organisation.
                </p>

                <h4 className="text-lg font-semibold mb-4">Fundraiser Information:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  such as a fundraiser title, a fundraiser category, images, videos, description and fundraiser goal(s).
                </p>

                <h4 className="text-lg font-semibold mb-4">Financial Information:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  such as payment account details for withdrawals and payment information for donations, and billing information. We use third-party payment processors to process financial information. For example, we use Stripe, Adyen and Paypal for payments, analytics, and other business services. These payment processors may collect personal data, including via cookies and similar technologies. The personal data they collect include transactional data and identifying information about devices that connect to its services. They use this information to operate and improve the services provided to us, including fraud detection, loss prevention, authentication, and analytics related to the performance of services. They operate both as processors for us and independent controllers for the information they collect directly from our users.
                </p>



                {/* Add other subsections similarly... */}

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  You can learn more about Stripe and read its privacy policy at <a href="https://stripe.com/privacy" className="text-blue-500 hover:underline">https://stripe.com/privacy</a>. You can learn more about Adyen and read its privacy policy at <a href="https://www.adyen.com/policies-and-disclaimer/privacy-policy" className="text-blue-500 hover:underline">https://www.adyen.com/policies-and-disclaimer/privacy-policy</a>. You can learn more about PayPal and read its privacy policy at <a href="https://www.paypal.com/us/legalhub/privacy-full" className="text-blue-500 hover:underline">https://www.paypal.com/us/legalhub/privacy-full</a>.
                </p>
                <h4 className="text-lg font-semibold mb-4">Publicly Available and Non-Publicly Available Communications and Content:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  such as comments, feedback, thank-you notes or other communications with other users through our Services. Any content, including such comments, feedback, and notes that you choose to post on publicly available campaign pages through our Services, are available to the public by default. Please remember that any information that is disclosed in these areas becomes public information for both us and other users to use and share. Take care not to disclose information about you unless you intend for that data to become public. Please also be considerate and respectful of others while using the community to publish your opinions. We reserve the right but do not have the obligation, to review and monitor publicly-available content, non-publicly available communications between users, and any other content made on or through our Services, and to remove postings or content that may be viewed as inappropriate or offensive to others.
                </p>
                <h4 className="text-lg font-semibold mb-4">Information about Yourself and Others, Including Sensitive Information:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Such as information about a fundraiser, including the beneficiary of a fundraiser, fundraiser goals, and other information you choose to provide, which may include information about situations or other circumstances that motivate a fundraising request. If you provide us with information about others, you acknowledge and agree that you have the authority and consent of the relevant other parties for us to access, disclose, and use the relevant data, and that you have notified the other parties and informed them of how their information is collected and used by Prabalta.org to provide the Services. We reserve the right to identify you as the person who has made the referral in any messages that are sent to them. You can also provide us with information about yourself or other parties that are considered sensitive, such as political opinions, race, ethnicity, health information, or religious beliefs. If you post this information in a fundraiser, you choose to provide such information to be published publicly in relation to your fundraiser, donation, or otherwise. Subject to applicable law, Prabalta.org may process any information that has manifestly been made public, including by processing information about you that reveals sensitive information.
                </p>
                <h4 className="text-lg font-semibold mb-4">Chats and Other Communications with Us:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Such as for troubleshooting or support services. We may engage vendors to provide certain interactive features on our Services. Your use of these interactive features is voluntary, and we may retain the information that you submit through these features. For example, we may offer an interactive chat feature on the Services for customer service purposes. When you participate in the interactive chat, the contents of the chat may be captured and kept as a transcript. By using these features, you understand that we and our vendors on our behalf may, on a real-time basis, collect and process the information obtained through the feature. We would ask that you take care not to send financial or health information or other sensitive personal data to us through our chat services or other means unless we ask you specifically for that data as part of providing the Services to you. When you provide such information to us directly, you consent to the processing of this data by Prabalta.org and/or our vendors. We collect the information listed in this section for the purposes described below in the sections “Our Use of Information Collected” and “Our Disclosure of Information Collected About You.”
                </p>
                <h4 className="text-lg font-semibold mb-4">Biometric Information::</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We sometimes engage vendors to collect biometric information for identity verification, regulatory compliance, and fraud prevention purposes. We or the vendors we engage may collect a copy of your government-issued ID (i.e. your driver’s licence, state ID card, or passport) and a scan of the picture on your government-issued ID for identification purposes. Our vendors may, with your consent, employ facial recognition technology to verify your identity and share the results of their analysis with us, if needed. Prabalta.org does not have access to or otherwise collect or maintain any biometric information. Your biometric information will be retained by our vendors, and subject to their retention policies and privacy policies.
                </p>

                <h4 className="text-lg font-semibold mb-4">B. Information We Collect from Other Parties:</h4>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We sometimes engage vendors to collect biometric information for identity verification, regulatory compliance, and fraud prevention purposes. We or the vendors we engage may collect a copy of your government-issued ID (i.e. your driver’s licence, state ID card, or passport) and a scan of the picture on your government-issued ID for identification purposes. Our vendors may, with your consent, employ facial recognition technology to verify your identity and share the results of their analysis with us, if needed. Prabalta.org does not have access to or otherwise collect or maintain any biometric information. Your biometric information will be retained by our vendors, and subject to their retention policies and privacy policies.

                  Prabalta.org may use vendors to improve the quality of our own user database so that we might improve our Services to you, for verification and fraud prevention purposes, and as otherwise described in this Notice. In addition, Prabalta.org obtains contact details and other personal information regarding media contacts and influencers from various groups such as Cision and MuckRack. See the <a href="https://www.cision.com/us/legal/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}><strong>Cision privacy policy</strong></a> and the <a href="https://muckrack.com/privacy" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}><strong>Muck Rack privacy policy</strong></a> for further information.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  One of the special features of the Services is that they allow you to enable or log into the Services via various social media applications/services, like Facebook or X, or to upload videos using YouTube APIs (each and collectively, the “Social Networking Service(s)”). By directly integrating these Social Networking Services, we aim to make your online experiences richer and more personalised. To take advantage of these features, we will ask you to provide your credentials for the applicable Social Networking Service in order to log in to our Services. When you add a Social Networking Services account to the Services or log into the Services using your Social Networking Services account, we will collect the relevant information necessary to enable the Services to access that Social Networking Service. As part of such integration, the Social Networking Service will provide us with access to certain information that you have provided to the Social Networking Service and made available to us based on your privacy settings with the Social Networking Service (for example, your name and photograph, as well as other information that you permit the Social Networking Service to share with us).
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We will use, store, and disclose such information in accordance with this Notice. However, please remember that the manner in which Social Networking Services use, store, and disclose your information is governed by the policies of such third parties, and Prabalta.org shall have no liability or responsibility for the privacy practices or other actions of any Social Networking Services that may be enabled within the Services. For example, if you upload a video onto your fundraiser using the mobile application for the Services, your video will be uploaded using YouTube and subject to <a href="https://policies.google.com/privacy?hl=en-US" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>Google’s Privacy Policy</a> and <a href="https://myaccount.google.com/permissions?pli=1" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>Google’s Security Settings</a>. You further acknowledge that if you choose to use this feature, your friends, followers, and subscribers on any Social Networking Services you have enabled may be able to view such activity. For example, if you sign on using Meta, <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>Meta’s Privacy Policy</a> and <a href="https://policies.google.com/privacy?hl=en-US" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>Terms of Service</a> would apply to the data Meta might obtain from your activities on Prabalta.org. Please visit the terms of use and privacy policy of the relevant Social Networking Service to learn more about your rights and choices, and to exercise your choices about those technologies.
                </p>
                <h4 className="text-lg font-semibold mb-4">C. Information That is Passively or Automatically Collected:</h4>

                <div className="text-base text-gray-600 mb-8 leading-relaxed">
                  <h5 className="font-semibold text-base mb-2">I. Device & Usage Information:</h5>
                  <p className="text-base text-gray-600 mb-8 leading-relaxed">
                    When you interact with Prabalta.org through the Services, we automatically receive and store certain information from devices that you use to access the Services such as your IP address. This information is collected passively by using various technologies and includes the type of Internet browser or mobile device you use, any website from which you have come to the Services, your operating system, and inferred location data through an IP address that identifies the city and state where you logged into the Services. Prabalta.org either stores such information itself or such information is included in databases owned and maintained by Prabalta.org, and/or its agents or vendors.
                  </p>

                  <h5 className="font-semibold text-base mb-2">II. Location Information:</h5>
                  <p className="text-base text-gray-600 mb-8 leading-relaxed">
                    When you use the Services to organise a fundraiser, the Services may require that you provide your postcode, city or town, and region/country. Please keep in mind that other users of the Services can view your postcode, city or town, and region/country in connection with the fundraiser. If you install our mobile application, we may ask you to grant us access to your mobile device’s precise geolocation data. If you grant such permission, we may collect information about your precise geolocation, and we may use that information to improve the Services, such as providing you with location-based features (e.g. to prepopulate your postcode or to identify fundraisers near you). We also use your location information as described in this Notice, such as described above under “Device & Usage Information,” and in an aggregate way, as described below in the “Aggregated Data” section. You may opt out of providing your geolocation information in your device’s settings.
                  </p>
                  <h5 className="font-semibold text-base mb-2">III. Cookies and Other Electronic Technologies:</h5>
                  <p className="text-base text-gray-600 mb-8 leading-relaxed">
                    Please review our <a href="https://policies.google.com/privacy?hl=en-US" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>Cookie Policy</a> for information on the types of cookies we or our vendors use on the Services, along with how we use those cookies and other electronic technologies.

                    If we are unable to collect your information, we may not be able to provide you with the Services or assist you with your questions.
                  </p>

                </div>




              </section>

              {/* Our Use of Information Collected Section */}
              <section id="our-use-of-info">
                <h4 className="text-lg font-semibold mb-4">Our Use of Information Collected</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Prabalta.org uses the information collected from the Services in a manner that is consistent with this Notice. We may use the information that you provide (or otherwise permit the Services to access) for the following purposes:
                </p>
                <ul className="list-disc ml-6 text-base text-gray-600 mb-8 leading-relaxed">
                  <li>Provide, operate, and maintain the Services, including to register and maintain your account, facilitate the Know Your Customer (KYC) verification process and to complete transactions;</li>
                  <li>Communicate with you for various purposes, including to help you fundraise more, or for administrative purposes (e.g. to provide services and information that you request or to respond to comments and questions) regarding the Services;</li>
                  <li>Request your feedback;</li>
                  <li>Personalisation, marketing, and advertising;</li>
                  <li>Analyse, improve, modify, customise, and measure the Services, including to train our artificial intelligence/machine learning models;</li>
                  <li>Develop new products and services and other research and development;</li>
                  <li>Verify your identity and detect and prevent fraud or other misuses of the Services;</li>
                  <li>Maintain the security of your account and any associated fundraisers;</li>
                  <li>Comply with legal obligations, law enforcement requests, and legal process and to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or other parties, including to enforce our Terms of Service and any other agreements; and</li>
                  <li>Carry out any other purpose for which the information was collected.</li>
                </ul>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We may combine information that we collect from you through any or all of the Services with information that we obtain from other sources. For example, we may compile information from a variety of sources to generate inferences about you, such as inferences about your likely interests in making charitable or other donations and the potential size of such donations, starting peer-to-peer fundraisers, signing up for recurring donations, or other similar indicators.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We may also aggregate and/or de-identify information collected through the Services. We may use and disclose de-identified or aggregated data for any purpose, including, without limitation, research and marketing purposes.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We may communicate with you regarding your Prabalta.org account by SMS, MMS, or text message. For example, when setting up your Prabalta.org account, if you click “Send code” by “Text Message,” you agree to receive automated and nonautomated text messages related to your account from or on behalf of Prabalta.org at the phone number provided. You can reply STOP to such text messages to cancel, except for automated text messages related to the security of your account. Message frequency will vary. In the event you change or deactivate your mobile telephone number, you agree to promptly update your Prabalta.org account information to ensure that your messages are not misdirected. Please note that your mobile service provider’s standard charges, message and data rates, and other fees may apply where you access the Services through a mobile device. In addition, downloading, installing, or using certain Services on a mobile device may be prohibited or restricted by your service provider, and not all Services may work with all providers or devices.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We may also employ machine learning and other analytics that make inferences about some characteristics of our users. We use these tools to tailor emails to you, to show and prioritise causes, fundraisers, and pages we think you’ll be interested in, to protect you and your account from misuse, and make suggestions about how much you may want to fundraise or donate. To opt out of this use, please submit a request through this form.
                </p>
              </section>

              <section id="our-disclosure-of-info">
                <h2 className="text-2xl font-extrabold mb-4">OUR DISCLOSURE OF INFORMATION COLLECTED ABOUT YOU</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  There are certain circumstances in which we disclose information collected about you with certain other parties without further notice to you, as set forth below.
                </p>

                <h4 className="text-lg font-semibold mb-4">A. Business Transfers:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganisation, dissolution, similar event, or steps taken in anticipation of such events (e.g. due diligence in a transaction), user information may be part of the reviewed and transferred assets.
                </p>

                <h4 className="text-lg font-semibold mb-4">B. Affiliates and Subsidiaries:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We disclose your personal information among the Prabalta.org entities, including our affiliates and subsidiaries, for purposes consistent with this Notice, such as to provide our Services, including hosting; marketing, and publicising fundraisers; providing you with customer support; administering funds in connection with fundraisers; preventing fraud and misuse; authenticating donors; sending you communications; improving Services offered by the various Prabalta.org entities and conducting the other activities described in this Notice.
                </p>

                <h4 className="text-lg font-semibold mb-4">C. Agents, Consultants, and Vendors:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Prabalta.org contracts with other companies to help us perform certain business-related functions, and we provide access to or disclose your information with these companies so they can perform services for us. Examples of such functions include marketing, postal information, data storage, security, identity verification, fraud prevention, payment processing, preventing fraud and misuse, legal services, and database maintenance. Our websites and checkout experiences for Classy Clients that are powered by Classy are protected by reCAPTCHA and Google Privacy Policy and Terms of Service apply.
                </p>

                <h4 className="text-lg font-semibold mb-4">D. Legal Requirements:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We may transfer, disclose to, and preserve your information for courts, law enforcement, governmental or public authorities, tax authorities, or authorised third parties, if and to the extent we are required or permitted to do so by law or where disclosure is reasonably necessary to: (i) comply with our legal obligations, (ii) comply with a valid legal request (such as a subpoena or court order), (iii) respond to claims asserted against us, (iv) respond to a valid legal request relating to a criminal investigation to address alleged or suspected illegal activity or to respond to or address any other activity that may expose us, you or any other of our users or members of the public to imminent harm, legal or regulatory liability, (v) enforce and administer our Terms of Service or other policies, or (vi) protect the rights, property or personal safety of Prabalta.org, its affiliates, its employees, its users, or members of the public.
                </p>

                <h4 className="text-lg font-semibold mb-4">E. Organisers, Non-profit Beneficiaries, and Classy Clients:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <em>Organisers.</em> We may disclose your information to Organisers, which may or may not be the same as the Beneficiaries of the fundraiser. For example, if you donate to a fundraiser for an individual, we may disclose your name and donation amount to the Organiser who may in some instances also be the Beneficiary. Likewise, if you donate to a non-profit through Classy, you direct us to disclose your donation, contact information and any other information you provide to the applicable non-profit.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <em>Non-profit Beneficiaries.</em> Organisers may create fundraisers designating a non-profit organisation as a Beneficiary. Where a non-profit organisation is designated as the Beneficiary, that non-profit organisation may access certain information about the Campaign Organiser and donor information, including the donor’s name, email address, donation amount, date of transaction, transaction identification number, and name of the campaign. Prabalta.org contractually requires the non-profit organisation to use such information solely to communicate with you about your donation and for legal and auditing requirements. Prabalta.org may authorise non-profit organisations to process your information for other purposes (such as marketing) if you opt-in to that processing.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <em>Classy Clients and Their Vendors/Partners.</em> Classy may power the donation pages and other activities, such as event hosting for Classy Clients. If you donate to a non-profit through a Classy-powered webpage, you direct us to disclose your donation and contact information to the applicable non-profit, and such information shall also be subject to the privacy policies and practices of that Classy Client. Classy Clients may also direct us to further disclose your personal information to other third parties such as CRM tools or other integration partners used by the relevant Classy Client. We may also disclose certain information about you, such as information about your interests and donation preferences, to Classy Clients who may use such data for their fundraising and marketing operations.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <em> “Anonymous” Donations Limited to Public Activity Feeds.</em> If you selected not to be named publicly on the public fundraiser pages on the Services, while your donation will be designated as “anonymous” on the public activity feed, your name and other information may still be visible to the Classy Client, Organiser, any of their team members and the Beneficiary, and will be otherwise processed in accordance with this Notice.
                </p>
                <h4 className="text-lg font-semibold mb-4">F. Your Consent:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  In certain situations, we may disclose your information when you consented to the disclosure of your information. For example, we may partner with third-party organisations to arrange for specific fundraisers. If you consent to our providing your contact information to a specific partner, we will disclose the following information with the partner organisation: your name, email address and other information you have provided in connection with your donation to the specific fundraiser. Additionally, with the applicable Organiser’s or Beneficiary’s consent, we may provide their personal information to journalists and media to help amplify a campaign.</p>

                <h4 className="text-lg font-semibold mb-4">G. Aggregated Data:</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We aggregate, anonymise and/or de-identify information collected actively or passively about you so that the information no longer relates to you individually. We then use that data for various legally permissible purposes, including but not limited to our research on our customer demographics, interests and behaviour. We also disclose this information with our affiliates, agents, business partners, research facilities or other third parties.
                </p>

                <h4 className="text-lg font-semibold mb-4">H. Cookies and Other Electronic Technologies</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Information is disclosed as stated in the
                  <a
                    href="https://prabalta.org/cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Cookie Policy
                  </a>.</p>
                <h4 className="text-lg font-semibold mb-4">I. Other Users of Our Services
                </h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We provide your information to other users of our Services if you choose to make your information publicly available in a publicly accessible area of the Services, such as in your fundraiser through your donations or in the comments.</p>
              </section>

              <section id="volunteered-information">
                <h2 className="text-2xl font-extrabold mb-4">VOLUNTEERED INFORMATION</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Please be advised that some information you provide may be publicly accessible, such as information posted in forums or comment sections. When you launch a fundraiser, you make certain information publicly available and thus not protected by certain data protection laws.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We also collect information through forums and communities, surveys and customer support communications, your communication to us of ideas for new products or modifications to existing products, feedback, and other solicited or unsolicited submissions (collectively, with publicly-accessible information, “Volunteered Information”).
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Subject to applicable law, by sending us Volunteered Information, you further agree that we are under no obligation of confidentiality, express or implied, with respect to the Volunteered Information. This Volunteered Information section shall survive any termination of your account or the Services.
                </p>
              </section>

              <section id="online-analytics-and-tailored-advertising">
                <h2 className="text-2xl font-extrabold mb-4">ONLINE ANALYTICS AND TAILORED ADVERTISING</h2>


                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <em>Analytics:</em>    We may use third-party web analytics services on the Services, such as those of Google Analytics. These vendors use the sort of technology described in the “Information That is Passively or Automatically Collected” section above and in our <a
                    href="https://prabalta.org/cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Cookie Policy
                  </a> to help us analyse how users use the Services, including by noting the third-party website from which you arrive. The information collected by such technology will be disclosed to or collected directly by these vendors, who use the information to evaluate your use of the Services. We also may use Google Analytics for certain purposes related to advertising, as described in the following section. To prevent Google Analytics from using your information for web analytics, you may install the Google Analytics Opt-Out Browser Add-on.
                </p>


                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <em>Tailored Advertising:</em> We may allow third-party advertising networks to place cookies or other tracking technologies on your computer, mobile phone, or other device to collect information about your use of the Services in order to (a) inform, optimise, and serve marketing content based on past visits to our website and other online services and (b) report how our marketing content impressions and interactions with these marketing impressions are related to visits to our online services. We may also allow other unaffiliated parties (e.g. ad networks and ad servers such as Google Analytics) to serve tailored marketing to you and to access their own cookies or other tracking technologies on your computer, mobile phone, or other device you use to access the Services. Those parties that use these technologies may offer you a way to opt out of targeted advertising as described below. You may receive tailored advertising on your computer through a web browser. Cookies may be associated with de-identified data linked to or derived from data you voluntarily have submitted to us (e.g. your email address) that may be disclosed to a vendor in hashed, non-human-readable form.                </p>



                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We may use certain Meta products, such as the Meta Conversion API, on our Services (see our Cookie Policy for further information about digital advertising and cookies and similar technologies). We may use the product for certain analytics and marketing purposes as described in this Notice, including for measuring the effectiveness of Facebook ads for statistical and market research purposes. Meta Platforms Ireland Limited (“Meta”) acts as a joint controller of processing with Prabalta.org within the scope of the General Data Protection Regulation for certain of the processing activities relating to marketing and ad measurements. Information about how Meta processes your information, the legal basis for the processing, and how to contact Meta to exercise your rights with regard to information stored by Meta can be found at <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">https://www.facebook.com/about/privacy</a>.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  If you are interested in more information about tailored advertising and how you can generally control cookies from being put on your computer to deliver tailored marketing, you may visit the Network Advertising Initiative’s (“NAI”) Consumer Opt-Out Link, the Digital Advertising Alliance’s (“DAA”) Consumer Opt-Out Link, and/or the European Interactive Digital Advertising Alliance to opt-out of receiving tailored advertising from companies that participate in those programs. To exercise choices about how Google personalises Display Advertising or to customise Google Display Network ads, you can visit the Google Ads Settings page. Please note that to the extent advertising technology is integrated into the Services, you may still receive advertising content even if you opt out of tailored advertising. In that case, the advertising content may not be tailored to your interests. Also, we do not control any of the above opt-out links and are not responsible for any choices you make using these mechanisms or the continued availability or accuracy of these mechanisms. If your browsers are configured to reject cookies when you visit these opt-out pages, or you subsequently erase your cookies, or use a different computer or change web browsers, your opt-out may no longer be effective.  </p>


              </section>


              <section id="children">
                <h2 className="text-2xl font-extrabold mb-4">Children</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Our Services are not designed for use by individuals under the age of 13 (or 16 for children located in the European Economic Area or United Kingdom). If you are under the age of 13 (or 16 for children located in the European Economic Area or United Kingdom), please do not use the Services and/or submit any information through the Services. If you have reason to believe that a child under the age of 13 (or 16 for children located in the European Economic Area or United Kingdom) has provided personal information to Prabalta.org through the Services, please contact us at support@prabalta.org, and we will delete that information from our databases to the extent required by law.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Moreover, if you provide us with information about children under the age of 16, you acknowledge and agree that either you are the child’s parent/legal guardian or you have the authority and consent of the child’s parent/legal guardian to create a campaign/fundraiser and for us to access, disclose, and use the relevant data.
                </p>
              </section>

              <section id="links-to-other-websites">
                <h2 className="text-2xl font-extrabold mb-4">LINKS TO OTHER WEBSITES</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  The Services can contain links to other websites not operated or controlled by Prabalta.org (the “Third-Party Sites”). The policies and procedures we described here do not apply to the Third-Party Sites. The links from the Services do not imply that Prabalta.org endorses or has reviewed the Third-Party Sites. We suggest contacting those sites directly for information on their respective privacy policies.
                </p>
              </section>

              <section id="security">
                <h2 className="text-2xl font-extrabold mb-4">SECURITY</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We may hold your information we have collected in paper and/or electronic form. While no organisation can guarantee perfect security, Prabalta.org has implemented and seeks to continuously improve technical and organisational security measures to protect the information provided via the Services from loss, misuse, unauthorised access, disclosure, alteration, or destruction.
                  Please see <a
                    href="https://prabalta.org/security"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    here
                  </a> for details about security for prabalta.org.
                </p>
              </section>

              <section id="retention-of-your-information">
                <h2 className="text-2xl font-extrabold mb-4">RETENTION OF YOUR INFORMATION</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We retain your information for as long as we deem necessary for the purpose for which that information was collected and for our legitimate business operations; provided, however, that your information is only retained to the extent permitted or required by applicable laws.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  When determining the retention period for your information, we take into account various criteria, such as the type of products and services requested by or provided to you, the nature and length of our relationship with you, possible re-enrolment with our products or services, the impact on the Services we provide to you if we delete some information about you, mandatory retention periods provided by law, and any applicable statute of limitations.
                </p>
              </section>

              <section id="cross-border-data-transfers">
                <h2 className="text-2xl font-extrabold mb-4">CROSS BORDER DATA TRANSFERS</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Prabalta.org is a global organisation headquartered in the United States, and we may process your information in various countries, including the United States, India, and Argentina. Prabalta.org has implemented appropriate cross-border transfer solutions to provide adequate protection for transfers of certain personal data to the United States, such as execution of the Standard Contractual Clauses for transfers of data from the European Economic Area, United Kingdom, and Switzerland to the United States.
                </p>
              </section>

              <section id="users-outside-us">
                <h2 className="text-2xl font-extrabold mb-4">USERS FROM OUTSIDE THE UNITED STATES</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  The Services are hosted in the United States. If you are visiting the Services from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States and other locations such as Argentina and India where our servers and personnel are located, and our central database is operated. The data protection and other laws of the United States and other countries might not be as comprehensive as those in your country, and data may be accessible to law enforcement and national security authorities under certain circumstances.
                </p>
              </section>

              <section id="your-choices">
                <h2 className="text-2xl font-extrabold mb-4">YOUR CHOICES</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We offer you certain choices regarding the collection, use, and disclosure of information about you.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <em>Account Information.</em> You may have the ability to create an account in the Services. You may verify, correct, update, or delete certain of your information through your account profile page.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <em>Marketing Communications.</em> Each email marketing communication we send you will contain instructions permitting you to “opt out” of receiving future marketing communications. In addition, if at any time you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us at the email address or postal address set forth under “Contacting Prabalta.org”. If you opt out of receiving marketing communications or other information we think may interest you, we can still send you certain emails about your account or any Services you have requested or received from us.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <em> Cookies and Analytics. </em>You can opt out of certain cookie-related and analytics processing by following the instructions in this Notice.</p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <em> Rights to Information About You.</em> Presently, residents of California, Colorado, Virginia, Utah, Connecticut, Nevada, Oregon, Texas, and Montana have certain rights with respect to their information. Depending on your jurisdiction, you may request that we:
                </p>

                <ul className="list-disc ml-6 text-base text-gray-600 mb-8 leading-relaxed">
                  <li>Provide you with information about the categories of personal information we collect or disclose about you; the categories of sources of such information; the business or commercial purpose for collecting such information; and the categories of unaffiliated parties to whom we disclose such personal information. Such information is also set forth in this Notice;</li>
                  <li>Provide access to and/or a copy of certain information we hold about you;</li>
                  <li>Prevent the processing of your information for direct-marketing purposes (including any direct marketing processing based on profiling);</li>
                  <li>Update information which is out of date or incorrect;</li>
                 
                  <li>Delete certain information that we are holding about you, except we are not obliged to do so if we need to retain such data in order to comply with a legal obligation or to establish, exercise or defend legal claims;</li>
<li>Restrict the way that we process and disclose certain information about you, where you believe such data to be inaccurate; our processing is unlawful; or that we no longer need to process such data for a particular purpose unless we are not able to delete the data due to a legal or other obligation or because you do not wish for us to delete it;</li>
                  <li>Restrict the way that we process and disclose certain information about you;</li>
                  <li>Transfer your information to a third-party provider of services;</li>
                  <li>Opt you out of the processing of your personal information for purposes of profiling in furtherance of decisions that produce legal or similarly significant effects, if applicable; and</li>
                  <li>Revoke your consent for the processing of your information provided that the withdrawal of consent shall not affect the lawfulness of the processing based on consent before its withdrawal.</li>
                </ul>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  You may be able to designate an authorised agent to make requests on your behalf.  In order for an authorised agent to be verified, you must provide the authorised agent with signed, written permission to make such requests or a power of attorney.  We may also follow up with you to verify your identity before processing the authorised agent’s request as permitted by applicable law.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Please note that certain information may be exempt from such requests under applicable law.  For example, we may retain certain information for legal compliance and to secure our Services.  We also may need certain information in order to provide the Services to you; if you ask us to delete it, you may no longer be able to use the Services.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  You also have the right not to be discriminated against (as provided for in applicable law) for exercising your rights.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  You may also have the right to opt out of sales of your personal information, or the processing of your personal information for targeted advertising. To opt out of any future targeted advertising we may offer, please use the tools described in the section above on Online Analytics and Tailored Advertising, or in our <a
                    href="https://prabalta.org/cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Cookie Policy
                  </a>. We may “sell” information about you to certain partners such as non-profit organisations to help them understand your likely interests in making charitable or other donations, the potential size of such donations, or your interests in starting peer-to-peer fundraisers, signing up for recurring donations or other similar indicators. To opt out of “sales” of your personal information that do not involve cookies or other online tracking technologies, please complete this <a
                    href="https://prabalta.org/cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Form
                  </a>.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  If you would like information regarding your rights under applicable law or would like to exercise any of them, contact us at the email address or postal address set forth under <a href="https://prabalta.org/cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    “Contacting Prabalta.org”
                  </a>. For your own privacy and security, at our discretion, we may require you to provide your identity before providing the requested information. To protect your privacy and security, we take reasonable steps to verify your identity and requests before granting such requests. If we are unable to verify your identity, we may be unable to respond to your requests.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Depending on applicable law, if we deny your request, you may have the right to appeal our decision to deny your request.  We will provide information about how to exercise that right in our response denying the request.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Regional Privacy Disclosures and Rights. Additional disclosures for residents of the European Economic Area, Australia, United Kingdom, Switzerland, California, Colorado, and Nevada are set forth below in Regional Privacy Disclosures.
                </p>

              </section>

              <section id="regional-privacy-disclosures">
                <h2 className="text-2xl font-extrabold mb-4">REGIONAL PRIVACY DISCLOSURES</h2>

                <h3 className="text-md font-semibold mb-4">Users in Europe and United Kingdom</h3>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  If you are in the European Economic Area (“EEA”), the United Kingdom or Switzerland (collectively “Europe”) and have used the Prabalta.org Services under a contractual relationship under the Terms of Service with Prabalta.org Ireland, then for the purposes of the EU General Data Protection Regulation 2016/679 (the “GDPR”), the UK GDPR , and the Swiss Federal Act of 19 June 1992 on Data Protection (FADP) and the Privacy and Electronic Communications Directive 2002/58/EC on Privacy and Electronic Communications (ePrivacy Directive), the data controller is Prabalta.org Ireland Limited. and can be reached at the address set out at “Contacting Prabalta.org” below; and
                </p>

                <ul className="list-disc ml-6 text-base text-gray-600 mb-8 leading-relaxed">
                  <li>
                    For the Users in the EEA that do not fall under the previous paragraph, and to the extent that this would be required by GDPR, Prabalta.org Ireland also acts as representative in the EU for Prabalta.org Inc.
                  </li>
                </ul>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  If you are located in Europe, our legal grounds for processing your information may be as follows:
                </p>
                <ul className="list-disc ml-6 text-base text-gray-600 mb-8 leading-relaxed">
                  <li><em>Consent:</em> Where you have given consent to such processing for one or more specific purposes.</li>
                  <li><em>Contractual Obligation:</em> Where the processing is necessary for the performance of a contract with you.</li>
                  <li><em>Legal Obligation:</em> Where processing is necessary for compliance with our legal obligation.</li>
                  <li><em>Legitimate Interest:</em> Where processing is necessary for our legitimate interests or the legitimate interests of our users and your interests and fundamental rights and freedoms do not override those interests.</li>
                </ul>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We transfer your personal data to third countries subject to appropriate safeguards, such as standard contractual clauses.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Residents of Europe also have the right to lodge a complaint with the local data protection authority if you believe that we have not complied with applicable data protection laws.  You also have the right to lodge a complaint with the supervisory authority of your residence, place of work or where the incident took place.  However, we encourage you to contact us first, and we will do our very best to resolve your concern.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  The Irish Data Protection Commissioner shall act as the competent lead supervisory authority for users in the European Economic Area, and you have the right to lodge a complaint with your local data protection authority (see list in the link
                  &nbsp;<a
                    href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm
                  </a>
                  ). The Information Commissioner’s Office shall act as the competent supervisory authority for users in the United Kingdom and the Data Protection and Information Commissioner (FDPIC) for Switzerland.
                </p>

                <h3 className="text-md font-semibold mb-4">USERS IN AUSTRALIA</h3>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  If you are a user in Australia, you also have the right to lodge a complaint with the local data protection authority if you believe that we have not complied with applicable data protection laws, and you have the right to lodge a complaint with the supervisory authority of your residence, place of work or where the incident took place.  However, we encourage you to contact us first, and we will do our very best to resolve your concern.
                </p>
                <h3 className="text-md font-semibold mb-4">RESIDENTS OF NEVADA</h3>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  If you are a resident of Nevada, you have the right to opt-out of the sale of certain personal information to unaffiliated parties. You can exercise this right by contacting us as described in the “Contacting Prabalta.org” section below with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address. We do not currently sell your personal information as sales are defined in Nevada Revised Statutes Chapter 603A.
                </p>

                <h3 className="text-md font-semibold mb-4">RESIDENTS OF COLORADO</h3>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Under the California Consumer Privacy Act Notice, for additional disclosures about the purposes for which we use each category of information we collect from and about you.
                </p>


              </section>

              <section id="other-terms">
                <h2 className="text-lg font-semibold mb-4">Other Terms</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Your access to and use of the Services is subject to
                  <a
                    href="https://prabalta.org/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Prabalta.org’s Terms of Service
                  </a>
                  and such other terms, which may be made available to you in connection with your use of the Services.
                </p>
              </section>

              <section id="changes-to-privacy-notice">
                <h2 className="text-lg font-semibold mb-4">Changes to Prabalta.org’s Privacy Notice</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Prabalta.org reserves the right to update or modify this Notice at any time and from time to time. We will notify you of any material updates or changes we make to this Notice. If you disagree with our revisions to the Notice, you can deactivate your account or discontinue the use of our Services. Please review this Notice periodically for any updates or changes to this Notice.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  By using the Services after any such update or modification, you acknowledge that you have read and understood the terms of the Notice as updated or modified.
                </p>
              </section>


              <section id="contacting-prabalta">
                <h4 className="text-lg font-semibold mb-4">Contacting Prabalta.org</h4>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Please note that the Privacy Team and Data Protection Officer are only able to handle data privacy related enquiries. Consequently, if your e-mail concerns anything else, you may not receive a response. For non-privacy related enquiries, please visit the Help Centre.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  To contact our Data Protection Officer, please email  &nbsp;
                  <a href="mailto:dpo@prabalta.org" className="text-blue-500 hover:underline">dpo@prabalta.org</a>.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  To submit a Data Subject Request, please fill out this form or email &nbsp;
                  <a href="mailto:privacy-requests@prabalta.org" className="text-blue-500 hover:underline">privacy-requests@prabalta.org</a>.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  You can also contact us via mail or phone as follows:
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">

                
                  Prabalta.org Inc.
                  <br />
                  3025 Windy Knoll Court
                  <br />
                  Rockville, Maryland 20850
                  <br />
                  United States
                </p>

              </section>


            </main>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />

      {/* Toast notifications */}
      <ToastContainer />
    </div>
  );
};

export default Privacy;
