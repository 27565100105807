import React from "react";

import DonorManagementSideBar from "./DonorManagementSideBar";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { IconEdit, IconEye, IconTrash } from "@tabler/icons-react";
import { Tooltip as RxTooltip } from "@mantine/core";
import Tour from "reactour";
import axios from "axios";
import { Triangle } from "react-loader-spinner";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { ActionIcon, Box, Button } from "@mantine/core";

import { getRequestWithTokenWithoutDataPartner } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import EditFundraiser from "../BrowseFundraiser/EditFundraiser";
import ViewRecieptPopUp from "./ViewRecieptPopUp";
import DonorManagementTop from "./DonorManagementTop";
import MultipleTour from "./MultipleTour";

const AllDonationList = () => {
  const currentStepIndex = localStorage.getItem("tourStepNew");

  const navigate = useNavigate();
  const [isTourOpen, setIsTourOpen] = useState(true);

  const [currentStep, setCurrentStep] = useState(
    parseInt(localStorage.getItem("tourDonationStep") || 0, 10)
  );

  const [donorData, setDonorData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true);

  const [sorting, setSorting] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [value, setValue] = React.useState(0);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [editPopupOpen, setEditPopupOpen] = useState({
    isOpen: false,
    rowData: null,
  });

  const [showReceiptPopup, setShowReceiptPopup] = useState(false);

  const [receiptPopupData, setReceiptPopupData] = useState(null);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const updateRowInData = (updatedRow) => {
    console.log(updatedRow);
  };

  const tourCompletedFlag = localStorage.getItem("donorTourCompletedFlag");
  console.log("donorTourCompletedFlag ", tourCompletedFlag);

  const partnerId = partnerData.partnerId;

  const navigateToaddDonation = async () => {
    console.log("Naviaget to donatin");
    localStorage.setItem("donorID", "null");
    localStorage.setItem("donor", "null");
    const donorname = localStorage.getItem("donor");
    console.log("donor namessssssss", donorname);
    navigate("/addDonations");
  };

  useEffect(() => {
    if (!donorData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getAllDonationList();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting, //refetch when sorting changes
    activeTab,
    columnFilters,
  ]);

  const handleViewReceipt = (rowdata) => {
    console.log("view reciept called ", rowdata.original);
    setShowReceiptPopup(true);
    setReceiptPopupData(rowdata.original);
  };

  const getAllDonationList = async () => {
    console.log(activeTab);
    const url = new URL(`${TQ_URL}/getAllDonationList`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("activeTab", `${activeTab}`);

    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);

    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );

      console.log(response);

      console.log(response.datalist);

      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log(response.datalist);

      setDonorData(dataWithSerialNumber);
      setRowCount(response.totalCount);

      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the all donation list:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    //  setIsRefetching(false);
  };

  const formatCurrency = (value) => {
    if (!value) return "";
    const amount = parseFloat(value);
    if (isNaN(amount)) return "";
    return `$${amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        sortable: true,
      },

      {
        accessorKey: "donorName",
        header: "Donor name",
        sortable: true,
        Cell: ({ cell }) => toInitCap(cell.getValue()),
        Cell: ({ row }) => {
          const title = row.original.donorName;
          return (
            <RxTooltip
              label={title || ""}
              styles={(theme) => ({
                tooltip: {
                  backgroundColor: theme.colors.blue[9],
                  color: theme.white,
                },
              })}
            >
              <span>
                {title && title.length > 15
                  ? `${title.substring(0, 15)}...`
                  : title}
              </span>
            </RxTooltip>
          );
        },
      },

      {
        accessorKey: "fundraiserName",
        header: "Fundraiser",
        sortable: true,
        Cell: ({ cell }) => toInitCap(cell.getValue()),
        Cell: ({ row }) => {
          const title = row.original.fundraiserName;
          return (
            <RxTooltip
              label={title || ""}
              styles={(theme) => ({
                tooltip: {
                  backgroundColor: theme.colors.blue[9],
                  color: theme.white,
                },
              })}
            >
              <span>
                {title && title.length > 15
                  ? `${title.substring(0, 15)}...`
                  : title}
              </span>
            </RxTooltip>
          );
        },
      },
      {
        accessorKey: "causeNames",
        header: "Cause Name",
        sortable: true,
        Cell: ({ cell }) => toInitCap(cell.getValue()),
        Cell: ({ row }) => {
          const title = row.original.causeNames;
          return (
            <RxTooltip
              label={title || ""}
              styles={(theme) => ({
                tooltip: {
                  backgroundColor: theme.colors.blue[9],
                  color: theme.white,
                },
              })}
            >
              <span>
                {title && title.length > 15
                  ? `${title.substring(0, 15)}...`
                  : title}
              </span>
            </RxTooltip>
          );
        },
      },
      {
        accessorKey: "donatedAmt",
        header: "Donated amount",
        sortable: true,
        Cell: ({ cell }) => <Box>{formatCurrency(cell.getValue())}</Box>,
      },
      {
        accessorKey: "donationDate",
        header: "Donation Date",
        sortable: true,
      },
    ],
    []
  );

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      console.log("searchText:", searchText);

      const url = new URL(`${TQ_URL}/searchImportedDonationList`);
      url.search = new URLSearchParams();
      url.searchParams.set("start", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("activeTab", `${activeTab}`);
      url.searchParams.set("keyword", `${searchText}`);
      url.searchParams.set("partnerId", `${partnerData.partnerId}`);
      url.searchParams.set("pageNo", 1);

      try {
        const response = await getRequestWithTokenWithoutDataPartner(
          url.toString()
        );

        //  setDonationData(response);
        console.log(response);

        console.log(response.dataList);

        const dataWithSerialNumber = response.dataList.map((item, index) => ({
          ...item,
          id: index + 1,
        }));

        console.log(response.dataList);

        setDonorData(dataWithSerialNumber);
        setRowCount(response.totalCount);

        setIsRefetching(false);
      } catch (error) {
        console.error("Error in fetching the searched donor's history:", error);
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
  };
  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleEdit = (row) => {
    console.log("inside edit");
    console.log(activeTab);

    console.log(row);

    navigate("/editDonations", { state: row });
  };

  const handleTabs = (e, value) => {
    console.log(e);
    setValue(e);
    console.log("Value:", value);
    if (e == 0) {
      setActiveTab(1);
      //  getAllDonationsDonorList(donorIdd);
      console.log("data from imported donors");
    } else {
      console.log("data from website donors");
      setActiveTab(2);
      //  getAllDonationsDonorList();
    }
  };

  const table = useMantineReactTable({
    columns,
    data: donorData,

    initialState: { showColumnFilters: false },
    rowCount,

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,

    enableRowActions: true,
    positionActionsColumn: "last",

    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },

    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
        <Button onClick={() => handleViewReceipt(row)} variant="outline">
          View Receipt
        </Button>

        {activeTab == 1 && (
          <ActionIcon
            color="orange"
            onClick={() => {
              handleEdit(row.original);
            }}
          >
            <IconEdit />
          </ActionIcon>
        )}
      </Box>
    ),
  });

  return (
    <>
      <DonorManagementTop />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user overflow-hidden">
          <div className="mx-auto shadow-md">
            <div className="flex flex-col md:flex-row justify-between items-center container px-10 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">All Donation List</h2>
              </div>
              <div className="flex flex-row items-center md:ml-4 mt-4 md:mt-0 md:flex-2">
                <div className="md:mx-2 mx-2 py-2 md:py-0">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="py-2 pr-8 pl-4 block rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div
                  id="donation-addDonation"
                  className="md:mx-2 mx-2 py-2 md:py-0"
                >
                  <button
                    className="btn-style-1 text-white py-2 px-4 rounded "
                    onClick={navigateToaddDonation}
                  >
                    +Add
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container md:mx-auto md:py-16 py-6">
            {/*   <div sx={{ width: "100%" }}>*/}
            <div style={{ borderBottom: "1px solid #ddd" }}>
              <Tabs
                value={value}
                onChange={(event, newValue) => handleTabs(newValue)}
                aria-label="basic tabs example"
              >
                <Tab id="donation-step2" label="Imported Donations" value={0} />
                <Tab id="donation-step3" label="Website Donations" value={1} />
              </Tabs>
            </div>

            <div className="p-4">
              <MantineReactTable table={table} />
            </div>

            {editPopupOpen.isOpen && (
              <EditFundraiser
                onClose={() => {
                  setEditPopupOpen({ isOpen: false });
                }}
                fundraiserData={editPopupOpen.rowData}
                onUpdateRow={updateRowInData}
              />
            )}

            {showReceiptPopup && (
              <ViewRecieptPopUp
                onClose={() => {
                  setShowReceiptPopup(false);
                  setReceiptPopupData(null);
                }}
                rowData={receiptPopupData}
                activeTab={activeTab}
              />
            )}
          </div>
        </main>

        {tourCompletedFlag === "false" && isLargeScreen && <MultipleTour />}
      </div>
    </>
  );
};
export default AllDonationList;
