import React, { useState } from 'react';
import OpenModal from './OpenModal'; // Assuming OpenModal component is in the same directory

const ParentComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <h1>Calendar Example</h1>
      <button
        onClick={openModal}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Open Modal
      </button>
      {isModalOpen && <OpenModal onClose={closeModal} />}
    </div>
  );
};

export default ParentComponent;
