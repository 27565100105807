import React, { useEffect } from "react";
import { ACCESS_TOKEN } from "../../utility/baseUrl";
import { useNavigate, useLocation } from "react-router-dom";
import { getRequestWithTokenWithoutData } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";

const OAuth2RedirectHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const donationData = JSON.parse(localStorage.getItem("donationData"));
  console.log(donationData);

  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const token = getUrlParameter("token");
  const error = getUrlParameter("error");
  const flag = getUrlParameter("flag");
  const email = getUrlParameter("email");
  const userType = getUrlParameter("userType");

  const fetchOauthData = async () => {
    try {
      const response = await getRequestWithTokenWithoutData(
        `${TQ_URL}/getOauthProfile`
      );

      // Determine the user type and set data in localStorage accordingly
      if (response.userType === "Individual") {
        const userDetails = response.details;
        localStorage.setItem(
          "userData",
          JSON.stringify({
            userId: userDetails.userId,
            userName: userDetails.userName,
            email: userDetails.email,
            phone: userDetails.phone,
            userType: response.userType,
            imageUrl: userDetails.imageUrl,
          })
        );
      } else if (response.userType === "Partner") {
        const partnerDetails = response.details;
        const userDetails = response.userPartner;
        localStorage.setItem(
          "partnerData",
          JSON.stringify({
            partnerId: partnerDetails.partnerId,
            orgName: partnerDetails.orgName,
            contactPersonName: partnerDetails.contactPersonName,
            email: partnerDetails.email,
            contactNumber: partnerDetails.contactNumber,
            userType: response.userType,
            imageUrl: partnerDetails.imageUrl,
            addressOne: partnerDetails.addressOne,
            addressTwo: partnerDetails.addressTwo,
            city: partnerDetails.city,
            state: partnerDetails.state,
            zipCode: partnerDetails.zipCode,
            role: "partner",
          })
        );
        localStorage.setItem(
          "userData",
          JSON.stringify({
            userId: userDetails.userId,
            userName: userDetails.userName,
            email: userDetails.email,
            phone: userDetails.phone,
            userType: response.userType,
            imageUrl: userDetails.imageUrl,
          })
        );
      }
      return response;
    } catch (error) {
      console.error("Error fetching OAuth profile:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        localStorage.setItem(ACCESS_TOKEN, token);
        localStorage.setItem("email", email);
        localStorage.setItem("userType", userType);

        try {
          const response = await fetchOauthData();
          localStorage.setItem(
            "userAddressDetails",
            JSON.stringify({ addressDetails: response.addressDetail })
          );

          const localStorageData =
            userType === "Individual"
              ? JSON.parse(localStorage.getItem("userData"))
              : JSON.parse(localStorage.getItem("partnerData"));

          localStorage.setItem("OauthFlag", flag);

          if (userType === "Individual") {
            if (response.addressDetail === null) {
              navigate("/addaddressdetails-user");
            } else {
              // navigate("/home");
              if (!donationData) {
                navigate("/home"); // Redirect to home if donationData is null
              } else {
                // navigate("/donate"); // Redirect to donate if donationData is not null
                navigate("/donate", { state: donationData });
                localStorage.removeItem("donationData");
              }
            }
          } else if (userType === "Partner") {
            navigate("/services-dashboard", { state: { from: location } });
          } else if (flag == 1) {
            navigate(
              `/add-ngo-addressdetails?email=${encodeURIComponent(
                localStorageData.email
              )}&userType=${encodeURIComponent(localStorageData.userType)}`
            );
          } else {
            navigate("/services-dashboard", { state: { from: location } });
          }
        } catch (error) {
          console.error("Error during OAuth2 process:", error);
        }
      } else if (error) {
        navigate("/ngo-login", { state: { from: location, error } });
      }
    };
    fetchData();
  }, [error, navigate, location, token, flag, email, userType]);

  // The component doesn't render any JSX directly
  return null;
};

export default OAuth2RedirectHandler;
