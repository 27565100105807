import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "./Footer";
import Header from "./Header";
import { TQ_URL } from "../../utility/baseUrl";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import { isSocial } from "../../utility/baseUrl";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "../Common/Tooltip";
import { Triangle } from "react-loader-spinner"; // Import Triangle loader
import { Helmet } from "react-helmet";
import { Accordion, Text, ThemeIcon } from "@mantine/core";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import HowWeWorkModal from "../HowWeWork/HowWeWorkModal";
import ReceiptPopup from "../Common/ReceiptPopup";
import HowWeWork from "../HowWeWork/HowWeWork";
import AddBankDetails from "../Profile/AddBankDetails";
import AddCausePopup from "../Cause/AddCausePopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
import TestimonialsandBlogSec from "../HomeNew/TestimonialsandBlogSec";
import Cookies from "js-cookie";
import LiveFeeds from "./LiveFeeds";

const FAQData = [
  {
    question:
      "What services will Prabalta provide to non-profit organizations?",
    answer:
      " Prabalta will provide a comprehensive range of services, including IT infrastructure, a secure payment platform, donor management systems, analytics and reporting, email communication tools, social media integration, and subdomain creation. These services will be designed to empower non-profits to efficiently manage their operations and enhance their online presence.",
  },
  {
    question:
      "When and how can my organization start using Prabalta's services?",
    answer:
      " Prabalta's non-profit organization oriented services are being finalized. To start using  Prabalta 's services, you will be able sign up on our platform and choose the specific services your organization needs. Once registered, you'll have access to our tools, including domain creation, email campaign management, and social media scheduling, all tailored to support your organization's goals.",
  },
  {
    question: "Is it possible to set up recurring donations on Prabalta ?",
    answer:
      "Yes, Prabalta allows donors to set up recurring donations. For individual donors, the details are provided under “How we work”. For non-profits, this feature will ensure that your organization receives consistent support, helping you plan and execute your projects more effectively.",
  },
  {
    question: "How does  Prabalta ensure the security of online donations?",
    answer:
      " Prabalta  prioritizes the security of online transactions by using advanced encryption and secure payment gateways. Our platform is designed to protect donor information and ensure that all transactions are safe and reliable, giving both non-profits and donors peace of mind.",
  },
  // {
  //   question:
  //     "Can businesses or organizations outside of the non-profit sector use Prabalta.org's IT services?",
  //   answer:
  //     "Absolutely. While Prabalta.org is dedicated to supporting non-profits, we also offer our IT services to businesses and organizations in other sectors. Our ready-made software solutions, email campaign tools, and social media scheduling services are available to help any organization enhance their digital presence and operational efficiency.",
  // },
];

const HomePage = () => {
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };
  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const shareText = "Check out this fundraiser on my website!";
  const [counter, setCounter] = useState(1);
  const [topFundraiser, setTopFundraiser] = useState([]);
  const [topFundLoading, setTopFundLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const isMounted = useRef(true);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const [showReceiptPopup, setShowReceiptPopup] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [receiptPopupData, setReceiptPopupData] = useState(null);
  const [config, setConfig] = useState({}); // State to store config data
  const [pageBaseUrl, setPageBaseUrl] = useState("");

  const pageTitle = "Your Page Title";
  const pageDescription = "Description of your content";

  var pageUrl;
  useEffect(() => {
    AOS.init();
  }, []);

  const [openedIndex, setOpenedIndex] = useState(null);

  const handleAccordionChange = (index) => {
    setOpenedIndex(openedIndex === index ? null : index);
  };

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        const baseUrl = data.redirect_uri.replace(
          "oauth2/redirect",
          "fundraiser"
        );
        setPageBaseUrl(baseUrl);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    // Check if the cookie exists
    const hasSeenModal = Cookies.get("hasSeenHowWeWorkModal");
    const isOnHomepage = window.location.pathname === "/";

    // Show the modal if the user hasn't seen it before and is on the homepage
    if (!hasSeenModal && isOnHomepage) {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    if (config.partnerId) {
      const showModalFlag = sessionStorage.getItem("showModal");

      if (!showModalFlag || showModalFlag === "false") {
        //setShowModal(true);
        console.log("found");
      } else {
        console.log("not found");
      }

      window.scrollTo(0, 0);
      if (isMounted.current) {
        //fetchLiveFeeds();
        fetchTopFundraiserList();
        isMounted.current = false;
      }

      console.log(showModal);
    }
  }, [config.partnerId]);

  const closeModal = () => {
    setShowModal(false);
    sessionStorage.setItem("showModal", "true");
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter < 10) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [counter]);

  const shareOnWhatsApp = (fundraiserId) => {
    const pageUrl = `${pageBaseUrl}/${fundraiserId}`;

    const shareText = "Check out this fundraiser on my website!";

    console.log("share on whats app called");
    // Replace 'your-share-url' with the actual URL you want to share
    const whatsappLink = `https://api.whatsapp.com/send?text=${`${shareText} ${pageUrl}`}`;

    // Open a new window with the WhatsApp share link
    window.open(whatsappLink, "_blank");
  };

  const shareOnTwitter = (fundraiserId) => {
    console.log("sharing the content on twitter");
    const pageUrl = `${pageBaseUrl}/${fundraiserId}`;
    const shareText = encodeURIComponent(
      "Check out this fundraiser on my website!"
    );
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareText}&url=${encodeURIComponent(
      pageUrl
    )}`;

    window.open(twitterShareUrl, "_blank");
  };
  const shareOnFacebook = (fundraiserId) => {
    const pageUrl = `${pageBaseUrl}/${fundraiserId}`;
    const shareText = encodeURIComponent(
      "Check out this fundraiser on my website!"
    );

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      pageUrl
    )}&quote=${shareText}`;

    //   `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pageUrl)}&quote=${shareText}`

    window.open(facebookLink, "_blank");
  };

  const fetchTopFundraiserList = async () => {
    try {
      const partnerId = config.partnerId;
      if (!partnerId) {
        console.error("Partner ID is not defined");
        toast.error("Partner ID is not defined. Please contact support.");
        setTopFundLoading(false);
        return;
      }
  
      // Get the browser's timezone
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
      const response = await axios.get(
        `${TQ_URL_USPROJECT}/getTopFundraiserList`,
        {
          params: {
            partnerId: partnerId,
            timeZone: browserTimeZone, // Add the timezone parameter
          },
        }
      );
  
      console.log(response.data);
      if (response.status === 200) {
        setTopFundraiser(response.data);
      } else if (response.status === 404) {
        // toast.error("Fundraiser list not available.");
      } else {
        toast.error("An unexpected error occurred. Please try again later.");
      }
  
      setTopFundLoading(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.error(
          "Error fetching top fundraiser list:",
          error.response.data
        );
        //  toast.error("An error occurred: " + error.response.data);
      } else {
        console.error("Error fetching top fundraiser list:", error.message);
        //    toast.error("An unexpected error occurred. Please try again later.");
      }
      setTopFundLoading(false);
    }
  };
  

  const sliderSettingsFundarisers = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: Math.min(3, topFundraiser.length),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // hide live feed

  const [lastScrollTop, setLastScrollTop] = useState(0);

  const [footer, setFooter] = useState(null);

  function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const handleDonateNowClick = () => {
    if (localStorage.getItem("accessToken") !== null) {
      navigate("/donate");
    } else {
      navigate("/login");
    }
  };

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play(); // Play video if paused
    } else {
      videoRef.current.pause(); // Pause video if playing
    }
  };

  return (
    <div>
      <Header />

      <HowWeWorkModal showModal={showModal} onClose={closeModal} />
      {/* )} */}

      {/* Banner Section */}

      <div className="landing-page-banner-sectionPrabalta text-white px-8 flex  w-full ">
        <div className=" w-full  text-center">
          <h1
            className="text-black lg:text-3xl md:text-2xl font-semibold  font-family-poppins mb-6 mt-4 leading-tight"
          // style={{ fontFamily: "Georgia, 'Times New Roman', serif" }}
          >
            Looking to Make a Donation for a Worthy Cause?
          </h1>
          <p className="banner-text-1  font-extralight mb-10 font-family-poppins">
            You are in the right place – Prabalta empowers your donation to
            <br />
            make a difference!
          </p>
        </div>
      </div>

      {/* <div className="landing-page-banner-sectionPrabaltaMobile text-white  flex  w-full ">
        
        
        <div className=" w-full  text-center p-1">
          <h1
            className="text-black mobileText font-semibold  font-family-poppins mb-5 leading-tight"
            // style={{ fontFamily: "Georgia, 'Times New Roman', serif" }}
          >
            Looking to Make a Donation for a <br /> Worthy Cause?
          </h1>
          <p className="banner-text-1  font-extralight mb-10 font-family-poppins">
            You are in the right place – Prabalta empowers your donation to
            <br />
            make a difference!
          </p>
        </div>
      </div> */}

      <div className="landing-page-banner-sectionPrabaltaMobile  flex flex-col justify-center items-center text-white w-full">
        <img
          src="/assets/images/m1Copy.png"
          alt="Default Image"
          width={"97%"}
        />

        <div className=" w-full  text-center p-1 textContainerBg">
          <h1
            className="text-black mobileText font-semibold  font-family-poppins mb-5 leading-tight"
          // style={{ fontFamily: "Georgia, 'Times New Roman', serif" }}
          >
            Looking to Make a Donation for a <br /> Worthy Cause?
          </h1>
          <p className="banner-text-1  font-extralight mb-10 font-family-poppins">
            You are in the right place – Prabalta empowers your donation to
            <br />
            make a difference!
          </p>
        </div>
      </div>

      <div className="video-sectionUser flex justify-center relative">
        <video
          ref={videoRef}
          src="/assets/videos/prabaltauser.mp4"
          controls
          poster="/assets/videos/thumbuser.jpg"
          className="w-full md:w-4/5 lg:w-2/3 rounded-2xl shadow-lg"
          onPlay={() => setIsPlaying(true)} // Update isPlaying state when the video plays
          onPause={() => setIsPlaying(false)} // Update isPlaying state when the video pauses
        >
          Your browser does not support the video tag.
        </video>

        {!isPlaying && (
          <div
            className="absolute inset-0 flex items-center justify-center cursor-pointer"
            onClick={handlePlayPause} // Button click will handle play/pause toggle
          >
            <button className="bg-gray-800 bg-opacity-50 text-white p-4 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6.293 4.293a1 1 0 011.414 0l7.586 7.586a1 1 0 010 1.414l-7.586 7.586a1 1 0 01-1.414-1.414L11.172 12 5.293 6.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        )}
      </div>

      <LiveFeeds config={config} />

      {/* Crowdfunding Categories Section */}
      {/* <div className="p-8 flex flex-col items-center pb-20">
        <h2 className="txt-style-7 mb-6 font-family-poppins text-center">
          Crowdfunding Categories
        </h2>

      
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-10">
       
          <div className="text-center icon-box">
            <img
              src="/assets/images/healthcare.png"
              alt="Logo"
              className="h-14"
            />
            <p className="text-sm font-semibold">Health Care</p>
          </div>

       
          <div className="text-center icon-box">
            <img src="/assets/images/animal.png" alt="Logo" className="h-14" />
            <p className="text-sm font-semibold">Animal</p>
          </div>
          <div className="text-center icon-box">
            <img src="/assets/images/sport.png" alt="Logo" className="h-14" />
            <p className="text-sm font-semibold">Sport</p>
          </div>

          <div className="text-center icon-box">
            <img src="/assets/images/user.svg" alt="Logo" className="h-14" />
            <p className="text-sm font-semibold">Personal Cause</p>
          </div>

          <div className="text-center icon-box">
            <img
              src="/assets/images/education.png"
              alt="Logo"
              className="h-14"
            />
            <p className="text-sm font-semibold">Education</p>
          </div>

          <div className="text-center icon-box">
            <img src="/assets/images/food.png" alt="Logo" className="h-14" />
            <p className="text-sm font-semibold">Food</p>
          </div>
        </div>
      </div> */}

      {/* Live Feed Section*/}

      {/* New Section Below the "Live Feeds" */}
      {/* <div className=" md:p-4  p-5 flex flex-col md:flex-row items-center justify-center md:py-4">
        <div className="2xl:w-2/5  w-full md:w-1/3 md:pr-8 relative ">
          <img
            src="/assets/images/newCroppedImg.png"
            alt="Image"
            className="object-cover mb-6 md:mb-0 below-live-feed-img w-full h-auto"
          />
          <div className="counter-overlay  bottom-16 left-16 right-0 bg-blue-500 text-white text-center p-2">
            <p className="text-4xl font-bold ">
              {counter}+{" "}
              <p className="text-xs font-semibold blue-box-text">
                Happy Clients{" "}
              </p>
            </p>
          </div>
        </div>

        <div className="2xl:w-1/3 md:w-1/2 flex flex-col items-start md:text-left ">
          <h2 className="text-lg md:text-2xl font-semibold mb-4">
            Contribute Monthly
            <br />
            To Preserve Innumerable Lives
          </h2>
          <p className="text-black font-medium md:mb-10 mb-5 text-sm md:text-md">
            "Giving Every Month" is a monthly subscription program specifically
            crafted to support patients in obtaining critical and life-saving
            medical care promptly.
          </p>
          <button
            className="btn-style-1 py-2 text-white px-6 rounded mb-4"
            onClick={handleDonateNowClick}
          >
            Give Every Month
          </button>
          <div className="counter-with-text">
            <p className="md:text-2xl font-bold mb-2 italic">
              <span className="orange-txt ">100%</span> Transparency
            </p>
          </div>
        </div>
      </div> */}

      <div className="top-fundraiser-sec p-8 flex flex-col items-center pb-20">
        <h2 className="txt-style-7 mb-8 font-family-poppins text-center">
          Our Recent Fundraisers
        </h2>
        {topFundLoading ? (
          <div className="flex items-center justify-center h-[200px]">
            <div className="flex items-center justify-center h-[200px] w-full md:pr-20">
              <Triangle
                height="120"
                width="120"
                color="blue"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                visible={true}
              />
            </div>
          </div>
        ) : topFundraiser.length === 0 ? (
          <p className="mx-auto text-center text-lg font-semibold mt-5">
            No Data Found
          </p>
        ) : (
          <Slider {...sliderSettingsFundarisers} className="container">
            {topFundraiser.map((data, index) => (
              <Link to={`/fundraiser/${data.fundraiserId}`}>
                <div
                  key={index}
                  className="donation-box text-center !w-11/12 md:!w-4/5 md:mx-10 mx-2 mb-10"
                >
                  {/* Image */}
                  {/* Check if the image exists in the data.imageList array */}
                  {data.imageList && data.imageList.length > 0 ? (
                    <img
                      // src={`assets/USNP_Document/${data.imageList[0]}`}
                      src={data.imageList[0]}
                      alt="Data Image"
                      className="w-full object-cover mb-6 h-[210px] rounded-xl"
                      onError={(e) => {
                        e.target.src = "/assets/images/defaultFund.jpg"; // Clear src to avoid displaying broken image icon
                      }}
                    />
                  ) : (
                    <img
                      src="/assets/images/defaultFund.jpg"
                      alt="Default Image"
                      className="w-full object-cover mb-6 h-[210px] rounded-xl"
                    />
                  )}
                  {/* Text with Center Alignment */}
                  <div className="text-center px-10">
                    <p className="description-text text-gray-500 mb-4 px-10 truncate">
                      {" "}
                      <span className="font-bold">
                        {data.fundraiserName}
                      </span>{" "}
                      <br />
                      {data.title.length < 25 ? data.title : data.title.substring(0, 25) + '...'}
                    </p>

                    {/* Social Icons */}
                    {/* Gamil Icon */}
                    <Helmet>
                      <meta charSet="utf-8" />
                      <meta name="description" content={pageDescription} />
                      <meta property="og:title" content={data.title} />
                      <meta
                        property="og:description"
                        content={pageDescription}
                      />
                      <meta
                        property="og:image"
                        content="https://prabalta.s3.eu-north-1.amazonaws.com/202312251/add-details-self-bg.jpg"
                      />
                      <meta property="og:image:width" content="500" />{" "}
                      <meta property="og:image:height" content="630" />
                      <meta content="image/*" property="og:image:type" />
                      <meta property="og:url" content={pageUrl} />
                    </Helmet>
                    <div className="pb-6 flex justify-center items-center space-x-3 social-icon-box">
                      {/* twitter Icon */}

                      <Tooltip text="Share on Twitter">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 72 72"
                          viewBox="0 0 72 72"
                          className="w-8"
                          id="twitter-x"
                          onClick={() => {
                            shareOnTwitter(data.fundraiserId);
                          }}
                        >
                          <switch>
                            <g>
                              <path
                                d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66
              h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"
                              ></path>
                            </g>
                          </switch>
                        </svg>
                      </Tooltip>

                      <div>
                        <Tooltip text="Share on WhatsApp">
                          <svg
                            viewBox="0 0 48 48"
                            className="w-6"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            fill="#000000"
                            onClick={() => {
                              shareOnWhatsApp(data.fundraiserId);
                            }}
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <title>Whatsapp-color</title>{" "}
                              <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                              <g
                                id="Icons"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                {" "}
                                <g
                                  id="Color-"
                                  transform="translate(-700.000000, -360.000000)"
                                  fill="#67C15E"
                                >
                                  {" "}
                                  <path
                                    d="M723.993033,360 C710.762252,360 700,370.765287 700,383.999801 C700,389.248451 701.692661,394.116025 704.570026,398.066947 L701.579605,406.983798 L710.804449,404.035539 C714.598605,406.546975 719.126434,408 724.006967,408 C737.237748,408 748,397.234315 748,384.000199 C748,370.765685 737.237748,360.000398 724.006967,360.000398 L723.993033,360.000398 L723.993033,360 Z M717.29285,372.190836 C716.827488,371.07628 716.474784,371.034071 715.769774,371.005401 C715.529728,370.991464 715.262214,370.977527 714.96564,370.977527 C714.04845,370.977527 713.089462,371.245514 712.511043,371.838033 C711.806033,372.557577 710.056843,374.23638 710.056843,377.679202 C710.056843,381.122023 712.567571,384.451756 712.905944,384.917648 C713.258648,385.382743 717.800808,392.55031 724.853297,395.471492 C730.368379,397.757149 732.00491,397.545307 733.260074,397.27732 C735.093658,396.882308 737.393002,395.527239 737.971421,393.891043 C738.54984,392.25405 738.54984,390.857171 738.380255,390.560912 C738.211068,390.264652 737.745308,390.095816 737.040298,389.742615 C736.335288,389.389811 732.90737,387.696673 732.25849,387.470894 C731.623543,387.231179 731.017259,387.315995 730.537963,387.99333 C729.860819,388.938653 729.198006,389.89831 728.661785,390.476494 C728.238619,390.928051 727.547144,390.984595 726.969123,390.744481 C726.193254,390.420348 724.021298,389.657798 721.340985,387.273388 C719.267356,385.42535 717.856938,383.125756 717.448104,382.434484 C717.038871,381.729275 717.405907,381.319529 717.729948,380.938852 C718.082653,380.501232 718.421026,380.191036 718.77373,379.781688 C719.126434,379.372738 719.323884,379.160897 719.549599,378.681068 C719.789645,378.215575 719.62006,377.735746 719.450874,377.382942 C719.281687,377.030139 717.871269,373.587317 717.29285,372.190836 Z"
                                    id="Whatsapp"
                                  >
                                    {" "}
                                  </path>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        </Tooltip>
                      </div>

                      {/* Facebook Icon */}

                      <Tooltip text="Share on Facebook">
                        <svg
                          viewBox="-5 0 20 20"
                          className="w-6"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          fill="#000000"
                          onClick={() => {
                            shareOnFacebook(data.fundraiserId);
                          }}
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <title>facebook [#176]</title>{" "}
                            <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                            <g
                              id="Page-1"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              {" "}
                              <g
                                id="Dribbble-Light-Preview"
                                transform="translate(-385.000000, -7399.000000)"
                                fill="#4267b2"
                              >
                                {" "}
                                <g
                                  id="icons"
                                  transform="translate(56.000000, 160.000000)"
                                >
                                  {" "}
                                  <path
                                    d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                                    id="facebook-[#176]"
                                  >
                                    {" "}
                                  </path>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>
                          </g>
                        </svg>
                      </Tooltip>
                    </div>

                    {/* Donation Information */}
                    {/* Donation Information */}
                    <p className="donation-info-text mb-2 flex justify-between">
                      <div>
                        <span className="donation-info-numbers">
                          {"$"}
                          {Math.round(data.amtAchieved).toLocaleString("en-US")}{" "}
                        </span>
                        Raised
                      </div>
                      <span className="donation-info-numbers">
                        {Math.round(data.amountAchivedInPercetage)}%
                      </span>
                    </p>


                    {/* Progress Bar */}
                    <div className="progress-bar-green mb-2">
                      <div
                        className="progress-bar-fill-green rise-in-left"
                        style={{
                          width: `${(counter / 10) * data.amountAchivedInPercetage
                            }%`,
                        }}
                      />
                    </div>

                    {/* Donate Button */}
                    <Link to={`/fundraiser/${data.fundraiserId}`}>
                      <button className="btn-style-1 py-2 text-white px-6 rounded my-4 ">
                        Donate Now
                      </button>
                    </Link>
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
        )}
      </div>

      {/* <div className="mt-4 divColor2">
        <TestimonialsandBlogSec />
      </div> */}

      <div>
        <h2 className="text-4xl font-bold mb-4 text-center md:py-10 py-4">
          FAQ's
        </h2>

        <div className="container md:mx-auto pb-20 px-4">
          <div className="faq-container">
            <Accordion
              multiple={false}
              transitionDuration={1200} // Adjust the duration for smoother animation
              chevron={null}
            >
              {FAQData.map((faq, index) => (
                <Accordion.Item
                  key={index}
                  value={faq.question}
                  onClick={() => handleAccordionChange(index)}
                  styles={{
                    chevron: { display: "none" }, // This hides the default chevron icon
                  }}
                >
                  <Accordion.Control
                    icon={null}
                    styles={{
                      chevron: { display: "none" }, // Hides the chevron inside the control
                    }}
                  >
                    <div className="faq-question flex justify-between items-center">
                      <Text className="text-base md:text-xl">
                        {faq.question}
                      </Text>
                      <span
                        className={`text-4xl faq-icon ${openedIndex === index
                            ? "opened text-blue-500"
                            : "text-blue-500"
                          }`}
                      >
                        {openedIndex === index ? "-" : "+"}
                      </span>
                    </div>
                  </Accordion.Control>
                  <Accordion.Panel className=" faq-answer">
                    {faq.answer}
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
