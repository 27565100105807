import React, { useState, useEffect } from "react";
import TopHeader from "../Home/TopHeader";
import SocialMediaSideBar from "./SocialMediaSideBar";
import CircleAnalyticsComponent from "./CircleAnalyticsComponent";
import {
  getRequestWithTokenWithData,
  getTypeRequestWithTokenWithDataWithAuth,
} from "../../utility/apiRequest";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { TQ_URL } from "../../utility/baseUrl";
import SocialMediaTour from "./SocialMediaTour";
import axios from "axios";

const SocialMediaAnalytics = () => {
  const [selectedChannelIndex, setSelectedChannelIndex] = useState(null);
  const [connectedChannels, setConnectedChannels] = useState([]);
  const [posts, setPosts] = useState([]);

  const tourCompletedFlag = localStorage.getItem("socialTourCompletedFlag");

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId;

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

 

  const fetchPosts = async (connectedChannelsId) => {
    try {
      const response = await getTypeRequestWithTokenWithDataWithAuth(
        `${TQ_URL}/api/social-media/posts?connectedChannelsId=${connectedChannelsId}`
      );
      console.log(response);
      setPosts(response.data || []); // Ensure the response data is set as an array
    } catch (error) {
      console.error("Error fetching posts:", error);
      setPosts([]); // Set an empty array on error to avoid issues with .map
    }
  };

  const handleItemClick = (index, connectedChannelsId) => {
    setSelectedChannelIndex(index);
    fetchPosts(connectedChannelsId);
  };

  useEffect(() => {
    const fetchConnectedChannels = async () => {
      try {
        const partnerData = JSON.parse(localStorage.getItem("partnerData"));
        const partnerId = partnerData.partnerId;
        const response = await getRequestWithTokenWithData(
          `${TQ_URL}/connected-channels/${partnerId}`
        );
        console.log(response);
        setConnectedChannels(response);
      } catch (error) {
        console.error("Error fetching connected channels:", error);
      }
    };

    fetchConnectedChannels();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "N/A" : date.toDateString();
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed">
          <SocialMediaSideBar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px]">
          <div className="mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Social media Analytics</h2>
              </div>
            </div>
          </div>
          {connectedChannels.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-64">
              <p className="text-xl font-bold text-gray-500">
                No channels are connected
              </p>
            </div>
          ) : (
            <>
              <div>
                <h2 className="text-lg md:mt-4 md:ml-8 ml-4 mb-4 font-bold md:py-4 py-2 border-b border-gray-300">
                  Connected Channels
                </h2>
                <div className="flex md:mt-8 mb-2 md:ml-8">
                  {connectedChannels.map((channel, index) => (
                    <CircleAnalyticsComponent
                      key={channel.connectedChannelsId}
                      bigCircleImageUrl={channel.pageImageUrl}
                      channelName={channel.socialMediaConnector.channelName}
                      isSelected={index === selectedChannelIndex}
                      onClick={() =>
                        handleItemClick(index, channel.connectedChannelsId)
                      }
                    />
                  ))}
                </div>
              </div>

              {selectedChannelIndex === null ? (
                <div className="flex flex-col items-center justify-center h-64">
                  <p className="text-xl font-bold text-gray-500">
                    Please select channels to check analytics
                  </p>
                </div>
              ) : (
                <>
                  <h2 className="text-2xl mx-8 md:mt-4 md:ml-8 mb-4 md:py-2 md:mb-2 font-bold text-center">
                    Posts
                  </h2>

                  <div className="md:mt-[20px] flex flex-wrap justify-center">
                    {posts.map((data, index) => (
                      <div
                        key={index}
                        className={`analytics md:mx-4 md:mt-8 md:ml-[20px] text-center md:w-1/2 md:w-1/4 lg:w-1/5 mx-2 mb-10 hover:bg-gray-100 transition duration-300 ease-in-out`}
                      >
                        <div className="analytics-box">
                          <p className="description-text text-gray-500 mb-4 mt-2 md:mx-2 font-bold">
                            {data.message?.length > 15
                              ? `${data.message.slice(0, 15)}...`
                              : data.message || data.caption}
                          </p>
                        </div>
                        <div className="flex justify-center items-center">
                          {data.full_picture || data.media_url ? (
                            <img
                              src={data.full_picture || data.media_url}
                              alt="Data Image"
                              className="w-[80%] object-cover mb-6 h-[170px] rounded-md"
                            />
                          ) : (
                            <img
                              src="/assets/images/fundraiser-1.jpg"
                              alt="Default Image"
                              className="w-full object-cover mb-10 rounded-t-3xl h-[170px]"
                            />
                          )}
                        </div>

                        <div className="analytics-box md:mb-2">
                          <p className="description-text text-gray-500 mb-2">
                            Reach :{" "}
                            <span className="font-bold">
                              {data.reach || "N/A"}
                            </span>
                          </p>
                        </div>

                        <div className="analytics-box md:mb-2">
                          <p className="description-text text-gray-500 mb-2">
                            Eng. Rate :{" "}
                            <span className="font-bold">
                              {data.engRate || "N/A"}
                            </span>
                          </p>
                        </div>

                        <div className="analytics-box md:mb-2">
                          <p className="description-text text-gray-500 mb-2">
                            Total likes :{" "}
                            <span className="font-bold">
                              {data.likes?.summary?.total_count ||
                                data.like_count}
                            </span>
                          </p>
                        </div>
                        <div className="analytics-box md:mb-2">
                          <p className="description-text text-gray-500 mb-2">
                            Total Comments :{" "}
                            <span className="font-bold">
                              {data.comments?.summary?.total_count ||
                                data.comments_count}
                            </span>
                          </p>
                        </div>

                        <div className="analytics-box md:mb-2">
                          <p className="description-text text-gray-200 mb-2">
                            <span>&#128339;</span>
                            <span className="text-gray-400">
                              {formatDate(data.created_time)}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </main>
      </div>
      {tourCompletedFlag === "false" && isLargeScreen && <SocialMediaTour />}
    </>
  );
};

export default SocialMediaAnalytics;
