import React, { useState, useEffect, useRef, forwardRef } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { Button } from "@mantine/core";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
  getRequestWithTokenWithDataWithAuth,
  patchRequestWithTokenWithData,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import { async } from "q";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputMask from "react-input-mask";

const schema = yup.object().shape({
  //   email: yup
  //     .string()
  //     .email("Invalid email format")
  //     .required("Email is Required *"),
  //contactNumber: yup.string().required("Phone is Required *"),
  // Add more validations for other fields if needed
  userType: yup.string(), // Add validation for userType if needed
  userName: yup.string(), // Add validation for userName if needed

  // postalCode: yup
  //   .number()
  //   // .required("Zip Postal Code is Required * ")
  //   .positive("Zip Postal should be positive or non-zero")
  //   .typeError("Zip Postal should not be Empty"),
});

const CustomInput = forwardRef((props, ref) => (
  <InputMask {...props} inputRef={ref} />
));

const CreateProfile = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    address: "",
    city: "",
    country: "",
    state: "",
    //postalCode: "",
  });

  const [stateName, setStateName] = useState(null);
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [flag, setFlag] = useState(false);
  const [cityName, setCityName] = useState(null);
  const [animationClass, setAnimationClass] = useState("modal-swipe-right");
  const [isChecked, setIsChecked] = useState(false);

  const [loading, setLoading] = useState(true);
  const isMounted = useRef(true);
  const [config, setConfig] = useState({});
  const [reason, setReason] = useState("");
  // console.log("USERS DATA>>>>>", userData);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleCheckboxChangeForDelete = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleConfirm = async (confirmed) => {
    if (confirmed) {
      try {
        let request = {
          reason: reason,
        };
        const response = await patchRequestWithTokenWithData(
          `${TQ_URL}/updateUserActstatus`,
          request
        );
        console.log(response);
        if (response.result != null) {
          toast.success("Account deleted successfully...");

          setTimeout(async () => {
            await getRequestWithTokenWithoutData(`${TQ_URL}/logoutOauth`);

            // Clear local storage
            localStorage.removeItem("userData");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userAddressDetails");
            localStorage.removeItem("partnerData");
            localStorage.setItem("loggedIn", false);

            setUserData(null);

            console.log("===================>", "complete log out");

            // Redirect to home (0 to reload the current page, or use a route path if needed)
            navigate("/");
          }, 2000); // Adjust the delay time as needed
        } else {
          toast.error("Something Went Wrong...");
        }
      } catch (error) {
        console.error("Error in Verifying user:", error);
      }
    }
    handleCloseModal(); // Close the modal
  };
  const {
    register,
    handleSubmit,
    control,

    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      contactnumber: userData.contactNumber || "",
    },
  });

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getUserProfile();
      isMounted.current = false;
    }
  }, []);

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }
  useEffect(() => {
    console.log(userData);
    if (userData?.contactNumber) {
      console.log(userData?.contactNumber);
      setValue("contactnumber", userData.contactNumber);
    }
  }, [userData, setValue]);

  const getUserProfile = async () => {
    try {
      const response = await getRequestWithTokenWithoutData(
        `${TQ_URL_USPROJECT}/getUserProfile`
      );
      console.log(response);
      //   setUserInfo(response.userOrgRegistrationDetailsDTO);
      setUserData(response);
      //  console.log(response.userOrgRegistrationDetailsDTO);

      setLoading(false);
      return response;
    } catch (error) {
      console.error("Error in fetching the userData:", error);
    }
  };

  const onSubmitHandler = async (data) => {
    console.log("Form data to update");
    console.log(data);

    // Create the request object using filteredData
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value !== undefined && value !== null && value !== ""
      )
    );

    try {
      // Update userData with filteredData
      setUserData((prevUserData) => ({ ...prevUserData, ...filteredData }));

      // Now use the updated userData
      const updatedUserData = { ...userData, ...filteredData };

      // Create the request object using both filteredData and updatedUserData
      let request = {
        userName: toInitCap(updatedUserData.userName),
        address: updatedUserData.address,
        city: updatedUserData.city,
        state: updatedUserData.state,
        contactNumber: updatedUserData.contactnumber,
        country: updatedUserData.country,
        postalCode: updatedUserData.postalCode,
        userType: userType,
        // filteredData: filteredData, // Add the filteredData to the request
      };

      console.log(request);

      const response = await getRequestWithTokenWithDataWithAuth(
        `${TQ_URL_USPROJECT}/updateProfile`,
        request
      );
      console.log(response);

      if (response.message) {
        console.log("Success:", response.message);
        // toast.success(response.message);
        successFormSubmit();

        const userData = await getUserProfile();
        console.log("User Data  after update-------->", userData);
        localStorage.setItem(
          "userData",
          JSON.stringify({
            userId: userData.userId,
            userName: userData.userName,
            email: userData.email,
            phone: userData.contactNumber,
            userType: userData.userType,
            imageUrl: userData.imageUrl,
          })
        );

        localStorage.setItem(
          "userAddressDetails",
          JSON.stringify({ addressDetails: userData.address })
        );
        window.location.reload();
      } else if (response.error) {
        console.error("Error:", response.error);
        toast.error("Failed to update profile");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Error:", error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        console.error("Error in updating profile:", error.message);
        toast.error("Error updating profile data.");
      }
    }
  };

  const successFormSubmit = () =>
    toast.success("Profile Updated Successfully!");

  const [showPassword, setShowPassword] = useState(false);

  const handleVerify = () => {
    localStorage.setItem("redirectFrom", "profile");

    navigate("/verifyuser");
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [verified, setVerified] = useState(false);
  const handleCheckboxChange = () => {
    setVerified(!verified);
    setValue("verified", !verified); // Update the form value for 'verified'
  };

  const userDataString = localStorage.getItem("userData");
  const userJson = JSON.parse(userDataString);
  const userJsonId = userJson.userId;
  const userType = userJson.userType;
  const getAddBankAccount = async () => {
    try {
      const response = await getRequestWithTokenWithData(
        `${TQ_URL_USPROJECT}/setup-payment`,
        {
          clientId: userJsonId,
        }
      );
      if (response) {
        //const sessionData = await response.json();
        window.location.href = response.url;
      } else {
        console.error("Error initiating payment setup");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  const zipCodeDetails = async (event) => {
    const zipcode = event.target.value;
    console.log(zipcode + "this is my zip");

    if (!zipcode) {
      setNotFoundMessage("Zip Code is required");
      setStateName("");
      setValue("postalCode", zipcode);
      setFlag(false);
      return;
    }
    if (zipcode.length === 5) {
      try {
        const response = await fetch(`${TQ_URL}/getAddressDetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            zipcode: zipcode,
          }),
        });

        if (response.status == 200) {
          const res = await response.json();
          if (res.Status == "Succsess") {
            setUserData((prev) => ({ ...prev, state: res.State.state }));
            setNotFoundMessage("");
            // setCityName(res.State.city);
            setUserData((prev) => ({ ...prev, city: res.State.city }));
            setUserData((prev) => ({ ...prev, zip: zipcode }));
            setFlag(true);
          } else {
            setNotFoundMessage("Incorrect Zip Code");
            setStateName("");
            setFlag(false);
          }
        }
      } catch (error) {
        console.error("Error making post request:", error);
      }
    } else {
      setStateName("");
      setFlag(false);
      setNotFoundMessage("Incorrect Zip Code");
    }
  };

  return (
    <>
      <Header />
      <div className="flex flex-col md:flex-row min-h-screen ">
        {/* Left Section */}
        <div className="md:flex-1 bg-cover bg-center relative fade-in-left create-profile-bg hide-md-lg">
          <div className="absolute inset-0 bg-black opacity-40"></div>
          <div className="absolute inset-0 flex flex-col items-start justify-end md:px-20 px-5">
            <h3 className="text-white txt-style-1 mb-5">
              Empower Your Impact:
            </h3>
            <h3 className="text-white txt-style-2 mb-10">
              Updating Lives, <br />
              One Profile at a Time
            </h3>
          </div>
        </div>

        {/* Right Section */}
        <div className="md:flex-1 flex  justify-start  p-10 sm:p-10 md:ms-10 max-w-screen-lg mx-auto w-full  h-auto fade-in-right">
          <div className="max-w-full w-full sm:max-w-lg 2xl:max-w-2xl ">
            {/* Logo */}
            <div className="text-center md:text-left mb-10 sm:mb-10 flex md:justify-between justify-center hide-md-lg">
              <Link to="/">
                <img
                  src="assets/images/main-logo.svg"
                  alt="Logo"
                  className="h-10 mx-auto sm:mx-0 hidden md:block"
                />
              </Link>

              <button
                className="btn-style-1 text-white px-6 py-2 rounded-md"
                onClick={handleOpenModal}
              >
                Delete your Account
              </button>
              {/* <Modal
                opened={isModalOpen}
                onClose={handleCloseModal}
                centered
                size="md"
                overlayOpacity={0.5}
                overlayBlur={3}
                classNames={{ modal: "bg-gray-100 rounded-lg p-6" }}
              >
                <div className="flex flex-col items-center space-y-6">
                  <h2 className="text-xl font-semibold text-gray-800">
                    Are you sure you want to delete your account?
                  </h2>
                  <div className="flex justify-center space-x-4">
                    <Button
                      onClick={() => handleConfirm(true)}
                      className="bg-green-600 text-white font-semibold px-6 py-2 rounded-lg"
                    >
                      Yes
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => handleConfirm(false)}
                      className="border-gray-300 text-gray-600 font-semibold px-6 py-2 rounded-lg"
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Modal> */}
            </div>
            <form onSubmit={handleSubmit(onSubmitHandler)} className="">
              <div className="mb-6 md:flex justify-between">
                <label className="w-3/12">Email:</label>
                <input
                  {...register("email")}
                  type="text"
                  defaultValue={userData.email}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.email && "border-red-500"
                  }`}
                  disabled
                />
                {/*     <span className="text-red-500 text-sm mt-1">{errors.email?.message}</span>*/}
              </div>

              {/* <div className="mb-6 md:flex">
                <label className="w-3/12">Phone:</label>
                <input
                  {...register("contactNumber")}
                  type="text"
                  maxLength={13}
                  defaultValue={userData.contactNumber}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.contactNumber && "border-red-500"
                  }`}
                />
                <span className="text-red-500 text-sm mt-1">
                  {errors.contactNumber?.message}
                </span>
              </div> */}

              <div className="mb-6 md:flex">
                <label className="w-3/12">Phone:</label>
                <Controller
                  name="contactnumber"
                  control={control}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      mask="(999) 999-9999"
                      placeholder="Contact Number *"
                      alwaysShowMask={false}
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                        errors.contactnumber && "border-red-500"
                      }`}
                    />
                  )}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.contactnumber?.message}
                </p>
              </div>

              <div className="mb-6 md:flex">
                <label className="w-3/12">User Type:</label>
                <input
                  {...register("userType")}
                  type="text"
                  defaultValue={userData.userType}
                  readOnly
                  style={{ cursor: "not-allowed" }}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.userType && "border-red-500"
                  }`}
                />
                <span className="text-red-500 text-sm mt-1">
                  {errors.userType?.message}
                </span>
              </div>

              <div className="mb-6 md:flex">
                <label className="w-3/12">User Name:</label>
                <input
                  {...register("userName")}
                  type="text"
                  defaultValue={userData.userName}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.userName && "border-red-500"
                  }`}
                />
                <span className="text-red-500 text-sm mt-1">
                  {errors.userName?.message}
                </span>
              </div>

              <div className="mb-6 md:flex">
                <label className="w-3/12">Address:</label>
                <input
                  {...register("address")}
                  type="text"
                  defaultValue={userData.address}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.userName && "border-red-500"
                  }`}
                />
                <span className="text-red-500 text-sm mt-1">
                  {errors.address?.message}
                </span>
              </div>
              <div className="flex space-x-6">
                <div className="mb-6 md:flex w-1/2">
                  <label className="w-9/12">City:</label>
                  <input
                    {...register("city")}
                    type="text"
                    readOnly
                    defaultValue={userData.city}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.userName && "border-red-500"
                    }`}
                  />
                  <span className="text-red-500 text-sm mt-1">
                    {errors.city?.message}
                  </span>
                </div>
                <div className="mb-6 md:flex w-1/2 items-center ">
                  <label className="w-9/12 flex md:justify-center">
                    State:
                  </label>
                  <input
                    {...register("state")}
                    type="text"
                    readOnly
                    // value={stateName}
                    defaultValue={userData.state}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.userName && "border-red-500"
                    }`}
                  />
                  <span className="text-red-500 text-sm mt-1">
                    {errors.state?.message}
                  </span>
                </div>
              </div>
              <div className="flex space-x-6">
                <div className="mb-6 md:flex w-1/2">
                  <label className="w-9/12 ">Zip:</label>
                  <input
                    {...register("postalCode")}
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    onBlur={zipCodeDetails}
                    maxLength={6}
                    defaultValue={userData.postalCode}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.zip && "border-red-500"
                    }`}
                  />
                  <span className="text-red-500 text-sm mt-1">
                    {errors.zip?.message}
                  </span>

                  {!errors.zip?.message && (
                    <span className="text-red-500 text-sm mt-1">
                      {notFoundMessage}
                    </span>
                  )}
                </div>
                <div className="mb-6 md:flex w-1/2 items-center justify-center">
                  <label className="w-9/12 flex md:justify-center">
                    Country:
                  </label>
                  <input
                    {...register("country")}
                    type="text"
                    readOnly
                    defaultValue={userData.country}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.userName && "border-red-500"
                    }`}
                  />
                  <span className="text-red-500 text-sm mt-1">
                    {errors.country?.message}
                  </span>
                </div>
              </div>
              {/*   <div className="mb-6 flex items-center space-x-4">
                            <input
                              type="checkbox"
                                 {...register('verified')}
                                    checked={verified}
                                    onChange={handleCheckboxChange}
                                    className="form-checkbox h-5 w-5 text-blue-600 ml-2"
                                />
                                <label className="w-3/12">Verified</label>
                                    </div> */}
              {/* {userData.isVerified ? (
                <div className="mb-6 flex items-center space-x-4">
                  <input
                    type="checkbox"
                    {...register("verified")}
                    checked={userData.isVerified}
                    // onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-blue-600 ml-2"
                  />
                  <label className="w-3/12">Verified</label>
                </div>
              ) : (
                <div className="mb-6">
                  <p>
                    <a
                      to="#"
                      onClick={handleVerify}
                      className="btn-style-4 px-8 py-2 "
                    >
                      Verify Yourself
                    </a>
                  </p>
                </div>
              )} */}

              {/* ************** */}

              <div className="flex w-full justify-center items-center">
                <button
                  type="submit"
                  className="md:w-1/4 w-1/2 text-white p-2 rounded-md btn-style-1 "
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer theme="colored" />
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Onboarding Process Modal"
        shouldCloseOnOverlayClick={false}
        className={`onboarding-modal bg-white p-6 rounded-lg transition-all duration-700 ease-in-out ${animationClass}`}
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-700 ease-in-out"
      >
        <button
          onClick={handleCloseModal}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none text-2xl "
          aria-label="Close modal"
        >
          &times; {/* You can use an icon here or any other symbol */}
        </button>

        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Delete My Account</h2>
          {/* <p className="text-6xl mb-4">☹️</p> */}

          <img
            src="assets/images/deleteSadFace.jpg"
            alt="Sad face"
            className="mx-auto mb-4 w-16 h-16"
          />
          <p className="text-red-600 font-semibold">
            Account deletion is permanent and cannot be reversed.
          </p>
          <p className="mt-2 text-sm text-gray-700">
            If you delete your account, you will permanently lose access to it,
            along with any associated information, such as donation history and
            account settings. Any recurring transactions will automatically be
            unsubscribed after account deletion.
          </p>
          <p className="mt-2 text-sm text-gray-700">
            Understand your privacy. This request is separate from any other
            data privacy rights you may have based on your location. For a full
            profile of your data control rights and other actions you can take,
            please refer to our{" "}
            <a
              href="/privacy"
              className="text-blue-500 underline"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </p>

          {/* <div className="mt-4">
            <label className="block text-sm font-semibold mb-2">
              What is the main reason you’re deleting your account? (Optional)
            </label>
            <select className="border border-gray-300 rounded-md w-full p-2">
              <option value="">Select a main reason</option>
              <option value="privacy-concerns">Privacy concerns</option>
              <option value="not-using-account">
                I no longer use this account
              </option>
              <option value="found-alternative">
                I found an alternative service
              </option>
              <option value="too-expensive">
                The service is too expensive
              </option>
              <option value="technical-issues">
                Too many technical issues
              </option>
              <option value="content-dissatisfaction">
                Dissatisfied with the content
              </option>
              <option value="other">Other</option>
            </select>
          </div> */}

          <div className="mt-4">
            <label className="block text-sm font-semibold mb-2">
              Please share the main reason you’re deleting your account
              (Optional):
            </label>
            <textarea
              className="border border-gray-300 rounded-md w-full p-2"
              rows="4"
              placeholder="Type your reason here..."
              value={reason}
              onChange={handleReasonChange}
            />
          </div>

          <div className="mt-4">
            <label className="flex items-center text-sm">
              <input
                type="checkbox"
                className="mr-2 mb-4"
                onChange={handleCheckboxChangeForDelete}
              />
              I understand account deletion is permanent and cannot be reversed.
            </label>
          </div>

          <button
            className={`mt-6 w-full ${
              isChecked
                ? "btn-style-1 text-white px-6 py-2 rounded-md"
                : "bg-gray-500 cursor-not-allowed"
            } text-white font-bold py-2 rounded-md`}
            disabled={!isChecked}
            onClick={() => handleConfirm(true)}
          >
            CONTINUE TO ACCOUNT DELETION
          </button>
        </div>
      </Modal>
      <Footer />
    </>
  );
};

export default CreateProfile;
