import React, { useState, useEffect } from "react";
import { Spinner } from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import { TQ_URL } from "../../utility/baseUrl";
import { getRequestWithTokenWithoutDataPartner } from "../../utility/apiRequest";
import AccountModal from "../Profile/AccountModal"; // Import AccountModal component

const DonorManagementTop = () => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const navigate = useNavigate();

  const isUserLoggedIn = () => {
    return userData !== null;
  };

  const openAccountModal = () => {
    setIsModalOpen(true);
  };

  const logout = async () => {
    setIsLoading(true);
    setIsModalOpen(false); // Close the modal immediately

    localStorage.removeItem("partnerData");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("OauthFlag");

   

    try {
      await getRequestWithTokenWithoutDataPartner(`${TQ_URL}/logoutOauth`);
    } catch (error) {
      console.log("Error:", error);
    }
    localStorage.setItem("loggedIn", false);
    setUserData(null);
    setIsLoading(false);
    navigate("/");
  };

  const logLocalData = () => {
    const localStorageData = localStorage.getItem("partnerData");
    console.log("Local Storage Data:", JSON.parse(localStorageData));
    setUserData(JSON.parse(localStorageData));
  };

  useEffect(() => {
    logLocalData();
    console.log("Useeffect call ---------->");
  }, []);

  return (
    <div className="relative">
      <div className="grid grid-cols-2 md:flex primary-bg-color text-white p-2 md:justify-between items-center px-4 md:px-10 2xl:px-20">
        <div>
          <Link to="/services-dashboard">
            <img
              src="assets/images/main-logo.svg"
              alt="Logo"
              className="h-10 z-10"
            />
          </Link>
        </div>

        <div className="flex items-center space-x-4 justify-end">
          <div className="w-full space-x-6 items-center me-10 md:flex hidden">
            {/* Social Media Icons */}
          </div>
          {isUserLoggedIn() && (
            <div className="block">
              {isLoading ? (
                <Spinner color="blue" className="animate-spin" />
              ) : (
                <div className="cursor-pointer" onClick={openAccountModal}>
                  <div className="w-11/12 flex items-center justify-between space-x-4 me-8">
                  
                    <h1 className="text-xl">
                      {userData.orgName != null
                        ? userData.orgName.split(" ")[0]
                        : ""}
                    </h1>
                    {userData.imageUrl == null ? (
                      <img
                        src={
                          "https://prabalta.org/assets/images/user.webp"
                        }
                        alt="Placeholder Avatar"
                        className="rounded-full h-8 w-8"
                      />
                    ) : (
                      <img
                        src={userData.imageUrl}
                        alt="User Avatar"
                        className="rounded-full h-8 w-8"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {!isUserLoggedIn() && (
            <Link to="/login">
              <button className="bg-white text-blue-500 py-1 px-2 rounded login-btn ml-6">
                Login
              </button>
            </Link>
          )}
        </div>
      </div>

      {/* Account Modal */}
      <AccountModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default DonorManagementTop;
