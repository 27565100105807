import React from "react";
import { useState, useRef } from "react";
import { useEffect } from "react";
import ProgressBar from "../Common/ProgressBar";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getRequestWithTokenWithoutData } from "../../utility/apiRequest";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateFundraiserAdmin = ({ onComplete }) => {
  const [counter, setCounter] = useState(1);
  const [value, setValue] = useState("");
  const [selectDropdown, setSelectDropdown] = useState(false); // Manage visibility of the third dropdown
  const [errorType, setErrorType] = useState("");
  const [errorCauseType, setErrorCauseType] = useState("");
  const [config, setConfig] = useState({});
  const isMounted = useRef(true);

  const [causeList, setCauseList] = useState([]);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [selectedCauseType, setSelectedCauseType] = useState([]);
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState([]);
  const [otherFundraiserType, setOtherFundraiserType] = useState(""); // New state for other fundraiser type
  const [errorOtherFundraiserType, setErrorOtherFundraiserType] = useState(""); // Error state for the new dropdown


  useEffect(() => {
    window.scrollTo(0, 0);
    setUserType(JSON.parse(localStorage.getItem("userData")));
  }, []);

  useEffect(() => {
    onComplete();
  }, [onComplete]);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    if (isMounted.current && config.partnerId) {
      getCauseList();
      isMounted.current = false;
    }
    setSelectedValues([]);
  }, [config]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter < 10) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [counter]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const uniqueValues = [...new Set(value)];

    setSelectedValues(uniqueValues);

    setErrorCauseType(uniqueValues ? "" : "Please select a Cause type");

    const selectedCauseIds = uniqueValues.map((item) => item.causeId);
    setSelectedCauseType(selectedCauseIds);
    setCauseList(causeList);
  };

  const getCauseList = async () => {
    const partnerId = config.partnerId;
    console.log(partnerId);
    if (!partnerId) {
      throw new Error("Partner ID is not defined");
    }
    try {
      const response = await getRequestWithTokenWithoutData(
        `${TQ_URL_USPROJECT}/getCauseList?partnerId=${partnerId}`
      );
      console.log(response);
      setCauseList(response.data);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Error:", error.response.data.error);
      } else {
        console.error("Error fetching cause list data:", error.message);
      }
    }
  };

  const getTruncatedCauses = (selected, maxLength) => {
    const selectedCause = selected.map((cause) => cause.causeName).join(", ");

    if (selectedCause.length > maxLength) {
      return selectedCause.slice(0, maxLength).trim() + "...";
    }

    return selectedCause;
  };

  const getTypeValue = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value>>", selectedValue);

    setValue(selectedValue);
    setErrorType(selectedValue ? "" : "Please select a fundraiser type");

    // If "Someone Else" is selected, show the third dropdown
    if (selectedValue === "Someone Else") {
      setSelectDropdown(true);
    } else {
      setSelectDropdown(false);
      setOtherFundraiserType(""); // Reset the third dropdown if not needed
    }
  };

  const handleOtherFundraiserTypeChange = (event) => {
    const selectedValue = event.target.value;
    setOtherFundraiserType(selectedValue);
    setErrorOtherFundraiserType(selectedValue ? "" : "Please select an Other Fundraiser Type");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate form fields
    if (selectedCauseType.length <= 0) {
      setErrorCauseType("Please select Cause Type");
      return;
    }

    if (!value) {
      setErrorType("Please select a type");
      return;
    }

    if (selectDropdown && !otherFundraiserType) {
      setErrorOtherFundraiserType("Please select an Other Fundraiser Type");
      return;
    }

    setErrorCauseType("");
    setErrorType("");
    setErrorOtherFundraiserType("");

    // Create an object with all the necessary state
    const values = {
      selectedCauses: selectedCauseType,
      relationValue: value,           // Pass the second dropdown value
      otherFundraiserTyp: otherFundraiserType,  // Pass the third dropdown value
    };

    console.log(values);

    if (value === "Someone Else") {
      navigate("/other-admin", { state: values });
    } else if (value === "Organization / Individual") {
      navigate("/self-admin", { state: values });
    }
  };


  return (
    <>
      <DonorManagementTop />
      <ProgressBar value={30} size="sm" color="blue" />

      <h2 className="text-xl md:text-3xl font-bold mb-4 text-center uppercase md:py-10 py-5">
        Raising Fund
      </h2>

      <div className="top-fundraiser-sec md:p-8 p-4 flex flex-col md:flex-row items-center justify-start pe-6">
        <div className="2xl:w-1/2 md:w-1/2 md:pr-8 relative">
          <img
            src="assets/images/newCroppedImg.png"
            alt="Image"
            className="object-cover mb-6 md:mb-0 below-live-feed-img  w-full h-auto"
          />
          <div className="counter-overlay  bottom-20 left-16 right-0 bg-blue-500 text-white text-center p-2">
            <p className="text-4xl font-bold ">
              {counter}+{" "}
              <p className="text-xs font-semibold blue-box-text">
                Happy Clients{" "}
              </p>
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <h2 className="text-xl md:text-3xl font-bold mb-4">
            Who Are You Fundraising For:
          </h2>

          {/* First Dropdown */}
          <div className="flex items-center mt-2 mb-2 w-full">
            <FormControl
              className={`bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errorCauseType && "border-red-500"
                }`}
            >
              <InputLabel
                style={{ fontWeight: "bold" }}
                className="text-black-900 font-semibold text-sm"
                id="demo-multiple-checkbox-label"
              >
                Select Causes
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedValues}
                onChange={handleChange}
                input={<OutlinedInput label="Select Causes" />}
                renderValue={(selected) => getTruncatedCauses(selected, 80)}
                MenuProps={MenuProps}
              >
                {causeList.map((variant) => (
                  <MenuItem
                    key={variant.causeId}
                    value={variant}
                    style={{ padding: "5px", fontSize: "16px", margin: "2px" }}
                  >
                    <Checkbox
                      checked={selectedValues.includes(variant)}
                      style={{
                        fontSize: "16px",
                        margin: "5px",
                      }}
                    />
                    <ListItemText primary={variant.causeName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {errorCauseType && <p style={{ color: "red" }}>{errorCauseType}</p>}

          {/* Second Dropdown */}
          <div className="flex items-center mt-2 mb-2 w-full">
            <select
              onChange={getTypeValue}
              value={value}
              className={`bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding ${errorType && "border-red-500"
                }`}
            ><option value="">Select fundraiser type</option>
              <option value="Organization / Individual">Yourself</option> {/* Display changed */}
              <option value="Someone Else">Someone Else</option>
            </select>
          </div>
          {errorType && <p style={{ color: "red" }}>{errorType}</p>}

          {/* Third Dropdown */}
          <div
            className={`transition-all duration-500 ease-in-out overflow-hidden ${selectDropdown ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
              }`}
          >
            <div className="flex items-center mt-2 mb-2 w-full"> {/* Consistent margin */}
              <select
                onChange={handleOtherFundraiserTypeChange}
                value={otherFundraiserType}
                className={`bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding ${errorOtherFundraiserType && "border-red-500"
                  }`}
              >
                <option value="">Select Other Fundraiser Type</option>
                <option value="Individual">Individual</option>
                <option value="Organization">Organization</option>
              </select>
            </div>
            {errorOtherFundraiserType && (
              <p style={{ color: "red" }}>{errorOtherFundraiserType}</p>
            )}
          </div>

          <p className="text-black font-medium my-10 italic text-xs md:text-lg">
            *To receive money raised, Please make sure you have added the
            following details.
            <br />
            <p className="mt-3 ms-7">- Verification</p>
          </p>

          <div className="w-full flex md:block justify-center">
            <button
              type="submit"
              className="btn-style-1 py-2 text-white px-20 rounded mb-4 "
            >
              Next
            </button>
          </div>
        </form>
        <ToastContainer theme="colored" />
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default CreateFundraiserAdmin;
