import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { async } from "q";
import { Triangle } from "react-loader-spinner"; // Import Triangle loader
import axios from "axios";
import { TQ_URL } from "../../utility/baseUrl";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import {
  getRequestWithTokenWithoutData,
  getRequestWithTokenWithDataWithAuth,
} from "../../utility/apiRequest";
import TopHeader from "../Home/TopHeader";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";

const schema = yup.object().shape({
  individualName: yup.string().required("Individual's name is required"),
  //  address: yup.string().required("Address is required"),
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),

  fundraiserGoal: yup
    .number()
    .required("Fundraiser goal is required")
    .positive("Fundraiser goal should be positive or non-zero")
    .typeError("Fundraiser goal Amount should not be Empty"),
  targetDate: yup.date().required("Target Date is required"),
  uploadedDocs: yup.array().of(yup.string()),
});

const EditFundraiser = () => {
  const { state } = useLocation();
  const { fundraiserId } = state || {};
  const [fundraiserData, setFundraiserData] = useState(null);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  const [showSliderModal, setShowSliderModal] = useState(false);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [deleteFiles, setDeleteFiles] = useState({});
  const navigate = useNavigate();

  const fileInputRef = useRef();
  // console.log("Fundraiser  data.. :", fundraiserData);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchUpdatedData = async (fundraiserId) => {
    try {
      const response = await getRequestWithTokenWithDataWithAuth(
        `${TQ_URL_USPROJECT}/getFundraiserProfileDetailsToEdit`,
        {
          fundraiserId: fundraiserId,
        }
      );
      return response.data;
      // onUpdateRow(response.data);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Error:", error.response.data.error);
        toast.error("Error fetching updated data.");
      } else {
        // Display a generic error message
        console.error("Error fetching updated data:", error.message);
        // toast.error("Error fetching updated data.");
      }
      setTimeout(() => {
        handleBack();
      }, 2000);
    }
  };
  useEffect(() => {
    const loadData = async () => {
      const data = await fetchUpdatedData(fundraiserId);
      if (data) {
        setFundraiserData(data);
      }
    };

    if (fundraiserId) {
      loadData();
    }
  }, [fundraiserId]);

  useEffect(() => {
    if (fundraiserData) {
      setValue("individualName", fundraiserData.fundraiserName);
      // setValue("address", fundraiserData.address);
      setValue("title", fundraiserData.title);
      setValue("description", fundraiserData.desc);
      setValue("fundraiserGoal", fundraiserData.goalAmt);
      setValue("targetDate", fundraiserData.targetDate);
      setUploadedDocs(fundraiserData.docNamesList || []);
      setUploadedFiles(fundraiserData.docNamesList || []);
      setPreviewFiles(fundraiserData.imageList || []);
      setDeleteFiles(fundraiserData.docList || {});
    }
  }, [fundraiserData, setValue]);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  // useEffect(() => {
  //   if (isModalOpen) {
  //     // Lock scrolling
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     // Unlock scrolling
  //     document.body.style.overflow = 'auto';
  //   }

  //   // Clean up the effect
  //   return () => {
  //     document.body.style.overflow = 'auto';
  //   };
  // }, [isModalOpen]);

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    const maxSize = 5 * 1024 * 1024; // Maximum size in bytes (5 MB)

    console.log("Selected files:", files);

    // Filter valid files based on size
    const validFiles = files.filter((file) => {
      if (file.size > maxSize) {
        toast.error(
          `File "${file.name}" is too large. Maximum size allowed is 5 MB.`
        );
        return false;
      }
      return true;
    });

    console.log("Valid files:", validFiles);
    const newFiles = Array.from(validFiles);
    console.log("new files:", newFiles);
    const vaildDocs = validFiles.map((file) => URL.createObjectURL(file));

    // Update state with valid files
    setUploadedDocs((prevDocs) => [
      ...prevDocs,
      ...newFiles.map((file) => file.name),
    ]);

    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);

    setPreviewFiles((previewFiles) => [...previewFiles, ...vaildDocs]);

    // Clear file input (optional)
    e.target.value = null;
  };

  // Use `useEffect` to observe updated state
  useEffect(() => {
    console.log("Updated uploadedDocs:", uploadedDocs);
    console.log("Updated uploadedFiles:", uploadedFiles);
    console.log(" previewFiles:", previewFiles);
  }, [uploadedDocs, uploadedFiles, previewFiles]);

  const handleRemoveDocument = (index) => {
    setUploadedDocs((prevDocs) => prevDocs.filter((_, i) => i !== index));
    setPreviewFiles((prevDocs) => prevDocs.filter((_, i) => i !== index));
    setUploadedFiles((prevDocs) => prevDocs.filter((_, i) => i !== index));

    setDeleteFiles((prevDocs) => {
      // Convert object to array of [key, value] pairs
      const entries = Object.entries(prevDocs);
      // Remove the entry by index
      const updatedEntries = entries.filter((_, i) => i !== index);
      // Convert back to object
      return Object.fromEntries(updatedEntries);
    });
  };

  const openSliderModal = () => {
    setShowSliderModal(true);
  };

  const closeSliderModal = () => {
    setShowSliderModal(false);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleBack = () => {
    navigate("/all-fundraiser");
  };

  const handleUpdateFundraiser = (data, event) => {
    window.scrollTo(0, 0);
    // setLoading(true);
    console.log("Form data:", data);
    console.log("inside update fundraiser");
    const partnerId = config.partnerId;
    console.log(partnerId);

    if (uploadedFiles.length == 0 && uploadedDocs.length == 0) {
      toast.error("Please select document to upload..");
      return;
    }

    event.preventDefault();
    setLoading(true);

    const formData = new FormData();

    console.log("------------->");

    const selectedDate = data.targetDate.toISOString().split("T")[0];
    console.log("target date:" + selectedDate);
    formData.append("fundraiserId", fundraiserData.fundraiserId);
    formData.append("fundraiserName", data.individualName);
    //formData.append("address", data.address);
    formData.append("desc", data.description);
    formData.append("title", data.title);
    formData.append("currencyType", "USD");
    formData.append("goalAmt", parseFloat(data.fundraiserGoal));

    formData.append("targetDate", selectedDate);
    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((file) => {
        formData.append(`file`, file);
      });
    }

    uploadedDocs.forEach((doc) => {
      formData.append(`doc`, doc);
    });

    if (Object.keys(deleteFiles).length > 0) {
      Object.entries(deleteFiles).forEach(([key, value]) => {
        formData.append(`deletelist`, key);
      });
    }

    console.log(formData);
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    const token = localStorage.getItem("accessToken");

    // Send the file and additional data using Axios
    axios
      .put(`${TQ_URL_USPROJECT}/editFundraiserDetails`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Handle successful response
        console.log("response data", response.data);
        setLoading(false);
        causeUpdated();

        fetchUpdatedData(response.data.data.fundraiserId);
        setUploadedDocs(
          response.data.data.docLists.map((doc) => doc.documentName || [])
        );
        setUploadedFiles(
          response.data.data.docLists.map((doc) => doc.documentName || [])
        );
        setPreviewFiles(response.data.imagelist || []);
        setTimeout(() => {}, 2000);
      })
      .catch((error) => {
        if (error.response.data.error) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Something went wrong...");
        }
        console.error("Error uploading file:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const causeUpdated = () => toast.success("Fundraiser Updated Successfully!");

  const formatCurrency = (value) => {
    if (!value) return "";
    const options = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    };
    return new Intl.NumberFormat("en-US", options).format(value);
  };

  const handleFundraiserGoalChange = (e, onChange) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value === "") {
      onChange(null);
      return;
    }
    value = parseInt(value, 10);
    onChange(value);
  };

  return (
    <>
      <DonorManagementTop />
      {/* <Header /> */}
      <div className="flex justify-center items-center bg-white-100 p-4 bg-slate-50">
        <h2 className="text-2xl font-bold  text-center">Edit Fundraiser</h2>
      </div>

      <div className="flex justify-center items-center bg-white-100 p-4 bg-slate-50 ">
        <div className="editfundraiser w-full max-w-screen-lg bg-white rounded-xl shadow-lg p-6 md:px-16 lg:px-24 ">
          {loading ? (
            <div className="flex items-center justify-center h-[200px] w-full md:pr-20">
              <Triangle
                height="120"
                width="120"
                color="blue"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                visible={true}
              />
            </div>
          ) : (
            <form onSubmit={handleSubmit(handleUpdateFundraiser)}>
              <div className="md:flex mb-4">
                <label className="block md:w-1/4 self-center text-left pr-4 font-semibold">
                  Fundraiser Name
                </label>
                <div className="md:w-3/4">
                  <input
                    type="text"
                    color="blue"
                    {...register("individualName")}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md  ${
                      errors.individualName &&
                      "border-red-500 focus:border-red-500 focus:ring-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.individualName?.message}
                  </p>
                </div>
              </div>

              {/* <div className="md:flex mb-4">
                <label className="block md:w-1/4 self-center text-left pr-4 font-semibold">
                  Address
                </label>
                <div className="md:w-3/4">
                  <input
                    type="text"
                    {...register("address")}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 border p-2 w-full shadow-md  ${
                      errors.address && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.address?.message}
                  </p>
                </div>
              </div> */}

              <div className="md:flex mb-4">
                <label className="block md:w-1/4 self-center text-left pr-4 font-semibold">
                  Title
                </label>
                <div className="md:w-3/4">
                  <input
                    type="text"
                    {...register("title")}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md  ${
                      errors.title && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.title?.message}
                  </p>
                </div>
              </div>

              <div className="md:flex mb-4">
                <label className="block md:w-1/4 self-center text-left pr-4 font-semibold">
                  Description
                </label>
                <div className="md:w-3/4">
                  <textarea
                    {...register("description")}
                    rows="6"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md  ${
                      errors.description && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.description?.message}
                  </p>
                </div>
              </div>

              <div className="md:flex mb-4">
                <label className="block md:w-1/4 self-center text-left pr-4 font-semibold">
                  Fundraiser Goal
                </label>
                <div className="md:w-3/4">
                  {/* <input
                    type="number"
                    {...register("fundraiserGoal")}
                    className={`border p-2 w-full shadow-md rounded-lg ${
                      errors.fundraiserGoal && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.fundraiserGoal?.message}
                  </p> */}

                  <Controller
                    name="fundraiserGoal"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          type="text" // Change type to text for better currency handling
                          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                            errors.fundraiserGoal && "border-red-500"
                          }`}
                          value={field.value ? formatCurrency(field.value) : ""}
                          onChange={(e) =>
                            handleFundraiserGoalChange(e, field.onChange)
                          }
                        />
                        <p className="text-red-500 text-sm mt-1">
                          {errors.fundraiserGoal?.message}
                        </p>
                      </>
                    )}
                  />

                  <p className="text-red-500 text-sm mt-1">
                    {errors.fundraiserGoal?.message}
                  </p>
                </div>
              </div>

              <div className="md:flex mb-4">
                <label className="block md:w-1/4 self-center text-left pr-4 font-semibold">
                  Target Date
                </label>
                <div className="md:w-3/4">
                  <input
                    type="date"
                    {...register("targetDate")}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md  ${
                      errors.targetDate && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.targetDate?.message}
                  </p>
                </div>
              </div>

              <div className="md:flex mb-4">
                <label className="block md:w-1/4 self-center text-left pr-4 font-bold">
                  Uploaded Documents
                </label>
                <div className="md:w-3/4 mb-4 flex flex-wrap">
                  {previewFiles.map((doc, index) => (
                    <div
                      key={index}
                      className="relative w-24 h-24 mr-2 mb-2 overflow-hidden"
                    >
                      <img
                        src={doc}
                        alt={`Document ${index}`}
                        className="object-cover w-full h-full rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-110"
                        onClick={openSliderModal}
                      />
                      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-10 pointer-events-none">
                        <p className="text-white opacity-100">Click to view</p>
                      </div>
                      <button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent click event from propagating to the image
                          handleRemoveDocument(index);
                        }}
                        className="absolute top-0 right-2 bg-white text-red-600 rounded-full p-0.5"
                      >
                        &#x2715;
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Modal for displaying images in a slider */}
              {showSliderModal && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                  <div className="bg-white p-6  rounded-md max-w-lg w-full h-auto relative">
                    <button
                      onClick={closeSliderModal}
                      className="absolute top-2 right-2 text-red-500 text-2xl font-bold"
                    >
                      &times;
                    </button>
                    <Slider {...sliderSettings}>
                      {previewFiles.map((doc, index) => (
                        <div
                          key={index}
                          className="flex justify-center items-center"
                        >
                          <img
                            src={doc}
                            alt={`Document ${index}`}
                            className="w-full max-h-96 object-contain"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              )}

              <div className="md:flex mb-4">
                <label className="block md:w-1/4 self-center text-left pr-4 font-bold">
                  Upload Documents
                </label>
                {/* <div
                  className={`md:w-2/3 mb-4 border p-6 bg-white shadow-md rounded cursor-pointer ${
                    uploadedDocs.length ? "hover-shadow" : ""
                  }`}
                  onClick={() => fileInputRef.current.click()}
                  data-placeholder="Select files to upload"
                >
                  <input
                    type="file"
                    multiple
                    ref={fileInputRef}
                    placeholder="click to upload files"
                    onChange={handleFileInputChange}
                    className="hidden"
                  />
                  {!uploadedDocs.length && <p>Click to upload documents</p>}
                </div> */}
                <div className="flex flex-col md:flex-row md:items-start items-center md:w-3/4 mb-4">
                  <label
                    htmlFor="documentInput"
                    className="cursor-pointer mt-2"
                  >
                    <img
                      src="assets/images/cloud-icon.svg"
                      className="mx-auto"
                      alt="Cloud Icon"
                    />
                    <p className="text-xs text-center">
                      <span className="font-bold underline">
                        Click to upload
                      </span>
                      <br />
                      Doc, PNG, JPG, JPEG, MP4, PDF (Max. 800 x 400, Size up to
                      5 MB)
                    </p>
                  </label>
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png, .doc, .pdf, .mp4"
                    onChange={handleFileInputChange}
                    className="hidden"
                    id="documentInput"
                    multiple
                  />
                </div>
              </div>

              <div className=" flex md:flex mb-4 items-center justify-center">
                {/* <label className="block md:w-1/3 text-left pr-4 font-bold">
                
                </label> */}
                {/* {  <div className="md:w-2/3 mb-4 flex flex-wrap">} */}
                <button
                  type="button"
                  onClick={handleBack}
                  className="bg-gray-300 !font-bold text-gray-700 py-2 mr-6 px-10 btn-style-3 !rounded-md"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded btn-style-1"
                >
                  Update Fundraiser
                </button>
                {/* </div> */}
              </div>
            </form>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditFundraiser;
