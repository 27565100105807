import React from 'react';

const ImagePreview = ({ files, handleRemove }) => {
    return (
        <div className="mt-4 grid grid-cols-5 gap-2">
            {files.map((file, index) => (
                <div key={index} className="relative">
                    {file.type.startsWith('image/') ? (
                        <img src={URL.createObjectURL(file)} alt={`uploaded-${index}`} className="w-full h-20 object-cover rounded-lg" />
                    ) : (
                        <video controls src={URL.createObjectURL(file)} alt={`uploaded-${index}`} className="w-full h-20 object-cover rounded-lg" />
                    )}
                    <button
                        onClick={() => handleRemove(index)}
                        className="absolute top-0 right-0 p-1 bg-gray-800 bg-opacity-75 text-white rounded-full hover:bg-opacity-100"
                    >
                        &times;
                    </button>
                </div>
            ))}
        </div>
    );
};

export default ImagePreview;