import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";

const CookiePolicy = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", "accepted", { expires: 365 });
    setShowBanner(false);
  };

  const handleDecline = () => {
    Cookies.set("cookieConsent", "declined", { expires: 365 });
    setShowBanner(false);
  };

  return (
    <AnimatePresence>
      {showBanner && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.5 }}
          className="fixed bottom-0 left-0 right-0 bg-gradient-to-r from-blue-50 to-white border-t border-gray-200 p-5 md:p-6 flex flex-col md:flex-row items-center justify-between z-50 shadow-lg rounded-t-lg"
        >
          <div className="text-[rgb(14,57,143)] text-sm md:text-base mb-3 md:mb-0 flex-1">
            <p className="mb-2 md:mb-0 font-medium">
              We use cookies to personalize content, tailor ads, and analyze our traffic to ensure you get the best experience. By clicking "Accept", you consent to our use of cookies.{" "}
              <a href="/privacy" className="text-blue-600 underline hover:text-blue-700 transition-colors duration-200">
                Learn more
              </a>
            </p>
          </div>
          <div className="flex space-x-4">
            <button
              onClick={handleAccept}
              className="bg-blue-600 text-white font-semibold py-2 px-6 rounded-full transition-transform transform hover:scale-105 shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
            >
              Accept
            </button>
            <button
              onClick={handleDecline}
              className="bg-transparent border border-gray-300 text-[rgb(14,57,143)] font-semibold py-2 px-6 rounded-full transition-transform transform hover:scale-105 shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50"
            >
              Decline
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CookiePolicy;
