import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { TQ_URL } from "../../utility/baseUrl";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faCheck,
  faBan,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { getTypeRequestWithTokenWithDataWithAuth } from "../../utility/apiRequest";
import { Triangle } from "react-loader-spinner";

const defaultLogoPath = "/assets/images/main-logo.svg";

const schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(5, "Name must be at least 5 characters")
    .max(50, "Name must be at most 50 characters")
    .required("Name is Required *"),
  domainName: yup
    .string()
    .trim()
    .min(5, "Domain name must be at least 5 characters")
    .matches(/^[a-z0-9]+$/, "Domain name must be lowercase and contain no special characters")
    .required("Domain name is Required *"),
  email: yup
    .string()
    .trim()
    .email("Invalid email format")
    .required("Email is Required *"),
  phone: yup
    .string()
    .typeError("Phone must be a number")
    .test("only-digit", "Phone must be a number", (value) =>
      /^[\d+\-]+$/.test(value)
    )
    .test("min-length", "Phone number should be at least 8 digits", (value) =>
      /^[\d+-]{8,}$/.test(value)
    )
    .required("Phone is Required *"),
  address: yup.string().required("Address is Required * "),
  zipcode: yup
    .number()
    .required("ZipCode is Required *")
    .positive("ZipCode should be positive or non-zero")
    .typeError("ZipCode should not be Empty"),
  linkedin: yup.string().trim().nullable(),
  facebook: yup.string().trim().nullable(),
  twitter: yup.string().trim().nullable(),
  thread: yup.string().trim().nullable(),
  instagram: yup.string().trim().nullable(),
  causes: yup
    .array()
    .of(
      yup
        .string()
        .trim()
        .min(3, "Cause must be at least 3 characters")
        .required("At least one cause is required")
    )
    .min(1, "At least one cause is required"),
}).test(
  "at-least-one-social-media",
  "At least one social media link is required",
  (value) => {
    return (
      !!value.linkedin ||
      !!value.facebook ||
      !!value.twitter ||
      !!value.thread ||
      !!value.instagram
    );
  }
);

const CreateDomain = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [stateName, setStateName] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [countryFlag, setCountryFlag] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [domainAvailability, setDomainAvailability] = useState(null);
  const [domainName, setDomainName] = useState("");
  const [domainNameWarning, setDomainNameWarning] = useState("");
  const [logoUrl, setLogoUrl] = useState(defaultLogoPath);
  const [domainId, setDomainId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isForm1Complete, setIsForm1Complete] = useState(false);
  const [isForm2Complete, setIsForm2Complete] = useState(false);
  const [isForm3Complete, setIsForm3Complete] = useState(false);
  const [hoveredSection, setHoveredSection] = useState(null);
  const [primaryColor, setPrimaryColor] = useState("#000000");
  const [secondaryColor, setSecondaryColor] = useState("#000000");
  const [causes, setCauses] = useState([]); // State for causes from the API

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (domainName.length >= 5 && !domainNameWarning) {
      checkDomainAvailability(domainName);
    } else {
      setDomainAvailability(null);
    }
  }, [domainName, domainNameWarning]);

  const fetchDomainDetails = async () => {
    try {
      const domainDetails = await getTypeRequestWithTokenWithDataWithAuth(
        `${TQ_URL}/domain-details/partner/${partnerData.partnerId}`
      );
      if (domainDetails) {
        setDomainId(domainDetails.id);
        setValue("name", domainDetails.organizationName);
        setValue("domainName", domainDetails.domainName);
        setValue("email", domainDetails.donorEmail);
        setValue("phone", domainDetails.contactNo);
        setValue("address", domainDetails.address);
        setValue("zipcode", domainDetails.postalCode);
        setValue("linkedin", domainDetails.linkedinLink);
        setValue("facebook", domainDetails.facebookLink);
        setValue("instagram", domainDetails.instagramLink);
        setValue("twitter", domainDetails.twitterLink);
        setValue("thread", domainDetails.threadLink);
        if (domainDetails.logo.includes("/home/tqadmin/public_html")) {
          domainDetails.logo = domainDetails.logo.replace("/home/tqadmin/public_html", "http://prabalta.org");
        }      
        setLogoUrl(domainDetails.logo || defaultLogoPath);

        setPrimaryColor(domainDetails.primaryColor);
        setSecondaryColor(domainDetails.secondaryColor);
        setCauses(domainDetails.causeTypes.map(cause => cause.causeName) || []); // Set causes from the data

        // Call the zip code details API for auto-filled zip code
        zipCodeDetails({ target: { value: domainDetails.postalCode } });
      }
    } catch (error) {
      console.error("Error fetching domain details:", error);
    }
  };

  useEffect(() => {
    fetchDomainDetails();
  }, [partnerData.partnerId, setValue]);

  const checkDomainAvailability = async (domain) => {
    try {
      const response = await fetch(`${TQ_URL}/checkDomain`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ domainName: domain }),
      });
      const data = await response.json();
      if (data.available) {
        setDomainAvailability(true);
      } else {
        setDomainAvailability(false);
      }
    } catch (error) {
      console.error("Error checking domain availability:", error);
    }
  };

  const handleDomainNameChange = (event) => {
    const value = event.target.value;
    setDomainName(value);

    if (/[^a-z0-9]/.test(value)) {
      setDomainNameWarning("Domain name must be lowercase and contain no special characters");
    } else {
      setDomainNameWarning("");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileSizeInMegabytes = file.size / (1024 * 1024);
      const maxSize = 20;

      if (fileSizeInMegabytes > maxSize) {
        toast.error(
          `${file.name} File size exceeds the maximum limit of ${maxSize} MB`
        );
        event.target.value = null;
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        setUploadedFile({ file, preview: img.src });
      };

      img.onerror = () => {
        toast.error(`${file.name} Invalid image file.`);
        event.target.value = null;
      };
    }
  };

  const removeFile = () => {
    setUploadedFile(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileChange({ target: { files: [file] } });
    }
  };

  const onSubmitHandler = async (data) => {
    if (currentPage === 1 && !isForm1Complete) {
      setIsForm1Complete(true);
      setCurrentPage(2);
      return;
    }

    if (currentPage === 2 && !isForm2Complete) {
      setIsForm2Complete(true);
      setCurrentPage(3);
      return;
    }

    if (currentPage === 3 && !isForm3Complete) {
      setIsForm3Complete(true);
      setCurrentPage(4);
      return;
    }

    if (currentPage === 4) {
      setIsLoading(true);

      try {
        const token = localStorage.getItem("accessToken");
        const formData = new FormData();
        formData.append("organizationName", data.name);
        formData.append("domainName", data.domainName);
        if (uploadedFile) {
          formData.append("logo", uploadedFile.file);
        } else {
          const response = await fetch(defaultLogoPath);
          const blob = await response.blob();
          formData.append("logo", blob, "default-logo.svg");
        }
        formData.append("donorEmail", data.email);
        formData.append("contactNo", data.phone);
        formData.append("address", data.address);
        formData.append("city", cityName);
        formData.append("state", stateName);
        formData.append("country", "USA");
        formData.append("postalCode", data.zipcode);
        formData.append("partnerId", partnerData.partnerId);
        formData.append("linkedinLink", data.linkedin);
        formData.append("facebookLink", data.facebook);
        formData.append("instagramLink", data.instagram);
        formData.append("twitterLink", data.twitter);
        formData.append("threadLink", data.thread);
        formData.append("primaryColor", primaryColor);
        formData.append("secondaryColor", secondaryColor);
        formData.append("causes", JSON.stringify(causes));

        const url = domainId
          ? `${TQ_URL}/update/${domainId}`
          : `${TQ_URL}/create`;
        const method = domainId ? "PUT" : "POST";

        const response = await fetch(url, {
          method: method,
          headers: {
            Authorization: token,
          },
          body: formData,
        });

        if (response.status === 200) {
          toast.success("Domain updated successfully", {
            position: "top-right",
          });
          await fetchDomainDetails();
          setCurrentPage(1);
        } else {
          const errorText = await response.text();
          console.error("Error response:", errorText);
          throw new Error(
            `HTTP error! status: ${response.status}, message: ${errorText}`
          );
        }
      } catch (error) {
        console.error("Failed to submit form:", error);
        toast.error("Failed to save the details", { position: "top-right" });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const zipCodeDetails = async (event) => {
    const zipcode = event.target.value;

    if (!zipcode) {
      setNotFoundMessage("Zip Code is required");
      setStateName("");
      setCityName("");

      setValue("state", "");
      setValue("country", "");
      setCountryFlag(false);
      return;
    }
    if (zipcode.length === 5) {
      try {
        const response = await fetch(`${TQ_URL}/getAddressDetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            zipcode: zipcode,
          }),
        });

        if (response.status === 200) {
          const res = await response.json();
          if (res.Status === "Succsess") {
            setStateName(res.State.state);
            setCityName(res.State.city);
            setCountryFlag(true);
            setNotFoundMessage("");

            setValue("state", res.State.state);
            setValue("city", res.State.city);
            setValue("country", "USA");
          } else {
            setNotFoundMessage("Incorrect Zip Code");
            setStateName("");
            setCityName("");
            setCountryFlag(false);
          }
        }
      } catch (error) {
        console.error("Error making post request:", error);
      }
    } else {
      setStateName("");
      setCityName("");
      setValue("state", "");
      setValue("city", "");
      setValue("country", "");
      setCountryFlag(false);
      setNotFoundMessage("Incorrect Zip Code");
    }
  };

  const handleNext = async () => {
    const result = await trigger(
      [
        currentPage === 1
          ? ["name", "domainName", "email", "phone", "address", "zipcode"]
          : currentPage === 2
          ? ["linkedin", "facebook", "instagram", "twitter", "thread"]
          : currentPage === 3
          ? causes.map((_, index) => `causes[${index}]`)
          : [],
      ].flat()
    );

    if (result) {
      if (currentPage === 1) setIsForm1Complete(true);
      if (currentPage === 2) setIsForm2Complete(true);
      if (currentPage === 3) setIsForm3Complete(true);

      if (currentPage === 2) {
        const values = getValues();
        const hasAtLeastOneLink =
          !!values.linkedin ||
          !!values.facebook ||
          !!values.instagram ||
          !!values.twitter ||
          !!values.thread;
        if (!hasAtLeastOneLink) {
          toast.error("At least one social media link is required");
          return;
        }
        const urlsToValidate = [
          "linkedin",
          "facebook",
          "instagram",
          "twitter",
          "thread",
        ];
        for (let url of urlsToValidate) {
          if (values[url] && !values[url].startsWith("https://")) {
            toast.error(`The ${url} link must start with 'https://'.`);
            return;
          }
        }
      }

      if (currentPage === 1 && (domainAvailability === false || domainNameWarning)) {
        toast.error(
          "Domain name is invalid or already taken. Please choose a different domain name."
        );
        return;
      }

      if (currentPage === 3 && causes.every(cause => cause.trim().length < 3)) {
        toast.error("Each cause must be at least 3 characters long.");
        return;
      }

      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleCauseChange = (index, value) => {
    const updatedCauses = [...causes];
    updatedCauses[index] = value;
    setCauses(updatedCauses);
  };

  const addCauseField = () => {
    setCauses([...causes, ""]);
  };

  return (
    <>
      <DonorManagementTop />
      <div className="flex flex-col items-center">
        <div className="w-full md:py-4 px-4 md:px-16">
          <div className="flex flex-col md:flex-row">
            <Link to="/services-dashboard">
              <div className="absolute top-12 left-2 m-2">
                <button className="btn-style-1 text-white hover:bg-blue-600  rounded-full">
                  <svg
                    enableBackground="new 0 0 256 256"
                    height="40px"
                    viewBox="0 0 256 256"
                    width="40px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path
                      fill="white"
                      d="M203.313,128c0,3.534-2.865,6-6.4,6H74.878l47.141,45.956c2.538,2.463,2.597,6.616,0.138,9.149  c-1.256,1.295-2.925,1.995-4.594,1.995c-1.606,0-3.216-0.576-4.456-1.782l-58.466-56.737c-1.244-1.206-1.943-2.855-1.943-4.587  s0.699-3.385,1.943-4.591l58.466-56.743c2.528-2.459,6.584-2.401,9.05,0.139c2.459,2.534,2.4,6.789-0.138,9.251L74.878,122h122.034  C200.447,122,203.313,124.466,203.313,128z M256,128C256,57.422,198.578,0,128,0S0,57.422,0,128s57.422,128,128,128  S256,198.578,256,128z M243.2,128c0,63.521-51.679,115.2-115.2,115.2S12.8,191.521,12.8,128S64.479,12.8,128,12.8  S243.2,64.479,243.2,128z"
                    />
                  </svg>
                </button>
              </div>
            </Link>
            <div className="sidebar hidden lg:block p-4 shadow-md bg-white rounded-lg mr-4 lg:w-1/4">
              <div className="flex flex-col space-y-4">
                <div
                  className="text-left cursor-pointer"
                  onClick={() => setCurrentPage(1)}
                  onMouseEnter={() => setHoveredSection(1)}
                  onMouseLeave={() => setHoveredSection(null)}
                >
                  <span
                    className={`block w-full text-left px-4 py-2 rounded ${
                      currentPage === 1
                        ? "bg-gray-300 text-blue-700 font-bold"
                        : "bg-white text-black font-normal"
                    }`}
                  >
                    Basic Information
                    {isForm1Complete && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="ml-2 text-green-500"
                      />
                    )}
                  </span>
                </div>
                <div
                  className={`text-left ${
                    isForm1Complete
                      ? "cursor-pointer"
                      : "pointer-events-none opacity-50"
                  }`}
                  onClick={() => isForm1Complete && setCurrentPage(2)}
                  onMouseEnter={() => setHoveredSection(2)}
                  onMouseLeave={() => setHoveredSection(null)}
                >
                  <span
                    className={`block w-full text-left px-4 py-2 rounded ${
                      currentPage === 2
                        ? "bg-gray-300 text-blue-700 font-bold"
                        : "bg-white text-black font-normal"
                    }`}
                  >
                    Add Social Media Links
                    {isForm2Complete && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="ml-2 text-green-500"
                      />
                    )}
                    {!isForm1Complete && hoveredSection === 2 && (
                      <FontAwesomeIcon
                        icon={faBan}
                        className="ml-2 text-red-500"
                      />
                    )}
                  </span>
                </div>
                <div
                  className={`text-left ${
                    isForm2Complete
                      ? "cursor-pointer"
                      : "pointer-events-none opacity-50"
                  }`}
                  onClick={() => isForm2Complete && setCurrentPage(3)}
                  onMouseEnter={() => setHoveredSection(3)}
                  onMouseLeave={() => setHoveredSection(null)}
                >
                  <span
                    className={`block w-full text-left px-4 py-2 rounded ${
                      currentPage === 3
                        ? "bg-gray-300 text-blue-700 font-bold"
                        : "bg-white text-black font-normal"
                    }`}
                  >
                    Add Cause
                    {isForm3Complete && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="ml-2 text-green-500"
                      />
                    )}
                    {!isForm2Complete && hoveredSection === 3 && (
                      <FontAwesomeIcon
                        icon={faBan}
                        className="ml-2 text-red-500"
                      />
                    )}
                  </span>
                </div>
                <div
                  className={`text-left ${
                    isForm3Complete
                      ? "cursor-pointer"
                      : "pointer-events-none opacity-50"
                  }`}
                  onClick={() => isForm3Complete && setCurrentPage(4)}
                  onMouseEnter={() => setHoveredSection(4)}
                  onMouseLeave={() => setHoveredSection(null)}
                >
                  <span
                    className={`block w-full text-left px-4 py-2 rounded ${
                      currentPage === 4
                        ? "bg-gray-300 text-blue-700 font-bold"
                        : "bg-white text-black font-normal"
                    }`}
                  >
                    Add Logo
                    {!isForm3Complete && hoveredSection === 4 && (
                      <FontAwesomeIcon icon={faBan} className="ml-2 text-red-500" />
                    )}
                  </span>
                </div>
              </div>
            </div>
            <form
              onSubmit={handleSubmit(onSubmitHandler)}
              className="fundraiser-for-other-form md:py-12 md:px-20 p-6 shadow-lg rounded-lg bg-white flex-grow"
              autoComplete="off"
            >
              <div className="mb-6 text-center mt-8">
                <h2 className="text-2xl font-bold">Create Your Own Domain</h2>
              </div>
              {currentPage === 1 && (
                <>
                  <h3 className="text-xl font-bold mb-4">Basic Information</h3>
                  <div className="mb-6 md:flex">
                    <label className="w-full md:w-1/3">
                      Organization Name *
                    </label>
                    <div className="w-full">
                      <input
                        {...register("name")}
                        placeholder="Enter Your Organization Name"
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.name && "border-red-500"
                        } rounded`}
                        autoComplete="new-password"
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {errors.name?.message}
                      </p>
                    </div>
                  </div>
                  <div className="mb-6 md:flex">
                    <label className="w-full md:w-1/3">Domain Name *</label>
                    <div className="w-full">
                      <input
                        {...register("domainName")}
                        placeholder="Enter Domain Name"
                        type="text"
                        readOnly={domainId !== null}
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.domainName && "border-red-500"
                        } rounded`}
                        onChange={handleDomainNameChange}
                        autoComplete="new-password"
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {domainNameWarning || errors.domainName?.message}
                      </p>
                      {domainName && (
                        <p className="text-sm mt-1">
                          {domainName}.prabalta.org{" "}
                          {domainAvailability === null ? null : domainAvailability && !domainNameWarning ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="text-green-500"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="text-red-500"
                            />
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mb-6 md:flex">
                    <label className="w-full md:w-1/3">Email *</label>
                    <div className="w-full">
                      <input
                        {...register("email")}
                        placeholder="Add Support Email"
                        type="email"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.email && "border-red-500"
                        } rounded`}
                        autoComplete="new-password"
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {errors.email?.message}
                      </p>
                    </div>
                  </div>
                  <div className="mb-6 md:flex">
                    <label className="w-full md:w-1/3">Contact Number *</label>
                    <div className="w-full">
                      <input
                        {...register("phone")}
                        placeholder="Add Your Phone Number"
                        type="text"
                        maxLength={12}
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.phone && "border-red-500"
                        } rounded`}
                        autoComplete="new-password"
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {errors.phone?.message}
                      </p>
                    </div>
                  </div>
                  <div className="mb-6 md:flex">
                    <label className="w-full md:w-1/3">Address *</label>
                    <div className="w-full">
                      <input
                        {...register("address")}
                        placeholder="Enter Address"
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.address && "border-red-500"
                        } rounded`}
                        autoComplete="new-password"
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {errors.address?.message}
                      </p>
                    </div>
                  </div>
                  <div className="mb-6 md:flex">
                    <label className="w-full md:w-1/3">Zip Code *</label>
                    <div className="w-full">
                      <input
                        {...register("zipcode")}
                        placeholder="zipcode"
                        onBlur={zipCodeDetails}
                        maxLength={6}
                        type="number"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.zipcode && "border-red-500"
                        } rounded`}
                        autoComplete="new-password"
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {errors.zipcode?.message}
                      </p>
                      {!errors.zipcode?.message && (
                        <p className="text-red-500 text-sm mt-1">
                          {notFoundMessage}
                        </p>
                      )}

                      {cityName && stateName && countryFlag && (
                        <div className="text-bold mt-1">
                          <em>
                            {" "}
                            <b>{`${cityName}, ${stateName}, USA`}</b>
                          </em>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {currentPage === 2 && (
                <>
                  <h3 className="text-xl font-bold mb-4">
                    Add Social Media Links
                  </h3>
                  <div className="mb-6 md:flex">
                    <label className="w-full md:w-1/3">Linkedin Link *</label>
                    <div className="w-full">
                      <input
                        {...register("linkedin")}
                        placeholder="Add Your Linkedin Link"
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.linkedin && "border-red-500"
                        } rounded`}
                        autoComplete="new-password"
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {errors.linkedin?.message}
                      </p>
                    </div>
                  </div>
                  <div className="mb-6 md:flex">
                    <label className="w-full md:w-1/3">Facebook Link *</label>
                    <div className="w-full">
                      <input
                        {...register("facebook")}
                        placeholder="Add Your Facebook Link"
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.facebook && "border-red-500"
                        } rounded`}
                        autoComplete="new-password"
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {errors.facebook?.message}
                      </p>
                    </div>
                  </div>
                  <div className="mb-6 md:flex">
                    <label className="w-full md:w-1/3">Instagram Link *</label>
                    <div className="w-full">
                      <input
                        {...register("instagram")}
                        placeholder="Add Your Instagram Link"
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.instagram && "border-red-500"
                        } rounded`}
                        autoComplete="new-password"
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {errors.instagram?.message}
                      </p>
                    </div>
                  </div>
                  <div className="mb-6 md:flex">
                    <label className="w-full md:w-1/3">Twitter Link *</label>
                    <div className="w-full">
                      <input
                        {...register("twitter")}
                        placeholder="Add Your Twitter Link"
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.twitter && "border-red-500"
                        } rounded`}
                        autoComplete="new-password"
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {errors.twitter?.message}
                      </p>
                    </div>
                  </div>
                  <div className="mb-6 md:flex">
                    <label className="w-full md:w-1/3">Thread Link *</label>
                    <div className="w-full">
                      <input
                        {...register("thread")}
                        placeholder="Add Your Thread Link"
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.thread && "border-red-500"
                        } rounded`}
                        autoComplete="new-password"
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {errors.thread?.message}
                      </p>
                    </div>
                  </div>
                </>
              )}
              {currentPage === 3 && (
                <>
                  <h3 className="text-xl font-bold mb-4">Add Cause</h3>
                  {causes.map((cause, index) => (
                    <div key={index} className="mb-6 md:flex">
                      <label className="w-full md:w-1/3">{`Cause ${index + 1}`}</label>
                      <div className="w-full">
                        <input
                          value={cause}
                          onChange={(e) =>
                            handleCauseChange(index, e.target.value)
                          }
                          placeholder={`Cause ${index + 1}`}
                          type="text"
                          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded`}
                          readOnly={domainId !== null} // Make readonly if updating
                          autoComplete="new-password"
                        />
                        {errors.causes && errors.causes[index] && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.causes[index].message}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-end mb-4">
                    {!domainId && causes.length < 5 && (
                      <button
                        type="button"
                        onClick={addCauseField}
                        className="btn-style-1 text-white p-2 rounded-md"
                        disabled={
                          causes.some((cause) => cause.length < 3) ||
                          causes.length >= 5
                        }
                      >
                        Add Cause
                      </button>
                    )}
                  </div>
                </>
              )}
              {currentPage === 4 && (
                <>
                  <h3 className="text-xl font-bold mb-4">Add Logo</h3>
                  <div className="md:flex">
                    <div className="w-full md:w-1/3 flex flex-col items-center justify-center">
                      {uploadedFile ? (
                        <img
                          src={uploadedFile.preview}
                          alt="Logo Preview"
                          className="w-40 h-40 object-contain mb-4"
                        />
                      ) : logoUrl ? (
                        <img
                          src={logoUrl}
                          alt="Logo"
                          className="w-40 h-40 object-contain mb-4"
                        />
                      ) : null}
                    </div>
                    <div
                      className="w-full md:w-2/3 flex justify-between items-center my-5 md:my-0"
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                    >
                      <div
                        className="mb-6 relative flex flex-col items-center w-full"
                        style={{
                          borderColor: isDragOver ? "blue" : "gray",
                          boxShadow: isDragOver
                            ? "0 0 10px rgba(0, 0, 255, 0.5)"
                            : "none",
                          borderRadius: "8px",
                          borderStyle: "dashed",
                          borderWidth: "2px",
                          paddingInline: "20px",
                          paddingBlock: "10px",
                          cursor: "pointer",
                        }}
                      >
                        {uploadedFile && (
                          <div className="flex items-center mb-2">
                            <p className="mr-2">{uploadedFile.file.name}</p>
                            <button
                              type="button"
                              className="text-red-500 hover:text-red-700 cursor-pointer"
                              onClick={removeFile}
                            >
                              &#x2715;
                            </button>
                          </div>
                        )}
                        <p className="text-red-500 text-sm mt-1 text-center">
                          {/* * Logo must be 200x200 pixels */}
                        </p>
                        <label
                          htmlFor="documentInput"
                          className="cursor-pointer"
                        >
                          <img
                            src="assets/images/cloud-icon.svg"
                            className="mx-auto"
                            alt="Cloud Icon"
                          />
                          <p className="text-xs text-center">
                            <span className="font-bold underline">
                              Click to upload Logo
                            </span>
                          </p>
                        </label>
                        <input
                          {...register("document")}
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          onChange={handleFileChange}
                          className="hidden"
                          id="documentInput"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center mt-4">
                    <button
                      type="submit"
                      className="md:w-1/6 w-full text-white p-2 rounded-md btn-style-1"
                    >
                      {domainId ? "Update" : "Submit"}
                    </button>
                  </div>
                </>
              )}
              <div className="flex justify-between items-center mt-4">
                {currentPage > 1 && (
                  <button
                    type="button"
                    onClick={handlePrev}
                    className="md:w-1/6 w-1/3 text-white p-2 rounded-md btn-style-4"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Previous
                  </button>
                )}
                {currentPage < 4 && (
                  <button
                    type="button"
                    onClick={handleNext}
                    className="md:w-1/6 w-1/3 text-white p-2 rounded-md btn-style-1 ml-auto"
                  >
                    Next{" "}
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                  </button>
                )}
              </div>
            </form>
          </div>
          <ToastContainer theme="colored" />
        </div>
      </div>

      {isLoading && (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
          <Triangle height="120" width="120" color="blue" ariaLabel="loading" />
          <p className="text-gray-300 mt-4">
            Please wait, your sub domain is being created...
          </p>
        </div>
      )}
    </>
  );
};

export default CreateDomain;
