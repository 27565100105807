import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { TQ_URL } from "../../utility/baseUrl";
import * as yup from "yup";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { getTypeRequestWithTokenWithDataWithAuth } from "../../utility/apiRequest";
import InputMask from "react-input-mask";
import { Triangle } from "react-loader-spinner";
import axios from "axios";
import Modal from "react-modal";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
  patchRequestWithTokenWithData,
  getRequestWithTokenWithDataWithAuth,
} from "../../utility/apiRequest";
import './bank_modalStyles.css';

const CustomInput = forwardRef((props, ref) => (
  <InputMask {...props} inputRef={ref} />
));


const schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(5, "Name must be at least 5 characters")
    .max(50, "Name must be at most 50 characters")
    .required("Name is Required *"),
  contactPerson: yup
    .string()
    .trim()
    .min(5, "Contact person name must be at least 5 characters")
    .required("Contact person name is Required *"),
  email: yup
    .string()
    .trim()
    .email("Invalid email format")
    .required("Email is Required *"),
  phone: yup
    .string()
    .typeError("Phone must be a number")
    .matches(
      /^\(\d{3}\) \d{3}-\d{4}$/,
      "Phone must be in the format (999) 999-9999"
    )
    .required("Phone is Required *"),

  address: yup.string().required("Address is Required * "),
  zipcode: yup
    .number()
    .required("ZipCode is Required *")
    .positive("ZipCode should be positive or non-zero")
    .typeError("ZipCode should not be Empty"),
  // primaryColor: yup.string().required("Primary Color is Required *"),
  // secondaryColor: yup.string().required("Secondary Color is Required *"),
});

const ProfilePage = () => {
  const [stateName, setStateName] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [countryFlag, setCountryFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [partnerDetails, setPartnerDetails] = useState("");
  const [bankDetails, setBankDetails] = useState([]); // Bank details state
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state
  const [selectedBankIndex, setSelectedBankIndex] = useState(null); // To manage the selected bank radio button
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  // State to manage the loading of default bank details
  const [isLoadingDefaultBank, setIsLoadingDefaultBank] = useState(false);

  // State to store the default bank account details
  const [defaultBankDetails, setDefaultBankDetails] = useState(null);


  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  // Fetch bank details based on partnerId
  const fetchBankDetails = async () => {
    setIsLoadingModal(true);
    try {
      const partnerId = partnerData.partnerId;
      const response = await axios.get(`${TQ_URL}/fetch-bank-details/${partnerId}`);
      const bankData = response.data;

      // Store the bank details along with the methodId
      setBankDetails(bankData);

      // Find the default bank and set the default selectedBankIndex
      const defaultBankIndex = bankData.findIndex((bank) => bank.isDefault);
      setSelectedBankIndex(defaultBankIndex);
    } catch (error) {
      toast.error("Failed to fetch bank details.");
      console.error("Error fetching bank details:", error);
    } finally {
      setIsLoadingModal(false);
    }
  };


  const openModal = () => {
    setBankDetails([]); // Clear previous bank details
    setSelectedBankIndex(null);
    setIsModalOpen(true);
    fetchBankDetails(); // Fetch fresh data
  };

  const closeModal = () => {
    fetchDefaultBankDetails();
    setIsModalOpen(false);

  };

  const handleDefaultChange = async (selectedIndex) => {
    setSelectedBankIndex(selectedIndex);

    const selectedBank = bankDetails[selectedIndex];
    const methodId = selectedBank.methodId || selectedBank.id; // Ensure you're getting the correct methodId

    if (methodId) {
      try {
        const partnerId = partnerData.partnerId;
        const response = await axios.put(
          `${TQ_URL}/update-default?methodId=${methodId}&partnerId=${partnerId}`
        );

        if (response.status === 200) {
          toast.success(response.data.message || "Default bank account updated successfully.");

          // After successful update, manually update the bankDetails state to mark the selected bank as default
          const updatedBankDetails = bankDetails.map((bank, index) => ({
            ...bank,
            isDefault: index === selectedIndex, // Only mark the selected bank as default
          }));

          setBankDetails(updatedBankDetails); // Update state with the new bank details
        } else {
          toast.error(response.data.error || "Failed to update the default bank account");
        }
      } catch (error) {
        console.error("Error updating default bank account:", error);
        toast.error("Error updating default bank account");
      }
    } else {
      console.error("methodId is undefined or missing");
    }
  };

  const defaultBank = bankDetails.find((bank) => bank.isDefault);

  // Method to fetch the default bank account details
  const fetchDefaultBankDetails = async () => {
    try {
      setIsLoadingDefaultBank(true);
      const response = await axios.get(`${TQ_URL}/fetch-default-bank/${partnerData.partnerId}`);

      if (response.status === 200 && response.data) {
        setDefaultBankDetails(response.data); // Set the default bank details to state
      } else {
        toast.error("No default bank account found.");
      }
    } catch (error) {
      console.error("Error fetching default bank details:", error);
      toast.error("Failed to fetch default bank details.");
    } finally {
      setIsLoadingDefaultBank(false);
    }
  };


  const formatAccountNumber = (accountNumber) => {
    if (!accountNumber) return "";

    // Mask everything except the last 4 digits
    const visibleDigits = accountNumber.slice(-4);
    const maskedPart = accountNumber.slice(0, -4).replace(/\d/g, "x");

    // Join the masked part and visible digits with hyphens
    return `${maskedPart}-${visibleDigits}`;
  };

  // Open modal and fetch bank details


  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchPartnerDetail = async () => {
    const partnerId = parseInt(partnerData.partnerId);

    try {
      const partnerData = await getTypeRequestWithTokenWithDataWithAuth(
        `${TQ_URL}/getPartnerProfile?partnerId=${partnerId}`
      );

      const PartnerDetail = partnerData.data;
      setPartnerDetails(partnerData.data);
      if (PartnerDetail) {
        setValue("name", PartnerDetail.orgName);
        setValue("contactPerson", PartnerDetail.contactPersonName);
        setValue("email", PartnerDetail.email);
        setValue("address", PartnerDetail.addressOne);
        setValue("zipcode", PartnerDetail.zipCode);
        zipCodeDetails({ target: { value: PartnerDetail.zipCode } });
      }
    } catch (error) {
      console.error("Error fetching domain details:", error);
    }
  };

  useEffect(() => {
    fetchPartnerDetail();
    fetchDefaultBankDetails();
  }, [partnerData.partnerId, setValue]);

  useEffect(() => {
    if (partnerDetails?.contactNumber) {
      setValue("phone", partnerDetails.contactNumber);
    }
  }, [partnerDetails, setValue]);

  const zipCodeDetails = async (event) => {
    const zipcode = event.target.value;
    if (!zipcode) {
      setNotFoundMessage("Zip Code is required");
      setStateName("");
      setCityName("");
      setValue("state", "");
      setCountryFlag(false);
      return;
    }
    if (zipcode.length === 5) {
      try {
        const response = await fetch(`${TQ_URL}/getAddressDetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            zipcode: zipcode,
          }),
        });

        if (response.status === 200) {
          const res = await response.json();
          if (res.Status === "Succsess") {
            setStateName(res.State.state);
            setCityName(res.State.city);
            setCountryFlag(true);
            setValue("state", res.State.state);
            setValue("city", res.State.city);
            setValue("country", "USA");
          } else {
            setNotFoundMessage("Incorrect Zip Code");
            setStateName("");
            setCityName("");
            setCountryFlag(false);
          }
        }
      } catch (error) {
        console.error("Error fetching zip code details:", error);
      }
    } else {
      setStateName("");
      setCityName("");
      setValue("state", "");
      setCountryFlag(false);
      setNotFoundMessage("Incorrect Zip Code");
    }
  };


  const getAddBankAccount = async () => {
    try {
      const partnerJson = JSON.parse(localStorage.getItem("partnerData"));
      const partnerJsonId = partnerJson.partnerId;
      const response = await axios.post(`${TQ_URL}/setup-payment`, {
        clientId: partnerJsonId,
      });

      if (response.data.url) {
        const accountId = extractAccountIdFromUrl(response.data.url);
        if (accountId) {
          localStorage.setItem("stripeAccountId", accountId);
        }
        const popup = window.open(
          response.data.url,
          "stripePopup",
          "width=600,height=700"
        );
        const timer = setInterval(async () => {
          if (popup.closed) {
            clearInterval(timer);
            await fetchRecentPaymentMethod(partnerJsonId);
          }
        }, 500);
      } else {
        toast.error("Failed to initiate bank account setup.");
      }
    } catch (error) {
      toast.error("Something went wrong while setting up the bank account.");
    }
  };

  const extractAccountIdFromUrl = (url) => {
    const regex = /(acct_[a-zA-Z0-9]+\/[a-zA-Z0-9]+)$/;
    const match = url.match(regex);
    return match ? match[0] : null;
  };

  const handleRemoveAccount = () => {
    setPaymentMethod(null);
  };

  const onSubmitHandler = async (data) => {
    setIsLoading(true);
    const isValid = await trigger();
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("accessToken");
      const id = partnerData.partnerId;
      const formData = new FormData();
      formData.append("organizationName", toInitCap(data.name));
      formData.append("contactPerson", toInitCap(data.contactPerson));
      formData.append("partnerEmail", data.email);
      formData.append("contactNo", data.phone);
      formData.append("address", data.address);
      formData.append("city", cityName);
      formData.append("state", stateName);
      formData.append("country", "United States");
      formData.append("postalCode", data.zipcode);
      formData.append("partnerId", partnerData.partnerId);

      const url = `${TQ_URL}/updatePartnerProfile/${id}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: { Authorization: token },
        body: formData,
      });

      const userData = await response.json();
      if (response.status === 200) {
        toast.success("Profile updated successfully", {
          position: "top-right",
        });
        localStorage.setItem("partnerData", JSON.stringify(userData.partner));
      } else {
        const errorText = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status}, message: ${errorText}`
        );
      }
    } catch (error) {
      console.error("Failed to submit form:", error);
      toast.error("Failed to save the details", { position: "top-right" });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRecentPaymentMethod = async (clientId) => {
    try {
      const stripeAccountId = localStorage.getItem("stripeAccountId"); // Fetch from localStorage
      const isDefaultAccount = localStorage.getItem("isDefaultAccount"); // Fetch isDefaultAccount flag from localStorage

      if (!stripeAccountId) {
        console.error("Stripe account ID is missing.");
        return;
      }

      const url = `${TQ_URL}/recent-payment-method`;
      const requestData = {
        clientId,
        accountId: stripeAccountId,
        isDefault: isDefaultAccount === 'true' // Convert to boolean and send even if false
      };

      const response = await getRequestWithTokenWithDataWithAuth(url, requestData);

      if (response && response.paymentMethod) {
        setPaymentMethod(response.paymentMethod); // Store payment method details
        setErrorMessage(null); // Clear any previous errors
      } else {
        setErrorMessage("No payment method found, please add a bank account.");
      }

      // Remove the account ID and isDefaultAccount from localStorage after use
      localStorage.removeItem("stripeAccountId");
      localStorage.removeItem("isDefaultAccount");
      fetchDefaultBankDetails();

    } catch (error) {
      if (error.response && error.response.status === 404) {
        setErrorMessage("No payment method added, please try again.");
      } else {
        setErrorMessage("Something went wrong while fetching the payment method.");
      }
    }
  };


  return (
    <>
      <DonorManagementTop />
      <div className="absolute top-12 left-2 m-2 flex items-center">
        <button
          className="btn-style-1 text-white hover:bg-blue-600 rounded-full"
          onClick={(e) => {
            e.preventDefault();
            window.history.back();
          }}
        >
          <svg
            enableBackground="new 0 0 256 256"
            height="40px"
            viewBox="0 0 256 256"
            width="40px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="white" d="..." />
          </svg>
        </button>
      </div>
      <div className="flex flex-col items-center">
        <div className="w-full md:py-4 px-4 md:px-16">
          <div className="flex flex-col md:flex-row">
            <form
              onSubmit={handleSubmit(onSubmitHandler)}
              className="fundraiser-for-other-form md:py-12 md:px-20 p-6 shadow-lg rounded-lg bg-white flex-grow"
            >
              <div className="mb-6 text-center">
                <h2 className="text-2xl font-bold">Profile Page</h2>
              </div>
              <div className="mb-6 md:flex">
                <label className="w-full md:w-1/3">Organization Name *</label>
                <div className="w-full">
                  <input
                    {...register("name")}
                    placeholder="Enter Your Organization Name"
                    type="text"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.name && "border-red-500"
                      } rounded`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.name?.message}
                  </p>
                </div>
              </div>
              <div className="mb-6 md:flex">
                <label className="w-full md:w-1/3">Email *</label>
                <div className="w-full">
                  <input
                    {...register("email")}
                    placeholder="Add Support Email"
                    type="email"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.email && "border-red-500"
                      } rounded`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.email?.message}
                  </p>
                </div>
              </div>
              <div className="mb-6 md:flex">
                <label className="w-3/12">Contact Number *</label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      mask="(999) 999-9999"
                      placeholder="Contact Number *"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.phone && "border-red-500"
                        }`}
                    />
                  )}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.phone?.message}
                </p>
              </div>
              <div className="mb-6 md:flex">
                <label className="w-full md:w-1/3">Address *</label>
                <div className="w-full">
                  <input
                    {...register("address")}
                    placeholder="Enter Address"
                    type="text"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.address && "border-red-500"
                      } rounded`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.address?.message}
                  </p>
                </div>
              </div>
              <div className="mb-6 md:flex">
                <label className="w-full md:w-1/3">Zip Code *</label>
                <div className="w-full">
                  <input
                    {...register("zipcode")}
                    placeholder="Zip Code"
                    onBlur={zipCodeDetails}
                    maxLength={6}
                    type="number"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.zipcode && "border-red-500"
                      } rounded`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.zipcode?.message}
                  </p>
                  {!errors.zipcode?.message && (
                    <p className="text-red-500 text-sm mt-1">
                      {notFoundMessage}
                    </p>
                  )}
                  {cityName && stateName && countryFlag && (
                    <div className="text-bold mt-1">
                      <em>
                        <b>{`${cityName}, ${stateName}, USA`}</b>
                      </em>
                    </div>
                  )}
                </div>
              </div>



              {/* Add Bank Details or Display Existing Account */}
              <div className="mb-6 relative md:flex items-center space-x-4">
                <label className="block w-full md:w-1/3">Link Bank Account</label>

                <div className="flex space-x-4 w-full">

                  {/* Link Bank Account Card */}
                  <div className="flex-1">
                    {errorMessage ? (
                      <div className="bg-white border border-gray-300 shadow-lg rounded-lg p-4 w-full">
                        <div className="text-red-600">
                          <h3 className="text-lg font-semibold">Bank Account Error</h3>
                          <p className="text-sm mt-2">{errorMessage}</p>
                          <p className="text-sm">Please add a bank account for donations to proceed.</p>
                        </div>
                        <div className="text-center mt-4">
                          <button
                            onClick={getAddBankAccount}
                            className="text-blue-500 underline cursor-pointer"
                          >
                            Add Bank Account
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="p-4 bg-white border border-gray-300 shadow-sm rounded-lg cursor-pointer hover:shadow-lg transition-shadow duration-300 w-full"
                        onClick={getAddBankAccount}
                      >
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              className="h-8 w-8 text-blue-600"
                              fill="currentColor"
                            >
                              <path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160l0 8c0 13.3 10.7 24 24 24l400 0c13.3 0 24-10.7 24-24l0-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224l-64 0 0 196.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512l448 0c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1L448 224l-64 0 0 192-40 0 0-192-64 0 0 192-48 0 0-192-64 0 0 192-40 0 0-192zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <h3 className="text-lg font-medium text-gray-900">Link Bank Account</h3>
                            <p className="text-sm text-gray-500">Click to add a bank account for donations.</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>


                  {/* View Bank Details Card */}
                  {/* <div className="flex-1">
                    <div
                      className="p-4 bg-white border border-gray-300 shadow-sm rounded-lg cursor-pointer hover:shadow-lg transition-shadow duration-300 w-full"
                      onClick={openModal}
                    >
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="h-8 w-8 text-blue-600"
                            fill="currentColor"
                          >
                            <path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160l0 8c0 13.3 10.7 24 24 24l400 0c13.3 0 24-10.7 24-24l0-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224l-64 0 0 196.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512l448 0c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1L448 224l-64 0 0 192-40 0 0-192-64 0 0 192-48 0 0-192-64 0 0 192-40 0 0-192zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                          </svg>
                        </div>
                        <div className="ml-4">
                          <h3 className="text-lg font-medium text-gray-900">View Bank Details</h3>
                          <p className="text-sm text-gray-500">Click to view your bank account details.</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="mb-6 relative md:flex items-center space-x-4">
                <label className="w-full md:w-1/3">Default Bank</label>

                <div className="w-full">
                  {isLoadingDefaultBank ? (
                    <div className="flex justify-center">
                      <Triangle height="40" width="40" color="blue" ariaLabel="loading" />
                    </div>
                  ) : defaultBankDetails ? (
                    <div
                      className="p-4 bg-white border border-gray-300 shadow-sm rounded-lg cursor-pointer hover:shadow-lg transition-shadow duration-300 w-full flex items-center justify-between"
                    >
                      <div className="flex items-center">
                        {/* SVG Icon for Default Bank */}
                        <div className="flex-shrink-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="h-10 w-10"
                          >
                            <path
                              fill="#0fbd4c"
                              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
                            />
                          </svg>
                        </div>

                        <div className="ml-4">
                          {/* Bank Account Details */}
                          <p className="text-sm mt-2">
                            <span className="font-bold">Bank Name:</span> {defaultBankDetails.bankName}
                          </p>
                          <p className="text-sm mt-2">
                            <span className="font-bold">Account Number:</span> XXXXXX
                            {defaultBankDetails.last4Digits}
                          </p>
                        </div>
                      </div>

                      {/* Button for Other Connected Accounts */}
                      <div className="ml-auto">
                        <button
                          onClick={openModal}
                          className="bg-indigo-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-indigo-700 transform hover:scale-105 transition-transform duration-300 ease-in-out"
                        >
                          Other Connected Accounts
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p className="text-red-500">No default bank account found.</p>
                  )}
                </div>
              </div>

              <div className="flex justify-center items-center mt-4">
                <button
                  type="submit"
                  className="md:w-1/6 w-full text-white p-2 rounded-md btn-style-1"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
          <ToastContainer theme="colored" />
        </div>
      </div>

      {/* Bank Details Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false} // Prevents closing on outside clicks
        contentLabel="Bank Details"
        className="bank_modal p-6 fixed-size-modal border-none outline-none shadow-lg rounded-lg"
        overlayClassName="bank_modal-overlay"
      >
        {/* Modal Header */}
        <div className="bank_modal-header flex justify-between items-center pb-4">
          <h2 className="bank_modal-title text-xl font-bold">Bank Details</h2>
          {/* Close button */}
          <button
            onClick={closeModal}
            className="bank_modal-close text-red-500 hover:text-red-700 font-bold text-2xl transform transition-transform duration-200 hover:scale-125"
          >
            &times;
          </button>
        </div>

        {/* Modal Body */}
        <div className="bank_modal-body space-y-4">
          {isLoadingModal ? (
            // Show the loading spinner centered within the modal
            <div className="flex flex-col justify-center items-center h-full" style={{ height: '300px' }}>
              <Triangle height="80" width="80" color="blue" ariaLabel="loading" />
              <p className="text-gray-600 mt-4">Please wait, we are loading your bank accounts...</p>
            </div>
          ) : (
            <>
              {/* Default Account Section */}
              {defaultBank && (
                <div className="default-account-section">
                  <h3 className="text-lg font-bold mb-2">Default Account</h3>
                  <div className="flex items-center p-4 rounded-lg shadow-lg bg-blue-100">
                    <div className="flex-shrink-0">
                      {/* Bank SVG */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="h-8 w-8 text-blue-600"
                        fill="currentColor"
                      >
                        <path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160l0 8c0 13.3 10.7 24 24 24l400 0c13.3 0 24-10.7 24-24l0-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224l-64 0 0 196.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512l448 0c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1L448 224l-64 0 0 192-40 0 0-192-64 0 0 192-48 0 0-192-64 0 0 192-40 0 0-192zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                      </svg>
                    </div>

                    <div className="ml-6 flex-grow">
                      <p className="text-sm mt-2">
                        <span className="font-bold">Bank Name:</span> {defaultBank.bankName}
                      </p>
                      <p className="text-sm">
                        <span className="font-bold">Account Number:</span> {'XXXXXX' + defaultBank.last4Digits}
                      </p>
                    </div>

                    <div className="flex-shrink-0 ml-4">
                      <span className="px-3 py-1 bg-green-500 text-white text-xs rounded-full">
                        Default
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {/* Other Accounts Section */}
              {bankDetails.length > 0 && (
                <div className="other-accounts-section">
                  <h3 className="text-lg font-bold mb-2">Other Accounts</h3>
                  <div className="space-y-4">
                    {bankDetails.map((bank, index) => (
                      <div
                        key={index}
                        className={`flex items-center p-4 rounded-lg shadow-lg ${bank.isDefault ? 'bg-blue-100' : 'bg-blue-50'}`}
                      >
                        {/* Radio Button */}
                        <div className="flex-shrink-0">
                          <input
                            type="radio"
                            name="defaultBank"
                            checked={selectedBankIndex === index}
                            onChange={() => handleDefaultChange(index)} // Call handleDefaultChange when a bank is selected
                            className="form-radio h-5 w-5 text-blue-600"
                          />
                        </div>

                        {/* Bank Information */}
                        <div className="ml-6 flex-grow">
                          <p className="text-sm mt-2">
                            <span className="font-bold">Bank Name:</span> {bank.bankName}
                          </p>
                          <p className="text-sm">
                            <span className="font-bold">Account Number:</span> {'XXXXXX' + bank.last4Digits}
                          </p>
                        </div>

                        {/* Default Mark */}
                        <div className="flex-shrink-0 ml-4">
                          {bank.isDefault || selectedBankIndex === index ? (
                            <span className="px-3 py-1 bg-green-500 text-white text-xs rounded-full">
                              Default
                            </span>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Modal>


      {isLoading && (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
          <Triangle height="120" width="120" color="blue" ariaLabel="loading" />
          <p className="text-gray-300 mt-4">Please wait, your profile is being updated...</p>
        </div>
      )}
    </>
  );
};

export default ProfilePage;
