import React, { useState, useEffect } from "react";
import { getRequestWithTokenWithData } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

// Schema for form validation
const schema = yup.object().shape({
  userName: yup.string().required("User Name is Required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is Required"),
});

const EditmailList = ({ onClose, emailData }) => {
  const [loading, setLoading] = useState(false);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId; // Fetching partnerId from localStorage

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Prepopulate form fields with rowData
  useEffect(() => {
    console.log("Rowdata------------>", emailData);

    if (emailData) {
      setValue("userName", emailData.userName);
      setValue("email", emailData.email);
    }
  }, [emailData, setValue]);

  const onSubmitHandler = async (data) => {
    setLoading(true);
    const mailingListId = localStorage.getItem("mailingListId");
    const requestData = {
      userName: data.userName,
      newEmail: data.email,
      oldEmail: emailData.email,
      mailingListId: mailingListId, // Assuming mailingListId is part of rowData
    };

    console.log(requestData);

    try {
      const response = await getRequestWithTokenWithData(
        `${TQ_URL}/updateMailingListEntry`, // Change endpoint according to your API
        requestData
      );
      console.log(response);

      if (response.success) {
        toast.success(response.message);
        onClose(); // Close the modal on successful save
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error updating email list entry:", error);
      toast.error("An error occurred while updating email list entry.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-scroll z-50 px-4">
      <div className="fade-in-bottom rounded-xl w-[500px]">
        <div className="bg-white rounded-t-xl max-w-2xl w-full flex justify-between items-center">
          <h2 className="text-xl font-bold mb-4 text-start pt-4 px-10">
            Edit Email List Entry
          </h2>
          <button onClick={onClose} className="px-2 cursor-pointer">
            {/* Close button code */}
          </button>
        </div>
        <div className="p-6 bg-blue-50 rounded-b-xl">
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="mb-6">
              <input
                {...register("userName")}
                placeholder="Enter User Name"
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.userName && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.userName?.message}
              </p>
            </div>
            <div className="mb-6">
              <input
                {...register("email")}
                placeholder="Enter Email"
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.email && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.email?.message}
              </p>
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                className="w-full text-white p-2 rounded-md mb-6 btn-style-1 mr-2"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </button>
              <button
                type="button"
                onClick={onClose}
                className="w-full text-white p-2 rounded-md mb-6 btn-style-1 bg-gray-400 hover:bg-gray-500"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer theme="colored" />
    </div>
  );
};

export default EditmailList;
