import React, { useRef, useEffect } from "react";
import { Spinner } from "@material-tailwind/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TQ_URL } from "../../utility/baseUrl";
import {
  getRequestWithTokenWithoutData,
  getRequestWithTokenWithoutDataPartner,
  getTypeRequestWithTokenWithDataWithAuth,
} from "../../utility/apiRequest";

const AccountModal = ({ isOpen, onClose }) => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const modalRef = useRef(null);

  const logLocalData = () => {
    const localStorageData = localStorage.getItem("partnerData");
    console.log("Local Storage Data:", JSON.parse(localStorageData));
    setUserData(JSON.parse(localStorageData));
  };

  const logout = async () => {
    // setTimeout(async () => {
    try{
    const response = await getRequestWithTokenWithoutData(`${TQ_URL}/logoutOauth`);
    localStorage.removeItem("partnerData");
    localStorage.removeItem("userData");
    localStorage.removeItem("accessToken");
    localStorage.setItem("loggedIn", false);

    setUserData(null);
    localStorage.removeItem("tourDonationStep");
    localStorage.removeItem("tourDonorStep");
    localStorage.removeItem("tourFundraiserStep");
    localStorage.removeItem("tourReportStep");
    localStorage.removeItem("tourSettingStep");
    localStorage.removeItem("tourStepNew");
    localStorage.removeItem("tourStepSocial");
    localStorage.removeItem("tourStepEmail");

    navigate("/");
    }
    catch (error) {
      console.error('Error during logout', error);  // Log the error for debugging
        // Ensure loading state is reset even on error
  }
    // }, 1000);
  };

  // useEffect(() => {
  //   logLocalData();
  //   console.log("Useeffect call ---------->");
  // }, []);

  // Close the modal if the user clicks outside of it
  useEffect(() => {
    logLocalData();
    console.log("Useeffect call ---------->");
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="absolute top-14 right-0 md:right-10 z-50">
      <div
        ref={modalRef}
        className="bg-gray-100 rounded-lg shadow-xl w-72 border border-gray-300 relative"
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-red-500 hover:text-red-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        {userData ? (
          <>
            <div className="p-4 pb-0 text-center border-b bg-white rounded-t-lg">
            {userData.userType== "partner" ?  
              <p className="text-sm text-gray-700 font-semibold">
                {userData.email}
              </p> : 
              <p className="text-sm text-gray-700 font-semibold">
                {userData.orgName}
              </p>
              }
              <div className="flex flex-col items-center mt-2">
                <div className="w-14 h-14 rounded-full bg-pink-600 flex items-center justify-center text-white text-3xl">
                  {userData.contactPersonName
                    ? userData.orgName.charAt(0)
                    : "N/A"}
                </div>
                {userData.userType== "partner" ?  
                <p className="text-lg font-medium mt-2 text-gray-800">
                  {" "}
                  Hi,{" "}
                  {userData.orgName != null
                    ? userData.orgName.split(" ")[0]
                    : "User"}
                  !
                </p> : <p className="text-lg font-medium mt-2 text-gray-800">
                  {" "}
                  Hi,{" "}
                  {userData.contactPersonName != null
                    ? userData.contactPersonName.split(" ")[0]
                    : "Subadmin"}
                  !
                </p> }

              </div>
            </div>
            
            {userData.userType== "partner" ?  
            <div className="p-4 text-center border-b bg-white">
              <Link to="/profile">
                <button className="w-full py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-full text-sm font-medium">
                  Manage your Account
                </button>
              </Link>
            </div>
            : null}

<div className={`p-4 flex items-center text-blue-600 text-sm border-b bg-white ${userData.userType === "partner" ? "justify-between" : "justify-center"}`}>
            {userData.userType== "partner" ?  
              <button className="hover:underline flex items-center">
                <svg
                  className="w-5 h-5 mr-1"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2C8.13 2 5 5.13 5 9c0 2.04.79 3.88 2.09 5.26L12 22l4.91-7.74C18.21 12.88 19 11.04 19 9c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9s-1.12 2.5-2.5 2.5z" />
                </svg>
                Add account
              </button>
               : null}
              <button
                className="hover:underline flex items-center"
                onClick={logout}
              >
                <svg
                  className="w-4 h-4 mr-1"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M16 13v-2H7V8l-5 4 5 4v-3h9zm-5 9h11V2H11v4h2V4h7v16h-7v-2h-2v4z" />
                </svg>
                Sign out
              </button>
            </div>

            <div className="p-4 text-xs text-gray-500 text-center bg-white rounded-b-lg">
              <p>
                <a href="#" className="hover:underline">
                  Privacy policy
                </a>{" "}
                ·{" "}
                <a href="#" className="hover:underline">
                  Terms of service
                </a>
              </p>
            </div>
          </>
        ) : (
          <div className="p-4 text-center">Loading...</div>
        )}
      </div>
    </div>
  );
};

export default AccountModal;
