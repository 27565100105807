import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Header from "../Home/Header";
import ProgressBar from "../Common/ProgressBar";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosRefreshCircle } from "react-icons/io";
import { TQ_URL, TQ_URL_USPROJECT } from "../../utility/baseUrl";
import Modal from "react-modal";
import axios from "axios";
import { PinInput } from "@mantine/core";
import { FaPaypal } from "react-icons/fa";
import { FaStripeS } from "react-icons/fa";
import { postRequestWithoutTokenWithoutData } from "../../utility/apiRequest";
import PaypalPayment from "../payment-getways/PaypalPayments";
import PayPalCheckout from "../payment-getways/PayPalCheckout";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const TransactionDetails = ({ onComplete }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const newUserData = JSON.parse(localStorage.getItem("userData"));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [pin, setPin] = useState("");
  const [timer, setTimer] = useState(0);
  const [showRefresh, setShowRefresh] = useState(false);
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);

  const fundraiserId = state?.fundraiserId;
  const fundraiserName = state?.fundraiserName;
  const fundraiserTitle = state?.fundraiserTitle;
  const remainingAmount = state?.remainingAmount;

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPreviousMail, setIsPreviousMail] = useState();
  const [fundraiserShare, setFundraiserShare] = useState(0);
  const [platformFee, setPlatformFee] = useState(0);
  const [paypalLink, setPayPalLink] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);
  const [fundraiserNameZelle, setFundraiserNameZelle] = useState("");
  const [fundraiserIdZelle, setFundraiserIdZelle] = useState("");
  const [donorNameZelle, setDonorNameZelle] = useState("");

  const [animationClass, setAnimationClass] = useState("");
  const [message, setMessage] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [paypalAmount, setPaypalAmount] = useState("");
  const [donationAmount, setDonationAmount] = useState(0);

  const [donationAmtZelle, setDonationAmtZelle] = useState(0);

  // Function to open the modal with animation for Zelle
  const handleZelleRedirect = async () => {
    setAnimationClass("opacity-100 scale-100"); // Add any initial animation classes here
    const result = await trigger(["amount", "fullName", "email", "phone"]); // Trigger validation for specific fields

    if (result) {
      setIsModalOpen(true);

      const storedUserData = localStorage.getItem("userData");
      let userId = 0;
      if (storedUserData != null) {
        const userData = JSON.parse(storedUserData);
        userId = userData.userId;
      }
      try {
        const formData = {
          fundraiser: fundraiserId,
          amount: watch("amount"),
          fullName: watch("fullName"),
          email: watch("email"),
          phone: watch("phone"),
          isAnonymous: watch("anonymously"),
          userId: userId,
          isRegistered: isLoggedIn,
        };

        console.log(formData);

        const response = await axios.post(
          `${TQ_URL_USPROJECT}/saveDonationByZelle`,
          formData
        );

        console.log(response);
        if (response.data.status == "success") {
          // handleCloseModal(); // Close the modal if successful

          // navigate("/zelleSuccess", {
          //   state: {
          //     fundraiserId: response.data.fundraiserId,
          //     fundraiserName: response.data.fundraiserName, // Ensure fundraiserName is defined
          //     donorName: response.data.data.donorName,
          //   },
          // });
          setFundraiserNameZelle(response.data.fundraiserName);
          setFundraiserIdZelle(response.data.fundraiserId);
          setDonorNameZelle(response.data.data.donorName);
          setDonationAmtZelle(watch("amount"));
        } else {
          toast.error("Failed to save donation.");
        }
      } catch (error) {
        console.error("Error saving donation:", error);
        toast.error("Error occurred while saving donation.");
      }
    } else {
      // Fields are invalid, modal will not open and errors will be displayed
      toast.error("Please fill out all required fields correctly.");
    }
  };

  //local and Staging
  const initialOptions = {
    "client-id":
      "AapYhRuiFsaWaUMtFBYwKZE8I9IaMFSt9r-y-3T34jf6IWmNh-2T9OMWbqsUJRUNpdb49PlxWThx_aN-", // Replace this with your actual PayPal client ID
    "enable-funding": "venmo",
    "disable-funding": "paylater",
    currency: "USD",
    components: "buttons",
    locale: "en_US",
  };

  //Live
  // const initialOptions = {
  //   "client-id":
  //   "AaUAqcaotyy4nDg5_dJOpB5btc4X0H3AIgUTM4t3et1xTcpjdUk_qn9Bi4VGkSCVRs0-R4yOLEUlEyS_", // Live
  //   "enable-funding": "venmo",
  //   "disable-funding": "paylater",
  //   currency: "USD",
  //   components: "buttons",
  //   locale: "en_US",
  // };

  const handleCreateOrder = async () => {
    const storedUserData = localStorage.getItem("userData");
    // toast.error("Error occurred while saving donation.");

    let userId = null;
    if (storedUserData != null) {
      const userData = JSON.parse(storedUserData);
      userId = userData.userId;
    }

    try {
      const response = await fetch(`${TQ_URL_USPROJECT}/api/orders`, {
        // Updated URL
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fundraiserId,
          name: watch("fullName"),
          email: watch("email"),
          phone: watch("phone"),
          anonymously: watch("anonymously"),
          userId,
          purchase_units: [
            {
              amount: {
                currency_code: "USD",
                value: watch("amount") ? watch("amount").toString() : "10.00",
              },
              description: `Donation by ${watch("fullName")}`,
              custom_id: fundraiserId,
            },
          ],
          application_context: {
            brand_name: "Prabalta",
            landing_page: "BILLING",
            shipping_preference: "NO_SHIPPING",
            user_action: "PAY_NOW",
          },
        }),
      });

      const orderData = await response.json();
      if (orderData.id) {
        console.log("Order created successfully with ID:", orderData.id);
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        throw new Error(
          errorDetail ? `${errorDetail.description}` : JSON.stringify(orderData)
        );
      }
    } catch (error) {
      console.error("Error creating order:", error);
      setMessage(`Could not initiate PayPal Checkout... ${error}`);
      return null;
    }
  };

  const handleApprove = async (data, actions) => {
    try {
      const storedUserData = localStorage.getItem("userData");
      let userId = null;
      if (storedUserData != null) {
        const userData = JSON.parse(storedUserData);
        userId = userData.userId;
      }

      const requestBody = {
        fundraiserId,
        name: watch("fullName"),
        email: watch("email"),
        phone: watch("phone"),
        anonymously: watch("anonymously"),
        userId,
      };
      //`${TQ_URL_USPROJECT}/api/orders`
      const response = await fetch(
        `${TQ_URL_USPROJECT}/api/orders/${data.orderID}/capture`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody), // Send the request body directly
        }
      );

      const orderData = await response.json();
      const transaction = orderData.purchase_units[0].payments.captures[0];
      setMessage(
        `Donation ${transaction.status}: ${transaction.id}. Thank you for your support!`
      );
      if (transaction.status == "COMPLETED") {
        const formData = {
          fundraiserId: fundraiserId,
          amount: watch("amount"),
          fullName: watch("fullName"),
          email: watch("email"),
          phone: watch("phone"),
          anonymously: watch("anonymously"),
          fundraiserName: fundraiserTitle,
        };
        navigate("/success-paypal", { state: formData });
      }
      console.log("Capture result", orderData);
    } catch (error) {
      console.error("Error capturing order:", error);
      setMessage(`Sorry, your donation could not be processed... ${error}`);
    }
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setAnimationClass("opacity-0 scale-95"); // Add exit animation classes here if desired
    setTimeout(() => setIsModalOpen(false), 500); // Delay to match transition
  };

  console.log("selected caused:", fundraiserId);
  console.log("selected fundname:", fundraiserName);
  console.log("selected fundtitle:", fundraiserTitle);

  // Function to format phone number
  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const isPhoneNumberFormatted = (value) => {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return phoneRegex.test(value);
  };

  const handlePhoneNumberChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setValue("phone", formattedPhoneNumber);
  };

  // Function to format amount as currency
  const formatAmount = (value) => {
    if (!value) return value;
    const amount = parseInt(value.replace(/[^0-9]/g, ""), 10);
    return isNaN(amount) ? "" : `$${amount.toLocaleString("en-US")}`;
  };

  // Function to calculate the fundraiser share and platform fee with a fixed base charge of 30 cents.
  // Function to calculate the fundraiser share and platform fee with a fixed base charge of 30 cents.
  const handleAmountChange = (event) => {
    const { value } = event.target;
    const formattedAmount = formatAmount(value);
    setValue("amount", formattedAmount);
    event.target.value = formattedAmount;

    // Ensure cursor moves to the end of the input
    // setTimeout(() => {
    //   event.target.setSelectionRange(
    //     formattedAmount.length,
    //     formattedAmount.length
    //   );
    // }, 0);

    // Calculate and update the distribution with the base charge of 30 cents.
    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));
    //const baseCharge = 0.3; // 30 cents base charge

    if (!isNaN(numericValue)) {
      // Calculate the platform fee as 4% of the amount plus the base charge.
      const platformFee = Number((numericValue * 0.04).toFixed(2));
      // Calculate the fundraiser share as the total amount minus the platform fee.
      const fundraiserAmount = Number((numericValue - platformFee).toFixed(2));
      setFundraiserShare(fundraiserAmount);
      setDonationAmount(numericValue);
      setPlatformFee(platformFee);
    } else {
      setFundraiserShare(0);
      setPlatformFee(0);
    }
  };

  const handleAmountBlur = (event) => {
    let numericValue = parseInt(event.target.value.replace(/[^0-9]/g, ""), 10);

    // Enforce minimum value of 10 on blur
    if (isNaN(numericValue) || numericValue < 10) {
      numericValue = 10;
      toast.error("Minimum Donation is $10");
    }
    const formattedValue = numericValue.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    event.target.value = formattedValue;
    setValue("amount", formattedValue);

    const platformFee = Number((numericValue * 0.04).toFixed(2));
    const fundraiserAmount = Number((numericValue - platformFee).toFixed(2));
    setFundraiserShare(fundraiserAmount);
    setPlatformFee(platformFee);
  };

  // Update the validation schema to include the max validation for amount and phone number format
  const schema = yup.object().shape({
    amount: yup
      .string()
      .required("Amount is required")
      .matches(/^\$\d{1,3}(,\d{3})*$/, "Invalid amount format")
      .test(
        "max",
        `Amount should not exceed ${formatAmount(remainingAmount.toString())}`,
        (value) => parseInt(value.replace(/[^0-9]/g, ""), 10) <= remainingAmount
      ),
    fullName: yup
      .string()
      .min(5, "Name must be at least 5 characters")
      .max(50, "Name must be at most 50 characters")
      .required("Full Name is Required *"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is Required *"),
    phone: yup
      .string()
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "Phone number must be in the format (XXX) XXX-XXXX"
      )
      .required("Phone is Required *"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    onComplete();
    window.scrollTo(0, 0);
  }, [onComplete]);

  const onSubmitHandler = (data) => {
    // if(isVerifyEmail == false){
    //   toast.info("Please verify email id", { position: "top-right" });
    //   return;
    // }

    const values = {
      fundraiserId: fundraiserId,
      fundraiserName: toInitCap(fundraiserName),
      amount: parseInt(data.amount.replace(/[^0-9]/g, ""), 10),
      phone: data.phone,
      email: data.email,
      donarName: data.fullName,
      anonymously: data.anonymously,
    };

    if (fundraiserId != null && fundraiserName != null) {
      navigate("/stripepay", { state: values });
      successFormSubmit();
    } else toast.error("Something went wrong");
    console.log(data);
  };

  const [showSubmissionPopup, setShowSubmissionPopup] = useState(false);
  const [popupFields, setPopupFields] = useState({
    field1: "",
    field2: "",
    field3: "",
  });

  const handlePopupFieldChange = (fieldName, value) => {
    setPopupFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const onSubmitPopup = () => {
    setShowSubmissionPopup(false);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    const storedUserData = localStorage.getItem("userData");

    let userId = null;

    if (storedToken) {
      console.log("Token fetched from localStorage:", storedToken);
      setIsLoggedIn(true);
      setIsVerifyEmail(true);
    } else {
      console.log("No token found in localStorage.");
      setIsLoggedIn(false);
      setIsVerifyEmail(false);
    }

    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      console.log("UserData fetched from localStorage:", userData);

      setValue("amount", ""); // Assuming 'amount' is not present in userData
      setValue("fullName", userData.userName);
      setValue("email", userData.email);

      userId = userData.userId;

      let phoneNumber = userData.phone
        ? userData.phone.replace("+91", "").trim()
        : "";
      if (phoneNumber && !isPhoneNumberFormatted(phoneNumber)) {
        phoneNumber = formatPhoneNumber(phoneNumber);
      }
      setValue("phone", phoneNumber);
    } else {
      console.log("No userData found in localStorage.");
    }

    // Set the PayPal link based on fundraiserId and include query parameters
  }, [setValue]);

  const successFormSubmit = () => toast.success("Form Submitted Successfully!");
  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  useEffect(() => {
    let interval;
    if (showOtp && timer === 0) {
      setShowRefresh(true);
    }
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showOtp, timer]);

  const email = watch("email");

  const handleChange = async (value) => {
    setPin(value);
    const otpValue = value;
    if (otpValue.length < 4) {
      return;
    }
    console.log("value -------->", value);
    try {
      const response = await fetch(`${TQ_URL_USPROJECT}/verifyOtp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          otp: parseInt(otpValue),
          partnerId: 1,
        }),
      });
      const data = await response.json();
      console.log(response);
      if (data) {
        if (data.status === 200) {
          toast.success("OTP Verified Successfully!");
          setIsVerifyEmail(true);
          setShowOtp(false);
          setTimer(0);
          setPin("");
          setShowRefresh(false);
        } else if (data.status === 401) {
          toast.error("Incorrect OTP. Please try again.");
        } else if (data.status === 409) {
          toast.error("OTP Expired. Please request a new OTP.");
        } else {
          toast.error("An unexpected error occurred.");
        }
      } else {
        toast.error("Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred while verifying OTP.");
      setIsVerifyEmail(false);
    }
  };
  const otpFunction = async () => {
    if (!email) {
      toast.error("Please enter a valid email address", {
        position: "top-right",
      });
      return; // Don't proceed if email is invalid
    }
    if (timer > 0 && email === isPreviousMail) {
      toast.info("You have already requested OTP for this email", {
        position: "top-right",
      });
      return; // Don't proceed if emails are the same
    }
    console.log("Watch email->", email);
    setIsVerifyEmail(false);
    if (email.includes("@")) {
      const response = await fetch(`${TQ_URL_USPROJECT}/sendEmailotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          partnerId: "1",
          flag: "TransactionPage",
        }),
      });
      console.log(response);
      if (response.status == 200) {
        setPin("");
        setShowOtp(true);
        setTimer(90);
        setShowRefresh(false);
        toast.success("Otp is send on email", { position: "top-right" });
        setIsPreviousMail(email);
      } else if (response.status == 400)
        toast.info("This email is used, please use different email", {
          position: "top-right",
        });
    } else {
      setShowOtp(false);
      setTimer(0);
      setPin("");
      setShowRefresh(false);
      setIsVerifyEmail(false);
    }
  };

  const handleAmountSelection = (amount) => {
    setSelectedAmount(amount);
    setValue("amount", `$${amount}`);
    handleAmountChange({ target: { value: `$${amount}` } });
  };

  const handleSaveZelleDonation = async () => {
    // const storedUserData = localStorage.getItem("userData");
    // let userId = 0;
    // if (storedUserData != null) {
    //   const userData = JSON.parse(storedUserData);
    //   userId = userData.userId;
    // }
    // try {
    //   const formData = {
    //     fundraiser: fundraiserId,
    //     amount: watch("amount"),
    //     fullName: watch("fullName"),
    //     email: watch("email"),
    //     phone: watch("phone"),
    //     isAnonymous: watch("anonymously"),
    //     userId: userId,
    //     isRegistered: isLoggedIn,
    //   };
    //   console.log(formData);
    //   const response = await axios.post(
    //     `${TQ_URL_USPROJECT}/saveDonationByZelle`,
    //     formData
    //   );
    //   console.log(response);
    //   if (response.data.status == "success") {
    //     handleCloseModal(); // Close the modal if successful
    navigate("/zelleSuccess", {
      state: {
        fundraiserId: fundraiserIdZelle,
        //   fundraiserName: fundraiserNameZelle, // Ensure fundraiserName is defined
        fundraiserName: fundraiserTitle,
        donorName: donorNameZelle,
        donatedAmt: donationAmtZelle,
      },
    });
    //   } else {
    //     toast.error("Failed to save donation.");
    //   }
    // } catch (error) {
    //   console.error("Error saving donation:", error);
    //   toast.error("Error occurred while saving donation.");
    // }
  };

  const handlePayPalRedirect = async () => {
    // Check if the form is valid
    if (isValid()) {
      const formData = {
        fundraiserId: fundraiserId,
        amount: watch("amount"),
        fullName: watch("fullName"),
        email: watch("email"),
        phone: watch("phone"),
        anonymously: watch("anonymously"),
        fundraiserName: fundraiserName,
      };

      console.log(formData);

      localStorage.setItem("donationPaypalData", JSON.stringify(formData));

      const storedUserData = localStorage.getItem("userData");
      let userId = null;
      if (storedUserData != null) {
        const userData = JSON.parse(storedUserData);
        userId = userData.userId;
      }

      console.log(
        "=============================> fundid",
        fundraiserId == 202410301
      );
      let payPalLinkUrl = null;
      const isanonymus = watch("anonymously"); // true or false based on the form
      if (fundraiserId == 202410061) {
        // https://www.paypal.com/donate/?hosted_button_id=RYUFL2YHAAZ9Q
        // payPalLinkUrl = `https://www.paypal.com/donate/?hosted_button_id=RYUFL2YHAAZ9Q&custom=${fundraiserId}_userId=${userId}_isReg=${isLoggedIn}_isAnonymous=${isanonymus}`;
        payPalLinkUrl = `https://www.sandbox.paypal.com/donate/?hosted_button_id=PWM5YGB7MQGB2&custom=${fundraiserId}_userId=${userId}_isReg=${isLoggedIn}_isAnonymous=${isanonymus}`;
      } else if (fundraiserId == 202410301) {
        // https://www.paypal.com/donate/?hosted_button_id=P7EJPQG676VG6
        // payPalLinkUrl = `https://www.paypal.com/donate/?hosted_button_id=P7EJPQG676VG6&custom=${fundraiserId}_userId=${userId}_isReg=${isLoggedIn}_isAnonymous=${isanonymus}`;

        payPalLinkUrl = `https://www.sandbox.paypal.com/donate/?hosted_button_id=7JCV5PYCBBZES&custom=${fundraiserId}_userId=${userId}_isReg=${isLoggedIn}_isAnonymous=${isanonymus}`;
      } else if (fundraiserId == 202410051) {
        // https://www.paypal.com/donate/?hosted_button_id=EUZC43TMXC4GA
        // payPalLinkUrl = `https://www.paypal.com/donate/?hosted_button_id=EUZC43TMXC4GA&custom=${fundraiserId}_userId=${userId}_isReg=${isLoggedIn}_isAnonymous=${isanonymus}`;

        payPalLinkUrl = `https://www.sandbox.paypal.com/donate/?hosted_button_id=TFYGHJGGFHJHJ&custom=${fundraiserId}_userId=${userId}_isReg=${isLoggedIn}_isAnonymous=${isanonymus}`;
      }
      // Redirect to PayPal
      window.open(payPalLinkUrl, "_blank");
    } else {
      setIsFormValid(false);
      // Optionally show a toast notification or alert
      toast.error(
        "Please fill in all required fields before proceeding to PayPal."
      );
    }
  };

  const isValid = () => {
    // Implement your validation logic here
    return (
      !!watch("amount") &&
      !!watch("fullName") &&
      !!watch("email") &&
      !!watch("phone")
    );
  };

  const updatePaypalAmount = (event) => {
    const numericValue = parseFloat(event.target.value.replace(/[^0-9.]/g, ""));
    if (!isNaN(numericValue)) {
      setPaypalAmount(numericValue.toFixed(2));
    } else {
      setPaypalAmount("");
    }
  };

  return (
    <>
      <Header />
      <ProgressBar value={30} size="sm" color="blue" />

      <h2 className="md:text-3xl text-xl font-bold mb-4 text-center uppercase py-10">
        Donate Money For {toInitCap(fundraiserTitle)}
      </h2>

      <div className="bg-gradient-to-r from-blue-50 to-white py-10">
        <div className="md:pb-10 md:flex justify-center">
          {/* Left Image Section - hidden on smaller screens */}
          {/* <div className="2xl:w-1/4 lg:w-1/3 xl:me-20 mx-10 lg:mx-0 hidden xl:block">
            <img
              src="assets/images/handWithCoin.png"
              className="md:w-full md:mx-16 w-94 h-82 rounded-lg shadow-lg"
              style={{ width: "94%", height: "82%" }}
              alt="Charity"
            />
          </div> */}

          <div className="md:w-2/3 m-4 md:m-0 w-full-md-lg lg:ml-10 leftMarg">
            <form
              onSubmit={handleSubmit(onSubmitHandler)}
              className="fundraiser-for-other-form md:py-12 md:px-20 p-6 md:me-20 bg-white rounded-lg shadow-md"
            >
              {/* Amount */}
              <div className="mb-6 md:flex">
                <label className="block text-lg font-semibold mb-4">
                  Select Donation Amount*
                </label>
                <div className="w-full">
                  {/* Fixed Donation Buttons */}
                  <div className="flex flex-wrap gap-4 mb-6">
                    {[100, 500, 1000].map((amount) => (
                      <button
                        key={amount}
                        type="button"
                        className={`w-32 h-12 px-6 py-3 font-semibold text-lg rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ${
                          selectedAmount === amount
                            ? "bg-[#84EC6F] text-black"
                            : "bg-blue-900 text-white"
                        }`}
                        onClick={() => handleAmountSelection(amount)}
                      >
                        ${amount}
                      </button>
                    ))}
                  </div>

                  {/* Input for Custom Amount */}
                  <div className="relative">
                    <input
                      {...register("amount", {
                        required: "Amount is required",
                      })}
                      placeholder="Enter Custom Amount ($10 Minimum)"
                      type="text"
                      className={`w-full bg-gray-100 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block p-3 rounded-lg shadow-md ${
                        errors.amount ? "border-red-500" : ""
                      }`}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        ); // Only allow numbers
                        const formattedValue = `$${numericValue}`; // Format as currency
                        e.target.value = formattedValue;
                        setValue("amount", formattedValue); // Update the form value

                        // Check if the entered value matches a fixed amount
                        const numericAmount = parseInt(numericValue, 10);
                        if ([100, 500, 1000].includes(numericAmount)) {
                          setSelectedAmount(numericAmount); // Highlight matching button
                        } else {
                          setSelectedAmount(null); // Clear highlight if no match
                        }

                        handleAmountChange(e); // Update fundraiser share and platform fee
                      }}
                      onBlur={handleAmountBlur}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.amount?.message}
                    </p>
                  </div>

                  {/* Amount Distribution */}
                  <div
                    className={`transition-all duration-500 ease-in-out ${
                      fundraiserShare > 0 && platformFee > 0
                        ? "opacity-100 max-h-48"
                        : "opacity-0 max-h-0"
                    } overflow-hidden`}
                  >
                    <div className="mt-6 bg-blue-50 border border-blue-200 rounded-lg p-4 space-y-4 shadow-inner">
                      <div className="flex items-center space-x-2">
                        <span role="img" aria-label="Fundraiser">
                          💰
                        </span>
                        <span className="text-blue-900 font-semibold text-lg">
                          Fundraiser's Share:{" "}
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(fundraiserShare)}
                        </span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <span role="img" aria-label="Platform Fee">
                          📊
                        </span>
                        <span className="text-blue-900 font-semibold text-lg">
                          Platform Fee (4% of Amount):{" "}
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(platformFee)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Full Name */}
              <div className="mb-6 md:flex">
                <label>Full name*</label>
                <div className="w-full">
                  <input
                    {...register("fullName")}
                    placeholder="Full name"
                    type="text"
                    disabled={isLoggedIn}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                      errors.fullName && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.fullName?.message}
                  </p>
                </div>
              </div>

              {/* Email */}
              <div className="mb-6 md:flex">
                <label>Email*</label>
                <div className="w-full">
                  <input
                    {...register("email")}
                    placeholder="Email"
                    type="email"
                    disabled={isLoggedIn}
                    autoComplete="new-password"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                      errors.email && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.email?.message}
                  </p>
                </div>
              </div>

              {/* Phone */}
              <div className="mb-6 md:flex">
                <label>Contact Number*</label>
                <div className="w-full">
                  <input
                    {...register("phone")}
                    placeholder="Phone"
                    type="text"
                    maxLength={14}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                      errors.phone && "border-red-500"
                    }`}
                    onChange={handlePhoneNumberChange}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.phone?.message}
                  </p>
                </div>
              </div>

              {/* Optional Terms and Conditions Checkbox */}
              <div className="mb-6 md:flex justify-between">
                <div></div>

                <div className="md:w-4/6">
                  <input
                    {...register("anonymously")}
                    type="checkbox"
                    className="mr-2"
                  />
                  <label className="text-dark-200 italic">
                    Send donation anonymously
                  </label>
                  <p className="text-red-500 text-sm mt-1">
                    {errors.anonymously?.message}
                  </p>
                  {/* <div className="flex w-full md:justify-between justify-center items-center">
                    
                    <button
                      type="submit"
                      className="md:w-1/3 w-1/2 text-white p-2 rounded-md btn-style-1 mt-4"
                    >
                      Submit
                    </button>
                    <FaStripeS />
                    Pay with 
                    <button
                      type="submit"
                      className="md:w-1/3 w-1/2 flex items-center justify-center text-white p-2 rounded-md btn-style-1 mt-4"
                    >
                      <FaStripeS size={24} className="mr-2" />

                    </button>

                    <FaPaypal type="submit" />

                  </div> */}
                  {/* <p>{message}</p>{" "} */}
                  {/* Display messages or errors related to PayPal transactions */}
                  <div className="w-full mt-4">
                    {/* Heading */}
                    <h2 className="text-lg font-bold text-center mb-4">
                      Donate with
                    </h2>
                    {/* <div className="flex flex-col justify-center items-center mt-8 mb-8 space-y-4"> */}
                    {/* Zelle Button */}
                    <button
                      type="button"
                      onClick={handleZelleRedirect}
                      disabled={!isValid()}
                      className="w-full flex flex-col items-center p-1 border border-blue-500 bg-purple-500 rounded-md mb-4 hover:bg-purple-700 transition-colors duration-200"
                    >
                      <img
                        // src="assets/images/zelleBg.png" // Update to your actual Zelle logo path
                        src="assets/images/zelleWhiteBgRemove.png"
                        alt="Zelle Logo"
                        className="w-[4rem] h-[2rem] mb-2" // Responsive size
                      />
                    </button>

                    {/* PayPal Button */}
                    {/* <button
                        type="button"
                        onClick={handlePayPalRedirect}
                        className="w-[60%] flex flex-col items-center p-2 border border-blue-500 bg-yellow-200 rounded-2xl hover:bg-yellow-100 transition-colors duration-200"
                      >
                        <img
                          src="assets/images/ppal.png" // Update to your actual PayPal logo path
                          alt="PayPal Logo"
                          className="w-[5rem] h-[2rem] mb-2" // Responsive size
                        />
                      </button> */}
                    {/* Stripe Button */}

                    <Modal
                      isOpen={isModalOpen}
                      onRequestClose={handleCloseModal}
                      contentLabel="Onboarding Process Modal"
                      shouldCloseOnOverlayClick={false}
                      className={`onboarding-modal modal-container_paypal bg-white p-6 rounded-lg transition-all duration-700 ease-in-out ${animationClass}`}
                      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-700 ease-in-out z-50"
                    >
                      <button
                        onClick={handleCloseModal}
                        className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none text-2xl"
                        aria-label="Close modal"
                      >
                        &times;
                      </button>

                      <div className="flex flex-col items-center text-center space-y-4">
                        <h2 className="text-xl font-bold mb-4">
                          Please scan the bar code above to pay using Zelle or
                          <br />
                          send the donation to{" "}
                          <span href="#" className="text-blue-500 underline">
                            prabalta.org@gmail.com
                          </span>{" "}
                          in Zelle
                        </h2>

                        {/* Barcode Image */}
                        <img
                          src="assets/images/barcode.jpeg" // Update with your actual barcode image path
                          alt="Barcode"
                          className="w-50 h-50 mb-4 item-center justify-center" // Adjust width and height as needed
                        />

                        {/* Continue Button */}
                        <button
                          onClick={handleSaveZelleDonation}
                          className="md:w-1/4 w-1/2 text-white flex item-center justify-center p-2 rounded-md btn-style-1"
                        >
                          Continue
                        </button>
                      </div>
                    </Modal>

                    <PayPalScriptProvider options={initialOptions}>
                      <div className="paypal-button-container">
                        <PayPalButtons
                          key={donationAmount}
                          style={{
                            shape: "rect",
                            layout: "vertical",
                            color: "gold",
                            label: "donate",
                          }}
                          createOrder={handleCreateOrder}
                          onApprove={handleApprove}
                          disabled={!isValid()}
                          onError={(err) => {
                            console.error("PayPal error:", err);
                            // setMessage(
                            //   "An error occurred during the transaction."
                            // );
                            toast.error(
                              "An error occurred during the transaction."
                            );
                          }}
                        />
                      </div>
                    </PayPalScriptProvider>
                    {/* <div className="flex flex-col justify-center items-center mt-8 space-y-4"> */}
                    <button
                      type="submit"
                      disabled={!isValid()}
                      className="w-full mt-4 flex flex-col items-center p-1 border border-blue-500 bg-blue-500 rounded-md hover:bg-blue-700 transition-colors duration-200"
                    >
                      <img
                        src="assets/images/stripebg-removebg.png" // Update to your actual Stripe logo path
                        alt="Stripe Logo"
                        className="w-[5rem] h-[2rem] mb-2" // Responsive size
                      />
                    </button>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Submission Popup */}
      {showSubmissionPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center fade-in-bottom">
          <div className="bg-white p-6 rounded-3xl max-w-2xl w-full">
            <button
              onClick={() => setShowSubmissionPopup(false)}
              className="text-gray-700 cursor-pointer flex justify-end w-full mb-6"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* Popup Fields */}
            <div className="py-10 px-5">
              <div className="mb-6 flex items-center">
                <label
                  htmlFor="documentField"
                  className="block font-semibold mb-1 w-1/3"
                >
                  Upload Document
                </label>
                <input
                  type="text"
                  id="documentField"
                  value={popupFields.field1}
                  onChange={(e) =>
                    handlePopupFieldChange("field1", e.target.value)
                  }
                  className="w-2/3 p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-6 flex items-center">
                <label
                  htmlFor="fundraiserGoalField"
                  className="block font-semibold mb-1 w-1/3"
                >
                  Fundraiser Goal
                </label>
                <input
                  type="text"
                  id="fundraiserGoalField"
                  value={popupFields.field2}
                  onChange={(e) =>
                    handlePopupFieldChange("field2", e.target.value)
                  }
                  className="w-2/3 p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-6 flex items-center">
                <label
                  htmlFor="targetDateField"
                  className="block font-semibold mb-1 w-1/3"
                >
                  Target Date
                </label>
                <input
                  type="text"
                  id="targetDateField"
                  value={popupFields.field3}
                  onChange={(e) =>
                    handlePopupFieldChange("field3", e.target.value)
                  }
                  className="w-2/3 p-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="flex w-full">
              <span className="block font-semibold mb-1 w-1/3"></span>
              <button
                onClick={onSubmitPopup}
                className="w-1/3 text-white p-2 rounded-md btn-style-1 mt-4"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer theme="colored" />
      <Footer />
    </>
  );
};

export default TransactionDetails;
