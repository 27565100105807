import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { getRequestWithTokenWithoutDataPartner } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import MailCampaignSideBar from "./MailCampaignSideBar";
import DatePicker from "react-datepicker";
import { format, parse } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Checkbox, CheckboxGroup } from "@mantine/core";
import EmailCampaignTour from "./EmailCampaignTour";

import { useNavigate, useLocation } from "react-router-dom";

const schema = Yup.object().shape({
  mailingList: Yup.string().required("Mailing list is required"),
  campaignName: Yup.string().required("campaign name is required."),
  senderEmail: Yup.string()
    .email("Invalid email address")
    .required("Sender email is required"),
  senderName: Yup.string().required("Sender name is required"),
  subjectLine: Yup.string().required("Subject line is required"),
});

const sendFormSchema = Yup.object().shape({
  deliveryOption: Yup.string().required("Please select a delivery option"),
});

var emailListFlag = 0;

function MyCampaign() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useRef(true);
  const [selectedDate, setSelectedDate] = useState(
    format(new Date(), "MMMM d, h:mm a")
  );
  const [enableSecondButton, setEnableSecondButton] = useState(false);

  const [enableThirdButton, setEnableThirdButton] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: registerSend,
    handleSubmit: handleSubmitSend,
    control: controlSend,
    formState: { errors: sendErrors },
  } = useForm({
    resolver: yupResolver(sendFormSchema),
    defaultValues: {
      scheduleDate: new Date(), // Set the default value for scheduleDate
    },
  });

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId;
  const [mailingListData, setMailingListData] = useState([]);
  const [templateId, setTemplateId] = useState(null);
  const [remainingCount, setRemainingCount] = useState(0);
  const [selectedMailingList, setSelectedMailingList] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [templateDetails, setTemplateDetails] = useState();
  const [activeForm, setActiveForm] = useState("campaign");
  const [formData, setFormData] = useState({
    mailingList: "",
    senderEmail: "",
    senderName: "",
    subjectLine: "",
  });

  const id = localStorage.getItem("templateId");

  const tourCompletedFlag = localStorage.getItem("emailTourCompletedFlag");
  console.log("emailTourCompletedFlag mycamp", tourCompletedFlag);
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchTemplateDetails = async () => {
    console.log("temlate Id :", id);
    const url = new URL(`${TQ_URL}/getTemplateDetails`);
    url.searchParams.set("templateId", id);
    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );
      console.log("Fetched template details:", response.templateData);
      setTemplateDetails(response.templateData);
    } catch (error) {
      console.error("Error fetching template details:", error);
    }
  };

  useEffect(() => {
    console.log("Remaining Count:", remainingCount);
    console.log("Selected Emails:", selectedEmails);
  }, [remainingCount, selectedEmails]);

  const handleSubmitForModal = () => {
    emailListFlag = 1;

    console.log("Selected Email IDs:", selectedEmails);
    localStorage.setItem("selectedEmails", JSON.stringify(selectedEmails));
    setIsModalOpen(false);
  };

  const handleSelectAll = () => {
    const allEmailIds = selectedMailingList.emailList.map((email) =>
      email.mailListEmailId.toString()
    );
    setSelectedEmails(allEmailIds);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      handleEmailChange([]); // Deselect all
    } else {
      const allEmails = selectedMailingList.emailList.map((email) =>
        email.mailListEmailId.toString()
      );
      handleEmailChange(allEmails); // Select all
    }
    setSelectAll(!selectAll);
  };

  const handleDeselectAll = () => {
    setSelectedEmails([]);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const form = params.get("form");

    if (form) {
      showForm(form);
    } else {
      showForm("campaign"); // Default form
    }
    console.log(id);

    if (id) {
      setTemplateId(id);
    }
  }, [location.search]);

  const getMailingList = async () => {
    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        `${TQ_URL}/getAllMailingListNames?partnerId=${partnerId}`
      );
      console.log(response);
      setMailingListData(response.mailingList);
      setRemainingCount(response.remainingCount);
    } catch (error) {
      console.error("Error in fetching all the mailing List:", error);
    }
  };

  const showForm = (formName) => {
    switch (formName) {
      case "campaign":
        const campaignData = JSON.parse(
          localStorage.getItem("formDataForInfo")
        );
        console.log(JSON.parse(localStorage.getItem("formDataForInfo")));

        if (campaignData) {
          setValue("mailingList", campaignData.mailingList);
          setValue("campaignName", campaignData.campaignName);
          setValue("senderEmail", campaignData.senderEmail);
          setValue("senderName", campaignData.senderName);
          setValue("subjectLine", campaignData.subjectLine);

          setActiveForm(formName);
          setEnableSecondButton(true);
          setEnableThirdButton(true);
          localStorage.setItem("redirectFlagForFirstForm1", "1");
        } else {
          setActiveForm(formName);
        }
        break;
      case "contact":
        setActiveForm(formName);
        break;
      case "subscription":
        const formDataFromStorage = JSON.parse(
          localStorage.getItem("formDataForInfo")
        );
        if (formDataFromStorage) {
          setFormData(formDataFromStorage);
        }
        setActiveForm(formName);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getMailingList();
    localStorage.removeItem("redirectFlagForFirstForm");
    localStorage.removeItem("redirectFlagForFirstForm1");
    if (isMounted.current) {
      fetchTemplateDetails();
      isMounted.current = false;
    }
  }, [templateId]);

  useEffect(() => {
    setValue("scheduleDate", new Date());
  }, [setValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getMailingListNameById = (id) => {
    const mailingList = mailingListData.find((list) => list.id === id);
    return mailingList ? mailingList.mailListName : "Unknown List";
  };

  const onSubmit = (data) => {
    const value = localStorage.getItem("redirectFlagForFirstForm");
    const value2 = localStorage.getItem("redirectFlagForFirstForm1", "1");
    if (value == 1 || value2 == 1) {
      showForm("contact");
    } else {
      const mailingListName = getMailingListNameById(data.mailingListId);

      const updatedData = {
        ...data,
        mailingListName: toInitCap(mailingListName),
        mailingListId: data.mailingListId,
      };

      localStorage.setItem("formDataForInfo", JSON.stringify(updatedData));

      setFormData(updatedData);
      setEnableSecondButton(true);
      //  setEnableThirdButton(true);
      navigate("/templateList?source=newCampaign");
    }
  };

  const onSubmitSend = async (data, status) => {
    console.log(data);
    console.log(JSON.parse(localStorage.getItem("formDataForInfo")));

    var statusValue = 0;
    const selectedEmails = JSON.parse(localStorage.getItem("selectedEmails"));

    if (status == 1) {
      statusValue = data.deliveryOption;
    } else {
      statusValue = 5;
    }
    if (emailListFlag == 0) setSelectedEmails([]);
    try {
      let data = {
        senderName: toInitCap(formData.senderName),
        senderEmail: formData.senderEmail,
        subjectLine: formData.subjectLine,
        mailingListId: formData.mailingList,
        selectedEmailIds: selectedEmails,
        emailListFlag: emailListFlag,
        campaignName: toInitCap(formData.campaignName),
        sendType: statusValue,
        scheduleDateTime: selectedDate,
        templateId: id,
        partnerId: partnerId,
      };

      console.log("inside save campaign");
      console.log(selectedDate);
      console.log(data);
      const response = await axios.post(`${TQ_URL}/saveCampaignInfo`, data);

      console.log("Campaign Information saved successfully:", response);
      if (response.status == 200) {
        if (status == 2) {
          toast.success("Campaign Information Saved to Draft Successfully.");

          localStorage.removeItem("formDataForInfo");

          setTimeout(() => {
            navigate("/campaignList");
          }, 2000);
        } else {
          toast.success("Campaign Information Saved Successfully.");
          localStorage.removeItem("formDataForInfo");
          setTimeout(() => {
            navigate("/campaignList");
          }, 2000);
        }
      } else {
        toast.error("Failed to save Campaign Information.");
      }
    } catch (error) {
      console.error("Error saving Campaign Information:", error);
      toast.error("Something went wrong");
    }
  };

  const handlePrevious = () => {
    //  navigate(-1); // Navigate to the previous page
    showForm("campaign");

    const campaignData = JSON.parse(localStorage.getItem("formDataForInfo"));
    console.log(JSON.parse(localStorage.getItem("formDataForInfo")));

    setValue("mailingList", campaignData.mailingList);
    setValue("campaignName", campaignData.campaignName);
    setValue("senderEmail", campaignData.senderEmail);
    setValue("senderName", campaignData.senderName);
    setValue("subjectLine", campaignData.subjectLine);

    localStorage.setItem("redirectFlagForFirstForm", "1");
  };

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const handleNext = () => {
    setEnableThirdButton(true);
    showForm("subscription");
  };

  const handlePreviousPreview = () => {
    showForm("contact");
  };

  const handleMailingListChange = (event) => {
    const selectedId = parseInt(event.target.value, 10);
    console.log("inside handle mailing list : ", selectedId);

    console.log(mailingListData);

    const selectedList = mailingListData.find(
      (list) => list.mailingListId === selectedId
    );

    console.log(selectedList);

    if (selectedList) {
      const mailingListCount = selectedList.emailCount; // Assuming this is how you get the count

      setSelectedMailingList(selectedList);
      console.log(mailingListCount);
      console.log(remainingCount);

      const allEmailIds = selectedList.emailList.map((email) =>
        email.mailListEmailId.toString()
      );

      setSelectedEmails(allEmailIds);

      if (mailingListCount > remainingCount) {
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }
    }
  };
  const handleEmailChange = (selected) => {
    setSelectedEmails(selected);
  };

  const renderForm = () => {
    switch (activeForm) {
      case "campaign":
        return (
          <form
            id="infoAboutCampaing"
            className="fundraiser-for-other-form md:py-8 md:px-8 p-4 mx-auto w-full max-w-4xl"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="text-center mb-4">
              <h2 className="text-xl font-bold">New Campaign Email</h2>
            </div>

            <div className="flex flex-row items-center justify-start mx-2 mb-4 fade-in-right">
              <label
                htmlFor="mailing-list"
                className="mr-4 text-sm font-semibold text-black-900"
              >
                Mailing List
              </label>
              <div className="w-full">
                <select
                  id="mailing-list"
                  {...register("mailingList", {
                    onChange: handleMailingListChange,
                  })}
                  className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                >
                  <option value="">Select Mailing List</option>
                  {mailingListData.map((type, index) => (
                    <option key={index} value={type.mailingListId}>
                      {type.mailListName}
                    </option>
                  ))}
                </select>
                {errors.mailingList && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.mailingList.message}
                  </p>
                )}
              </div>

              {/* Modal */}
              {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                  <div className="relative bg-white p-6 rounded-lg max-w-2xl w-full shadow-lg">
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="mt-2 p-2 cursor-pointer absolute top-2 right-2"
                    >
                      <svg
                        className="w-8"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                            fill="#0F0F0F"
                          ></path>{" "}
                        </g>
                      </svg>{" "}
                    </button>
                    <h2 className="text-xl font-semibold mb-4 text-center">
                      Warning
                    </h2>

                    <p
                      className="text-md font-semibold mb-4 text-center"
                      style={{
                        color: "orange",
                      }}
                    >
                      You have only {remainingCount} Email Send Remaining.
                      Please edit the list accordingly
                    </p>

                    <div className="flex justify-between space-x-4  m-8">
                      <div>
                        <strong>Remaining Email Count:</strong> {remainingCount}
                      </div>
                      <div>
                        <strong>Selected Emails:</strong>{" "}
                        {selectedEmails.length}
                      </div>
                    </div>

                    <div>
                      <h2 className="mb-4 text-lg font-semibold"></h2>
                      <div className="max-h-64 overflow-y-auto">
                        {/* {remainingCount > 0 && (
                          <div className="flex justify-between mb-2">
                            <button
                              onClick={handleSelectAll}
                              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                            >
                              Select All
                            </button>
                            <button
                              onClick={handleDeselectAll}
                              className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
                            >
                              Deselect All
                            </button>
                          </div>
                        )} */}
                        {remainingCount > 0 ? (
                          <>
                            <div className="flex items-center mb-2">
                              <Checkbox
                                value="selectAll"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                                className="flex items-center p-2 bg-gray-50 rounded-md hover:bg-gray-100"
                              />
                            </div>

                            <Checkbox.Group
                              value={selectedEmails}
                              onChange={handleEmailChange}
                              className="flex flex-col space-y-2"
                            >
                              {selectedMailingList.emailList.map(
                                (email, index) => (
                                  <Checkbox
                                    key={index}
                                    value={email.mailListEmailId.toString()} // Assuming email.id is unique
                                    label={email.email}
                                    className="flex items-center p-2 bg-gray-50 rounded-md hover:bg-gray-100"
                                  />
                                )
                              )}
                            </Checkbox.Group>
                          </>
                        ) : (
                          <h1 className="text-red-600 font-semibold text-center">
                            You cannot send emails now. You have reached the
                            limit.
                          </h1>
                        )}
                      </div>

                      <button
                        className={`mt-4 px-4 py-2 rounded-lg justify-center ${
                          remainingCount >= selectedEmails.length
                            ? "bg-blue-500 text-white cursor-pointer"
                            : "bg-gray-400 text-gray-200 cursor-not-allowed"
                        }`}
                        onClick={handleSubmitForModal}
                        // onClick={() => setIsModalOpen(false)}
                        disabled={remainingCount < selectedEmails.length}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-row items-center justify-start mx-2 mb-4 fade-in-right">
              <label
                htmlFor="campaignName"
                className="mr-4 text-sm font-semibold text-black-900"
              >
                Campaign Name
              </label>
              <div className="w-full">
                <input
                  id="campaignName"
                  {...register("campaignName")}
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.campaignName && "border-red-500"
                  }`}
                  placeholder="Enter Campaign Name"
                />
                {errors.campaignName && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.campaignName.message}
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-start justify-start mx-2 mb-4 fade-in-right">
              <div className="w-full md:w-6/12 mr-4">
                <label
                  htmlFor="sender-email"
                  className="block text-sm font-semibold text-black-900 mb-4"
                >
                  Sender Email
                </label>
                <input
                  id="sender-email"
                  {...register("senderEmail")}
                  type="email"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.senderEmail && "border-red-500"
                  }`}
                  placeholder="Enter sender email"
                />
                {errors.senderEmail && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.senderEmail.message}
                  </p>
                )}
              </div>
              <div className="w-full md:w-6/12 md:ml-4 mt-4 md:mt-0">
                <label
                  htmlFor="sender-name"
                  className="block text-sm font-semibold text-black-900 mb-4"
                >
                  Sender Name
                </label>
                <input
                  id="sender-name"
                  type="text"
                  {...register("senderName")}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.senderName && "border-red-500"
                  }`}
                  placeholder="Enter sender name"
                />
                {errors.senderName && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.senderName.message}
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-col items-start justify-start mx-2 mb-4 fade-in-right">
              <label
                htmlFor="subject-line"
                className="block text-sm font-semibold text-black-900 mb-4"
              >
                Subject Line
              </label>
              <textarea
                id="subject-line"
                {...register("subjectLine")}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.subjectLine && "border-red-500"
                }`}
                placeholder="Enter subject line"
                rows="3"
              />
              {errors.subjectLine && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.subjectLine.message}
                </p>
              )}
            </div>

            <div className="mb-4 flex justify-center mt-4">
              <button
                type="submit"
                className={`w-1/3 p-2 rounded-md ml-2 ${
                  remainingCount <= 0
                    ? "bg-gray-400 text-gray-700 cursor-not-allowed" // Disabled state
                    : "bg-blue-500 text-white btn-style-1" // Enabled state
                }`}
                onClick={handleSubmit(onSubmit)}
                disabled={remainingCount <= 0}
              >
                Next
              </button>
            </div>
          </form>
        );
      case "contact":
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              // height: "100vh",
            }}
          >
            <div
              style={{
                border: "1px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                backgroundColor: "#fff",
                borderRadius: "8px", // optional: for rounded corners
                width: "80%", // optional: to control the width of the box
                height: "auto",
                maxWidth: "800px", // optional: to limit the maximum width of the box
                textAlign: "center", // Ensure text is centered inside the box
              }}
            >
              <h1
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Message Content
              </h1>
              {templateDetails ? (
                <div>
                  <style>{templateDetails.templateCss}</style>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: templateDetails.templateHtml,
                    }}
                  />
                </div>
              ) : (
                <p>Loading template...</p>
              )}

              <div className="mt-5 flex justify-center">
                <button
                  onClick={handlePrevious}
                  className="px-4 py-2 mr-2 bg-gray-600 text-white rounded cursor-pointer"
                >
                  Previous
                </button>
                <button
                  onClick={handleNext}
                  className="px-4 py-2 bg-green-500 text-white rounded cursor-pointer"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        );
      case "subscription":
        return (
          <div>
            <form
              onSubmit={handleSubmitSend((data) => onSubmitSend(data, 1))}
              className="fundraiser-for-other-form md:py-8 md:px-8 p-4 mx-auto w-full max-w-4xl"
            >
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-2xl font-bold">
                  Information About Campaign
                </h2>
              </div>

              <div className="border border-gray-300 p-4 rounded-lg mb-4">
                <div className="flex mb-2">
                  <div className="w-1/3">
                    <span className="text-sm font-medium text-gray-600">
                      Mailing List Name:
                    </span>
                  </div>
                  <div className="w-2/3">
                    <span className="text-sm">{formData.mailingListName}</span>
                  </div>
                </div>
                <div className="flex mb-2">
                  <div className="w-1/3">
                    <span className="text-sm font-medium text-gray-600">
                      Sender Name:
                    </span>
                  </div>
                  <div className="w-2/3">
                    <span className="text-sm">{formData.senderName}</span>
                  </div>
                </div>
                <div className="flex mb-2">
                  <div className="w-1/3">
                    <span className="text-sm font-medium text-gray-600">
                      Sender Email:
                    </span>
                  </div>
                  <div className="w-2/3">
                    <span className="text-sm break-all">
                      {formData.senderEmail}
                    </span>
                  </div>
                </div>
                <div className="flex">
                  <div className="w-1/3">
                    <span className="text-sm font-medium text-gray-600">
                      Subject Line:
                    </span>
                  </div>
                  <div className="w-2/3">
                    {formData.subjectLine.length > 40
                      ? `${formData.subjectLine.substring(0, 40)}...`
                      : formData.subjectLine}
                  </div>
                </div>
              </div>

              <h2 className="text-2xl font-bold">Message Content</h2>
              <div className="border border-gray-300 p-4 rounded-lg mb-4 items-start mx-2 mb-4 fade-in-right">
                <div
                  style={{
                    height: "auto",
                  }}
                >
                  {templateDetails ? (
                    <div className="break-all">
                      <style>{templateDetails.templateCss}</style>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: templateDetails.templateHtml,
                        }}
                      />
                    </div>
                  ) : (
                    <p>Loading template...</p>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between mb-4">
                <h2 className="text-2xl font-bold">Sending the Campaign</h2>
              </div>

              <div className="border border-gray-300 p-4 rounded-lg mb-4 flex flex-col items-start mx-2 mb-4 fade-in-right">
                <div className="flex items-center mb-4">
                  <input
                    type="radio"
                    value="1"
                    {...registerSend("deliveryOption")}
                    className="form-radio text-blue-500 h-4 w-4"
                  />
                  <span className="ml-2">Send Now</span>
                </div>

                <div className="mt-2 flex items-center">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="2"
                      {...registerSend("deliveryOption")}
                      className="form-radio text-blue-500 h-4 w-4"
                    />
                    <span className="ml-2">Schedule At:</span>
                  </label>
                  <Controller
                    control={controlSend}
                    name="scheduleDate"
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        selected={field.value || selectedDate}
                        onChange={(date) => {
                          setSelectedDate(format(date, "MMMM d, yyyy h:mm a"));
                          field.onChange(date);
                        }}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="ml-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2 outline-none"
                      />
                    )}
                  />
                </div>

                {sendErrors.deliveryOption && (
                  <p className="text-red-500 text-sm mb-4">
                    {sendErrors.deliveryOption.message}
                  </p>
                )}
                {sendErrors.scheduleDate && (
                  <p className="text-red-500 text-sm mb-4">
                    {sendErrors.scheduleDate.message}
                  </p>
                )}
              </div>

              <div className="mt-4 flex justify-center sm:justify-between max-sm:gap-x-11">
                <button
                  type="button"
                  onClick={handlePreviousPreview}
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
                >
                  Previous
                </button>

                <div className="flex space-x-3">
                  <button
                    type="submit"
                    className="btn-style-1 text-white bg-blue-500 hover:bg-blue-600 py-1 px-1 md:py-2 md:px-4 rounded md:block"
                    onClick={(e) => {
                      e.preventDefault();
                      onSubmitSend({}, 2);
                    }}
                  >
                    Save as Draft
                  </button>
                  <button
                    type="submit"
                    className="btn-style-1 text-white bg-green-500 hover:bg-green-600 py-1 px-3 md:py-2 md:px-4 rounded md:block"
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed h-full md:overflow-y-auto">
          <MailCampaignSideBar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px] overflow-x-hidden">
          <div className=" mx-auto shadow-md">
            <div className="container flex justify-center items-center px-10 py-6 md:py-6">
              <div className="flex justify-center items-center space-x-4">
                <button
                  className="btn-style-1 text-white py-1 px-1 md:py-2 md:px-4 rounded mx-2 max-sm:text-xs"
                  onClick={() => showForm("campaign")}
                >
                  Information about campaign
                </button>

                <button
                  className="btn-style-1 text-white py-1 px-1 md:py-2 md:px-4 rounded mx-2"
                  onClick={() => showForm("contact")}
                  disabled={!enableSecondButton}
                >
                  Message Content
                </button>

                <button
                  className="btn-style-1 text-white py-1 px-1 md:py-2 md:px-4 rounded mx-2"
                  onClick={() => showForm("subscription")}
                  disabled={!enableThirdButton}
                >
                  Preview And Send
                </button>
              </div>
            </div>
          </div>

          <div className="m-10 ">{renderForm()}</div>
        </main>
      </div>
      <ToastContainer theme="colored" />

      {tourCompletedFlag === "false" && isLargeScreen && <EmailCampaignTour />}
    </>
  );
}

export default MyCampaign;
