import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tour from "reactour"; // Assuming you're using reactour or similar library
import axios from "axios";
import { TQ_URL } from "../../utility/baseUrl";

const stepStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
};

const MultipleTour = () => {
  const [isTourOpen, setIsTourOpen] = useState(true);

  const [currentStep, setCurrentStep] = useState(
    Number(localStorage.getItem("tourStepNew")) || 0
  );

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const partnerId = partnerData.partnerId;
  const moduleId = 1;

  const navigate = useNavigate();

  const steps = [
    {
      // 0 - Donor Management Introduction
      selector: "#donor-step1",
      content: (
        <div className="step-content">
          <span className="emoji-large">👥</span>
          <div>Welcome to the Donor Management section!</div>
          <div>Efficiently manage all your donors here.</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 1 - Imported Donors
      selector: "#donor-step2",
      content: (
        <div className="step-content">
          <span className="emoji-large">📥</span>
          <div>Here is the list of all imported donors.</div>
          <div>Track and manage them effortlessly.</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 2 - Website Donors
      selector: "#donor-step3",
      content: (
        <div className="step-content">
          <span className="emoji-large">🌐</span>
          <div>Here is the list of donors from your website.</div>
          <div>Stay connected with your supporters online.</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 3 - Import Donor
      selector: "#donor-importDonor",
      content: (
        <div className="step-content">
          <span className="emoji-large">📂</span>
          <div>Import your donor list here.</div>
          <div>Bring your external donor data into the system!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 4 - Add Donor Manually
      selector: "#donor-addDonor",
      content: (
        <div className="step-content">
          <span className="emoji-large">➕</span>
          <div>Add a donor manually by filling out their details here.</div>
          <div>Grow your donor base one step at a time!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 5 - Donation Options
      selector: "#donation-option",
      content: (
        <div className="step-content">
          <span className="emoji-large">💸</span>
          <div>This section gives you access to donation options.</div>
          <div>Manage donations with ease!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 6 - Imported Donations
      selector: "#donation-step2",
      content: (
        <div className="step-content">
          <span className="emoji-large">📥</span>
          <div>Here is the list of all imported donations.</div>
          <div>Review and track all offline donations.</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 7 - Website Donations
      selector: "#donation-step3",
      content: (
        <div className="step-content">
          <span className="emoji-large">🌐</span>
          <div>Here is the list of all donations made on your website.</div>
          <div>Stay on top of your online donations!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 8 - Add Donation
      selector: "#donation-addDonation",
      content: (
        <div className="step-content">
          <span className="emoji-large">➕</span>
          <div>Add a donation by filling out the donation details here.</div>
          <div>Track every contribution effortlessly!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 9 - Settings
      selector: "#setting",
      content: (
        <div className="step-content">
          <span className="emoji-large">⚙️</span>
          <div>
            Here you can update your custom logo and signature settings.
          </div>
          <div>Personalize your platform!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 10 - Logo and Signature Upload
      selector: "#setting-step2",
      content: (
        <div className="step-content">
          <span className="emoji-large">📤</span>
          <div>
            Ensure all required fields are filled before uploading your logo and
            signature.
          </div>
          <div>Customize with ease!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 11 - Submit Form
      selector: "#setting-step3",
      content: (
        <div className="step-content">
          <span className="emoji-large">✅</span>
          <div>Click here to submit the form and finalize your changes.</div>
          <div>Save your customizations!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 12 - Fundraiser Information
      selector: "#fundraiserInfo",
      content: (
        <div className="step-content">
          <span className="emoji-large">💼</span>
          <div>Here you'll find detailed information about fundraisers.</div>
          <div>Stay informed and manage effectively!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 13 - Imported Fundraisers
      selector: "#fund-step2",
      content: (
        <div className="step-content">
          <span className="emoji-large">📥</span>
          <div>Here is the list of all imported fundraisers.</div>
          <div>Manage your offline fundraising efforts.</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 14 - Website Fundraisers
      selector: "#fund-step3",
      content: (
        <div className="step-content">
          <span className="emoji-large">🌐</span>
          <div>
            Here is the list of all fundraisers started on your website.
          </div>
          <div>Track your online campaigns efficiently!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 15 - Add Fundraiser
      selector: "#addFundraiser",
      content: (
        <div className="step-content">
          <span className="emoji-large">➕</span>
          <div>Add a fundraiser by entering its details here.</div>
          <div>Grow your cause one campaign at a time!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 16 - Reports
      selector: "#report",
      content: (
        <div className="step-content">
          <span className="emoji-large">📊</span>
          <div>
            This section provides detailed reports on your fundraising efforts.
          </div>
          <div>Analyze and improve with ease!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 17 - Imported Donations Reports
      selector: "#report-step1",
      content: (
        <div className="step-content">
          <span className="emoji-large">📥</span>
          <div>View detailed reports on imported donations here.</div>
          <div>Get a full picture of your offline contributions!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 18 - Website Donations Reports
      selector: "#report-step2",
      content: (
        <div className="step-content">
          <span className="emoji-large">🌐</span>
          <div>Here you'll find detailed reports on website donations.</div>
          <div>Analyze your online donation patterns!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      // 19 - Add Fundraiser from Reports
      selector: "#report-step3",
      content: (
        <div className="step-content">
          <span className="emoji-large">➕</span>
          <div>
            Add a fundraiser directly from your reports section by entering the
            details.
          </div>
          <div>Grow your campaign directly from insights!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
  ];

  const handleNextStep = (stepIndex) => {
    if (currentStep !== stepIndex) {
      setCurrentStep(stepIndex);
      localStorage.setItem("tourStepNew", stepIndex);

      // Navigate to the correct page based on step index
      if (stepIndex === 0) {
        navigate("/donorlist"); // Navigate to Page 1
      } else if (stepIndex === 5) {
        navigate("/allDonationList"); // Navigate to Page 2
      } else if (stepIndex === 9) {
        navigate("/customReceipt"); // Navigate to Page 3
      } else if (stepIndex === 12) {
        navigate("/allFundraiserList"); // Navigate to Page 3
      } else if (stepIndex === 16) {
        navigate("/report");
      }
    }
  };

  const handlePrevStep = () => {
    console.log("prev step called....");

    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
      localStorage.setItem("tourStepNew", currentStep - 1);
      if (currentStep === 5) {
        navigate("/donorlist"); // Navigate to Page 2
      } else if (currentStep === 9) {
        navigate("/allDonationList"); // Navigate to Page 3
      } else if (currentStep === 12) {
        navigate("/customReceipt"); // Navigate to Page 3
      } else if (currentStep === 16) {
        navigate("/allFundraiserList");
      }
    }
  };

  useEffect(() => {
    // Navigate to the correct page on initial render based on current step
    handleNextStep(currentStep);
  }, [currentStep]);

  const handleFinishRedirection = async () => {
    console.log("finish clicked");
    localStorage.removeItem("tourStepNew");

    try {
      // Call the API to mark the module as finished
      const response = await axios.put(
        `${TQ_URL}/complete/${partnerId}/${moduleId}`
      );

      if (response.status === 200) {
        console.log("Module marked as complete");

        // Redirect to the services dashboard after completion
        navigate("/services-dashboard");
      } else {
        console.error("Failed to complete the module", response.data);
      }
    } catch (error) {
      console.error("Error marking module as complete:", error);
    }
  };
  const handleCloseRequest = async () => {
    console.log("close clicked");
    localStorage.removeItem("tourStepNew");

    try {
      // Call the API to mark the module as finished
      const response = await axios.put(
        `${TQ_URL}/complete/${partnerId}/${moduleId}`
      );

      if (response.status === 200) {
        console.log("Module marked as complete");
        setIsTourOpen(false);
      } else {
        console.error("Failed to complete the module", response.data);
      }
    } catch (error) {
      console.error("Error marking module as complete:", error);
    }
  };

  return (
    <div className="my-page-tour">
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        startAt={currentStep}
        onRequestClose={handleCloseRequest}
        nextButton={<button className="tour-button">Next ➡️</button>}
        // Skip button added instead of X
        closeButton={
          <button className="tour-button" onClick={handleCloseRequest}>
            Skip ⏭️
          </button>
        }
        prevButton={
          <button className="tour-button" onClick={handlePrevStep}>
            ⬅️ Back
          </button>
        }
        getCurrentStep={(stepIndex) => handleNextStep(stepIndex)}
        lastStepNextButton={
          <button className="tour-button" onClick={handleFinishRedirection}>
            Finish 🎉
          </button>
        }
        onBeforeClose={() => console.log("Tour closed")}
      />
    </div>
  );
};

export default MultipleTour;
