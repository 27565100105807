import React, { useState, useEffect } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Select, Textarea, Button } from "@mantine/core";
import { getRequestWithOutToken } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { Link } from "react-router-dom";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AllSupportQueries = () => {
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [isStatusesLoaded, setIsStatusesLoaded] = useState(false);

  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchStatusList = async () => {
      try {
        await getEnquiryStatusList();
        setIsStatusesLoaded(true);
      } catch (error) {
        console.error("Failed to fetch enquiry status list:", error);
      }
    };

    fetchStatusList();
  }, []);

  useEffect(() => {
    if (isStatusesLoaded) {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }
      getAllSupportQueries();
    }
  }, [
    isStatusesLoaded, // Ensure enquiries are fetched after statuses are loaded
    pagination.pageIndex, // Refetch when page index changes
    pagination.pageSize, // Refetch when page size changes
  ]);

  async function getEnquiryStatusList() {
    const url = new URL(`${TQ_URL}/getEnquiryStatusList`);
    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log("Enquiry Status List");
      console.log(response);
      //   setStatuses(response.data);

      const statusOptions = response.data.map((status) => ({
        value: status.statusId,
        label: status.statusName,
      }));

      setStatuses(statusOptions);
    } catch (error) {
      console.error("Error in fetching the Cause data:", error);
    }
  }

  const handleStatusChange = async (id, status, comment = "") => {
    try {
      // Update status and comment locally
      setData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, status, comment } : item
        )
      );

      // Send update to the backend
      const response = await axios.post(
        `${TQ_URL}/saveStatusCommentForEnquiry`,
        {
          supportId: id,
          status: status,
          comment: comment,
        }
      );

      if (response.status === 200) {
        getAllSupportQueries();
        toast.success("Status and Comment Updated Successfully");
        console.log("Status and Comment updated successfully");
      } else {
        console.error("Failed to update status and comment");
      }
    } catch (error) {
      console.error("Error updating status and comment:", error);
    }
  };

  const columns = [
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorKey: "name",
      header: "User Name",
    },
    {
      accessorKey: "email",
      header: "User Email",
    },
    {
      accessorKey: "subject",
      header: "Subject",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessor: "statusId",
      header: "Status",
      Cell: ({ row }) => (
        <Select
          value={row.original.statusId}
          onChange={(value) =>
            handleStatusChange(
              row.original.supportId,
              value,
              row.original.comment
            )
          }
          data={statuses}
        />
      ),
    },

    {
      accessor: "comment",
      header: "Comment",
      Cell: ({ row }) => (
        <Textarea
          //   value={row.original.comment}
          onChange={(event) =>
            setData((prevData) =>
              prevData.map((item) =>
                item.id === row.original.id
                  ? { ...item, comment: event.target.value }
                  : item
              )
            )
          }
          onBlur={() =>
            handleStatusChange(
              row.original.supportId,
              row.original.statusId,
              row.original.comment
            )
          }
          placeholder="Add a comment"
        />
      ),
    },
  ];

  const getAllSupportQueries = async () => {
    const url = new URL(`${TQ_URL}/allSupportQueries`);
    url.search = new URLSearchParams();
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);

    try {
      const response = await getRequestWithOutToken(url.toString());

      console.log(response);
      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setData(dataWithSerialNumber);
      //setData(response.datalist);
      setRowCount(response.totalCount);
    } catch (error) {
      console.error("Error in fetching All Support Queries:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const table = useMantineReactTable({
    columns,
    data: data,

    initialState: { showColumnFilters: false },
    rowCount,

    manualPagination: true,

    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,
  });

  return (
    <div>
      <DonorManagementTop />
      <div className="flex">
        <div className=""></div>
        <main className="w-full verify-user ">
          <div className="mx-auto shadow-md">
            <div className="flex justify-between container items-center px-10 py-6 mx-auto">
              <div className="text-left flex items-center">
                <h2 className="text-2xl font-bold">
                  Checkout the list of all Support Queries
                </h2>
              </div>
            </div>
          </div>
          {/* <div className="flex justify-end container mx-auto md:pt-10"></div> */}
          <div className="md:px-10 py-16 container mx-auto">
            <MantineReactTable table={table} />
          </div>
        </main>
      </div>
      <ToastContainer theme="colored" />
    </div>
  );
};

export default AllSupportQueries;
