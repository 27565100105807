import React, { useState, useEffect, forwardRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputMask from "react-input-mask";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

const stripMask = (value) => value.replace(/\D/g, "");

const schema = yup.object().shape({
  email: yup.string().email().required("Email is Required *"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .max(25, "Password must be at most 25 characters")
    .required("Password is Required *"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is Required *"),
  organization: yup.string().required("This option is Required *"),
  username: yup
    .string()
    .min(5, "Username must be at least 5 characters")
    .max(50, "Username must be at most 50 characters")
    .required("Username is Required *"),
  contactnumber: yup
    .string()
    .required("Contact Number is Required *")
    .transform(stripMask)
    .matches(
      /^[2-9]\d{2}[2-9]\d{2}\d{4}$/,
      "Contact number must be a valid US phone number"
    ),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], "Please accept the terms and conditions"),
});
const CustomInput = forwardRef((props, ref) => (
  <InputMask {...props} inputRef={ref} />
));

const CreateAccount = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(false);
  const [config, setConfig] = useState({});
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch("/config.json");
      const data = await response.json();
      setConfig(data);
      setPartnerIdCookie(data.partnerId);
    } catch (error) {
      console.error("Error loading config.json:", error);
    }
  };

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const setPartnerIdCookie = (partnerId) => {
    Cookies.set("partnerId", partnerId, {
      domain: "prabalta.org",
      path: "/usproject",
      secure: true,
      sameSite: "None",
      expires: 1, // 1-day expiry
    });

    Cookies.set("partnerId", partnerId, {
      // domain: ".prabalta.org",
      domain: "prabalta.org",

      path: "/",
      secure: true,
      sameSite: "None",
      expires: 1, // 1-day expiry
    });

    Cookies.set("partnerId", partnerId, {
      domain: "localhost",
      path: "/",
      secure: false, // Secure should be false for localhost as it usually runs on HTTP
      sameSite: "Lax", // SameSite Lax is generally more compatible with localhost
      expires: 1, // 1-day expiry
    });
  };

  const onSubmitHandler = async (data, event) => {
    setLoading(true);

    console.log(data);
    event.preventDefault();
    try {
      const response = await fetch(`${TQ_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: toInitCap(data.username),
          email: data.email,
          name: data.username,
          contactNumber: data.contactnumber,
          userType: data.organization,
          password: data.confirmPassword,
          partnerId: config.partnerId,
        }),
      });

      const res = await response.json();

      console.log("BODY >>", res);

      if (response.status === 200) {
        userCreated(data.email);
        reset();
      } else if (
        response.status === 400 &&
        res.errors &&
        res.errors.length > 0
      ) {
        let errorMsg = "";
        res.errors.forEach((msg) => {
          errorMsg += ` ${msg}`;
        });

        console.log(errorMsg);

        toast.error(errorMsg, { position: "top-right" });
      } else {
        userNotCreated();
      }
    } catch (error) {
      console.error("Error during registration:", error);
      userNotCreated();
    } finally {
      setLoading(false);
    }
  };

  const handleSocialIconClick = (dynamicUrl) => {
    const organizationType = watch("organization");

    if (!organizationType) {
      setSelectedOrganization(true);
      return;
    } else {
      localStorage.setItem("userType", organizationType);
      window.location.href = dynamicUrl;
    }
  };

  const userCreated = (email) => {
    toast.success("User Created Successfully!", { position: "top-right" });

    setTimeout(() => {
      navigate(`/addaddressdetails?email=${encodeURIComponent(email)}`);
    }, 1000);
  };

  const userNotCreated = () => {
    toast.error("Oops ! User Not Created ", { position: "top-right" });
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneNumberChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setValue("contactnumber", formattedPhoneNumber);
  };

  console.log("Errors:", errors);

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {loading && (
        <div className="fixed top-0 left-0 right-0 h-1 loader-bg-color z-50"></div>
      )}
      <div className="md:flex-1 bg-cover bg-center relative fade-in-left create-ac-bg">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute text-left md:m-20">
          <Link to="/">
            <img src={config.logoUrl} alt="Logo" className="h-10 sm:mx-0" />
          </Link>
        </div>
        <div className="absolute inset-0 flex flex-col items-start justify-start md:px-20 px-5 mt-[200px]">
          <h3 className="text-white txt-style-1 mb-5">
            Work For charity? <br />
            Sign in for a Charity Account?
          </h3>
          <h3 className="text-white txt-style-2 mb-10">Create Account</h3>
        </div>
      </div>

      <div className="md:flex-1 flex items-center justify-start p-10 sm:p-10 md:ms-10 max-w-screen-lg mx-auto w-full h-full fade-in-right">
        <div className="max-w-full w-full sm:max-w-lg 2xl:max-w-2xl">
          <h1 className="txt-style-3 text-left">
            Thousands Are Raising Funds Online On Prabalta You Can Too!
          </h1>

          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="flex justify-between mb-5 w-1/2">
              <div className="flex items-center">
                <Controller
                  name="organization"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Please select an option" }}
                  render={({ field }) => (
                    <div className="flex">
                      <input
                        {...field}
                        type="radio"
                        id="organization-radio"
                        value="Organization"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.organization && "border-red-500"
                          }`}
                      />
                      <label
                        htmlFor="organization-radio"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 me-4"
                      >
                        Organization
                      </label>
                    </div>
                  )}
                />
              </div>

              <div className="flex items-center">
                <Controller
                  name="organization"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Please select an option" }}
                  render={({ field }) => (
                    <div className="flex">
                      <input
                        {...field}
                        type="radio"
                        id="individual-radio"
                        value="Individual"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.organization && "border-red-500"
                          }`}
                      />
                      <label
                        htmlFor="individual-radio"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Individual
                      </label>
                    </div>
                  )}
                />
              </div>
            </div>

            <p className="text-red-500 text-sm mb-6">
              {!selectedOrganization &&
                errors.organization &&
                errors.organization.message}
            </p>

            {!watch("organization") && selectedOrganization && (
              <p className="text-red-500 text-sm mt-1">
                Please select an organization type before proceeding.
              </p>
            )}

            <div className="mb-6">
              <input
                {...register("username")}
                placeholder="Enter Organization / User Name"
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.username && "border-red-500"
                  }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.username?.message}
              </p>
            </div>

            <div className="mb-6">
              <input
                {...register("email")}
                placeholder="Email"
                type="email"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.email && "border-red-500"
                  }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.email?.message}
              </p>
            </div>

            <div className="mb-6 relative">
              <input
                {...register("password")}
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.password && "border-red-500"
                  }`}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer h-10"
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </span>
              <p className="text-red-500 text-sm mt-1">
                {errors.password?.message}
              </p>
            </div>

            <div className="mb-6 relative">
              <input
                {...register("confirmPassword")}
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.confirmPassword && "border-red-500"
                  }`}
              />
              <span
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer h-10"
              >
                {showConfirmPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </span>
              <p className="text-red-500 text-sm mt-1">
                {errors.confirmPassword?.message}
              </p>
            </div>

            {/* <div className="mb-6">
              <input
                {...register("contactnumber")}
                placeholder="Contact Number *"
                type="text"
                maxLength={14}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.contactnumber && "border-red-500"
                }`}
                onChange={handlePhoneNumberChange}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.contactnumber?.message}
              </p>
            </div> */}

            <div className="mb-6">
              <Controller
                name="contactnumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    mask="(999) 999-9999"
                    placeholder="Contact Number *"
                    alwaysShowMask={false}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.contactnumber && "border-red-500"
                      }`}
                  />
                )}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.contactnumber?.message}
              </p>
            </div>

            <div className="mb-6">
              <input
                {...register("termsAndConditions")}
                type="checkbox"
                className="mr-2"
              />
              <label className="text-dark-200 ">
                I have read and accept the{" "}
                <a onClick={() => setShowTermsPopup(true)}>
                  {" "}
                  <u>terms and conditions</u>
                </a>
              </label>
              <p className="text-red-500 text-sm mt-1">
                {errors.termsAndConditions?.message}
              </p>
            </div>

            {showTermsPopup && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-6 rounded-md max-w-md w-full">
                  <h2 className="text-xl font-bold mb-4">
                    Terms and Conditions
                  </h2>
                  By registering with our non-profit organization, you agree to
                  abide by our mission, values, and policies. You understand
                  that any misuse of resources or violation of ethical standards
                  may result in termination of your registration.
                  <div className="mt-4">
                    <button
                      onClick={() => {
                        setShowTermsPopup(false);
                      }}
                      className="mt-6 p-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 cursor-pointer"
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="md:flex w-full justify-between items-center space-y-4 md:space-y-0">
              <button
                type="submit"
                className="w-full md:w-1/3 text-white p-2 rounded-md btn-style-1"
              >
                Sign Up
              </button>

              <p className="text-sm text-dark-600 text-center">
                <i> Or Sign Up With </i>
              </p>

              <div className="flex justify-center space-x-4">
                <a
                  href="#"
                  onClick={
                    () =>
                      // handleSocialIconClick(
                      //   "https://prabalta.org:8087/prabaltatwo/oauth2/authorize/google?redirect_uri=https://prabalta.org/oauth2/redirect"
                      // )
                      handleSocialIconClick(
                        "https://prabalta.org:8087/prabaltatwouat/oauth2/authorize/google?redirect_uri=https://prabalta.org/oauth2/redirect"
                      )

                    // handleSocialIconClick(
                    //   "https://localhost:8080/oauth2/authorize/google?redirect_uri=https://social.prabalta.org/oauth2/redirect"
                    // )
                  }
                  className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 488 512"
                  >
                    <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
                  </svg>
                </a>

                <a
                  href="#"
                  onClick={
                    () =>
                      // handleSocialIconClick(
                      //   "https://prabalta.org:8087/prabaltatwo/oauth2/authorize/facebook?redirect_uri=https://prabalta.org/oauth2/redirect"
                      // )

                      handleSocialIconClick(
                        "https://prabalta.org:8087/prabaltatwouat/oauth2/authorize/facebook?redirect_uri=https://prabalta.org/oauth2/redirect"
                      )

                    //   handleSocialIconClick(
                    //   "http://localhost:8080/oauth2/authorize/facebook?redirect_uri=https://social.prabalta.org/oauth2/redirect"
                    // )
                  }
                  className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                  </svg>
                </a>

                {/* <a
                  href="#"
                  onClick={() =>
                    handleSocialIconClick(
                      "http://localhost:8080/oauth2/authorize/microsoft?redirect_uri=http://localhost:3000/oauth2/redirect"
                    )
                  }
                  className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path d="M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z" />
                  </svg>
                </a> */}
              </div>
            </div>
          </form>

          <ToastContainer theme="colored" />
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;