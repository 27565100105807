import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "@mantine/core"; // Assuming you are using Mantine for Modal
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { getTypeRequestWithTokenWithDataWithAuth } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import OnboardingProcess from "../Common/OnboardingProcess";
import axios from "axios";

import Tour from "reactour";

const stepStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
};

export const PrabaltaServicesDashboard = () => {
  const [subdomainTitle, setSubdomainTitle] = useState(
    "Build your own website"
  );
  const [subdomainLink, setSubdomainLink] = useState("/createDomain");
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const navigate = useNavigate();
  const [nextModule, setNextModule] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const [isTourOpen, setIsTourOpen] = useState(false);

  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const [onboardingOpenFlag, setOnboardingOpenFlag] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const partnerId = partnerData.partnerId;

  const [steps, setSteps] = useState([
    {
      selector: "#service-donor",
      content: (
        <div className="step-content">
          <span className="emoji-large">🎉</span>
          <div>
            This is your first service to manage your donor records. Learn more
            about it here.
          </div>
          <div>It's quick and easy!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#service-social",
      content: (
        <div className="step-content">
          <span className="emoji-large">📱</span>
          <div>
            This is the Social integration section where you can schedule posts
            or reels to share on social media.
          </div>
          <div>It's quick and easy!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#service-domain",
      content: (
        <div className="step-content">
          <span className="emoji-large">🌐</span>
          <div>
            This is the section where you can build your own domain for your
            brand.
          </div>
          <div>Make it unique!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#service-email",
      content: (
        <div className="step-content">
          <span className="emoji-large">✉️</span>
          <div>
            This is the email service section for managing your campaigns and
            communications.
          </div>
          <div>Reach your audience effectively!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#service-analytics",
      content: (
        <div className="step-content">
          <span className="emoji-large">📊</span>
          <div>
            This is the analytics and reporting section to monitor your
            performance.
          </div>
          <div>Make informed decisions!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#service-payment",
      content: (
        <div className="step-content">
          <span className="emoji-large">💳</span>
          <div>
            This is the payment section where you can manage all transactions.
          </div>
          <div>Secure and simple!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
  ]);
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchOnboardingStatus = async () => {
      try {
        const response = await axios.get(
          `${TQ_URL}/user-Onboarding-status/${partnerId}`
        );
        console.log(response.data.onBoardingStatus);

        if (response.data.onBoardingStatus === false) {
          setIsOnboardingOpen(true); // Open the modal if the onboarding status is false

          await updateOnboardingStatusToTrue();
        } else {
          setIsTourOpen(true);
        }
      } catch (error) {
        console.error("Error fetching onboarding status:", error);
      }
    };

    const updateOnboardingStatusToTrue = async () => {
      try {
        await axios.put(`${TQ_URL}/update-onboarding-status/${partnerId}`, {
          onBoardingStatus: true,
        });
        console.log("Onboarding status updated to true");
      } catch (error) {
        console.error("Error updating onboarding status:", error);
      }
    };

    fetchOnboardingStatus();
  }, []);

  useEffect(() => {
    const fetchNextModule = async () => {
      try {
        const response = await axios.get(`${TQ_URL}/next/${partnerId}`);

        console.log(response);

        console.log(response.data);
        console.log(response.data.modules.moduleId);
        if (response.status === 200) {
          const moduleId = response.data.modules.moduleId;
          console.log("Fetched module ID:", moduleId);
          if (response.data && response.data.modules !== undefined) {
            setCurrentStep(moduleId - 1); // Assuming moduleId corresponds to step index + 1
            //    setIsTourOpen(true);
          }
        } else {
          setNextModule(null); // No content (e.g., tour completed)
        }
      } catch (err) {
        setError("Error fetching the next module");
      }
    };

    fetchNextModule();
  }, []);

  useEffect(() => {
    console.log("Updated currentStep value: ", currentStep);
  }, [currentStep]);

  useEffect(() => {
    fetchDomainStatus();
  }, []);
  const handleOnboardingModalClose = () => {
    console.log("close function called");
    setIsOnboardingOpen(false);
    setIsTourOpen(true);
  };

  const fetchDomainStatus = async () => {
    try {
      const partnerId = partnerData.partnerId; // Replace with your partner ID or fetch it from context or props.
      const response = await getTypeRequestWithTokenWithDataWithAuth(
        `${TQ_URL}/check/${partnerId}`
      );

      console.log(response);
      if (response === "Domain exists for the given partner ID.") {
        setSubdomainTitle("Manage your subdomain");
        setSubdomainLink("/money-details"); // Update link if domain exists
      }
    } catch (error) {
      console.error("Error fetching domain status:", error);
      // Handle error appropriately
    }
  };

  const handleAnalyticsClick = async (e) => {
    e.preventDefault();

    try {
      const partnerId = 1; // Replace with your partner ID or fetch it from context or props.
      const response = await getTypeRequestWithTokenWithDataWithAuth(
        `${TQ_URL}/check/${partnerId}`
      );

      if (response === "Domain exists for the given partner ID.") {
        navigate("/analyticsDashboard");
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error checking domain status:", error);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate("/createDomain");
  };

  const handleSkip = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <DonorManagementTop />

      <main className="flex h-full my-10 justify-center">
        <div className="container px-5 md:px-10 lg:px-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 md:gap-9">
          <div id="service-donor">
            <Link
              to="/donorlist"
              className="group relative bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 cursor-pointer"
            >
              <div className="relative">
                <img
                  src="assets/images/hww1_copy1.png"
                  alt="Donor Management services"
                  className="w-full h-40 sm:h-48 object-contain transform group-hover:scale-110 transition-transform duration-300"
                />
              </div>
              <div className="pt-3 sm:pt-5 px-3 sm:px-4 pb-4 sm:pb-6 text-center">
                <h2 className="text-lg sm:text-xl font-semibold relative text-gray-800 group-hover:text-[#ff7b00] transition-colors duration-300">
                  Manage your donor base
                </h2>
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-0 group-hover:opacity-75 transition-opacity duration-300"></div>
            </Link>
          </div>
          <div id="service-social">
            <Link
              to="/schedulePost"
              className="group relative bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 cursor-pointer"
            >
              <div className="relative">
                <img
                  src="assets/images/socialMedia2.jpg"
                  alt="Social Media Campaign"
                  className="w-full h-40 sm:h-48 object-contain transform group-hover:scale-110 transition-transform duration-300"
                />
              </div>
              <div className="pt-3 sm:pt-5 px-3 sm:px-4 pb-4 sm:pb-6 text-center">
                <h2 className="text-lg sm:text-xl font-semibold text-gray-800 group-hover:text-[#ff7b00] transition-colors duration-300">
                  Market on social media
                </h2>
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-0 group-hover:opacity-75 transition-opacity duration-300"></div>
            </Link>
          </div>
          <div id="service-domain">
            {/* {partnerData.userType == "partner"  ? */}
              <Link
                to={subdomainLink}
                className="group relative bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 cursor-pointer"
              >
                <div className="relative">
                  <img
                    src="assets/images/subDomain.jpg"
                    alt="SubDomain"
                    className="w-full h-40 sm:h-48 object-contain transform group-hover:scale-110 transition-transform duration-300"
                  />
                </div>
                <div className="pt-3 sm:pt-5 px-3 sm:px-4 pb-4 sm:pb-6 text-center">
                  <h2 className="text-lg sm:text-xl font-semibold text-gray-800 group-hover:text-[#ff7b00] transition-colors duration-300">
                    {subdomainTitle}
                  </h2>
                </div>
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-0 group-hover:opacity-75 transition-opacity duration-300"></div>
              </Link>
              {/* : null} */}

            {/* {partnerData.userType == "subadmin" && subdomainLink == "/money-details" ?
              <Link
                to={subdomainLink}
                className="group relative bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 cursor-pointer"
              >
                <div className="relative">
                  <img
                    src="assets/images/subDomain.jpg"
                    alt="SubDomain"
                    className="w-full h-40 sm:h-48 object-contain transform group-hover:scale-110 transition-transform duration-300"
                  />
                </div>
                <div className="pt-3 sm:pt-5 px-3 sm:px-4 pb-4 sm:pb-6 text-center">
                  <h2 className="text-lg sm:text-xl font-semibold text-gray-800 group-hover:text-[#ff7b00] transition-colors duration-300">
                    {subdomainTitle}
                  </h2>
                </div>
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-0 group-hover:opacity-75 transition-opacity duration-300"></div>
              </Link>
              : null} */}
          </div>
          <div id="service-email">
            <Link
              to="/mailingList"
              className="group relative bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 cursor-pointer"
            >
              <div className="relative">
                <img
                  src="assets/images/emailCampNew (1).png"
                  alt="Email campaign"
                  className="w-full h-40 sm:h-48 object-contain transform group-hover:scale-110 transition-transform duration-300"
                />
              </div>
              <div className="pt-3 sm:pt-5 px-3 sm:px-4 pb-4 sm:pb-6 text-center">
                <h2 className="text-lg sm:text-xl font-semibold text-gray-800 group-hover:text-[#ff7b00] transition-colors duration-300">
                  Run your campaigns
                </h2>
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-0 group-hover:opacity-75 transition-opacity duration-300"></div>
            </Link>
          </div>
          <div id="service-analytics">
            <Link
              to="#"
              onClick={handleAnalyticsClick}
              className="group relative bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 cursor-pointer"
            >
              <div className="relative">
                <img
                  src="assets/images/analytics.jpg"
                  alt="Analytics and Reporting"
                  className="w-full h-40 sm:h-48 object-contain transform group-hover:scale-110 transition-transform duration-300"
                />
              </div>
              <div className="pt-3 sm:pt-5 px-3 sm:px-4 pb-4 sm:pb-6 text-center">
                <h2 className="text-lg sm:text-xl font-semibold text-gray-800 group-hover:text-[#ff7b00] transition-colors duration-300">
                  Get Analytics and Reporting
                </h2>
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-0 group-hover:opacity-75 transition-opacity duration-300"></div>
            </Link>
          </div>
          <div id="service-payment">
            <Link
              to="/paymentMethod"
              className="group relative bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 cursor-pointer"
            >
              <div className="relative">
                <img
                  src="assets/images/multipayment.png"
                  alt="Payment Services"
                  className="w-full h-40 sm:h-48 object-contain transform group-hover:scale-110 transition-transform duration-300"
                />
              </div>
              <div className="pt-3 sm:pt-5 px-3 sm:px-4 pb-4 sm:pb-6 text-center">
                <h2 className="text-lg sm:text-xl font-semibold text-gray-800 group-hover:text-[#ff7b00] transition-colors duration-300">
                  Utilize multiple payment services
                </h2>
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-0 group-hover:opacity-75 transition-opacity duration-300"></div>
            </Link>
          </div>
          {/* <button
            className="bg-blue-500 text-white px-6 py-3 rounded-full"
            onClick={() => setIsOnboardingOpen(true)}
          >
            Open Onboarding Process
          </button> */}

          {isOnboardingOpen && (
            <OnboardingProcess
              isOpen={isOnboardingOpen}
              closeModal={handleOnboardingModalClose} // Make sure the modal can be closed
            />
          )}

          {/* Modal for creating subdomain */}
          <Modal
            opened={isModalOpen}
            onClose={handleModalClose}
            centered
            size="lg"
            overlayOpacity={0.8}
            overlayBlur={5}
            transition="fade"
            transitionDuration={500}
            withCloseButton={false} // Remove the close button
            closeOnClickOutside={false} // Prevent closing on outside click
            classNames={{
              modal:
                "bg-gradient-to-r from-yellow-400 to-orange-500 p-8 rounded-lg",
            }}
            styles={{
              modal: {
                height: "450px", // Increase height to prevent cropping
                paddingTop: "20px", // Add padding to prevent cropping
              },
            }}
          >
            <div className="flex flex-col items-center justify-center text-center h-full">
              <div className="text-6xl mb-4 animate-bounce">🤔</div>
              <h2 className="text-3xl font-bold mb-4 text-black drop-shadow-lg">
                Consider Creating a Subdomain First
              </h2>
              <p className="text-lg mb-6 text-black drop-shadow-lg">
                To access this module, you need to create a subdomain. Please
                continue to create your subdomain.
              </p>
              <div className="flex space-x-4">
                <Button
                  className="bg-white text-blue-800 hover:bg-gray-200 px-6 py-2 rounded-full"
                  onClick={handleModalClose}
                >
                  Continue
                </Button>
                <Button
                  className="bg-white text-blue-800 hover:bg-gray-200 px-6 py-2 rounded-full"
                  onClick={handleSkip}
                >
                  Skip
                </Button>
              </div>
            </div>
          </Modal>
        </div>

        {isTourOpen && currentStep !== null && isLargeScreen && (
          <Tour
            steps={steps}
            isOpen={isTourOpen}
            rounded={5}
            startAt={currentStep}
            stepInteraction={true}
            maskClassName="mask"
            accentColor="#269faf"
            onRequestClose={() => setIsTourOpen(false)}
            nextButton={<button className="tour-button">Next ➡️</button>}
            prevButton={<button className="tour-button">⬅️ Back</button>}
            showSkipButton={true}
            lastStepNextButton={
              <button
                className="tour-button"
                onClick={() => setIsTourOpen(false)}
              >
                Finish 🎉
              </button>
            }
          />
        )}
      </main>
    </div>
  );
};

export default PrabaltaServicesDashboard;
