import React, { useRef, useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment/moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import nodemailer from "nodemailer";

import { getRequestWithTokenWithDataWithAuthPartner } from "../../utility/apiRequest";

import { TQ_URL } from "../../utility/baseUrl";

const ViewRecieptPopUp = ({ onClose, rowData, activeTab }) => {
  const componentRef = useRef(null);

  const [logoUrl, setLogoUrl] = useState([]);

  var pageUrl;

  console.log("DATE TODAY>>>", moment(new Date()).format("MMM Do YYYY"));
  console.log("rowData in pop up >>:", rowData);
  console.log("rowData in pop up active tab>>:", activeTab);

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  useEffect(() => {
    getLogoUrl();
  }, []);

  const shareOnTwitter = (fundraiserId) => {
    console.log("sharing the content on twitter");

    if (activeTab == 1) {
      const pageBaseUrl = "https://prabaltauat.prabalta.org/";
      const shareText = encodeURIComponent(
        `I have Successfully donated $${rowData.donatedAmt} to ${rowData.fundraiserName}. Check out this fundraiser on website!`
      );

      const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareText}&url=${encodeURIComponent(
        pageUrl
      )}`;

      window.open(twitterShareUrl, "_blank");
    } else if (activeTab == 2) {
      const pageBaseUrl = "www.prabalta.org/fundraiser";

      if (fundraiserId == null) pageUrl = "www.prabalta.org";
      else pageUrl = `${pageBaseUrl}/${fundraiserId}`;
      const shareText = encodeURIComponent(
        `I have Successfully donated $${rowData.donatedAmt} to ${rowData.causeNames}. Check out this fundraiser on website!`
      );
      const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareText}&url=${encodeURIComponent(
        pageUrl
      )}`;
      window.open(twitterShareUrl, "_blank");
    }
  };

  const shareOnFacebook = (fundraiserId) => {
    if (activeTab == 1) {
      const pageBaseUrl = "https://prabaltauat.prabalta.org/";
      const shareText = encodeURIComponent(
        `I have Successfully donated $${rowData.donatedAmt} to $${rowData.fundraiserName}. Check out this fundraiser on website!`
      );

      const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        pageBaseUrl
      )}&quote=${encodeURIComponent(shareText)}`;

      window.open(facebookLink, "_blank");
    } else if (activeTab == 2) {
      const pageBaseUrl = "http://www.prabalta.org/fundraiser";
      if (fundraiserId == null) pageUrl = "www.prabalta.org";
      else pageUrl = `${pageBaseUrl}/${fundraiserId}`;
      const shareText = encodeURIComponent(
        `I have Successfully donated $${rowData.donatedAmt} to ${rowData.causeNames}. Check out this fundraiser on website!`
      );

      const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        pageUrl
      )}&quote=${encodeURIComponent(shareText)}`;

      window.open(facebookLink, "_blank");
    }
  };

  // const sendEmailWithPDF = async (pdfData) => {
  //   const transporter = nodemailer.createTransport({
  //     host: "myexamo.com",
  //     port: 587,
  //     secure: false,
  //     auth: {
  //       user: "thinkquotientotp@myexamo.com",
  //       pass: "R08qtP&pOtzF",
  //     },
  //   });

  //   const mailOptions = {
  //     from: "thinkquotientotp@myexamo.com",
  //     to: "tqsonalis@gmail.com",
  //     subject: "Receipt for Your Purchase",
  //     text: "Dummy message",
  //     attachments: [
  //       {
  //         filename: `DonationReceipt_${moment(new Date()).format(
  //           "MMM_Do_YYYY"
  //         )}.pdf`,
  //         content: pdfData,
  //         contentType: "application/pdf",
  //       },
  //       ...attachments.map((file) => ({
  //         filename: file.name,
  //         content: file.data,
  //       })),
  //     ],
  //   };

  //   try {
  //     await transporter.sendMail(mailOptions);
  //     console.log("Email sent successfully");
  //   } catch (error) {
  //     console.error("Error sending email:", error);
  //   }
  // };

  const getLogoUrl = async () => {
    try {
      const response = await getRequestWithTokenWithDataWithAuthPartner(
        `${TQ_URL}/getLogoURL`,
        {
          partnerId: partnerData.partnerId,
        }
      );
      console.log(response.logURL);
      setLogoUrl(response.logURL);
    } catch (error) {
      console.error("Error in fetching the logoURL:", error);
    }
  };

  const convertImageToBase64 = (imgUrl, callback) => {
    const proxyUrl = "https://cors-anywhere.herokuapp.com/";
    const img = new Image();
    img.crossOrigin = "Anonymous"; // This is important for cross-origin images
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      callback(dataURL);
    };
    img.src = proxyUrl + imgUrl;
  };

  const handleExportPDF = (type) => {
    const pdf = new jsPDF("p", "mm", "a4");
    const divToExclude = document.getElementById("share");
    // Hide the div to exclude
    const originalDisplayStyle = divToExclude.style.display;
    divToExclude.style.display = "none";

    // Get all table cells in Column 3
    const columnThreeCells = document.querySelectorAll(
      '[data-column="excludeSection"]'
    );

    // Hide Column 3 content for PDF export by adding inline style
    columnThreeCells.forEach((cell) => {
      cell.style.display = "none";
    });

    if (componentRef.current) {
      html2canvas(componentRef.current, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pageWidth = 210;
        const pageHeight = 297;
        const padding = 10;
        const componentWidth = canvas.width;
        const componentHeight = canvas.height;
        const scaleFactor = Math.min(
          (pageWidth - 2 * padding) / componentWidth,
          (pageHeight - 2 * padding) / componentHeight
        );

        const x = padding;
        const y = padding;

        // Add the image as a PNG to the PDF with padding
        pdf.addImage(
          imgData,
          "PNG",
          x,
          y,
          componentWidth * scaleFactor,
          componentHeight * scaleFactor
        );

        // Show Column 3 content after generating the PDF
        columnThreeCells.forEach((cell) => {
          cell.style.display = "";
        });

        divToExclude.style.display = originalDisplayStyle;

        if (type == 1) {
          console.log("type-1", type);
          const d = moment(new Date()).format("MMM Do YYYY");
          // Save or display the PDF
          pdf.save(`Donation Receipt_${d}.pdf`);
        } else if (type == 2) {
          console.log("type-2", type);
          const pdfData = pdf.output("blob");
          console.log(pdfData);

          const file = new File([pdfData], "filename.pdf", {
            type: "application/pdf",
          });

          const formData = new FormData();

          formData.append("donorEmail", rowData.donorDetails.donorEmail);
          formData.append("pdfObj", file);
          axios
            .post(`${TQ_URL}/sendReceiptOnEmail`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              console.log(response);
              toast.success("Receipt Sent to email Successfully");
            });
        }
      });
    }
  };

  // const handleExportPDF = (type) => {
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const divToExclude = document.getElementById("share");
  //   // Hide the div to exclude
  //   const originalDisplayStyle = divToExclude.style.display;
  //   divToExclude.style.display = "none";

  //   // Get all table cells in Column 3
  //   const columnThreeCells = document.querySelectorAll(
  //     '[data-column="excludeSection"]'
  //   );

  //   // Hide Column 3 content for PDF export by adding inline style
  //   columnThreeCells.forEach((cell) => {
  //     cell.style.display = "none";
  //   });

  //   if (componentRef.current) {
  //     html2canvas(componentRef.current, { scale: 2 }).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const pageWidth = 210; // A4 width in mm
  //       const pageHeight = 297; // A4 height in mm
  //       const padding = 10; // Padding around the image
  //       const componentWidth = canvas.width * 0.264583; // Convert px to mm
  //       const componentHeight = canvas.height * 0.264583; // Convert px to mm
  //       const scaleFactor = Math.min(
  //         (pageWidth - 2 * padding) / componentWidth,
  //         (pageHeight - 2 * padding) / componentHeight
  //       );

  //       const x = padding;
  //       const y = padding;

  //       // Add the image as a PNG to the PDF with padding
  //       pdf.addImage(
  //         imgData,
  //         "PNG",
  //         x,
  //         y,
  //         componentWidth * scaleFactor,
  //         componentHeight * scaleFactor
  //       );

  //       // Convert external image to base64
  //       const externalImageUrl =
  //         "http://prabalta.org/prabalta/usnpDocument/uploadLogo/Partner_14/applePay1.png"; // Replace with your URL
  //       convertImageToBase64(logoUrl, (base64Image) => {
  //         // Add base64 image to the PDF
  //         pdf.addImage(base64Image, "PNG", 10, 20, 50, 50); // Adjust position and size as needed

  //         // Show Column 3 content after generating the PDF
  //         columnThreeCells.forEach((cell) => {
  //           cell.style.display = "";
  //         });

  //         divToExclude.style.display = originalDisplayStyle;

  //         if (type === 1) {
  //           const d = new Date().toLocaleDateString("en-GB", {
  //             day: "2-digit",
  //             month: "short",
  //             year: "numeric",
  //           });
  //           // Save the PDF
  //           pdf.save(`Donation Receipt_${d}.pdf`);
  //         } else if (type == 2) {
  //           console.log("type-2", type);
  //           const pdfData = pdf.output("blob");
  //           console.log(pdfData);
  //           const file = new File([pdfData], "filename.pdf", {
  //             type: "application/pdf",
  //           });

  //           const formData = new FormData();

  //           formData.append("donorEmail", rowData.donorDetails.donorEmail);
  //           formData.append("pdfObj", file);
  //           axios
  //             .post(`${TQ_URL}/sendReceiptOnEmail`, formData, {
  //               headers: {
  //                 "Content-Type": "multipart/form-data",
  //               },
  //             })
  //             .then((response) => {
  //               console.log(response);
  //               toast.success("Receipt Sent to email Successfully");
  //             });
  //         }
  //       });
  //     });
  //   }
  // };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-scroll">
      <div className="fade-in-bottom">
        <div
          className="bg-white border-4 border-black max-w-2xl w-full mt-[200px]"
          ref={componentRef}
        >
          <button onClick={onClose} className="mt-2 p-2cursor-pointer absolute">
            <svg
              className="w-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                  fill="#0F0F0F"
                ></path>{" "}
              </g>
            </svg>{" "}
          </button>

          <div className="mb-4"></div>
          <div className="flex items-center flex-col mb-4 ">
            {logoUrl && logoUrl != null ? (
              <img
                src={logoUrl}
                // onError={(e) => {
                //   e.target.src = "/assets/images/main-logo.svg" // Clear src to avoid displaying broken image icon
                // }}
                className="h-10  mx-auto sm:mx-0"
              />
            ) : (
              <img
                src="/assets/images/main-logo.svg"
                alt="Logo"
                className="h-10  mx-auto sm:mx-0"
              />
            )}
          </div>

          {/* <div className="flex items-center flex-col mb-4 ">
            <img
              src="/assets/images/main-logo.svg"
              // src={logoUrl}
              alt="Logo"
              className="h-10  mx-auto sm:mx-0"
            />
          </div> */}

          <div className="mb-4"></div>

          {/* </h2> */}
          <div className="flex items-center flex-col">
            <img src="/assets/images/tick-png.png" className="w-14" />
            <h2 className="text-2xl font-bold mb-4 text-center pt-4  ">
              A great big thank you!
            </h2>
            <p className="text-sm font-semibold mb-4 text-center  px-14">
              Your donation will go directly to supporting local libraries in
              our communities. We've sent your donation receipt to{" "}
              <b> {rowData.donorDetails.donorEmail}</b>
            </p>
            <div id="share" className="px-10 bg-gray-100 border py-6 mt-3">
              <p className="text-xs font-semibold mb-4 text-center">
                Tell the world about your generosity and help spread the word!
              </p>
              <div className="flex justify-between space-x-4 px-4 ">
                <button
                  className="share-fb-div flex items-center "
                  onClick={() => {
                    shareOnFacebook(rowData.fundraiserId);
                  }}
                >
                  Share on Facebook{" "}
                  <svg
                    viewBox="-5 0 20 20"
                    className="w-6"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    fill="#000000"
                    onClick={() => {
                      shareOnFacebook(rowData.fundraiserId);
                    }}
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>facebook [#176]</title>{" "}
                      <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        {" "}
                        <g
                          id="Dribbble-Light-Preview"
                          transform="translate(-385.000000, -7399.000000)"
                          fill="#ffffff"
                        >
                          {" "}
                          <g
                            id="icons"
                            transform="translate(56.000000, 160.000000)"
                          >
                            {" "}
                            <path
                              d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                              id="facebook-[#176]"
                            >
                              {" "}
                            </path>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </button>

                <button
                  className="share-twitter-div flex items-center "
                  onClick={() => {
                    shareOnTwitter(rowData.fundraiserId);
                  }}
                >
                  Share on Twitter{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 72 72"
                    viewBox="0 0 72 72"
                    className="w-8"
                    id="twitter-x"
                  >
                    <switch>
                      <g>
                        <path
                          d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66
			h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"
                        ></path>
                      </g>
                    </switch>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="px-16 pt-6">
            <div className="border px-6 py-4 space-y-3 rounded-lg">
              <div className="flex justify-between items-center">
                <div className="flex px-3">
                  <svg
                    className="w-4 me-2"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <circle
                        cx="12"
                        cy="6"
                        r="4"
                        stroke="#7a7a7a"
                        stroke-width="1.5"
                      ></circle>{" "}
                      <path
                        d="M19.9975 18C20 17.8358 20 17.669 20 17.5C20 15.0147 16.4183 13 12 13C7.58172 13 4 15.0147 4 17.5C4 19.9853 4 22 12 22C14.231 22 15.8398 21.8433 17 21.5634"
                        stroke="#7a7a7a"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      ></path>{" "}
                    </g>
                  </svg>
                  <p className="text-sm text-gray-500 font-semibold">
                    DONOR NAME :
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-500 font-semibold">
                    {rowData.donorName}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex px-3">
                  <svg
                    className="w-4 me-2"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M4 18L9 12M20 18L15 12M3 8L10.225 12.8166C10.8665 13.2443 11.1872 13.4582 11.5339 13.5412C11.8403 13.6147 12.1597 13.6147 12.4661 13.5412C12.8128 13.4582 13.1335 13.2443 13.775 12.8166L21 8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z"
                        stroke="#7a7a7a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>{" "}
                  <p className="text-sm text-gray-500 font-semibold">
                    EMAIL ADDRESS :
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-500 font-semibold">
                    {rowData.donorDetails.donorEmail}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex px-3">
                  <svg
                    className="w-4 me-2"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z"
                        stroke="#7a7a7a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                      <path
                        d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z"
                        stroke="#7a7a7a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>{" "}
                  <p className="text-sm text-gray-500 font-semibold">
                    BILLING ADDRESS:
                  </p>
                </div>

                <div className="w-2/5">
                  {rowData.donorAddress !== null ? (
                    <p className="text-sm text-gray-500 font-semibold text-right">
                      {rowData.donorDetails.addresss}
                    </p>
                  ) : (
                    <p className="text-sm text-gray-500 font-semibold text-right">
                      {" "}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="border  rounded-lg mt-5">
              <div className="px-6 py-4 space-y-3">
                <div className="flex justify-between items-center">
                  <div className="flex px-3">
                    <p className="text-sm text-gray-500 font-semibold">
                      PAYMENT METHOD:
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 font-semibold">Card</p>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex px-3">
                    <p className="text-sm text-gray-500 font-semibold">
                      PAYMENT STATUS :
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 font-semibold">
                      {rowData.donationStatus}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex px-3">
                    <p className="text-sm text-gray-500 font-semibold">
                      DONATION AMOUNT:
                    </p>
                  </div>
                  <div className="w-2/5">
                    <p className="text-sm text-gray-500 font-semibold text-right">
                      ${rowData.donatedAmt.toLocaleString("en-US")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center bg-gray-200 px-6 py-2">
                <div className="flex px-3">
                  <p className="text-sm text-gray-800 font-semibold">
                    DONATION TOTAL:
                  </p>
                </div>
                <div className="w-2/5">
                  <p className="text-sm text-black font-bold text-right">
                    ${rowData.donatedAmt.toLocaleString("en-US")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center flex-col">
            <div className="px-10 py-6 mt-3 mb-10" data-column="excludeSection">
              <div className="flex justify-between space-x-4 px-4">
                <button
                  className="share-fb-div flex items-center"
                  onClick={() => handleExportPDF(1)}
                >
                  Download Receipt
                </button>

                <button
                  className="share-fb-div flex items-center"
                  onClick={() => handleExportPDF(2)}
                >
                  Email Receipt
                </button>
              </div>
            </div>
          </div>

          <h2 className="text-sm font-semibold text-gray-500 mb-4 text-center py-4 bg-gray-100 border-t-2 flex justify-center items-center mt-2">
            <svg
              className="w-3 me-3"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.25 10.0546V8C5.25 4.27208 8.27208 1.25 12 1.25C15.7279 1.25 18.75 4.27208 18.75 8V10.0546C19.8648 10.1379 20.5907 10.348 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.40931 10.348 4.13525 10.1379 5.25 10.0546ZM6.75 8C6.75 5.10051 9.10051 2.75 12 2.75C14.8995 2.75 17.25 5.10051 17.25 8V10.0036C16.867 10 16.4515 10 16 10H8C7.54849 10 7.13301 10 6.75 10.0036V8Z"
                  fill="#7a7a7a"
                ></path>{" "}
              </g>
            </svg>
            Secure Donation
          </h2>
        </div>
      </div>
      <ToastContainer theme="colored" />
    </div>
  );
};

export default ViewRecieptPopUp;
