import React, { useState } from "react";

import { useNavigate, Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  Tabs,
  Tab,
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithDataWithAuthPartner,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";

const schema = yup.object().shape({
  name: yup.string().required("User Name is Required"),
  email: yup.string().required("Email is Required"),
});

const uploadFormSchema = yup.object().shape({
  file: yup.mixed().required("File is required"), // Validation for file upload
});

const AddEmailsMailList = ({ onClose }) => {
  const [value, setValue] = React.useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors: manualErrors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    //validationSchema: schema,
  });

  const {
    register: registerUpload,
    handleSubmit: handleSubmitUpload,
    errors: uploadErrors,
  } = useForm({
    validationSchema: uploadFormSchema,
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [emailVerified, setEmailVerified] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId;

  const mailingListId = localStorage.getItem("mailingListId");

  console.log(mailingListId);

  const handleTabs = (e, value) => {
    console.log(e);
    setValue(e);

    console.log("Value:", value);
    if (e == 0) {
      console.log("data from imported donors");
    } else {
      console.log("data from website donors");
    }
  };

  const handleCheckboxChange = (event) => {
    setEmailVerified(event.target.checked);
  };

  const onSubmitUpload = async (data) => {
    // event.preventDefault();
    console.log(value);
    console.log(mailingListId);
    console.log(data);

    setLoading(true);

    // const file = event.target.file.files[0];
    const file = data.file[0];
    const formData = new FormData();
    console.log("selected file:", file);
    if (file == "undefined" || file == null) {
      toast.error("please select file first..");
      setLoading(false);
      return;
    }
    formData.append("file", file);
    formData.append("mailingListId", mailingListId);
    try {
      await getRequestWithTokenWithDataWithAuthPartner(
        `${TQ_URL}/importEmailList`,
        formData
      );

      toast.success("File uploaded successfully!");
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Error importing email user:", error);
      toast.error("Error uploading file. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const onSubmitHandler = async (data) => {
    setLoading(true);

    try {
      const response = await getRequestWithTokenWithData(
        `${TQ_URL}/addMailingListEmailManually?userName=${toInitCap(
          data.name
        )}&email=${data.email}&mailingListId=${mailingListId}`
      );
      if (response.success) {
        toast.success("Email added successfully!");
        reset();
        // setTimeout(() => {
        //   setTimeout(onClose(), 2000);
        // }, 3000);
      } else {
        toast.error("Failed to add email.");
      }
    } catch (error) {
      console.error("Error adding fund:", error);
      toast.error("An error occurred while adding email.");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-scroll z-50 px-4">
        <div className="bg-white md:mx-8 md:py-12 md:px-12 py-6 rounded-lg shadow-lg md:w-2/5 relative">
          <button
            onClick={onClose}
            className="mt-2 p-2 cursor-pointer absolute top-2 right-2"
          >
            <svg
              className="w-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                  fill="#0F0F0F"
                ></path>{" "}
              </g>
            </svg>{" "}
          </button>

          <div id="addEmailsShowModal" sx={{ width: "100%" }}>
            <div style={{ borderBottom: "1px solid #ddd" }}>
              <Tabs
                value={value}
                onChange={(event, newValue) => handleTabs(newValue)}
                aria-label="basic tabs example"
              >
                <Tab label="Upload File" value={0} />
                <Tab label="Add Manually  " value={1} />
              </Tabs>
            </div>

            <div className="md:mx-2 md:py-4 py-6 md:mt-8">
              {value === 0 && (
                <div className="bg-white shadow-md rounded p-6 max-w-md mx-auto">
                  <h2 className="text-lg font-semibold mb-4">Upload File</h2>
                  <form onSubmit={handleSubmitUpload(onSubmitUpload)}>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        File Upload
                      </label>
                      <input
                        {...registerUpload("file")}
                        type="file"
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                      />
                      {/* {uploadErrors.file && (
                <span>{uploadErrors.file.message}</span>
              )} */}
                    </div>
                    <div className="flex justify-between">
                      <button
                        type="submit"
                        className="w-full text-white p-2 rounded-md mb-6 btn-style-1 mr-2"
                        disabled={loading}
                      >
                        {loading ? "Adding..." : "Add"}
                      </button>
                      <button
                        type="button"
                        onClick={onClose}
                        className="w-full text-white p-2 rounded-md mb-6 btn-style-1 bg-gray-400 hover:bg-gray-500"
                      >
                        Cancel
                      </button>
                    </div>
                    {/* <div className="mt-4 text-center">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={emailVerified}
                  onChange={handleCheckboxChange}
                  className="form-checkbox h-4 w-4 text-blue-600"
                />
                <span className="ml-2 text-gray-700">
                  Verify uploaded email
                </span>
              </label>
            </div> */}
                  </form>
                  <div className="mt-4">
                    <a
                      className="text-blue-500 underline"
                      download="EmailListSample.xlsx"
                      target="_blank"
                      href="assets/SampleEmailList.xlsx"
                    >
                      Download Sample here
                    </a>
                  </div>
                </div>
              )}
              {value === 1 && (
                <div className="bg-white shadow-md rounded p-6  max-w-md mx-auto">
                  <h2 className="text-lg font-semibold mb-4">Add Manually</h2>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="mb-4">
                      <input
                        {...register("name")}
                        placeholder="Enter User Name"
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          manualErrors.name && "border-red-500"
                        }`}
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {manualErrors.name?.message}
                      </p>
                    </div>
                    <div className="mb-4">
                      <input
                        {...register("email")}
                        placeholder="Enter email"
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          manualErrors.email && "border-red-500"
                        }`}
                      />
                      <p className="text-red-500 text-sm mt-1">
                        {manualErrors.email?.message}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <button
                        type="submit"
                        className="w-full text-white p-2 rounded-md mb-6 btn-style-1 mr-2"
                        disabled={loading}
                      >
                        {loading ? "Adding..." : "Add"}
                      </button>
                      <button
                        type="button"
                        onClick={onClose}
                        className="w-full text-white p-2 rounded-md mb-6 btn-style-1 bg-gray-400 hover:bg-gray-500"
                      >
                        Cancel
                      </button>
                    </div>
                    {/* <div className="mt-4 text-center">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={emailVerified}
                  onChange={handleCheckboxChange}
                  className="form-checkbox h-4 w-4 text-blue-600"
                />
                <span className="ml-2 text-gray-700">
                  Verify uploaded email
                </span>
              </label>
            </div> */}
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" />
    </>
  );
};

export default AddEmailsMailList;
