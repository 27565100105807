import React, { useEffect, useState, useMemo } from "react";
import AnalyticsSidebar from "./AnalyticsSidebar";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Tooltip as RxTooltip } from "@mantine/core";
import {
  PieChart,
  Pie,
  Tooltip as RechartsTooltip,
  Cell,
  Legend,
} from "recharts";

import {
  Chart,
  Tooltip as DxTooltip,
  SeriesTemplate,
  CommonSeriesSettings,
  Title,
} from "devextreme-react/chart";
import { Box } from "@mantine/core";

import { BarChart } from "@mui/x-charts";
import {
  getRequestWithOutToken,
  getRequestWithTokenWithoutDataPartner,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";

function RecurringCausewiseDonation() {
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const [causeTypes, setCauseTypes] = useState([]);
  const [selectedCauseType, setSelectedCauseType] = useState(0);
  const [frequencies, setFrequency] = useState([]);
  const [selectedFrequency, setSelectedFrequency] = useState(0);
  const [pieData, setPieData] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [causeList, setCauseList] = useState([]);
  const [causeName, setCauseName] = useState("Select cause");
  const [causeValue, setCauseValue] = useState(1);
  const [causeId, setCauseId] = useState(0);

  const [donorData, setDonorData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [value, setValue] = useState("monthly");
  const [monthDropdown, setMonthDropdown] = useState(false);
  const [yearDropdown, setYearDropdown] = useState(true);
  const [monthValue, setMonthValue] = useState("");
  const [yearValue, setYearValue] = useState(new Date().getFullYear());
  const [graphData, setGraphData] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const yearList = [2020, 2021, 2022, 2023, 2024];

  const monthList = [
    {
      id: "01",
      name: "January",
    },
    {
      id: "02",
      name: "February",
    },
    {
      id: "03",
      name: "March",
    },
    {
      id: "04",
      name: "April",
    },
    {
      id: "05",
      name: "May",
    },
    {
      id: "06",
      name: "June",
    },
    {
      id: "07",
      name: "July",
    },
    {
      id: "08",
      name: "August",
    },
    {
      id: "09",
      name: "September",
    },
    {
      id: "10",
      name: "October",
    },
    {
      id: "11",
      name: "November",
    },
    {
      id: "12",
      name: "December",
    },
  ];

  const colors = [
    "#2731C8",
    "#60009B",
    "#B800D8",
    "#2E96FF",
    "#03008D",
    "#02B2AF",
    "#845EC2",
    "#CF3D2A",
  ];
  useEffect(() => {
    getRecurringCauseWiseDonation();
  }, []);

  useEffect(() => {
    if (!donorData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    getCauseList();
    getFrequencyList();
    getAllRecurringDonorList();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting, //refetch when sorting changes
    columnFilters,
  ]);

  useEffect(() => {
    getAllRecurringDonorList();
  }, [selectedStatus, selectedCauseType, selectedFrequency]);

  // const handleKeyPress = async (event) => {
  //   if (event.key === "Enter") {
  //     console.log("searchText:", searchText);

  //     const url = new URL(`${TQ_URL}/searchRecurringDonorCausewiseList`);
  //     url.search = new URLSearchParams();
  //     url.searchParams.set("start", `${pagination.pageIndex}`);
  //     url.searchParams.set("size", `${pagination.pageSize}`);
  //     url.searchParams.set("keyword", `${searchText}`);
  //     url.searchParams.set("partnerId", `${partnerData.partnerId}`);

  //     try {
  //       const response = await getRequestWithTokenWithoutDataPartner(
  //         url.toString()
  //       );

  //       //  setDonationData(response);
  //       console.log(response);

  //       console.log(response.datalist);

  //       const dataWithSerialNumber = response.datalist.map((item, index) => ({
  //         ...item,
  //         id: index + 1,
  //       }));

  //       console.log(response.datalist);

  //       setDonorData(dataWithSerialNumber);
  //       setRowCount(response.totalCount);

  //       setIsRefetching(false);
  //     } catch (error) {
  //       console.error("Error in fetching the searched donor's history:", error);
  //       setIsError(true);
  //       setIsLoading(false);
  //       console.error(error);
  //       return;
  //     }
  //   }
  // };

  // const handleChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  const getTypeValue = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value>>", selectedValue);

    setValue(selectedValue);

    if (selectedValue === "weekly") {
      setMonthDropdown(true);
      if (yearDropdown) setYearDropdown(false);
    } else if (selectedValue === "monthly") {
      setYearDropdown(true);

      if (monthDropdown) setMonthDropdown(false);
    } else {
      setMonthDropdown(false);
      setYearDropdown(false);
    }
  };

  const getMonthValue = (event) => {
    const selectedMonthValue = event.target.value;
    console.log("Selected month value>>", selectedMonthValue);
    setMonthValue(selectedMonthValue);
  };

  const getYearValue = (event) => {
    const selectedYearValue = event.target.value;
    console.log("Selected year value>>", selectedYearValue);
    setYearValue(selectedYearValue);
    console.log(monthValue);
  };

  useEffect(() => {
    handleAnalyticsDataFetching(value, monthValue, yearValue);
  }, [causeValue, value, monthValue, yearValue]);

  function handleAnalyticsDataFetching(typeValue, monthValue, yearValue) {
    if (typeValue === "yearly") {
      getRecurringCauseDonationAnalysisBarGraph(
        "yearly",
        monthValue,
        yearValue
      );
    } else if (typeValue === "weekly" && monthValue && yearValue) {
      getRecurringCauseDonationAnalysisBarGraph(
        "weekly",
        monthValue,
        yearValue
      );
    } else if (typeValue === "monthly" && yearValue) {
      getRecurringCauseDonationAnalysisBarGraph(
        "monthly",
        monthValue,
        yearValue
      );
    } else {
      console.error("Invalid parameters provided.");
    }
  }

  const getRecurringCauseDonationAnalysisBarGraph = async () => {
    const url = new URL(`${TQ_URL}/getRecurringCauseDonationAnalysisBarGraph`);
    url.search = new URLSearchParams();
    url.searchParams.set("typeValue", value);
    if (monthValue != "" && !isNaN(monthValue)) {
      url.searchParams.set("monthValue", parseInt(monthValue));
    }
    if (yearValue != "" && !isNaN(yearValue)) {
      url.searchParams.set("yearValue", parseInt(yearValue));
    }
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);
    url.searchParams.set("causeValue", causeValue);

    url.searchParams.set("yearList", JSON.stringify(yearList ?? []));

    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log(response);
      setGraphData(response.list);
    } catch (error) {
      console.error(
        "Error in fetching the details for analytics  data:",
        error
      );
      setIsError(true);
      return;
    }
  };

  const formatCurrency = (value) => {
    if (!value) return "";
    const amount = parseFloat(value);
    if (isNaN(amount)) return "";
    return `$${amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const getAllRecurringDonorList = async () => {
    const url = new URL(`${TQ_URL}/getRecurringCausewiseDonation`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);
    url.searchParams.set("causeId", selectedCauseType);
    url.searchParams.set("freqId", selectedFrequency);
    url.searchParams.set("statusId", parseInt(selectedStatus));
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));

    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );

      setDonorData(response);
      console.log(response);

      console.log(response.datalist);

      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log(response.datalist);

      setDonorData(dataWithSerialNumber);
      setRowCount(response.totalCount);

      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the donor's history:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    //  setIsRefetching(false);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "donorName",
        header: "Donor name",
        sortable: true,
        Cell: ({ row }) => {
          const title = row.original.donorName;
          return (
            <RxTooltip
              label={title || ""}
              styles={(theme) => ({
                tooltip: {
                  backgroundColor: theme.colors.blue[9],
                  color: theme.white,
                },
              })}
            >
              <span>
                {title && title.length > 15
                  ? `${title.substring(0, 15)}...`
                  : title}
              </span>
            </RxTooltip>
          );
        },
      },
      {
        accessorKey: "formattedDate",
        header: "Date",
        sortable: true,
      },

      {
        accessorKey: "freqName",
        header: "Frequency",
        sortable: true,
      },
      {
        accessorKey: "causeNames",
        header: "CauseType",
        sortable: true,
        Cell: ({ row }) => {
          const title = row.original.causeNames;
          return (
            <RxTooltip
              label={title || ""}
              styles={(theme) => ({
                tooltip: {
                  backgroundColor: theme.colors.blue[9],
                  color: theme.white,
                },
              })}
            >
              <span>
                {title && title.length > 15
                  ? `${title.substring(0, 15)}...`
                  : title}
              </span>
            </RxTooltip>
          );
        },
      },

      {
        accessorKey: "donatedAmt",
        header: "Amount",
        Cell: ({ cell }) => (
          <Box>{formatCurrency(cell.getValue() || "0.0")}</Box>
        ),
        sortable: true,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: donorData,

    initialState: { showColumnFilters: false },
    rowCount,

    mantineTableBodyRowProps: ({ row }) => ({}),

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,

    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  async function getCauseList() {
    const url = new URL(
      `${TQ_URL}/getCauseList?partnerId=${partnerData.partnerId}`
    );
    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log(response.data);
      setCauseTypes(response.data);
    } catch (error) {
      console.error("Error in fetching the Cause data:", error);
    }
  }
  const handleCauseTypeChange = (e) => {
    console.log("selected cause :", e.target.value);
    setSelectedCauseType(e.target.value);
  };

  const handleStatusChange = (e) => {
    console.log("selected status :", e.target.value);
    setSelectedStatus(e.target.value);
  };

  async function getFrequencyList() {
    const url = new URL(`${TQ_URL}/getFrequencyList`);
    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log("Frequency List");
      console.log(response);
      setFrequency(response);
    } catch (error) {
      console.error("Error in fetching the Cause data:", error);
    }
  }

  async function getRecurringCauseWiseDonation() {
    const url = new URL(`${TQ_URL}/getPiechartRecurringcausewisedonation`);
    url.search = new URLSearchParams();
    console.log(partnerData.partnerId);
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);

    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log(response);

      const transformedData = Object.keys(response).map((cause) => ({
        label: cause,
        value: response[cause],
      }));
      setPieData(transformedData);
    } catch (error) {
      console.error("Error in fetching the recurring Cause data:", error);
    }
  }

  const handleFrequencyChange = (e) => {
    console.log("selected Freq :", e.target.value);
    setSelectedFrequency(e.target.value);
  };

  const getCauseTypeValue = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value>>", selectedValue);

    setCauseValue(selectedValue);
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed h-full md:overflow-y-auto">
          <AnalyticsSidebar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px] overflow-x-hidden">
          {/* <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">
                  Recurring Causewise Donation
                </h2>
              </div>
            </div>

            <div className="w-full flex flex-col md:flex-row items-center md:mt-4">
              <div className="flex mx-2 my-2">
                <div className=" md:mx-2 mx-2 md:py-2 py-4">
                  <select
                    className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block md:mt-7 w-full custom-padding"
                    id="causeTypeDropdown"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  >
                    <option value="">Select status</option>
                    <option value="1">Active</option>
                    <option value="2">Failed</option>
                    <option value="4">Stopped</option>
                  </select>
                </div>
                <div className=" md:mx-2 mx-2 md:py-2 py-4">
                  <select
                    className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block md:mt-7 w-full custom-padding"
                    id="causeTypeDropdown"
                    value={selectedCauseType}
                    onChange={handleCauseTypeChange}
                  >
                    <option value="">Select a cause type</option>
                    {causeTypes.map((cause) => (
                      <option key={cause.causeId} value={cause.causeId}>
                        {cause.causeName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" mx-2 md:py-2 py-4">
                  <select
                    className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block md:mt-7 w-full custom-padding"
                    id="causeTypeDropdown"
                    value={selectedFrequency}
                    onChange={handleFrequencyChange}
                  >
                    <option value="">Select Frequency</option>
                    {frequencies.map((freq) => (
                      <option key={freq.freqId} value={freq.freqId}>
                        {freq.frequencyName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div> */}

          <div className="mx-auto shadow-md">
            <div className="flex justify-between items-center container px-10 py-6">
              <div className="text-center md:text-left flex-1">
                <h2 className="text-2xl font-bold">
                  Recurring Causewise Donation
                </h2>
              </div>
            </div>

            {/* Dropdowns in a separate row, responsive to screen size */}
            <div className="container px-10 py-2">
              <div className="flex flex-col md:flex-row justify-between">
                <div className="md:mx-2 mx-2 w-full">
                  <select
                    className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                    id="causeTypeDropdown"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  >
                    <option value="">Select status</option>
                    <option value="1">Active</option>
                    <option value="2">Failed</option>
                    <option value="4">Stopped</option>
                  </select>
                </div>
                <div className="md:mx-2 mx-2 w-full">
                  <select
                    className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                    id="causeTypeDropdown"
                    value={selectedCauseType}
                    onChange={handleCauseTypeChange}
                  >
                    <option value="">Select a cause type</option>
                    {causeTypes.map((cause) => (
                      <option key={cause.causeId} value={cause.causeId}>
                        {cause.causeName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="md:mx-2 mx-2 w-full">
                  <select
                    className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                    id="causeTypeDropdown"
                    value={selectedFrequency}
                    onChange={handleFrequencyChange}
                  >
                    <option value="">Select Frequency</option>
                    {frequencies.map((freq) => (
                      <option key={freq.freqId} value={freq.freqId}>
                        {freq.frequencyName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="md:mx-8 md:py-8 py-6">
            <div sx={{ width: "100%" }}>
              <div className="md:mx-2 md:py-4 py-6">
                <MantineReactTable table={table} />
              </div>
            </div>
          </div>

          <div className="container mt-8">
            <h2 className="text-lg px-10 font-bold divHidden">
              Recurring Cause Donations Analysis
            </h2>
            <div className="webkit-centerForAnalytics divHidden">
              {pieData.length > 0 && (
                <PieChart width={1100} height={600}>
                  <Pie
                    data={pieData}
                    dataKey="value"
                    nameKey="label"
                    cx="50%"
                    cy="50%"
                    outerRadius={150}
                    innerRadius={20}
                    paddingAngle={2}
                    labelLine={true}
                    label={({ name, value }) => `${name}: ${value}`}
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colors[index % colors.length]}
                      />
                    ))}
                  </Pie>
                  <RechartsTooltip />
                  <Legend
                    align="right"
                    verticalAlign="middle"
                    layout="vertical"
                  />
                </PieChart>
              )}
            </div>
          </div>

          <div className="container mt-8">
            <div className="flex flex-col md:flex-row md:items-center justify-between px-10">
              <h2 className="text-lg font-bold mb-4 md:mb-0">
                Cause Donations Analysis
              </h2>
              <div className="flex flex-wrap justify-between w-full mb-8 md:w-auto">
                <div className="mx-2 mb-4 md:mb-0 md:w-1/4">
                  <select
                    onChange={getCauseTypeValue}
                    value={causeValue}
                    className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                  >
                    <option value="">Select Cause Name</option>
                    {causeTypes.map((type, index) => (
                      <option key={index} value={type.causeId}>
                        {type.causeName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mx-2 mb-4 md:mb-0 md:w-1/4">
                  <select
                    onChange={getTypeValue}
                    value={value}
                    className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                  >
                    <option value="">Select frequency type</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
                {monthDropdown && (
                  <div className="mx-2 mb-4 md:mb-0 md:w-1/4">
                    <select
                      onChange={getMonthValue}
                      value={monthValue}
                      className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                    >
                      <option value="">Select Month</option>
                      {monthList.map((month, index) => (
                        <option key={month.id} value={month.id}>
                          {month.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {(yearDropdown || monthValue) && (
                  <div className="mx-2 mb-4 md:mb-0 md:w-1/4">
                    <select
                      onChange={getYearValue}
                      value={yearValue}
                      className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                    >
                      <option value="">Select Year</option>
                      {yearList.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>

            <div className="webkit-centerForAnalytics md:w-full md:ml-2">
              <Chart id="chart" palette="Soft" dataSource={graphData}>
                <CommonSeriesSettings
                  argumentField="freqLabel"
                  valueField="usercount"
                  type="bar"
                  ignoreEmptyPoints={true}
                  width={30}
                />
                <SeriesTemplate nameField="freqLabel" />
                {/* <Title text="Recurring CauseWise Brakdown" /> */}
                <DxTooltip enabled={true} />
              </Chart>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default RecurringCausewiseDonation;
