import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./modalStyles.css"; // Import the updated scoped CSS
import confetti from "canvas-confetti"; // Import the confetti library
import { FaFacebook, FaInstagramSquare } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { getRequestWithTokenWithData } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Triangle } from "react-loader-spinner";
import { getTypeRequestWithTokenWithDataWithAuth } from "../../utility/apiRequest";
import {
  postWithTokenWithData,
  postWithTokenWithDataReturnDate,
} from "../../utility/apiRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faCheck,
  faBan,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const FB_APP_ID = "830389442285126";
const FB_SCOPE =
  "business_management,pages_manage_cta,pages_show_list,pages_messaging,pages_manage_metadata,pages_read_engagement,pages_read_user_content,pages_manage_posts,pages_manage_engagement";
const SCOPES =
  "business_management,instagram_basic,instagram_manage_insights,instagram_content_publish,instagram_shopping_tag_products,instagram_manage_events";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .trim()
      .min(5, "Name must be at least 5 characters")
      .max(50, "Name must be at most 50 characters")
      .required("Name is Required *"),
    domainName: yup
      .string()
      .trim()
      .min(5, "Domain name must be at least 5 characters")
      .matches(
        /^[a-z0-9]+$/,
        "Domain name must be lowercase and contain no special characters"
      )
      .required("Domain name is Required *"),
    email: yup
      .string()
      .trim()
      .email("Invalid email format")
      .required("Email is Required *"),
    phone: yup
      .string()
      .matches(/^[\d+\-]+$/, "Phone must be a valid number")
      .min(8, "Phone number should be at least 8 digits")
      .required("Phone is Required *"),
    address: yup.string().required("Address is Required *"),
    zipcode: yup
      .number()
      .required("ZipCode is Required *")
      .positive("ZipCode should be positive or non-zero")
      .typeError("ZipCode should not be Empty"),
    linkedin: yup.string().url("LinkedIn link must be a valid URL").nullable(),
    facebook: yup.string().url("Facebook link must be a valid URL").nullable(),
    twitter: yup.string().url("Twitter link must be a valid URL").nullable(),
    instagram: yup
      .string()
      .url("Instagram link must be a valid URL")
      .nullable(),
    thread: yup.string().url("Thread link must be a valid URL").nullable(),
  })
  .test(
    "at-least-one-social-media",
    "At least one social media link is required",
    (value) => {
      return (
        !!value.linkedin ||
        !!value.facebook ||
        !!value.twitter ||
        !!value.instagram ||
        !!value.thread
      );
    }
  );

// Bind modal to your app element (important for screen readers)
Modal.setAppElement("#root");

const OnboardingProcess = ({ isOpen, closeModal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [step, setStep] = useState(1); // Track which step of the onboarding process the user is on
  const [causes, setCauses] = useState([""]); // State for dynamic input fields in Add Cause
  const [emails, setEmails] = useState([""]); // State for dynamic email input fields in Add Sub Admin
  const [selectedFile, setSelectedFile] = useState(null); // State for the uploaded image
  const [isDragOver, setIsDragOver] = useState(false);
  const [animationClass, setAnimationClass] = useState("modal-swipe-right"); // Default animation for first page
  const [stateName, setStateName] = useState(null); // To store the state name from zip code
  const [cityName, setCityName] = useState(null); // To store the city name from zip code
  const [notFoundMessage, setNotFoundMessage] = useState(""); // To store error message for invalid zip code
  const [countryFlag, setCountryFlag] = useState(false); // Flag to check if country is found
  const [domainAvailability, setDomainAvailability] = useState(null); // To check domain name availability
  const [domainNameWarning, setDomainNameWarning] = useState(""); // Warning for domain name validation
  const [domainName, setDomainName] = useState("");
  const [domainId, setDomainId] = useState(null);
  const [isForm1Complete, setIsForm1Complete] = useState(false);
  const [isForm2Complete, setIsForm2Complete] = useState(false);
  const [isForm3Complete, setIsForm3Complete] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // To handle loading state
  const [organizationName, setOrganizationName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [accessToken, setAccessToken] = useState(
    location.state?.accessToken || ""
  );
  const [instagramAccessToken, setInstagramAccessToken] = useState("");
  const [loadingFB, setLoadingFB] = useState(false);
  const [loadingInstagram, setLoadingInstagram] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const defaultLogoPath = "/assets/images/main-logo.svg"; // Update with the correct path

  const partnerData = JSON.parse(localStorage.getItem("partnerData")); // Retrieve partner data from local storage

  const [primaryColor, setPrimaryColor] = useState("#000000"); // Replace with the default primary color
  const [secondaryColor, setSecondaryColor] = useState("#FFFFFF"); // Replace with the default secondary color

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isOpen) {
      setModalVisible(true);
      if (step === 1) {
        setTimeout(() => {
          triggerGlitterConfetti();
        }, 800); // Sync with bounce animation (0.8s delay)
      }
    } else {
      setTimeout(() => setModalVisible(false), 800); // Delay close for animation
    }
  }, [isOpen, step]);

  // Success confetti effect, triggered when step === 11
  useEffect(() => {
    if (step === 11) {
      triggerSuccessConfetti();
    }
  }, [step]);

  const sendAddSubAdminLink = async () => {
    try {
      console.log(emails);
      const emailList = emails.filter((email) => email && email.trim() !== "");
      const currentURL = window.location.origin;
      console.log("Current URL:", currentURL);
      const url = `${TQ_URL}/sendAddSubadminLink`;
      const response = await getRequestWithTokenWithData(url, {
        emails: emailList,
        path: `${currentURL}/addSubAdminDetails`,
        partnerId: partnerData.partnerId,
      });
      console.log(response);

      // toast.success("Add SubAdmin Link send To privided email.", {
      //   position: "top-right",
      // });
    } catch (error) {
      console.error("Failed To send the link", error.response.data.message);
      toast.error("Failed To send the link", {
        position: "top-right",
      });
    }
  };

  const saveFacebookData = async (shortLivedToken) => {
    const partnerData = JSON.parse(localStorage.getItem("partnerData")); // Retrieve partnerData from localStorage
    const facebookData = {
      partnerId: partnerData.partnerId, // Use partnerId from partnerData
      channel: "Facebook",
      shortLivedToken: shortLivedToken,
    };
    const endPoint = "/api/facebook/save";
    const url = `${TQ_URL}${endPoint}`;
    try {
      const response = await postWithTokenWithDataReturnDate(url, facebookData);
      if (response.status === 201) {
        // navigate("/connectedChannels");
      } else if (response === "Data saved successfully") {
        setModalMessage(response);
        setIsModalOpen(true);
        setIsModalOpen(false);
        //navigate("/connectedChannels");
      } else {
        setModalMessage(response.data);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error saving data to backend:", error);
      setModalMessage(
        error.response?.data || error.message || "Error saving data to backend"
      );
      setIsModalOpen(true);
    } finally {
      setLoadingFB(false);
    }
  };

  const saveInstagramData = async (shortLivedToken) => {
    const partnerData = JSON.parse(localStorage.getItem("partnerData")); // Retrieve partnerData from localStorage
    const facebookData = {
      partnerId: partnerData.partnerId, // Use partnerId from partnerData
      channel: "Instagram",
      shortLivedToken: shortLivedToken,
    };
    const endPoint = "/api/facebook/save";
    const url = `${TQ_URL}${endPoint}`;
    try {
      const response = await postWithTokenWithData(url, facebookData);
      if (response.status === 201) {
        //navigate("/connectedChannels");
      } else if (response === "Data saved successfully") {
        setModalMessage(response);
        setIsModalOpen(true);
        setIsModalOpen(false);
        // navigate("/connectedChannels");
      } else {
        setModalMessage(response.data);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error saving data to backend:", error);
      setModalMessage(
        error.response?.data || error.message || "Error saving data to backend"
      );
      setIsModalOpen(true);
    } finally {
      setLoadingInstagram(false);
    }
  };

  const handleFBLogin = () => {
    setLoadingFB(true);
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          console.log("Welcome! Fetching your information.... ");
          setAccessToken(response.authResponse.accessToken);
          saveFacebookData(response.authResponse.accessToken);
        } else {
          console.log("User cancelled login or did not fully authorize.");
          setLoadingFB(false);
          setModalMessage("Failed to authenticate with Facebook");
          setIsModalOpen(true);
        }
      },
      { scope: FB_SCOPE }
    );
  };

  const handleInstagramLogin = () => {
    setLoadingInstagram(true);
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          console.log(
            "Instagram Access Token:",
            response.authResponse.accessToken
          );
          setInstagramAccessToken(response.authResponse.accessToken);
          saveInstagramData(response.authResponse.accessToken);
        } else {
          console.log("User cancelled login or did not fully authorize.");
          setLoadingInstagram(false);
          setModalMessage("Failed to authenticate with Instagram");
          setIsModalOpen(true);
        }
      },
      { scope: SCOPES }
    );
  };

  useEffect(() => {
    // Load the Facebook SDK for JavaScript
    ((d, s, id) => {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    // Initialize the SDK
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FB_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v16.0",
      });
    };
  }, []);

  const triggerGlitterConfetti = () => {
    confetti({
      particleCount: 200,
      spread: 120,
      origin: { x: 0.5, y: 0.5 },
      colors: ["#FFC107", "#FF5722", "#8BC34A", "#00BCD4", "#9C27B0"],
      ticks: 500,
      startVelocity: 50,
      gravity: 0.6,
    });
  };

  const triggerSuccessConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { x: 0.5, y: 0.5 },
      colors: ["#0E398F", "#FFD700", "#28A745"],
      startVelocity: 50,
      gravity: 0.8,
    });
  };

  // Handle next and previous steps with swipe animations
  const handleStepChange = async (direction) => {
    const currentValues = getValues();

    if (direction === "next") {
      // Step 1: Proceed from Welcome Screen
      if (step === 1) {
        setAnimationClass("modal-fade-out");
        setTimeout(() => {
          setStep(step + 1); // Move to Step 2
          setAnimationClass("modal-swipe-right");
        }, 300); // Delay for animation synchronization
        return;
      }

      // Step 2: No validation for step 2, move to Step 3 directly
      if (step === 2) {
        setAnimationClass("modal-fade-out");
        setTimeout(() => {
          setStep(step + 1); // Move to Step 3
          setAnimationClass("modal-swipe-right");
        }, 300); // Delay for animation synchronization
        return;
      }

      // Step 3: Validate Basic Information
      if (step === 3) {
        const isValid = await trigger([
          "name",
          "domainName",
          "email",
          "phone",
          "address",
          "zipcode",
        ]);

        if (!isValid) {
          console.log("Validation failed for Step 3. Not proceeding.");
          return; // Stop proceeding to the next step if validation fails
        }

        // Reset irrelevant fields for Step 4 (social media fields)
        reset({
          linkedin: "",
          facebook: "",
          twitter: "",
          instagram: "",
          thread: "",
        });

        setAnimationClass("modal-fade-out");
        setTimeout(() => {
          setStep(step + 1); // Move to Step 4
          setAnimationClass("modal-swipe-right");
        }, 300); // Delay for animation synchronization
        return;
      }

      // Step 4: Validate Social Media Links
      if (step === 4) {
        const isValid = await trigger([
          "linkedin",
          "facebook",
          "instagram",
          "twitter",
          "thread",
        ]);

        if (!isValid) {
          console.log("Validation failed for Step 4. Not proceeding.");
          return; // Stop proceeding if validation fails
        }

        const values = getValues();
        const hasAtLeastOneLink =
          !!values.linkedin ||
          !!values.facebook ||
          !!values.instagram ||
          !!values.twitter ||
          !!values.thread;

        if (!hasAtLeastOneLink) {
          setError("at-least-one-social-media", {
            type: "manual",
            message: "At least one social media link is required",
          });
          return; // Stop proceeding if no social media link is provided
        } else {
          clearErrors("at-least-one-social-media");
        }

        // Reset irrelevant fields for Step 5
        reset({
          causes: [...causes],
        });

        setAnimationClass("modal-fade-out");
        setTimeout(() => {
          setStep(step + 1); // Move to Step 5
          setAnimationClass("modal-swipe-right");
        }, 300); // Delay for animation synchronization
        return;
      }

      // Step 5: Validate Causes
      if (step === 5) {
        // Manual validation for causes
        const causesValid = causes.every((cause) => cause.length >= 3);
        if (!causesValid) {
          console.log("Validation failed for Step 5. Not proceeding.");
          return;
        }

        setAnimationClass("modal-fade-out");
        setTimeout(() => {
          setStep(step + 1); // Move to Step 6
          setAnimationClass("modal-swipe-right");
        }, 300); // Delay for animation synchronization
        return;
      }

      // Step 6: Proceed to next
      if (step === 6) {
        onSubmitHandler(getValues());
        setAnimationClass("modal-fade-out");
        setTimeout(() => {
          setStep(step + 1); // Move to Step 7
          setAnimationClass("modal-swipe-right");
        }, 300); // Delay for animation synchronization
        return;
      }

      // Step 7: Proceed to next
      if (step === 7) {
        setAnimationClass("modal-fade-out");
        setTimeout(() => {
          setStep(step + 1); // Move to Step 8
          setAnimationClass("modal-swipe-right");
        }, 300); // Delay for animation synchronization
        return;
      }

      // Step 8: Proceed to next
      if (step === 8) {
        setAnimationClass("modal-fade-out");
        setTimeout(() => {
          setStep(step + 1); // Move to Step 9
          setAnimationClass("modal-swipe-right");
        }, 300); // Delay for animation synchronization
        return;
      }

      // Step 9: Proceed to next
      if (step === 9) {
        setAnimationClass("modal-fade-out");
        setTimeout(() => {
          setStep(step + 1); // Move to Step 10
          setAnimationClass("modal-swipe-right");
        }, 300); // Delay for animation synchronization
        return;
      }

      // Step 10: Finish
      if (step === 10) {
        sendAddSubAdminLink();
        setAnimationClass("modal-fade-out");
        setTimeout(() => {
          setStep(step + 1); // Move to Success Step 11
          setAnimationClass("modal-swipe-right");
        }, 300); // Delay for animation synchronization
        return;
      }
    } else if (direction === "prev") {
      // Moving back to the previous step
      reset({ ...currentValues }); // Restore the form values from the previous step
      setAnimationClass("modal-fade-out");
      setTimeout(() => {
        setStep(step - 1); // Decrement step to move backward
        setAnimationClass("modal-swipe-left");
      }, 300); // Delay for animation synchronization
    }
  };

  // Custom Skip Logic based on the flow
  const handleSkip = () => {
    setAnimationClass("modal-fade-out");
    setTimeout(() => {
      setAnimationClass("modal-swipe-right"); // Always right swipe for skip
      if (step >= 2 && step <= 6) {
        setStep(7); // Go to step 7
      } else if (step >= 7 && step <= 8) {
        setStep(9); // Go to step 9
      } else if (step >= 9 && step <= 10) {
        setStep(11); // Go to step 11
      }
    }, 300);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile({
        file,
        preview: URL.createObjectURL(file),
      });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile({
        file,
        preview: URL.createObjectURL(file),
      });
    }
    setIsDragOver(false);
  };

  const removeFile = () => {
    setSelectedFile(null); // Remove the uploaded file
  };

  const addCauseInput = () => {
    if (causes.length < 5) {
      setCauses([...causes, ""]); // Add a new empty cause input field
    } else {
      toast.error(
        "You can only add up to 5 causes now. You can add more later.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const checkDomainAvailability = async (domain) => {
    try {
      const response = await fetch(`${TQ_URL}/checkDomain`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ domainName: domain }),
      });
      const data = await response.json();
      if (data.available) {
        setDomainAvailability(true);
      } else {
        setDomainAvailability(false);
      }
    } catch (error) {
      console.error("Error checking domain availability:", error);
    }
  };
  const zipCodeDetails = async (event) => {
    const zipcode = event.target.value;

    if (!zipcode) {
      setNotFoundMessage("Zip Code is required");
      setStateName("");
      setCityName("");
      setValue("state", "");
      setValue("country", "");
      setCountryFlag(false);
      return;
    }

    if (zipcode.length === 5) {
      try {
        const response = await fetch(`${TQ_URL}/getAddressDetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            zipcode: zipcode,
          }),
        });

        if (response.status === 200) {
          const res = await response.json();

          // Handle both "Success" and "Succsess"
          if (res.Status === "Success" || res.Status === "Succsess") {
            setStateName(res.State.state);
            setCityName(res.State.city);
            setZipcode(zipcode);
            setCountryFlag(true);
            setNotFoundMessage("");
            setValue("state", res.State.state);
            setValue("city", res.State.city);
            setValue("country", "USA");
          } else {
            setNotFoundMessage("Incorrect Zip Code");
            setStateName("");
            setCityName("");
            setCountryFlag(false);
          }
        }
      } catch (error) {
        console.error("Error fetching zip code details:", error);
      }
    } else {
      setStateName("");
      setCityName("");
      setValue("state", "");
      setValue("city", "");
      setValue("country", "");
      setCountryFlag(false);
      setNotFoundMessage("Incorrect Zip Code");
    }
  };

  const handleDomainNameChange = (event) => {
    const value = event.target.value;
    setDomainName(value);

    if (/[^a-z0-9]/.test(value)) {
      setDomainNameWarning(
        "Domain name must be lowercase and contain no special characters"
      );
    } else {
      setDomainNameWarning("");
    }

    if (value.length >= 5 && !domainNameWarning) {
      checkDomainAvailability(value); // Call domain check when length is sufficient
    }
  };

  const onSubmitHandler = async (data) => {
    if (step === 3) {
      setIsForm1Complete(true);
      setStep(4);
      return;
    }

    if (step === 4) {
      setIsForm2Complete(true);
      setStep(5);
      return;
    }

    if (step === 5) {
      setIsForm3Complete(true);
      setStep(6);
      return;
    }

    if (step === 6) {
      setIsLoading(true); // Show loading modal when API call is initiated

      try {
        const token = localStorage.getItem("accessToken");
        const formData = new FormData();
        formData.append("organizationName", organizationName);
        formData.append("domainName", domainName);

        if (selectedFile) {
          formData.append("logo", selectedFile.file);
        } else {
          const response = await fetch(defaultLogoPath);
          const blob = await response.blob();
          formData.append("logo", blob, "default-logo.svg");
        }

        formData.append("donorEmail", email);
        formData.append("contactNo", phone);
        formData.append("address", address);
        formData.append("city", cityName);
        formData.append("state", stateName);
        formData.append("country", "USA");
        formData.append("postalCode", zipcode);
        formData.append("partnerId", partnerData.partnerId);
        formData.append("linkedinLink", data.linkedin);
        formData.append("facebookLink", data.facebook);
        formData.append("instagramLink", data.instagram);
        formData.append("twitterLink", data.twitter);
        formData.append("threadLink", data.thread);
        formData.append("primaryColor", primaryColor);
        formData.append("secondaryColor", secondaryColor);
        formData.append("causes", JSON.stringify(causes));

        const url = `${TQ_URL}/create`; // Create call only
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: token,
          },
          body: formData,
        });

        if (response.status === 200) {
          toast.success("Domain created successfully", {
            position: "top-right",
          });
          // Reset the form or proceed to the next step
          setIsLoading(false); // Hide loading modal
        } else {
          const errorText = await response.text();
          console.error("Error response:", errorText);
          throw new Error(
            `HTTP error! status: ${response.status}, message: ${errorText}`
          );
        }
      } catch (error) {
        console.error("Failed to submit form:", error);
        toast.error("Failed to save the details", { position: "top-right" });
      } finally {
        setIsLoading(false); // Hide loading modal after response is received
      }
    }
  };

  const addEmailInput = () => {
    console.log(emails);

    if (emails.length < 5) {
      setEmails([...emails, ""]); // Add a new empty email input field
    } else {
      toast.error("You can only add up to 5 emails for sub-admins.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const renderProgressBar = () => {
    const progressPercentage = step * 10; // Simplified percentage calculation
    return (
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
    );
  };

  const renderContent = () => {
    if (step === 1) {
      return (
        <div className="onboarding-modal-content">
          {renderProgressBar()}
          <div className="onboarding-image-container">
            <img
              src="/assets/images/Onboarding-cuate.png"
              alt="Onboarding"
              className="onboarding-image"
            />
          </div>
          <h2 className="onboarding-welcome-message mt-4 mb-2 text-2xl font-bold">
            Welcome to Prabalta!
          </h2>
          <p className="onboarding-paragraph mt-2">
            Together, we empower communities by supporting NGOs with the tools
            they need to make a difference.
          </p>
          <div className="get-started-button-container">
            <button
              onClick={() => handleStepChange("next")}
              className="centered-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Let’s Get Started
            </button>
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <div className="onboarding-modal-content">
          {renderProgressBar()}
          <div className="onboarding-image-container">
            <img
              src="/assets/images/Website designer-bro.png"
              alt="Create Subdomain"
              className="onboarding-image-small"
            />
          </div>
          <div id="scrollable-content" className="scrollable-content">
            <div className="onboarding-text-content">
              <h2 className="onboarding-welcome-message mt-4 mb-2 text-2xl font-bold">
                Create Your Subdomain
              </h2>
              <ul className="onboarding-paragraph mt-2 text-left list-disc list-inside">
                <li>
                  Create a unique subdomain for your NGO's fundraising platform.
                </li>
                <li>
                  Build fundraisers to receive donations directly from
                  supporters.
                </li>
                <li>
                  Manage your NGO's website with custom branding, logo, and
                  colors.
                </li>
                <li>
                  Add contact information such as your support email, address,
                  and phone number.
                </li>
                <li>
                  Track and manage donations, campaigns, and communications all
                  in one place.
                </li>
              </ul>
            </div>
          </div>

          <div className="button-container">
            <button
              onClick={handleSkip}
              className="onboarding-button skip-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Skip
            </button>
            <button
              onClick={() => handleStepChange("next")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Next
            </button>
          </div>
        </div>
      );
    } else if (step === 3) {
      return (
        <div className="onboarding-modal-content bg-white">
          {" "}
          {/* Root container */}
          {renderProgressBar()} {/* Display progress bar */}
          <h2 className="onboarding-welcome-message mt-4 mb-2 text-2xl font-bold">
            Basic Information
          </h2>
          <form className="w-full flex flex-col items-center space-y-4">
            {" "}
            {/* Form Container */}
            {/* Organization Name Field */}
            <div className="w-full flex flex-col items-center">
              <input
                {...register("name")}
                value={organizationName} // use state variable
                onChange={(e) => setOrganizationName(e.target.value)}
                placeholder="Enter Your Organization Name"
                type="text"
                className={`w-full max-w-lg p-2.5 border border-gray-300 rounded-lg text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 ${
                  errors.name && "border-red-500"
                }`}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.name?.message}
              </p>{" "}
              {/* Validation Message */}
            </div>
            {/* Domain Name Field with .prabalta.org */}
            <div className="w-full relative flex flex-col items-center">
              <div className="relative w-full max-w-lg flex items-center">
                <input
                  {...register("domainName")}
                  placeholder="Enter Domain Name"
                  type="text"
                  readOnly={domainId !== null}
                  className={`w-full p-2.5 border border-gray-300 rounded-lg text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 ${
                    errors.domainName && "border-red-500"
                  }`}
                  onChange={handleDomainNameChange}
                  autoComplete="new-password"
                />
                <span className="ml-2 mr-6 text-gray-500 whitespace-nowrap">
                  .prabalta.org
                </span>{" "}
                {/* Domain suffix */}
                {/* Validation Icon for Domain Name */}
                <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                  {domainName && (
                    <>
                      {domainAvailability ===
                      null ? null : domainAvailability && !domainNameWarning ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-green-500"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-red-500"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
              <p className="text-red-500 text-sm mt-1">
                {domainNameWarning || errors.domainName?.message}
              </p>
            </div>
            {/* Support Email Field */}
            <div className="w-full flex flex-col items-center">
              <input
                {...register("email")}
                value={email} // use state variable
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Add Support Email"
                type="email"
                className={`w-full max-w-lg p-2.5 border border-gray-300 rounded-lg text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 ${
                  errors.email && "border-red-500"
                }`}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.email?.message}
              </p>
            </div>
            {/* Phone Number Field */}
            <div className="w-full flex flex-col items-center">
              <input
                {...register("phone")}
                value={phone} // use state variable
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Add Your Phone Number"
                type="text"
                maxLength={12}
                className={`w-full max-w-lg p-2.5 border border-gray-300 rounded-lg text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 ${
                  errors.phone && "border-red-500"
                }`}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.phone?.message}
              </p>
            </div>
            {/* Address Field */}
            <div className="w-full flex flex-col items-center">
              <input
                {...register("address")}
                value={address} // use state variable
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter Address"
                type="text"
                className={`w-full max-w-lg p-2.5 border border-gray-300 rounded-lg text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 ${
                  errors.address && "border-red-500"
                }`}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.address?.message}
              </p>
            </div>
            {/* Zip Code Field */}
            <div className="w-full flex flex-col items-center">
              <input
                {...register("zipcode")}
                placeholder="Zipcode"
                onBlur={zipCodeDetails}
                maxLength={6}
                type="number"
                className={`w-full max-w-lg p-2.5 border border-gray-300 rounded-lg text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 ${
                  errors.zipcode && "border-red-500"
                }`}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.zipcode?.message}
              </p>
              {!errors.zipcode?.message && (
                <p className="text-red-500 text-sm mt-1">{notFoundMessage}</p>
              )}

              {/* Display City and State if available */}
              {cityName && stateName && countryFlag && (
                <div className="text-bold mt-1">
                  <em>
                    <b>{`${cityName}, ${stateName}, USA`}</b>
                  </em>
                </div>
              )}
            </div>
          </form>
          {/* Navigation Buttons */}
          <div className="button-container">
            <button
              onClick={() => handleStepChange("prev")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Prev
            </button>
            <button
              onClick={() => handleStepChange("next")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Next
            </button>
          </div>
        </div>
      );
    } else if (step === 4) {
      return (
        <div className="onboarding-modal-content bg-white">
          {" "}
          {/* Root container */}
          {renderProgressBar()} {/* Display progress bar */}
          <h2 className="onboarding-welcome-message mt-4 mb-2 text-2xl font-bold">
            Add Social Media Links
          </h2>
          <form className="w-full flex flex-col items-center space-y-4">
            {/* LinkedIn Field */}
            <div className="w-full flex flex-col items-center">
              <input
                {...register("linkedin")}
                placeholder="LinkedIn Link"
                type="text"
                className={`w-full max-w-lg p-2.5 border border-gray-300 rounded-lg text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 ${
                  errors.linkedin && "border-red-500"
                }`}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.linkedin?.message}
              </p>{" "}
              {/* Display LinkedIn error */}
            </div>

            {/* Facebook Field */}
            <div className="w-full flex flex-col items-center">
              <input
                {...register("facebook")}
                placeholder="Facebook Link"
                type="text"
                className={`w-full max-w-lg p-2.5 border border-gray-300 rounded-lg text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 ${
                  errors.facebook && "border-red-500"
                }`}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.facebook?.message}
              </p>{" "}
              {/* Display Facebook error */}
            </div>

            {/* Instagram Field */}
            <div className="w-full flex flex-col items-center">
              <input
                {...register("instagram")}
                placeholder="Instagram Link"
                type="text"
                className={`w-full max-w-lg p-2.5 border border-gray-300 rounded-lg text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 ${
                  errors.instagram && "border-red-500"
                }`}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.instagram?.message}
              </p>{" "}
              {/* Display Instagram error */}
            </div>

            {/* Twitter Field */}
            <div className="w-full flex flex-col items-center">
              <input
                {...register("twitter")}
                placeholder="Twitter Link"
                type="text"
                className={`w-full max-w-lg p-2.5 border border-gray-300 rounded-lg text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 ${
                  errors.twitter && "border-red-500"
                }`}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.twitter?.message}
              </p>{" "}
              {/* Display Twitter error */}
            </div>

            {/* Thread Field */}
            <div className="w-full flex flex-col items-center">
              <input
                {...register("thread")}
                placeholder="Thread Link"
                type="text"
                className={`w-full max-w-lg p-2.5 border border-gray-300 rounded-lg text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 ${
                  errors.thread && "border-red-500"
                }`}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.thread?.message}
              </p>{" "}
              {/* Display Thread error */}
            </div>

            {/* Error message for at least one social media link */}
            <p className="text-red-500 text-sm mt-1">
              {errors?.linkedin?.message ||
                errors?.facebook?.message ||
                errors?.instagram?.message ||
                errors?.twitter?.message ||
                errors?.thread?.message ||
                errors["at-least-one-social-media"]?.message}
            </p>
          </form>
          {/* Navigation Buttons */}
          <div className="button-container">
            <button
              onClick={() => handleStepChange("prev")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Prev
            </button>
            <button
              onClick={() => handleStepChange("next")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Next
            </button>
          </div>
        </div>
      );
    } else if (step === 5) {
      return (
        <div className="onboarding-modal-content bg-white">
          {renderProgressBar()} {/* Display progress bar */}
          <h2 className="onboarding-welcome-message mt-4 mb-2 text-2xl font-bold">
            Add Causes
          </h2>
          <form className="w-full flex flex-col items-center space-y-4">
            {" "}
            {/* Form Container */}
            {causes.map((cause, index) => (
              <div key={index} className="w-full flex flex-col items-center">
                {" "}
                {/* Wrap input inside container for uniform layout */}
                <input
                  type="text"
                  placeholder={`Cause ${index + 1}`}
                  value={cause}
                  onChange={(e) => {
                    const newCauses = [...causes];
                    newCauses[index] = e.target.value;
                    setCauses(newCauses);
                  }}
                  className={`w-full max-w-lg p-2.5 border border-gray-300 rounded-lg text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 ${
                    cause.length < 3 ? "border-red-500" : "border-gray-300"
                  }`}
                  required
                />
                {/* Display error if cause is too short */}
                {cause.length < 3 && (
                  <p className="text-red-500 text-sm mt-1">
                    Cause must be at least 3 characters long.
                  </p>
                )}
              </div>
            ))}
            {/* Add Cause Button */}
            <button
              type="button"
              className="onboarding-button mt-4 px-4 py-2 rounded bg-blue-500 text-white hover:bg-green-600 transition duration-300"
              onClick={addCauseInput}
              disabled={
                causes.length >= 5 || causes.some((cause) => cause.length < 3)
              }
            >
              Add Cause
            </button>
          </form>
          <div className="button-container mt-4">
            <button
              onClick={() => handleStepChange("prev")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded bg-gray-300 text-gray-700 hover:bg-gray-400 transition duration-300"
            >
              Prev
            </button>
            <button
              onClick={() => handleStepChange("next")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded bg-blue-500 text-white hover:bg-green-600 transition duration-300"
              disabled={causes.some((cause) => cause.length < 3)}
            >
              Next
            </button>
          </div>
        </div>
      );
    } else if (step === 6) {
      const defaultLogoPath = "/assets/images/main-logo.svg"; // Path for the default logo

      return (
        <div className="onboarding-modal-content">
          {renderProgressBar()}
          <h3 className="text-xl font-bold mb-4">Add Logo</h3>

          <div className="md:flex justify-center">
            <div
              className="upload-box"
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              style={{
                borderColor: isDragOver ? "blue" : "gray",
                boxShadow: isDragOver
                  ? "0 0 10px rgba(0, 0, 255, 0.5)"
                  : "none",
                borderRadius: "8px",
                borderStyle: "dashed",
                borderWidth: "2px",
                paddingInline: "10px",
                paddingBlock: "10px",
                cursor: "pointer",
                width: "400px", // Decreased width
                height: "200px", // Decreased height
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {selectedFile ? (
                <div className="flex flex-col items-center">
                  <p className="mb-2">{selectedFile.file.name}</p>
                  <button
                    type="button"
                    className="text-red-500 hover:text-red-700 cursor-pointer"
                    onClick={removeFile}
                  >
                    &#x2715;
                  </button>
                </div>
              ) : (
                <label
                  htmlFor="documentInput"
                  className="cursor-pointer flex flex-col items-center"
                >
                  <img
                    src={defaultLogoPath}
                    className="w-16 h-16 object-contain mb-2"
                    alt="Default Logo"
                  />
                  <p className="text-xs">
                    <span className="font-bold underline">
                      Click to upload Logo
                    </span>
                  </p>
                </label>
              )}

              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={handleFileChange}
                className="hidden"
                id="documentInput"
              />
            </div>
          </div>

          {selectedFile ? (
            <div className="uploaded-image-preview mt-4">
              <h4 className="text-lg font-bold mb-2 text-center">
                Uploaded Logo Preview
              </h4>
              <img
                src={selectedFile.preview}
                alt="Logo Preview"
                className="mx-auto w-32 h-32 object-contain"
              />
            </div>
          ) : (
            <div className="uploaded-image-preview mt-4">
              <h4 className="text-lg font-bold mb-2 text-center">
                Default Logo Preview
              </h4>
              <img
                src={defaultLogoPath}
                alt="Default Logo Preview"
                className="mx-auto w-32 h-32 object-contain"
              />
            </div>
          )}

          <div className="button-container">
            <button
              onClick={() => handleStepChange("prev")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Prev
            </button>
            <button
              onClick={() => handleStepChange("next")}
              className={`onboarding-button next-button mt-4 px-4 py-2 rounded ${
                isLoading
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-green-600"
              }`}
              disabled={isLoading} // Disable button when loading
            >
              Next
            </button>
          </div>
        </div>
      );
    } else if (step === 7) {
      return (
        <div className="onboarding-modal-content">
          {renderProgressBar()}
          <div className="social-media-management-image">
            <img
              src="/assets/images/Social Dashboard-pana.png"
              alt="Social Media Management"
              className="onboarding-image-small"
            />
          </div>
          <div id="scrollable-content" className="scrollable-content">
            <div className="onboarding-text-content">
              <h2 className="onboarding-welcome-message mt-4 mb-2 text-2xl font-bold">
                Social Media Management
              </h2>
              <ul className="onboarding-paragraph mt-2 text-left list-disc list-inside">
                <li>
                  Connect your NGO's Facebook and Instagram accounts to
                  centralize your social media activities.
                </li>
                <li>
                  Schedule future posts for both Facebook and Instagram,
                  ensuring your message reaches your audience at the right time.
                </li>
                <li>
                  View detailed analytics for each post, including engagement,
                  reach, and impressions.
                </li>
                <li>
                  Manage multiple social media campaigns and track their
                  performance over time.
                </li>
                <li>
                  Get insights on which type of content performs best, helping
                  you optimize your social media strategy.
                </li>
                <li>
                  Collaborate with your team to assign roles and
                  responsibilities for managing social media tasks.
                </li>
                <li>
                  Automate the posting of upcoming events, fundraisers, and
                  community updates, saving valuable time.
                </li>
              </ul>
            </div>
          </div>
          <div className="button-container">
            <button
              onClick={handleSkip}
              className="onboarding-button skip-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Skip
            </button>
            <button
              onClick={() => handleStepChange("next")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Next
            </button>
          </div>
        </div>
      );
    }else if (step === 8) {
      return (
        <div className="onboarding-modal-content">
          {renderProgressBar()}
          <h2 className="onboarding-welcome-message mt-4 mb-2 text-2xl font-bold">
            Connect Social Media Accounts
          </h2>
          <div className="social-connect-wrapper">
            <div className="social-item">
              <FaFacebook size={50} color="#4267B2" />
              <div className="social-details">
                <h3>Facebook</h3>
                <p>Page</p>
              </div>
              <button
                id="connect_button"
                className="connect-button"
                onClick={handleFBLogin}
                disabled={loadingFB}
              >
                {loadingFB ? "Connecting..." : "Connect"}
              </button>
            </div>
            <div className="social-item">
              <FaInstagramSquare size={50} color="#E4405F" />
              <div id="connect_button" className="social-details">
                <h3>Instagram</h3>
                <p>Business accounts</p>
              </div>
              <button
                className="connect-button"
                onClick={handleInstagramLogin}
                disabled={loadingInstagram}
              >
                {loadingInstagram ? "Connecting..." : "Connect"}
              </button>
            </div>
          </div>
    
          <div className="button-container">
            <button
              onClick={() => handleStepChange("prev")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Prev
            </button>
            <button
              onClick={() => handleStepChange("next")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Next
            </button>
          </div>
        </div>
      );
    } else if (step === 9) {
    
      return (
        <div className="onboarding-modal-content">
          {renderProgressBar()}
          <div className="onboarding-image-container">
            <img
              src="/assets/images/Pair programming-bro.png"
              alt="Add Sub-Admin"
              className="onboarding-image-small"
            />
          </div>
          <div id="scrollable-content" className="scrollable-content">
            <div className="onboarding-text-content">
              <h2 className="onboarding-welcome-message mt-4 mb-2 text-2xl font-bold">
                Add Sub-Admin
              </h2>
              <ul className="onboarding-paragraph mt-2 text-left list-disc list-inside">
                <li>Do you want to add a sub-admin?</li>
                <li>
                  Sub-admins will have access to all functionalities except for
                  money withdrawal and transfer-related features.
                </li>
                <li>
                  Sub-admins can manage fundraisers, track donations, and modify
                  campaigns.
                </li>
                <li>
                  Sub-admins will not have permissions to modify payment
                  settings or handle financial accounts.
                </li>
                <li>
                  Sub-admins can view donation statistics and generate reports.
                </li>
                <li>
                  You can add or remove sub-admins anytime from your settings.
                </li>
              </ul>
            </div>
          </div>

          <div className="button-container">
            <button
              onClick={handleSkip}
              className="onboarding-button skip-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Skip
            </button>
            <button
              onClick={() => handleStepChange("next")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Next
            </button>
          </div>
        </div>
      );
    } else if (step === 10) {
      return (
        <div className="onboarding-modal-content">
          {renderProgressBar()}
          <div className="onboarding-text-content">
            <h2 className="onboarding-welcome-message mt-4 mb-2 text-2xl font-bold">
              Add Sub-Admin Emails
            </h2>
            <form className="basic-info-form mt-4">
              {emails.map((email, index) => (
                <input
                  key={index}
                  type="email"
                  placeholder={`Sub-Admin Email ${index + 1}`}
                  value={email}
                  onChange={(e) => {
                    const newEmails = [...emails];
                    newEmails[index] = e.target.value;
                    setEmails(newEmails);
                  }}
                  required
                />
              ))}
              <button
                type="button"
                className="onboarding-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
                onClick={addEmailInput}
              >
                Add Email
              </button>
            </form>
          </div>

          <div className="button-container">
            <button
              onClick={() => handleStepChange("prev")}
              className="onboarding-button next-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Prev
            </button>
            <button
              onClick={() => handleStepChange("next")}
              className="onboarding-button skip-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Finish
            </button>
          </div>
        </div>
      );
    } else if (step === 11) {
      return (
        <div className="onboarding-modal-content">
          <div className="success-animation-container">
            <img
              src="/assets/images/Confirmed-bro.png"
              alt="Success"
              className="success-icon"
            />
          </div>
          <h2 className="onboarding-welcome-message mt-4 mb-2 text-3xl font-bold">
            Success!
          </h2>
          <p className="onboarding-paragraph mt-2 text-lg">
            Congratulations, you’ve successfully completed the onboarding
            process. You’re all set to start using Prabalta!
          </p>

          <div className="close-button-container">
            <button
              onClick={closeModal}
              className="centered-button next-button mt-4 px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    modalVisible && (
      <>
        <ToastContainer />
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Onboarding Process Modal"
          shouldCloseOnOverlayClick={false}
          className={`onboarding-modal bg-white p-6 rounded-lg transition-all duration-700 ease-in-out ${animationClass}`}
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-700 ease-in-out"
        >
          {renderContent()}

          {/* Add the loading modal here */}
          {isLoading && (
            <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
              <Triangle
                height="120"
                width="120"
                color="blue"
                ariaLabel="loading"
              />
              <p className="text-gray-300 mt-4">
                Please wait, your data is being saved...
              </p>
            </div>
          )}
        </Modal>
      </>
    )
  );
};

export default OnboardingProcess;
