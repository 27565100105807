import React, { useEffect, useState } from "react";
import Pagination from "../Pagination";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { getRequestWithTokenWithoutDataPartner } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import MailCampaignSideBar from "./MailCampaignSideBar";
import CreateMailingList from "./CreateMailingList";
import AddEmailsMailList from "./AddEmailsMailList";
import { useNavigate, Link } from "react-router-dom";
import EmailCampaignTour from "./EmailCampaignTour";

function MyCampaignList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState([]);
  const [modalopen, setModalOpen] = useState(false);
  const [modalEmailOpen, setModalEmailOpen] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignListId, setCampaignListId] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [counter, setCounter] = useState(1);
  const navigate = useNavigate();
  const tourCompletedFlag = localStorage.getItem("emailTourCompletedFlag");
  console.log("emailTourCompletedFlag mycamplist", tourCompletedFlag);

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let PageSize = 3;

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId;
  useEffect(() => {
    getCampaignList(currentPage);
  }, []);
  useEffect(() => {
    if (searchText) {
      search(searchText, currentPage);
    } else {
      getCampaignList(currentPage);
    }
  }, [currentPage, searchText]); // Ensure searchText is included in dependencies

  useEffect(() => {
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter < 10) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [counter]);

  const getCampaignList = async (page) => {
    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        `${TQ_URL}/getCampaignList?partnerId=${partnerId}&page=${page}`
      );
      console.log(response);
      setCampaignList(response.campaignList);
      setTotalCount(response.totalCount);
    } catch (error) {
      console.error("Error in fetching the mailing List:", error);
    }
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const search = async (query, page) => {
    console.log("searchText:", query);

    const url = new URL(`${TQ_URL}/searchCampaignList`);
    url.search = new URLSearchParams();
    url.searchParams.set("page", `${page}`);
    url.searchParams.set("keyword", `${query}`);
    url.searchParams.set("partnerId", `${partnerId}`);

    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );

      console.log(response);

      setCampaignList(response.campaignList);
      setTotalCount(response.totalCount);
    } catch (error) {
      console.error("Error in fetching the searched Mailing List:", error);
      return;
    }
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      await search(searchText, currentPage);
    }
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed h-full md:overflow-y-auto">
          <MailCampaignSideBar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px] overflow-x-hidden">
          {/* <div className="mx-auto shadow-md">
            <div className="md:flex justify-between items-center px-10 py-6">
              <div className="text-center md:text-left">
                <h2 className="text-2xl font-bold">Campaign List</h2>
              </div>
              <div className="flex items-center md:ml-8 w-full md:w-auto">
                <input
                  type="text"
                  placeholder="Search..."
                  className="py-2 pr-8 pl-4 block w-full md:w-96 rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300 md:mr-4"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
                <Link id="createCampaignButton" to="/myCampaign">
                  <button className="btn-style-1 text-white py-2 px-4 rounded mx-2 max-sm:text-xs">
                    Create New Campaign
                  </button>
                </Link>
              </div>
            </div>
          </div> */}

          <div className="mx-auto shadow-md">
            <div className="flex flex-col md:flex-row justify-between items-center container px-10 py-6">
              <div className="text-center md:text-left">
                <h2 className="text-2xl font-bold">Campaign List</h2>
              </div>

              <div className="flex flex-row items-center md:ml-4 mt-4 md:mt-0 w-full md:w-auto">
                <div className="md:mx-2 mx-2 py-2 md:py-0">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="py-2 pr-8 pl-4 block w-full rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </div>

                <div
                  id="createCampaignButton"
                  className="md:mx-2 mx-2 py-2 md:py-0"
                >
                  <Link to="/myCampaign">
                    <button className="btn-style-1 text-white py-2 px-4 rounded">
                      Create New Campaign
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="channel-list campaign-blocks w-full mx-auto  mt-8">
            {campaignList.length === 0 ? (
              <div className="text-center text-gray-500 font-bold text-xl italic">
                No Campaign lists found.
              </div>
            ) : (
              campaignList.map((campList, index) => (
                <Link
                  key={index}
                  to={`/editCampaign/${campList.campaignId}`}
                  className="channel-item mb-4 no-underline"
                >
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="flex flex-col md:mt-4">
                      <div className="ml-[50px] md:mb-4">
                        <span className="font-semibold text-gray-800">
                          {campList.campaignName}
                        </span>
                        <span className="text-gray-500 text-sm block">
                          {campList.formattedScheduleDate}
                        </span>
                        <Link
                          to={`/emailList/${campList.mailingListId}`}
                          className="text-gray-500 text-sm block hover:underline"
                        >
                          <span className="text-blue-500 text-sm block">
                            {campList.mailingListName} ({campList.emailCounts})
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div
                      className={`md:mr-[50px] ml-[50px] ${
                        campList.statusName === "Sent"
                          ? "text-green-500"
                          : campList.statusName === "Draft"
                          ? "text-yellow-500"
                          : campList.statusName === "Schedule"
                          ? "text-blue-500"
                          : campList.statusName === "Failed"
                          ? "text-red-500"
                          : "text-gray-500"
                      }`}
                    >
                      <span className="font-semibold text-sm block">
                        {campList.statusName}
                      </span>
                    </div>
                  </div>
                </Link>
              ))
            )}
          </div>

          <Pagination
            className="pagination-bar md:mt-12"
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </main>
      </div>
      {tourCompletedFlag === "false" && isLargeScreen && <EmailCampaignTour />}
    </>
  );
}

export default MyCampaignList;
