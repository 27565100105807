import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import Modal from "react-modal";
import "slick-carousel/slick/slick.css";
import { Spinner } from "@material-tailwind/react";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios"; // Import axios
import { Triangle } from "react-loader-spinner";

import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithDataWithAuth,
  getTypeRequestWithTokenWithDataWithAuth,
  getRequestWithTokenWithoutData,
} from "../../utility/apiRequest";
import CircleComponent from "./CircleComponent";
import ImagePreview from "./ImagePreview";
import { CSSTransition } from "react-transition-group";
import { format, parse } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./OpenModal.css"; // Import the CSS file
import { TQ_URL } from "../../utility/baseUrl";

const schema = yup.object().shape({
  fundName: yup.string().required("Fund Name is Required"),
});

const OpenModal = ({ onClose, selectedDate, setSelectedDate }) => {
  console.log(selectedDate);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId;
  const [connectedChannels, setConnectedChannels] = useState([]);
  const [error, setError] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [postText, setPostText] = useState("");
  const [view, setView] = useState("post");
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [tempDate, setTempDate] = useState(new Date());
  const [likes, setLikes] = useState(Math.floor(Math.random() * 1000));

  const [date, setDate] = useState(
    selectedDate ? format(selectedDate, "MMMM d, h:mm a") : ""
  ); // State to manage the date
  const [showDatePicker, setShowDatePicker] = useState(false); // State to toggle date picker

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClick = (channel) => {
    const webpImagesSelected = uploadedFiles.some(
      (file) => file.type === "image/webp"
    );

    if (
      webpImagesSelected &&
      channel.socialMediaConnector.channelName === "instagram"
    ) {
      toast.error("WebP images are not supported for Instagram posts.");
      return;
    }

    setSelectedChannels((prevSelected) => {
      if (prevSelected.includes(channel)) {
        return prevSelected.filter((selected) => selected !== channel);
      } else {
        return [...prevSelected, channel];
      }
    });
  };

  const handleOpenModal = () => {
    setTempDate(selectedDate); // Initialize tempDate with the current selectedDate

    setShowDatePicker(true);
  };

  const handleCloseModal = () => {
    setShowDatePicker(false);
  };

  const validateVideo = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        const duration = video.duration;
        const width = video.videoWidth;
        const height = video.videoHeight;
        const aspectRatio = width / height;
        const isInstagramSelected = selectedChannels.some(
          (channel) => channel.socialMediaConnector.channelName === "instagram"
        );
        const isFacebookSelected = selectedChannels.some(
          (channel) => channel.socialMediaConnector.channelName === "facebook"
        );

        const errors = [];

        if (file.size > 4 * 1024 * 1024 * 1024) {
          errors.push("File size must be up to 4GB.");
        }
        if (aspectRatio !== 9 / 16) {
          errors.push("Aspect ratio must be 9:16.");
        }
        if (width < 500 || height < 888) {
          errors.push("Resolution must be at least 500x888 pixels.");
        }
        if (duration < 3 || duration > 60) {
          errors.push("Duration must be between 3 and 60 seconds.");
        }
        if (video.frameRate < 30) {
          errors.push("Frame rate must be at least 30 FPS.");
        }
        if (!["video/mp4", "video/quicktime"].includes(file.type)) {
          errors.push("Format must be MP4 or MOV.");
        }

        if (errors.length > 0) {
          toast.error(errors.join(" "));
          reject(errors.join(" "));
        } else {
          resolve(file);
        }
      };

      video.onerror = () => {
        toast.error("Invalid video file.");
        reject("Invalid video file.");
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const isInstagramSelected = selectedChannels.some(
        (channel) => channel.socialMediaConnector.channelName === "instagram"
      );

      const images = acceptedFiles.filter((file) =>
        file.type.startsWith("image/")
      );
      const videos = acceptedFiles.filter((file) =>
        file.type.startsWith("video/")
      );

      if (images.length > 0 && videos.length > 0) {
        toast.error(
          "Only images can be uploaded with images. Adding only the video."
        );
        acceptedFiles = videos;
      }

      const currentImages = uploadedFiles.filter((file) =>
        file.type.startsWith("image/")
      ).length;
      const currentVideos = uploadedFiles.filter((file) =>
        file.type.startsWith("video/")
      ).length;
      let newImages = 0;
      let newVideos = 0;

      const validFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          if (file.type.startsWith("image/")) {
            if (view === "reel") {
              toast.error("Images cannot be uploaded when Reel is selected.");
              return null;
            }
            if (isInstagramSelected && file.type === "image/webp") {
              toast.error("WebP images are not supported for Instagram posts.");
              return null;
            }
            if (currentVideos > 0) {
              toast.error("Adding an image will remove the existing video.");
              setUploadedFiles([]);
            }
            if (currentImages + newImages < 10) {
              newImages += 1;
              return file;
            } else {
              toast.error("You can only upload up to 10 images.");
              return null;
            }
          }
          if (file.type.startsWith("video/")) {
            if (view === "reel") {
              if (isInstagramSelected) {
                try {
                  await validateVideo(file);
                  if (currentVideos > 0 || newVideos > 0) {
                    toast.error(
                      "Adding a second video will remove the first one."
                    );
                    setUploadedFiles([]);
                    newVideos = 1;
                  } else {
                    newVideos += 1;
                  }
                  return file;
                } catch (error) {
                  toast.error(error);
                  return null;
                }
              } else {
                try {
                  await validateVideo(file);
                  if (currentVideos > 0 || newVideos > 0) {
                    toast.error(
                      "Adding a second video will remove the first one."
                    );
                    setUploadedFiles([]);
                    newVideos = 1;
                  } else {
                    newVideos += 1;
                  }
                  return file;
                } catch (error) {
                  toast.error(error);
                  return null;
                }
              }
            }
            if (view === "post") {
              if (currentVideos > 0 || newVideos > 0) {
                toast.error("Adding a second video will remove the first one.");
                setUploadedFiles([]);
                newVideos = 1;
                return file;
              } else {
                newVideos += 1;
                return file;
              }
            }
            return null;
          }
          return null;
        })
      ).then((files) => files.filter(Boolean));

      setUploadedFiles((prevFiles) => [...validFiles]);
      console.log(validFiles);
    },
    [uploadedFiles, view, selectedChannels]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
      "video/*": [],
    },
  });

  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    fetchConnectedConnectors(partnerId);
  }, [partnerId]);

  const fetchConnectedConnectors = async (partnerId) => {
    try {
      const response = await getRequestWithTokenWithData(
        `${TQ_URL}/connected-channels/${partnerId}`
      );
      console.log(response);
      setConnectedChannels(response);
    } catch (error) {
      console.error("Error fetching connected channels:", error);
      setError(error);
    }
  };

  const truncateText = (text) => {
    return text.length > 13 ? text.substring(0, 13) + "...more" : text;
  };

  const isFacebookSelected = selectedChannels.some(
    (channel) => channel.socialMediaConnector.channelName === "facebook"
  );

  const isInstagramSelected = selectedChannels.some(
    (channel) => channel.socialMediaConnector.channelName === "instagram"
  );

  const isSavePostDisabled =
    selectedChannels.length === 0 ||
    (view === "reel" &&
      uploadedFiles.filter((file) => file.type.startsWith("video/")).length ===
        0) ||
    (view === "post" &&
      isInstagramSelected &&
      uploadedFiles.filter((file) => file.type.startsWith("image/")).length ===
        0) ||
    (selectedDate && selectedDate < new Date());

  const handleViewChange = async (newView) => {
    if (newView === "reel") {
      const currentVideos = uploadedFiles.filter((file) =>
        file.type.startsWith("video/")
      );

      if (currentVideos.length > 0) {
        try {
          await validateVideo(currentVideos[0]);
        } catch (error) {
          toast.error(error);
          setUploadedFiles([]);
        }
      }
    }
    setView(newView);
  };

  const handleDateChange = (e) => {
    const parsedDate = parse(e.target.value, "MMMM d, h:mm a", new Date());
    setDate(e.target.value);
    setSelectedDate(parsedDate);
  };

  const handleOkClick = () => {
    handleCloseModal();
  };

  const getPageName = (channelName) => {
    const channel = selectedChannels.find(
      (channel) =>
        channel.socialMediaConnector.channelName.toLowerCase() ===
        channelName.toLowerCase()
    );
    return channel ? channel.pageName : "Page Name"; // Default fallback
  };

  const savePost = async (e) => {
    e.stopPropagation(); // Stop event propagation
    e.preventDefault(); // Prevent default action

    console.log("Save post function called"); // Add this line for debugging
    const endPoint = "/save-post";
    const url = `${TQ_URL}${endPoint}`;
    try {
      const postData = {
        partnerId,
        connectedChannelsId: selectedChannels.map(
          (channel) => channel.connectedChannelsId
        ),
        text: postText,
        postType: view,
        scheduledDateTime: date,
      };

      const formData = new FormData();
      formData.append(
        "post",
        new Blob([JSON.stringify(postData)], { type: "application/json" })
      );

      uploadedFiles
        .filter((file) => file.type.startsWith("image/"))
        .forEach((file) => {
          formData.append("images", file);
        });

      const videoFile = uploadedFiles.find((file) =>
        file.type.startsWith("video/")
      );
      if (videoFile) {
        formData.append("video", videoFile);
      }

      setLoading(true);
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      });

      toast.success("Post saved successfully!");

      setLoading(false);

      setTimeout(() => {
        onClose();
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error saving post:", error);
      setError("Error saving post");
    }
  };

  const isToday =
    selectedDate && selectedDate.toDateString() === new Date().toDateString();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-scroll z-50 px-4 modal-overlay">
      <div className="fade-in-bottom flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
        {loading ? (
          <div className="flex items-center justify-center h-[200px]">
            <Triangle
              visible={true}
              height="120"
              width="120"
              color="blue"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <div
            id="modal_content"
            className="rounded-xl w-full md:w-[700px] h-[700px] max-h-[90vh] overflow-auto bg-white"
          >
            <div className="rounded-t-xl w-full flex justify-between items-center">
              <h2 className="text-xl font-bold mb-4 text-start pt-4 px-4 md:px-10">
                Schedule Post
              </h2>
              <button onClick={onClose} className="px-2 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="p-4 md:p-6 bg-white rounded-b-xl">
              <div className="container flex flex-wrap gap-4">
                {connectedChannels.length === 0 ? (
                  <div className="flex flex-col items-center justify-center h-full mt-10">
                    <p className="text-xl font-bold">
                      No channels are connected
                    </p>
                  </div>
                ) : (
                  connectedChannels.map((channel) => (
                    <CircleComponent
                      key={channel.connectedChannelsId}
                      bigCircleImageUrl={channel.pageImageUrl}
                      smallCircleImageUrl={
                        channel.socialMediaConnector.channelName
                      }
                      channelName={channel.socialMediaConnector.channelName}
                      pageNumber={channel.pageNumber}
                      onClick={() => handleClick(channel)}
                    />
                  ))
                )}
              </div>

              <div className="mt-6 border border-gray-300 p-4 rounded-t-lg relative">
                <div className="flex space-x-4">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="post"
                      name="view"
                      value="post"
                      className="mr-2"
                      checked={view === "post"}
                      onChange={() => handleViewChange("post")}
                    />
                    <label htmlFor="post">Post</label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="reel"
                      name="view"
                      value="reel"
                      className="mr-2"
                      checked={view === "reel"}
                      onChange={() => handleViewChange("reel")}
                    />
                    <label htmlFor="reel">Reel</label>
                  </div>
                </div>
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 h-1 w-8 bg-gray"></div>
              </div>
              <div className="border border-gray-300 rounded-b-lg h-[170px]">
                <textarea
                  className="w-full h-full p-4 rounded-b-lg border-0 focus:outline-none resize-none"
                  placeholder="Type your content here..."
                  onChange={(e) => setPostText(e.target.value)}
                />
              </div>
              <div
                {...getRootProps()}
                className="mt-4 p-4 border border-dashed border-gray-400 rounded cursor-pointer"
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here...</p>
                ) : (
                  <p>
                    Drag 'n' drop some files here, or click to select files
                    (Only images and videos are accepted)
                  </p>
                )}
              </div>
              <ImagePreview
                files={uploadedFiles}
                handleRemove={handleRemoveFile}
              />
              <div className="flex items-center mt-4">
                <span className="font-bold">{`Schedule date: ${
                  date
                    ? format(selectedDate, "MMMM d, h:mm a")
                    : "No date selected"
                }`}</span>

                <button
                  onClick={handleOpenModal}
                  className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
                >
                  Edit
                </button>
                <Modal
                  isOpen={showDatePicker}
                  onRequestClose={handleCloseModal}
                  contentLabel="Date Picker Modal"
                  className="modal"
                  overlayClassName="modal-overlay"
                >
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      setDate(format(date, "MMMM d, yyyy h:mm a"));
                    }}
                    minDate={new Date()}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm a"
                    timeFormat="h:mm aa"
                    minTime={isToday ? new Date() : new Date().setHours(0, 0)}
                    maxTime={
                      isToday
                        ? new Date().setHours(23, 59)
                        : new Date().setHours(23, 59)
                    }
                    timeIntervals={1}
                    inline
                  />
                  <button
                    onClick={handleOkClick}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
                  >
                    OK
                  </button>
                  <button
                    onClick={handleCloseModal}
                    className="mt-4 px-4 py-2 ml-2 bg-red-500 text-white rounded-lg"
                  >
                    Close
                  </button>
                </Modal>
              </div>
              <div className="mt-4 flex">
                <button
                  onClick={(e) => savePost(e)}
                  className={`mt-4 px-4 py-2 ${
                    isSavePostDisabled
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-green-500"
                  } text-white rounded-lg mx-auto justify-center item-center`}
                  disabled={isSavePostDisabled}
                >
                  Save Post
                </button>
              </div>
            </div>
          </div>
        )}

        {!loading && (
          <CSSTransition
            in={isFacebookSelected}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div className="rounded-xl w-full md:w-[350px] h-[654px] max-h-[90vh] overflow-auto bg-white facebook-preview">
              <div className="rounded-t-xl w-full flex justify-between items-center">
                <h2 className="text-xl font-bold mb-4 text-start pt-4 px-4 md:px-10">
                  {view === "post"
                    ? "Facebook Preview Post"
                    : "Facebook Preview Reel"}
                </h2>
                <button onClick={onClose} className="px-2 cursor-pointer">
                  {/* SVG code */}
                </button>
              </div>
              <div className="p-4 md:p-6 bg-white rounded-b-xl">
                {view === "post" ? (
                  <div className="border border-gray-300 p-4 rounded-lg w-full">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center">
                        <img
                          src="https://via.placeholder.com/40"
                          alt="Profile"
                          className="w-10 h-10 rounded-full mr-2"
                        />
                        <div>
                          <p className="font-bold">{getPageName("facebook")}</p>
                          <div className="flex items-center text-sm text-gray-600">
                            <span>Just now · </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              className="w-4 h-4 text-gray-500 ml-1"
                            >
                              <path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.2s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="w-5 h-5 text-gray-600"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 9.5A1.5 1.5 0 1 1 3 6.5a1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                        </svg>
                      </div>
                    </div>
                    <p className="mb-2 break-words whitespace-pre-line mt-2">
                      {postText}
                    </p>
                    <div className="grid gap-1">
                      {uploadedFiles.length === 1 &&
                        uploadedFiles[0].type.startsWith("image/") && (
                          <img
                            src={URL.createObjectURL(uploadedFiles[0])}
                            alt="uploaded-0"
                            className="w-full rounded-lg"
                          />
                        )}
                      {uploadedFiles.length === 1 &&
                        uploadedFiles[0].type.startsWith("video/") && (
                          <video
                            autoPlay
                            loop
                            src={URL.createObjectURL(uploadedFiles[0])}
                            alt="uploaded-0"
                            className="w-full rounded-lg"
                          />
                        )}
                      {uploadedFiles.length === 2 && (
                        <div className="grid grid-cols-2 gap-1">
                          <img
                            src={URL.createObjectURL(uploadedFiles[0])}
                            alt="uploaded-0"
                            className="w-full rounded-lg"
                          />
                          <img
                            src={URL.createObjectURL(uploadedFiles[1])}
                            alt="uploaded-1"
                            className="w-full rounded-lg"
                          />
                        </div>
                      )}
                      {uploadedFiles.length === 3 && (
                        <>
                          <img
                            src={URL.createObjectURL(uploadedFiles[0])}
                            alt="uploaded-0"
                            className="w-full rounded-lg"
                          />
                          <div className="grid grid-cols-2 gap-1">
                            <img
                              src={URL.createObjectURL(uploadedFiles[1])}
                              alt="uploaded-1"
                              className="w-full rounded-lg"
                            />
                            <img
                              src={URL.createObjectURL(uploadedFiles[2])}
                              alt="uploaded-2"
                              className="w-full rounded-lg"
                            />
                          </div>
                        </>
                      )}
                      {uploadedFiles.length === 4 && (
                        <div className="grid grid-cols-2 gap-1">
                          <img
                            src={URL.createObjectURL(uploadedFiles[0])}
                            alt="uploaded-0"
                            className="w-full rounded-lg"
                          />
                          <img
                            src={URL.createObjectURL(uploadedFiles[1])}
                            alt="uploaded-1"
                            className="w-full rounded-lg"
                          />
                          <img
                            src={URL.createObjectURL(uploadedFiles[2])}
                            alt="uploaded-2"
                            className="w-full rounded-lg"
                          />
                          <img
                            src={URL.createObjectURL(uploadedFiles[3])}
                            alt="uploaded-3"
                            className="w-full rounded-lg"
                          />
                        </div>
                      )}
                      {uploadedFiles.length === 5 && (
                        <>
                          <div className="grid grid-cols-2 gap-1">
                            <img
                              src={URL.createObjectURL(uploadedFiles[0])}
                              alt="uploaded-0"
                              className="w-full rounded-lg"
                            />
                            <img
                              src={URL.createObjectURL(uploadedFiles[1])}
                              alt="uploaded-1"
                              className="w-full rounded-lg"
                            />
                          </div>
                          <div className="grid grid-cols-3 gap-1">
                            <img
                              src={URL.createObjectURL(uploadedFiles[2])}
                              alt="uploaded-2"
                              className="w-full rounded-lg"
                            />
                            <img
                              src={URL.createObjectURL(uploadedFiles[3])}
                              alt="uploaded-3"
                              className="w-full rounded-lg"
                            />
                            <img
                              src={URL.createObjectURL(uploadedFiles[4])}
                              alt="uploaded-4"
                              className="w-full rounded-lg"
                            />
                          </div>
                        </>
                      )}
                      {uploadedFiles.length > 5 && (
                        <>
                          <div className="grid grid-cols-2 gap-1">
                            <img
                              src={URL.createObjectURL(uploadedFiles[0])}
                              alt="uploaded-0"
                              className="w-full rounded-lg"
                            />
                            <img
                              src={URL.createObjectURL(uploadedFiles[1])}
                              alt="uploaded-1"
                              className="w-full rounded-lg"
                            />
                          </div>
                          <div className="grid grid-cols-3 gap-1">
                            {uploadedFiles.slice(2, 5).map((file, index) => (
                              <div key={index} className="relative">
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={`uploaded-${index + 2}`}
                                  className="w-full rounded-lg"
                                />
                                {index === 2 && uploadedFiles.length > 5 && (
                                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-white text-xl font-bold rounded-lg">
                                    +{uploadedFiles.length - 5}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="flex justify-around border-t pt-2">
                      <button className="flex items-center space-x-1 text-gray-600 hover:text-blue-600">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="w-5 h-5"
                        >
                          <path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.2s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z" />
                        </svg>
                        <span>Like</span>
                      </button>
                      <button className="flex items-center space-x-1 text-gray-600 hover:text-blue-600">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="w-5 h-5"
                        >
                          <path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z" />
                        </svg>
                        <span>Comment</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="relative w-full h-[500px] bg-black rounded-lg overflow-hidden">
                    {uploadedFiles.length === 1 &&
                      uploadedFiles[0].type.startsWith("video/") && (
                        <video
                          autoPlay
                          loop
                          src={URL.createObjectURL(uploadedFiles[0])}
                          className="absolute inset-0 w-full h-full object-cover"
                        />
                      )}
                    <div className="absolute bottom-16 left-0 p-4 text-white w-full flex justify-between items-center">
                      <div className="flex items-center space-x-2">
                        <img
                          src="https://via.placeholder.com/40"
                          alt="Profile"
                          className="w-8 h-8 rounded-full"
                        />
                        <div className="flex items-center space-x-1">
                          <p className="font-bold text-sm">
                            {getPageName("facebook")}
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="w-3 h-3"
                          >
                            <path
                              fill="currentColor"
                              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-4.01-.49-7.22-3.91-7.72-7.93h4.72v1.93c0 .41.34.75.75.75s.75-.34.75-.75v-1.93h4.72c-.49 4.01-3.71 7.44-7.72 7.93zM12 4c1.78 0 3.45.58 4.82 1.57L6.57 16.82C5.58 15.45 5 13.78 5 12c0-3.86 3.14-7 7-7zm0 14c-1.78 0-3.45-.58-4.82-1.57L17.43 7.18C18.42 8.55 19 10.22 19 12c0 3.86-3.14 7-7 7z"
                            />
                          </svg>
                          <button className="border border-white text-xs px-2 py-1 ml-1 rounded-full">
                            Follow
                          </button>
                        </div>
                      </div>
                      <div className="cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="w-4 h-4"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 9.5A1.5 1.5 0 1 1 3 6.5a1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                        </svg>
                      </div>
                    </div>
                    <div className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2 flex flex-col space-y-4 text-white">
                      <button className="flex flex-col items-center space-y-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                          />
                        </svg>
                        <span className="text-xs">29K</span>
                      </button>
                      <button className="flex flex-col items-center space-y-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M12 4.998c-4.411 0-8 3.589-8 8 0 4.411 3.589 8 8 8 4.411 0 8-3.589 8-8 0-4.411-3.589-8-8-8zm0 14.563c-3.618 0-6.563-2.946-6.563-6.563 0-3.617 2.946-6.563 6.563-6.563 3.617 0 6.563 2.946 6.563 6.563 0 3.617-2.946 6.563-6.563 6.563zM12 8.998c-.829 0-1.5.671-1.5 1.5s.671 1.5 1.5 1.5 1.5-.671 1.5-1.5-.671-1.5-1.5-1.5-1.5.671-1.5 1.5 1.5.671 1.5 1.5 1.5-.671 1.5-1.5-671-1.5-1.5-1.5z"
                          />
                        </svg>
                        <span className="text-xs">35</span>
                      </button>
                      <button className="flex flex-col items-center space-y-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M2 21l21-9-21-9v7l15 2-15 2v7z"
                          />
                        </svg>
                        <span className="text-xs">163</span>
                      </button>
                      <button className="flex flex-col items-center space-y-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M21 6.5l-2.1 1.75c-2.47-3.65-8.23-3.66-10.71 0L6 6.5c3.37-4.18 10.61-4.18 14 0zM21 9.5l-2.1 1.75c-1.53-2.33-5.07-2.34-6.61 0L6 9.5c2.1-2.59 6.48-2.59 8.59 0L21 9.5zM21 12.5l-2.1 1.75c-.61-.93-1.67-1.5-2.89-1.5s-2.28.57-2.89 1.5L6 12.5c1.2-1.18 3.13-1.18 4.33 0L21 12.5z"
                          />
                        </svg>
                        <span className="text-xs">Send</span>
                      </button>
                      <button className="flex flex-col items-center space-y-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M3 5.5a1.5 1.5 0 1 0 0 3h18a1.5 1.5 0 0 0 0-3H3zm0 7a1.5 1.5 0 1 0 0 3h18a1.5 1.5 0 0 0 0-3H3zm0 7a1.5 1.5 0 1 0 0 3h18a1.5 1.5 0 0 0 0-3H3z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="absolute bottom-4 left-0 w-full px-4 text-white">
                      <div className="p-2 mt-4">
                        <p className="text-xs">{truncateText(postText)}</p>
                      </div>
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder="Add a comment..."
                          className="w-full p-2 rounded-full border border-white bg-gray-800 bg-opacity-50 placeholder-gray-400 text-white focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </CSSTransition>
        )}
        {!loading && (
          <CSSTransition
            in={isInstagramSelected}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div className="rounded-xl w-full md:w-[350px] h-[654px] max-h-[90vh] overflow-auto bg-white instagram-preview">
              <div className="rounded-t-xl w-full flex justify-between items-center">
                <h2 className="text-xl font-bold mb-4 text-start pt-4 px-4 md:px-10">
                  {view === "post"
                    ? "Instagram Preview Post"
                    : "Instagram Preview Reel"}
                </h2>
                <button onClick={onClose} className="px-2 cursor-pointer">
                  {/* SVG code */}
                </button>
              </div>
              <div className="p-4 md:p-6 bg-white rounded-b-xl">
                {view === "post" ? (
                  <div className="border border-gray-300 p-4 rounded-lg w-full">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center">
                        <img
                          src="https://via.placeholder.com/40"
                          alt="Profile"
                          className="w-10 h-10 rounded-full mr-2"
                        />
                        <div>
                          <p className="font-bold">
                            {getPageName("instagram")}
                          </p>
                          <img
                            src="https://via.placeholder.com/15x15?text=✓"
                            alt="Blue Tick"
                            className="inline-block ml-1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="relative w-full h-[400px] bg-black rounded-lg overflow-hidden flex items-center justify-center">
                      {uploadedFiles.length > 0 && (
                        <div className="absolute inset-0 w-full h-full overflow-hidden">
                          <Slider
                            dots
                            infinite={false}
                            speed={500}
                            slidesToShow={1}
                            slidesToScroll={1}
                          >
                            {uploadedFiles.map((file, index) => (
                              <div
                                key={index}
                                className="slide flex items-center justify-center"
                              >
                                {file.type.startsWith("image/") ? (
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`uploaded-${index}`}
                                    className="max-h-full max-w-full object-contain w-full h-full"
                                  />
                                ) : (
                                  <video
                                    autoPlay
                                    loop
                                    src={URL.createObjectURL(file)}
                                    alt={`uploaded-${index}`}
                                    className="max-h-full max-w-full object-contain w-full h-full"
                                  />
                                )}
                              </div>
                            ))}
                          </Slider>
                        </div>
                      )}
                      <div className="absolute bottom-2 w-full flex justify-center">
                        <ul className="flex space-x-1">
                          {uploadedFiles.map((_, index) => (
                            <li
                              key={index}
                              className={`h-2 w-2 rounded-full ${
                                index === 0 ? "bg-gray-800" : "bg-gray-400"
                              }`}
                            />
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="flex justify-between mt-2 px-4">
                      <div className="flex space-x-4">
                        <button className="text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="w-6 h-6"
                          >
                            <path
                              fill="currentColor"
                              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                            />
                          </svg>
                        </button>
                        <button className="text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="w-6 h-6"
                          >
                            <path
                              fill="currentColor"
                              d="M12 4.998c-4.411 0-8 3.589-8 8 0 4.411 3.589 8 8 8 4.411 0 8-3.589 8-8 0-4.411-3.589-8-8-8zm0 14.563c-3.618 0-6.563-2.946-6.563-6.563 0-3.617 2.946-6.563 6.563-6.563 3.617 0 6.563 2.946 6.563 6.563 0 3.617-2.946 6.563-6.563 6.563zM12 8.998c-.829 0-1.5.671-1.5 1.5s.671 1.5 1.5 1.5 1.5-.671 1.5-1.5-.671-1.5-1.5-1.5-1.5.671-1.5 1.5 1.5.671 1.5 1.5 1.5-.671 1.5-1.5-.671-1.5-1.5-1.5-1.5.671-1.5 1.5 1.5.671 1.5 1.5 1.5-.671 1.5-1.5z"
                            />
                          </svg>
                        </button>
                        <button className="text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="w-6 h-6"
                          >
                            <path
                              fill="currentColor"
                              d="M2 21l21-9-21-9v7l15 2-15 2v7z"
                            />
                          </svg>
                        </button>
                      </div>
                      <button className="text-black">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M3 5.5a1.5 1.5 0 1 0 0 3h18a1.5 1.5 0 0 0 0-3H3zm0 7a1.5 1.5 0 1 0 0 3h18a1.5 1.5 0 0 0 0-3H3zm0 7a1.5 1.5 0 1 0 0 3h18a1.5 1.5 0 0 0 0-3H3z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="mt-2 px-4">
                      <p className="text-sm font-bold">{likes} likes</p>
                      <p className="text-sm">
                        <span className="font-bold">
                          {getPageName("instagram")}
                        </span>
                        {truncateText(postText)}
                      </p>
                    </div>
                    <div className="mt-2 px-4">
                      <input
                        type="text"
                        placeholder="Add a comment..."
                        className="w-full p-2 rounded-full border border-gray-300 bg-gray-100 placeholder-gray-400 text-gray-700 focus:outline-none"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="relative w-full h-[500px] bg-black rounded-lg overflow-hidden">
                    {uploadedFiles.length === 1 &&
                      uploadedFiles[0].type.startsWith("video/") && (
                        <video
                          autoPlay
                          loop
                          src={URL.createObjectURL(uploadedFiles[0])}
                          className="absolute inset-0 w-full h-full object-cover"
                        />
                      )}
                    <div className="absolute bottom-16 left-0 p-4 text-white w-full flex justify-between items-center">
                      <div className="flex items-center space-x-2">
                        <img
                          src="https://via.placeholder.com/40"
                          alt="Profile"
                          className="w-8 h-8 rounded-full"
                        />
                        <div className="flex items-center space-x-1">
                          <p className="font-bold text-sm">
                            {getPageName("instagram")}
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="w-3 h-3"
                          >
                            <path
                              fill="currentColor"
                              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-4.01-.49-7.22-3.91-7.72-7.93h4.72v1.93c0 .41.34.75.75.75s.75-.34.75-.75v-1.93h4.72c-.49 4.01-3.71 7.44-7.72 7.93zM12 4c1.78 0 3.45.58 4.82 1.57L6.57 16.82C5.58 15.45 5 13.78 5 12c0-3.86 3.14-7 7-7zm0 14c-1.78 0-3.45-.58-4.82-1.57L17.43 7.18C18.42 8.55 19 10.22 19 12c0 3.86-3.14 7-7 7z"
                            />
                          </svg>
                          <button className="border border-white text-xs px-2 py-1 ml-1 rounded-full">
                            Follow
                          </button>
                        </div>
                      </div>
                      <div className="cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="w-4 h-4"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 9.5A1.5 1.5 0 1 1 3 6.5a1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                        </svg>
                      </div>
                    </div>
                    <div className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2 flex flex-col space-y-4 text-white">
                      <button className="flex flex-col items-center space-y-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                          />
                        </svg>
                        <span className="text-xs">29K</span>
                      </button>
                      <button className="flex flex-col items-center space-y-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M12 4.998c-4.411 0-8 3.589-8 8 0 4.411 3.589 8 8 8 4.411 0 8-3.589 8-8 0-4.411-3.589-8-8-8zm0 14.563c-3.618 0-6.563-2.946-6.563-6.563 0-3.617 2.946-6.563 6.563-6.563 3.617 0 6.563 2.946 6.563 6.563 0 3.617-2.946 6.563-6.563 6.563zM12 8.998c-.829 0-1.5.671-1.5 1.5s.671 1.5 1.5 1.5 1.5-.671 1.5-1.5-.671-1.5-1.5-1.5-1.5.671-1.5 1.5 1.5.671 1.5 1.5 1.5-.671 1.5-1.5-.671-1.5-1.5-1.5-1.5.671-1.5 1.5 1.5.671 1.5 1.5 1.5-.671 1.5-1.5-.671-1.5-1.5-1.5-1.5.671-1.5 1.5 1.5.671 1.5 1.5 1.5-.671 1.5-1.5-.671-1.5-1.5-1.5-1.5.671-1.5 1.5 1.5.671 1.5 1.5 1.5-.671 1.5-1.5-.671-1.5-1.5-1.5-1.5.671-1.5 1.5 1.5.671 1.5 1.5 1.5-.671 1.5-1.5-.671-1.5-1.5-1.5-1.5.671-1.5 1.5z"
                          />
                        </svg>
                        <span className="text-xs">35</span>
                      </button>
                      <button className="flex flex-col items-center space-y-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M2 21l21-9-21-9v7l15 2-15 2v7z"
                          />
                        </svg>
                        <span className="text-xs">163</span>
                      </button>
                      <button className="flex flex-col items-center space-y-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M21 6.5l-2.1 1.75c-2.47-3.65-8.23-3.66-10.71 0L6 6.5c3.37-4.18 10.61-4.18 14 0zM21 9.5l-2.1 1.75c-1.53-2.33-5.07-2.34-6.61 0L6 9.5c2.1-2.59 6.48-2.59 8.59 0L21 9.5zM21 12.5l-2.1 1.75c-.61-.93-1.67-1.5-2.89-1.5s-2.28.57-2.89 1.5L6 12.5c1.2-1.18 3.13-1.18 4.33 0L21 12.5z"
                          />
                        </svg>
                        <span className="text-xs">Send</span>
                      </button>
                      <button className="flex flex-col items-center space-y-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M3 5.5a1.5 1.5 0 1 0 0 3h18a1.5 1.5 0 0 0 0-3H3zm0 7a1.5 1.5 0 1 0 0 3h18a1.5 1.5 0 0 0 0-3H3zm0 7a1.5 1.5 0 1 0 0 3h18a1.5 1.5 0 0 0 0-3H3z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="absolute bottom-4 left-0 w-full px-4 text-white">
                      <div className="p-2 mt-4">
                        <p className="text-xs">{truncateText(postText)}</p>
                      </div>
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder="Add a comment..."
                          className="w-full p-2 rounded-full border border-white bg-gray-800 bg-opacity-50 placeholder-gray-400 text-white focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </CSSTransition>
        )}
      </div>
      <ToastContainer theme="colored" />
    </div>
  );
};

export default OpenModal;
