import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const LinkedInRedirect = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      fetch(`/api/linkedin/callback?code=${code}`, {
        method: "GET",
      })
        .then((response) => response.text())
        .then((data) => {
          alert(data); // Display success message
        })
        .catch((err) => {
          alert("Error connecting LinkedIn: " + err.message);
        });
    }
  }, [searchParams]);

  return <p>Connecting to LinkedIn...</p>;
};

export default LinkedInRedirect;
