import React, { useState, forwardRef } from "react";
import DonorManagementSideBar from "./DonorManagementSideBar";
import TopHeader from "../Home/TopHeader";
import { useNavigate } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import InputMask from "react-input-mask";

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { DatePickerInput } from "@mantine/dates";
import { TQ_URL } from "../../utility/baseUrl";

import * as yup from "yup";
import DonorManagementTop from "./DonorManagementTop";

const stripMask = (value) => value.replace(/\D/g, "");

const schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(5, "Name must be at least 5 characters")
    .max(50, "Name must be at most 50 characters")
    .required("Name is Required *"),

  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is Required *"),
  phone: yup
    .string()
    .required("Contact Number is Required *")
    .transform(stripMask)
    .matches(
      /^[2-9]\d{2}[2-9]\d{2}\d{4}$/,
      "Contact number must be a valid US phone number"
    ),
  address: yup.string().required("Address is Required * "),
  zipcode: yup
    .number()
    .required("ZipCode is Required *")
    .positive("ZipCode should be positive or non-zero")
    .typeError("ZipCode should not be Empty"),

  city: yup.string().required("City is Required *"),
  state: yup.string().required("State is Required *"),
  country: yup.string().required("Country is Required *"),
});

const CustomInput = forwardRef((props, ref) => (
  <InputMask {...props} inputRef={ref} />
));

const AddDonorManually = () => {
  const [stateName, setStateName] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [countryFlag, setCountryFlag] = useState(false);
  const navigate = useNavigate();

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = async (data, event) => {
    console.log("page is hitting success ");
    console.log({ data });
    const formattedPhone = `(${data.phone.slice(0, 3)}) ${data.phone.slice(
      3,
      6
    )}-${data.phone.slice(6)}`;

    event.preventDefault();
    const token = localStorage.getItem("accessToken");
    const response = await fetch(`${TQ_URL}/saveDonorDetails`, {
      method: "POST",
      Authorization: token,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        donorName: toInitCap(data.name),
        donorEmail: data.email,
        contactNo: formattedPhone,
        address: data.address,
        city: data.city,
        state: data.state,
        country: data.country,
        postalCode: data.zipcode,
        partnerId: partnerData.partnerId,
      }),
    });

    const res = await response.json();

    console.log("BODY >>", res);

    if (response.status === 200) {
      console.log(res);
      // setStoredUserId(res.userId);

      toast.success("Donor Added Successfully");

      reset();
      setValue("state", "");
      setValue("country", "");
      setValue("city", "");

      setTimeout(() => {
        navigate(`/donorlist`);
      }, 1000);
    } else if (
      response.status === 400 &&
      res.message &&
      res.message.length > 0
    ) {
      let errorMsg = "";
      res.message.forEach((msg) => {
        errorMsg = errorMsg + "  " + msg;
      });

      console.log(errorMsg);

      toast.error(errorMsg + "\n", { position: "top-right" });
    } else {
      userNotCreated();
    }
  };

  const userNotCreated = () => {
    toast.error("Failed to saved the details", { position: "top-right" });
  };

  const zipCodeDetails = async (event) => {
    const zipcode = event.target.value;
    console.log(zipcode);

    if (!zipcode) {
      setNotFoundMessage("Zip Code is required");
      setStateName("");
      setCityName("");

      setValue("state", "");
      setValue("country", "");
      setCountryFlag(false);
      return;
    }
    if (zipcode.length === 5) {
      try {
        const response = await fetch(`${TQ_URL}/getAddressDetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            zipcode: zipcode,
          }),
        });

        if (response.status == 200) {
          const res = await response.json();
          if (res.Status == "Succsess") {
            setStateName(res.State.state);
            setCityName(res.State.city);
            setValue("state", res.State.state);
            setValue("city", res.State.city);
            setValue("country", "USA");
            setNotFoundMessage("");
            setCountryFlag(true);
          } else {
            setNotFoundMessage("Incorrect Zip Code");
            setStateName("");
            setCountryFlag(false);
          }
        }
      } catch (error) {
        console.error("Error making post request:", error);
      }
    } else {
      setStateName("");
      setValue("state", "");
      setValue("city", "");
      setValue("country", "");
      setCountryFlag(false);
      setNotFoundMessage("Incorrect Zip Code");
    }
  };

  return (
    <>
      {/* <TopHeader /> */}
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex  mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user  md:ml-[250px] md:pb-[100px] ">
          <div className=" shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Add Donor Manually</h2>
              </div>
            </div>
          </div>

          <div className="w-full item-center md:py-4 px-8">
            <form
              onSubmit={handleSubmit(onSubmitHandler)}
              className="fundraiser-for-other-form md:py-12 md:px-4 p-6 md:me-4"
            > 
              <div className="mb-6 md:flex">
                <label>Individual's name</label>
                <div className="w-full">
                  <input
                    {...register("name")}
                    placeholder="Enter individual's name"
                    type="text"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.name && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.name?.message}
                  </p>
                </div>
              </div>

              <div className="mb-6 md:flex">
                <label>Email*</label>
                <div className="w-full">
                  <input
                    {...register("email")}
                    placeholder="Email"
                    type="email"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.email && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.email?.message}
                  </p>
                </div>
              </div>

              {/* Phone */}
              {/* <div className="mb-6 md:flex">
                <label>Contact Number*</label>
                <div className="w-full">
                  <input
                    {...register("phone")}
                    placeholder="Phone"
                    type="text"
                    maxLength={12}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.phone && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.phone?.message}
                  </p>
                </div>
              </div> */}

              <div className="mb-6 md:flex">
                <label>Contact Number*</label>
                <div className="w-full">
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        mask="(999) 999-9999"
                        placeholder="Contact Number *"
                        alwaysShowMask={false}
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.phone && "border-red-500"
                        }`}
                      />
                    )}
                  />
                </div>
                <p className="text-red-500 text-sm mt-1">
                  {errors.phone?.message}
                </p>
              </div>

              {/* Address */}
              <div className="mb-6 md:flex">
                <label>Address</label>
                <div className="w-full">
                  <input
                    {...register("address")}
                    placeholder="Enter Address"
                    type="text"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.address && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.address?.message}
                  </p>
                </div>
              </div>

              {/* zipcode */}
              <div className="mb-6 md:flex">
                <label>Zip Code</label>
                <div className="w-full">
                  <input
                    {...register("zipcode")}
                    placeholder="zipcode"
                    onBlur={zipCodeDetails}
                    maxLength={6}
                    type="number"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.zipcode && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.zipcode?.message}
                  </p>
                  {!errors.zipcode?.message && (
                    <p className="text-red-500 text-sm mt-1">
                      {notFoundMessage}
                    </p>
                  )}
                </div>
              </div>

              {/* City */}

              <div className="mb-6 md:flex">
                <label>City</label>
                <div className="w-full">
                  <input
                    {...register("city")}
                    placeholder="city"
                    type="text"
                    defaultValue={cityName}
                    readOnly
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.city && stateName == "" ? "border-red-500" : ""
                    }`}
                  />
                  <p
                    className={`text-red-500 text-sm mt-1 ${
                      cityName && "hidden"
                    }`}
                  >
                    {errors.city?.message}
                  </p>
                </div>
              </div>

              {/* state */}
              <div className="mb-6 md:flex">
                <label>State</label>
                <div className="w-full">
                  <input
                    {...register("state")}
                    placeholder="state"
                    type="text"
                    defaultValue={stateName}
                    readOnly
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.state && stateName == "" ? "border-red-500" : ""
                    }`}
                  />
                  <p
                    className={`text-red-500 text-sm mt-1 ${
                      stateName && "hidden"
                    }`}
                  >
                    {errors.state?.message}
                  </p>
                </div>
              </div>

              {/* country */}
              <div className="mb-6 md:flex">
                <label>Country</label>
                <div className="w-full">
                  <input
                    {...register("country")}
                    placeholder="country"
                    type="text"
                    value={countryFlag ? "USA" : ""}
                    readOnly
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.country && stateName == "" ? "border-red-500" : ""
                    }`}
                  />
                  <p
                    className={`text-red-500 text-sm mt-1 ${
                      stateName && "hidden"
                    }`}
                  >
                    {errors.country?.message}
                  </p>
                </div>
              </div>

              <div className="flex  justify-center items-center">
                {/* Submit Button */}
                <button
                  type="submit"
                  className="md:w-1/3 w-1/2 text-white p-2 rounded-md btn-style-1 mt-4 "
                >
                  Submit
                </button>
              </div>
            </form>
            <ToastContainer theme="colored" />
          </div>
        </main>
      </div>
    </>
  );
};

export default AddDonorManually;
