import React, { useState, useEffect } from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { Link, Element } from "react-scroll";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Terms = () => {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Scroll to section and center content
  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (!section) return; // If section doesn't exist, return early.

    // Get the sticky header height
    const header = document.querySelector(".sticky-header");
    const headerHeight = header ? header.offsetHeight : 0;

    // Get the position of the section, and adjust it to account for the header height
    const sectionPosition =
      section.getBoundingClientRect().top + window.scrollY;

    // Scroll to the position, considering the header's height
    window.scrollTo({
      top: sectionPosition - headerHeight - 20, // Add 20px for extra margin
      behavior: "smooth",
    });

    // Update the active section for the Table of Contents highlight
    setActiveSection(sectionId);
  };

  // Generate className for active TOC link
  const isActiveLink = (id) =>
    activeSection === id
      ? "text-blue-700 font-bold"
      : "text-blue-500 hover:text-blue-700";

  return (
    <div>
      {/* Header */}
      <Header />

      {/* Main Content */}
      <div className="privacy-container p-10 bg-gray-50 text-gray-800">
        <div className="max-w-7xl mx-auto">
          {/* Title Section */}
          <div className="mb-12 text-center">
            <h1 className="text-4xl font-extrabold text-gray-900 mb-4">
              {" "}
              Prabalta Terms of Service
            </h1>
            <p className="text-lg text-gray-500 mt-2">
              Effective Date: 1 September 2024
            </p>
          </div>

          <div className="flex flex-col lg:flex-row">
            {/* Table of Contents */}
            <aside className="w-full lg:w-1/4 bg-white p-6 shadow-lg mb-10 lg:mb-0 rounded-lg lg:sticky lg:top-20 max-h-[calc(100vh-100px)] overflow-y-auto">
              <h2 className="text-2xl font-semibold mb-6 text-gray-800">
                Table of Contents
              </h2>

              <ul className="space-y-4">
                <li>
                  <button
                    onClick={() => handleScrollToSection("Introduction")}
                    className={isActiveLink("Introduction")}
                  >
                    1. Introduction
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("Definitions")}
                    className={isActiveLink("Definitions")}
                  >
                    2. Definitions
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("serviceProvided")}
                    className={isActiveLink("serviceProvided")}
                  >
                    3. The Services We Provide
                  </button>
                </li>

                <li>
                  <button
                    onClick={() => handleScrollToSection("accountCreation")}
                    className={`sidebar-button ${isActiveLink(
                      "accountCreation"
                    )}`}
                  >
                    4. Account Creation and Eligibility Requirements
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("paymentProcessor")}
                    className={`sidebar-button ${isActiveLink(
                      "paymentProcessor"
                    )}`}
                  >
                    5. Payment Processors
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("userResponsibility")}
                    className={`sidebar-button ${isActiveLink(
                      "userResponsibility"
                    )}`}
                  >
                    6. User Responsibilities and Obligations
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("transferAndhold")}
                    className={`sidebar-button ${isActiveLink(
                      "transferAndhold"
                    )}`}
                  >
                    7. Transfers, Holds & Chargebacks
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("prohibitedFund")}
                    className={`sidebar-button ${isActiveLink(
                      "prohibitedFund"
                    )}`}
                  >
                    8. Prohibited Fundraisers and Related User Content
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("prohibitedUser")}
                    className={`sidebar-button ${isActiveLink(
                      "prohibitedUser"
                    )}`}
                  >
                    9. Prohibited User Conduct
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("contentModeration")}
                    className={`sidebar-button ${isActiveLink(
                      "contentModeration"
                    )}`}
                  >
                    10. Content Moderation and Reporting Fundraisers
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("fees")}
                    className={`sidebar-button ${isActiveLink("fees")}`}
                  >
                    11. Fees
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("propertyRights")}
                    className={`sidebar-button ${isActiveLink(
                      "propertyRights"
                    )}`}
                  >
                    12. Intellectual Property Rights, Content Ownership and
                    Licenses
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("dataPrivacy")}
                    className={`sidebar-button ${isActiveLink("dataPrivacy")}`}
                  >
                    13. Data Privacy and Artificial Intelligence
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("thirdPartyService")}
                    className={`sidebar-button ${isActiveLink(
                      "thirdPartyService"
                    )}`}
                  >
                    14. Third-Party Services and Content
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("terminationAccount")}
                    className={`sidebar-button ${isActiveLink(
                      "terminationAccount"
                    )}`}
                  >
                    15. Suspension or Termination of Accounts
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      handleScrollToSection("disclaimerAndLimitation")
                    }
                    className={`sidebar-button ${isActiveLink(
                      "disclaimerAndLimitation"
                    )}`}
                  >
                    16. Disclaimers and Limitations of Liability
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("indemnification")}
                    className={`sidebar-button ${isActiveLink(
                      "indemnification"
                    )}`}
                  >
                    17. Indemnification and Release
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("disputeResolution")}
                    className={`sidebar-button ${isActiveLink(
                      "disputeResolution"
                    )}`}
                  >
                    18. Dispute Resolution & Arbitration
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("changesTotheterms")}
                    className={`sidebar-button ${isActiveLink(
                      "changesTotheterms"
                    )}`}
                  >
                    19. Changes to the Terms
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("miscellaneous")}
                    className={`sidebar-button ${isActiveLink(
                      "miscellaneous"
                    )}`}
                  >
                    20. Miscellaneous
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScrollToSection("questions")}
                    className={`sidebar-button ${isActiveLink("questions")}`}
                  >
                    21. Questions? Concerns? Suggestions?
                  </button>
                </li>
              </ul>
            </aside>

            {/* Privacy Content with Margin for Distance */}
            <main className="w-full lg:w-3/4 p-8 bg-white shadow-lg rounded-lg lg:ml-10">
              <section id="Introduction">
                <h2 className="text-2xl font-extrabold mb-4">
                  1. Introduction
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>1.1. Purpose of the Terms</strong>: Welcome to
                  Prabalta! These Terms of Service, which we may update from
                  time to time, apply to the Prabalta Platform, including our
                  website at{" "}
                  <a
                    href="https://www.prabalta.org/"
                    className="text-blue-500 underline hover:underline"
                  >
                    www.prabalta.org
                  </a>
                  , mobile apps, and any new features we might add. These Terms
                  of Service are important because they help define our
                  relationship with you as you use the Platform. “Prabalta,”
                  “we,” “us,” “our,” and other similar terms, shall refer to the
                  party you are contracting with.{" "}
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>1.2. Acceptance of Terms</strong>: By using any of the
                  Services or the Platform, you&#8217;re agreeing to these Terms
                  of Service. This means you understand and accept all the rules
                  about using our Services. Whether you are contributing to a
                  Fundraiser as a Donor, starting a Fundraiser, withdrawing
                  funds as an Organizer, Beneficiary, or NPO, or otherwise
                  interacting with the Services, these terms apply to you.
                  Depending on where you live, you might be entering into this
                  agreement with different Prabalta entities. If you are located
                  in the United States, you are contracting with Prabalta, Inc.
                  If you are located in Australia, you are contracting with
                  Prabalta Australia PTY Ltd. in Australia. If you are located
                  anywhere else in the world, you are contracting with Prabalta
                  Ireland Limited. However, Prabalta, Inc. may apply, and
                  exercise our rights under, these Terms of Service on behalf of
                  the party that you are contracting with. Please see the bottom
                  of these Terms of Service for the addresses and contact
                  information for each of these Prabalta entities.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>1.3. Quick Note on Arbitration</strong>: If
                  you&#8217;re using Prabalta in the United States, Australia or
                  Canada, you need to know about our arbitration agreement. By
                  using our Services, you are agreeing that if there&#8217;s a
                  disagreement or legal issue between you and Prabalta, it will
                  be resolved through binding arbitration, not in court. You
                  also waive your right to be part of a class action lawsuit or
                  to have a jury trial. Please make sure to read the “Dispute
                  Resolution &amp; Arbitration” section of these Terms of
                  Service for more details.
                </p>
              </section>

              {/* Definitions */}
              <section id="Definitions">
                <h2 className="text-2xl font-extrabold mb-4">2. Definitions</h2>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  These are the key terms that you need to know that will be
                  referenced throughout these Terms of Service:
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.1. Account</strong>: A unique account created by a
                  user to access and utilize Prabalta's Services, which includes
                  personal information, credentials, and activity logs.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.2. Affiliates</strong>: Any entity that directly or
                  indirectly controls, is controlled by, or is under common
                  control with Prabalta. This includes subsidiaries, parent
                  companies, and any other related entities.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.3. Beneficiary</strong>: The individual, group, or
                  entity for whose benefit the corresponding Fundraiser is
                  conducted and who is the intended recipient of the funds
                  raised.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.4. Certified NPO</strong>: Any 501(c)(3) non-profit
                  organization that has successfully enrolled with the PayPal
                  Giving Fund to receive donations.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.5. Donor</strong>: Any individual or entity that
                  contributes funds to a Fundraiser on or through the Services.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.6. Fundraiser</strong>: A campaign initiated on the
                  Services with the goal of raising funds for a specific purpose
                  for a Beneficiary.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.7. NPO</strong>: A non-profit organization
                  (including churches, universities, or other educational
                  institutions) that is established for charitable purposes
                  under applicable laws and is eligible to receive donations
                  through the Platform.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.8. Organizer</strong>: An individual or entity
                  (including a non-profit organization) who initiates and
                  manages a fundraiser on the Platform.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.9. Platform</strong>: The entire suite of online
                  Services provided by Prabalta and its Affiliates, including
                  our website, mobile applications, and any related services or
                  technologies.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.10. Recurring Fee</strong>: A recurring fixed fee
                  that we may charge when donors set up a recurring donation to
                  a Fundraiser.
                  {/* To learn more about the Platform and the
                  applicable Recurring Fee, visit Prabalta Pricing. */}
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.11. Services</strong>: The various features,
                  functionalities, and tools provided by Prabalta and its
                  Affiliates through its Platform, enabling Users to create and
                  manage Fundraisers, donate and interact with each other.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.12. Services Content</strong>: All content and
                  materials provided by Prabalta on the Services, including
                  text, graphics, logos, images, and software, excluding User
                  Content.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.13. Software</strong>: The proprietary software and
                  applications provided and owned by Prabalta that enable users
                  to access and use the Services.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.14. Third Party Resources</strong>: Any websites,
                  Services, content, or resources provided by entities other
                  than Prabalta or its Affiliates, which may be accessible
                  through the Services.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.15. Transaction Fee</strong>: A fee charged by
                  Prabalta or its payment processors for processing donations.
                  {/* To learn more about the Platform and the applicable
                  Transaction Fee, visit Prabalta Pricing. */}
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.16. Transfers</strong>: The process of moving funds
                  raised through the Services to the Beneficiary’s bank account
                  or other designated account.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.17. User</strong>: Any individual or entity that
                  accesses, registers for, or uses the Platform, including
                  Organizers, Donors, and Beneficiaries.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.18. User Content</strong>: Any content, including
                  text, images, videos, and other materials, that a User posts,
                  uploads, submits, or otherwise makes available or shares with
                  other Users on or through the Services.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>2.19. User Conduct</strong>: The behaviors and actions
                  of Users while using or otherwise accessing the Services.
                </p>
              </section>

              {/* The Services We Provide */}
              <section id="serviceProvided">
                <h2 className="text-2xl font-extrabold mb-4">
                  3. The Services We Provide
                </h2>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>3.1. Description of the Services provided</strong>: We
                  offer a Platform for individuals, entities, or nonprofits to
                  create Fundraisers to collect monetary donations from Donors
                  for themselves or a third party who will benefit from the
                  funds. We also operate Classy.org via our affiliate, Classy,
                  Inc., providing specialized services to nonprofit and other
                  organizations mainly in the US.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>3.2. Our Role and Limitations</strong>: Our Services
                  are a tool for running Fundraisers and for helping Organizers
                  connect with Donors; we are not a bank, payment processor,
                  broker, charity, or financial advisor. We do not solicit
                  donations, the existence of the Services is not a solicitation
                  of donations, and we do not engage in solicitation activities
                  for ourselves or others on our Platform. All information
                  provided through our Services is for your general knowledge
                  and isn’t meant to be professional advice. If you need
                  specific advice, especially regarding financial, legal, or tax
                  issues, you should consult with a professional. We do not
                  control or endorse any User, Fundraiser, or cause, and we
                  can’t guarantee the success of a Fundraiser. As a Donor, it’s
                  up to you to decide if a cause is worth contributing to.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>
                    3.3. Modification, Suspension, or Termination of the
                    Services
                  </strong>
                  : We can change, pause, or stop all or some of the Services at
                  any time and for any reason. We will try to avoid any problems
                  this might cause you or others, but sometimes we might not be
                  able to give you advance notice, especially if it’s an
                  emergency or required by law. We are not responsible for any
                  issues that these changes might cause.
                </p>
              </section>

              {/*  Account Creation and Eligibility Requirements */}
              <section id="accountCreation">
                <h2 className="text-2xl font-extrabold mb-4">
                  4. Account Creation and Eligibility Requirements
                </h2>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>4.1. What You Need for Registration</strong>: When you
                  sign up to use certain Services, you must provide information
                  that is correct and complete about yourself, your
                  organization, or your NPO. This includes your real name,
                  address, phone number and any photos or videos that you may
                  provide, if you’re organizing the Fundraiser. It’s important
                  to always keep this information up to date to ensure
                  everything runs smoothly. Our{" "}
                  <a href="/privacy" className="text-blue-500 hover:underline">
                    {" "}
                    Privacy Notice
                  </a>{" "}
                  and these Terms of Service govern the registration data and
                  any other information you provide.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>4.2. Compliance with Third-Party Services</strong>:
                  You might also need to register with third-party payment
                  processors we work with, which may involve agreeing to their
                  terms (provided under the Payment Processor section below).
                  If, at any point, we or our payment processors discover that
                  the information you provided to us is inaccurate, violates our
                  Terms of Service or terms of service of our payment
                  processors, or if you misuse the funds, we may immediately
                  suspend or terminate your access to all or some of our
                  Services. You could also face criminal charges by relevant
                  governmental authorities.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>4.3. Age Restrictions</strong>:If you are under 13
                  years old (or under 16 in the European Economic Area, the
                  United Kingdom, or Switzerland), you cannot use the Platform
                  or Services, even with registration. If you’re not yet an
                  adult in your area, which usually means being under 18 or 19
                  depending on the law where you live, you can only use our
                  Platform or Services with your parent’s or guardian’s
                  permission.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>4.4. Keeping Your Account Safe</strong>: You are
                  responsible for keeping your password and account information
                  confidential. Do not share your password with anyone. If you
                  think someone else has used your Account without your
                  permission, you should tell us right away. Also, always
                  remember to log out of your Account when you’re done using it,
                  especially if you’re on a computer that others can use too. If
                  you don’t keep your Account safe and comply with these Terms
                  of Service, Prabalta won’t be responsible for any losses that
                  you may incur. For more information on how to keep your
                  account safe, visit our Account security page.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>4.5. Mobile Services and Text Messages</strong>: The
                  Services include certain features that may be made available
                  via a mobile device, including the ability to: (i) upload User
                  Content to the Platform; (ii) browse the Platform; and (iii)
                  access certain items through an application downloaded and
                  installed on a mobile device (collectively, the “Mobile
                  Services”). To the extent you access Mobile Services, your
                  wireless service carrier’s standard charges, data rates and
                  other fees may apply. In addition, downloading, installing, or
                  using certain Mobile Services may be prohibited or restricted
                  by your carrier, and not all Mobile Services may work with all
                  carriers or devices. By using the Mobile Services, you agree
                  that we may communicate with you about matters related to your
                  Account or security by SMS, MMS, text message or other
                  electronic means to your mobile device and that certain
                  information about your usage of the Mobile Services may be
                  communicated to us. Further, when setting up your Prabalta
                  Account, if you click “Send code” by “Text Message,” you agree
                  to receive automated and non automated text messages related
                  to your Account from or on behalf of Prabalta at the phone
                  number provided. You can reply STOP to such text messages to
                  cancel, except for automated text messages related to the
                  security of your Account. Message frequency will vary. Message
                  and normal data rates may apply. We will comply with any
                  additional requirements that may apply under local laws and
                  regulations before communicating with you in this manner. In
                  the event that you change or deactivate your mobile telephone
                  number, you agree to promptly update your Prabalta Account
                  information to ensure that your messages are not sent to the
                  person that acquires your old number.
                </p>
              </section>

              <section id="paymentProcessor">
                <h2 className="text-2xl font-extrabold mb-4">
                  5. Payment Processors
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Prabalta itself does not hold any funds raised on our
                  Platform, nor does it handle the actual processing of
                  payments. Instead, we use third-party payment processors to
                  manage and process all donations for Fundraisers. To Transfer
                  funds from a Fundraiser, you must provide your bank account
                  details to our payment processor partners.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  {/* By making a Donation, setting up a Fundraiser or accepting the
                  role of Beneficiary to a Fundraiser, you agree to the
                  processing, use, Transfer or disclosure of data by the Payment
                  Processors pursuant to these Terms of Service as well as any
                  and all applicable terms set forth by the applicable Payment
                  Processors. Our current Payment Processors include: Adyen LLC
                  (Adyen’s terms and conditions), Stripe, Inc. (Stripe’s terms
                  of service), and PayPal, Inc. (PayPal’s terms of service), and
                  PayPal Giving Fund terms. Our current payment processor for
                  Organizers and Beneficiaries located in the EEA, Switzerland
                  and the UK is Adyen N.V. which contracts with Prabalta
                  Ireland, Ltd. Please see Adyen N.V.’s terms and conditions
                  here. Prabalta’s payment partner in Australia is Adyen
                  Australia. Adyen Australia contracts with Prabalta’s
                  Australian entity, Prabalta Australia Pty Ltd (ACN 627 702
                  630), is the contracting party responsible for transactions
                  benefitting the Australian User. For more information, please
                  see Combined Financial Services Guide and Product Disclosure
                  Statement. */}
                  By making a Donation, setting up a Fundraiser or accepting the
                  role of Beneficiary to a Fundraiser, you agree to the
                  processing, use, Transfer or disclosure of data by the Payment
                  Processors pursuant to these Terms of Service as well as any
                  and all applicable terms set forth by the applicable Payment
                  Processors.  Our current Payment Processors include: Adyen LLC
                  (
                  <a
                    href="https://www.adyen.com/legal/terms-and-conditions-adyen-for-platforms/"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    Adyen’s terms and conditions
                  </a>
                  ), Stripe, Inc. (
                  <a
                    href="https://stripe.com/us/legal"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    Stripe’s terms of service
                  </a>
                  ), and PayPal, Inc. (
                  <a
                    href="https://www.paypal.com/ga/webapps/mpp/ua/legalhub-full"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    PayPal’s terms of service
                  </a>
                  ), and{" "}
                  <a
                    href="https://www.paypal.com/us/webapps/mpp/givingfund/policies/donor-terms-of-service"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    PayPal Giving Fund terms
                  </a>
                  . Our current payment processor for Organizers and
                  Beneficiaries located in the EEA, Switzerland and the UK is
                  Adyen N.V. which contracts with GoFundMe Ireland, Ltd.  Please
                  see Adyen N.V.’s terms and conditions{" "}
                  <a
                    href="https://www.adyen.com/legal/terms-and-conditions-adyen-for-platforms/"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    here
                  </a>
                  . Prabalta’s payment partner in Australia is Adyen Australia.
                  Adyen Australia contracts with GoFundMe’s Australian entity,
                  GoFundMe Australia Pty Ltd (ACN 627 702 630), is the
                  contracting party responsible for transactions benefitting the
                  Australian User. For more information, please see{" "}
                  <a
                    href="https://www.adyen.com/legal/combined-financial-services-guide"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    Combined Financial Services Guide and Product Disclosure
                    Statement
                  </a>
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  For more details, including how we handle data with these
                  third party payment processors, please refer to our Privacy
                  Notice, Adyen LLC (Adyen’s terms and conditions), Stripe, Inc.
                  (Stripe’s terms of service), and PayPal, Inc. (PayPal’s terms
                  of service).
                </p>
              </section>

              <section id="userResponsibility">
                <h2 className="text-2xl font-extrabold mb-4">
                  6. User Responsibilities and Obligations
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>6.1. Organizers</strong>: As an Organizer, you
                  represent and warrant that all the information you provide
                  (whether directly, or through an agent or by using artificial
                  intelligence) about your Fundraiser is accurate, complete, and
                  clear. You are responsible for describing on your Fundraiser
                  how funds will be used, and ensuring the funds raised are only
                  used for that specific purpose. You may post updates to your
                  Fundraiser so Donors know how their money is being used, and
                  any other relevant information. If you are raising funds on
                  behalf of someone else, you must ensure that all funds raised
                  are given to or spent on behalf of that Beneficiary. By adding
                  a Beneficiary, you relinquish control of the donations for
                  your Fundraiser to the Beneficiary. When you organize a
                  Fundraiser, you agree to follow all laws and regulations
                  related to your Fundraiser, including but not limited to those
                  about taxes and donations. If you are using personal data from
                  anyone, including but not limited to their name, image or
                  likeness, you must have their valid legal permission to share
                  it with us and post it on the Services. You also agree not to
                  provide or offer to provide goods or services in exchange for
                  donations. We may share information about your Fundraiser with
                  Donors, the Beneficiary, legal authorities, and as otherwise
                  described in our{" "}
                  <a href="/privacy" className="text-blue-500 hover:underline">
                    Privacy Notice
                  </a>
                  .
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(a) Maintaining Accurate Information</strong>: Keeping
                  your registration details accurate and current is essential.
                  This includes updating your name, address, and any images or
                  videos you use to represent yourself, your organization, or
                  your NPO. This helps maintain transparency and trust with
                  Donors and ensures compliance with these Terms of Service and
                  applicable legal requirements.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(b) You Agree to Cooperate</strong>: When you organize
                  a Fundraiser on Prabalta, you agree to fully cooperate with
                  any request for evidence that we deem necessary to verify your
                  compliance with these Terms of Service. Our requests may
                  include, but are not limited to, asking that you: (a) explain
                  how funds were or will be handled; (b) supply documentary
                  evidence of the circumstances described on your Fundraiser;
                  (c) share the identity of any party receiving, benefitting
                  from, or involved with handling all or any portion of the
                  funds; (d) supply proof of how funds were or will be used; or
                  (e) supply evidence that the intended third party Beneficiary
                  consents to a plan for distribution of funds consistent with
                  the description of your Fundraiser. We reserve the right to
                  refuse, condition, suspend, freeze or ban any Donations,
                  Accounts, Fundraisers, Transfers or other transactions that we
                  believe in our sole discretion may violate these Terms of
                  Service or harm the interests of our Users, business partners,
                  the public, or Prabalta, or that expose you, Prabalta, or
                  others to risks unacceptable to us.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>6.2. Donors</strong>: When you donate money on
                  Prabalta, it’s your responsibility to understand how your
                  donation will be used, and you donate at your own risk. Make
                  sure to regularly check the Fundraiser’s page for any updates
                  or new information. We are not responsible for what Organizers
                  promise or offer in their Fundraisers. We are also not
                  responsible for verifying information that appears on
                  Fundraisers, nor do we guarantee that Donations will be used
                  in accordance with any fundraising purpose prescribed by a
                  User or Fundraiser. However, we take all reports of fraud or
                  misuse of funds very seriously, and we will take appropriate
                  action against any Fundraiser or User that violates our Terms
                  of Service.
                  {/* We also protect Donors through the Prabalta Giving
                  Guarantee. */}
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(a) Making Donations</strong>: When you donate to a
                  Fundraiser or NPO through Prabalta, you need to use a credit
                  card or other payment method that is linked to your Account.
                  You represent and warrant that your payment information is
                  correct and that you are legally authorized to use your
                  payment method. There may be a minimum amount you can donate,
                  and once you donate your money will not be refunded unless
                  Prabalta decides to give a refund in special cases like under
                  the Prabalta Giving Guarantee. We use third party payment
                  processors to process your donation. As explained further in
                  Section 5 “Payment Processors”, by donating, you also agree to
                  allow our payment processors to handle your payment
                  information according to their rules and these Terms. For
                  details on the companies we use for processing payments and
                  their rules, please check the “Payment Processors” section
                  above.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(b) Recurring Donations:</strong>: By opting into
                  recurring donations, you authorize Prabalta and its vendors to
                  charge your designated payment method without further
                  authorization from you. You agree that the donation amount you
                  specify will be charged to your payment method at the
                  frequency you select (e.g., monthly) until the earlier of (1)
                  the Fundraiser ceasing to be a valid, active Fundraiser or (2)
                  as soon as reasonably practical, after you elect to cancel
                  this recurring donation.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-8">
                  (i)You may cancel your recurring donation at any time by
                  contacting our Support Team or through the cancellation
                  feature in your account as part of Services. Cancellation will
                  take effect promptly upon receipt of your cancellation
                  request, and no further donations will be processed after the
                  cancellation date. However, any donations processed prior to
                  your cancellation will not be refunded unless due to other
                  reasons such as the fundraiser not being valid.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong> (c) Refunds and Disputes</strong>: If you believe
                  that an error has occurred in any of your donations, please
                  contact us immediately so we can help resolve the issue. Any
                  fraud disputes or chargebacks initiated with your payment
                  provider may be contested by us on the basis of this
                  authorization.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(d) Reporting Concerns</strong>:We take any reports of
                  fraud or misuse of funds very seriously.
                  {/* You can learn more
                  about how we handle fraud and misuse by visiting our page on
                  How We Protect Our Community.  */}
                  If you have reason to believe that a User or Fundraiser is not
                  raising or using the funds for their stated purpose, please
                  use the “Report” button that appears at the bottom of every
                  Fundraiser to alert our team of this potential issue and we
                  will investigate.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(e) No Restrictions When You Donate to NPOs</strong>
                  :If you donate to an NPO you can’t decide exactly how your
                  donation is used. Even if you donate for a specific project or
                  tell the NPO how you’d like your donation used, these
                  instructions are just suggestions. The NPO has the final say
                  and can use all donations at their discretion.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(f) Tax Implications of Donating to NPOs</strong>:
                  When you donate to an NPO, you should talk to a tax advisor to
                  understand if your donation is tax-deductible. Prabalta
                  doesn’t withhold funds for tax purposes or otherwise, nor does
                  Prabalta guarantee that your donations are tax-deductible or
                  eligible for tax credits. Prabalta will also not be liable for
                  any claims or penalties assessed by any tax authorities
                  regarding how your donation is reported by you or a third
                  party.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>
                    (g) We share your Information with NPOs you Donate to
                  </strong>
                  : In certain cases, we may share your personal information
                  (even if you’ve chosen to donate anonymously), with the NPO to
                  which you donate, in accordance with our Privacy Notice. The
                  NPO may use this information only for compliance and
                  transactional purposes such as providing a donation receipt
                  unless you expressly provide appropriate consent during the
                  Donor checkout flow to allow the NPO to reach out to you.
                  Prabalta isn’t responsible for how the NPO uses your
                  information.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>6.3. NPOs</strong>: If you’re using the service to
                  fundraise as an agent for an NPO or claiming or managing an
                  account for an NPO on our Services, you represent and warrant
                  that you are authorized to do so and will follow all
                  applicable laws. We may seek additional verification to
                  confirm that you are authorized to act on behalf of the NPO as
                  needed. Your NPO must be legally recognized as and continue to
                  remain tax-exempt and registered with the appropriate
                  authorities, like the IRS in the United States or the Canada
                  Revenue Agency in Canada.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(a) Refunds via PayPal or PayPal Giving Fund</strong>
                  :If a Donor gets a refund of their donation and if the PayPal
                  Giving Fund has already transferred the donation to the NPO,
                  the refunded amount will be deducted from any future payments
                  to the NPO. If necessary, an invoice may be issued to the
                  applicable NPO regarding the refunded amount. The NPO agrees
                  to pay back the full amount of any refunded donation to
                  Prabalta, PayPal or the PayPal Giving Fund. PayPal or the
                  PayPal Giving Fund might also elect to reduce a future
                  donation amount instead of asking the NPO to return the money.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>
                    (b) Adding or Claiming your NPO on the Services
                  </strong>
                  : Our Services may include public-facing pages providing
                  information about NPOs and an authorized representative from
                  the NPO will have the right to claim these pages and manage
                  their presence on the Services.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong> (c) Removing your NPO from Prabalta</strong>: If
                  you’re an authorized representative of an NPO and you don’t
                  want your NPO listed on Prabalta, you can ask us to remove
                  your NPO from our database. Please send us an email to{" "}
                  <a
                    href="mailto: support@prabalta.org"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    support@prabalta.org
                  </a>{" "}
                  with your full name, your job title, and your NPO’s email and
                  phone number. Keep in mind, if we remove your NPO from our
                  database, it won’t be able to receive donations through
                  Prabalta anymore.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(d) Receiving Funds</strong>: As an NPO, when you get
                  donations, the total amount you actually receive will be
                  reduced by the amount of our Transaction Fees, and subject to
                  the applicable Payment Processor’s procedures and terms.
                  Prabalta isn’t the one processing your payments or holding
                  onto your funds.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(e) Payment Processors for NPOs</strong>
                  :Prabalta has partnered with PayPal and, in limited
                  circumstances, Adyen or Stripe, for Donations to NPOs.
                  Although exceptions can be made, NPOS in the United States,
                  United Kingdom, Ireland, Canada and Australia will be
                  defaulted to using the PayPal Giving Fund to process Donations
                  made through the Services. The manner in which transactions
                  are processed is explained below. NPOs in other countries will
                  be defaulted to using Adyen or Stripe.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(f) PayPal Giving Fund</strong>:In the United States,
                  Donations to support Certified NPOs will be made to PayPal
                  Giving Fund, a grant-giving IRS-registered 501(c)(3) public
                  charity. PayPal Giving Fund receives Donations from Users as
                  the NPO of record and then, in accordance with its policies,
                  grants Funds to the NPO selected by the Organizer and
                  identified in the Fundraiser. While PayPal Giving Fund takes
                  multiple steps to attempt to grant donated funds according to
                  the Donor’s identified preference, PayPal Giving Fund retains
                  exclusive control over all Donations. If a Certified NPO fails
                  to meet the terms of{" "}
                  <a
                    href="https://www.paypal.com/us/webapps/mpp/givingfund/policies/nonprofit-certification-policy"
                    className="text-blue-500 underline hover:underline"
                  >
                    PayPal Giving Fund’s Nonprofit Certification Policy
                  </a>{" "}
                  and/or fails to meet PayPal Giving Fund’s due diligence
                  standards for receipt of a grant of donated funds at any time,
                  PayPal Giving Fund may reassign the funds in accordance with
                  its{" "}
                  <a
                    href="https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy"
                    className="text-blue-500 underline hover:underline"
                  >
                    Donation Delivery Policy
                  </a>
                  . When PayPal Giving Fund receives your Donation, it will
                  grant the Donation to the NPO of your choice, but PayPal
                  Giving Fund will provide your tax receipt. Donations made to
                  PayPal Giving Fund are governed by PayPal Giving Fund’s{" "}
                  <a
                    href="https://www.paypal.com/us/webapps/mpp/givingfund/policies/privacy-policy"
                    className="text-blue-500 underline hover:underline"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.paypal.com/us/webapps/mpp/givingfund/policies/donor-terms-of-service"
                    className="text-blue-500 underline hover:underline"
                  >
                    Donor Terms of Service
                  </a>
                  .  PayPal Giving Fund’s entities outside the United States are
                  also registered NPOs in their countries. For example, if an
                  Organizer in the United Kingdom starts a Fundraiser for an NPO
                  in the United Kingdom, then PayPal will act as the Payment
                  Processor with PayPal Giving Fund UK receiving the Donations. 
                  Thereafter, PayPal Giving Fund UK will grant funds to the NPO
                  identified in the Fundraiser in accordance with its policies,
                  and it will issue a tax receipt for all Donations received
                  through it.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>
                    6.4. Tax Responsibilities for Organizers, NPOs and
                    Beneficiaries
                  </strong>
                  : We do not withhold funds for tax purposes or otherwise. You,
                  as an Organizer, Beneficiary, or NPO, are solely responsible
                  for paying any applicable taxes in connection with any
                  donations you receive. It’s up to you to calculate, report,
                  and pay the correct amount of tax to the tax authorities.
                </p>
              </section>

              <section id="transferAndhold">
                <h2 className="text-2xl font-extrabold mb-4">
                  7. Transfers, Holds & Chargebacks
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>7.1. Transfers</strong>: While we strive to make
                  Transfers available to you promptly, our ability to do so is
                  dependent upon Users providing the right information and upon
                  our technical systems operating as intended. You acknowledge
                  and agree that: (i) Transfers may not be available to you for
                  use immediately; (ii) we do not guarantee that Transfers will
                  be always available to you within any specific time frame, but
                  we will use commercially reasonable efforts to provide
                  Transfers as soon as reasonably possible; (iii) you will
                  cooperate with any request we make for evidence we deem
                  necessary to verify your compliance with these Terms of
                  Service; and (iv) to the extent permitted by applicable law,
                  we expressly disclaim any and all responsibility for any delay
                  in Transfers or your inability to access and use donated funds
                  at any specified time, and any consequences arising from such
                  delay or inability. We will use commercially reasonable
                  efforts to let you know when you can expect to receive the
                  Transfer and to provide a clear timeframe whenever possible.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(a) Maintaining Accurate Information</strong>: Keeping
                  your registration details accurate and current is essential.
                  This includes updating your name, address, and any images or
                  videos you use to represent yourself, your organization, or
                  your NPO. This helps maintain transparency and trust with
                  Donors and ensures compliance with these Terms of Service and
                  applicable legal requirements.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(a) You must provide accurate info</strong>: You, as
                  an Organizer and/or Beneficiary, are responsible for (i)
                  verifying your personal information and bank account
                  information under “Set up transfers” as soon as possible; and
                  (ii) ensuring that the information you provide to Prabalta
                  and/or its Affiliates in order to process a Transfer,
                  including bank account information, is accurate and up to
                  date.{" "}
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(b) Refunds</strong>:We may, without notice and in our
                  sole discretion, offer or issue a refund of Donation(s), which
                  may comprise the entire amount donated to your Fundraiser. To
                  the extent permitted by applicable law, we are not liable to
                  you or to any third party for any claims, damages, costs,
                  losses, or other consequences caused by us issuing refunds,
                  including, but not limited to transaction or overdraft fees.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(c) Transfer Funds within 120 Days:</strong>:Our
                  payment processors are not able to hold funds indefinitely. If
                  you do not Transfer your donations to your bank account within
                  one hundred and twenty (120) days of your first donation, our
                  payment processors may, in accordance with relevant laws and
                  regulations, refund or escheat any funds raised. You will be
                  responsible for working with the appropriate governmental
                  authority to claim any escheated funds.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>7.2. Transfer Holds</strong>: We may, in our sole
                  discretion, place a hold on a Fundraiser, restrict Transfers,
                  initiate a reverse ACH Transfer, secure reserves, or take
                  similar actions to protect our Users (any of these actions may
                  be referred to as a “Hold”). We may put a Hold on your
                  Fundraiser or Account for several reasons, including but not
                  limited to:
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  (a) We need more information to verify that your Fundraiser
                  complies with our Terms of Service, or we have determined that
                  a Fundraiser or User has violated our Terms of Service;
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  (b) Our determination that the funds should be provided
                  directly to a person other than the Organizer, such as a legal
                  Beneficiary or person entitled by law to act on behalf of an
                  Organizer;
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  (c) We have identified activity on your Fundraiser that
                  introduces risks unacceptable to us;
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  (d) Such action is required to comply with a court order,
                  injunction, writ, or as otherwise required under applicable
                  law and regulations.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed ">
                  If you have questions about a Hold we may have placed on your
                  Fundraiser or Account, or need information about how to
                  resolve the Hold, please see this article and check the email
                  account used to register for additional information.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>7.3. Payment Processor Chargebacks</strong>
                  :Occasionally, a Donor may dispute a credit card charge for a
                  Donation through the Services or submit a request for a refund
                  under the Prabalta Giving Guarantee. In situations where the
                  Cardholder is not alleging that a fraudulent donation has been
                  made (i.e. that the transaction was not made by the
                  cardholder), the Cardholder should always attempt to resolve
                  the dispute with the Organizer or with us before pursuing a
                  chargeback.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We will review each chargeback request to determine whether
                  the chargeback is legitimate, and if we determine a chargeback
                  is not legitimate, we may use information submitted by you
                  and/or at our disposal at the time of donation to defend that
                  chargeback. Further, we will defend any non-fraud chargeback
                  with such reason codes as Merchandise / Services Not Received
                  or any other code that suggests non-delivery, as transactions
                  processed on Prabalta are donations without the expectation or
                  exchange of goods or services.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  If a Donor disputes their transaction with their card issuer,
                  or if the card issuer disputes the transaction on behalf of
                  the cardholder and it becomes a chargeback, the Donor
                  surrenders, without limitation, any benefits or protections of
                  the Prabalta Giving Guarantee related to that Donation.
                </p>
              </section>

              <section id="prohibitedFund">
                <h2 className="text-2xl font-extrabold mb-4">
                  8. Prohibited Fundraisers and Related User Content
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  This Section includes our rules about prohibited and/or
                  illegal Fundraisers and User Content. We may remove any User
                  Content–including any Fundraisers–that we determine violates
                  these Terms of Service. Further, if you violate these Terms of
                  Service, we may ban or disable your use of the Services, stop
                  payments to any Fundraiser, freeze or place a hold on
                  donations and Transfers, report you to law enforcement
                  authorities, or take any other appropriate legal action.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We may investigate a Fundraiser, a User or User Content at any
                  time to ensure compliance with these Terms of Service. In
                  doing so, we may consider all available material, including
                  but not limited to social media, related news, and any other
                  information that we deem relevant in our review. Please note
                  that while we reserve the right to remove, edit, or modify any
                  content on our Platform at our sole discretion, we are not
                  obligated to do so. This includes content that is illegal,
                  inaccurate, misleading, infringes on intellectual property
                  rights, or violates these Terms of Service.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed ">
                  You agree that you will not use the Services or Platform to
                  raise funds or establish any Fundraiser for the purposes of
                  promoting or involving:
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.1.</strong> The violation of any law, regulation,
                  industry requirement, or third-party guidelines or agreements
                  by which you are bound, including those of payment card
                  providers that are utilized in connection with the Services;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.2.</strong> Fundraisers that are fraudulent,
                  misleading, inaccurate, dishonest, or impossible;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.3.</strong> Offensive, graphic, perverse, or sexual
                  content;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.4.</strong> The funding of a ransom, human
                  trafficking or exploitation, vigilantism, bribes, or bounty;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.5.</strong> Purchase or use by an organization or
                  individual of drugs, narcotics, steroids, controlled
                  substances, pharmaceuticals or similar products or therapies
                  that are either illegal or prohibited at the state or national
                  level;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.6.</strong> Activities with, in, or involving
                  countries, regions, governments, persons, or entities that are
                  subject to U.S. and other economic sanctions under applicable
                  law, unless such activities are expressly authorized by the
                  appropriate governmental authority and by our payment service
                  providers;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.7.</strong> Any equipment or weapons meant for use
                  in conflict or by an armed group, explosives, ammunition,
                  firearms, knives, or other weaponry or accessories;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.8.</strong> Any activity in support of terrorism,
                  extremism, hate, violence, harassment, bullying,
                  discrimination, terrorist financing, extremist financing, or
                  money laundering;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.9.</strong> User Content that reflects, incites, or
                  promotes bullying, harassment, discrimination, or intolerance
                  of any kind relating to race, ethnicity, national origin,
                  religious affiliation, sexual orientation, sex, gender, gender
                  identity, gender expression, disabilities, or diseases;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.10.</strong> The legal defense of financial and
                  violent crimes, including those related to money laundering,
                  murder, robbery, assault, battery, sex crimes, or crimes
                  against minors;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.11.</strong> User Content that promotes self-harm or
                  suicide except as permitted by law in a clinical setting;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.12.</strong> Gambling, gaming, and/or any other
                  activity with an entry fee and a prize including, but not
                  limited to raffles, casino games, sports betting, fantasy
                  sports, horse or greyhound racing, lottery tickets, raffle
                  tickets, auctions, and other ventures that facilitate
                  gambling, games of skill or chance (whether or not it is
                  legally defined as a lottery), promotions involving rewards
                  (monetary or otherwise) in exchange for donations, including
                  event tickets, raffle entries, meet-and-greet opportunities,
                  gift cards, or sweepstakes;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.13.</strong> Any activity that disguises, conceals,
                  or otherwise obscures the origin of funds;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.14.</strong> Annuities, investments with the
                  expectation of a return, loans, equity or lottery contracts,
                  lay-away systems, off-shore banking or similar transactions,
                  money service businesses (including currency exchanges, check
                  cashing, or the like), pyramid schemes, “get rich quick
                  schemes” (i.e., investment opportunities or other services,
                  that promise high rewards), network marketing and referral
                  marketing programs, debt collection or cryptocurrencies;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.15.</strong> The receipt or grant of cash advances
                  or lines of credit to yourself or to another person for any
                  reason, including but not limited to self-payments, or
                  payments for which there is no apparent purpose;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.16.</strong> Products or services that directly
                  infringe or facilitate infringement upon the trademark,
                  patent, copyright, trade secrets, or proprietary or privacy
                  rights of any third party, including but not limited to
                  counterfeit music, movies, software, or other licensed
                  materials without the appropriate authorization from the
                  rights holder;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.17.</strong> The sale or resale of goods or
                  services;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.18.</strong> Electoral fundraising unless the
                  Fundraiser is directly managed by the candidate or their
                  committee; any election Fundraiser in an unsupported country
                  unless run by a registered organization within a supported
                  country;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.19.</strong> Any attempt to bypass or otherwise
                  circumvent payment processing rules and regulations, or these
                  Terms of Service;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.20.</strong> Any activity that presents Prabalta
                  with an unacceptable risk of financial loss;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>8.21.</strong> Any other activity that Prabalta may
                  deem, in its sole discretion, to: (a) be unacceptable or
                  objectionable; (b) restrict or inhibit any other person from
                  using or enjoying the Services; or (c) expose Prabalta, its
                  employees or Users to any harm or liability of any type.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  Please note that if you are located in the United Kingdom, you
                  will be permitted to offer give-away incentives (e.g., one
                  sticker, while supplies last, for every donation made) in
                  connection with Your Fundraiser. Otherwise, You are not
                  permitted to offer any contest, competition, reward,
                  give-away, raffle, sweepstakes or similar activity (each, a
                  “Promotion”) on or through the Services.
                </p>
              </section>

              <section id="prohibitedUser">
                <h2 className="text-2xl font-extrabold mb-4">
                  9. Prohibited User Conduct
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  This Section includes our rules about User Conduct that is
                  prohibited and/or illegal. We may remove any User
                  Content–including any Fundraisers–if we determine that a
                  relevant User has engaged in User Conduct that violates these
                  Terms of Service. Further, if you violate these Terms of
                  Service, we may ban or disable your use of the Services, stop
                  payments to any Fundraiser, freeze or place a hold on
                  donations and Transfers, report you to law enforcement
                  authorities, or otherwise take appropriate legal action.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  By using the Services or our Platform, you agree:
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed ">
                  You agree that you will not use the Services or Platform to
                  raise funds or establish any Fundraiser for the purposes of
                  promoting or involving:
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong> 9.1.</strong> Not to use the Services to transmit or
                  otherwise upload any User Content that: (i) infringes any
                  intellectual property or other proprietary rights of any
                  party; (ii) you do not have a right to upload under any law or
                  under contractual or fiduciary relationships; (iii) contains
                  software viruses or any other computer code, files or programs
                  designed to interrupt, destroy or limit the functionality of
                  any computer software or hardware or telecommunications
                  equipment; (iv) poses or creates a privacy or security risk to
                  any person; or (v) constitutes unsolicited or unauthorized
                  advertising, promotional materials, commercial activities
                  and/or sales, “junk mail,” “spam,” “chain letters,” “pyramid
                  schemes,” “contests,” “sweepstakes,” or any other form of
                  solicitation;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong> 9.2.</strong> Not to interfere with or disrupt
                  servers or networks connected to or used to provide the
                  Services or their respective features, or disobey any
                  requirements, procedures, policies or regulations of the
                  networks connected to or used to provide the Services;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong> 9.3.</strong> Not to harvest, collect, scrape or
                  publish personally identifiable information of others;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong> 9.4.</strong> Not to raise funds for a minor without
                  the express permission of the minor’s guardian unless the
                  funds are Transferred into a trust, UTMA or UGMA account for
                  the sole benefit of the minor;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong> 9.5.</strong> Not to use the Services on behalf of a
                  third party or post any personal data or other information
                  about a third party, without the express consent of that third
                  party;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong> 9.6.</strong> Not to use another User’s Account or
                  URL without permission, impersonate any person or entity,
                  falsely state or otherwise misrepresent your affiliation with
                  a person or entity, misrepresent an NPO or Fundraiser through
                  the Services, or post User Content in any inappropriate
                  category or areas on the Services;
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.7.</strong> Not to create any liability for Prabalta
                  or cause us to lose (in whole or in part) the services of our
                  Internet Service Provider(s), web hosting company or any other
                  vendors or suppliers;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.8.</strong> Not to gain unauthorized access to the
                  Services, or any account, computer system, or network
                  connected to the Services, by any unauthorized or illegal
                  means;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.9.</strong> Not to obtain or attempt to obtain any
                  materials or information not intentionally made available
                  through the Services;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.10.</strong> Not to use the Services to post,
                  transmit or in any way exploit any information, software or
                  other material for commercial purposes, or that contain
                  advertising, except that using the Services for fundraising
                  activities in accordance with these Terms of Service is
                  expressly permitted;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.11.</strong> Not to transmit more request messages
                  through the Services in a given period of time than a human
                  can reasonably produce in the same period by using a
                  conventional online web browser;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.12.</strong> Not to undertake any activity or engage
                  in any conduct that is inconsistent with the business or
                  purpose of the Services;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.13.</strong> Not to share your password or login
                  credentials with anyone for any reason;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.14.</strong> Not to make or accept any Donations
                  that you know or suspect to be erroneous, suspicious, or
                  fraudulent;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.15.</strong> Not to use the Services in or for the
                  benefit of a country, organization, entity, or person
                  embargoed or blocked by any government, including those on
                  sanctions lists identified by the United States Office of
                  Foreign Asset Control (OFAC), nor as applicable in your
                  country;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.16.</strong> Not to attempt to undertake indirectly
                  any of the foregoing.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.17.</strong> To maintain reasonable and standard
                  security measures to protect any information transmitted and
                  received through the Services, including without limitation,
                  adhering to any security procedures and controls required by
                  Prabalta from time to time;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.18.</strong> To maintain a copy of all electronic
                  and other records related to Fundraisers and Donations as
                  necessary for Prabalta to verify compliance with these Terms
                  of Service and make such records available to Prabalta upon
                  our request. For clarity, the foregoing does not affect or
                  limit your obligations to maintain documentation as required
                  by applicable laws, rules, regulations, or governmental
                  authority;
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>9.19.</strong> At Prabalta’s request, to cooperate as
                  far as reasonably possible and permitted under applicable law,
                  in the auditing of, investigation of (including without
                  limitation, investigations by Prabalta, a Payment Processor,
                  or a regulatory or governmental authority), and remedial
                  efforts to correct any alleged or uncovered violation or
                  wrongdoing of a User to whom, or Fundraiser or Donation to
                  which, you are connected.
                </p>
              </section>

              <section id="contentModeration">
                <h2 className="text-2xl font-extrabold mb-4">
                  10. Content Moderation and Reporting Fundraisers
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  If you have reason to believe that a Fundraiser contains
                  illegal content or content that violates these Terms of
                  Service or our content moderation policies, please use the
                  “Report” button on the Fundraiser to alert our team of this
                  potential issue and we will investigate. If you disagree with
                  a decision taken by us in connection to a notice or complaint
                  related to User Content and/or use of the Platform that is
                  illegal or prohibited by Prabalta, you may use our available
                  dispute resolution systems.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  We leverage a combination of business rules, machine learning,
                  and human review to identify and remediate violations of our
                  Terms of Service. Additional information related to how we
                  moderate User Content, how we protect individuals from illegal
                  content, the process for reporting or appealing violations,
                  the process for handling and resolution of complaints and the
                  dispute resolution systems available can be found in our Help
                  Center. You acknowledge that rules in this section concerning
                  content moderation are without prejudice to
                  policies/procedures available in our Help Center regulating
                  how we moderate User Content, how we protect individuals from
                  illegal content, the process for reporting or appealing
                  violations, the process for handling and resolution of
                  complaints and the dispute resolution systems available.
                </p>
              </section>

              <section id="fees">
                <h2 className="text-2xl font-extrabold mb-4">11. Fees</h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>11.1. We Don’t Charge a Platform Fee</strong>: A
                  Platform fee is an upfront, calculated charge, either fixed or
                  percentage-based, for accessing or using a specific service on
                  a Platform. We do not charge a Platform fee, and we do not
                  charge to start or maintain a Fundraiser. However, a
                  Transaction Fee does apply to donations received and, if
                  donors choose recurring donations, we charge donors a
                  Recurring Fee on each donation.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>
                    11.2. A Transaction Fee Is Charged on All Donations and A
                    Recurring Fee is Charged for Recurring Donations
                  </strong>
                  :A Transaction Fee is the cost of processing a payment.
                  Although there are no Platform fees to start or maintain a
                  Fundraiser, please keep in mind that a Transaction fee,
                  including credit and debit charges, is deducted from each
                  donation by our Payment Processors to securely deliver your
                  donations. If donors choose recurring donations, we charge
                  donors a Recurring Fee per donation to the selected
                  Fundraiser.
                  {/* To learn more about our fees, please visit
                  Prabalta Pricing. */}
                </p>
              </section>
              <section id="propertyRights">
                <h2 className="text-2xl font-extrabold mb-4">
                  12. Intellectual Property Rights, Content Ownership and
                  Licenses
                </h2>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>12.1. Ownership and intellectual property:</strong>{" "}
                  You acknowledge that the Services Content are protected under
                  laws related to copyright, patent, trademark, and other
                  proprietary rights. The technology and Software that support
                  the Services, or are distributed in connection with them, are
                  owned by Prabalta, our Affiliates, and our partners.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>12.2. Use of Prabalta Trademarks:</strong> The
                  Prabalta name and logos are trademarks of Prabalta and its
                  Affiliates (collectively the “Prabalta Trademarks”). Other
                  company, product, and service names and logos displayed on our
                  Services or Platform may be trademarks of their respective
                  owners, who may or may not be affiliated with us. Nothing in
                  these Terms of Service or our Services gives you permission to
                  use any Prabalta Trademarks without our prior written consent.
                  All goodwill generated from the use of Prabalta Trademarks
                  benefits us exclusively.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>
                    12.3. Do Not Misappropriate Content on our Site:
                  </strong>{" "}
                  You agree not to alter, copy, frame, scrape, rent, lease,
                  loan, sell, distribute, or create derivative works based on
                  the Services or the Services Content. You agree not to use any
                  data mining, spiders, robots, scraping, or similar data
                  gathering or extraction methods to extract or copy any of the
                  Services Content in any form or otherwise in connection with
                  your use of the Services. If we block your access to the
                  Services (including blocking your IP address), you agree not
                  to bypass this blocking (for example, by masking your IP
                  address or using a proxy IP address). Any rights not expressly
                  granted herein by us are reserved.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>12.4. Do Not Misappropriate our Software:</strong> You
                  are prohibited from copying, modifying, creating derivative
                  works of, reverse engineering, disassembling, or attempting to
                  discover any source code of the Software or the Services in
                  any form.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>
                    12.5. Rights and Permissions for User Content You Share:
                  </strong>{" "}
                  When you share content through our Services, you represent and
                  warrant that you either own the content or have permission to
                  use and share it. This includes all related copyrights,
                  trademarks, and rights to privacy or publicity. When you
                  upload, share, or make any User Content available in
                  connection with the Services, you grant Prabalta and its
                  Affiliates the worldwide, royalty-free, transferable,
                  sublicensable, perpetual, irrevocable license to copy,
                  display, distribute, store, modify, publish, prepare
                  derivative works, or otherwise use that User Content for any
                  purpose, including, with your prior consent, for the
                  promotion, advertisement or marketing of our Services in any
                  form of media. You further acknowledge that your participation
                  in the Services and submission of User Content is voluntary
                  and that you will not receive financial compensation of any
                  type associated with the licenses, waivers, and releases set
                  forth herein (or Prabalta’s exploitation thereof), and that
                  the sole consideration for subject matter of this agreement is
                  the opportunity to use the Services.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>
                    12.6. Release and Waiver of Rights in User Content:
                  </strong>{" "}
                  When you post any User Content and to the maximum extent
                  permitted by law, you irrevocably waive any moral rights in
                  your User Content against us and our Users, and agree to
                  release and hold harmless Prabalta, our contractors and our
                  employees from (i) any claims for invasion of privacy,
                  publicity or libel; (ii) any liability from the use of your
                  name, image, or likeness, including blurring, distortion,
                  alteration or other uses; and (iii) any liability for claims
                  made by you related to your User Content, name, image or
                  likeness. By posting User Content, you also waive any right to
                  inspect or approve any intermediary or finished versions of
                  your User Content. If your User Content includes anyone other
                  than yourself, you represent and warrant that you have
                  obtained all necessary permissions, waivers, and releases from
                  those individuals. This ensures that Prabalta can use the
                  content as described above without any legal issues.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>12.7. User Submissions and Feedback:</strong> Any
                  content or information you provide to Prabalta, whether
                  solicited or not, may be publicly accessible. This includes
                  any information you post on forums, in comment sections,
                  surveys, customer support communications, or any other
                  submissions such ideas, suggestions or feedback about the
                  Services (“Submissions and Feedback”). By submitting any
                  Submissions or Feedback, you agree that (i) we have no
                  obligation to keep Submissions or Feedback confidential; (ii)
                  we may already have similar information under consideration or
                  development; (iii) we can use and distribute Submissions and
                  Feedback for any purpose, without acknowledgement or
                  compensation to you; (iv) you have all necessary rights to
                  submit such Submissions and Feedback; (v) you grant Prabalta a
                  perpetual, worldwide, royalty-free, irrevocable,
                  non-exclusive, and fully transferable license to use,
                  reproduce, perform, display, distribute, adapt, modify,
                  re-format, create derivative works of, and otherwise exploit
                  other information, including the right to sublicense these
                  rights; and (vi) You waive any moral rights or equivalent
                  claims to the extent permitted by law. This section remains
                  effective even after your Account or use of the Services is
                  terminated.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>12.8. Copyright or Trademark Complaints:</strong> We
                  respect the intellectual property of others, and we ask that
                  our Users do the same. We will process and investigate notices
                  of alleged copyright or trademark infringement and take
                  appropriate action under the Digital Millennium Copyright Act
                  (“DMCA”) or other locally equivalent intellectual property
                  laws. In our sole discretion, we may terminate the Accounts of
                  any Users who infringe on others’ intellectual property
                  rights.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(a) Takedown Notice</strong>: If you believe that your
                  work appears on our site in a way that constitutes copyright
                  infringement, or that your intellectual property rights have
                  otherwise been violated, you must notify us in writing, as
                  follows:
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  Email our copyright agent at with the{" "}
                  <a
                    href="mailto: support@prabalta.org"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    support@prabalta.org
                  </a>{" "}
                  subject line (“DMCA Takedown Request”), or mail your request
                  to:
                </p>
                <p className=" text-base text-gray-600 mb-8 leading-relaxed md:pl-12 mb-4">
                  If you believe that your work appears on our site in a way
                  that constitutes copyright infringement, or that your
                  intellectual property rights have otherwise been violated, you
                  must notify us in writing, as follows:
                </p>

                <p className=" text-base text-gray-600 mb-8 leading-relaxed md:pl-12  mb-4">
                  Email our copyright agent at{" "}
                  <a
                    href="mailto: support@prabalta.org"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    support@prabalta.org
                  </a>{" "}
                  with the subject line ("DMCA Takedown Request"), or mail your
                  request to:
                </p>
                <address className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12 mb-4">
                  <strong>Prabalta Copyright Agent:</strong>
                  <br />
                  Prabalta
                  <br />
                  3025 Windy Knoll Court
                  <br />
                  Rockville
                  <br />
                  Maryland 20850
                  <br />
                  United States
                </address>

                <h3 className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4 mb-4">
                  To be effective, your Takedown Notice must contain the
                  following information:
                </h3>
                <ul className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12 list-disc list-inside mb-4">
                  <li className="mb-2">
                    Your complete contact information (full name, mailing
                    address and phone number). Please note that we may provide
                    your contact information to the person who posted the
                    content you are reporting. For this reason, you may wish to
                    provide a professional or business email address;
                  </li>
                  <li className="mb-2">
                    A description of the copyrighted work that you claim has
                    been infringed;
                  </li>
                  <li className="mb-2">
                    A description of the contention on our site that you claim
                    infringes your copyright;
                  </li>
                  <li className="mb-2">
                    A description of where the material that you claim is
                    infringing is located on the Services. The easiest way to do
                    this is by providing web addresses (URLs) leading directly
                    to the allegedly infringing content.
                  </li>
                  <li className="mb-2">
                    A declaration that:
                    <ul className="list-inside list-disc ml-6">
                      <li className="mb-1">
                        You have a good faith belief that the disputed use is
                        not authorized by the copyright owner, its agent, or the
                        law;
                      </li>
                      <li className="mb-1">
                        The information in your notice is accurate; and
                      </li>
                      <li className="mb-1">
                        Under penalty of perjury, you are the owner or
                        authorized to act on behalf of the owner of an exclusive
                        copyright that is allegedly infringed.
                      </li>
                    </ul>
                  </li>
                  <li className="mb-2">
                    Your electronic or physical signature.
                  </li>
                </ul>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>(b) Counter-Notice</strong>: If you believe that your
                  User Content that was either removed or disabled is
                  non-infringing or that you have authorization from the owner,
                  the owner’s agent, or pursuant to the law, to upload and use
                  such content, you may send us a counter-notice to our
                  copyright agent at support@prabalta.org or the address listed
                  above.
                </p>

                <h3 className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4 mb-4">
                  To be effective, your counter-notice must include the
                  following information:
                </h3>
                <ul className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12 list-disc list-inside mb-4">
                  <li className="mb-2">
                    Your complete contact information (full name, mailing
                    address and phone number). Please note that we may provide
                    your contact information to the person who complained about
                    your content. For this reason, you may wish to provide a
                    professional or business email address;
                  </li>
                  <li className="mb-2">
                    identification of the content that has been removed or to
                    which access has been disabled and the location at which the
                    content appeared before it was removed or disabled;
                  </li>
                  <li className="mb-2">
                    A description of the contention on our site that you claim
                    infringes your copyright;
                  </li>

                  <li className="mb-2">
                    A declaration that:
                    <ul className="list-inside list-disc ml-6">
                      <li className="mb-1">
                        You have a good-faith belief that the content was
                        removed or disabled as a result of mistake or a
                        misidentification of the content;
                      </li>
                      <li className="mb-1">
                        You consent to the jurisdiction of the Federal District
                        Court for the judicial district in which your address is
                        located. If you are outside the United States, you must
                        consent to the jurisdiction of any Federal District
                        court in any judicial district in which Prabalta
                        operates; and
                      </li>
                      <li className="mb-1">
                        You will accept service of process from or on behalf of
                        the person who provided the DMCA takedown notice, or an
                        agent of such person.
                      </li>
                    </ul>
                  </li>
                  <li className="mb-2">
                    Your electronic or physical signature.
                  </li>
                </ul>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  If we receive a counter-notice, we will send a copy of the
                  counter-notice to the original complaining party, informing
                  that person that we may replace the removed content or cease
                  disabling it in 10 business days. Unless the original
                  complaining party files an action seeking a court order
                  against the content provider or User, the removed content may
                  be replaced, or access to it restored, in 10 to 14 business
                  days or more after receipt of the counter-notice.
                </p>
              </section>
              <section id="dataPrivacy">
                <h2 className="text-2xl font-extrabold mb-4">
                  13. Data Privacy and Artificial Intelligence
                </h2>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>13.1. Privacy Notice:</strong> At Prabalta, we respect
                  the privacy of our Users. For details, please see our Privacy
                  Notice. By using the Services, you acknowledge our collection,
                  use and sharing of personal data as outlined therein.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>13.2. Retention of Fundraiser Data:</strong> We are
                  not obligated to retain data related to any Account or
                  Fundraiser after its conclusion. We may delete historical data
                  or terminate inactive Accounts without notice, except for any
                  data we must keep to comply with legal obligations or to
                  establish, exercise or defend legal claims. When possible, we
                  will try to provide NPOs with reasonable notice before
                  deleting Accounts or data tied to them.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>
                    13.3. Understanding Public Visibility and Privacy Settings:
                  </strong>{" "}
                  Some things you do on our Platform are public, like the posts
                  you make and the material you upload (this could be anything
                  from descriptions, photos, videos, comments, to music and
                  logos). Also, the profile information you provide (like your
                  name, organization, and biography) can be seen by other Users
                  to help people connect within the Service. For example, if
                  you’re organizing a Fundraiser, you might share personal
                  details, such as a hospital stay, which are sensitive. If
                  you’re donating, you can choose to show your donation
                  publicly, meaning anyone on the internet, including search
                  engines like Google, can see it. If you want to keep your
                  donation private, just check the “Don’t display my name
                  publicly on the fundraiser” box when you donate. Keep in mind,
                  the Fundraiser Organizer, their team, the Beneficiary and
                  others will still see your information according to our
                  Privacy Notice.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>13.4. Third-Party Communications:</strong> When using
                  our Services to communicate with third parties (e.g.,
                  referring someone or discussing a Fundraiser or Donation), you
                  (i) confirm you have the authority and necessary consents from
                  the third party to share their data with us and that you have
                  informed them about how their information will be collected
                  and used by Prabalta; and (ii) agree that we may identify you
                  as the person who made the referral in any messages sent to
                  the third party. You also agree that we may use such data to
                  contact the third party or provide you with a template message
                  to facilitate communication and that we may send reminders or
                  related messages to you and the third party.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>13.5. Artificial Intelligence:</strong> We’re
                  constantly developing new technologies and features to improve
                  our Services. For example, we may enable you to use artificial
                  intelligence developed by us and/or our vendors to make it
                  easier for you to write, create assets or other content and
                  promote your use of our Services such as helping you draft
                  posts or create photos or videos to share your campaigns, or
                  for optimizing the use of our Services. Use of such features
                  is optional, offered solely as a convenience to you, and such
                  features are offered on an as-is basis with no warranties of
                  any kind. Any output generated by use of any such Services
                  enabled by artificial intelligence and machine learning is
                  probabilistic and should be evaluated for accuracy as
                  appropriate for your use case, including by employing human
                  review of such output. It is also possible that output through
                  the use of large language models is not always unique across
                  customers and the Services may generate the same or similar
                  results across multiple Users.
                </p>
              </section>
              <section id="thirdPartyService">
                <h2 className="text-2xl font-extrabold mb-4">
                  14. Third-Party Services and Content
                </h2>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>
                    14.1. Other Third Party Websites/Links/Services
                  </strong>
                  : Our Services may rely on, or certain third parties may
                  include in our Services links to, certain Third Party
                  Resources. We have no control over such Third Party Resources
                  and do not endorse or otherwise take any responsibility for
                  such Third Party Resources. By using our Services, you agree
                  that we are not liable for the content, functions, accuracy,
                  or legality of these Third Party Resources, or any damages or
                  loss that may be caused by these Third Party Resources. In
                  certain situations, Third Party Resources may include products
                  or services offered by a third party that you may display or
                  are otherwise made available through the Services and if so,
                  you may be subject to third-party terms associated with such
                  Third Party Resources. Prabalta has relationships with certain
                  providers of such products and services, and we may be paid by
                  such providers in the form of commissions in connection with
                  these products and services.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>14.2. Apple-Enabled Software Applications:</strong>{" "}
                  Prabalta offers Software applications that are intended to be
                  operated in connection with products made commercially
                  available by Apple Inc. (“Apple”), among other platforms. With
                  respect to Software that is made available for your use in
                  connection with an Apple-branded product (such software,
                  “Apple-Enabled Software”), in addition to the other terms and
                  conditions set forth in these Terms of Service, the following
                  terms and conditions apply:
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  (a) Prabalta and you acknowledge that these Terms of Service
                  are concluded between Prabalta and you only, and not with
                  Apple, and that as between Prabalta and Apple, Prabalta, not
                  Apple, is solely responsible for the Apple-Enabled Software
                  and the content thereof (except for User Content).
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  (b) You may not use the Apple-Enabled Software in any manner
                  that is in violation of or inconsistent with the Usage Rules
                  set forth for Apple-Enabled Software in, or otherwise be in
                  conflict with, the App Store Terms of Service.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  (c) Your license to use the Apple-Enabled Software is limited
                  to a non-transferable license to use the Apple-Enabled
                  Software on an iOS Product that you own or control, as
                  permitted by the Usage Rules set forth in the App Store Terms
                  of Service.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  (d) Apple has no obligation whatsoever to provide any
                  maintenance or support services with respect to the
                  Apple-Enabled Software.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  (e) Apple is not responsible for any product warranties,
                  whether express or implied by law. In the event of any failure
                  of the Apple-Enabled Software to conform to any applicable
                  warranty, you may notify Apple, and Apple will refund the
                  purchase price for the Apple-Enabled Software to you, if any;
                  and, to the maximum extent permitted by applicable law, Apple
                  will have no other warranty obligation whatsoever with respect
                  to the Apple-Enabled Software, or any other claims, losses,
                  liabilities, damages, costs or expenses attributable to any
                  failure to conform to any warranty, which will be Prabalta’s
                  sole responsibility, to the extent it cannot be disclaimed
                  under applicable law.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  (f) Prabalta and you acknowledge that Prabalta, not Apple, is
                  responsible for addressing any claims of you or any third
                  party relating to the Apple-Enabled Software or your
                  possession and/or use of that Apple-Enabled Software,
                  including, but not limited to: (i) product liability claims;
                  (ii) any claim that the Apple-Enabled Software fails to
                  conform to any applicable legal or regulatory requirement; and
                  (iii) claims arising under consumer protection or similar
                  legislation.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  (g) In the event of any third party claim that the
                  Apple-Enabled Software or the end-user’s possession and use of
                  that Apple-Enabled Software infringes that third party’s
                  intellectual property rights, as between Prabalta and Apple,
                  Prabalta, not Apple, will be solely responsible for the
                  investigation, defense, settlement and discharge of any such
                  intellectual property infringement claim.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  (h) If you have any questions, complaints or claims with
                  respect to the Apple-Enabled Software, they should be directed
                  to Prabalta as follows:
                </p>

                <address className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12 mb-4">
                  <strong>Prabalta Copyright Agent:</strong>
                  <br />
                  Prabalta
                  <br />
                  3025 Windy Knoll Court
                  <br />
                  Rockville
                  <br />
                  Maryland 20850
                  <br />
                  United States
                </address>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  Or{" "}
                  <a
                    href="mailto: support@prabalta.org"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    support@prabalta.org
                  </a>{" "}
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  (i) Prabalta and you acknowledge and agree that Apple, and
                  Apple’s subsidiaries, are third-party beneficiaries of these
                  Terms of Service with respect to the Apple-Enabled Software,
                  and that, upon your acceptance of the terms and conditions of
                  these Terms of Service, Apple will have the right (and will be
                  deemed to have accepted the right) to enforce these Terms of
                  Service against you with respect to the Apple-Enabled Software
                  as a third-party Beneficiary thereof. Accordingly, the parties
                  acknowledge and agree that Prabalta enters into this clause
                  (“Apple-Enabled Software Applications”) for its own benefit
                  and on its own behalf and also as an agent for the benefit and
                  on behalf of Apple and its subsidiaries with respect to the
                  exercise and enforcement of all rights, benefits and remedies
                  of Apple and its subsidiaries (but not any obligation or
                  burden) in this clause (“Apple-Enabled Software Applications”)
                  which rights, benefits and remedies shall be enforceable by
                  Prabalta in its own right and also as agent for and on behalf
                  of each of Apple and its subsidiaries. Prabalta may amend,
                  terminate or rescind these Terms of Service without the
                  consent of Apple or any such subsidiary.
                </p>
              </section>
              <section id="terminationAccount">
                <h2 className="text-2xl font-extrabold mb-4">
                  15. Suspension or Termination of Accounts
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  You agree that we may, in response to concerns of fraudulent
                  or illegal activity or a material breach of these Terms of
                  Service, suspend or terminate your Account (or any part
                  thereof) or your access to the Services and remove and discard
                  any User Content or data at any time, including any
                  Fundraisers you may have organized. To the extent permitted by
                  applicable law, we may take any such actions without any
                  liability to you or to any third party for any claims,
                  damages, costs or losses resulting therefrom. We may take such
                  actions with or without providing you notice.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed">
                  <strong>15.1. Account Closures</strong>: We reserve the right,
                  without limitation, to close your Account or disable your
                  access to the Services in any of the following circumstances:
                  (i) we are unable to confirm that your Fundraiser complies
                  with these Terms of Service; (ii) we are unable to support
                  your Account from a technical perspective; (iii) our payment
                  processors are unable to support your Account; (iv) the
                  Beneficiary requests that the Fundraiser is removed; (v) your
                  Account becomes dormant or otherwise abandoned; (vi) your
                  Account displays activity that poses a risk to Prabalta or its
                  community; or (vii) such action(s) is required to comply with
                  a court order, writ, injunction, or as otherwise required
                  under applicable laws and regulations. If we close your
                  account or otherwise disable your access to the Services for
                  any of these reasons, we may also issue refunds, as
                  appropriate, in accordance with these Terms of Service and the
                  Prabalta Giving Guarantee.
                </p>
              </section>
              <section id="disclaimerAndLimitation">
                <h2 className="text-2xl font-extrabold mb-4">
                  16. Disclaimers and Limitations of Liability
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>16.1. Warranty Disclaimer:</strong> YOUR USE OF THE
                  SERVICES IS AT YOUR SOLE RISK. TO THE EXTENT PERMITTED BY
                  APPLICABLE LAW, THE SERVICES ARE PROVIDED ON AN “AS IS” AND
                  “AS AVAILABLE” BASIS. EACH OF Prabalta AND ITS AFFILIATES
                  EXPRESSLY DISCLAIM AND EXCLUDE, TO THE FULLEST EXTENT
                  PERMITTED BY APPLICABLE LAW, ALL WARRANTIES, CONDITIONS AND
                  REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED OR
                  STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                  WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                  PURPOSE, TITLE AND NON-INFRINGEMENT.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  WITHOUT PREJUDICE TO STATUTORY WARRANTIES UNDER APPLICABLE
                  LAW, AND WITHOUT PREJUDICE TO CONTENT MODERATION REQUIREMENTS
                  WHERE PRESCRIBED BY APPLICABLE LAW, NEITHER Prabalta NOR ANY
                  OF ITS AFFILIATES MAKE ANY WARRANTY OR CLAIM THAT: (I) THE
                  SERVICES WILL MEET YOUR REQUIREMENTS; (II) THE SERVICES WILL
                  BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (III) THE
                  RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL
                  BE ACCURATE OR RELIABLE; OR (IV) THE QUALITY OF ANY PRODUCTS,
                  SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED
                  BY YOU THROUGH THE SERVICES WILL MEET YOUR EXPECTATIONS.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  ALL THIRD-PARTY INFORMATION AND CONTENT ON THE SERVICES ARE
                  FOR INFORMATIONAL PURPOSES ONLY. Prabalta AND ITS AFFILIATES
                  DO NOT GUARANTEE THE ACCURACY, COMPLETENESS, TIMELINESS, OR
                  RELIABILITY OF THIS INFORMATION. NO CONTENT IS INTENDED TO
                  PROVIDE FINANCIAL, LEGAL, TAX, OR OTHER PROFESSIONAL ADVICE.
                  BEFORE MAKING DECISIONS ABOUT FUNDRAISERS, NPOS, DONATIONS, OR
                  ANY RELATED INFORMATION, CONSULT WITH YOUR FINANCIAL, LEGAL,
                  TAX, OR OTHER PROFESSIONAL ADVISOR. YOU ACKNOWLEDGE THAT YOU
                  ACCESS ALL INFORMATION AND CONTENT ON THE SERVICES AT YOUR OWN
                  RISK.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  WE DO NOT GUARANTEE THAT ANY FUNDRAISER WILL RECEIVE A
                  SPECIFIC AMOUNT OF DONATIONS OR ANY DONATIONS AT ALL. WE DO
                  NOT ENDORSE ANY FUNDRAISER, USER, OR CAUSE, AND WE MAKE NO
                  GUARANTEES ABOUT THE ACCURACY OF INFORMATION PROVIDED THROUGH
                  THE SERVICES. AS A DONOR, YOU MUST DETERMINE THE VALUE AND
                  APPROPRIATENESS OF CONTRIBUTING TO ANY USER OR FUNDRAISER.
                </p>
              </section>

              <section id="indemnification">
                <h2 className="text-2xl font-extrabold mb-4">
                  17. Indemnification and Release
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>
                    17.1. Obligations of Users to Indemnify Prabalta against
                    certain types of claims:
                  </strong>{" "}
                  To the fullest extent permitted by applicable law, You agree
                  to release, indemnify and hold Prabalta and its Affiliates and
                  their officers, employees, directors and agents harmless from
                  any and all losses, damages, expenses (including reasonable
                  attorneys’ fees), costs, awards, fines, claims, and actions of
                  any kind, arising out of or relating to your use of the
                  Services, any Donation or Fundraiser, any User Content, your
                  connection to the Services, your violation of these Terms of
                  Service or your violation of any rights of another. You agree
                  that Prabalta has the right to conduct its own defense of any
                  claims at its own discretion, and that you will indemnify
                  Prabalta for the costs of its defense.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>17.2. Release of Claims:</strong> If you are a
                  California resident, you waive California Civil Code Section
                  1542, which says: “A general release does not extend to claims
                  that the creditor or releasing party does not know or suspect
                  to exist in his or her favor at the time of executing the
                  release, and that if known by him or her would have materially
                  affected his or her settlement with the debtor or released
                  party.” If you are a resident of another jurisdiction—in or
                  outside of the United States—you waive any comparable statute
                  or doctrine to the extent permitted by law.
                </p>
              </section>
              <section id="disputeResolution">
                <h2 className="text-2xl font-extrabold mb-4">
                  18. Dispute Resolution & Arbitration
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>
                    18.1. USERS IN THE UNITED STATES AND ANYWHERE ELSE IN THE
                    WORLD EXCEPT FOR THE EUROPEAN ECONOMIC AREA, THE UK AND
                    SWITZERLAND:
                  </strong>{" "}
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>
                    {" "}
                    PLEASE READ THIS SECTION CAREFULLY BEFORE USING THE SERVICES
                    OR PLATFORM AS THIS AGREEMENT AFFECTS YOUR LEGAL RIGHTS AND
                    GOVERNS HOW CLAIMS THAT YOU AND WE MAY HAVE AGAINST EACH
                    OTHER ARE RESOLVED.
                  </strong>{" "}
                  This Agreement requires you to arbitrate disputes with
                  Prabalta and limits the manner in which you can seek relief.
                  This Agreement limits certain legal rights, including the
                  right to a jury trial, the right to participate in any form of
                  class, collective, or representative claim, dispute or action,
                  and the right to certain remedies and forms of relief. Other
                  rights that we or you would have in court, such as an
                  appellate review, also may not be available in the arbitration
                  process described within this section.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  <strong>(a) Informal Resolution:</strong> You and Prabalta
                  agree that good-faith informal efforts to resolve disputes can
                  often result in a prompt, low-cost and mutually beneficial
                  outcome. In the unlikely event that a disagreement arises
                  between you and Prabalta regarding any claim or controversy at
                  law or equity arising out of, relating to, or connected in any
                  way with the Services or the Platform (collectively,
                  “Dispute”), prior to initiating any legal action, you must
                  first contact us directly by email at support@prabalta.org You
                  must provide your name, the email address associated with your
                  Prabalta account (if any), a description of the Dispute and
                  the specific relief sought.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  You agree that the term “Dispute” in these Terms of Service
                  will have the broadest meaning possible. These Terms also
                  cover any Dispute between you and any officer, director, board
                  member, agent, employee, affiliate of Prabalta, or third party
                  if Prabalta could be liable, directly or indirectly, for such
                  Dispute. This includes any Disputes arising out of or relating
                  to your relationship with us, including without limitation,
                  disputes related to these Terms of Service or the breach,
                  termination, enforcement, interpretation or validity thereof,
                  your use of the services, and/or any rights of privacy and/or
                  publicity.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  During the 60 days from the date you first contacted us, you
                  and we agree to engage in good faith efforts to resolve the
                  Dispute. You will not initiate any legal action during this
                  period. At a minimum, we will personally meet and confer, via
                  telephone or videoconference as part of an informal dispute
                  resolution conference. If you are represented by counsel, your
                  counsel may participate in the conference, but you shall also
                  fully participate in the conference. An extension of time may
                  be mutually agreed upon by you and us.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  Engaging in an informal dispute resolution conference is a
                  requirement that must be fulfilled before commencing
                  arbitration or legal action. The statute of limitations and
                  any filing fee deadlines shall be tolled while the parties
                  engage in the informal dispute resolution process required by
                  this paragraph.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  <strong>
                    (b) Binding Arbitration Agreement & Class Action Waiver:
                  </strong>{" "}
                  YOU AND Prabalta AGREE THAT ANY DISPUTE ARISING OUT OF OR
                  RELATED TO THIS AGREEMENT OR OUR SERVICES IS PERSONAL TO YOU
                  AND Prabalta. YOU AND Prabalta AGREE THAT ANY DISPUTE, CLAIM
                  OR CONTROVERSY MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND
                  NOT ON A CLASS OR COLLECTIVE BASIS AND NOT IN A COURT OF LAW.
                  YOU AND WE HEREBY EXPRESSLY WAIVE ANY RIGHTS TO SUE IN COURT
                  AND RECEIVE A TRIAL BY JUDGE OR JURY OR TO PARTICIPATE AS A
                  PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR
                  REPRESENTATIVE PROCEEDING. The arbitrator, and not any
                  federal, state or local court or agency, shall have exclusive
                  authority to resolve any dispute relating to the
                  interpretation, applicability, enforceability or formation of
                  this Arbitration Agreement, including, but not limited to any
                  claim that all or any part of this Arbitration Agreement is
                  void or voidable. The arbitration proceedings and arbitration
                  outcome are subject to certain confidentiality rules, and
                  judicial review of the arbitration outcome is limited.
                  Discovery and rights to appeal in arbitration are generally
                  more limited than in a lawsuit, and other rights that you and
                  we would have in court may not be available in arbitration.
                  The arbitrator’s award shall be final and binding and may be
                  entered as a judgment in any court of competent jurisdiction.
                  This Agreement to arbitrate such disputes, claims or
                  controversies shall be referred to herein as the “Arbitration
                  Agreement”.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  Notwithstanding the foregoing, the Arbitration Agreement shall
                  not require arbitration of the following Disputes: (i)
                  qualifying individual Disputes in small claims court, so long
                  as such individual action remains in such small claims court
                  and advances only on an individual (non-class,
                  non-representative) basis; (ii) an enforcement action through
                  the applicable federal, state, or local agency if that action
                  is available; or (iii) injunctive or other equitable relief in
                  a court of competent jurisdiction for any dispute related to
                  the actual or threatened infringement or other misuse of
                  intellectual property rights (such as trademarks, trade dress,
                  domain names, trade secrets, copyrights and patents).
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  <strong>(c) Arbitration Process, Rules, and Forum:</strong>{" "}
                  You and Prabalta agree that the terms of this Arbitration
                  Agreement are governed by the Federal Arbitration Act in all
                  respects. If for whatever reason the rules and procedures of
                  the FAA cannot apply, the state law governing arbitration
                  agreements in the state in which you reside shall apply.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  The arbitration will be administered by the American
                  Arbitration Association (“AAA”) under its Consumer Arbitration
                  Rules (collectively, “AAA Rules”), including its Mass
                  Arbitration Supplementary Rules, as modified by these Terms of
                  Service. The AAA Rules and filing forms are available online
                  at www.adr.org. Unless otherwise agreed to by the parties in
                  writing, the arbitration proceedings shall remain
                  confidential. Unless you and Prabalta otherwise agree, or the
                  Mass Arbitration process outlined below is triggered, the
                  arbitration will be conducted in the county where you reside.
                  Any Arbitration Demand filed with the AAA must comply with the
                  requirements set forth by the AAA under its AAA rules. In
                  addition, the demand shall include a statement certifying the
                  completion of the informal dispute resolution conference
                  pursuant to the informal dispute resolution section above. A
                  copy of the Arbitration Demand must be sent by email to the
                  counsel who represented Prabalta in the informal dispute
                  resolution process, or if there was no such counsel then by
                  email to support@prabalta.org and by mail to Attn: Legal at PO
                  Box 121270, 815 E Street, San Diego, CA, 92101, United States.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  If the party requesting arbitration is represented by counsel,
                  the Arbitration Demand shall also include counsel’s name,
                  telephone number, mailing address and email address. Counsel
                  must also sign the Arbitration Demand. By signing the request,
                  counsel certifies to the best of counsel’s knowledge,
                  information, and belief, formed after an inquiry reasonable
                  under the circumstances, that (1) the Arbitration Demand is
                  not being filed for any improper purpose, such as to harass,
                  cause unnecessary delay, or needlessly increase the cost of
                  dispute resolution; (2) the claims, defenses and other legal
                  contentions are warranted by existing law or by a nonfrivolous
                  argument for extending, modifying, or reversing existing law
                  or for establishing new law; and (3) the factual and damages
                  contentions have evidentiary support or, if specifically so
                  identified, we will likely have evidentiary support after a
                  reasonable opportunity for further investigation or discovery.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  <strong>(d) Arbitration Fees:</strong> Your responsibility to
                  pay any AAA filing fees, case management fees and arbitrator
                  compensation will be solely as set forth in the AAA Rules.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  <strong>(e) Mass Arbitrations:</strong> In the event 25 or
                  more Arbitration Demands of a similar nature are filed against
                  Prabalta, where representation of all parties is consistent or
                  coordinated across the cases, the AAA Supplementary Rules for
                  Mass Arbitration shall apply.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  All parties agree that Arbitration Demands are of a “similar
                  nature” if they arise out of the same event or similar factual
                  scenario, and raise the same or similar legal issues and seek
                  the same or similar relief. To the extent the parties disagree
                  on the applicability of the Mass Arbitration process, the
                  disagreeing party shall advise the AAA, and the AAA shall
                  appoint a sole standing Process Arbitrator to determine the
                  Mass Arbitration process’ applicability (“Process
                  Arbitrator”). In an effort to expedite resolution of any such
                  disputes, the parties agree the Process Arbitrator may set
                  forth such procedures as are necessary to resolve any disputes
                  promptly. Should initial efforts at dispute resolution through
                  the AAA-ICDR mediation as set forth in Section MA-9 of the AAA
                  Supplementary Rules for Mass Arbitration fail and the cases
                  proceed, you consent to and the Process Arbitrator shall order
                  the cases to proceed according to the following batching
                  terms:
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  The Process Arbitrator shall group and administer the
                  arbitration demands into batches of no more than 26 demands
                  per batch (plus, to the extent there are less than 26
                  arbitration demands left over after the batching described
                  above, a final batch consisting of the remaining demands.
                  Thirteen (13) of the initial batch will be selected by the
                  claimants and their coordinated counsel and thirteen (13) will
                  be selected by Prabalta. If there is any dispute on the
                  selection of claimants, the Process Arbitrator will have sole
                  discretion to select the claims. The remaining Arbitration
                  Demands shall be stayed nor shall any arbitration provider
                  fees be assessed in connection with those Arbitration Demands
                  until they are selected to proceed to individual arbitration
                  proceedings as part of the staged process described herein. If
                  the parties are unable to resolve the remaining Arbitration
                  Demands after the conclusion of the initial twenty-six
                  proceedings, the parties shall participate in a second global
                  mediation session. Should this second mediation not result in
                  a global settlement, the batching process will repeat until
                  such resolution is achieved.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  You agree to cooperate in good faith with Prabalta to
                  implement such a batch approach to resolution and fees,
                  including the payment of single case management fees and
                  arbitrator compensation for batches, as well as any steps to
                  minimize the time and costs of arbitration. Any applicable
                  statute of limitations on your Arbitration Demand and filing
                  fee deadlines shall be tolled for batched coordinated disputes
                  from the time any Arbitration Demand is selected for the first
                  set of batching proceedings until the time your Arbitration
                  Demand is selected to proceed in arbitration, withdrawn, or
                  otherwise resolved.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  This Arbitration provision shall in no way be interpreted as
                  authorizing a class, collective and/or mass arbitration or
                  action of any kind, or arbitration involving joint or
                  consolidated claims under any circumstances, except as set
                  forth in this section. If the terms regarding batched disputes
                  is deemed unenforceable as to you or your batch, then it shall
                  be severed and you agree to arbitrate in individual
                  proceedings as ordered by the Process Arbitrator in accordance
                  with this section.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  <strong>(f) Confidentiality:</strong> We each agree to keep
                  any informal dispute and arbitration proceedings confidential,
                  including all information exchanged between us and any
                  settlement offers, unless otherwise required by law or
                  requested by law enforcement or any court or governmental
                  body. However, we may each disclose these matters, in
                  confidence, to our respective attorneys, accountants,
                  auditors, and insurance providers.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  <strong>(g) Enforceability:</strong> If any provision of these
                  Terms of Service or this Dispute Resolution section is found
                  to be unenforceable, illegal or invalid for any reason, such
                  unenforceability, illegality or invalidity will not affect any
                  other provision of these Terms of Service or this Dispute
                  Resolution section, and these Terms of Service and this
                  Dispute Resolution section will be construed as if such
                  unenforceable, illegal or invalid provision had never been
                  contained therein.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  <strong>(h) Opt Out:</strong> You may opt out of this
                  Arbitration Agreement. To opt out, you must notify Prabalta in
                  writing no later than 30 days after first becoming subject to
                  this Arbitration Agreement including any updates to the
                  Arbitration Agreement. Your notice must include your name and
                  address, the title of and link to your Prabalta campaign (if
                  any), the email address you use to access your Prabalta
                  account (if you have one), and a CLEAR statement that you want
                  to opt out of this Arbitration Agreement. You must send your
                  opt-out notice to support@prabalta.org with the subject line
                  “Arbitration Opt-Out Notice”. If you opt out of this
                  Arbitration Agreement, all other parts of these Terms of
                  Service will continue to apply to you. Prabalta will continue
                  to honor the valid opt outs of users who validly opted out of
                  the Arbitration Agreement in a prior version of the Terms of
                  Service.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  <strong>(i) Time Period for Claims:</strong> You agree that
                  any claim or cause of action arising out of or related to use
                  of the Services, the Platform or these Terms of Service must
                  be filed within one (1) year after such claim or cause of
                  action arose or otherwise will be forever barred, which means
                  that you and Prabalta will not have a right to assert the
                  Claim.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>
                    18.2. USERS IN THE UK, EUROPEAN ECONOMIC AREA AND
                    SWITZERLAND:
                  </strong>
                  These Terms and any dispute arising out of or in connection
                  with your use of the Services are governed by the laws of the
                  Republic of Ireland subject to any more favorable public
                  policy provisions under your local law, and the courts of the
                  Republic of Ireland have exclusive jurisdiction, except to the
                  extent that public policy provision under your local law
                  allows for the competence of your local courts.
                </p>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-12">
                  <strong>
                    (a) Users In The European Union, Norway, Iceland Or
                    Liechtenstein
                  </strong>
                  You have the right to submit all unresolved disputes between
                  you and Prabalta to the Online Dispute Resolution platform
                  (ODR) arbitration administered by the European Union:
                  {/* https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks */}
                  then in effect, except that either party may seek injunctive
                  relief for infringement of intellectual property rights or
                  other proprietary rights, in court and that claim brought
                  under the EU or UK General Data Protection Regulations may be
                  resolved locally in court or by referral to a competent
                  privacy regulator.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>18.3. Disputes With Other Users</strong>
                  You agree that you are solely responsible for your
                  interactions with any other User in connection with the
                  Services and neither Prabalta nor its Affiliates will have any
                  liability or responsibility with respect thereto. Prabalta and
                  its Affiliates reserve the right, but have no obligation, to
                  become involved in any way with disputes between you and any
                  other User of the Services.
                </p>
              </section>
              <section id="changesTotheterms">
                <h2 className="text-2xl font-extrabold mb-4">
                  19. Changes to the Terms
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>
                    19.1. Policies regarding how changes to the Terms of Service
                    will be communicated and implemented:
                  </strong>{" "}
                  We reserve the right, at our sole discretion, to change or
                  modify portions of these Terms of Service at any time. Where
                  possible we will provide 30 days’ notice of substantive
                  changes to these Terms of Service and, if appropriate, we may
                  inform Users of such changes via email or other appropriate
                  means. Changes may be made at short or no notice where a
                  change is required by law. When we make a change, we will post
                  the revised Terms of Service on this page and will indicate
                  the date of such revision. Your continued use of the Services
                  after the date of any such changes constitutes your acceptance
                  of the new Terms of Service. If you do not wish to accept the
                  new Terms of Service, you should discontinue your use of the
                  Services.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>19.2. English Version Binding:</strong> To the extent
                  allowed by applicable law, the English language version of
                  these Terms of Service is binding and their translations in
                  other languages are for convenience only; in case of
                  discrepancies between the English version of these Terms of
                  Service and their translations, the English version shall
                  prevail.
                </p>
              </section>

              <section id="miscellaneous">
                <h2 className="text-2xl font-extrabold mb-4">
                  20. Miscellaneous
                </h2>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>20.1. Entire Agreement:</strong> These Terms of
                  Service constitute the entire agreement between you and
                  Prabalta and govern your use of the Services and the Platform,
                  superseding any prior agreements between you and Prabalta with
                  respect to the Services. You also may be subject to additional
                  terms of service that may apply when you use affiliate or
                  third-party services, third-party content or third-party
                  software.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>20.2. Governing Law:</strong> For Users in the United
                  Kingdom, European Economic Area and Switzerland, these Terms
                  of Service and any dispute arising out of or in connection
                  with your use of the Services are governed as set out above
                  under “Disputes – Users In the UK, European Economic Area and
                  Switzerland”. For all other Users, these Terms of Service will
                  be governed by the laws of the State of California without
                  regard to its conflict of law provisions. With respect to any
                  disputes or claims not subject to arbitration, as set forth
                  above, you and Prabalta agree to submit to the personal and
                  exclusive jurisdiction of the state and federal courts located
                  within San Mateo County, California.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>20.3. Waiver:</strong> The failure of Prabalta to
                  exercise or enforce any right or provision of these Terms of
                  Service will not constitute a waiver of such right or
                  provision.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>20.4. Severability:</strong> If any provision of these
                  Terms of Service is found by a court of competent jurisdiction
                  to be (or are otherwise) invalid, the parties nevertheless
                  agree that the court should endeavor to give effect to the
                  parties’ intentions as reflected in the provision, and the
                  other provisions of these Terms of Service remain in full
                  force and effect.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>20.5. Printed Version:</strong> A printed version of
                  this agreement and of any notice given in electronic form will
                  be admissible in judicial or administrative proceedings based
                  upon or relating to this agreement to the same extent and
                  subject to the same conditions as other business documents and
                  records originally generated and maintained in printed form.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>20.6. Assignment:</strong> You may not assign these
                  Terms of Service without the prior written consent of
                  Prabalta, but Prabalta and/or its Affiliates may assign or
                  transfer these Terms of Service, in whole or in part, without
                  restriction. For the sake of clarity, Prabalta and/or its
                  Affiliates may, at any time, assign our rights or delegate our
                  obligations hereunder without notice to you in connection with
                  a merger, acquisition, reorganization or sale of equity or
                  assets, or by operation of law or otherwise.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>20.7. Section Titles:</strong> The section titles in
                  these Terms of Service are for convenience only and have no
                  legal or contractual effect.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>20.8. Notices:</strong> To the extent permitted by
                  applicable law, notices to you may be made via email or
                  regular mail. The Services may also provide notices to you of
                  changes to these Terms of Service or other matters by
                  displaying notices or links to notices generally on the
                  Platform.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>20.9. Force Majeure:</strong> Except to the extent
                  provided otherwise under applicable law, Prabalta shall not be
                  liable for any delay or failure to perform resulting from
                  causes outside its reasonable control, including, but not
                  limited to, acts of God, war or threats of war, terrorism or
                  threats of terrorism, riots, embargos, acts of civil or
                  military authorities, fire, floods, accidents, governmental
                  regulation or advisory, recognized health threats, as
                  determined by the World Health Organization, the Centers for
                  Disease Control, or local government authority or health
                  agencies, strikes or shortages or curtailment of
                  transportation facilities, fuel, energy, labor or materials.
                </p>

                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  <strong>20.10. Export Controls:</strong> Software available in
                  connection with the Services and the transmission of
                  applicable data, if any, may be subject to export controls and
                  economic sanctions laws of the United States or other
                  jurisdictions. No Software may be downloaded from the Services
                  or otherwise exported or re-exported in violation of such
                  export control and economic sanctions laws. Downloading or
                  using the Software is at your sole risk. You represent and
                  warrant that (i) you are not located in a country that is
                  subject to a U.S. Government embargo, or that has been
                  designated by the U.S. Government as a “terrorist supporting”
                  country; (ii) you are not listed on any U.S. Government list
                  of prohibited or restricted parties and (iii) you are not
                  located in any other country or jurisdiction from which you
                  would be barred from using the Services by applicable law.
                </p>
              </section>

              <section id="questions">
                <h2 className="text-2xl font-extrabold mb-4">
                  21. Questions? Concerns? Suggestions?
                </h2>
                <p className="text-base text-gray-600 mb-8 leading-relaxed md:pl-4">
                  Please visit the
                  <a
                    href="/support-user"
                    className="text-blue-500 hover:underline"
                  >
                    {" "}
                    Help Support
                  </a>{" "}
                  to learn more about Prabalta’s Services and Platform or
                  contact us to report violations or pose any question.
                </p>
              </section>
            </main>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />

      {/* Toast notifications */}
      <ToastContainer />
    </div>
  );
};
