import React, { useRef, useEffect } from "react";
import { Spinner } from "@material-tailwind/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TQ_URL } from "../../utility/baseUrl";
import { getRequestWithTokenWithoutData } from "../../utility/apiRequest";

const AccountModalHome = ({ isOpen, onClose, onLoadingStateChange }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const modalRef = useRef(null);

  const logLocalData = () => {
    const localStorageData = localStorage.getItem("userData");
    //    console.log("Local Storage Data:", JSON.parse(localStorageData));
    setUserData(JSON.parse(localStorageData));
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      const response = await getRequestWithTokenWithoutData(
        `${TQ_URL}/logoutOauth`
      );
      localStorage.removeItem("userData");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userAddressDetails");
      localStorage.removeItem("partnerData");
      localStorage.removeItem("donationData");
      localStorage.setItem("loggedIn", false);
      setUserData(null);
      setIsLoading(false);
      onLoadingStateChange(true);
      console.log("===================>", "complete log out");
      navigate(0);
      // navigate("/");
      // window.location.reload();
    } catch (error) {
      console.error("Error during logout", error); // Log the error for debugging
      setIsLoading(false); // Ensure loading state is reset even on error
    }
  };

  // useEffect(() => {
  //   logLocalData();
  //   console.log("Useeffect call ---------->");
  // }, []);

  // Close the modal if the user clicks outside of it
  useEffect(() => {
    logLocalData();
    //      console.log("Useeffect call ---------->");
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleScroll = () => {
      if (
        modalRef.current &&
        !modalRef.current.contains(document.activeElement)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <>
      {userData ? (
        userData.userType !== "Admin" ? (
          <div className="absolute top-14 right-0 md:right-6 z-[9999] ">
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute top-2 right-1 text-red-500 hover:text-red-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className="p-10 pb-0 pt-2 text-center border-b bg-white rounded-t-lg shadow-lg shadow-grey">
              <p className="text-sm text-gray-700 font-semibold">
                {userData.email}
              </p>
              <div className="flex flex-col items-center mt-2">
                <div className="w-14 h-14 rounded-full bg-pink-600 flex items-center justify-center text-white text-3xl">
                  {userData.userName ? userData.userName.charAt(0) : "N/A"}
                </div>
                <p className="text-lg font-medium mt-2 text-gray-800">
                  {" "}
                  Hi, {userData.userName || "User"}!
                </p>
              </div>
            </div>

            {/* <div className="p-4 text-center border-b bg-white">
                            <button className="w-full py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-full text-sm font-medium">
                                Manage Account
                            </button>
                        </div> */}

            <div className="p-4 text-center border-b bg-white flex justify-center gap-4">
              <button
                className="py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-full text-sm font-medium"
                onClick={() => navigate("/userprofile")}
              >
                Profile
              </button>
              <button
                className="py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-full text-sm font-medium"
                onClick={() => navigate("/donorshistory")}
              >
                History
              </button>
            </div>

            <div className="p-4 flex justify-center items-center text-blue-600 text-sm border-b bg-white">
              <button
                className="hover:underline flex items-center"
                onClick={logout}
              >
                <svg
                  className="w-4 h-4 mr-1"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M16 13v-2H7V8l-5 4 5 4v-3h9zm-5 9h11V2H11v4h2V4h7v16h-7v-2h-2v4z" />
                </svg>
                Sign out
              </button>
            </div>

            <div className="p-4 text-xs text-gray-500 text-center bg-white rounded-b-lg">
              <p>
                <a href="/privacy" className="hover:underline">
                  Privacy policy
                </a>{" "}
                ·{" "}
                <a href="/terms" className="hover:underline">
                  Terms of service
                </a>
              </p>
            </div>
          </div>
        ) : (
          <div
            className="absolute right-0 md:right-10 mt-2 bg-white border rounded shadow-md w-76 px-5 py-2 !z-50"
            ref={modalRef}
          >
            <ul className="list-none p-2 text-black">
              <li className="py-2">
                <button
                  className="text-red-500 w-full flex justify-center"
                  onClick={logout}
                >
                  <>
                    Logout
                    <svg
                      className="ms-1 w-6"
                      viewBox="0 -0.5 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.75 9.874C11.75 10.2882 12.0858 10.624 12.5 10.624C12.9142 10.624 13.25 10.2882 13.25 9.874H11.75ZM13.25 4C13.25 3.58579 12.9142 3.25 12.5 3.25C12.0858 3.25 11.75 3.58579 11.75 4H13.25ZM9.81082 6.66156C10.1878 6.48991 10.3542 6.04515 10.1826 5.66818C10.0109 5.29121 9.56615 5.12478 9.18918 5.29644L9.81082 6.66156ZM5.5 12.16L4.7499 12.1561L4.75005 12.1687L5.5 12.16ZM12.5 19L12.5086 18.25C12.5029 18.25 12.4971 18.25 12.4914 18.25L12.5 19ZM19.5 12.16L20.2501 12.1687L20.25 12.1561L19.5 12.16ZM13.25 9.874V4H11.75V9.874H13.25ZM9.18918 5.29644C6.49843 6.52171 4.7655 9.19951 4.75001 12.1561L6.24999 12.1639C6.26242 9.79237 7.65246 7.6444 9.81082 6.66156L9.18918 5.29644ZM4.75005 12.1687C4.79935 16.4046 8.27278 19.7986 12.5086 19.75L12.4914 18.25C9.08384 18.2892 6.28961 15.5588 6.24995 12.1513L4.75005 12.1687ZM12.4914 19.75C16.7272 19.7986 20.2007 16.4046 20.2499 12.1687L18.75 12.16L18.7413 12.1639C18.7269 9.19951 16.0014 6.52171 13.25 5.29644L15.8108 6.66156L14.8174 5.66818C14.6458 5.29121 14.9891 5.12478 15.3661 5.29644L13.25 9.874ZM13.25 9.874V4H11.75V9.874H13.25ZM4.75005 12.1687C4.74572 12.3426 4.74572 12.5139 4.75005 12.6867L6.24999 12.1639L4.75005 12.1687ZM13.25 9.874C13.25 10.2882 12.9142 10.624 12.5 10.624C12.0858 10.624 11.75 10.2882 11.75 9.874H13.25ZM5.5 12.16L6.24999 12.1639L6.25005 12.1687L5.5 12.16ZM15.8108 5.29644C15.4338 5.12478 14.9891 5.29121 14.8174 5.66818C14.6458 6.04515 14.8122 6.48991 15.1892 6.66156L15.8108 5.29644ZM12.5 19L12.4914 18.25C12.4971 18.25 12.5029 18.25 12.5086 18.25L12.5 19ZM19.5 12.16L20.2501 12.1687L20.25 12.1561L19.5 12.16ZM13.25 9.874V4H11.75V9.874H13.25ZM5.5 12.16L4.75005 12.1687L4.7499 12.1561L5.5 12.16ZM15.8108 5.29644C15.4338 5.12478 14.9891 5.29121 14.8174 5.66818C14.6458 6.04515 14.8122 6.48991 15.1892 6.66156L15.8108 5.29644ZM13.25 9.874C13.25 10.2882 12.9142 10.624 12.5 10.624C12.0858 10.624 11.75 10.2882 11.75 9.874H13.25Z" />
                    </svg>
                  </>
                </button>
              </li>
            </ul>
          </div>
        )
      ) : null}
    </>
  );
};

export default AccountModalHome;
