import React, { useState, useEffect, useRef, useCallback } from "react";
import { DatePicker } from "@mantine/dates";
import { ActionIcon, Popover } from "@mantine/core";
import { CiCalendarDate } from "react-icons/ci";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SocialMediaSideBar from "./SocialMediaSideBar";
import AOS from "aos";
import axios from "axios";
import "aos/dist/aos.css";
import OpenModal from "./OpenModal";
import { format, addDays, isBefore, parseISO, compareDesc } from "date-fns";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import {
  getRequestWithTokenWithDataWithAuth,
  getRequestWithTokenWithoutDataPartner,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { Triangle } from "react-loader-spinner";
import { MdDelete } from "react-icons/md";
import SocialMediaTour from "./SocialMediaTour";

const SchedulePost = () => {
  const [eventsByDate, setEventsByDate] = useState({});
  const [displayedDates, setDisplayedDates] = useState([]);
  const [allDates, setAllDates] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [loadingScheduled, setLoadingScheduled] = useState(false);
  const [loadingOld, setLoadingOld] = useState(false);
  const [oldPosts, setOldPosts] = useState([]);
  const [showOldPosts, setShowOldPosts] = useState(false);
  const containerRef = useRef(null);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const [firstDateReached, setFirstDateReached] = useState(false);
  const [opened, setOpened] = useState(false);

  const [tourCompletedFlag, setTourCompletedFlag] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(true);

  const partnerId = partnerData.partnerId;
  const moduleId = 2;

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

 

  useEffect(() => {
    const fetchTourStatus = async () => {
      try {
        const response = await axios.get(
          `${TQ_URL}/user-tour-status/${partnerId}/${moduleId}`
        );
        console.log(response.data.tourCompleted);
        setTourCompletedFlag(response.data.tourCompleted); // Assuming your API returns the status in 'tourCompleted'
        localStorage.setItem(
          "socialTourCompletedFlag",
          response.data.tourCompleted
        );
      } catch (error) {
        console.error("Error fetching tour status:", error);
      } finally {
        setIsTableLoading(false); // Set loading to false after fetching the flag
      }
    };
    fetchTourStatus();
  }, []);

  useEffect(() => {
    AOS.init();
    initializeDates();
    fetchScheduledPosts();
  }, []);

  const fetchScheduledPosts = useCallback(async () => {
    setLoadingScheduled(true);
    const url = `${TQ_URL}/getScheduledPostEventData?partnerId=${partnerData.partnerId}`;

    try {
      const response = await getRequestWithTokenWithoutDataPartner(url);
      console.log("----------->", response);
      const events = response.map((event) => ({
        eventId: event.eventId,
        title: event.title,
        description: event.description,
        scheduleTime: parseISO(event.scheduleTime),
        channelId: event.channelId,
      }));

      const groupedEvents = groupEventsByDate(events);
      setEventsByDate(groupedEvents);
    } catch (error) {
      console.error("Error in fetching the scheduled post list:", error);
      // toast.error(error.response?.data || "An error occurred while fetching the scheduled post list.");
      //  toast.error("An error occurred while fetching the scheduled post list.");
    } finally {
      setLoadingScheduled(false);
    }
  }, [partnerData.partnerId]);

  const fetchOldPosts = useCallback(async () => {
    setLoadingOld(true);
    const url = `${TQ_URL}/getOldPostEventData?partnerId=${partnerData.partnerId}`;

    try {
      const response = await getRequestWithTokenWithoutDataPartner(url);

      const events = response.map((event) => ({
        eventId: event.eventId,
        title: event.title,
        description: event.description,
        scheduleTime: parseISO(event.scheduleTime),
        channelId: event.channelId,
      }));

      const oldPostsList = events.filter((event) =>
        isBefore(event.scheduleTime, new Date())
      );
      oldPostsList.sort((a, b) => compareDesc(a.scheduleTime, b.scheduleTime)); // Sort in descending order
      setOldPosts(oldPostsList);
    } catch (error) {
      console.error("Error in fetching the old post list:", error);
      // toast.error(error.response?.data || "An error occurred while fetching the old post list.");
      //    toast.error("An error occurred while fetching the scheduled post list.");
    } finally {
      setLoadingOld(false);
    }
  }, [partnerData.partnerId]);

  const groupEventsByDate = (events) => {
    return events.reduce((acc, event) => {
      const dateKey = format(event.scheduleTime, "yyyy-MM-dd");
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(event);
      return acc;
    }, {});
  };

  const initializeDates = () => {
    const startDate = new Date();
    const allDatesArray = [];
    for (let i = 0; i < 365; i++) {
      allDatesArray.push(addDays(startDate, i));
    }
    setAllDates(allDatesArray);
    setDisplayedDates(allDatesArray.slice(0, 10));
  };

  const loadMoreDates = () => {
    if (loadingScheduled) return;
    setLoadingScheduled(true);
    setTimeout(() => {
      const moreDates = allDates.slice(
        displayedDates.length,
        displayedDates.length + 10
      );
      setDisplayedDates((prevDates) => [...prevDates, ...moreDates]);
      setLoadingScheduled(false);
    }, 1000);
  };

  const handleOpen = (date) => {
    setSelectedDate(date);
    setCurrentTime(new Date());
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDate(null);
    setCurrentTime(null);
  };

  const handleScroll = () => {
    if (
      containerRef.current.scrollHeight - containerRef.current.scrollTop <=
      containerRef.current.clientHeight + 10
    ) {
      loadMoreDates();
    }
  };

  const formatDate = (date) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return "Tomorrow";
    } else {
      return format(date, "EEEE, MMMM d, yyyy");
    }
  };

  const handleDateChange = (date) => {
    const now = new Date();
    const combinedDateTime = new Date(date);
    combinedDateTime.setHours(now.getHours());
    combinedDateTime.setMinutes(now.getMinutes());
    combinedDateTime.setSeconds(now.getSeconds());

    setSelectedDate(combinedDateTime);
    setCurrentTime(combinedDateTime);
    handleOpen(combinedDateTime);
    setOpened(false); // Call handleOpen with the selected date
  };

  const truncateTitle = (title, maxLength = 20) => {
    if (title.length > maxLength) {
      return `${title.slice(0, maxLength)}...`;
    }
    return title;
  };

  const handleDelete = async (event) => {
    const currentTime = new Date();
    const scheduleTime = new Date(event.scheduleTime);

    if (currentTime >= scheduleTime) {
      console.log("Not Deleted: Scheduled time has passed.");
      toast.error("Scheduled time has passed");
      return;
    }
    try {
      const requestBody = {
        partnerId: partnerData.partnerId,
        eventId: event.eventId,
      };

      const apiUrl = `${TQ_URL}/deletePostData`;
      const response = await getRequestWithTokenWithDataWithAuth(
        apiUrl,
        requestBody
      );
      console.log("====================================>res", response);
      toast.success(response);
    } catch (error) {
      toast.error(
        "Error deleting event:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const renderEventsForDate = (date) => {
    const dateKey = format(date, "yyyy-MM-dd");
    const events = eventsByDate[dateKey] || [];

    return (
      <>
        {events.length === 0 && (
          <p className="text-gray-500">No events scheduled</p>
        )}
        {events.map((event) => (
          <div
            key={event.eventId}
            className={`flex justify-between items-center border rounded p-2 mb-2 shadow bg-white cursor-pointer
            ${
              event.channelId === 1
                ? "hover:bg-blue-100 hover:border-blue-500"
                : ""
            }
            ${
              event.channelId === 2
                ? "hover:bg-pink-100 hover:border-pink-500"
                : ""
            }`}
          >
            <div className="flex items-center">
              {event.channelId === 1 && (
                <img
                  src="/assets/images/facebook.png"
                  alt="Facebook Logo"
                  className="h-6 w-6 mr-2"
                />
              )}
              {event.channelId === 2 && (
                <img
                  src="/assets/images/instagram.png"
                  alt="Instagram Logo"
                  className="h-6 w-6 mr-2"
                />
              )}
              <div className="flex flex-col">
                <h3 className="text-base">{truncateTitle(event.title)}</h3>
                <p className="text-sm">{event.description}</p>
              </div>
            </div>
            <div className="flex items-center">
              <p className="text-gray-500 text-sm ml-4 mr-4">
                {format(event.scheduleTime, "h:mm a")}
              </p>
              <MdDelete
                onClick={() => handleDelete(event)}
                className="cursor-pointer text-red-500 hover:text-red-700"
              />
            </div>
          </div>
        ))}
        <div
          id="addPost"
          onClick={() => handleOpen(date)}
          className="flex justify-center items-center border rounded p-2 mb-2 shadow bg-white cursor-pointer hover:bg-gray-100"
          title="Click to add new post"
        >
          <button className="text-black font-bold text-lg">+</button>
        </div>
      </>
    );
  };

  const renderOldPosts = () => {
    const postsByDate = oldPosts.reduce((acc, post) => {
      const dateKey = format(post.scheduleTime, "yyyy-MM-dd");
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(post);
      return acc;
    }, {});

    return Object.keys(postsByDate).map((dateKey) => (
      <div key={dateKey}>
        <div className="text-lg mb-2">
          {format(parseISO(dateKey), "MMMM d, yyyy")}
        </div>
        {postsByDate[dateKey].map((post) => (
          <div
            key={post.eventId}
            className={`flex justify-between items-center border rounded p-2 mb-2 shadow bg-white cursor-pointer
            ${
              post.channelId === 1
                ? "hover:bg-blue-100 hover:border-blue-500"
                : ""
            }
            ${
              post.channelId === 2
                ? "hover:bg-pink-100 hover:border-pink-500"
                : ""
            }`}
          >
            <div className="flex items-center">
              {post.channelId === 1 && (
                <img
                  src="/assets/images/facebook.png"
                  alt="Facebook Logo"
                  className="h-6 w-6 mr-2"
                />
              )}
              {post.channelId === 2 && (
                <img
                  src="/assets/images/instagram.png"
                  alt="Instagram Logo"
                  className="h-6 w-6 mr-2"
                />
              )}
              <div className="flex flex-col">
                <h3 className="text-base">{truncateTitle(post.title)}</h3>
                <p className="text-sm">{post.description}</p>
              </div>
            </div>
            <div className="flex items-center">
              <p className="text-gray-500 text-sm ml-4">
                {format(post.scheduleTime, "h:mm a")}
              </p>
            </div>
          </div>
        ))}
      </div>
    ));
  };

  useEffect(() => {
    if (oldPosts.length > 0) {
      const firstDateInOldPosts = oldPosts[oldPosts.length - 1].scheduleTime;
      setFirstDateReached(isBefore(new Date(firstDateInOldPosts), new Date()));
    }
  }, [oldPosts]);

  if (isTableLoading) {
    return null; // Don't render anything while loading
  }

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px] h-[calc(100vh-50px)]">
        <div className="md:w-64 w-1/12 fixed h-full hidden md:block">
          <SocialMediaSideBar />
        </div>
        <main
          className="w-full ml-0 md:ml-[250px] pb-10 md:pb-0 h-full overflow-y-auto"
          ref={containerRef}
          onScroll={handleScroll}
        >
          <div className="mx-auto h-full flex flex-col px-4 md:px-10 py-6">
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold">Schedule Post</h2>
            </div>
            <div className="text-center mb-6 flex  items-center justify-end">
              <Popover
                opened={opened}
                onClose={() => setOpened(false)}
                position="bottom"
                withArrow
              >
                <Popover.Target>
                  <ActionIcon
                    onClick={() => setOpened((o) => !o)}
                    size="xxl"
                    style={{
                      color: "#1E3A8A", // Dark blue color

                      marginRight: "15px", // Margin to the right
                      justifyContent: "end",
                    }}
                  >
                    <CiCalendarDate size={50} />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <DatePicker
                    value={selectedDate}
                    onChange={handleDateChange}
                    minDate={new Date()}
                    maxDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() + 1)
                      )
                    }
                  />
                </Popover.Dropdown>
              </Popover>

              <button
                id="show-hidepost"
                className="primary-bg-color text-white px-4 py-2 rounded shadow"
                onClick={() => {
                  setShowOldPosts(!showOldPosts);
                  if (!showOldPosts) {
                    fetchOldPosts(); // Fetch old posts only when the button is clicked to show them
                  }
                }}
              >
                {showOldPosts ? "Hide Sent Posts" : "Show Sent Posts"}
              </button>
            </div>
            {showOldPosts ? (
              <div className="mt-4">
                <h3 className="text-xl font-bold mb-4">Sent Posts</h3>
                {loadingOld ? (
                  <div className="flex justify-center items-center p-4">
                    <Triangle
                      height="120"
                      width="120"
                      color="blue"
                      ariaLabel="triangle-loading"
                      visible={showOldPosts}
                    />
                  </div>
                ) : oldPosts.length === 0 ? (
                  <p>No old posts available.</p>
                ) : (
                  <>
                    {renderOldPosts()}
                    <div className="flex justify-center items-center p-4">
                      <p>No more posts</p>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <ul className="list-none p-0">
                {displayedDates.map((date) => (
                  <li key={date.toISOString()} className="mb-4">
                    <div
                      className="py-2 cursor-pointer"
                      onClick={() => handleOpen(date)}
                    >
                      <span className="font-semibold">{formatDate(date)}</span>
                    </div>
                    {renderEventsForDate(date)}
                  </li>
                ))}
              </ul>
            )}
            {loadingScheduled && !showOldPosts && (
              <div className="flex justify-center items-center p-4">
                <Triangle
                  height="120"
                  width="120"
                  color="blue"
                  ariaLabel="triangle-loading"
                  visible={!showOldPosts}
                />
              </div>
            )}
          </div>
        </main>
      </div>
      {open && (
        <OpenModal
          onClose={handleClose}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          currentTime={currentTime}
        />
      )}
      <ToastContainer />
      {!tourCompletedFlag && isLargeScreen && <SocialMediaTour />}
    </>
  );
};

export default SchedulePost;
