import React, { useEffect, useState } from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Triangle } from "react-loader-spinner"; // Import Triangle loader
import boardMembers from "./BoardMember.json";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />

      {/* Banner Section */}
      <div className="about-page-banner-section text-white 2xl:px-20 md:px-10 px-4 flex justify-between w-full flex-col md:flex-row">
        <div className="flex items-center fade-in-left">
          <h1 className="banner-text-2 md:w-7/12 md:leading-[5rem] leading-[2.8rem]">
            Committed To Change &{" "}
            <span className="yellow-texture-bg">To Each Other</span>
          </h1>
        </div>
      </div>

      {/* About Us Section with gradient effect */}
      <section className="relative bg-gradient-to-r from-blue-50 to-white py-20 px-6 md:px-20">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center md:flex md:flex-col md:items-start md:justify-center lg:flex-row">
          {/* Image Section */}
          <div className="relative flex items-center justify-center w-full md:order-first lg:order-none">
            <div className="relative rounded-lg shadow-xl overflow-hidden w-full md:w-auto">
              {/* Image with gradient */}
              <img
                src="/assets/images/aboutusN.jpg"
                alt="About Us"
                className="w-full h-full object-cover transform hover:scale-105 transition duration-300 ease-in-out"
              />
              {/* Gradient overlay effect on the image */}
              <div className="absolute inset-0 bg-gradient-to-tl from-blue-900/40 to-transparent"></div>
            </div>
          </div>

          {/* Text Section */}
          <div className="space-y-6 text-left md:w-full">
            <h2 className="text-4xl font-bold text-blue-900 hover:text-blue-700 transition-colors duration-200">
              About Us
            </h2>
            <p className="text-lg text-gray-600">
              <b>
                We started this organization because of a tremendous personal
                loss of a loved one far too early from colon cancer. To honor
                their memory and giving spirit, we embarked on a journey to find
                a way to honor that legacy.
              </b>
              <br />
              <br />
              After years of diligent thought and effort, we created Prabalta.
              Prabalta roughly translates to "strength" in Sanskrit. During
              tough times, what we all need is strength.
            </p>
            <p className="text-lg text-gray-600">
              We are an organization that will be a lasting legacy, outliving
              all of us, helping other non-profits through their technological
              challenges and helping them focus on their core mission by
              utilizing cloud-based software that is easy to use and scalable.
            </p>
            <p className="text-lg text-gray-600">
              Lastly, we intend to regularly fund the goal to find a cure for
              colon cancer and honor the memory of our loved one through
              contributions to the various colon care charities and similar
              organizations.
            </p>
          </div>
        </div>
      </section>

      <div className="container-card py-10">
        <h1 className="text-2xl md:text-3xl font-semibold text-center mb-4">
          Board Members
        </h1>
        {/* <div className="flex flex-wrap justify-center gap-6 px-4">
          {boardMembers.map((data, index) => (
            <div
              key={index}
              className="board-member-card w-[90%] sm:w-[48%] md:w-[30%] lg:w-[17%] flex flex-col mx-8 justify-between"
              style={{ minHeight: "420px" }}
            >
              <div className="relative group flex justify-center items-center w-full h-[350px] overflow-hidden rounded-xl bg-white">
                <img
                  src={data.image}
                  alt={`${data.name}'s Image`}
                  className="max-w-full max-h-full object-contain"
                  onError={(e) =>
                    (e.target.src = "/assets/images/defaultUser.jpg")
                  }
                />

                <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                  <div className="flex space-x-4">
                    {data.linkedin && (
                      <a
                        href={data.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-2 bg-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-200 rounded-lg"
                        style={{ width: "40px", height: "40px" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          preserveAspectRatio="xMidYMid meet"
                          width="100%"
                          height="100%"
                        >
                          <path
                            fill="#0288D1"
                            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                          />
                          <path
                            fill="#FFF"
                            d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                          />
                        </svg>
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="text-center flex-grow px-2 animate-fadeIn transition-all duration-300 hover:bg-blue-100 hover:shadow-lg">
                <p className="name-text mb-2 text-2xl font-bold text-blue-900">
                  {data.name}
                </p>
                {data.title && (
                  <p className="title-text mb-2 text-lg text-gray-600 font-bold">
                    {data.title}
                  </p>
                )}
                <div className="flex justify-center mt-2 space-x-4">
                  {data.linkedin && (
                    <a
                      href={data.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-200 rounded-lg"
                      style={{ width: "40px", height: "40px" }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        preserveAspectRatio="xMidYMid meet"
                        width="100%"
                        height="100%"
                      >
                        <path
                          fill="#0288D1"
                          d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                        />
                        <path
                          fill="#FFF"
                          d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                        />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div> */}

        <div className=" flex flex-wrap justify-center gap-2 px-4">
          {boardMembers.map((data, index) => (
            <div
              key={index}
              className="board-member-card w-[90%] sm:w-[48%] md-lg:w-[28%] md:w-[28%] lg:w-[16%] flex flex-col mt-12 mx-4 justify-between"
              style={{ minHeight: "420px" }}
            >
              {/* Image Container */}
              <div className="flex justify-center items-center w-[95%] lg:h-[330px] h-[400px] md:h-[330px] md-lg:h-[360px] mb-2 overflow-hidden rounded-xl bg-white">
                <img
                  src={data.image}
                  alt={`${data.name}'s Image`}
                  className="w-[100%] md:h-[80%] md-lg:h-[80%] lg:h-[80%] h-[93%] object-cover rounded-xl"
                  onError={(e) =>
                    (e.target.src = "/assets/images/defaultUser.jpg")
                  }
                />
              </div>

              {/* Details Section */}
              <div className="text-center flex-grow px-2 animate-fadeIn transition-all duration-300 hover:bg-blue-100 hover:shadow-lg">
                <p
                  className="name-text mb-2 text-2xl font-bold text-blue-900"
                  style={{
                    fontSize: "1.3rem",
                  }}
                >
                  {data.name}
                </p>
                {data.title && (
                  <p className="title-text mb-2 text-lg text-gray-600 font-bold">
                    {data.title}
                  </p>
                )}
              </div>

              {/* LinkedIn Icon */}

              {/* <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-500"> */}
              <div className="flex justify-center mt-2 space-x-0.3">
                {/* {data.facebook && (
                  <a
                    href={data.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-200 rounded-lg hover:scale-110 hover:bg-blue-200" // Add hover effects here
                    style={{ width: "50px", height: "50px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <svg
                      viewBox="-5 0 20 20"
                      className="w-[100%] h-[100%]"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <title>facebook [#176]</title>{" "}
                        <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                        <g
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          {" "}
                          <g
                            id="Dribbble-Light-Preview"
                            transform="translate(-385.000000, -7399.000000)"
                            fill="#4267b2"
                          >
                            {" "}
                            <g
                              id="icons"
                              transform="translate(56.000000, 160.000000)"
                            >
                              {" "}
                              <path
                                d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                                id="facebook-[#176]"
                              >
                                {" "}
                              </path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </a>
                )}
                {data.instagram && (
                  <a
                    href={data.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-200 rounded-lg hover:scale-110 hover:bg-pink-200" // Add hover effects here
                    style={{ width: "50px", height: "50px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      id="instagram"
                    >
                      <linearGradient
                        id="a"
                        x1="1.464"
                        x2="14.536"
                        y1="14.536"
                        y2="1.464"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stop-color="#FFC107"></stop>
                        <stop offset=".507" stop-color="#F44336"></stop>
                        <stop offset=".99" stop-color="#9C27B0"></stop>
                      </linearGradient>
                      <path
                        fill="url(#a)"
                        d="M11 0H5a5 5 0 0 0-5 5v6a5 5 0 0 0 5 5h6a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5zm3.5 11c0 1.93-1.57 3.5-3.5 3.5H5c-1.93 0-3.5-1.57-3.5-3.5V5c0-1.93 1.57-3.5 3.5-3.5h6c1.93 0 3.5 1.57 3.5 3.5v6z"
                      ></path>
                      <linearGradient
                        id="b"
                        x1="5.172"
                        x2="10.828"
                        y1="10.828"
                        y2="5.172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stop-color="#FFC107"></stop>
                        <stop offset=".507" stop-color="#F44336"></stop>
                        <stop offset=".99" stop-color="#9C27B0"></stop>
                      </linearGradient>
                      <path
                        fill="url(#b)"
                        d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6.5A2.503 2.503 0 0 1 5.5 8c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5c0 1.378-1.122 2.5-2.5 2.5z"
                      ></path>
                      <linearGradient
                        id="c"
                        x1="11.923"
                        x2="12.677"
                        y1="4.077"
                        y2="3.323"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stop-color="#FFC107"></stop>
                        <stop offset=".507" stop-color="#F44336"></stop>
                        <stop offset=".99" stop-color="#9C27B0"></stop>
                      </linearGradient>
                      <circle
                        cx="12.3"
                        cy="3.7"
                        r=".533"
                        fill="url(#c)"
                      ></circle>
                    </svg>
                  </a>
                )}
                {data.twitter && (
                  <a
                    href={data.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-200 rounded-lg hover:scale-110 hover:bg-gray-200" // Add hover effects here
                    style={{ width: "50px", height: "50px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <svg
                      className="w-[100%] h-[100%]" // Adjusted size to fit within the square
                      fill="#000000"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 72 72"
                    >
                      <path
                        d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66 h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"
                        fill="#000000"
                      />
                    </svg>
                  </a>
                )}
                {data.linkedin && (
                  <a
                    href={data.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-200 rounded-lg hover:scale-110 hover:bg-blue-200" // Add hover effects here
                    style={{ width: "50px", height: "50px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      preserveAspectRatio="xMidYMid meet"
                      width="100%"
                      height="100%"
                    >
                      <path
                        fill="#0288D1"
                        d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                      />
                      <path
                        fill="#FFF"
                        d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                      />
                    </svg>
                  </a>
                )}
                {data.thread && (
                  <a
                    href={data.thread}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-200 rounded-lg hover:scale-110 hover:bg-gray-200"
                    style={{ width: "50px", height: "50px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="black" // Changed for visibility
                      width="30" // Adjusted for better display
                      height="30" // Adjusted for better display
                      viewBox="0 0 440 511" // Match the path dimensions
                    >
                      <path
                        fill-rule="nonzero"
                        d="M342.382 237.037a176 176 0 0 0-6.707-3.045c-3.947-72.737-43.692-114.379-110.428-114.805-38.505-.256-72.972 15.445-94.454 48.041l36.702 25.178c15.265-23.159 39.221-28.097 56.864-28.097q.306 0 .61.003c21.973.139 38.555 6.528 49.287 18.987 7.81 9.071 13.034 21.606 15.62 37.425-19.482-3.312-40.552-4.329-63.077-3.039-63.449 3.656-104.24 40.661-101.5 92.081 1.39 26.083 14.384 48.522 36.586 63.18 18.773 12.391 42.95 18.451 68.078 17.08 33.183-1.819 59.214-14.48 77.376-37.631 13.793-17.579 22.516-40.362 26.368-69.068 15.814 9.543 27.535 22.103 34.007 37.2 11.007 25.665 11.648 67.84-22.764 102.222-30.15 30.121-66.392 43.151-121.164 43.554-60.757-.45-106.707-19.934-136.582-57.914-27.976-35.563-42.434-86.93-42.973-152.675.539-65.745 14.997-117.113 42.973-152.675 29.875-37.979 75.824-57.464 136.581-57.914 61.197.455 107.948 20.033 138.967 58.195 15.21 18.713 26.677 42.248 34.236 69.688l43.011-11.476c-9.163-33.775-23.581-62.881-43.203-87.017C357.031 25.59 298.872.519 223.935 0h-.3C148.851.518 91.343 25.683 52.709 74.794 18.331 118.498.598 179.308.002 255.534l-.002.18.002.18c.596 76.226 18.329 137.037 52.707 180.741 38.634 49.11 96.142 74.277 170.926 74.794h.3c66.487-.462 113.352-17.868 151.96-56.442 50.511-50.463 48.991-113.717 32.342-152.548-11.944-27.847-34.716-50.464-65.855-65.402m-114.795 107.93c-27.809 1.566-56.7-10.917-58.124-37.652-1.056-19.823 14.108-41.942 59.83-44.577q7.853-.452 15.422-.45c16.609 0 32.146 1.613 46.272 4.702-5.268 65.798-36.173 76.483-63.4 77.977"
                      ></path>
                    </svg>
                  </a>
                )} */}

                {data.facebook && (
                  <a
                    href={data.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-200 rounded-lg hover:scale-110 hover:bg-blue-200"
                    style={{ width: "50px", height: "50px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      src="/assets/images/fb.png" // Replace with the actual path to your image
                      alt="Facebook"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </a>
                )}

                {data.instagram && (
                  <a
                    href={data.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-200 rounded-lg hover:scale-110 hover:bg-pink-200"
                    style={{ width: "50px", height: "50px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      src="/assets/images/instagram.png"
                      alt="Instagram"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </a>
                )}

                {data.twitter && (
                  <a
                    href={data.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-200 rounded-lg hover:scale-110 hover:bg-gray-200"
                    style={{ width: "50px", height: "50px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      src="/assets/images/twitter.png"
                      alt="Twitter"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </a>
                )}

                {data.linkedin && (
                  <a
                    href={data.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-200 rounded-lg hover:scale-110 hover:bg-blue-200"
                    style={{ width: "50px", height: "50px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      src="/assets/images/linkedin.png"
                      alt="LinkedIn"
                      className="rounded-lg"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </a>
                )}

                {data.thread && (
                  <a
                    href={data.thread}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-white bg-opacity-50 hover:bg-opacity-75 transition-all duration-200 rounded-lg hover:scale-110 hover:bg-gray-200"
                    style={{ width: "50px", height: "50px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      src="/assets/images/thread.png"
                      alt="Thread"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </a>
                )}
              </div>
            </div>
            // </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
