import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const LinkedInOAuth = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [pageId, setPageId] = useState(null);
  const location = useLocation();

  // Step 1: Redirect user to LinkedIn for authentication
  const handleLogin = () => {
    const clientId = '77eoxsx25of9bt'; // Replace with your LinkedIn Client ID
    const redirectUri = 'https://1782-59-88-27-139.ngrok-free.app/auth/linkedin/callback'; // Replace with your ngrok or production URL
    const state = 'RANDOM_STRING'; // You can generate a random string for CSRF protection
    const scope = 'r_liteprofile r_emailaddress w_member_social rw_organization_admin'; // Permissions you want to request

    const authorizationUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${encodeURIComponent(
      scope
    )}`;

    window.location.href = authorizationUrl;
  };

  // Step 2: Exchange authorization code for access token
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (code) {
      exchangeCodeForAccessToken(code);
    }
  }, [location]);

  const exchangeCodeForAccessToken = async (code) => {
    const clientId = '77eoxsx25of9bt'; // Replace with your LinkedIn Client ID
    const clientSecret = 'WPL_AP1.Vh1bhKJzzgSqvKnj.WFNSEg=='; // Replace with your LinkedIn Client Secret
    const redirectUri = 'https://1782-59-88-27-139.ngrok-free.app/auth/linkedin/callback'; // Replace with your ngrok or production URL

    try {
      const response = await axios.post(
        'https://www.linkedin.com/oauth/v2/accessToken',
        new URLSearchParams({
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: redirectUri,
          client_id: clientId,
          client_secret: clientSecret,
        })
      );

      const token = response.data.access_token;
      setAccessToken(token);
      console.log('Access Token:', token);

      // Fetch the LinkedIn Page ID after getting the access token
      fetchLinkedInPageId(token);
    } catch (error) {
      console.error('Error exchanging code for access token:', error);
    }
  };

  // Step 3: Fetch LinkedIn Page ID
  const fetchLinkedInPageId = async (token) => {
    try {
      const response = await axios.get(
        'https://api.linkedin.com/v2/organizationAcls?q=roleAssignee&role=ADMINISTRATOR',
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const organizationData = response.data.elements;
      if (organizationData.length > 0) {
        const organizationUrn = organizationData[0].organization;
        const extractedPageId = organizationUrn.split(':')[3]; // Extracting the Page ID from the URN
        setPageId(extractedPageId);
        console.log('LinkedIn Page ID:', extractedPageId);

        // You can now use the token and Page ID in the backend for posting.
      } else {
        console.error('No pages found or no admin rights for any page.');
      }
    } catch (error) {
      console.error('Error fetching LinkedIn Page ID:', error);
    }
  };

  return (
    <div>
      <h1>LinkedIn OAuth Example</h1>
      {!accessToken ? (
        <button onClick={handleLogin}>Login with LinkedIn</button>
      ) : (
        <div>
          <p>Access Token: {accessToken}</p>
          <p>Page ID: {pageId ? pageId : 'Fetching LinkedIn Page ID...'}</p>
        </div>
      )}
    </div>
  );
};

export default LinkedInOAuth;
