import React from "react";
import { Link } from "react-router-dom";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";

const WebsiteDashboard = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <DonorManagementTop />
      <main className="flex-grow flex justify-center items-center">
        <div className="container px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-center items-center gap-10">
          <div className="bg-white shadow-lg border-b-4 border-transparent group cursor-pointer transition ease-in-out delay-150 hover:border-[#ff7b00] duration-300 w-[300px] h-auto">
            <Link to="/createDomain">
              <div className="relative">
                <img
                  src="assets/images/finance.jpg"
                  alt="Manage Sub-Domain"
                  className="w-full h-[180px] object-cover"
                />
              </div>
              <div className="pt-5 px-2 text-center">
                <h2 className="md:text-xl font-semibold">
                  Manage your <br /> Sub-Domain
                </h2>
              </div>
            </Link>
          </div>

          <div className="bg-white shadow-lg border-b-4 border-transparent group cursor-pointer transition ease-in-out delay-150 hover:border-[#ff7b00] duration-300 w-[300px] h-auto">
            <Link to="/money-details">
              <div className="relative">
                <img
                  src="assets/images/finance.jpg"
                  alt="Admin Center"
                  className="w-full h-[180px] object-cover"
                />
              </div>
              <div className="pt-5 px-2 text-center">
                <h2 className="md:text-xl font-semibold">Admin Center</h2>
              </div>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default WebsiteDashboard;
