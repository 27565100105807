import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Header from "../Home/Header";
import ProgressBar from "../Common/ProgressBar";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DatePickerInput } from "@mantine/dates";
import { TQ_URL } from "../../utility/baseUrl";
import DonorManagementTop from "./DonorManagementTop";

import DonorManagementSideBar from "./DonorManagementSideBar";
import { Select, TextInput, Button } from "@mantine/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TopHeader from "../Home/TopHeader";
import { Spinner } from "@material-tailwind/react";
import CreateFund from "./CreateFund";



const schema = yup.object().shape({
  // donor: yup.string().required("Donor is required"),
  donationDate: yup.date().required("Donation date is required"),
  donationAmount: yup
    .number()
    .required("Donation amount is required")
    .positive("Donation amount should be positive or non-zero")
    .typeError("Donation amount should be a number"),
  fundraiserId: yup.string().required("Fundraiser is required"),
});

const AddDonation = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [donorName, setDonorName] = useState();
  const [donorId, setDonorId] = useState();
  const [fundraiserOptions, setFundraiserOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [modalopen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fundraiserId: "",
    },
  });
  // https://prabalta.org:8087/prabaltatwo/oauth2/authorize/google?redirect_uri=https://prabaltauat.prabalta.org/oauth2/redirect
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const token = localStorage.getItem("accessToken");
  useEffect(() => {
    // Fetch fundraiser data from API
    const fetchFundraiserData = async () => {
      try {
        const response = await axios.get(
          `${TQ_URL}/getAllPartnerFundraiser?partnerId=${partnerData.partnerId}`,
          {
            headers: {
              Authorization: token, // Pass the authorization token
              "Content-Type": "application/json", // Specify the content type
            },
          }
        );

        const { data } = response;
        const fundList = data && data.fundList ? data.fundList : [];

        if (fundList != "Fundraiser List is Empty")
          setFundraiserOptions(fundList);
        else setFundraiserOptions([]);
      } catch (error) {
        console.error("Error fetching fundraiser data:", error);
      }
    };

    const fetchUserList = async () => {
      try {
        const response = await axios.get(
          `${TQ_URL}/getAllDonorForDropdown?partnerId=${partnerData.partnerId}`,
          {
            headers: {
              Authorization: token, // Pass the authorization token
              "Content-Type": "application/json", // Specify the content type
            },
          }
        );

        // Extract the fundraisersList array from the response data
        const { data } = response;
        const userList = data && data.userList ? data.userList : [];

        console.log(userList);

        if (userList != "NO User Exsist") setUsers(userList);
        else setUsers([]);
      } catch (error) {
        console.error("Error fetching Donor data:", error);
      }
    };

    fetchUserList();
    fetchFundraiserData();
  }, [modalopen]); // Empty dependency array ensures this effect runs only once on component mount

  const successFormSubmit = () => toast.success("Form Submitted Successfully!");

  const selectedFundraiserId = watch("fundraiserId");

  const handleSelectDonor = (event) => {
    const selectedDonorId = event.target.value;
    setDonorId(selectedDonorId); // Set the selected donor ID
    console.log("Selected Donor Id", donorId);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      console.log(localStorage.getItem("fundraiserId"));
      const fundraiserId = parseInt(localStorage.getItem("fundraiserId"));
      const donorName = localStorage.getItem("donor");
      const donorId = localStorage.getItem("donorID");
      setDonorId(donorId);
      setDonorName(donorName);
      console.log("DOnar name ", localStorage.getItem("donor"));
      console.log("Data type of donorName:", typeof donorName);

      if (fundraiserId) {
        setValue("fundraiserId", fundraiserId);
      }
    };

    fetchInitialData();
  }, [setValue]);

  const onSubmitHandler = async (data) => {
    console.log("This is data", data);

    setLoading(true);

    try {
      const response = await axios.post(
        `${TQ_URL}/saveDonationMannualy`,

        {
          donor: donorId,
          donationDate: data.donationDate,
          donationAmount: data.donationAmount,
          fundraiserId: data.fundraiserId,
          partnerId: partnerData.partnerId,
        },
        {
          headers: {
            Authorization: token, // Pass the authorization token
            "Content-Type": "application/json", // Specify the content type
          },
        }
      );

      if (response.status === 200) {
        successFormSubmit();

        localStorage.removeItem("fundraiserId");
        navigate(-1);
      } else {
        toast.error("Error saving donation. Please try again later.");
      }
    } catch (error) {
      toast.error("Error saving donation. Please try again later.");
    }
    setLoading(false);
  };

  const handleAddFund = () => {
    setModalOpen(true);
  };

  const formatCurrency = (value) => {
    if (!value) return "";
    const options = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    };
    return new Intl.NumberFormat("en-US", options).format(value);
  };

  const handleDonationAmountChange = (e, onChange) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value === "") {
      onChange(null);
      return;
    }
    value = parseInt(value, 10);
    onChange(value);
  };
  return (
    <>
      {/* <TopHeader /> */}
      <div id="donation-step" className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px]">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Add Donation</h2>
              </div>
            </div>
          </div>

          <div className="md:pb-20 md:flex justify-between">
            <div className="md:w-full m-4 md:m-0">
              {loading ? (
                <div className="flex items-center justify-center h-[200px]">
                  {/* <Spinner  color="blue" /> */}
                </div>
              ) : (
                <form
                  onSubmit={handleSubmit(onSubmitHandler)}
                  className="fundraiser-for-other-form md:py-12 md:px-20 p-6 md:me-20 md:ml-[100px] md:mt-10"
                >
                  {/* Donor Dropdown */}
                  <div className="mb-6 md:flex">
                    <label>Donor</label>

                    {donorName != "null" ? (
                      <div className="w-full">
                        <input
                          placeholder="Donor Name"
                          value={donorName}
                          type="text"
                          disabled
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        />
                      </div>
                    ) : (
                      <div className="w-full">
                        {users.length > 0 ? (
                          <>
                            {/* Replace this dropdown with your actual donor selection dropdown */}
                            <select
                              {...register("donor")}
                              onChange={handleSelectDonor}
                              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                                errors.donor && "border-red-500"
                              }`}
                            >
                              <option value="">Select donor...</option>
                              {users.map((currentUser) => (
                                <option
                                  key={currentUser.importedDonorId}
                                  value={currentUser.importedDonorId}
                                >
                                  {currentUser.donorName}
                                </option>
                              ))}
                            </select>
                          </>
                        ) : (
                          <div className="flex items-center justify-between">
                            <span>No users available.</span>
                            <Link to={`/addDonorManually`}>
                              <button
                                className="btn-style-1 text-white py-1 px-1 md:py-1  md:px-2 rounded  md:block mx-2"
                                // onClick={() => handleAddFund()}
                              >
                                Add Donor
                              </button>
                            </Link>
                          </div>
                        )}
                      </div>
                    )}

                    {/* {donorName ==null ?
                        <p className="text-red-500 text-sm mt-1">
                      {errors.donor?.message} </p>: null} */}
                  </div>

                  {/* Donation Date */}
                  <div className="mb-6 md:flex">
                    <label>Donation Date</label>
                    <div className="w-full">
                      <Controller
                        name="donationDate"
                        control={control}
                        render={({ field }) => (
                          <DatePickerInput
                            placeholder="Pick date"
                            {...field}
                            required
                            className={`bg-gray-50  border-gray-300 text-gray-900 text-sm focus:border-blue-500 ${
                              errors.donationDate && "border-red-500"
                            }`}
                            onChange={(value) => {
                              // Parse the string into a Date object if it's not already a Date
                              const dateValue =
                                value instanceof Date
                                  ? value
                                  : typeof value === "string"
                                  ? new Date(value)
                                  : null;

                              field.onChange(dateValue);
                            }}
                            minDate={new Date(0)} // Set minDate to the beginning of time
                            maxDate={null} // Set maxDate to null to allow all past dates
                          />
                        )}
                      />
                      {errors.donationDate && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.donationDate.message}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Donation Amount */}
                  <div className="mb-6 relative md:flex">
                    <label>Donation Amount</label>
                    <div className="w-full relative">
                      <Controller
                        name="donationAmount"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field} // Attach react-hook-form field handlers
                              type="text" // Use text to allow currency formatting
                              placeholder="Donation Amount"
                              value={
                                field.value ? formatCurrency(field.value) : ""
                              }
                              onChange={(e) =>
                                handleDonationAmountChange(e, field.onChange)
                              }
                              onInput={(e) => {
                                const rawValue = parseFloat(
                                  e.target.value.replace(/[^0-9.-]+/g, "")
                                );
                                if (rawValue < 1) {
                                  e.target.value = "";
                                }
                              }}
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                                errors.donationAmount && "border-red-500"
                              }`}
                            />
                            <p className="text-red-500 text-sm mt-1">
                              {errors.donationAmount?.message}
                            </p>
                          </>
                        )}
                      />
                    </div>
                  </div>

                  {/* Fundraiser Dropdown */}
                  <div className="mb-6 md:flex">
                    <label>Fundraiser</label>

                    {fundraiserOptions.length > 0 ? (
                      <div className="w-full">
                        <select
                          {...register("fundraiserId")}
                          value={selectedFundraiserId}
                          onChange={(e) =>
                            setValue("fundraiserId", e.target.value)
                          }
                          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                            errors.fundraiserId && "border-red-500"
                          }`}
                        >
                          <option value="">Select fundraiser...</option>
                          {fundraiserOptions.map((fundraiser) => (
                            <option
                              key={fundraiser.fundId}
                              value={fundraiser.fundId}
                            >
                              {fundraiser.fundName}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <div className="w-full flex items-center justify-between">
                        <span>No Fundraisers available.</span>
                        <button
                          className="btn-style-1 text-white py-1 px-1 md:py-1  md:px-2 rounded  md:block mx-2"
                          onClick={() => handleAddFund()}
                        >
                          Add Fund
                        </button>
                      </div>
                    )}
                    <p className="text-red-500 text-sm mt-1">
                      {errors.fundraiserId?.message}
                    </p>
                  </div>

                  {/* Submit Button */}
                  <div className="mb-6 md:flex justify-center">
                    <button
                      type="submit"
                      className="w-1/3 text-white p-2 rounded-md btn-style-1 mt-4 "
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
          {modalopen && <CreateFund onClose={() => setModalOpen(false)} />}
        </main>
      </div>
      <ToastContainer theme="colored" />
    </>
  );
};

export default AddDonation;
