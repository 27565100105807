import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function Message({ content }) {
    return <p>{content}</p>;
}

function PayPalCheckout({ amount, fundraiserId, name, email, phone }) {
    const initialOptions = {
        "client-id": "AapYhRuiFsaWaUMtFBYwKZE8I9IaMFSt9r-y-3T34jf6IWmNh-2T9OMWbqsUJRUNpdb49PlxWThx_aN-",
        "enable-funding": "venmo",
        "disable-funding": "paylater",
        currency: "USD",
        components: "buttons",
        locale: "en_US",
    };

    const [message, setMessage] = useState("");

    return (
        <div className="App">
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    style={{
                        shape: "rect",
                        layout: "vertical",
                        color: "gold",
                        label: "donate",
                    }}
                    createOrder={async () => {
                        try {
                            const response = await fetch("http://localhost:8081/api/orders", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    cart: [
                                        {
                                            id: fundraiserId,
                                            name,
                                            email,
                                            phone,
                                            unitAmount: amount ? amount.toString() : "10.00",
                                        },
                                    ],
                                    application_context: {
                                        brand_name: "Prabalta",
                                        landing_page: "BILLING",
                                        shipping_preference: "NO_SHIPPING",
                                        user_action: "PAY_NOW",
                                    },
                                }),
                            });
                            const orderData = await response.json();

                            if (orderData.id) {
                                return orderData.id;
                            } else {
                                const errorDetail = orderData?.details?.[0];
                                const errorMessage = errorDetail
                                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                    : JSON.stringify(orderData);
                                throw new Error(errorMessage);
                            }
                        } catch (error) {
                            console.error("Error creating order:", error);
                            setMessage(`Could not initiate PayPal Checkout... ${error}`);
                        }
                    }}
                    onApprove={async (data, actions) => {
                        try {
                            const response = await fetch(`http://localhost:8081/api/orders/${data.orderID}/capture`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            });

                            const orderData = await response.json();
                            const errorDetail = orderData?.details?.[0];

                            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                return actions.restart();
                            } else if (errorDetail) {
                                throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                            } else {
                                const transaction = orderData.purchase_units[0].payments.captures[0];
                                setMessage(`Donation ${transaction.status}: ${transaction.id}. Thank you for your support!`);
                                console.log("Capture result", orderData, JSON.stringify(orderData, null, 2));
                            }
                        } catch (error) {
                            console.error("Error capturing order:", error);
                            setMessage(`Sorry, your donation could not be processed... ${error}`);
                        }
                    }}
                    onError={(err) => {
                        console.error("PayPal error:", err);
                        setMessage("An error occurred during the transaction.");
                    }}
                />
            </PayPalScriptProvider>
            <Message content={message} />
        </div>
    );
}

export default PayPalCheckout;
