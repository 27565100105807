import React, { useEffect, useState, useMemo } from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "../Pagination";
import { TQ_URL } from "../../utility/baseUrl";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import fetchConfig from "../../config"; // Import the fetchConfig function
import { Triangle } from "react-loader-spinner"; // Import Triangle loader

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

let PageSize = 12;
var pageUrl;

const pageTitle = "Your Page Title";
const pageDescription = "Description of your content";
const BrowseFundraiser = () => {
  const [counter, setCounter] = useState(1);
  const [heading, setHeading] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [fundraisers, setFundraisers] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [partnerId, setPartnerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [config, setConfig] = useState({}); // State to store config data
  const [pageBaseUrl, setPageBaseUrl] = useState("");

  const { state } = useLocation();
  const navigate = useNavigate();

  let initialKeyword = state?.keyword || ""; // Set to an initial value or an empty string
  const [keyword, setKeyword] = useState(initialKeyword);

  useEffect(() => {
    setKeyword(initialKeyword);
    setCurrentPage(1);
  }, [initialKeyword]);

  const {
    formState: { errors, isValid },
    setValue,
  } = useForm();
  const [showRelationDropdown, setShowRelationDropdown] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter < 10) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 100);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [counter]);
  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        const baseUrl = data.redirect_uri.replace(
          "oauth2/redirect",
          "fundraiser"
        );
        setPageBaseUrl(baseUrl);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Fetch config and set partnerId
    const fetchPartnerId = async () => {
      const config = await fetchConfig();
      setPartnerId(config.partnerId);
    };

    fetchPartnerId();
  }, []);

  useEffect(() => {
    if (partnerId) {
      if (keyword !== undefined && keyword !== null && keyword !== "") {
        fetchSearchedFundraiserData(currentPage);
      } else {
        fetchFundraiserData(currentPage);
      }
    }
  }, [keyword, currentPage, partnerId]);

  const fundraiserList = useMemo(() => {
    return fundraisers;
  }, [PageSize, fundraisers]);

  const shareOnWhatsApp = (fundraiserId) => {
    const pageUrl = `${pageBaseUrl}/${fundraiserId}`;

    const shareText = "Check out this fundraiser on my website!";

    console.log("share on whats app called");
    // Replace 'your-share-url' with the actual URL you want to share
    const whatsappLink = `https://api.whatsapp.com/send?text=${`${shareText} ${pageUrl}`}`;

    // Open a new window with the WhatsApp share link
    window.open(whatsappLink, "_blank");
  };

  const shareOnTwitter = (fundraiserId) => {
    console.log("sharing the content on twitter");
    const pageUrl = `${pageBaseUrl}/${fundraiserId}`;
    const shareText = encodeURIComponent(
      "Check out this fundraiser on my website!"
    );
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareText}&url=${encodeURIComponent(
      pageUrl
    )}`;

    window.open(twitterShareUrl, "_blank");
  };
  const shareOnFacebook = (fundraiserId) => {
    const pageUrl = `${pageBaseUrl}/${fundraiserId}`;
    const shareText = encodeURIComponent(
      "Check out this fundraiser on my website!"
    );

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      pageUrl
    )}&quote=${shareText}`;

    //   `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pageUrl)}&quote=${shareText}`

    window.open(facebookLink, "_blank");
  };

  const fetchFundraiserData = async (page) => {
    try {
      setHeading(1);
      setLoading(true);
      setError(null);
  
      // Get the browser's timezone
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
      const response = await axios.get(
        `${TQ_URL_USPROJECT}/browseFundraiserList?page=${page}&partnerId=${partnerId}&timeZone=${encodeURIComponent(timeZone)}`
      );
      console.log(response.data.datalist);
      if (response.data.message) {
        setError(response.data.message);
      } else {
        setFundraisers(response.data.datalist);
        setTotalCount(response.data.totalCount);
      }
    } catch (error) {
      setError("Error fetching fundraisers: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  

  const fetchSearchedFundraiserData = async (page) => {
    try {
      setLoading(true);
      setHeading(2);
      setError(null);
      const response = await axios.post(
        `${TQ_URL_USPROJECT}/searchFundraiserList?page=${page}&size=${PageSize}`,
        {
          keywordText: keyword,
          partnerId: partnerId,
        }
      );
      if (response.data.message) {
        setError(response.data.message);
      } else {
        setFundraisers(response.data.datalist);
        setTotalCount(response.data.totalCount);
      }
    } catch (error) {
      setError("Error fetching fundraisers: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleImageError = (event) => {
    event.target.src = "assets/images/defaultFund.jpg";
  };

  const handleCreateFundraiserClick = () => {
    const localStorageData = JSON.parse(localStorage.getItem("userData"));
    if (
      localStorageData != null &&
      localStorage.getItem("accessToken") !== null
    ) {
      navigate("/createfundraiser");
    } else {
      navigate("/login");
    }
  };

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const { register: registerRelation } = useForm();

  return (
    <>
      <Header />

      <div className="flex items-center justify-center">
        <div className="flex-grow text-center">
          {heading == 1 && (
            <h2 className="text-xl md:text-3xl font-bold mb-4 text-center  md:py-10 py-5">
              Browse Fundraisers
            </h2>
          )}

          {heading == 2 && (
            <h2 className="text-xl md:text-3xl font-bold mb-4 text-center  md:py-10 py-5">
              Searched Fundraisers
            </h2>
          )}
        </div>

        {/* <div className="flex justify-end md:mr-[130px]">
          <button
            className="btn-style-1 py-2 text-white px-10 rounded mb-4 "
            onClick={handleCreateFundraiserClick}
          >
            Create
          </button>
        </div> */}
      </div>

      <div className="container bg-gradient-to-r from-blue-50 to-white mx-auto py-10 padding-x">
        <div
          className={`grid-container grid gap-x-8 justify-center ${
            fundraiserList.length === 1
              ? "grid-cols-1"
              : fundraiserList.length === 2
              ? "grid-cols-2"
              : "grid-cols-3"
          }`}
        >
          {loading ? (
            <div className="fixed top-0 left-0 right-0 flex items-center justify-center h-full bg-black bg-opacity-50 z-50">
              <Triangle
                height="120"
                width="120"
                color="blue"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                visible={true}
              />
            </div>
          ) : error ? (
            <p className="col-span-full mx-auto text-center text-lg font-semibold mt-5">
              {error}
            </p>
          ) : fundraiserList.length === 0 ? (
            <p className="col-span-full mx-auto text-center text-lg font-semibold mt-5">
              No Data Found
            </p>
          ) : (
            fundraiserList.map((data, index) => (
              <Link to={`/fundraiser/${data.fundraiserId}`} key={data.id}>
                <div
                  className="donation-boxBrowseFund bg-white rounded-lg shadow-lg  flex flex-col mx-auto justify-between"
                  style={{ minHeight: "420px" }} // Adjust height if necessary
                >
                  {/* Image */}
                  {data.imageList && data.imageList.length > 0 ? (
                    <img
                      src={data.imageList[0]}
                      alt="Fundraiser Image"
                      className="w-full object-cover mb-4 h-[200px] rounded-lg"
                      onError={(e) =>
                        (e.target.src = "/assets/images/defaultFund.jpg")
                      }
                    />
                  ) : (
                    <img
                      src="/assets/images/defaultFund.jpg"
                      alt="Default Image"
                      className="w-full object-cover mb-4 h-[200px] rounded-lg"
                    />
                  )}

                  {/* Text */}
                  <div className="text-center flex-grow px-2">
                    <p className="description-text mb-4 truncate">
                      <span className="font-bold">{data.fundraiserName}</span>
                      <br />
                      {data.title.length < 25
                        ? data.title
                        : data.title.substring(0, 25) + "..."}
                    </p>

                    {/* Donation Info */}
                    <p className="donation-info-text flex justify-between mb-2">
                      <span>
                        <strong>
                          $
                          {data.amtAchieved.toLocaleString("en-US", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </strong>{" "}
                        Raised
                      </span>
                      <span>{data.amountAchivedInPercetage}%</span>
                    </p>

                    {/* Progress Bar */}
                    <div className="progress-bar-green mb-4">
                      <div
                        className="progress-bar-fill-green"
                        style={{ width: `${data.amountAchivedInPercetage}%` }}
                      />
                    </div>
                  </div>

                  {/* Donate Button */}
                  <div className="mt-4 text-center">
                    <button className="btn-style-1 py-2 text-white px-6 rounded w-[60%]">
                      Donate Now
                    </button>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>

      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />

      <ToastContainer theme="colored" />

      <Footer />
    </>
  );
};

export default BrowseFundraiser;
