import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TQ_URL } from "../../utility/baseUrl"; // Adjust the base URL accordingly

const ThreadOAuthPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Handle OAuth callback and token exchange
  useEffect(() => {
    const handleOAuthCallback = async () => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get("code"); // Capture the authorization code

      if (code) {
        try {
          // Send the code to the backend for token exchange
          const response = await fetch(`${TQ_URL}/exchange-code`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ code }), // Send code to backend
          });

          const result = await response.json();

          if (response.ok) {
            console.log("Long-lived access token received:", result);
            // Navigate to a confirmation or success page
            navigate("/connectedChannels");
          } else {
            console.error("Error exchanging code for token:", result.message);
          }
        } catch (error) {
          console.error("OAuth Callback Error:", error);
        }
      }
    };

    handleOAuthCallback();
  }, [location, navigate]);

  return <div>Processing Threads OAuth...</div>;
};

export default ThreadOAuthPage;
