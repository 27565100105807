import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TQ_FRONTEND_URL, TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import SuccessLogin from "../Profile/SuccessLogin";
import { Button, Modal, Select, TextInput } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import confetti from "canvas-confetti"; // Import the confetti library

import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { postWithTokenWithDataReturnDate } from "../../utility/apiRequest";

// Define validation schema

const adminSchema = yup.object().shape({
  email: yup.string().email().required("Email is Required * "),
  password: yup.string().required("Password is Required *"),
});

// Subadmin schema with only email
const subadminSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is Required *"),
});

function toInitCap(str) {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

// Define NgoLogin component
const NgoLogin = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userType, setUserType] = useState("");
  const [authorized, setAuthorized] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let flag = queryParams.get("flag"); // Use let instead of const

  const [selectedType, setSelectedType] = useState("admin");
  const [showSubadminModal, setShowSubadminModal] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [organizationData, setOrganizationData] = useState([]);

  const [subAdminPassword, setSubadminPassword] = useState("");
  const [subAdminEmail, setSubAdminEmail] = useState("");
  const handleselectedTypeChange = (type) => {
    setSelectedType(type);
  };

  // Retrieve UserType from cookies

  const triggerGlitterConfetti = () => {
    confetti({
      particleCount: 200,
      spread: 120,
      origin: { x: 0.5, y: 0.5 },
      colors: ["#FFC107", "#FF5722", "#8BC34A", "#00BCD4", "#9C27B0"],
      ticks: 500,
      startVelocity: 50,
      gravity: 0.6,
    });
  };

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        triggerGlitterConfetti();
      }, 100); // Sync with bounce animation (0.8s delay)

      // Cleanup function to clear the timeout
      return () => clearTimeout(timer);
    }
  }, [showSuccessModal]); // Dependency array to watch for changes in showSuccessModal

  useEffect(() => {
    // Determine if we're running on localhost or prabalta.org
    const isLocalhost = window.location.hostname.includes("localhost");
    // const domain = isLocalhost ? "localhost" : ".prabalta.org";
    const domain = isLocalhost ? "localhost" : "prabalta.org";

    // Set the UserType cookie
    Cookies.set("UserType", "Individual", {
      domain: isLocalhost ? undefined : domain, // undefined domain for localhost, ".prabalta.org" for production
      path: "/", // Always root path
      secure: !isLocalhost, // Secure cookies in production (HTTPS), not for localhost
      sameSite: isLocalhost ? "Lax" : "None", // Lax for localhost, None for cross-site requests in production
      expires: 1, // 1 day expiration
    });

    // Retrieve the UserType cookie and set the state
    const userTypeCookie = Cookies.get("UserType");
    setUserType(userTypeCookie);
  }, []);

  const schema = selectedType === "admin" ? adminSchema : subadminSchema;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(`${TQ_URL}/user`, {
        method: "GET",
        headers: {
          Authorization: "Basic " + btoa(data.email + ":" + data.password),
          "Content-Type": "application/json",
          "X-User-Type": Cookies.get("UserType"),
          "Partner-Id": 1,
        },
      });

      setLoading(false);
      // const res = await response.json();
      // console.log(res);

      // if (!response.ok) {
      //   const errorData = await response.json();
      //   toast.error("Invalid Credentials");
      //   return;
      // }

      if (!response.ok) {
        const errorData = await response.json();

        if (response.status === 404) {
          toast.error("User account does not exist..Please Register");
        } else {
          toast.error("Invalid Credentials");
        }

        return;
      }

      const authorizationHeader = response.headers.get("Authorization");
      const token = authorizationHeader;

      if (token) {
        toast.success("Logged In Successfully!");
        localStorage.setItem("accessToken", token);
        const userData = await response.json();
        console.log("This is my user data------------->", userData);

        const user = userData?.user;
        const userType = user?.userType || userData?.userType;

        localStorage.setItem("loggedIn", true);
        if (userType === "Individual") {
          localStorage.setItem(
            "userData",
            JSON.stringify({
              userId: userData.user.userId,
              userName: toInitCap(userData.user.userName),
              email: userData.user.email,
              phone: userData.user.phone,
              userType: userType,
              imageUrl: userData.user.imageUrl,
            })
          );
          setUserName(toInitCap(userData.user.userName));

          if (userData.addressDetail === null) {
            flag = "socialAddress";
          } else {
            flag = "socialLogin";
          }
          localStorage.setItem(
            "userAddressDetails",
            JSON.stringify({ addressDetails: userData.addressDetail })
          );
        } else if (userType === "partner") {
          console.log("Inside partner data");

          localStorage.setItem(
            "partnerData",
            JSON.stringify({
              partnerId: userData.partner.partnerId,
              orgName: userData.partner.orgName
                ? toInitCap(userData.partner.orgName)
                : null,
              contactPersonName: toInitCap(userData.partner.contactPersonName),
              email: userData.partner.email,
              contactNumber: userData.partner.contactNumber,
              userType: userType,
              imageUrl: userData.partner.imageUrl,
              addressOne: userData.partner.addressOne
                ? toInitCap(userData.partner.addressOne)
                : null,
              addressTwo: userData.partner.addressTwo
                ? toInitCap(userData.partner.addressTwo)
                : null,
              city: userData.partner.city
                ? toInitCap(userData.partner.city)
                : null,
              state: userData.partner.state
                ? toInitCap(userData.partner.state)
                : null,
              zipCode: userData.partner.zipCode,
              role: "partner",
            })
          );
          console.log("userData inside partnerId", userData.user2);

          localStorage.setItem(
            "userData",
            JSON.stringify({
              userId: userData.user2.userId,
              userName: toInitCap(userData.user2.userName),
              email: userData.user2.email,
              phone: userData.user2.phone,
              userType: userType,
              imageUrl: userData.user2.imageUrl,
            })
          );

          setUserName(toInitCap(userData.partner.orgName));
          if (userData.partner.zipCode === null) {
            flag = "ngoAddress";
          } else {
            flag = "partner";
          }
        }

        setShowSuccessModal(true);

        // Check if donationData and redirect path are set

        const donationData = localStorage.getItem("donationData");
        const redirectAfterLogin = location.state?.redirectAfterLogin;

        if (donationData && redirectAfterLogin === "/donate") {
          // Wait for a brief period to show the success modal before navigating
          setTimeout(() => {
            navigate("/donate", { state: JSON.parse(donationData) });
            localStorage.removeItem("donationData"); // Clear donation data after redirecting
          }, 2000); // 2 seconds delay

          // Exit function to prevent further execution and any conditional redirects
          return;
        }
        setTimeout(() => {
          setShowSuccessModal(false);

          switch (flag) {
            case "donor":
              navigate("/donorlist");
              break;
            case "social":
              navigate("/schedulePost");
              break;
            case "email":
              navigate("/mailingList");
              break;
            case "report":
              navigate("/analyticsDashboard");
              break;
            case "website":
              navigate("/createDomain");
              break;
            case "ngoAddress":
              navigate(
                `/add-ngo-addressdetails?email=${encodeURIComponent(
                  userData.partner.email
                )}&userType=${encodeURIComponent(userType)}`
              );
              break;
            case "socialAddress":
              navigate("/addaddressdetails-user");
              break;
            case "partner":
              navigate("/services-dashboard");
              break;
            case "socialLogin":
              navigate("/home");
              break;
            default:
              navigate("/services-dashboard");
          }
        }, 2000);
      } else {
        throw new Error("Token not found in response");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.message || "An unexpected error occurred. Please try again later."
      );
      setLoading(false);
    }

    reset();
  };

  const getUserTypeText = () => {
    if (userType === "Organization") {
      return (
        <>
          Prabalta.org is a non-profit organization that offers comprehensive or
          customizable support solutions for other non-profits.
          <br />
          <br />
          Let us handle the routine, time-consuming, and tech-related tasks so
          you can focus on your core mission.
          <br />
          <br />
          Leverage our robust infrastructure to serve your community, just like
          the larger organizations do.
        </>
      );
    } else {
      return (
        <>
          Prabalta.org is a non-profit that provides support for other
          non-profits as a turnkey solution or choose as go model.
          <br />
          <br />
          Leave the mundane, tedious, information technology, and labor
          intensive work to us and concentrate on your non-profit’s primary
          mission.
          <br />
          <br />
          Serve your community utilizing our strong infrastructure backbone like
          larger organizations.
        </>
      );
    }
  };

  const googleOAuthUrl =
    TQ_URL + "/oauth2/authorize/google?redirect_uri=" + TQ_FRONTEND_URL;

  const facebookOAuthUrl =
    TQ_URL + "/oauth2/authorize/facebook?redirect_uri=" + TQ_FRONTEND_URL;

  const microsoftOAuthUrl =
    TQ_URL + "/oauth2/authorize/microsoft?redirect_uri=" + TQ_FRONTEND_URL;

  // Subadmin login flow

  const onSubmitSubadmin = async (data) => {
    console.log("INside subadmin function", data);
    const requestPayload = {
      email: data.email,
    };

    try {
      const endPoint = "getSubadminAccountList";
      const response = await postWithTokenWithDataReturnDate(
        `${TQ_URL}/${endPoint}`,
        requestPayload
      );
      console.log("----------------->", response.data);

      if (response.status === 200) {
        if (response.data && !response.data[0].error) {
          // No error, map the response data to organizationData
          setOrganizationData(
            response.data.map((org) => ({
              id: org.partnerId, // Mapping partnerId to id
              name: org.orgName, // Mapping orgName to name
            }))
          );
          setSubAdminEmail(data.email);
          setShowSubadminModal(true);
        } else if (response.data[0].error) {
          // Handle case where there is an error in the response
          toast.error(response.data[0].error);
        }
      }
    } catch (error) {
      console.error(
        "Error occurred while fetching subadmin account list:",
        error
      );
      // Optionally show an error message to the user
    }
  };

  // const organizationData = [
  //   { id: 1, name: 'Organization A' },
  //   { id: 2, name: 'Organization B' },
  //   { id: 3, name: 'Organization C' },
  //   { id: 4, name: 'Organization D' },
  //   { id: 5, name: 'Organization E' },
  //   { id: 6, name: 'Organization A' },
  //   { id: 7, name: 'Organization B' },
  //   { id: 8, name: 'Organization C' },
  //   { id: 9, name: 'Organization D' },
  //   { id: 10, name: 'Organization E' },
  // ];

  const handleToLoginSubadmin = async () => {
    if (!selectedOrg) {
      toast.error("Please select an organization.");
      return;
    }

    if (!subAdminPassword) {
      toast.error("Please enter your password.");

      return;
    }
    try {
      const response = await fetch(`${TQ_URL}/user`, {
        method: "GET",
        headers: {
          Authorization:
            "Basic " + btoa(subAdminEmail + ":" + subAdminPassword),
          "Content-Type": "application/json",
          "X-User-Type": "Subadmin",
          "Partner-Id": selectedOrg,
        },
      });

      const subData = await response.json();

      if (response.status === 200) {
        const authorizationHeader = response.headers.get("Authorization");
        const token = authorizationHeader;
        toast.success("Subadmin Logged in Successfully!");

        localStorage.setItem(
          "partnerData",
          JSON.stringify({
            subadminId: subData.subadmin.subAdminId,
            partnerId: subData.subadmin.partnerLoginDetails.partnerId,
            orgName: subData.subadmin.partnerLoginDetails.orgName
              ? toInitCap(subData.subadmin.partnerLoginDetails.orgName)
              : null,
            contactPersonName: toInitCap(subData.subadmin.subAdminName),
            email: subData.subadmin.subAdminName,
            userType: subData.userType,
          })
        );

        const getSubadmin = localStorage.getItem("partnerData");
        console.log(getSubadmin);

        setTimeout(() => {
          navigate("/services-dashboard");
        }, 2000);

        setShowSubadminModal(false);
        // Optionally redirect or perform any other action on success
      } else {
        toast.error(subData.message);
      }
    } catch (error) {
      console.error("Error occurred during login:", error);
      alert("An error occurred while signing in.");
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen overflow-hidden overflow-x-hidden">
      {/* Left Section */}
      <div className="md:flex-1 bg-cover bg-center relative fade-in-left login-bg">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0 flex flex-col items-start justify-end md:px-10 px-5">
          <h3 className="text-white txt-style-1 mb-5 italic text-base leading-relaxed">
            {getUserTypeText()}
          </h3>
        </div>
      </div>

      {/* Right Section */}
      <div className="md:flex-1 flex flex-col divide-y items-center justify-start md:ms-0 max-w-screen-lg w-full h-full fade-in-right">
        <div className="m-auto w-full h-full md:mb-1">
          <div className="relative mb-4">
            <img
              src="assets/images/JoinUs3.jpg"
              alt="welcome"
              className="w-full h-[320px] object-cover md:mb-4 items-center mx-auto"
            />
            <div className="absolute inset-0 flex flex-col items-start justify-end md:px-10 px-5">
              <Link to="/" className="absolute top-0 right-4 mt-2 ml-2">
                <img
                  src="assets/images/main-logo.svg"
                  alt="Logo"
                  className="h-8 sm:mx-0"
                />
              </Link>
            </div>
            <Link to="/ngo-create-account">
              <button className="absolute bottom-0 right-4 mb-[-15px] h-[50px] w-[150px] md:h-[60px] md:w-[200px]  btn-style-1 text-white py-2 px-4 rounded-md hover:bg-blue-600 flex justify-center items-center">
                Join Now
              </button>
            </Link>
          </div>
        </div>

        <div className="max-w-full w-full sm:max-w-lg 2xl:max-w-2xl border-none">
          {/* Logo */}
          {/* <div className="text-center">
            <h2 className="text-xl md:text-2xl font-bold mb-2">Login</h2>
          </div> */}
          <div className="text-center mb-6">
            <h2 className="text-2xl md:text-3xl font-extrabold text-gray-800 mb-4">
              Login
            </h2>

            {/* Admin & Subadmin Buttons */}
            {userType === "Organization" ? (
              <div className="flex justify-center gap-4 mb-6">
                <button
                  onClick={() => handleselectedTypeChange("admin")}
                  className={`px-4 py-2 rounded-md border ${selectedType === "admin"
                    ? " btn-style-1 text-white "
                    : "border-blue-500 text-blue-500"
                    } hover:bg-blue-500 hover:text-white transition-all`}
                >
                  Admin
                </button>
                <button
                  onClick={() => handleselectedTypeChange("subadmin")}
                  className={`px-4 py-2 rounded-md border ${selectedType === "subadmin"
                    ? "btn-style-1 text-white "
                    : "border-green-500 text-green-500"
                    } hover:bg-green-500 hover:text-white transition-all`}
                >
                  Subadmin
                </button>
              </div>
            ) : null}
          </div>

          {/* Email and Password Fields */}
          {selectedType === "admin" && (
            <div className="md:px-0 px-4">
              <form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="off">
                <div className="mb-4 ">
                  <input
                    {...register("email")}
                    placeholder="Email"
                    type="email"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.email && "border-red-500"
                      }`}
                    autoComplete="new-password"
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.email?.message}
                  </p>
                </div>

                <div className="mb-4 relative">
                  <input
                    {...register("password")}
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.password && "border-red-500"
                      }`}
                    autoComplete="new-password"
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer h-10"
                  >
                    {showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 24 24"
                        fill="none"
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <path
                          d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                          stroke="#000000"
                          strokeWidth="0.72"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                          stroke="#000000"
                          strokeWidth="0.72"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 24 24"
                        fill="none"
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <path
                          d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                          stroke="#000000"
                          strokeWidth="0.72"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </span>
                  <p className="text-red-500 text-sm mt-1">
                    {errors.password?.message}
                  </p>
                </div>

                {/* Forgot Password Text */}
                <div className="mb-4 text-center">
                  <Link
                    to="/forgetpassword"
                    className="text-sm text-blue-500 underline"
                  >
                    <i>Forgot Your Password?</i>
                  </Link>
                </div>
                <button
                  type="submit"
                  className="w-full text-white py-2 px-4 rounded-md mb-2  btn-style-1 hover:bg-blue-600 flex justify-center items-center"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Sign in"}
                </button>
              </form>
            </div>
          )}

          {/* Subadmin form */}
          {selectedType === "subadmin" && (
            <div className="md:px-0 px-4">
              <form
                onSubmit={handleSubmit(onSubmitSubadmin)}
                autoComplete="off"
              >
                <div className="mb-4">
                  <input
                    {...register("email")}
                    placeholder="Email"
                    type="email"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.email && "border-red-500"
                      }`}
                    autoComplete="new-password"
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.email?.message}
                  </p>
                </div>

                {/* Forgot Password Text */}
                <div className="mb-4 text-center">
                  <Link
                    to="/forgetpassword"
                    className="text-sm text-blue-500 underline"
                  >
                    <i>Forgot Your Password?</i>
                  </Link>
                </div>
                <button
                  type="submit"
                  className="w-full text-white py-2 px-4 rounded-md mb-2  btn-style-1 hover:bg-blue-600 flex justify-center items-center"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Check account"}
                </button>
              </form>
            </div>
          )}

          <div className="flex justify-center space-x-4 mt-4">
            <a
              href={googleOAuthUrl}
              className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 488 512"
              >
                <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
              </svg>
            </a>

            <a
              href={facebookOAuthUrl}
              className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 320 512"
              >
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>

            {/* <a
              href={microsoftOAuthUrl}
              className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M257.4 257.4H448V32.7L257.4 61.3V257.4zM32 32v225.4h193.4V55.7L32 32zM32 257.4V480l193.4 23.7V257.4H32zM257.4 480L448 448.6V257.4H257.4V480z" />
              </svg>
            </a> */}
          </div>
        </div>
        <Modal
          opened={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          centered
          size="auto" // Dynamically adjusts to content
          overlayOpacity={0.7}
          overlayBlur={5}
          transition="scale"
          transitionDuration={600}
          radius="xl"
          classNames={{
            modal: "w-full max-w-md p-6 bg-white rounded-xl shadow-xl relative",
            body: "flex flex-col items-center justify-center",
          }}
        >
          <div className="text-center space-y-6">
            <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-800 mb-4">
              Welcome, {userName}!
            </h2>
            <p className="text-base sm:text-lg text-gray-600 leading-relaxed">
              Explore our initiatives and join us in making the world a better place, one step at a time.
            </p>
            <div className="h-1 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 w-3/4 mx-auto rounded-full"></div>
          </div>
        </Modal>


        <Modal
          opened={showSubadminModal}
          onClose={() => setShowSubadminModal(false)}
          centered
          size="md"
          overlayOpacity={0.8}
          transition="scale"
          transitionDuration={600}
          // className="p-6"
          closeOnClickOutside={false} // Prevent modal from closing on outside click
          withCloseButton={false}
        >
          <div className="text-center space-y-5 p-6">
            <h2 className="text-2xl font-extrabold text-blue-900">
              Subadmin Accounts
            </h2>

            <div className="max-h-24 overflow-y-auto border border-gray-300 rounded-md p-2 mb-4">
              {organizationData.map((org) => (
                <div
                  key={org.id}
                  onClick={() => setSelectedOrg(org.id)}
                  className={`p-2 cursor-pointer rounded hover:bg-gray-200 ${selectedOrg === org.id ? "bg-gray-300" : ""
                    }`}
                >
                  <FontAwesomeIcon icon={faBuilding} className="mr-2" />{" "}
                  {org.name}
                </div>
              ))}
            </div>
            {selectedOrg && (
              <p className="mb-4 text-gray-700">
                Selected Organization:{" "}
                {organizationData.find((org) => org.id === selectedOrg)?.name}
              </p>
            )}

            <TextInput
              size="lg"
              // type="password"
              value={subAdminPassword} // Set the value of the input to the password state
              onChange={(event) =>
                setSubadminPassword(event.currentTarget.value)
              }
              placeholder="Enter password to selected account"
              classNames={{
                input:
                  "bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                label: "text-gray-800 font-semibold",
              }}
            />
            <div className="flex justify-center space-x-8">
              <Button
                variant="outline"
                onClick={() => {
                  setShowSubadminModal(false);
                  setSelectedOrg("");
                  setSubadminPassword("");
                }}
                className="border border-gray-300 text-gray-800 px-8 py-3  hover:bg-gray-200 transition-all duration-300 ease-in-out "
              >
                Cancel
              </Button>
              <Button
                onClick={handleToLoginSubadmin}
                // className="bg-gradient-to-r from-green-500 to-blue-500 text-white font-bold px-8 py-3 rounded-full shadow-xl hover:from-blue-600 hover:to-green-600 hover:shadow-2xl transition-all duration-300 ease-in-out transform hover:-translate-y-2"
                className="primary-bg-color text-white font-bold px-8   shadow-xl   hover:shadow-2xl transition-all duration-300 ease-in-out "
              >
                Sign in
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default NgoLogin;
