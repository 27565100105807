import React from "react";

const LinkedInIntegration = () => {
  const connectLinkedIn = () => {
    const clientId = "77506m5owe9sj3"; // Replace with LinkedIn Client ID
    const redirectUri = "https://7df4-117-215-30-212.ngrok-free.app/linkedin/callback"; // Updated Redirect URI
    const scope = "w_organization_social r_organization_social"; // Updated required scopes
    const state = "unique_state_value"; // Unique string for CSRF protection

    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${encodeURIComponent(scope)}&state=${state}`;

    console.log("Authorization URL: ", authUrl); // Debugging: Check this in console
    window.location.href = authUrl;
  };

  return (
    <div>
      <button onClick={connectLinkedIn}>Connect LinkedIn</button>
    </div>
  );
};

export default LinkedInIntegration;
