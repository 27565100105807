import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./HowWeWorkModal.css"; // Import the CSS file for styling
import Cookies from "js-cookie";

// Set the app element for accessibility
Modal.setAppElement("#root");

const HowWeWorkModal = ({ showModal, onClose }) => {
  const [isClosing, setIsClosing] = useState(false);

  // Handle modal closing with animation
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);

      // Set a persistent cookie to remember that the user has seen the modal
      Cookies.set("hasSeenHowWeWorkModal", "true", { expires: 365 }); // Cookie expires in 1 year
    }, 300); // Delay to match the closing animation duration
  };

  useEffect(() => {
    // Reset closing state when modal opens
    if (showModal) setIsClosing(false);
  }, [showModal]);

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={handleClose}
      contentLabel="How We Work Modal"
      className={`HowWeWorkModal-content ${isClosing ? "closing" : "opening"}`}
      overlayClassName="HowWeWorkModal-overlay"
    >
      {/* Close Button */}
      <button onClick={handleClose} className="HowWeWorkModal-closeButton">
        <svg
          className="HowWeWorkModal-closeIcon"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
            fill="#000"
          />
        </svg>
      </button>

      {/* Title */}
      <h2 className="HowWeWorkModal-title">How it Works</h2>

      {/* Container for side-by-side boxes */}
      <div className="HowWeWorkModal-container">
        {/* Donor Section */}
        <div className="HowWeWorkModal-box">
          <div className="HowWeWorkModal-imageContainer">
            <img
              src="assets/images/Charity-rafiki.png"
              className="HowWeWorkModal-image"
              alt="Donor Process"
            />
          </div>
          <div className="HowWeWorkModal-textContainer">
            <h2 className="HowWeWorkModal-subtitle">Are you a donor?</h2>
            <ul className="HowWeWorkModal-list">
              <li className="HowWeWorkModal-listItem">
                <span className="HowWeWorkModal-checkMark">✓</span>
                Browse or search for fundraisers and select one to support.
              </li>
              <li className="HowWeWorkModal-listItem">
                <span className="HowWeWorkModal-checkMark">✓</span>
                Input your transaction details to complete the donation.
              </li>
              <li className="HowWeWorkModal-listItem">
                <span className="HowWeWorkModal-checkMark">✓</span>
                Provide payment information, like credit card details.
              </li>
              <li className="HowWeWorkModal-listItem">
                <span className="HowWeWorkModal-checkMark">✓</span>
                Receive an email confirmation for your successful donation.
              </li>
            </ul>
          </div>
        </div>

        {/* Recurring Donor Section */}
        <div className="HowWeWorkModal-box">
          <div className="HowWeWorkModal-imageContainer">
            <img
              src="assets/images/Charity-cuate.png"
              className="HowWeWorkModal-image"
              alt="Recurring Donor Process"
            />
          </div>
          <div className="HowWeWorkModal-textContainer">
            <h2 className="HowWeWorkModal-subtitle">Recurring donor?</h2>
            <ul className="HowWeWorkModal-list">
              <li className="HowWeWorkModal-listItem">
                <span className="HowWeWorkModal-checkMark">✓</span>
                Log in to manage your recurring donations.
              </li>
              <li className="HowWeWorkModal-listItem">
                <span className="HowWeWorkModal-checkMark">✓</span>
                Choose donation frequency and fill in the required details.
              </li>
              <li className="HowWeWorkModal-listItem">
                <span className="HowWeWorkModal-checkMark">✓</span>
                Set the donation amount and payment preferences.
              </li>
              <li className="HowWeWorkModal-listItem">
                <span className="HowWeWorkModal-checkMark">✓</span>
                Receive confirmations for each successful subscription.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HowWeWorkModal;
