import React, { useEffect, useState } from "react";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import AnalyticsSidebar from "./AnalyticsSidebar";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { TQ_URL } from "../../utility/baseUrl";
import {
  getRequestWithOutToken,
  getRequestWithTokenWithoutDataPartner,
} from "../../utility/apiRequest";
import { DatePickerInput } from "@mantine/dates";

import {
  Chart,
  SeriesTemplate,
  CommonSeriesSettings,
  Title,
  Tooltip,
} from "devextreme-react/chart";

const NewUserSignUpAnalytics = () => {
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const [value, setValue] = useState("monthly");
  const [userInfo, setUserInfo] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [statusFilter, setStatusFilter] = useState(1);
  const [monthDropdown, setMonthDropdown] = useState(false);
  const [yearDropdown, setYearDropdown] = useState(true);
  const [monthValue, setMonthValue] = useState("");
  const [yearValue, setYearValue] = useState(new Date().getFullYear());
  const [graphData, setGraphData] = useState(0);
  const [searchText, setSearchText] = useState("");

  const [sorting, setSorting] = useState([]);
  const currentDate = new Date();
  const initialStartDate = new Date(
    currentDate.setMonth(currentDate.getMonth() - 3)
  );
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(new Date());
  const [convertedStartDate, setConvertedStartDate] = useState("");
  const [convertedEndDate, setConvertedEndDate] = useState("");

  const yearList = [2020, 2021, 2022, 2023, 2024];

  const monthList = [
    {
      id: "01",
      name: "January",
    },
    {
      id: "02",
      name: "February",
    },
    {
      id: "03",
      name: "March",
    },
    {
      id: "04",
      name: "April",
    },
    {
      id: "05",
      name: "May",
    },
    {
      id: "06",
      name: "June",
    },
    {
      id: "07",
      name: "July",
    },
    {
      id: "08",
      name: "August",
    },
    {
      id: "09",
      name: "September",
    },
    {
      id: "10",
      name: "October",
    },
    {
      id: "11",
      name: "November",
    },
    {
      id: "12",
      name: "December",
    },
  ];

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (!userInfo.length) {
    } else {
      setIsRefetching(true);
    }

    console.log(startDate);
    console.log(endDate);

    if (startDate && endDate) {
      convertDateIntoString(startDate, 1);

      convertDateIntoString(endDate, 2);

      getAnalyticsList();
    }
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    convertedStartDate,
    convertedEndDate,
    columnFilters,
    statusFilter,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    handleAnalyticsDataFetching(value, monthValue, yearValue);
  }, [value, monthValue, yearValue]);

  function handleAnalyticsDataFetching(typeValue, monthValue, yearValue) {
    if (typeValue === "yearly") {
      getNewSignUpAnalyticsDataForGraph("yearly", monthValue, yearValue);
    } else if (typeValue === "weekly" && monthValue && yearValue) {
      getNewSignUpAnalyticsDataForGraph("weekly", monthValue, yearValue);
    } else if (typeValue === "monthly" && yearValue) {
      getNewSignUpAnalyticsDataForGraph("monthly", monthValue, yearValue);
    } else {
      console.error("Invalid parameters provided.");
    }
  }

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      console.log("searchText:", searchText);

      if (searchText.trim() == "" && startDate != "" && endDate != "") {
        console.log("does not perform any operation");
        return;
      }

      const url = new URL(`${TQ_URL}/searchNewUserSignUpAnalytics`);
      url.search = new URLSearchParams();
      url.searchParams.set("start", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("keyword", `${searchText}`);
      url.searchParams.set("partnerId", `${partnerData.partnerId}`);

      try {
        const response = await getRequestWithTokenWithoutDataPartner(
          url.toString()
        );
        console.log(response);

        console.log(response.dataList);

        const dataWithSerialNumber = response.dataList.map((item, index) => ({
          ...item,
          id: index + 1,
        }));

        console.log(response.dataList);

        setUserInfo(dataWithSerialNumber);
        console.log(userInfo);
        setRowCount(response.totalCount);

        setIsRefetching(false);
      } catch (error) {
        console.error(
          "Error in fetching the searched new sign up user list:",
          error
        );
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
  };
  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const convertDateIntoString = (date, status) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    console.log(formattedDate);

    status == 1
      ? setConvertedStartDate(formattedDate)
      : setConvertedEndDate(formattedDate);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    convertDateIntoString(date, 1);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    convertDateIntoString(date, 2);
  };
  const getAnalyticsList = async () => {
    const url = new URL(`${TQ_URL}/getNewSignUpAnalyticsList`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("status", `${statusFilter}`);
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);
    url.searchParams.set("startDate", convertedStartDate);
    url.searchParams.set("endDate", convertedEndDate);

    try {
      const response = await getRequestWithOutToken(url.toString());

      console.log(response);

      console.log(response.datalist);

      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log("This is Data", dataWithSerialNumber);

      setUserInfo(dataWithSerialNumber);
      console.log(userInfo);
      setRowCount(response.totalCount);

      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the fundraiser data:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
  };

  const getNewSignUpAnalyticsDataForGraph = async () => {
    const url = new URL(`${TQ_URL}/getNewSignUpAnalyticsDataForGraph`);
    url.search = new URLSearchParams();
    url.searchParams.set("typeValue", value);
    if (monthValue != "" && !isNaN(monthValue)) {
      url.searchParams.set("monthValue", parseInt(monthValue));
    }
    if (yearValue != "" && !isNaN(yearValue)) {
      url.searchParams.set("yearValue", parseInt(yearValue));
    }
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);
    url.searchParams.set("yearList", JSON.stringify(yearList ?? []));

    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log(response);
      setGraphData(response.list);
    } catch (error) {
      console.error(
        "Error in fetching the details for analytics  data:",
        error
      );
      setIsError(true);
      return;
    }
  };
  const getTypeValue = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value>>", selectedValue);

    setValue(selectedValue);

    if (selectedValue === "weekly") {
      setMonthDropdown(true);
      if (yearDropdown) setYearDropdown(false);
    } else if (selectedValue === "monthly") {
      setYearDropdown(true);

      if (monthDropdown) setMonthDropdown(false);
    } else {
      setMonthDropdown(false);
      setYearDropdown(false);
    }
  };

  const getMonthValue = (event) => {
    const selectedMonthValue = event.target.value;
    console.log("Selected month value>>", selectedMonthValue);
    setMonthValue(selectedMonthValue);
  };

  const getYearValue = (event) => {
    const selectedYearValue = event.target.value;
    console.log("Selected year value>>", selectedYearValue);
    setYearValue(selectedYearValue);
    console.log(monthValue);
  };

  const columns = [
    {
      accessorKey: "id",
      header: "Sr. no",
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: "name",
      header: "User Name",
      sortable: true,
    },
    {
      accessorKey: "contactNumber",
      header: "User Contact",
      Cell: ({ row }) => row.original.contactNumber || "---",
      sortable: true,
    },
    {
      accessorKey: "email",
      header: "Email",
      sortable: true,
    },
  ];

  const table = useMantineReactTable({
    columns,
    data: userInfo,

    initialState: { showColumnFilters: false },
    rowCount,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,

    onColumnFiltersChange: setColumnFilters,

    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed h-full md:overflow-y-auto">
          <AnalyticsSidebar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px] overflow-x-hidden">
          <div className="mx-auto shadow-md">
            <div className="flex flex-col lg:flex-row justify-between items-center container px-10 py-4">
              <h2 className="text-2xl px-10 font-bold">
                New User Sign up Analytics
              </h2>
              <div className="flex flex-col lg:flex-row items-center lg:ml-4 mt-4 lg:mt-0 w-full lg:w-auto">
                <div className="w-full lg:w-auto mx-1 py-1 lg:py-0">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="py-2 pr-8 pl-4 block w-full rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className="flex flex-wrap items-center mt-4 md:mt-0 lg:flex-nowrap lg:space-x-4 w-full md:w-auto">
                  <div className="md:mx-2 mx-2 py-2 md:py-0">
                    <DatePickerInput
                      label="Start Date"
                      placeholder="Pick date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="md:mx-2 mx-2 py-2 md:py-0">
                    <DatePickerInput
                      label="End Date"
                      placeholder="Pick date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container px-10 py-5">
            <MantineReactTable table={table} />
          </div>
          <div className="container mt-8">
            <div className="flex flex-col md:flex-row md:items-center mb-8 justify-between px-10">
              <h2 className="text-lg font-bold mb-4 md:mb-0">
                New Sign Up User Analysis
              </h2>
              <div className="flex flex-wrap items-center w-full md:w-auto">
                <div className="mx-2 mb-4 md:mb-0 md:w-auto">
                  <select
                    onChange={getTypeValue}
                    value={value}
                    className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                  >
                    <option value="">Select frequency type</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
                {monthDropdown && (
                  <div className="mx-2 mb-4 md:mb-0 md:w-auto">
                    <select
                      onChange={getMonthValue}
                      value={monthValue}
                      className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                    >
                      <option value="">Select Month</option>
                      {monthList.map((month, index) => (
                        <option key={month.id} value={month.id}>
                          {month.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {(yearDropdown || monthValue) && (
                  <div className="mx-2 mb-4 md:mb-0 md:w-auto">
                    <select
                      onChange={getYearValue}
                      value={yearValue}
                      className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                    >
                      <option value="">Select Year</option>
                      {yearList.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>

            <div className="webkit-centerForAnalytics md:w-full md:ml-2">
              <Chart id="chart" palette="Soft" dataSource={graphData}>
                <CommonSeriesSettings
                  argumentField="freqLabel"
                  valueField="usercount"
                  type="bar"
                  ignoreEmptyPoints={true}
                  width={30}
                />
                <SeriesTemplate nameField="freqLabel" />
                {/* <Title text="UserCount Breakdown of New Sign up Users" /> */}
                <Tooltip enabled={true} />
              </Chart>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default NewUserSignUpAnalytics;
