import React, { useState } from "react";
import { getRequestWithTokenWithDataWithAuthPartner } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ImportExportModal = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const file = event.target.file.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("partnerId", partnerData.partnerId); // Change 123 to the actual partnerId
    try {
      await getRequestWithTokenWithDataWithAuthPartner(
        `${TQ_URL}/importDonors`,
        formData
      );
      // Show success toast
      toast.success("File uploaded successfully!");
      // Close the modal after a brief delay (you can adjust the delay as needed)
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      // Handle error, show an error message, etc.
      console.error("Error importing donations:", error);
      toast.error("Error uploading file. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadTemplate = () => {
    // Replace 'Sample-Donation.xlxs' with the actual path to your Excel template file
    const templateUrl = "../public/assets/Sample-Donation.xlxs";
    window.open(templateUrl, "_blank");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex mx-auto items-center justify-center overflow-y-scroll  z-50 px-4">
      <div className="fade-in-bottom rounded-xl w-[350px]">
        <div className="bg-white rounded-t-xl max-w-2xl w-full flex justify-between items-center">
          <h2 className="text-xl font-bold mb-4 text-start pt-4 px-10">
            Import Donor List
          </h2>
          <button onClick={onClose} className="px-2 cursor-pointer">
            <svg
              className="w-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* SVG code */}
            </svg>
          </button>
        </div>
        <div className="p-6 bg-blue-50 rounded-b-xl">
          {successMessage && (
            <div className="mb-4 text-green-600">{successMessage}</div>
          )}
          <form onSubmit={handleSubmit}>
            <input
              type="file"
              name="file"
              accept=".xlsx, .xls"
              className="mb-4"
            />
            <div className="flex justify-between my-2">
              <button
                type="submit"
                className="btn-style-1 text-white px-4 py-2 rounded mr-2"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
              <button
                type="button"
                onClick={onClose}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </form>
          <div className="mt-4">
            <a
              className="text-blue-500 underline"
              download="DonorSample.xlsx"
              target="_blank"
              href="assets/Sample-Donation.xlsx"
            >
              Download Sample here
            </a>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ImportExportModal;
