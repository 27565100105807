import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Header from "../Home/Header";
import ProgressBar from "../Common/ProgressBar";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate, Link } from "react-router-dom";

import axios from "axios";
import { getRequestWithOutToken } from "../../utility/apiRequest";
import { TQ_URL, TQ_URL_USPROJECT } from "../../utility/baseUrl";
import TopHeader from "../Home/TopHeader";

const AddDonationAdmin = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [showOtp, setShowOtp] = useState(false);
  const [pin, setPin] = useState("");
  const [timer, setTimer] = useState(0);
  const [showRefresh, setShowRefresh] = useState(false);
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const [fundraiserList, setFundraiserList] = useState([]);
  const [paymentMode, setPaymentMode] = useState([]);
  const [fundraiserShare, setFundraiserShare] = useState(0);
  const [tax, setTax] = useState(0);
  const [isAmountEntered, setIsAmountEntered] = useState(false);

  const [baseCharge, setBaseCharge] = useState(0);

  const fundraiserId = state?.fundraiserId;
  const fundraiserName = state?.fundraiserName;
  const remainingAmount = state?.remainingAmount;

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPreviousMail, setIsPreviousMail] = useState();
  const [errorPaymentMode, setErrorPaymentMode] = useState("");
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const [amount, setAmount] = useState("");

  const [isFormValid, setIsFormValid] = useState(true);

  console.log("selected caused:", fundraiserId);
  console.log("selected caused:", fundraiserName);

  // Function to format phone number
  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  // const handleAmountChange = (event) => {
  //   const { value } = event.target;
  //   const formattedAmount = formatAmount(value);
  //   setValue("amount", formattedAmount);
  //   event.target.value = formattedAmount;

  //   const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));

  //   if (!isNaN(numericValue) && numericValue >= 10) {
  //     setIsAmountEntered(true); // Show the box when a valid amount is entered
  //     const tax = Number((numericValue * 0.04).toFixed(2));
  //     const fundraiserAmount = Number((numericValue - tax).toFixed(2));
  //     setFundraiserShare(fundraiserAmount);
  //     setTax(tax);
  //     setBaseCharge(0);
  //   } else {
  //     setFundraiserShare(0);
  //     setTax(0);
  //     setIsAmountEntered(false); // Hide the box if the amount is invalid
  //   }
  // };

  const handlePhoneNumberChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setValue("phone", formattedPhoneNumber);
  };

  // // Handle amount input change
  // const handleAmountChange = (event) => {
  //   const { value } = event.target;
  //   const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));

  //   if (!isNaN(numericValue) && numericValue >= 10) {
  //     setAmount(numericValue);
  //     setIsAmountEntered(true); // Display the box once a valid amount is entered
  //   } else {
  //     setAmount(0);
  //     setIsAmountEntered(false); // Hide the box if the amount is invalid
  //   }
  // };

  // // Handle tax input change
  // const handleTaxChange = (event) => {
  //   const taxValue = parseFloat(event.target.value.replace(/[^0-9.]/g, ""));
  //   setTax(isNaN(taxValue) ? 0 : taxValue / 100); // Store tax as a decimal
  // };

  // // Handle base charge input change
  // const handleBaseChargeChange = (event) => {
  //   const baseValue = parseFloat(event.target.value.replace(/[^0-9.]/g, ""));
  //   setBaseCharge(isNaN(baseValue) ? 0 : baseValue);
  // };

  // // Update values whenever amount, tax, or baseCharge changes
  // useEffect(() => {
  //   if (isAmountEntered) {
  //     const calculatedTax = amount * tax;
  //     const calculatedFundraiserShare = amount - calculatedTax - baseCharge;

  //     setFundraiserShare(
  //       calculatedFundraiserShare > 0 ? calculatedFundraiserShare : 0
  //     );
  //   }
  // }, [amount, tax, baseCharge, isAmountEntered]);

  const handleAmountChange = (event) => {
    const { value } = event.target;
    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));

    setAmount(!isNaN(numericValue) ? numericValue : 0); // Set amount
  };

  // Handle tax input change
  const handleTaxChange = (event) => {
    const taxValue = parseFloat(event.target.value.replace(/[^0-9.]/g, ""));
    setTax(isNaN(taxValue) ? 0 : taxValue / 100); // Store tax as a decimal
  };

  // Handle base charge input change
  const handleBaseChargeChange = (event) => {
    const baseValue = parseFloat(event.target.value.replace(/[^0-9.]/g, ""));
    setBaseCharge(isNaN(baseValue) ? 0 : baseValue);
  };

  // Update values whenever amount, tax, or baseCharge changes
  useEffect(() => {
    const calculatedTax = amount * tax;
    const calculatedFundraiserShare = amount - calculatedTax - baseCharge;

    setFundraiserShare(
      calculatedFundraiserShare > 0 ? calculatedFundraiserShare : 0
    );
  }, [amount, tax, baseCharge]);

  const formatAmount = (value) => {
    if (!value) return value;
    const amount = parseInt(value.replace(/[^0-9]/g, ""), 10);
    return isNaN(amount) ? "" : `$${amount.toLocaleString("en-US")}`;
  };

  const fetchFundraisers = async () => {
    const url = new URL(
      `${TQ_URL_USPROJECT}/getFundraiserList?partnerId=${partnerData.partnerId}`
    );
    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log(response.data);
      setFundraiserList(response.data);
    } catch (error) {
      console.error("Error in fetching the FundraiserList data:", error);
    }
  };

  const fetchPaymentMode = async () => {
    const url = new URL(`${TQ_URL_USPROJECT}/getPaymentModeList`);
    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log(response.data);
      setPaymentMode(response.data);
    } catch (error) {
      console.error("Error in fetching the PaymentMode data:", error);
    }
  };

  // Use useEffect to call the fetch function when the component mounts
  useEffect(() => {
    fetchFundraisers();
    fetchPaymentMode();
  }, []);

  const schema = yup.object().shape({
    fundraiser: yup.string().required("Please select a fundraiser"),
    amount: yup
      .string()
      .required("Amount is required")
      .matches(/^\$\d{1,3}(,\d{3})*$/, "Invalid amount format"),
    fullName: yup
      .string()
      .min(5, "Name must be at least 5 characters")
      .max(50, "Name must be at most 50 characters")
      .required("Full Name is Required *"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is Required *"),
    phone: yup
      .string()
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "Phone number must be in the format (XXX) XXX-XXXX"
      )
      .required("Phone is Required *"),

    paymentMode: yup.string().required("Please select a payment mode"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = async (data) => {
    console.log(data);

    try {
      const response = await axios.post(
        `${TQ_URL_USPROJECT}/saveDonationByAdmin`,
        {
          fundraiser: data.fundraiser,
          fullName: data.fullName,
          email: data.email,
          amount: fundraiserShare,
          tax: amount * tax,
          paymentMode: data.paymentMode,
          transactionId: data.transactionId,
          phone: data.phone,
          baseCharge: baseCharge,
        }
      );
      console.log(response.data); // Show success message
      toast.success("Donation saved successfully");
      reset();
      setAmount(0);
      console.log("Donation saved successfully:", response.data);
    } catch (error) {
      console.error("Error saving donation:", error);
      toast.error("There was an error saving the donation.");
    }
  };

  const [showSubmissionPopup, setShowSubmissionPopup] = useState(false);
  const [popupFields, setPopupFields] = useState({
    field1: "",
    field2: "",
    field3: "",
  });

  const handlePopupFieldChange = (fieldName, value) => {
    setPopupFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const onSubmitPopup = () => {
    setShowSubmissionPopup(false);
  };

  useEffect(() => {
    let interval;
    if (showOtp && timer === 0) {
      setShowRefresh(true);
    }
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showOtp, timer]);

  const email = watch("email");

  const isValid = () => {
    // Implement your validation logic here
    return (
      !!watch("amount") &&
      !!watch("fullName") &&
      !!watch("email") &&
      !!watch("phone")
    );
  };

  return (
    <>
      <TopHeader />

      <main className="w-full verify-user ">
        <div className="mx-auto shadow-md">
          <div className="flex justify-between items-center container px-10 py-6 mx-auto">
            <div className="text-left flex items-center">
              <Link to="/money-details">
                <img
                  src="/assets/images/main-logo.svg"
                  alt="Logo"
                  className="h-10 px-6 my-5 sm:mx-0"
                />
              </Link>
            </div>
            <h2 className="text-4xl font-bold text-center flex-1">
              Add Donation
            </h2>
          </div>
        </div>
        {/* <h2 className="md:text-3xl text-xl font-bold mb-4 text-center uppercase py-4">
          Add Donation.
        </h2> */}

        <div className="bg-gradient-to-r from-blue-50 to-white] py-2">
          <div className="md:pb-10 md:flex justify-center">
            {/* Left Image Section - hidden on smaller screens */}
            {/* <div className="2xl:w-1/4 lg:w-1/3 xl:me-20 mx-10 lg:mx-0 hidden xl:block">
            <img
              src="assets/images/handWithCoin.png"
              className="md:w-full md:mx-16 w-94 h-82 rounded-lg shadow-lg"
              style={{ width: "94%", height: "82%" }}
              alt="Charity"
            />
          </div> */}

            <div className="md:w-2/3 m-4 md:m-0 w-full-md-lg lg:ml-10 leftMarg">
              <form
                onSubmit={handleSubmit(onSubmitHandler)}
                className="fundraiser-for-other-form md:py-12 md:px-20 p-6 md:me-20 bg-white rounded-lg shadow-md"
              >
                <div className="mb-6 md:flex">
                  <label>Select Fundraiser*</label>
                  <div className="w-full">
                    <select
                      {...register("fundraiser")}
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                        errors.fundraiser ? "border-red-500" : ""
                      }`}
                    >
                      <option value="">Select a fundraiser</option>
                      {fundraiserList.map((fundraiser) => (
                        <option
                          key={fundraiser.fundraiserId}
                          value={fundraiser.fundraiserId}
                        >
                          {fundraiser.title}
                        </option>
                      ))}
                    </select>
                    {errors.fundraiser && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.fundraiser.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="mb-6 md:flex">
                  <label>Donor name*</label>
                  <div className="w-full">
                    <input
                      {...register("fullName")}
                      placeholder="Full name"
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                        errors.fullName && "border-red-500"
                      }`}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.fullName?.message}
                    </p>
                  </div>
                </div>

                {/* Email */}
                <div className="mb-6 md:flex">
                  <label>Donor Email*</label>
                  <div className="w-full">
                    <input
                      {...register("email")}
                      placeholder="Email"
                      type="email"
                      autoComplete="new-password"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                        errors.email && "border-red-500"
                      }`}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.email?.message}
                    </p>
                  </div>
                </div>

                {/* Amount */}
                {/* <div className="mb-6 md:flex">
                  <label>Amount*</label>
                  <div className="w-full">
                    <input
                      {...register("amount", {
                        required: "Amount is required",
                        validate: (value) => {
                          const amountValue = parseInt(
                            value.replace(/[^0-9]/g, ""),
                            10
                          );
                          if (isNaN(amountValue) || amountValue < 10) {
                            return "Minimum amount is $10";
                          }
                          return true;
                        },
                      })}
                      placeholder="$0"
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                        errors.amount && "border-red-500"
                      }`}
                      style={{ appearance: "textfield" }}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        e.target.value = `$${numericValue}`;
                        handleAmountChange(e);
                      }}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.amount?.message}
                    </p>

                    <div
                      className={`transition-all duration-500 ease-in-out ${
                        isAmountEntered
                          ? "opacity-100 max-h-48"
                          : "opacity-0 max-h-0"
                      } overflow-hidden`}
                    >
                      <div className="mt-4 w-full bg-blue-50 border border-blue-200 rounded-md p-4 flex flex-col space-y-4">
                        <div className="flex items-center space-x-2 w-full">
                          <span role="img" aria-label="Fundraiser">
                            💰
                          </span>
                          <span className="text-blue-900 font-semibold">
                            Fundraiser's Share:{" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(fundraiserShare)}
                          </span>
                        </div>
                        <div className="flex items-center space-x-2 w-full">
                          <span role="img" aria-label="Platform Fee">
                            📊
                          </span>
                          <span className="text-blue-900 font-semibold">
                            Tax :{" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(tax)}
                          </span>
                        </div>
                        <div className="flex items-center space-x-2 w-full">
                          <span role="img" aria-label="Base Amount">
                            💵
                          </span>
                          <span className="text-blue-900 font-semibold">
                            Base Amount:{" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(baseCharge)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="mb-6 md:flex">
                <label>Tax Deductible (Optional)</label>
                <div className="w-full">
                  <input
                    {...register("tax", {
                      validate: (value) => {
                        const taxValue = parseInt(
                          value.replace(/[^0-9]/g, ""),
                          10
                        );
                        if (taxValue && taxValue < 0) {
                          return "Tax value cannot be negative";
                        }
                        return true;
                      },
                    })}
                    placeholder="Tax Amount in % (Optional)"
                    type="text"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                      errors.tax && "border-red-500"
                    }`}
                    style={{ appearance: "textfield" }}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      e.target.value = `$${numericValue}`;
                    }}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.tax?.message}
                  </p>
                </div>
              </div> */}

                {/* <div className="mb-6 md:flex">
                  <label>Tax Deductible (Optional)</label>
                  <div className="w-full">
                    <input
                      {...register("tax", {
                        validate: (value) => {
                          const taxValue = parseInt(
                            value.replace(/[^0-9]/g, ""),
                            10
                          );
                          if (taxValue && taxValue < 0) {
                            return "Tax value cannot be negative";
                          }
                          return true;
                        },
                      })}
                      placeholder="Tax Amount in % (Optional)"
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                        errors.tax && "border-red-500"
                      }`}
                      style={{ appearance: "textfield" }}
                      onChange={(e) => {
                        // Remove non-numeric characters, only allowing numbers
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        if (numericValue) {
                          e.target.value = `${numericValue}%`;
                        } else {
                          e.target.value = numericValue; // If no value, leave it empty
                        }
                        handleTaxChange(e);
                      }}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.tax?.message}
                    </p>
                  </div>
                </div> */}

                {/* <div className="mb-6 md:flex">
                  <label>Base Charge</label>
                  <div className="w-full">
                    <input
                      {...register("baseAmt")}
                      placeholder="Base Amount (Optional)"
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md"
                      onChange={handleBaseChargeChange}
                    />
                  </div>
                </div> */}

                {/* <div className="mb-6 md:flex">
                  <label>Amount*</label>
                  <div className="w-full">
                    <input
                      {...register("amount", {
                        required: "Amount is required",
                        validate: (value) => {
                          const amountValue = parseInt(
                            value.replace(/[^0-9]/g, ""),
                            10
                          );
                          if (isNaN(amountValue) || amountValue < 10) {
                            return "Minimum amount is $10";
                          }
                          return true;
                        },
                      })}
                      placeholder="$0"
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                        errors.amount && "border-red-500"
                      }`}
                      style={{ appearance: "textfield" }}
                      // onChange={handleAmountChange}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        ); // Only allow numbers
                        e.target.value = `$${numericValue}`; // Display formatted amount
                        handleAmountChange(e); // Update fundraiser share and platform fee
                      }}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.amount?.message}
                    </p>
                  </div>
                </div> */}

                <div className="mb-6 md:flex">
                  <label>Amount*</label>
                  <div className="w-full">
                    <input
                      {...register("amount", {
                        required: "Amount is required",
                        validate: (value) => {
                          const amountValue = parseInt(
                            value.replace(/[^0-9]/g, ""),
                            10
                          );
                          if (isNaN(amountValue)) {
                            return "Please enter a valid amount";
                          }
                          return true;
                        },
                      })}
                      placeholder="$0"
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                        errors.amount && "border-red-500"
                      }`}
                      style={{ appearance: "textfield" }}
                      onChange={(e) => {
                        // Remove non-numeric characters and parse the value
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );

                        // Format the value with commas as thousands separator
                        const formattedValue = `$${parseInt(
                          numericValue || "0",
                          10
                        ).toLocaleString()}`;

                        // Update the input display value with formatting
                        e.target.value = formattedValue;

                        // Call the handler to update fundraiser share and platform fee
                        handleAmountChange(e);
                      }}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.amount?.message}
                    </p>
                  </div>
                </div>

                {/* Tax Input */}
                <div className="mb-6 md:flex">
                  <label>Tax Deductible (Optional)</label>
                  <div className="w-full">
                    <input
                      {...register("tax")}
                      placeholder="Tax Amount in % (Optional)"
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md"
                      style={{ appearance: "textfield" }}
                      // onChange={handleTaxChange}
                      onChange={(e) => {
                        // Remove non-numeric characters, only allowing numbers
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        if (numericValue) {
                          e.target.value = `${numericValue}%`;
                        } else {
                          e.target.value = numericValue; // If no value, leave it empty
                        }
                        handleTaxChange(e);
                      }}
                    />
                  </div>
                </div>

                {/* Base Charge Input */}
                <div className="mb-6 md:flex">
                  <label>Base Charge</label>
                  <div className="w-full">
                    <input
                      {...register("baseCharge")}
                      placeholder="Base Amount (Optional)"
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md"
                      onChange={handleBaseChargeChange}
                    />
                  </div>
                </div>

                {/* Display Box */}
                {amount > 0 && (
                  <div className="mb-3 md:flex">
                    <div className="w-full lg:w-[68%]  lg:ml-[260px]">
                      <label></label>
                      <div className="transition-all duration-500 ease-in-out opacity-100 max-h-48 overflow-hidden">
                        <div className="mt-4 w-full bg-blue-50 border border-blue-200 rounded-md p-4 flex flex-col space-y-4">
                          <div className="flex items-center space-x-2 w-full">
                            <span role="img" aria-label="Fundraiser">
                              💰
                            </span>
                            <span className="text-blue-900 font-semibold">
                              Fundraiser's Share:{" "}
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(fundraiserShare)}
                            </span>
                          </div>
                          <div className="flex items-center space-x-2 w-full">
                            <span role="img" aria-label="Platform Fee">
                              📊
                            </span>
                            <span className="text-blue-900 font-semibold">
                              Tax:{" "}
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(amount * tax)}
                            </span>
                          </div>
                          <div className="flex items-center space-x-2 w-full">
                            <span role="img" aria-label="Base Amount">
                              💵
                            </span>
                            <span className="text-blue-900 font-semibold">
                              Base Amount:{" "}
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(baseCharge)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mb-6 md:flex">
                  <label>Transaction Id</label>
                  <div className="w-full">
                    <input
                      {...register("transactionId")}
                      placeholder="TransactionId (Optional)"
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md"
                    />
                  </div>
                </div>

                <div className="mb-6 md:flex">
                  <label>Select Payment Mode*</label>
                  <div className="w-full">
                    <select
                      {...register("paymentMode", {
                        required: "Please select a payment mode",
                      })}
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md ${
                        errors.paymentMode && "border-red-500"
                      }`}
                    >
                      <option value="">Select Payment Method</option>
                      {paymentMode.map((paymentMode) => (
                        <option
                          key={paymentMode.paymentModeId}
                          value={paymentMode.paymentModeId}
                        >
                          {paymentMode.paymentModeName}
                        </option>
                      ))}
                    </select>
                    <p className="text-red-500 text-sm mt-1">
                      {errors.paymentMode?.message}
                    </p>
                  </div>
                </div>

                {/* Phone */}
                <div className="mb-6 md:flex">
                  <label>Contact Number</label>
                  <div className="w-full">
                    <input
                      {...register("phone")}
                      placeholder="Phone"
                      type="text"
                      maxLength={14}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-md"
                      onChange={handlePhoneNumberChange}
                    />
                  </div>
                </div>

                <div className="flex w-full justify-center items-center">
                  <button
                    type="submit"
                    className="md:w-1/4 w-1/2 text-white p-2 rounded-md btn-style-1 "
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>

      <ToastContainer theme="colored" />
      <Footer />
    </>
  );
};

export default AddDonationAdmin;
