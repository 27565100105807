import React from "react";

const CircleAnalyticsComponent = ({ 
  bigCircleImageUrl, 
  channelName, 
  isSelected, 
  onClick 
}) => {
  // Define a function to determine the small circle image URL based on the channel name
  const getSmallCircleImageUrl = (channelName) => {
    if (channelName === "facebook") {
      return "/assets/images/facebook.png";
    } else if (channelName === "instagram") {
      return "/assets/images/instagram.png";
    } else {
      return ""; // Default or fallback image URL
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="relative">
        <div
          className={`w-14 h-14 md:w-14 md:h-14 bg-cover bg-left bg-no-repeat rounded-full cursor-pointer border-4 ${isSelected ? 'border-blue-500' : 'border-white'}`}
          style={{ 
            backgroundImage: `url(${bigCircleImageUrl})`,
            transition: "transform 0.2s ease-in-out",
          }}
          onClick={onClick} // Attach onClick event handler to big circle
        ></div>
        <div
          className="w-6 h-6 md:w-6 md:h-6 bg-cover bg-left bg-no-repeat rounded-full absolute bottom-0 right-0 cursor-pointer"
          style={{ backgroundImage: `url(${getSmallCircleImageUrl(channelName)})` }}
          onClick={onClick} // Attach onClick event handler to small circle
        ></div>
      </div>
    </div>
  );
};

export default CircleAnalyticsComponent;
