import "./App.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

// Importing components
import HomeNew from "./Components/HomeNew/HomeNew";
import Fundraiser from "./Components/BrowseFundraiser/Fundraiser";
import PrabaltaServicesDashboard from "./Components/Common/PrabaltaServicesDashboard";
import DonorList from "./Components/DonorManagement/DonorList";
import NgoLogin from "./Components/NGOAuth/NgoLogin";
import AdminLogin from "./Components/NGOAuth/AdminLogin";
import NgoCreateAccount from "./Components/NGOAuth/NgoCreateAccount";
import NgoAddressDetails from "./Components/NGOAuth/NgoAddressDetails";
import ProfilePage from "./Components/NGOAuth/ProfilePage";
import { DonorInformation } from "./Components/DonorManagement/DonorInformation";
import AddDonorManually from "./Components/DonorManagement/AddDonorManually";
import AllDonationList from "./Components/DonorManagement/AllDonationList";
import { CustomReceipt } from "./Components/DonorManagement/CustomReceipt";
import { FundraiserInformation } from "./Components/DonorManagement/FundraiserInformation";
import AllFundraisersList from "./Components/DonorManagement/AllFundraisersList";
import AddDonation from "./Components/DonorManagement/AddDonation";
import CreateFund from "./Components/DonorManagement/CreateFund";
import Report from "./Components/DonorManagement/Report";
import EditDonation from "./Components/DonorManagement/EditDonation";
import SocialMediaCampaign from "./Components/SocialMediaIntegration/SocialMediaCampaign";
import LinkedInOAuth from "./Components/SocialMediaIntegration/LinkedInOAuth";
import ThreadOAuthPage from "./Components/SocialMediaIntegration/ThreadOAuthPage";
import AnalyticsDashboard from "./Components/Analytics/AnalyticsDashboard";
import FundraiserPerformance from "./Components/Analytics/FundraiserPerformance";
import FundraiserPerformanceDetails from "./Components/Analytics/FundraiserPerformanceDetails";
import OAuth2RedirectHandler from "./Components/Login/OAuth2RedirectHandler";
import SchedulePost from "./Components/SocialMediaIntegration/SchedulePost";
import CreatePost from "./Components/SocialMediaIntegration/CreatePost";
import CausewiseDonation from "./Components/Analytics/CausewiseDonation";
import ParentComponent from "./Components/SocialMediaIntegration/ParentComponent";
import ViewTags from "./Components/SocialMediaIntegration/ViewTags";
import SocialMediaAnalytics from "./Components/SocialMediaIntegration/SocialMediaAnalytics";
import ConnectedChannels from "./Components/SocialMediaIntegration/ConnectedChannels";
import NewUserSignUpAnalytics from "./Components/Analytics/NewUserSignUpAnalytics";
import NewDonorAnalytics from "./Components/Analytics/NewDonorAnalytics";
import NewRecurringDonorAnalytics from "./Components/Analytics/NewRecurringDonorAnalytics";
import FacebookCallback from "./Components/SocialMediaIntegration/FacebookCallback";
import LinkedInIntegration from "./Components/SocialMediaIntegration/LinkedInIntegration";
import LinkedInRedirect from "./Components/SocialMediaIntegration/LinkedInRedirect";
import DonationConversionReport from "./Components/Analytics/DonationConversionReport";
import ReturningDonorAnalytics from "./Components/Analytics/ReturningDonorAnalytics";
import RecurringCausewiseDonation from "./Components/Analytics/RecurringCausewiseDonation";
import CreateDomain from "./Components/CreateDomain/CreateDomain";
import WebsiteDashboard from "./Components/CreateDomain/WebsiteDashboard";
import VerifyUser from "./Components/AdminDashboard/VerifyUser";
import VerifiedUser from "./Components/AdminDashboard/VerifiedUser";
import ReejectedUser from "./Components/AdminDashboard/ReejectedUser";
import VerifyFundraiser from "./Components/AdminDashboard/VerifyFundraiser";
import DonorsHistory from "./Components/AdminDashboard/DonorsHistory";
import FundraisersHistory from "./Components/AdminDashboard/FundraiserHistory";
import RecurringDonation from "./Components/AdminDashboard/RecurringDonation";
import MoneyDetails from "./Components/MoneyDetails/MoneyDetails";
import AllMoney from "./Components/MoneyDetails/AllMoney";
import TotalMoney from "./Components/MoneyDetails/TotalMoney";
import AllTransactions from "./Components/MoneyDetails/AllTransactions";
import DonationCause from "./Components/MoneyDetails/DonationCause";
import AllFundraisers from "./Components/MoneyDetails/AllFundraisers";
import BankStatus from "./Components/MoneyDetails/BankStatus";
import CreateFundraiserAdmin from "./Components/MoneyDetails/CreateFundraiserAdmin";
import LoginInfo from "./Components/Common/LoginInfo";
import MailingList from "./Components/EmailCampaign/MailingList";
import AddEmailsMailList from "./Components/EmailCampaign/AddEmailsMailList";
import EmailList from "./Components/EmailCampaign/EmailList";
import MyCampaign from "./Components/EmailCampaign/MyCampaign";
import GrapsReactTemplate from "./Components/EmailCampaign/GrapsReactTemplate";
import TemplateList from "./Components/EmailCampaign/TemplateList";
import MyCampaignList from "./Components/EmailCampaign/MyCampaignList";
import EditCampaign from "./Components/EmailCampaign/EditCampaign";
import HomePage from "./Components/Home/HomePage";
import Support from "./Components/Contact/Support";
import SupportUs from "./Components/ContactUs/Support";
import { PaymentMethods } from "./Components/UtilizePaymentMethod/PaymentMethods";
import ForgetPassword from "./Components/Login/ForgetPassword";
import ChangePassword from "./Components/Login/ChangePassword";
import LoginScreen from "./Components/Login/LoginScreen";
import CreateAccount from "./Components/Profile/CreateAccount";
import CreateProfile from "./Components/Profile/CreateProfile";
import AddAddressDetails from "./Components/Profile/AddAddressDetails";
import VerifyAddress from "./Components/Profile/VerifyAddress";
import Success from "./Components/Profile/Success";
import SuccessPartner from "./Components/Profile/SuccessPartner";
import ComingSoon from "./Components/Home/ComingSoon";
import CreateFundraiser from "./Components/CreateFundraiser/CreateFundraiser";
import BrowseFundraiser from "./Components/BrowseFundraiser/BrowseFundraiser";
import AboutUs from "./Components/AboutUs/AboutUs";
import ContactUs from "./Components/ContactUs/ContactUs";
import Other from "./Components/CreateFundraiser/Other";
import OtherAdmin from "./Components/MoneyDetails/OtherAdmin";
import Self from "./Components/CreateFundraiser/Self";
import SelfAdmin from "./Components/MoneyDetails/SelfAdmin";
import DonateNow from "./Components/BrowseFundraiser/DonateNow";
import TransactionDetails from "./Components/BrowseFundraiser/TransactionDetails";
import VerifyUsers from "./Components/Profile/VerifyUsers";
import VerifySuccess from "./Components/Profile/VerifySuccess";
import HowWeWork from "./Components/HowWeWork/HowWeWork";
import HowWeWorkModal from "./Components/HowWeWork/HowWeWorkModal";
import AddBankDetails from "./Components/Profile/AddBankDetails";
import ViewCause from "./Components/Cause/ViewCause";
import StripeStatus from "./Components/payment-getways/StripeStatus";
import StripeRecurringPayment from "./Components/payment-getways/StripeRecurringPayment";
import StripePayments from "./Components/payment-getways/StripePayments";
import StripeRecurringStatus from "./Components/payment-getways/StripeRecurringStatus";
import AllSupportQueries from "./Components/MoneyDetails/AllSupportQueries";
import AllEnquiries from "./Components/MoneyDetails/AllEnquiries";
import Cookies from "js-cookie";
import EditFundraiser from "./Components/BrowseFundraiser/EditFundraiser";
import PaymentRequest from "./Components/MoneyDetails/PaymentRequest";
import ManageSubAdmin from "./Components/MoneyDetails/ManageSubAdmin";
import AddSubAdmin from "./Components/MoneyDetails/AddSubAdmin";
import Privacy from "./Components/Home/Privacy";
import CookiePolicy from "./Components/Home/CookiePolicy";
import { Terms } from "./Components/Home/Terms";
import LegalPolicy from "./Components/Home/LegalPolicy";
import LiveFeeds from "./Components/Home/LiveFeeds";
import PaypalStatus from "./Components/payment-getways/PaypalStatus";
import AddDonationAdmin from "./Components/MoneyDetails/AddDonationAdmin";
import ZelleStatus from "./Components/payment-getways/ZelleStatus";
// import BoardMemberDetails from "./Components/AboutUs/BoardMemberDetails";
// import { Term, Terms } from "./Components/Home/Terms";

// PrivateRoute for User Login
const PrivateUserRoute = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (userData != null && userData.userType === "Admin") {
    return <Navigate to="/money-details" />;
  }

  return userData ? children : <Navigate to="/ngoServices" />;
};

const PrivateUserCheckAddressRoute = ({ children }) => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userAddressDetails = JSON.parse(
    localStorage.getItem("userAddressDetails")
  );
  if (userData && userData.userType === "Admin") {
    return <Navigate to="/money-details" />;
  }

  if (userAddressDetails == null || userAddressDetails.addressDetails == null) {
    return <Navigate to="/add-ngo-addressdetails" />;
  }

  return userData ? children : <Navigate to="/ngoServices" />;
};

// PrivateRoute for Partner Login
const PrivatePartnerRoute = ({ children }) => {
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  return partnerData ? children : <Navigate to="/ngoServices" />;
};

const PrivateAdminRoute = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  return userData && userData.userType === "Admin" ? (
    children
  ) : (
    <Navigate to={userData ? "/ngoServices" : "/money-details"} />
  );
};

const PrivateLoginRouteUser = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (userData) {
    return <Navigate to="/" />;
  }
  return children;
};

const PrivateLoginRoutePartner = ({ children }) => {
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  // const subAdmin = JSON.parse(localStorage.getItem("subadminData"));
  if (partnerData) {
    return <Navigate to="/services-dashboard" />;
  }
  return children;
};

// const renderPaymentRequest = () => {
//   const partnerData = JSON.parse(localStorage.getItem("partnerData"));
//   const partnerId = partnerData?.partnerId;

//   return partnerId === 1 ? <PaymentRequest /> : <Navigate to="/" />;
// };

function App() {
  const [paymentFlowCompleted, setPaymentFlowCompleted] = useState(false);
  const [paymentFlowCompletedRecurring, setPaymentFlowCompletedRecurring] =
    useState(false);
  const [isFundraiserInfoFilled, setIsFundraiserInfoFilled] = useState(false);

  const handlePaymentFlowStep1Complete = () => {
    setPaymentFlowCompleted(true);
  };

  const handlePaymentFlowStep2Complete = () => {
    setPaymentFlowCompleted(true);
  };

  const handleFilledFundraiserInfo = () => {
    setIsFundraiserInfoFilled(true);
  };

  const handlePaymentFlowRecurring = () => {
    setPaymentFlowCompletedRecurring(true);
  };

  return (
    <BrowserRouter>
      <CookiePolicy />
      <Routes>
        {/* Default Route */}
        {/* <Route
          path="/ngoServices"
          element={
            <PrivateLoginRoutePartner>
              <PrivateLoginRouteUser>
                <HomeNew />
              </PrivateLoginRouteUser>
            </PrivateLoginRoutePartner>
          }
        /> */}

        {/* ******************************** USER SIDE *********************************** */}
        <Route path="/" element={<HomePage />} />
        <Route
          path="/login"
          element={
            <PrivateLoginRouteUser>
              <LoginScreen />
            </PrivateLoginRouteUser>
          }
        />

        <Route
          path="/forgetpassword-user"
          element={
            <PrivateLoginRouteUser>
              <ForgetPassword />
            </PrivateLoginRouteUser>
          }
        />
        <Route
          path="/changepassword-user"
          element={
            <PrivateLoginRouteUser>
              <ChangePassword />
            </PrivateLoginRouteUser>
          }
        />
        <Route
          path="/createaccount-user"
          element={
            <PrivateLoginRouteUser>
              <CreateAccount />
            </PrivateLoginRouteUser>
          }
        />
        {/* <Route
          path="/addaddressdetails-user"
          element={
            <PrivateLoginRouteUser>
              <AddAddressDetails />
            </PrivateLoginRouteUser>
          }
        /> */}
        <Route path="/addaddressdetails-user" element={<AddAddressDetails />} />
        <Route path="/verifyaddress-user" element={<VerifyAddress />} />
        <Route path="/success-user" element={<Success />} />
        <Route path="/success-paypal" element={<PaypalStatus />} />
        <Route path="/verifysuccess-user" element={<VerifySuccess />} />
        <Route path="/comingsoon-user" element={<ComingSoon />} />
        <Route
          path="/createfundraiser-user"
          element={
            <PrivateUserCheckAddressRoute>
              <CreateFundraiser onComplete={handleFilledFundraiserInfo} />
            </PrivateUserCheckAddressRoute>
          }
        />
        <Route path="/browsefundraiser-user" element={<BrowseFundraiser />} />
        {/* <Route
          path="/donate"
          element={
            <PrivateUserRoute>
              <DonateNow onComplete={handlePaymentFlowRecurring} />
            </PrivateUserRoute>
          }
        /> */}

        <Route
          path="/donate"
          element={<DonateNow onComplete={handlePaymentFlowRecurring} />}
        />

        <Route
          path="/fundraiser/:id"
          element={
            // <PrivateUserRoute>
            <Fundraiser onComplete={handlePaymentFlowStep2Complete} />
            // </PrivateUserRoute>
          }
        />
        <Route path="/aboutus-user" element={<AboutUs />} />
        {/* <Route path="/bio/:id" element={<BoardMemberDetails />} /> */}
        <Route path="/contactus-user" element={<ContactUs />} />
        <Route path="/support-user" element={<SupportUs />} />
        <Route path="/terms" element={<Terms />} />

        <Route
          path="/individual-user"
          element={isFundraiserInfoFilled ? <Other /> : <Navigate to="/" />}
        />
        <Route
          path="/organisation-user"
          element={isFundraiserInfoFilled ? <Self /> : <Navigate to="/" />}
        />
        <Route
          path="/self-admin"
          element={isFundraiserInfoFilled ? <SelfAdmin /> : <Navigate to="/" />}
        />
        <Route
          path="/other-admin"
          element={
            isFundraiserInfoFilled ? <OtherAdmin /> : <Navigate to="/" />
          }
        />
        <Route
          path="/transactiondetails-user"
          element={
            paymentFlowCompleted ? (
              <TransactionDetails onComplete={handlePaymentFlowStep1Complete} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/verifyusers-user"
          element={
            <PrivatePartnerRoute>
              <VerifyUsers />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/verifieduser-user"
          element={
            <PrivatePartnerRoute>
              <VerifiedUser />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/rejecteduser-user"
          element={
            <PrivatePartnerRoute>
              <ReejectedUser />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/verifyFundraiser-user"
          element={
            <PrivatePartnerRoute>
              <VerifyFundraiser />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/donorshistory"
          element={
            <PrivateUserRoute>
              <DonorsHistory />
            </PrivateUserRoute>
          }
        />
        <Route
          path="/fundraiserhistory"
          element={
            <PrivateUserRoute>
              <FundraisersHistory />
            </PrivateUserRoute>
          }
        />
        {/* <Route
          path="/edit-fundraiser" 
          element={
            <PrivateUserRoute>
              <EditFundraiser />
            </PrivateUserRoute>
          }
        /> */}
        <Route
          path="/recurringdonation"
          element={
            <PrivateUserRoute>
              <RecurringDonation />
            </PrivateUserRoute>
          }
        />
        <Route path="/howwework-user" element={<HowWeWork />} />
        <Route path="/howweworkModal-user" element={<HowWeWorkModal />} />
        <Route
          path="/userprofile"
          element={
            <PrivateUserRoute>
              <CreateProfile />
            </PrivateUserRoute>
          }
        />
        <Route path="/add-bank-details-user" element={<AddBankDetails />} />
        <Route
          path="/list-of-causes-user"
          element={
            <PrivateUserRoute>
              <ViewCause />
            </PrivateUserRoute>
          }
        />
        <Route
          path="/money-details-user"
          element={
            <PrivateAdminRoute>
              <MoneyDetails />
            </PrivateAdminRoute>
          }
        />

        <Route path="/all-supportQueries" element={<AllSupportQueries />} />
        <Route path="/all-enquiries" element={<AllEnquiries />} />
        <Route path="/live-feeds" element={<LiveFeeds />} />

        {/* <Route
          path="/all-supportQueries"
          element={
            <PrivateAdminRoute>
              <AllSupportQueries />
            </PrivateAdminRoute>
          }
        /> */}

        <Route
          path="/all-money-user"
          element={
            <PrivatePartnerRoute>
              <AllMoney />
            </PrivatePartnerRoute>
          }
        />
        <Route path="/total-money-user" element={<TotalMoney />} />
        <Route
          path="/all-transactions-user"
          element={
            <PrivatePartnerRoute>
              <AllTransactions />
            </PrivatePartnerRoute>
          }
        />

        <Route
          path="/add-donation-admin"
          element={
            <PrivatePartnerRoute>
              <AddDonationAdmin />
            </PrivatePartnerRoute>
          }
        />
        <Route path="/donaton-cause-user" element={<DonationCause />} />
        <Route
          path="/all-fundraiser-user"
          element={
            <PrivatePartnerRoute>
              <AllFundraisers />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/bank-status-user"
          element={
            <PrivatePartnerRoute>
              <BankStatus />
            </PrivatePartnerRoute>
          }
        />

        <Route
          path="/createfundraiser-admin"
          element={
            <PrivatePartnerRoute>
              <CreateFundraiserAdmin onComplete={handleFilledFundraiserInfo} />
            </PrivatePartnerRoute>
          }
        />

        <Route path="/paymentrequest" element={<PaymentRequest />} />

        <Route path="/stripe-status-user" element={<StripeStatus />} />
        <Route path="/zelleSuccess" element={<ZelleStatus />} />

        <Route
          path="/stripe-donation"
          element={
            paymentFlowCompletedRecurring ? (
              <StripeRecurringPayment />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/stripe-recurring-status-user"
          element={
            <PrivateUserRoute>
              <StripeRecurringStatus />
            </PrivateUserRoute>
          }
        />
        <Route
          path="/stripepay"
          element={
            paymentFlowCompleted ? <StripePayments /> : <Navigate to="/" />
          }
        />
        <Route path="/success-partner" element={<SuccessPartner />} />

        {/* ********************************PRABALTA 2.0 *********************************** */}
        <Route
          path="/services-dashboard"
          element={
            <PrivatePartnerRoute>
              <PrabaltaServicesDashboard />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/donorlist"
          element={
            <PrivatePartnerRoute>
              <DonorList />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/donorInfo"
          element={
            <PrivatePartnerRoute>
              <DonorInformation />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/addDonorManually"
          element={
            <PrivatePartnerRoute>
              <AddDonorManually />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/allDonationList"
          element={
            <PrivatePartnerRoute>
              <AllDonationList />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/customReceipt"
          element={
            <PrivatePartnerRoute>
              <CustomReceipt />
            </PrivatePartnerRoute>
          }
        />
        <Route path="/fundraiserInfo" element={<FundraiserInformation />} />
        <Route
          path="/allFundraiserList"
          element={
            <PrivatePartnerRoute>
              <AllFundraisersList />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/manageSubAdmins"
          element={
            <PrivatePartnerRoute>
              <ManageSubAdmin />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/ngo-login"
          element={
            <PrivateLoginRoutePartner>
              <NgoLogin />
            </PrivateLoginRoutePartner>
          }
        />

        <Route
          path="/admin-login"
          element={
            <PrivateLoginRoutePartner>
              <AdminLogin />
            </PrivateLoginRoutePartner>
          }
        />

        <Route path="/privacy" element={<Privacy />} />
        <Route path="/LegalPolicy" element={<LegalPolicy />} />

        <Route
          path="/forgetpassword"
          element={
            <PrivateLoginRoutePartner>
              <ForgetPassword />
            </PrivateLoginRoutePartner>
          }
        />
        <Route
          path="/changepassword"
          element={
            <PrivateLoginRoutePartner>
              <ChangePassword />
            </PrivateLoginRoutePartner>
          }
        />

        <Route
          path="/addSubAdminDetails"
          element={
            // <PrivateLoginRoutePartner>
            <AddSubAdmin />
            // </PrivateLoginRoutePartner>
          }
        />

        <Route path="/ngo-create-account" element={<NgoCreateAccount />} />
        <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />} />
        <Route path="/add-ngo-addressdetails" element={<NgoAddressDetails />} />
        <Route
          path="/addDonations"
          element={
            <PrivatePartnerRoute>
              <AddDonation />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/editDonations"
          element={
            <PrivatePartnerRoute>
              <EditDonation />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/report"
          element={
            <PrivatePartnerRoute>
              <Report />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/socialMediaCampaign"
          element={
            <PrivatePartnerRoute>
              <SocialMediaCampaign />
            </PrivatePartnerRoute>
          }
        />

        <Route path="/linkdinOAuth" element={<LinkedInOAuth />} />
       

        <Route path="/threads-auth" element={<ThreadOAuthPage />} />
        <Route path="/connect-linkedin" element={<LinkedInIntegration />} />
        <Route path="/linkedin-redirect" element={<LinkedInRedirect />} />



        <Route
          path="/analyticsDashboard"
          element={
            <PrivatePartnerRoute>
              <AnalyticsDashboard />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/fundraiserPerformance"
          element={
            <PrivatePartnerRoute>
              <FundraiserPerformance />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/schedulePost"
          element={
            <PrivatePartnerRoute>
              <SchedulePost />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/createPost"
          element={
            <PrivatePartnerRoute>
              <CreatePost />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/newSignUpAnalytics"
          element={
            <PrivatePartnerRoute>
              <NewUserSignUpAnalytics />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/newDonorAnalytics"
          element={
            <PrivatePartnerRoute>
              <NewDonorAnalytics />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/donationConversionReport"
          element={
            <PrivatePartnerRoute>
              <DonationConversionReport />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/newRecurringDonorAnalytics"
          element={
            <PrivatePartnerRoute>
              <NewRecurringDonorAnalytics />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/fundraiserPerformanceDetails"
          element={
            <PrivatePartnerRoute>
              <FundraiserPerformanceDetails />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/causewiseDonation"
          element={
            <PrivatePartnerRoute>
              <CausewiseDonation />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/calender"
          element={
            <PrivatePartnerRoute>
              <ParentComponent />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/viewTags"
          element={
            <PrivatePartnerRoute>
              <ViewTags />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/connectedChannels"
          element={
            <PrivatePartnerRoute>
              <ConnectedChannels />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/socialMediaAnalytics"
          element={
            <PrivatePartnerRoute>
              <SocialMediaAnalytics />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivatePartnerRoute>
              <ProfilePage />
            </PrivatePartnerRoute>
          }
        />
        <Route path="/auth/facebook/callback" element={<FacebookCallback />} />
        <Route
          path="/returningDonorAnalytics"
          element={
            <PrivatePartnerRoute>
              <ReturningDonorAnalytics />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/recurringCausewiseDonation"
          element={
            <PrivatePartnerRoute>
              <RecurringCausewiseDonation />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/createDomain"
          element={
            <PrivatePartnerRoute>
              <CreateDomain />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/loginInfo"
          element={
            <PrivatePartnerRoute>
              <LoginInfo />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/mailingList"
          element={
            <PrivatePartnerRoute>
              <MailingList />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/addEmailToMailList"
          element={
            <PrivatePartnerRoute>
              <AddEmailsMailList />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/emailList"
          element={
            <PrivatePartnerRoute>
              <EmailList />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/websiteDashboard"
          element={
            <PrivatePartnerRoute>
              <WebsiteDashboard />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/emailList/:mailingListId"
          element={
            <PrivatePartnerRoute>
              <EmailList />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/myCampaign"
          element={
            <PrivatePartnerRoute>
              <MyCampaign />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/templateBuilder"
          element={
            <PrivatePartnerRoute>
              <GrapsReactTemplate />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/campaignList"
          element={
            <PrivatePartnerRoute>
              <MyCampaignList />
            </PrivatePartnerRoute>
          }
        />

        <Route
          path="/templateList"
          element={
            <PrivatePartnerRoute>
              <TemplateList />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/editCampaign"
          element={
            <PrivatePartnerRoute>
              <EditCampaign />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/paymentMethod"
          element={
            <PrivatePartnerRoute>
              <PaymentMethods />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/editCampaign/:campaignId"
          element={
            <PrivatePartnerRoute>
              <EditCampaign />
            </PrivatePartnerRoute>
          }
        />
        <Route path="/support" element={<Support />} />
        <Route
          path="/money-details"
          element={
            <PrivatePartnerRoute>
              <MoneyDetails />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/all-fundraiser"
          element={
            <PrivatePartnerRoute>
              <AllFundraisers />
            </PrivatePartnerRoute>
          }
        />
        <Route
          path="/edit-fundraiser"
          element={
            <PrivatePartnerRoute>
              <EditFundraiser />
            </PrivatePartnerRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
