import React from "react";

import DonorManagementSideBar from "./DonorManagementSideBar";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Tour from "reactour";
// import Box from "@mui/material/Box";
import { Tooltip as RxTooltip } from "@mantine/core";

import {
  ActionIcon,
  Box,
  Button,
  Loader,
  MantineProvider,
} from "@mantine/core";
import TopHeader from "../Home/TopHeader";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
  getRequestWithOutToken,
  postRequestWithoutTokenWithoutData,
  getRequestWithTokenWithoutDataPartner,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import DonorManagementTop from "./DonorManagementTop";
import CreateFund from "./CreateFund";
import MultipleTour from "./MultipleTour";

const AllFundraisersList = () => {
  const navigate = useNavigate();
  const currentStepIndex = localStorage.getItem("tourStepNew");

  const [fundraiserData, setFundraiserData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true);

  const [sorting, setSorting] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [value, setValue] = React.useState(0);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [modalopen, setModalOpen] = useState(false);
  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const tourCompletedFlag = localStorage.getItem("donorTourCompletedFlag");
  console.log("donorTourCompletedFlag ", tourCompletedFlag);

  const partnerId = partnerData.partnerId;

  useEffect(() => {
    if (!fundraiserData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getAllFundraiserList();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting, //refetch when sorting changes
    activeTab,
    columnFilters,
    modalopen,
  ]);

  const getAllFundraiserList = async () => {
    console.log(activeTab);
    const url = new URL(`${TQ_URL}/getAllFundraiserList`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("activeTab", `${activeTab}`);

    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);

    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );

      console.log(response);

      console.log(response.datalist);

      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log(response.datalist);

      setFundraiserData(dataWithSerialNumber);
      setRowCount(response.totalCount);

      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the all donation list:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    //  setIsRefetching(false);
  };

  const formatCurrency = (value) => {
    if (!value) return "";
    const amount = parseFloat(value);
    if (isNaN(amount)) return "";
    return `$${amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        sortable: true,
      },

      {
        accessorKey: "fundraiserName",
        header: "Fundraiser name",
        sortable: true,
        Cell: ({ cell }) => toInitCap(cell.getValue()),
        Cell: ({ row }) => {
          const title = row.original.fundraiserName;
          return (
            <RxTooltip
              label={title || ""}
              styles={(theme) => ({
                tooltip: {
                  backgroundColor: theme.colors.blue[9],
                  color: theme.white,
                },
              })}
            >
              <span>
                {title && title.length > 15
                  ? `${title.substring(0, 15)}...`
                  : title}
              </span>
            </RxTooltip>
          );
        },
      },

      {
        accessorKey: "formattedTargerDate",
        header: "Target Date",
        sortable: true,
      },
      {
        accessorKey: "causeNames",
        header: "Cause Name",
        sortable: true,
        Cell: ({ row }) => {
          const title = row.original.causeNames;
          return (
            <RxTooltip
              label={title || ""}
              styles={(theme) => ({
                tooltip: {
                  backgroundColor: theme.colors.blue[9],
                  color: theme.white,
                },
              })}
            >
              <span>
                {title && title.length > 15
                  ? `${title.substring(0, 15)}...`
                  : title}
              </span>
            </RxTooltip>
          );
        },
      },
      {
        accessorKey: "targetAmt",
        header: "Target amount",
        sortable: true,
        Cell: ({ cell }) => <Box>{formatCurrency(cell.getValue())}</Box>,
      },
      {
        accessorKey: "amountAchieved",
        header: "Generated amount",
        sortable: true,
        Cell: ({ cell }) => <Box>{formatCurrency(cell.getValue())}</Box>,
      },
      {
        accessorKey: "formattedDate",
        header: "Created Date",
        sortable: true,
      },
    ],
    []
  );

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      console.log("searchText:", searchText);

      const url = new URL(`${TQ_URL}/searchImportedFundraiserList`);
      url.search = new URLSearchParams();
      url.searchParams.set("start", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("activeTab", `${activeTab}`);
      url.searchParams.set("keyword", `${searchText}`);
      url.searchParams.set("partnerId", `${partnerData.partnerId}`);
      try {
        const response = await getRequestWithTokenWithoutDataPartner(
          url.toString()
        );

        console.log(response);

        console.log(response.dataList);

        const dataWithSerialNumber = response.dataList.map((item, index) => ({
          ...item,
          id: index + 1,
        }));

        console.log(response.dataList);

        setFundraiserData(dataWithSerialNumber);
        setRowCount(response.totalCount);

        setIsRefetching(false);
      } catch (error) {
        console.error("Error in fetching the searched donor's history:", error);
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
  };
  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleEdit = (row) => {
    console.log("inside edit");
    console.log(activeTab);

    console.log(row);
  };

  const handleTabs = (e, value) => {
    console.log(e);
    setValue(e);
    console.log("Value:", value);
    if (e == 0) {
      setActiveTab(1);
      console.log("data from imported donors");
    } else {
      console.log("data from website donors");
      setActiveTab(2);
    }
  };

  const table = useMantineReactTable({
    columns,
    data: fundraiserData,

    initialState: { showColumnFilters: false },
    rowCount,

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,

    // enableRowActions: true,
    // positionActionsColumn: "last",

    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const rowData = row.original;
        const fundraiserId = row.original.fundraiserId;
        console.log(fundraiserId);
        console.log(activeTab);
        localStorage.setItem("activeTabFundraiser", activeTab);
        navigate("/fundraiserInfo", { state: { fundraiserId } });
      },
      sx: { cursor: "pointer" },
    }),

    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });
  function importModal() {
    setModalOpen(true);
  }

  return (
    <>
      {/* <TopHeader /> */}
      <DonorManagementTop />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user1">
          {/* <div className="mx-auto shadow-md">
            <div className="flex flex-col md:flex-row justify-between items-center container px-10 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">All Fundraiser List</h2>
              </div>
              <div className="flex flex-row items-center md:ml-4 mt-4 md:mt-0 md:flex-2">
                <div className="md:mx-2 mx-2 py-2 md:py-0">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="py-2 pr-8 pl-4 block rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className="md:mx-2 mx-2 py-2 md:py-0">
                  {activeTab == 1 && (
                    <button
                      id="addFundraiser"
                      className="btn-style-1 text-white py-2 px-4 rounded"
                      onClick={importModal}
                    >
                      +Add Fund
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="mx-auto shadow-md">
            <div className="flex flex-col md:flex-row justify-between items-center container px-10 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">All Fundraiser List</h2>
              </div>
              <div className="flex flex-col md:flex-row items-center md:ml-4 mt-4 md:mt-0 w-full md:w-auto">
                <div className="w-full md:w-auto mx-2 py-2 md:py-0">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="w-full md:w-auto py-2 pr-8 pl-4 block rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className="w-full md:w-auto mx-2 py-2 md:py-0 flex justify-start md:justify-end">
                  {activeTab == 1 && (
                    <button
                      id="addFundraiser"
                      className="w-full md:w-auto btn-style-1 text-white py-2 px-4 rounded"
                      onClick={importModal}
                    >
                      +Add Fund
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="mx-auto shadow-md">
            <div className="flex flex-col md:flex-col lg:flex-row justify-between items-center container px-10 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">All Fundraiser List</h2>
              </div>
              <div className="flex flex-col md:flex-row items-center md:ml-4 mt-4 md:mt-0 md:w-full lg:w-auto">
                <div className="w-full md:mx-2 mx-2 py-2 md:py-0">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="w-full py-2 pr-8 pl-4 block rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className="w-full md:mx-2 mx-2 py-2 md:py-0 mt-4 md:mt-0">
                  {activeTab == 1 && (
                    <button
                      id="addFundraiser"
                      className="w-full btn-style-1 text-white py-2 px-4 rounded"
                      onClick={importModal}
                    >
                      +Add Fund
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="mx-auto shadow-md">
            <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row justify-between items-center container px-10 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">All Fundraiser List</h2>
              </div>
              <div className="flex flex-col sm:flex-col md:flex-row items-center sm:ml-0 md:ml-4 mt-4 sm:mt-4 md:mt-0 sm:w-full md:w-full lg:w-auto">
                <div className="w-full sm:mx-2 mx-2 py-2 sm:py-2 md:py-0">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="w-full py-2 pr-8 pl-4 block rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className="w-full sm:mx-2 mx-2 py-2 sm:py-2 md:py-0 mt-4 sm:mt-4 md:mt-0">
                  {activeTab == 1 && (
                    <button
                      id="addFundraiser"
                      className="w-full btn-style-1 text-white py-2 px-4 rounded"
                      onClick={importModal}
                    >
                      +Add Fund
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div> */}

          <div className="mx-auto shadow-md">
            <div className="flex flex-col lg:flex-row justify-between items-center container px-10 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">All Fundraiser List</h2>
              </div>
              <div className="flex flex-row items-center w-full lg:w-auto mt-4 lg:mt-0">
                <div className="w-full mx-2 py-2">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="w-full py-2 pr-8 pl-4 block rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className="mx-2 py-2">
                  {activeTab == 1 && (
                    <button
                      id="addFundraiser"
                      className="btn-style-1 text-white py-2 px-4 rounded"
                      onClick={importModal}
                    >
                      +Add Fund
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="container md:mx-auto md:py-16 py-6">
            {/*   <div sx={{ width: "100%" }}>*/}
            <div style={{ borderBottom: "1px solid #ddd" }}>
              <Tabs
                value={value}
                onChange={(event, newValue) => handleTabs(newValue)}
                aria-label="basic tabs example"
              >
                <Tab id="fund-step2" label="Imported Fundraisers" value={0} />
                <Tab id="fund-step3" label="Website Fundraisers" value={1} />
              </Tabs>
            </div>

            <div className="md:py-4 py-6">
              {value === 0 && <MantineReactTable table={table} />}
              {value === 1 && (
                <MantineReactTable table={table} /> // Replace `table2` with your data for tab 2
              )}
            </div>
          </div>
          {/* <MantineReactTable table={table} /> */}
          {/*  </div>*/}
        </main>
      </div>
      {modalopen && <CreateFund onClose={() => setModalOpen(false)} />}

      {tourCompletedFlag === "false" && isLargeScreen && <MultipleTour />}
    </>
  );
};
export default AllFundraisersList;
