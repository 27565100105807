import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";
import TopHeader from "./TopHeader";

const Header = () => {
  const location = useLocation();
  const [config, setConfig] = useState({});
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const handleKeyPress = (event) => {
    var path = location.pathname;
    if (event.key === "Enter") {
      redirectToAnotherPage(searchText);
    }
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const redirectToAnotherPage = (searchText) => {
    const value = {
      keyword: searchText,
    };

    navigate("/browsefundraiser-user", { state: value });
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setSticky(offset > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const isActiveRoute = (path) => {
    return location.pathname === path;
  };

  const [userData, setUserData] = useState(null);
  const isUserLoggedIn = () => {
    return userData !== null;
  };

  const logLocalData = () => {
    const localStorageData = localStorage.getItem("userData");
    console.log("Local Storage Data:", JSON.parse(localStorageData));

    const localStorageAddressData = localStorage.getItem("userAddressDetails");
    console.log(
      "Local Storage Address Data:",
      JSON.parse(localStorageAddressData)
    );

    setUserData(JSON.parse(localStorageData));
  };

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error("Error loading config.json:", error));

    logLocalData();
  }, []);

  const handleCreateFundraiserClick = () => {
    if (isUserLoggedIn() && localStorage.getItem("accessToken") !== null) {
      navigate("/createfundraiser-user");
    } else {
      navigate("/ngo-login");
    }
    setIsMobileMenuOpen(false);
  };

  const handleDonateNowClick = () => {
    if (isUserLoggedIn() && localStorage.getItem("accessToken") !== null) {
      navigate("/donate");
    } else {
      navigate("/ngo-login");
    }
    setIsMobileMenuOpen(false);
  };

  return (
    <div
      className={`w-full z-50 bg-white ${
        isSticky ? "sticky-header slide-in-up" : ""
      }`}
    >
      <TopHeader />
      {/* Main Header */}
      <div className="bg-white p-2 flex flex-col md:flex-row justify-between items-center px-4 md:px-10 2xl:px-20">
        {/* Logo */}
        <div className="mb-4 md:mb-0 flex justify-between navWidth">
          <Link to="/">
            <img
              src={config.logoUrl}
              alt="Logo"
              className="h-10 mx-auto sm:mx-0"
            />
          </Link>

          {/* Search bar for tablet screens */}
          <div className="hidden lg:hidden md:flex rounded p-1 w-full md:w-4/12 mx-4">
            <input
              type="text"
              placeholder="Search Fundraisers"
              className="border outline-none py-2 px-2 w-full rounded-3xl header-search-box focus:border-blue-800 focus:ring-blue-800"
              onChange={handleChange}
              onKeyDown={handleKeyPress}
            />
          </div>

          {/* Donate Now Button and Hamburger Menu */}
          <div className="flex justify-end">
            <Link
              to="/browsefundraiser-user"
              className="btn-style-1 text-white text-sm py-2 px-6 rounded block hambuger mr-4"
            >
              Donate
            </Link>
            {/* Hamburger Toggle for Mobile */}
            <div className="hambuger flex mt-2 items-center">
              <button
                className="text-dark-500 font-semibold focus:outline-none"
                onClick={toggleMobileMenu}
              >
                <FiMenu size={24} />
              </button>
            </div>
          </div>
        </div>

        {isMobileMenuOpen && (
          <div className="  fixed inset-0 bg-opacity-75 bg-gray-800 z-50">
            <AnimatePresence>
              <motion.div
                key="modal"
                initial={{ x: "100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: "-100%", opacity: 0 }}
                transition={{ duration: 0.4 }}
                className="absolute right-0 bg-white border rounded shadow-md md-lg:3/4 lg:w-[35%] px-5 py-2 h-full"
              >
                <button
                  className="text-dark-500 font-semibold focus:outline-none absolute top-4 right-4"
                  onClick={toggleMobileMenu}
                >
                  <FiX size={24} />
                </button>

                <ul className="list-none p-2 text-black mt-6">
                  <li className="py-1">
                    <Link
                      to="/browsefundraiser-user"
                      className={`text-dark-500 font-semibold nav-item ${
                        isActiveRoute("/browsefundraiser-user") && "active-link"
                      }`}
                    >
                      Browse Fundraisers
                    </Link>
                  </li>
                  <li className="py-1  mt-2">
                    <Link
                      to="/donate" // This can be replaced with a valid route if necessary
                      // onClick={(e) => {
                      //   e.preventDefault(); // Prevent the default anchor behavior
                      //   // handleDonateNowClick(); // Call your click handler
                      // }}
                      className={`text-dark-500 font-semibold nav-item cursor-pointer ${
                        isActiveRoute("/donate") ? "active-link" : ""
                      }`}
                    >
                      Commit to Give
                    </Link>
                  </li>

                  {/* {!userData && (
                    <li className="py-1 ">
                      <Link
                        to="/ngoServices"
                        className={`text-dark-500 font-semibold nav-item ${
                          isActiveRoute("/ngoServices") && "active-link"
                        }`}
                      >
                        NGO Services
                      </Link>
                    </li>
                  )} */}

                  {/* <li className="py-1 mb-4">
                    <a
                      to="/#"
                      onClick={handleCreateFundraiserClick}
                      className={`text-dark-500 font-semibold nav-item ${
                        isActiveRoute("/  ") && "active-link"
                      }`}
                    >
                      Create Fundraiser
                    </a>
                  </li> */}
                  <li className="py-1 mb-4 mt-2">
                    <Link
                      to="/aboutus-user"
                      className={`text-dark-500 font-semibold nav-item ${
                        isActiveRoute("/aboutus-user") && "active-link"
                      }`}
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="py-1 mb-4">
                    <Link
                      to="/howwework-user"
                      className={`text-dark-500 font-semibold nav-item ${
                        isActiveRoute("/howwework-user") && "active-link"
                      }`}
                    >
                      How it Work
                    </Link>
                  </li>

                  <li className="py-1 mb-4">
                    <Link
                      to="/support-user"
                      className={`text-dark-500 font-semibold nav-item ${
                        isActiveRoute("/support-user") && "active-link"
                      }`}
                    >
                      Support
                    </Link>
                  </li>
                </ul>
              </motion.div>
            </AnimatePresence>
          </div>
        )}

        {/* Search Bar */}
        <div className="rounded tabViewHide  p-1 flex w-full  justify-between md:flex-grow-0 md:w-2/12">
          <input
            type="text"
            placeholder="Search Fundraisers"
            className="border outline-none py-2 ps-2 w-full md:w-[110%] rounded-3xl header-search-box focus:border-blue-800  focus:ring-blue-800"
            onChange={handleChange}
            onKeyDown={handleKeyPress}
          />
        </div>

        {/* Navigation Menus */}
        <div
          className={`text-sm 2xl:text-base flex space-x-4 md:space-x-8 md:flex  tabViewHide`}
        >
          <Link
            to="/browsefundraiser-user"
            className={`text-dark-500 font-semibold nav-item ${
              isActiveRoute("/browsefundraiser-user") && "active-link"
            }`}
          >
            Browse Fundraisers
          </Link>

          <Link
            to="/donate" // This can be replaced with a valid route if necessary
            // onClick={(e) => {
            //   e.preventDefault(); // Prevent the default anchor behavior
            //   // handleDonateNowClick(); // Call your click handler
            // }}
            className={`text-dark-500 font-semibold nav-item cursor-pointer ${
              isActiveRoute("/donate") ? "active-link" : ""
            }`}
          >
            Commit to Give
          </Link>

          <Link
            to="/aboutus-user"
            className={`text-dark-500 font-semibold nav-item ${
              isActiveRoute("/aboutus-user") && "active-link"
            }`}
          >
            About Us
          </Link>
          <Link
            to="/howwework-user"
            className={`text-dark-500 font-semibold nav-item ${
              isActiveRoute("/howwework-user") && "active-link"
            }`}
          >
            How it Works
          </Link>

          <Link
            to="/support-user"
            className={`text-dark-500 font-semibold nav-item ${
              isActiveRoute("/support-user") && "active-link"
            }`}
          >
            Support
          </Link>
        </div>

        {/* Donate Now Button */}
        {/* <a
          to="#"
          onClick={handleDonateNowClick}
          className="btn-style-1 text-white py-2 px-4 rounded tabViewHide md:block cursor-pointer"
        >
          Donate Now
        </a> */}
        <Link
          to="/browsefundraiser-user"
          className="btn-style-1 text-white py-2 px-4 rounded tabViewHide md:block cursor-pointer"
        >
          Donate Now
        </Link>
      </div>
    </div>
  );
};

export default Header;
