import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
// import { Spinner } from "@material-tailwind/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Header from "../Home/Header";
import ProgressBar from "../Common/ProgressBar";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DatePickerInput } from "@mantine/dates";
import { TQ_URL } from "../../utility/baseUrl";
import DonorManagementTop from "./DonorManagementTop";
import { Box } from "@mantine/core";

import DonorManagementSideBar from "./DonorManagementSideBar";
import { Select, TextInput, Button } from "@mantine/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TopHeader from "../Home/TopHeader";

const schema = yup.object().shape({
  donor: yup.string().required("Donor is required"),
  donationDate: yup.date().required("Donation date is required"),
  donationAmount: yup
    .number()
    .required("Donation amount is required")
    .positive("Donation amount should be positive or non-zero")
    .typeError("Donation amount should be a number"),
  fundraiserId: yup.string().required("Fundraiser is required"),
});

const EditDonation = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [fundraiserOptions, setFundraiserOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  const editRow = state;

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const token = localStorage.getItem("accessToken");

  //   const defaultDonorId = editRow.donorDetails.importedDonorId;
  //   const defaultFundraiserId = editRow.fundraiserId;
  console.log(editRow);

  const defaultValues = {
    donationDate: editRow.donationDate
      ? new Date(editRow.donationDate)
      : new Date(),

    // donor: editRow.donorDetails.importedDonorId !=null || editRow.donorDetails.importedDonorId !=0
    //   ? editRow.donorDetails.importedDonorId
    //   : " ",

    fundraiserId: editRow.fundraiserId,

    // donationAmount: editRow.donatedAmt ? editRow.donatedAmt : "",
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    // Fetch fundraiser data from API
    const fetchFundraiserData = async () => {
      try {
        const response = await axios.get(
          `${TQ_URL}/getAllPartnerFundraiser?partnerId=${partnerData.partnerId}`,
          {
            headers: {
              Authorization: token, // Pass the authorization token
              "Content-Type": "application/json", // Specify the content type
            },
          }
        );

        const { data } = response;
        const fundList = data && data.fundList ? data.fundList : [];

        setFundraiserOptions(fundList);
      } catch (error) {
        console.error("Error fetching fundraiser data:", error);
      }
    };

    const fetchUserList = async () => {
      try {
        const partnerId = 1; // Replace with the actual partner ID
        const response = await axios.get(
          `${TQ_URL}/getAllDonorForDropdown?partnerId=${partnerData.partnerId}`,
          {
            headers: {
              Authorization: token, // Pass the authorization token
              "Content-Type": "application/json", // Specify the content type
            },
          }
        );

        const { data } = response;
        const userList = data && data.userList ? data.userList : [];

        setUsers(userList);
      } catch (error) {
        console.error("Error fetching fundraiser data:", error);
      }
    };

    fetchUserList();
    fetchFundraiserData();
  }, []);

  useEffect(() => {
    console.log(editRow);
    setValue("donor", editRow.donorDetails.importedDonorId);
    setValue("fundraiserId", editRow.fundraiserId);
    setValue("donationAmount", editRow.donatedAmt);
  }, [setValue]);

  const successFormSubmit = () =>
    toast.success("Donation Updated Successfully!");

  const onUpdateSubmitHandler = async (data) => {
    console.log(data);

    setLoading(true);

    try {
      const response = await axios.post(
        `${TQ_URL}/editDonationMannualy`,
        {
          donor: data.donor,
          donationDate: data.donationDate,
          donationAmount: data.donationAmount,
          fundraiserId: data.fundraiserId,
          donationId: editRow.donationId,
        },
        {
          headers: {
            Authorization: token, // Pass the authorization token
            "Content-Type": "application/json", // Specify the content type
          },
        }
      );
      if (response.status === 200) {
        successFormSubmit();

        setTimeout(() => {
          navigate(`/allDonationList`);
        }, 1000);
      } else {
        toast.error("Error saving donation. Please try again later.");
      }
    } catch (error) {
      toast.error("Error saving donation. Please try again later.");
    }
    setLoading(false);
  };

  const selectedFundraiserId = watch("fundraiserId");
  const selectedDonor = watch("donor");

  const formatCurrency = (value) => {
    if (!value) return "";
    const options = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    };
    return new Intl.NumberFormat("en-US", options).format(value);
  };

  const handleDonationAmountChange = (e, onChange) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value === "") {
      onChange(null);
      return;
    }
    value = parseInt(value, 10);
    onChange(value);
  };

  return (
    <>
      {/* <TopHeader /> */}
      <DonorManagementTop />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Edit Donation</h2>
              </div>
            </div>
          </div>

          <div className="md:pb-20 md:flex justify-between">
            <div className="md:w-full m-4 md:m-0">
              {/* {loading ? (
                <div className="flex items-center justify-center h-[200px]">
                  <Spinner color="blue" className="animate-spin h-14 w-14 " />
                </div>
              ) : ( */}
              <form
                onSubmit={handleSubmit(onUpdateSubmitHandler)}
                className="fundraiser-for-other-form md:py-12 md:px-20 p-6 md:me-20 md:ml-[100px] md:mt-10"
              >
                {/* Donor Dropdown */}
                <div className="mb-6 md:flex">
                  <label>Donor</label>
                  <div className="w-full">
                    {/* Replace this dropdown with your actual donor selection dropdown */}
                    <select
                      {...register("donor")}
                      value={selectedDonor}
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                        errors.donor && "border-red-500"
                      }`}
                    >
                      <option value="">Select donor...</option>
                      {users.map((currentUser) => (
                        <option
                          key={currentUser.importedDonorId}
                          value={currentUser.importedDonorId}
                        >
                          {currentUser.donorName}
                        </option>
                      ))}
                    </select>
                    <p className="text-red-500 text-sm mt-1">
                      {errors.donor?.message}
                    </p>
                  </div>
                </div>

                {/* Donation Date */}
                <div className="mb-6 md:flex">
                  <label>Donation Date</label>
                  <div className="w-full">
                    <Controller
                      name="donationDate"
                      control={control}
                      render={({ field }) => (
                        <DatePickerInput
                          placeholder="Pick date"
                          //  selected={  ? moment(props.input.value, 'DD-MM-YYYY') : moment()}
                          selected={field.value}
                          {...field}
                          required
                          className={`bg-gray-50  border-gray-300 text-gray-900 text-sm focus:border-blue-500 ${
                            errors.donationDate && "border-red-500"
                          }`}
                          onChange={(value) => {
                            // Parse the string into a Date object if it's not already a Date
                            const dateValue =
                              value instanceof Date
                                ? value
                                : typeof value === "string"
                                ? new Date(value)
                                : null;

                            field.onChange(dateValue);
                          }}
                          minDate={new Date(0)} // Set minDate to the beginning of time
                          maxDate={null} // Set maxDate to null to allow all past dates
                        />
                      )}
                    />
                    {errors.donationDate && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.donationDate.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Donation Amount */}
                <div className="mb-6 relative md:flex">
                  <label>Donation Amount</label>
                  <div className="w-full relative">
                    <Controller
                      name="donationAmount"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            {...field} // Use the field object to connect with react-hook-form
                            type="text" // Change to text for currency formatting
                            placeholder="Donation Amount"
                            value={
                              field.value ? formatCurrency(field.value) : ""
                            }
                            onChange={(e) =>
                              handleDonationAmountChange(e, field.onChange)
                            }
                            onInput={(e) => {
                              if (
                                parseFloat(
                                  e.target.value.replace(/[^0-9.-]+/g, "")
                                ) < 1
                              ) {
                                e.target.value = "";
                              }
                            }}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                              errors.donationAmount && "border-red-500"
                            }`}
                          />
                          <p className="text-red-500 text-sm mt-1">
                            {errors.donationAmount?.message}
                          </p>
                        </>
                      )}
                    />
                  </div>
                </div>

                {/* Fundraiser Dropdown */}
                <div className="mb-6 md:flex">
                  <label>Fundraiser</label>
                  <div className="w-full">
                    {/* Populate dropdown with API data */}
                    <select
                      {...register("fundraiserId")}
                      value={selectedFundraiserId}
                      onChange={(e) => setValue("fundraiserId", e.target.value)}
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                        errors.fundraiserId && "border-red-500"
                      }`}
                    >
                      <option value="">Select fundraiser...</option>
                      {fundraiserOptions.map((fundraiser) => (
                        <option
                          key={fundraiser.fundId}
                          value={fundraiser.fundId}
                        >
                          {fundraiser.fundName}
                        </option>
                      ))}
                    </select>
                    <p className="text-red-500 text-sm mt-1">
                      {errors.fundraiserId?.message}
                    </p>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="mb-6 md:flex justify-center">
                  <button
                    type="submit"
                    className="w-1/3 text-white p-2 rounded-md btn-style-1 mt-4 "
                  >
                    Submit
                  </button>
                </div>
              </form>
              {/* )} */}
            </div>
          </div>
        </main>
      </div>
      <ToastContainer theme="colored" />
    </>
  );
};

export default EditDonation;
