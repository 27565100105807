import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tour from "reactour"; // Assuming you're using reactour or similar library
import axios from "axios";
import { TQ_URL } from "../../utility/baseUrl";
import OpenModal from "./OpenModal";

const stepStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
};

const SocialMediaTour = () => {
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [selectedDate, setSelectedDate] = useState(null); // State to manage selected date
  const currentTime = new Date();
  const [currentStep, setCurrentStep] = useState(
    Number(localStorage.getItem("tourStepSocial")) || 0
  );

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const partnerId = partnerData.partnerId;
  const moduleId = 2;

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

 

  const navigate = useNavigate();
  

  const steps = [
    //0
    {
      selector: "#schedule_post",
      content: (
        <div className="step-content">
          <span className="emoji-large">📅</span>
          <div>
            Using this service, you can schedule posts or reels to upload on
            social media.
          </div>
          <div>It's quick and easy!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //1
    {
      selector: "#addPost",
      content: (
        <div className="step-content">
          <span className="emoji-large">➕</span>
          <div>
            By clicking this button, you can schedule a post to send to social
            media.
          </div>
          <div>Get started now!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //2
    {
      selector: "#modal_content",
      content: (
        <div className="step-content">
          <span className="emoji-large">📝</span>
          <div>
            Fill in the form with all required information to create a post or
            reel.
          </div>
          <div>Simple and efficient!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //3
    {
      selector: "#show-hidepost",
      content: (
        <div className="step-content">
          <span className="emoji-large">👁️</span>
          <div>Use this toggle button to show or hide scheduled posts.</div>
          <div>Manage your content easily!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //4
    {
      selector: "#connect_channel",
      content: (
        <div className="step-content">
          <span className="emoji-large">🔗</span>
          <div>
            Here you can connect social media channels to share posts across
            platforms.
          </div>
          <div>Stay connected!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //5
    {
      selector: "#connect_button",
      content: (
        <div className="step-content">
          <span className="emoji-large">🚀</span>
          <div>
            Click this button to connect to a social media platform by following
            the required steps.
          </div>
          <div>Expand your reach!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //6
    {
      selector: "#manage_channel",
      content: (
        <div className="step-content">
          <span className="emoji-large">📋</span>
          <div>
            On this page, you can see the list of connected social media
            channels.
          </div>
          <div>Manage your accounts!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //7
    {
      selector: "#connect_channel_new",
      content: (
        <div className="step-content">
          <span className="emoji-large">🔗</span>
          <div>
            Click this button to go to the channel connection page and link your
            social accounts.
          </div>
          <div>Connect with ease!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
    //8
    {
      selector: "#social-report",
      content: (
        <div className="step-content">
          <span className="emoji-large">📊</span>
          <div>
            View reports and analysis of the posts shared on your social media
            platforms.
          </div>
          <div>Track your performance!</div>
        </div>
      ),
      style: { ...stepStyle },
    },
  ];

  const handleNextStep = (stepIndex) => {
    if (currentStep !== stepIndex) {
      setCurrentStep(stepIndex);
      localStorage.setItem("tourStepSocial", stepIndex);

      // Navigate to the correct page based on step index
      if (stepIndex === 0) {
        navigate("/schedulePost"); // Navigate to Page 1
      } else if (stepIndex === 2) {
        setIsModalOpen(true);
      } else if (stepIndex === 3) {
        setIsModalOpen(false);
      } else if (stepIndex === 4) {
        navigate("/socialMediaCampaign"); // Navigate to Page 2
      } else if (stepIndex === 6) {
        navigate("/connectedChannels"); // Navigate to Page 3
      } else if (stepIndex === 8) {
        navigate("/socialMediaAnalytics"); // Navigate to Page 3
      }
    }
  };

  
  const handlePrevStep = () => {
    console.log("prev step called....");

    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
      localStorage.setItem("tourStepSocial", currentStep - 1);

     
      if (currentStep == 4) {
        navigate("/schedulePost");
      } else if (currentStep == 2) {
        setIsModalOpen(false);
      } else if (currentStep == 6) {
        navigate("/socialMediaCampaign");
      } else if (currentStep == 8) {
        navigate("/connectedChannels");
      }
    }
  };

  useEffect(() => {
    // Navigate to the correct page on initial render based on current step
    handleNextStep(currentStep);
  }, [currentStep]);

  const handleFinishRedirection = async () => {
    console.log("finish clicked");
    localStorage.removeItem("tourStepSocial");

    try {
      // Call the API to mark the module as finished
      const response = await axios.put(
        `${TQ_URL}/complete/${partnerId}/${moduleId}`
      );

      if (response.status === 200) {
        console.log("Module marked as complete");

        // Redirect to the services dashboard after completion
        navigate("/services-dashboard");
      } else {
        console.error("Failed to complete the module", response.data);
      }
    } catch (error) {
      console.error("Error marking module as complete:", error);
    }
  };
  const handleCloseRequest = async () => {
    console.log("close clicked");
    localStorage.removeItem("tourStepSocial");

    try {
      // Call the API to mark the module as finished
      const response = await axios.put(
        `${TQ_URL}/complete/${partnerId}/${moduleId}`
      );

      if (response.status === 200) {
        console.log("Module marked as complete");
        setIsTourOpen(false);
      } else {
        console.error("Failed to complete the module", response.data);
      }
    } catch (error) {
      console.error("Error marking module as complete:", error);
    }
  };

  return (
    <>
      <div className="my-page-tour">
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          startAt={currentStep}
          onRequestClose={handleCloseRequest}
          nextButton={<button className="tour-button">Next ➡️</button>}
          prevButton={
            <button className="tour-button" onClick={handlePrevStep}>
              ⬅️ Back
            </button>
          }
          getCurrentStep={(stepIndex) => handleNextStep(stepIndex)}
          lastStepNextButton={
            <button className="tour-button" onClick={handleFinishRedirection}>
              Finish 🎉
            </button>
          }
          onBeforeClose={() => console.log("Tour closed")}
        />
      </div>

      {isModalOpen && (
        <OpenModal
          onClose={() => setIsModalOpen(false)} // Pass the close function as a prop
          selectedDate={selectedDate} // Pass selectedDate to the modal
          setSelectedDate={setSelectedDate} // Pass setSelectedDate to allow updates
          currentTime={currentTime} // Pass the current time to the modal
        />
      )}
    </>
  );
};

export default SocialMediaTour;
