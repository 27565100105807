import React, { useState, useRef, useEffect } from "react";
import TopHeader from "../Home/TopHeader";

import ReactPlayer from "react-player";

import SocialMediaSideBar from "./SocialMediaSideBar";
import { getRequestWithTokenWithoutDataPartner } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faThumbsUp,
  faComment,
  faShare,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

import { useLocation, useNavigate, Link } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";

import AOS from "aos";
import "aos/dist/aos.css";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import AddTag from "./AddTag";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const connectedPage = "Prabalta";

const schema = yup.object().shape({
  document: yup.mixed().required("Document is Required *"),

  description: yup.string().required("Description is Required *"),
});

const CreatePost = () => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const [documentImage, setDocumentImage] = useState(null);
  const [modalopen, setModalOpen] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedOldFiles, setUploadedOldFiles] = useState([]);
  const [tagList, setTagsList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedOption, setSelectedOption] = useState("post");

  const [videoUrl, setVideoUrl] = useState(null);
  const [selectedTagsIds, setSelectedTagsIds] = useState([]);

  const [textValue, setTextValue] = useState("");
  const [selectedChannel, setSelectedChannel] = useState({
    facebook: true,
    twitter: true,
    instagram: true,
  });

  const [selectedPlatforms, setSelectedPlatforms] = useState([
    "facebook",
    "twitter",
    "instagram",
  ]);

  const { state } = useLocation();
  const {
    register,
    // handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    AOS.init();
    getTagList();
  }, []);

  const currentTimeSlot = state?.currentTimeSlot;
  const dateValue = state?.value;

  const getTagList = async () => {
    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        `${TQ_URL}/getTagList`
      );
      console.log(response);
      setTagsList(response);
    } catch (error) {
      console.error("Error in fetching the tagList:", error);
    }
  };

  function importModal() {
    setModalOpen(true);
  }

  const handleIconClick = (platform) => {
    setSelectedChannel((prevSelected) => {
      const newSelected = {
        ...prevSelected,
        [platform]: !prevSelected[platform],
      };

      if (newSelected[platform]) {
        setSelectedPlatforms((prevPlatforms) => [...prevPlatforms, platform]);
      } else {
        setSelectedPlatforms((prevPlatforms) =>
          prevPlatforms.filter((p) => p !== platform)
        );
      }

      console.log(newSelected);
      const twitterSelected =
        newSelected.twitter === true &&
        newSelected.facebook === false &&
        newSelected.instagram === false;

      if (twitterSelected && uploadedFiles.length > 4) {
        setUploadedFiles((prevFiles) => prevFiles.slice(0, 4));
      } else {
        setUploadedFiles(uploadedOldFiles);
      }
      return newSelected;
    });
  };

  const allChannelsDeselected =
    !selectedChannel.facebook &&
    !selectedChannel.twitter &&
    !selectedChannel.instagram;

  const allChannelsSelected =
    selectedChannel.facebook &&
    selectedChannel.twitter &&
    selectedChannel.instagram;

  const onlyFaceSelected =
    selectedChannel.facebook &&
    !selectedChannel.twitter &&
    !selectedChannel.instagram;

  const onlyInstaSelected =
    !selectedChannel.facebook &&
    !selectedChannel.twitter &&
    selectedChannel.instagram;

  const faceInstaSelected =
    selectedChannel.facebook &&
    !selectedChannel.twitter &&
    selectedChannel.instagram;

  const onlyTwitterSelected =
    selectedChannel.twitter &&
    !selectedChannel.facebook &&
    !selectedChannel.instagram;

  const disableReelRadioButton =
    selectedOption == "post" &&
    (selectedChannel.facebook || selectedChannel.instagram) &&
    uploadedFiles.length > 0 &&
    !videoUrl;

  console.log(selectedOption);
  console.log(selectedChannel);
  console.log(uploadedFiles.length);

  console.log("reel Disable:", disableReelRadioButton);

  const handleTextChange = (event) => {
    const value = event.target.value;
    console.log("text:", event.target.value);
    setTextValue(event.target.value);
    if (value.trim() === "") {
      setErrorMessage("This field cannot be empty.");
    } else {
      setErrorMessage("");
    }
  };

  let allowedExtensions = [];
  let allowMultiple = true;
  let maxVideoCount = 0;
  let maxImageCount = 0;

  if (
    (selectedChannel.facebook || selectedChannel.instagram) &&
    !selectedChannel.twitter &&
    selectedOption === "post"
  ) {
    allowedExtensions = ["image/png", "image/jpeg", "image/jpg"];
    allowMultiple = true;
    maxImageCount = 10;
  } else if (
    (selectedChannel.facebook || selectedChannel.instagram) &&
    !selectedChannel.twitter &&
    selectedOption === "reel"
  ) {
    allowedExtensions = ["video/mp4", "video/mkv"];
    maxVideoCount = 1;
  } else if (
    selectedChannel.twitter &&
    !selectedChannel.facebook &&
    !selectedChannel.instagram
  ) {
    allowedExtensions = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "video/mp4",
      "video/mkv",
    ];
    maxImageCount = 4;
    maxVideoCount = 1;
  } else if (
    selectedChannel.facebook &&
    selectedChannel.twitter &&
    selectedChannel.instagram
  ) {
    allowedExtensions = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "video/mp4",
      "video/mkv",
    ];

    maxImageCount = 10;

    maxVideoCount = 1;
  } else if (
    selectedChannel.facebook &&
    selectedChannel.twitter &&
    !selectedChannel.instagram
  ) {
    allowedExtensions = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "video/mp4",
      "video/mkv",
    ];

    maxImageCount = 10;

    maxVideoCount = 1;
  } else if (
    !selectedChannel.facebook &&
    selectedChannel.twitter &&
    selectedChannel.instagram
  ) {
    allowedExtensions = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "video/mp4",
      "video/mkv",
    ];

    maxImageCount = 10;

    maxVideoCount = 1;
  }

  const handleFileChange = (event) => {
    setVideoUrl(null);

    const files = Array.from(event.target.files);
    console.log(files);

    console.log(uploadedFiles);

    const filteredFiles = files.filter((file) =>
      allowedExtensions.includes(file.type)
    );

    const existingVideo = uploadedFiles.find((file) =>
      file.type.startsWith("video/")
    );
    if (existingVideo) {
      const imageFiles = filteredFiles.filter((file) =>
        file.type.startsWith("image/")
      );

      if (imageFiles.length > 0) {
        console.log(imageFiles.length);

        setVideoUrl(null);

        setUploadedFiles((prevFiles) =>
          prevFiles.filter((file) => !file.type.startsWith("video/"))
        );

        setUploadedOldFiles((prevFiles) =>
          prevFiles.filter((file) => !file.type.startsWith("video/"))
        );

        setUploadedFiles((prevFiles) => [...prevFiles, ...imageFiles]);
        setUploadedOldFiles((prevFiles) => [...prevFiles, ...imageFiles]);
        return;
      }
    }

    const existingImages = uploadedFiles.filter((file) =>
      file.type.startsWith("image/")
    );

    const newVideo = filteredFiles.find((file) =>
      file.type.startsWith("video/")
    );

    if (existingImages.length > 0 && newVideo) {
      setUploadedFiles([]);
      setUploadedOldFiles([]);

      setUploadedFiles([newVideo]);
      setUploadedOldFiles([newVideo]);

      const videoUrl = URL.createObjectURL(newVideo);
      setVideoUrl(videoUrl);

      return;
    }

    const videoCount = filteredFiles.filter((file) =>
      file.type.startsWith("video/")
    ).length;

    const existingImageCount = uploadedFiles.filter((file) =>
      file.type.startsWith("image/")
    ).length;
    const existingVideoCount = uploadedFiles.filter((file) =>
      file.type.startsWith("video/")
    ).length;

    const newImageCount = filteredFiles.filter((file) =>
      file.type.startsWith("image/")
    ).length;
    const newVideoCount = filteredFiles.filter((file) =>
      file.type.startsWith("video/")
    ).length;

    const totalImageCount = existingImageCount + newImageCount;
    const totalVideoCount = existingVideoCount + newVideoCount;

    if (totalImageCount > 0 && totalVideoCount > 0) {
      toast.error("Please upload only images or video at a time, not both.");
      return;
    }

    if (totalImageCount > maxImageCount) {
      console.log(totalImageCount);
      if (maxImageCount - uploadedFiles.length > 0) {
        const newFiles = Array.from(filteredFiles);

        const firstFourFiles = newFiles.slice(
          0,
          maxImageCount - uploadedFiles.length
        );

        setUploadedFiles((prevTwitterFiles) => [
          ...prevTwitterFiles,
          ...firstFourFiles,
        ]);
        setUploadedOldFiles((prevTwitterFiles) => [
          ...prevTwitterFiles,
          ...firstFourFiles,
        ]);
      }

      toast.error(
        `You can only upload a maximum of ${maxImageCount} image files.`
      );
      return;
    }

    if (totalImageCount > maxImageCount && onlyTwitterSelected) {
    }

    if (totalVideoCount > maxVideoCount) {
      toast.error("You can only select one video file.");
      const url = URL.createObjectURL(files[0]);
      console.log(url);
      setVideoUrl(url);

      setUploadedFiles(files[0]);

      setUploadedOldFiles(files[0]);
      return;
    }

    console.log(filteredFiles);

    const newFiles = Array.from(filteredFiles);

    console.log("New files:", newFiles);

    const fileType = files[0].type;

    const isMP4 = fileType === "video/mp4";

    if (isMP4) {
      const url = URL.createObjectURL(files[0]);
      console.log(url);
      setVideoUrl(url);
    }

    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setUploadedOldFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeFile = (index) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      console.log("File removed:", prevFiles[index].name);
      return newFiles;
    });

    setUploadedOldFiles((prevOldFiles) => {
      const newOldFiles = [...prevOldFiles];
      newOldFiles.splice(index, 1); // Remove the same file by index
      return newOldFiles;
    });
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      setValue("document", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setDocumentImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (textValue.trim() === "") {
      setErrorMessage("This field cannot be empty.");
    } else setErrorMessage("");

    console.log("Submit Clicked");
    if (uploadedFiles.length == 0) {
      toast.error("Please select document to upload..");
      return;
    }

    if (selectedPlatforms.length == 0) {
      toast.error("Please select At least one channel..");
      return;
    }

    let formDataValue = {
      selectedchannels: selectedPlatforms,
      tags: selectedTagsIds,
      content: textValue,
      files: uploadedFiles,
      timeSlot: currentTimeSlot.start,
      scheduleDate: dateValue,
    };

    console.log(formDataValue);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log(event.target.value);

    const uniqueValues = [...new Set(value)];

    setSelectedValues(uniqueValues);

    const selectedTagsIds = uniqueValues.map((item) => item.tagId);
    setSelectedTagsIds(selectedTagsIds);
    setTagsList(tagList);
  };

  const getTruncatedTags = (selected, maxLength) => {
    // Map selected values to tag names and join them with ", "
    const selectedTags = selected.map((tag) => tag.tagName).join(", ");
    // If the length exceeds maxLength, truncate and append "..."
    if (selectedTags.length > maxLength) {
      return selectedTags.slice(0, maxLength).trim() + "...";
    }
    // Otherwise, return the full string
    return selectedTags;
  };

  const groupImages = (images) => {
    const groupedImages = [];
    let tempGroup = [];

    const totalImages = images.length;

    if (totalImages >= 5) {
      tempGroup = images.slice(0, 2);
      groupedImages.push(tempGroup);
      tempGroup = images.slice(2, 5);
      groupedImages.push(tempGroup);

      return groupedImages;
    }

    images.forEach((image, index) => {
      tempGroup.push(image);

      if (
        (index % 5 === 0 && tempGroup.length === 1) ||
        (index % 5 === 1 && tempGroup.length === 2) ||
        (index % 5 === 3 && tempGroup.length === 2)
      ) {
        groupedImages.push(tempGroup);
        tempGroup = [];
      } else if (tempGroup.length === 3) {
        groupedImages.push(tempGroup);
        tempGroup = [];
      }
    });

    if (tempGroup.length) {
      groupedImages.push(tempGroup);
    }

    return groupedImages;
  };

  const groupTwitterImages = (images) => {
    const groupedImages = [];
    const totalImages = images.length;

    if (totalImages === 3) {
      groupedImages.push([images[0]]);

      groupedImages.push([images[1], images[2]]);
    } else {
      const columnCount = Math.ceil(totalImages / 2);
      for (let i = 0; i < columnCount; i++) {
        const columnImages = [];

        if (i < totalImages) {
          columnImages.push(images[i]);
          if (i + columnCount < totalImages) {
            columnImages.push(images[i + columnCount]);
          }
        }
        groupedImages.push(columnImages);
      }
    }
    return groupedImages;
  };

  const renderImageRows = (groupedImages) => {
    console.log(groupedImages);

    const totalImageCount = uploadedFiles.length;

    return groupedImages.map((group, index) => {
      const groupSize = group.length;
      const divWidth = 100;
      const imageWidthPercentage = divWidth / groupSize;

      return (
        <div
          key={index}
          className={`flex w-full h-[150px] space-x-2 my-2 relative`}
        >
          {group.map((file, i) => {
            const isLastImage =
              index === groupedImages.length - 1 && i === group.length - 1;

            const shouldBlurLastImage = totalImageCount > 5 && isLastImage;

            const isFifthImage =
              index === groupedImages.length - 1 && i === group.length - 1;

            const shouldBlurFifthImage = totalImageCount >= 6 && isFifthImage;
            const extraImageCount = totalImageCount - 5;

            console.log(isFifthImage);

            console.log(shouldBlurFifthImage);

            return (
              <div key={i} className="flex-1 mx-1">
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  style={{
                    width: "100%",
                    height: "100%",

                    filter: shouldBlurFifthImage ? "blur(2px)" : "none",
                  }}
                  className="object-cover"
                />

                {shouldBlurFifthImage && (
                  <div className="counter-text">+{extraImageCount}</div>
                )}
              </div>
            );
          })}
        </div>
      );
    });
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <DonorManagementTop />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <SocialMediaSideBar />
        </div>

        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Create Post</h2>
              </div>
            </div>
          </div>

          <div className="m-0 md:flex ">
            <div
              className="md:w-3/4 border border-gray-300 md:mx-4 p-5 flex flex-col justify-center"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="m-0 md:flex ">
                <div className="md:w-3/4 space-x-6 items-center me-10 md:flex">
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div
                      onClick={() => handleIconClick("facebook")}
                      style={{
                        cursor: "pointer",

                        borderRadius: "50%",
                        border: selectedChannel.facebook
                          ? "2px solid blue"
                          : "none",
                        padding: "10px",
                        color: "#1da1f2",
                        backgroundColor: selectedChannel.facebook
                          ? "#F9F6EE"
                          : "transparent",
                      }}
                    >
                      <FontAwesomeIcon icon={faFacebook} size="2x" />
                    </div>
                    <div
                      onClick={() => handleIconClick("twitter")}
                      style={{
                        cursor: "pointer",

                        borderRadius: "50%",
                        border: selectedChannel.twitter
                          ? "2px solid black"
                          : "none",
                        padding: "10px",
                        color: "#1da1f2",
                        backgroundColor: selectedChannel.twitter
                          ? "#F9F6EE"
                          : "transparent",
                      }}
                    >
                      <FontAwesomeIcon icon={faTwitter} size="2x" />
                    </div>
                    <div
                      onClick={() => handleIconClick("instagram")}
                      style={{
                        cursor: "pointer",

                        borderRadius: "50%",
                        border: selectedChannel.instagram
                          ? "2px solid #C13584"
                          : "none",
                        padding: "10px",
                        color: "#C13584",
                        backgroundColor: selectedChannel.instagram
                          ? "#F9F6EE"
                          : "transparent",
                      }}
                    >
                      <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </div>
                  </div>
                </div>

                <div className="md:w-1/2 space-x-6 items-center me-10 md:flex">
                  {tagList.length > 0 ? (
                    <FormControl className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block md:mt-7 w-full p-2.5">
                      <InputLabel
                        style={{ fontWeight: "bold" }}
                        className="text-black-900 font-semibold text-sm"
                        id="demo-multiple-checkbox-label"
                      >
                        Select tags
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedValues}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            label="Select Tags"
                          />
                        }
                        // renderValue={(selected) =>
                        //   selectedValues.map((x) => x.tagName).join(", ")
                        // }
                        renderValue={(selected) =>
                          getTruncatedTags(selected, 25)
                        }
                        MenuProps={MenuProps}
                      >
                        {tagList.map((variant) => (
                          <MenuItem
                            key={variant.tagId}
                            value={variant}
                            style={{
                              padding: "5px",
                              fontSize: "10px",
                              margin: "5px",
                            }}
                          >
                            <Checkbox
                              checked={selectedValues.includes(variant)}
                              style={{
                                fontSize: "10px",
                                margin: "5px",
                              }}
                            />
                            <ListItemText primary={variant.tagName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <div className="w-full flex items-center justify-between">
                      <span>No users available.</span>

                      <button className="btn-style-1 text-white py-1 px-1 md:py-1  md:px-2 rounded  md:block mx-2">
                        Add Tags
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <hr className="border-t border-gray-400 mt-4" />
              <form
                onSubmit={handleSubmit}
                className="fundraiser-for-other-form md:py-12 md:px-20 p-6 md:mx-8"
              >
                {(faceInstaSelected ||
                  onlyFaceSelected ||
                  onlyInstaSelected) && (
                  <div className="p-4">
                    <div className="mb-4">
                      <div className="flex items-center space-x-8">
                        {" "}
                        <div className="flex items-center">
                          <input
                            type="radio"
                            id="post"
                            name="radioOptions"
                            value="post"
                            checked={selectedOption === "post"}
                            onChange={handleRadioChange}
                            className="mr-2"
                          />
                          <label htmlFor="post" className="text-gray-700">
                            Post
                          </label>
                        </div>
                        {/* Radio button 2 */}
                        <div className="flex items-center">
                          <input
                            type="radio"
                            id="reel"
                            name="radioOptions"
                            value="reel"
                            checked={selectedOption === "reel"}
                            onChange={handleRadioChange}
                            disabled={disableReelRadioButton}
                            className="mr-2"
                          />
                          <label htmlFor="reel" className="text-gray-700">
                            Reel
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <hr className="border-t border-gray-400 mt-4" />

                <div className="w-full md:mt-4">
                  <textarea
                    rows={6}
                    value={textValue}
                    onChange={handleTextChange}
                    style={{ resize: "none" }}
                    placeholder="Start writing here..."
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errorMessage && "border-red-500"
                    }`}
                  />
                  {errorMessage && (
                    <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
                  )}
                </div>

                <div className="md:flex">
                  <div
                    className="flex justify-between w-full items-center  my-5 md:my-0"
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <div
                      className="mb-6 relative flex flex-col items-center w-full"
                      style={{
                        borderColor: isDragOver ? "blue" : "gray",
                        boxShadow: isDragOver
                          ? "0 0 10px rgba(0, 0, 255, 0.5)"
                          : "none",
                        borderRadius: "8px",

                        borderStyle: "dashed",
                        borderWidth: "2px",
                        paddingInline: "20px",
                        paddingBlock: "10px",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                    >
                      {uploadedFiles.map((file, index) => (
                        <div key={index} className="flex items-center mb-2">
                          <p className="mr-2">{file.name}</p>
                          <button
                            type="button"
                            className="text-red-500 hover:text-red-700 cursor-pointer"
                            onClick={() => removeFile(index)}
                          >
                            &#x2715;
                          </button>
                        </div>
                      ))}

                      <label htmlFor="documentInput" className="cursor-pointer">
                        <img
                          src="assets/images/cloud-icon.svg"
                          className="mx-auto"
                          alt="Cloud Icon"
                        />
                        <p className="text-xs text-center">
                          <span className="font-bold underline">
                            Click to upload
                          </span>{" "}
                          or drag and drop
                          <br />
                          {allowedExtensions
                            .map((ext) =>
                              ext.replace("image/", "").toUpperCase()
                            )
                            .join(", ")}
                          {maxVideoCount > 0 && ", one video"}
                        </p>
                      </label>

                      <input
                        type="file"
                        accept={allowedExtensions.join(",")}
                        multiple={allowMultiple}
                        onChange={handleFileChange}
                        id="documentInput"
                        className="hidden"
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-6 md:flex justify-center">
                  <button
                    type="submit"
                    className="w-1/3 text-white p-2 rounded-md btn-style-1 mt-4 "
                  >
                    Share now.
                  </button>
                </div>
              </form>
            </div>

            {/* *************************************************** */}

            <div
              className="md:w-1/2  md:mx-4 md:mt-8 md:mx-8 "
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {(faceInstaSelected || onlyFaceSelected || onlyInstaSelected) && (
                <div
                  className="previewDiv"
                  // style={{
                  //   border: "2px solid #D3D3D3",
                  //   backgroundColor: "white",
                  //   padding: "6px 7px",
                  //   borderRadius: "4px",
                  //   height: "auto",
                  //   width: "400px",
                  //   boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2)",
                  // }}
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  {faceInstaSelected && (
                    <h2 className="text-lg font-bold">
                      Facebook and Insta Preview
                    </h2>
                  )}

                  {onlyFaceSelected && (
                    <h2 className="text-lg font-bold">Facebook Preview</h2>
                  )}

                  {onlyInstaSelected && (
                    <h2 className="text-lg font-bold">Instagram Preview</h2>
                  )}

                  <div
                    className={`md:mx-2 ${
                      textValue || uploadedFiles.length ? "hidden" : ""
                    }`}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="assets/images/post-preview.png"
                      alt="Placeholder Avatar"
                      className="rounded-full h-[400px] w-[350px]"
                    />

                    <p
                      style={{
                        color: "lightgray",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "20px",
                        marginBottom: "20px",
                        paddingLeft: "50px",
                      }}
                    >
                      {" "}
                      See Your post's preview here
                    </p>
                  </div>

                  <div
                    className={`md:mx-2 p-4 border border-gray-300 ${
                      textValue || uploadedFiles.length ? "" : "hidden"
                    }`}
                  >
                    {selectedOption == "post" && !videoUrl && (
                      <div className="md:mb-8">
                        <div className="flex md:mt-4">
                          <img
                            src={
                              "https://social.prabalta.org/assets/images/user.webp"
                            }
                            alt="Placeholder Avatar"
                            className="rounded-full h-8 w-8"
                          />
                          <div className="ml-2 md:mb-4">
                            <span className="font-semibold text-gray-800">
                              {connectedPage}
                            </span>
                            <span className="text-gray-500 text-sm block">
                              just now
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="w-auto bg-gray  text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block break-words">
                            {" "}
                            {textValue}
                          </div>
                          <div className="w-full">
                            <div>
                              {renderImageRows(groupImages(uploadedFiles))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {selectedOption == "post" && videoUrl && (
                      <div className="md:mb-8">
                        <div className="flex md:mt-4">
                          <img
                            src={
                              "https://social.prabalta.org/assets/images/user.webp"
                            }
                            alt="Placeholder Avatar"
                            className="rounded-full h-8 w-8"
                          />
                          <div className="ml-2 md:mb-4">
                            <span className="font-semibold text-gray-800">
                              {connectedPage}
                            </span>
                            <span className="text-gray-500 text-sm block">
                              just now
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="w-auto bg-gray  text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block break-words">
                            {" "}
                            {textValue}
                          </div>
                          <div className="w-full">
                            <div className="w-full h-[500px]">
                              <div className="video-reel-container">
                                <div className="reel-view">
                                  <div className="video-container">
                                    {videoUrl && (
                                      <ReactPlayer
                                        url={videoUrl}
                                        playing={true}
                                        loop={true}
                                        controls={false}
                                        width="340px"
                                        height="600px"
                                        muted={true}
                                        style={{ objectFit: "cover" }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {selectedOption == "reel" && (
                      <div className="md:mb-8">
                        <div className="w-full h-[500px]">
                          <div className="video-reel-container">
                            <div className="reel-view">
                              <div className="video-container">
                                {videoUrl && (
                                  <ReactPlayer
                                    url={videoUrl}
                                    playing={true}
                                    loop={true}
                                    controls={false}
                                    width="340px"
                                    height="600px"
                                    muted={true}
                                    style={{ objectFit: "cover" }}
                                  />
                                )}

                                <div className="reel-icons">
                                  <button className="icon-button">
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                  </button>
                                  <button className="icon-button">
                                    <FontAwesomeIcon icon={faComment} />
                                  </button>
                                  <button className="icon-button">
                                    <FontAwesomeIcon icon={faShare} />
                                  </button>
                                  <button className="icon-button">
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                  </button>
                                </div>
                                <div className="channel-name-overlay">
                                  <div className="channel-info">
                                    <img
                                      src={
                                        "https://social.prabalta.org/assets/images/user.webp"
                                      }
                                      alt="Placeholder Avatar"
                                      className="rounded-full h-8 w-8"
                                    />

                                    <span className="font-semibold text-gray-800">
                                      {connectedPage}
                                    </span>
                                  </div>

                                  <div className="w-auto md:ml-2 text-sm focus:ring-blue-500 focus:border-blue-500 block break-words">
                                    {" "}
                                    {textValue.length > 30
                                      ? textValue.slice(0, 30) + "...more"
                                      : textValue}
                                  </div>
                                  {/* <div className="text-value">{textValue}</div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {onlyTwitterSelected && (
                <div
                  className="previewDiv md:mb-8"
                  // style={{
                  //   border: "2px solid #D3D3D3",
                  //   backgroundColor: "white",
                  //   padding: "6px 7px",
                  //   borderRadius: "4px",
                  //   height: "auto",
                  //   width: "400px",
                  //   boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2)",
                  // }}
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <h2 className="text-lg font-bold">Twitter Preview</h2>

                  <div
                    className={`md:mx-2 ${
                      textValue || uploadedFiles.length ? "hidden" : ""
                    }`}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="assets/images/post-preview.png"
                      alt="Placeholder Avatar"
                      className="rounded-full h-[400px] w-[350px]"
                    />

                    <p
                      style={{
                        color: "lightgray",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "20px",
                        paddingLeft: "50px",
                      }}
                    >
                      {" "}
                      See Your post's preview here
                    </p>
                  </div>

                  <div
                    className={`md:mx-2 p-4 border border-gray-300 ${
                      textValue || uploadedFiles.length ? "" : "hidden"
                    }`}
                  >
                    <div className="flex md:mt-4">
                      <img
                        src={
                          "https://social.prabalta.org/assets/images/user.webp"
                        }
                        alt="Placeholder Avatar"
                        className="rounded-full h-8 w-8"
                      />
                      <div className="ml-2 md:mb-4">
                        <span className="font-semibold text-gray-800">
                          {connectedPage}
                        </span>
                      </div>
                    </div>

                    <div>
                      <div className="w-auto bg-gray  text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block break-words">
                        {" "}
                        {textValue}
                      </div>
                      <div className="w-full">
                        {!videoUrl ? (
                          <div>
                            {renderImageRows(groupTwitterImages(uploadedFiles))}
                          </div>
                        ) : (
                          <div className="w-full h-[400px]">
                            <div className="video-reel-container">
                              {videoUrl && (
                                <ReactPlayer
                                  url={videoUrl}
                                  playing={true}
                                  loop={true}
                                  controls={false}
                                  width="400px"
                                  height="400px"
                                  muted={true}
                                  style={{ objectFit: "cover" }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ********************Channel Not selected ********************************* */}

              {allChannelsDeselected && (
                <div
                  className="md:mb-8"
                  style={{
                    border: "2px solid #D3D3D3",
                    backgroundColor: "#fff",
                    padding: "6px 7px",
                    borderRadius: "4px",
                    height: "600px",
                    width: "400px",
                    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <img
                    src="assets/images/post-preview.png"
                    alt="Placeholder Avatar"
                    className="rounded-full h-[400px] w-[350px]"
                  />

                  <div
                    style={{
                      color: "red",
                      fontSize: "20px",
                      paddingLeft: "40px",
                    }}
                  >
                    Please select at least one channel.
                  </div>
                </div>
              )}

              {/* ********************All Channels selected ********************************* */}

              {allChannelsSelected && (
                <div
                  className="md:mb-8"
                  style={{
                    border: "2px solid #D3D3D3",
                    backgroundColor: "#d3d3d366",
                    padding: "6px 7px",
                    borderRadius: "4px",
                    height: "600px",
                    width: "400px",
                    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2)",
                    display: "flex", // Use flexbox
                    justifyContent: "center", // Center the content horizontally
                    alignItems: "center",
                  }}
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <div style={{ color: "green", fontSize: "20px" }}>
                    Images/Video wiil be shared on all selected platform
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
      <ToastContainer theme="colored" />

      {modalopen && <AddTag onClose={() => setModalOpen(false)} />}
    </>
  );
};

export default CreatePost;