import React, { useState, useEffect } from "react";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tour from "reactour";
import axios from "axios";
import { TQ_URL } from "../../utility/baseUrl";
import { motion } from "framer-motion";

const stepStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
};
const AnalyticsDashboard = () => {
  const [isTourOpen, setIsTourOpen] = useState(true);
  const navigate = useNavigate();

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const partnerId = partnerData.partnerId;
  const moduleId = 5;

  const [tourCompletedFlag, setTourCompletedFlag] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(true);

  useEffect(() => {
    const fetchTourStatus = async () => {
      try {
        const response = await axios.get(
          `${TQ_URL}/user-tour-status/${partnerId}/${moduleId}`
        );
        console.log(response.data.tourCompleted);
        setTourCompletedFlag(response.data.tourCompleted); // Assuming your API returns the status in 'tourCompleted'
        localStorage.setItem(
          "donorTourCompletedFlag",
          response.data.tourCompleted
        );
      } catch (error) {
        console.error("Error fetching tour status:", error);
      } finally {
        setIsTableLoading(false); // Set loading to false after fetching the flag
      }
    };
    fetchTourStatus();
  }, []);

  useEffect(() => {
    console.log("Tour status:", isTourOpen);
  }, [isTourOpen]);

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const steps = [
    {
      selector: "#fundraiser-performance",

      content: (
        <div className="step-content">
          <span className="emoji-large">🎉</span>
          <div>
            This section gives you insights into the performance of various
            fundraisers.
          </div>
          <div>It's quick and easy!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#causewise-donation",
      content: (
        <div className="step-content">
          <span className="emoji-large">🎉</span>
          <div>
            Here, you can view donation statistics categorized by causes.
          </div>
          <div>It's quick and easy!</div>
        </div>
      ),
    },
    {
      selector: "#recurring-causewise-donation",
      content: (
        <div className="step-content">
          <span className="emoji-large">🎉</span>
          <div>
            This area provides data on recurring donations grouped by causes.
          </div>
          <div>It's quick and easy!</div>
        </div>
      ),
    },
    {
      selector: "#new-users-signup",
      content: (
        <div className="step-content">
          <span className="emoji-large">🎉</span>
          <div>Analytics of new users signing up can be viewed here.</div>
          <div>It's quick and easy!</div>
        </div>
      ),
    },
    {
      selector: "#new-donors-analytics",

      content: (
        <div className="step-content">
          <span className="emoji-large">🎉</span>
          <div>Here, you can track analytics for new donors.</div>
          <div>It's quick and easy!</div>
        </div>
      ),
    },
    {
      selector: "#monthly-recurring-donor",

      content: (
        <div className="step-content">
          <span className="emoji-large">🎉</span>
          <div>
            Monthly data on new recurring donors is shown in this section.
          </div>
          <div>It's quick and easy!</div>
        </div>
      ),
    },
    {
      selector: "#donor-retention",

      content: (
        <div className="step-content">
          <span className="emoji-large">🎉</span>
          <div>This section provides analytics on donor retention.</div>
          <div>It's quick and easy!</div>
        </div>
      ),
    },
    {
      selector: "#donation-page-conversion",
      // content: "",
      content: (
        <div className="step-content">
          <span className="emoji-large">🎉</span>
          <div>
            Here, you can see reports on donation page conversion rates..
          </div>
          <div>It's quick and easy!</div>
        </div>
      ),
    },
  ];

  const handleFinishRedirection = async () => {
    console.log("finish clicked");

    try {
      // Call the API to mark the module as finished
      const response = await axios.put(
        `${TQ_URL}/complete/${partnerId}/${moduleId}`
      );

      if (response.status === 200) {
        console.log("Module marked as complete");

        // Redirect to the services dashboard after completion
        navigate("/services-dashboard");
      } else {
        console.error("Failed to complete the module", response.data);
      }
    } catch (error) {
      console.error("Error marking module as complete:", error);
    }
  };
  const handleCloseRequest = async () => {
    console.log("close clicked");

    try {
      // Call the API to mark the module as finished
      const response = await axios.put(
        `${TQ_URL}/complete/${partnerId}/${moduleId}`
      );

      if (response.status === 200) {
        console.log("Module marked as complete");
        setIsTourOpen(false);
      } else {
        console.error("Failed to complete the module", response.data);
      }
    } catch (error) {
      console.error("Error marking module as complete:", error);
    }
  };

  if (isTableLoading) {
    return null; // Don't render anything while loading
  }

  return (
    <>
      <DonorManagementTop />
      <main className="flex h-full my-10 justify-center">
        {/* <div className="absolute top-12 left-2 m-2">
          <Link to="/service-dashboard">
            <button
              // onClick={() => window.history.back()}
              className="text-gray-900 hover:text-[#ff7b00] p-1"
            >
              <svg
                enableBackground="new 0 0 256 256"
                height="35px"
                viewBox="0 0 256 256"
                width="35px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <path d="M203.313,128c0,3.534-2.865,6-6.4,6H74.878l47.141,45.956c2.538,2.463,2.597,6.616,0.138,9.149  c-1.256,1.295-2.925,1.995-4.594,1.995c-1.606,0-3.216-0.576-4.456-1.782l-58.466-56.737c-1.244-1.206-1.943-2.855-1.943-4.587  s0.699-3.385,1.943-4.591l58.466-56.743c2.528-2.459,6.584-2.401,9.05,0.139c2.459,2.534,2.4,6.789-0.138,9.251L74.878,122h122.034  C200.447,122,203.313,124.466,203.313,128z M256,128C256,57.422,198.578,0,128,0S0,57.422,0,128s57.422,128,128,128  S256,198.578,256,128z M243.2,128c0,63.521-51.679,115.2-115.2,115.2S12.8,191.521,12.8,128S64.479,12.8,128,12.8  S243.2,64.479,243.2,128z" />
              </svg>
            </button>
          </Link>
        </div> */}

        <Link to="/services-dashboard">
          <div className="absolute top-12 left-2 m-2">
            <button className="btn-style-1 text-white hover:bg-blue-600  rounded-full">
              <svg
                enableBackground="new 0 0 256 256"
                height="40px"
                viewBox="0 0 256 256"
                width="40px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <path
                  fill="white"
                  d="M203.313,128c0,3.534-2.865,6-6.4,6H74.878l47.141,45.956c2.538,2.463,2.597,6.616,0.138,9.149  c-1.256,1.295-2.925,1.995-4.594,1.995c-1.606,0-3.216-0.576-4.456-1.782l-58.466-56.737c-1.244-1.206-1.943-2.855-1.943-4.587  s0.699-3.385,1.943-4.591l58.466-56.743c2.528-2.459,6.584-2.401,9.05,0.139c2.459,2.534,2.4,6.789-0.138,9.251L74.878,122h122.034  C200.447,122,203.313,124.466,203.313,128z M256,128C256,57.422,198.578,0,128,0S0,57.422,0,128s57.422,128,128,128  S256,198.578,256,128z M243.2,128c0,63.521-51.679,115.2-115.2,115.2S12.8,191.521,12.8,128S64.479,12.8,128,12.8  S243.2,64.479,243.2,128z"
                />
              </svg>
            </button>
          </div>
        </Link>

        <div className="container px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ">
          {/* Box 1 */}
          <Link
            id="fundraiser-performance"
            to="/fundraiserPerformance"
            className="max-w-xs  webkit-centerForAnalytics border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] "
          >
            <img
              className="  h-32 md:h-44"
              src="assets/images/fperformance.jpg"
              alt=""
            />
            <div className="p-3 pt-5">
              <h4 className="mb-2 lg:lg:text-xl sm:text-lg  text-gray-900  font-semibold text-center ">
                Fundraiser Performance
              </h4>
            </div>
          </Link>
          <a
            id="causewise-donation"
            href="/causewiseDonation"
            className="max-w-xs  webkit-centerForAnalytics border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] "
          >
            <img
              className="rounded-t-lg  h-32 md:h-40"
              src="assets/images/cdonation.jpg"
              alt=""
            />
            <div className="p-3 pt-5">
              <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900  font-semibold text-center">
                Causewise Donation
              </h4>
            </div>
          </a>
          <a
            id="recurring-causewise-donation"
            href="/recurringCausewiseDonation"
            className="max-w-xs  webkit-centerForAnalytics border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] "
          >
            <img
              className="rounded-t-lg  h-32 md:h-40"
              src="assets/images/rdonate.jpg"
              alt=""
            />
            <div className="p-3 pt-5">
              <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900  font-semibold text-center">
                Recurring Causewise Donation
              </h4>
            </div>
          </a>
          <a
            id="new-users-signup"
            href="/newSignUpAnalytics"
            className="max-w-xs  webkit-centerForAnalytics border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] "
          >
            <img
              className="rounded-t-lg  h-32 md:h-40"
              src="assets/images/newuser.jpg"
              alt=""
            />
            <div className="p-3 pt-5">
              <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900  font-semibold ">
                {" "}
                New Users Sign Up Analytics
              </h4>
            </div>
          </a>

          <a
            id="new-donors-analytics"
            href="/newDonorAnalytics"
            className="max-w-xs  webkit-centerForAnalytics  mt-4 border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] "
          >
            <img
              className="rounded-t-lg  h-32 md:h-40"
              src="assets/images/mdonation.jpg"
              alt=""
            />
            <div className="p-3 pt-5">
              <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900  font-semibold text-center">
                New Donors Analytics
              </h4>
            </div>
          </a>
          <a
            id="monthly-recurring-donor"
            href="/newrecurringDonorAnalytics"
            className="max-w-xs  webkit-centerForAnalytics mt-4 border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] "
          >
            <img
              className="rounded-t-lg  h-32 md:h-40"
              src="assets/images/hww1_copy1.png"
              alt=""
            />
            <div className="p-3 pt-5">
              <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900  font-semibold text-center">
                Monthly New Recurring Donor
              </h4>
            </div>
          </a>
          <a
            id="donor-retention"
            href="/returningDonorAnalytics"
            className="max-w-xs  webkit-centerForAnalytics  mt-4 border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] "
          >
            <img
              className="rounded-t-lg  h-32 md:h-40"
              src="assets/images/retenation.jpg"
              alt=""
            />
            <div className="p-3 pt-5">
              <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900  font-semibold text-center">
                Donor Retantion
              </h4>
            </div>
          </a>
          {/* <a
            href="#"
            className="max-w-xs  webkit-centerForAnalytics mt-4 border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] "
          >
            <img
              className="rounded-t-lg  h-32 md:h-40"
              src="assets/images/hww1_copy1.png"
              alt=""
            />
            <div className="p-3 pt-5">
              <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900  font-semibold ">
                Amount Raised per Email Sent Reports
              </h4>
            </div>
          </a> */}
          <a
            id="donation-page-conversion"
            href="/donationConversionReport"
            className="max-w-xs  webkit-centerForAnalytics  mt-4 border-b-4 border-transparent transition ease-in-out delay-150 shadow-lg hover:border-[#ff7b00] "
          >
            <img
              className="rounded-t-lg  h-32 md:h-40"
              src="assets/images/dreport.jpg"
              alt=""
            />
            <div className="p-3 pt-5">
              <h4 className="mb-2 lg:text-xl sm:text-lg  text-gray-900  font-semibold ">
                Donation Page Conversion Reports
              </h4>
            </div>
          </a>
        </div>

        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.9 }}
        >
          {!tourCompletedFlag && isLargeScreen && (
            <Tour
              steps={steps}
              isOpen={isTourOpen}
              scrollDuration={900}
              onRequestClose={handleCloseRequest}
              disableDotsNavigation={true}
              nextButton={<button className="tour-button">Next ➡️</button>}
              prevButton={<button className="tour-button">⬅️ Back</button>}
              lastStepNextButton={
                <button
                  className="tour-button"
                  onClick={handleFinishRedirection}
                >
                  Finish 🎉
                </button>
              }
            />
          )}
        </motion.div>
      </main>
    </>
  );
};

export default AnalyticsDashboard;
