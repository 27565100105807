import React, { useEffect, useState } from "react";
import AnalyticsSidebar from "./AnalyticsSidebar";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  PieChart,
  Pie,
  Tooltip as RechartsTooltip,
  Cell,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Tooltip as RxTooltip, Box } from "@mantine/core";
import { getRequestWithOutToken } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import {
  Chart,
  Tooltip as DxTooltip,
  SeriesTemplate,
  CommonSeriesSettings,
  Title,
} from "devextreme-react/chart";

function CausewiseDonation() {
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [statusFilter, setStatusFilter] = useState(1);
  const [sorting, setSorting] = useState([]);
  const [donorList, setDonorLIst] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [causeValueMain, setCauseValueMain] = useState(0);
  const [causeList, setCauseList] = useState([]);
  const [causeName, setCauseName] = useState("Select cause");
  const [causeValue, setCauseValue] = useState(1);
  const [causeId, setCauseId] = useState(0);
  const [pieData, setPieData] = useState("");
  const [value, setValue] = useState("monthly");
  const [monthDropdown, setMonthDropdown] = useState(false);
  const [yearDropdown, setYearDropdown] = useState(true);
  const [monthValue, setMonthValue] = useState("");
  const [yearValue, setYearValue] = useState(new Date().getFullYear());
  const [graphData, setGraphData] = useState(0);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const yearList = [2020, 2021, 2022, 2023, 2024];

  const monthList = [
    {
      id: "01",
      name: "January",
    },
    {
      id: "02",
      name: "February",
    },
    {
      id: "03",
      name: "March",
    },
    {
      id: "04",
      name: "April",
    },
    {
      id: "05",
      name: "May",
    },
    {
      id: "06",
      name: "June",
    },
    {
      id: "07",
      name: "July",
    },
    {
      id: "08",
      name: "August",
    },
    {
      id: "09",
      name: "September",
    },
    {
      id: "10",
      name: "October",
    },
    {
      id: "11",
      name: "November",
    },
    {
      id: "12",
      name: "December",
    },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleStatusFilterChange = (cause) => {
    setCauseName(cause.causeName);
    setIsDropdownOpen(false);
    setCauseId(cause.causeId);
  };

  useEffect(() => {
    handleAnalyticsDataFetching(value, monthValue, yearValue);
  }, [causeValue, value, monthValue, yearValue]);

  function handleAnalyticsDataFetching(typeValue, monthValue, yearValue) {
    if (typeValue === "yearly") {
      getCauseDonationAnalysisBarGraph("yearly", monthValue, yearValue);
    } else if (typeValue === "weekly" && monthValue && yearValue) {
      getCauseDonationAnalysisBarGraph("weekly", monthValue, yearValue);
    } else if (typeValue === "monthly" && yearValue) {
      getCauseDonationAnalysisBarGraph("monthly", monthValue, yearValue);
    } else {
      console.error("Invalid parameters provided.");
    }
  }

  const getCauseDonationAnalysisBarGraph = async () => {
    const url = new URL(`${TQ_URL}/getCauseDonationAnalysisBarGraph`);
    url.search = new URLSearchParams();
    url.searchParams.set("typeValue", value);
    if (monthValue != "" && !isNaN(monthValue)) {
      url.searchParams.set("monthValue", parseInt(monthValue));
    }
    if (yearValue != "" && !isNaN(yearValue)) {
      url.searchParams.set("yearValue", parseInt(yearValue));
    }
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);
    url.searchParams.set("causeValue", causeValue);

    url.searchParams.set("yearList", JSON.stringify(yearList ?? []));

    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log(response);
      setGraphData(response.list);
    } catch (error) {
      console.error(
        "Error in fetching the details for analytics  data:",
        error
      );
      setIsError(true);
      return;
    }
  };
  const getTypeValue = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value>>", selectedValue);

    setValue(selectedValue);

    if (selectedValue === "weekly") {
      setMonthDropdown(true);
      if (yearDropdown) setYearDropdown(false);
    } else if (selectedValue === "monthly") {
      setYearDropdown(true);

      if (monthDropdown) setMonthDropdown(false);
    } else {
      setMonthDropdown(false);
      setYearDropdown(false);
    }
  };

  const getMonthValue = (event) => {
    const selectedMonthValue = event.target.value;
    console.log("Selected month value>>", selectedMonthValue);
    setMonthValue(selectedMonthValue);
  };

  const getYearValue = (event) => {
    const selectedYearValue = event.target.value;
    console.log("Selected year value>>", selectedYearValue);
    setYearValue(selectedYearValue);
    console.log(monthValue);
  };

  const formatCurrency = (value) => {
    if (!value) return "";
    const amount = parseFloat(value);
    if (isNaN(amount)) return "";
    return `$${amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };


  const getCauseTypeValue = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value>>", selectedValue);

    setCauseValue(selectedValue);
  };

  const getCauseTypeValueForMain = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value>>", selectedValue);
    //  setCauseName(cause.causeName);
    setCauseValueMain(selectedValue);

    // setCauseId(cause.causeId);
  };

  const columns = [
    {
      accessorKey: "id",
      header: "Sr no",
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: "donorName",
      header: "Donor Name",
      sortable: true,
      Cell: ({ row }) => {
        const title = row.original.donorName;
        return (
          <RxTooltip
            label={title || ""}
            styles={(theme) => ({
              tooltip: {
                backgroundColor: theme.colors.blue[9],
                color: theme.white,
              },
            })}
          >
            <span>
              {title && title.length > 15
                ? `${title.substring(0, 15)}...`
                : title}
            </span>
          </RxTooltip>
        );
      },
    },
    {
      accessorKey: "createdDate",
      header: "Donation Date",
      sortable: true,
    },
    {
      accessor: "fundraiserTitle",
      header: "Title",

      Cell: ({ row }) => {
        const title = row.original.fundraiserTitle;
        return (
          <RxTooltip
            label={title || ""}
            styles={(theme) => ({
              tooltip: {
                backgroundColor: theme.colors.blue[9],
                color: theme.white,
              },
            })}
          >
            <span>
              {title && title.length > 15
                ? `${title.substring(0, 15)}...`
                : title}
            </span>
          </RxTooltip>
        );
      },

      sortable: true,
    },

    {
      accessorKey: "cause",
      header: "Cause Type",
      sortable: true,

      Cell: ({ row }) => {
        const cause = row.original.cause;
        return (
          <RxTooltip
            label={cause || ""}
            styles={(theme) => ({
              tooltip: {
                backgroundColor: theme.colors.blue[9],
                color: theme.white,
              },
            })}
          >
            <span>
              {cause && cause.length > 15
                ? `${cause.substring(0, 15)}...`
                : cause}
            </span>
          </RxTooltip>
        );
      },
    },

    {
      accessorKey: "donatedAmount",
      header: "Donated amount",
      sortable: true,
      Cell: ({ cell }) => <Box>{formatCurrency(cell.getValue())}</Box>,
    },
  ];

  const table = useMantineReactTable({
    columns,
    data: donorList,

    initialState: { showColumnFilters: false },
    rowCount,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,

    onColumnFiltersChange: setColumnFilters,

    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  useEffect(() => {
    getFundraiserList();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    columnFilters,
    statusFilter,
    causeValueMain,
  ]);

  useEffect(() => {
    getCauseList();
    getCauseWiseDonation();
  }, []);

  const colors = [
    "#2731C8",
    "#60009B",
    "#B800D8",
    "#2E96FF",
    "#03008D",
    "#02B2AF",
    "#845EC2",
    "#CF3D2A",
  ];

  async function getCauseWiseDonation() {
    const url = new URL(`${TQ_URL}/getPiechartcausewisedonation`);
    url.search = new URLSearchParams();
    console.log(partnerData.partnerId);
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);

    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log(response);

      const transformedData = Object.keys(response).map((cause) => ({
        label: cause,
        value: response[cause],
      }));
      setPieData(transformedData);
    } catch (error) {
      console.error("Error in fetching the Cause data:", error);
    }
  }

  const getFundraiserList = async () => {
    const cause = causeValueMain > 0 ? causeValueMain : 0;

    const fid = localStorage.getItem("fid");
    console.log("Local fid", fid);
    const url = new URL(`${TQ_URL}/getCausewiseDonation`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("causeId", `${cause}`);
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);

    try {
      const response = await getRequestWithOutToken(url.toString());

      console.log(response);

      const dataWithSerialNumber = response.dataList.datalist.map(
        (item, index) => ({
          ...item,
          id: index + 1,
        })
      );

      setDonorLIst(dataWithSerialNumber);
      console.log(dataWithSerialNumber);

      setRowCount(response.dataList.totalCount);

      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the causeWise donation data:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
  };

  async function getCauseList() {
    const url = new URL(
      `${TQ_URL}/getCauseList?partnerId=${partnerData.partnerId}`
    );
    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log(response.data);
      setCauseList(response.data);
    } catch (error) {
      console.error("Error in fetching the Cause data:", error);
    }
  }

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed h-full md:overflow-y-auto">
          <AnalyticsSidebar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px] overflow-x-hidden">
          <div className="mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="flex items-center justify-between w-full">
                <h2 className="text-2xl font-bold mr-4 max-sm:text-xl">
                  Causewise Donation
                </h2>
                <div className=" flex items-center">
                  {/* <span className="mr-2">Cause:</span> */}
                  <div>
                    <select
                      onChange={getCauseTypeValueForMain}
                      value={causeValueMain}
                      className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                    >
                      <option value="">Select Cause Name</option>
                      {causeList.map((type, index) => (
                        <option key={index} value={type.causeId}>
                          {type.causeName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="container"></div>
            <div className="container px-10 mt-5">
              <MantineReactTable table={table} />
            </div>
          </div>

          <div className="container mt-8">
            <h2 className="text-lg px-10 font-bold divHidden">
              Cause Donations Analysis
            </h2>
            <div className="webkit-centerForAnalytics divHidden">
              {pieData.length > 0 && (
                <ResponsiveContainer
                  width="100%"
                  height={400}
                  className="pieChart-Style"
                >
                  <PieChart>
                    <Pie
                      data={pieData}
                      dataKey="value"
                      nameKey="label"
                      cx="50%"
                      cy="50%"
                      outerRadius={150}
                      innerRadius={20}
                      paddingAngle={2}
                      labelLine={true}
                      label={({ name, value }) => `${name}: ${value}`}
                    >
                      {pieData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={colors[index % colors.length]}
                        />
                      ))}
                    </Pie>
                    <RechartsTooltip />
                    <Legend
                      align="right"
                      verticalAlign="middle"
                      layout="vertical"
                    />
                  </PieChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>

          <div className="container mt-8">
            <div className="flex flex-col md:flex-row md:items-center mb-8 justify-between px-10">
              <h2 className="text-lg font-bold mb-4 md:mb-0">
                Cause Donations Analysis
              </h2>
              <div className="flex flex-wrap justify-between w-full md:w-auto">
                <div className="mx-2 mb-4 md:mb-0">
                  <select
                    onChange={getCauseTypeValue}
                    value={causeValue}
                    className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                  >
                    <option value="">Select Cause Name</option>
                    {causeList.map((type, index) => (
                      <option key={index} value={type.causeId}>
                        {type.causeName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mx-2 mb-4 md:mb-0">
                  <select
                    onChange={getTypeValue}
                    value={value}
                    className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                  >
                    <option value="">Select frequency type</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
                {monthDropdown && (
                  <div className="mx-2 mb-4 md:mb-0">
                    <select
                      onChange={getMonthValue}
                      value={monthValue}
                      className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                    >
                      <option value="">Select Month</option>
                      {monthList.map((month, index) => (
                        <option key={month.id} value={month.id}>
                          {month.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {(yearDropdown || monthValue) && (
                  <div className="mx-2 mb-4 md:mb-0">
                    <select
                      onChange={getYearValue}
                      value={yearValue}
                      className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full custom-padding"
                    >
                      <option value="">Select Year</option>
                      {yearList.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>

            <div className="webkit-centerForAnalytics md:w-full md:ml-2">
              <Chart id="chart" palette="Soft" dataSource={graphData}>
                <CommonSeriesSettings
                  argumentField="freqLabel"
                  valueField="usercount"
                  type="bar"
                  ignoreEmptyPoints={true}
                  width={30}
                />
                <SeriesTemplate nameField="freqLabel" />
                {/* <Title text="CauseWise breakDown details" /> */}
                <DxTooltip enabled={true} />
              </Chart>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default CausewiseDonation;
