import React, { useState, useRef, useEffect, useMemo } from "react";
import TopHeader from "../Home/TopHeader";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";

import { useNavigate, Link } from "react-router-dom";
import SocialMediaSideBar from "./SocialMediaSideBar";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { DatePickerInput } from "@mantine/dates";
import AOS from "aos";
import "aos/dist/aos.css";
import { getRequestWithTokenWithoutDataPartner } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import AddTag from "./AddTag";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";

const ViewTags = () => {
  const navigate = useNavigate();

  const tagsDetails = [
    {
      id: 1,
      tagName: "#charity",
      draft: 2,
      sent: 3,
      schedule: 3,
    },
    {
      id: 2,
      tagName: "#NonProfit",
      draft: 2,
      sent: 3,
      schedule: 3,
    },
    {
      id: 3,
      tagName: "#CommunityService",
      draft: 2,
      sent: 3,
      schedule: 3,
    },
    {
      id: 4,
      tagName: "#Humanitarian",
      draft: 2,
      sent: 3,
      schedule: 3,
    },
    {
      id: 5,
      tagName: "#Volenteer",
      draft: 2,
      sent: 3,
      schedule: 3,
    },
  ];

  const [tagsData, setTagsData] = useState([tagsDetails]);
  const [modalopen, setModalOpen] = useState(false);

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);

  const [sorting, setSorting] = useState([]);

  const [value, setValue] = React.useState(0);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (!tagsData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getAllTagsAnalytics();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting, //refetch when sorting changes
    columnFilters,
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        sortable: true,
      },
      {
        accessorKey: "tagName",
        header: "Tag name",
        sortable: true,
      },
      {
        accessorKey: "scheduleCount",
        header: "Schedule post",
        sortable: true,
      },
      {
        accessorKey: "sentCount",
        header: "Sent post",
        sortable: true,
      },
    ],
    []
  );

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const getAllTagsAnalytics = async () => {
    const url = new URL(`${TQ_URL}/getTagsAnalytics`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );

      console.log(response);

      console.log(response.analyticsList);

      const dataWithSerialNumber = response.analyticsList.map(
        (item, index) => ({
          ...item,
          id: index + 1,
        })
      );

      console.log(response.analyticsList);

      setTagsData(dataWithSerialNumber);
      setRowCount(response.totalCount);

      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching tags analytics:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      console.log("searchText:", searchText);

      const url = new URL(`${TQ_URL}/searchTagsAnalytics`);
      url.search = new URLSearchParams();
      url.searchParams.set("start", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);

      url.searchParams.set("keyword", `${searchText}`);
      url.searchParams.set("partnerId", `${partnerData.partnerId}`);

      try {
        const response = await getRequestWithTokenWithoutDataPartner(
          url.toString()
        );

        console.log(response);

        console.log(response.searchCriteria);

        const dataWithSerialNumber = response.searchCriteria.map(
          (item, index) => ({
            ...item,
            id: index + 1,
          })
        );

        console.log(response.searchCriteria);

        setTagsData(dataWithSerialNumber);
        // setRowCount(response.totalCount);

        setIsRefetching(false);
      } catch (error) {
        console.error("Error in fetching the searched Tags Analytics:", error);
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
  };

  const table = useMantineReactTable({
    columns,
    data: tagsData,

    initialState: { showColumnFilters: false },
    rowCount,

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,

    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  function importModal() {
    setModalOpen(true);
  }

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
      <DonorManagementTop />
      </div>
      <div className="flex  mt-[50px] ">
        <div className="md:w-64 w-1/12 absolute md:fixed">
          <SocialMediaSideBar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px]">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">View tags Analytics </h2>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row items-center md:mt-4">
            <div className="md:mx-8 rounded  p-1 flex  w-full  justify-between md:flex-grow-0 md:w-6/12">
              <input
                type="text"
                placeholder="Search..."
                className="py-2 pr-8 pl-4 block w-full rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                onChange={handleChange}
                onKeyDown={handleKeyPress}
              />
            </div>

            <div className="flex mx-8 my-2">
              <button
                className="btn-style-1 text-white py-1 px-1 md:py-2  md:px-4 rounded  md:block mx-2"
                onClick={() => importModal()}
              >
                +Add Fund
              </button>
            </div>
          </div>

          <div className="md:mx-8 md:py-8 py-6">
            <MantineReactTable table={table} />
          </div>
        </main>
      </div>
      {modalopen && <AddTag onClose={() => setModalOpen(false)} />}
    </>
  );
};

export default ViewTags;
