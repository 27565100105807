import React, { useState, useEffect } from "react";
import TopHeader from "../Home/TopHeader";
import DonorManagementSideBar from "./DonorManagementSideBar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Tour from "reactour";
import { TQ_URL } from "../../utility/baseUrl";
import DonorManagementTop from "./DonorManagementTop";
import { useNavigate } from "react-router-dom";
import MultipleTour from "./MultipleTour";

// const steps = [
//   {
//     selector: "#report", // New step targeting the donation option
//     content: "Please find out the reports using this option",
//     style: { background: "#dff9fb" },
//   },

//   {
//     selector: "#step1",
//     content: "Here you will find the list of all imported Donations",
//     style: { background: "#dff9fb" },
//   },

//   {
//     selector: "#step2",
//     content: "Here you will find the list of all website Donations",
//     style: { background: "#dff9fb" },
//   },

//   {
//     selector: "#step3",
//     content: "Here you can add the Fundraiser  by adding the fund details",
//     style: { background: "#dff9fb" },
//   },
// ];

const Report = () => {
  const navigate = useNavigate();

  // const [isTourOpen, setIsTourOpen] = useState(true);

  // const [currentStep, setCurrentStep] = useState(
  //   parseInt(localStorage.getItem("tourReportStep") || 0, 10)
  // );

  const partnerDataString = localStorage.getItem("partnerData");
  const partnerData = partnerDataString ? JSON.parse(partnerDataString) : null;
  const partnerId = partnerData ? partnerData.partnerId : 1;

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

 

  const tourCompletedFlag = localStorage.getItem("donorTourCompletedFlag");
  console.log("donorTourCompletedFlag ", tourCompletedFlag);

  const handleExportDonors = async () => {
    try {
      const response = await axios.get(
        `${TQ_URL}/exportDonors?partnerId=${partnerId}`
      );
      // Assuming the backend returns a success status if data is available
      if (response.status === 200) {
        window.open(`${TQ_URL}/exportDonors?partnerId=${partnerId}`, "_blank");
      } else {
        toast.error("No data found for export"); // Show toast notification for error
      }
    } catch (error) {
      console.error("Error exporting donors:", error);
      toast.error("No data found for export"); // Show toast notification for error
    }
  };

  const handleExportFundraisers = async () => {
    try {
      const response = await axios.get(
        `${TQ_URL}/exportFundraiserDonations?partnerId=${partnerId}`
      );
      // Assuming the backend returns a success status if data is available
      if (response.status === 200) {
        window.open(
          `${TQ_URL}/exportFundraiserDonations?partnerId=${partnerId}`,
          "_blank"
        );
      } else {
        toast.error("No data found for export"); // Show toast notification for error
      }
    } catch (error) {
      console.error("Error exporting donors:", error);
      toast.error("No data found for export"); // Show toast notification for error
    }
  };

  const handleExportDonations = async () => {
    try {
      const response = await axios.get(
        `${TQ_URL}/exportDonations?partnerId=${partnerId}`
      );
      // Assuming the backend returns a success status if data is available
      if (response.status === 200) {
        window.open(
          `${TQ_URL}/exportDonations?partnerId=${partnerId}`,
          "_blank"
        );
      } else {
        toast.error("No data found for export"); // Show toast notification for error
      }
    } catch (error) {
      console.error("Error exporting donors:", error);
      toast.error("No data found for export"); // Show toast notification for error
    }
  };

  return (
    <>
      <DonorManagementTop />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user">
          <ToastContainer /> {/* Toast container */}
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Reports</h2>
              </div>
            </div>
          </div>
          <div className="container mx-auto">
            <div className="flex flex-wrap justify-between webkit-center">
              <div
                id="report-step1"
                className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 p-4"
              >
                <div className="max-w-[250px] rounded overflow-hidden shadow-lg py-5 text-center">
                  <div className="font-bold text-xl mb-2">Donor report</div>
                  <button
                    className="btn-style-1 text-white px-4 md:mt-8 py-2 rounded mt-2"
                    onClick={handleExportDonors}
                  >
                    Fetch
                  </button>
                </div>
              </div>
              <div
                id="report-step2"
                className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 p-4"
              >
                <div className="max-w-[250px] rounded overflow-hidden shadow-lg py-5 text-center">
                  <div className="font-bold text-xl mb-2">
                    Fundraiser report
                  </div>
                  <button
                    className="btn-style-1 text-white px-4 md:mt-8 py-2 rounded mt-2"
                    onClick={handleExportFundraisers}
                  >
                    Fetch
                  </button>
                </div>
              </div>

              <div
                id="report-step3"
                className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 p-4"
              >
                <div className="max-w-[250px] rounded overflow-hidden shadow-lg py-5 text-center">
                  <div className="font-bold text-xl mb-2">Donation Report</div>
                  <button
                    className="btn-style-1 text-white px-4 md:mt-8 py-2 rounded mt-2"
                    onClick={handleExportDonations}
                  >
                    Fetch
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* <Tour
          steps={steps}
          isOpen={isTourOpen}
          rounded={5}
          startAt={currentStep}
          stepInteraction={true}
          maskClassName="mask"
          accentColor="#269faf"
          onRequestClose={() => setIsTourOpen(false)} // Close the tour but don't navigate immediately
          getCurrentStep={(stepIndex) => {
            console.log("Step index from tour:", stepIndex); // Debug log
            handleNextStep(stepIndex);
          }}
          onBeforeClose={() => console.log("Tour closed")}
          nextButton={<button className="tour-button">Next ➡️</button>}
          prevButton={<button className="tour-button">⬅️ Back</button>}
          lastStepNextButton={
            <button className="tour-button" onClick={handleFinishRedirection}>
              Finish 🎉
            </button>
          }
          showSkipButton={true}
          skipButton={<button className="tour-button">Skip ❌</button>}
        /> */}

        {/* {currentStepIndex >= 16 && <MultipleTour />} */}

        {tourCompletedFlag === "false" && isLargeScreen && <MultipleTour />}
      </div>
    </>
  );
};

export default Report;
