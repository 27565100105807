import React, { useState, useEffect } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  Button,
  Tooltip,
  Modal,
  Textarea,
  ActionIcon,
  Box,
  TextInput,
} from "@mantine/core";
import { IconEdit, IconEye, IconCurrencyDollar } from "@tabler/icons-react";
import {
  getRequestWithTokenWithDataWithAuthentication,
  postWithTokenWithDataReturnDate,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { Triangle } from "react-loader-spinner";
import { Link } from "react-router-dom";

const AllFundraisers = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [selectedFundraiser, setSelectedFundraiser] = useState(null);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [editPopupOpen, setEditPopupOpen] = useState({
    isOpen: false,
    rowData: null,
  });
  const [reimbursementModalOpen, setReimbursementModalOpen] = useState(false);
  const [reimbursementAmount, setReimbursementAmount] = useState(""); // Track input for reimbursement
  const [totalMoneyRaised, setTotalMoneyRaised] = useState(0); // Track total money raised for the selected fundraiser
  const navigate = useNavigate();
  const [moneyAvailableForReimbursement, setMoneyAvailableForReimbursement] =
    useState(0); // Track total money raised for the selected fundraiser
  const [partnerId, setPartnerId] = useState(null);

  useEffect(() => {
    const partnerData = JSON.parse(localStorage.getItem("partnerData"));
    setPartnerId(partnerData ? partnerData.partnerId : null);
    if (!data.length) setIsLoading(true);
    else setIsRefetching(true);
    getAllTransaction();
  }, [pagination.pageIndex, pagination.pageSize, sorting, columnFilters]);

  useEffect(() => {
    if (reimbursementModalOpen) {
      setReimbursementAmount(moneyAvailableForReimbursement); // Set TextInput to the correct amount
    }
  }, [moneyAvailableForReimbursement, reimbursementModalOpen]);

  const getAllTransaction = async () => {
    const partnerData = JSON.parse(localStorage.getItem("partnerData"));
    const partnerId = partnerData ? partnerData.partnerId : null;
    const url = new URL(`${TQ_URL}/allFundraisers`);
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("partnerId", partnerId);
    if (sorting.length) {
      url.searchParams.set("sortField", sorting[0].id);
      url.searchParams.set("sortDirection", sorting[0].desc ? "desc" : "asc");
    }
    if (columnFilters.length) {
      url.searchParams.set("filterField", columnFilters[0].id);
      url.searchParams.set("filterValue", columnFilters[0].value);
    }

    try {
      const response = await getRequestWithTokenWithDataWithAuthentication(
        url.toString()
      );
      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,

        modifyTitle:
          item.title.split(" ").slice(0, 5).join(" ") +
          (item.title.split(" ").length > 5 ? "..." : ""),
        id: index + 1,
        status: item.isAdminApproved === 1 ? "Active" : "Inactive",
        fundraiserId: item.fundraiserId,
        // // reimbursedAmount: calculateReimbursedAmount(item)
        // reimbursedAmount:"$50"
      }));
      console.log(response);
      setData(dataWithSerialNumber);
      setRowCount(response.totalCount);
    } catch (error) {
      console.error("Error in fetching All Transactions:", error);
      setIsError(true);
      toast.error("Error loading data", { position: toast.POSITION.TOP_RIGHT });
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const handleStatusClick = (fundraiser) => {
    setSelectedFundraiser(fundraiser);
    console.log(fundraiser);
    setIsActivating(fundraiser.status === "Inactive");
    setIsModalOpen(true);
  };

  const handleSubmit = () => {
    if (!reason.trim()) {
      toast.warn("Please enter a reason or click Skip.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setConfirmationStep(true);
  };

  const handleSkip = () => {
    setReason("");
    setConfirmationStep(true);
  };

  const handleCloseModal = () => {
    setReason("");
    setConfirmationStep(false);
    setIsModalOpen(false);
  };

  const handleConfirm = async (confirm) => {
    setConfirmationStep(true);
    if (confirm) {
      setIsSubmitting(true);

      try {
        const response = await postWithTokenWithDataReturnDate(
          `${TQ_URL}/${
            isActivating
              ? "activatefundraiserAdmin"
              : "deactivatefundraiserAdmin"
          }`,
          { fundraiserId: selectedFundraiser.fundraiserId, reason: reason }
        );
        if (response.status === 200) {
          const updatedData = data.map((item) =>
            item.fundraiserId === selectedFundraiser.fundraiserId
              ? { ...item, status: isActivating ? "Active" : "Inactive" }
              : item
          );
          setData(updatedData);
          toast.success(response.data, { position: toast.POSITION.TOP_RIGHT });
        } else {
          toast.error(`Error: ${response.data}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.error(
          `Error ${isActivating ? "activating" : "deactivating"} fundraiser:`,
          error
        );
        toast.error(
          `Error ${isActivating ? "activating" : "deactivating"} fundraiser`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setIsError(true);
      } finally {
        setIsModalOpen(false);
        setIsSubmitting(false);
      }
    }
    setIsModalOpen(false);
    setConfirmationStep(false);
    setReason("");
  };

  const handleEditFundraiser = (row) => {
    console.log("Editing fundraiser:", row.fundraiserId);
    navigate("/edit-fundraiser", {
      state: {
        fundraiserId: row.fundraiserId, // Pass the fundraiserId through state
      },
    });
  };

  const handleViewClick = (fundraiserId) => {
    console.log("View fundraiser:", fundraiserId);

    let url = `/fundraiser/${fundraiserId}`;
    window.open(url, "_blank"); // Opens the link in a new tab
  };

  // Helper function to format the number as US currency while typing
  const formatCurrencyWhileTyping = (e) => {
    const input = e.target.value;

    // Remove all non-numeric characters except for one decimal point
    const cleanedInput = input
      .replace(/[^0-9.]/g, "") // Allow only digits and decimal point
      .replace(/(\..*)\./g, "$1") // Allow only one decimal point
      .replace(/^0+(\d)/, "$1"); // Prevent leading zeros (optional)

    // Limit decimal to two places
    const decimalIndex = cleanedInput.indexOf(".");
    if (decimalIndex >= 0) {
      const integerPart = cleanedInput.slice(0, decimalIndex);
      const decimalPart = cleanedInput.slice(
        decimalIndex + 1,
        decimalIndex + 3
      );
      const formattedIntegerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
      setReimbursementAmount(`$${formattedIntegerPart}.${decimalPart}`);
    } else {
      const formattedIntegerPart = cleanedInput.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
      setReimbursementAmount(`$${formattedIntegerPart}`);
    }
  };

  // Handle input change for reimbursement field with currency formatting while typing
  const handleReimbursementInputChange = (e) => {
    const input = e.target.value;

    const numericValue = sanitizeAmount(input);

    if (isNaN(numericValue)) {
      // If not a valid number, handle it (optional)
      setReimbursementAmount("");
      return;
    }

    const moneyAvailForReimb = sanitizeAmount(moneyAvailableForReimbursement);
    if (numericValue > moneyAvailForReimb) {
      toast.error(
        "The reimbursement amount cannot exceed the available amount.",
        { position: toast.POSITION.TOP_RIGHT }
      );
      setReimbursementAmount("");
      return;
    }
    setReimbursementAmount(formatCurrency(numericValue));
  };

  // Function to handle reimbursement click
  const handleReimbursement = (fundraiser) => {
    console.log("------------->funsraiser", fundraiser.totalMoneyRaised);
    if (sanitizeAmount(fundraiser.totalMoneyRaised) == 0) {
      toast.error("You cannot reimburse as no money has been raised.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setTotalMoneyRaised(fundraiser.totalMoneyRaised); // Set total money raised in state
    setSelectedFundraiser(fundraiser); // Set selected fundraiser
    const amt =
      sanitizeAmount(fundraiser.totalMoneyRaised) -
      sanitizeAmount(fundraiser.reinbuseAmount);
    console.log(" sanitized amt", amt);
    setMoneyAvailableForReimbursement(formatCurrency(amt));
    console.log(moneyAvailableForReimbursement);

    setReimbursementModalOpen(true); // Open modal
    // setReimbursementAmount(moneyAvailableForReimbursement); // Clear input
  };

  // Function to handle reimbursement submission
  const handleReimbursementSubmit = async () => {
    try {
      const amount = sanitizeAmount(reimbursementAmount);

      if (amount === "" || isNaN(amount) || parseFloat(amount) <= 0) {
        toast.error("Please enter a valid reimbursement amount", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      const partnerData = JSON.parse(localStorage.getItem("partnerData"));
      const partnerId = partnerData ? partnerData.partnerId : null;

      console.log(
        `Requesting reimbursement of $${amount} for fundraiser ID: ${selectedFundraiser.fundraiserId}`
      );
      let request = {
        partnerId: partnerId,
        fundraiserId: selectedFundraiser.fundraiserId,
        reimbusementAmount: amount,
      };
      const endPoint =
        partnerId == 1 ? "sendReinbuseAmountByAdmin" : "requestReinbuseAmount";
      const response = await postWithTokenWithDataReturnDate(
        `${TQ_URL}/${endPoint}`,
        request
      );
      console.log("--------------->", response);
      if (response.status == 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setReimbursementModalOpen(false);
      getAllTransaction();
      setReimbursementAmount(""); // Clear input after submission
    } catch (error) {
      console.error("Error during reimbursement submission:", error);
      toast.error(
        "An error occurred while processing your reimbursement request. Please try again.",
        { position: toast.POSITION.TOP_RIGHT }
      );
    }
  };

  const columns = [
    { accessorKey: "id", header: "ID", size: 70 },
    { accessorKey: "modifyTitle", header: "Title" },
    { accessorKey: "fundraiserName", header: "Fundraiser Name" },
    { accessorKey: "totalMoneyRaised", header: "Total Money Raised" },
    { accessorKey: "targetAmount", header: "Target Amount" },
    { accessorKey: "remainingAmount", header: "Remaining Amount" },
    { accessorKey: "reinbuseAmount", header: "Reimbursed Amount" },
    { accessorKey: "targetDate", header: "Target Date" },

    {
      accessorKey: "status",
      header: "Status",
      enableSorting: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      Cell: ({ row }) => {
        const isActive = row.original.status === "Active";
        return (
          <Tooltip
            label={
              isActive
                ? "Click to deactivate the fundraiser"
                : "Click to activate the fundraiser"
            }
            position="top"
            withArrow
          >
            {/* <Button
              onClick={() => handleStatusClick(row.original)}
              variant="light"
              color={isActive ? "green" : "red"}
            >
              {row.original.status || "Inactive"}
            </Button> */}

            <Android12Switch
              checked={isActive}
              onChange={() => handleStatusClick(row.original)}
              sx={{ m: 1 }} // Styling
            />
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ row }) => (
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Tooltip label="Edit Fundraiser" position="top" withArrow>
            <ActionIcon
              color="orange"
              onClick={() => handleEditFundraiser(row.original)}
            >
              <IconEdit />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Check Your Fundraiser" position="top" withArrow>
            <ActionIcon
              color="blue"
              onClick={() => handleViewClick(row.original.fundraiserId)}
            >
              <IconEye />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Request for Reimbursement" position="top" withArrow>
            <ActionIcon
              color="green"
              onClick={() => handleReimbursement(row.original)}
            >
              <IconCurrencyDollar />
            </ActionIcon>
          </Tooltip>
        </Box>
      ),
    },
  ];
  if (partnerId !== 1) {
    columns.push({
      accessorKey: "pendingReinbuseAmount",
      header: "Pending Reimbursed Amount",
      Cell: ({ cell }) => {
        const amount = cell.getValue();
        const cellStyle = {
          color: amount != "$0.00" ? "red" : "black", // Apply yellow background if greater than 0
        };
        return <div style={cellStyle}>{amount}</div>;
      },
    });
  }

  const table = useMantineReactTable({
    columns,
    data,
    initialState: { showColumnFilters: false },
    rowCount,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,
  });

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  return (
    <div>
      <DonorManagementTop />
      <div className="flex flex-col md:flex-row">
        <main className="w-full verify-user">
          <div className="mx-auto shadow-md p-4 md:p-8">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="text-center md:text-left">
                <h2 className="text-2xl font-bold">
                  Checkout the list of all Fundraisers
                </h2>
              </div>
              <div className="mt-4 md:mt-0">
                <Link to="/paymentrequest">
                  <button className="btn-style-1 text-white py-2 px-4 rounded hover:bg-blue-600 transition">
                    Check Reimbursement Request Status
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto px-4 md:px-8 py-8 container mx-auto">
            <MantineReactTable table={table} />
          </div>
        </main>
      </div>

      {/* Reimbursement Modal */}
      {/* <Modal
      opened={reimbursementModalOpen}
      onClose={() => setReimbursementModalOpen(false)}
      centered
      size="md"
      overlayOpacity={0.7}
      transition="fade"
      transitionDuration={300}
      className="p-6"
      styles={{
        modal: { backgroundColor: "#fff", padding: "40px", borderRadius: "10px", maxWidth: "500px" },
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
      }}
    >
      <div className="text-center space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800">Request Reimbursement</h2>
        <p className="text-base text-gray-500">
          You have raised a total of <span className="font-bold">{totalMoneyRaised}</span>.
        </p>
        <p className="text-base text-gray-500">
          The available reimbursement amount is <span className="font-bold">{moneyAvailableForReimbursement}</span>.
        </p>
        <TextInput
          label="Enter Reimbursement Amount"
          size="lg"
          value={reimbursementAmount}
          onChange={formatCurrencyWhileTyping}
          onBlur={handleReimbursementInputChange}
          placeholder="$0.00"

          classNames={{
            input: "border border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded-lg",
            label: "text-gray-600 font-medium",
          }}

        />
        <div className="flex justify-end space-x-4">
          <Button onClick={handleReimbursementSubmit} className="bg-blue-600 text-white">
            Submit
          </Button>
          <Button variant="outline" onClick={() => setReimbursementModalOpen(false)} className="border-gray-300 text-gray-600">
            Cancel
          </Button>
        </div>
      </div>
    </Modal> */}

      <Modal
        opened={reimbursementModalOpen}
        onClose={() => setReimbursementModalOpen(false)}
        centered
        size="md"
        overlayOpacity={0.8}
        transition="scale"
        transitionDuration={600}
        className="p-6"
        closeOnClickOutside={false} // Prevent modal from closing on outside click
        withCloseButton={false}
        styles={{
          modal: {
            background: "rgba(255, 255, 255, 0.85)", // Slight transparency for a glassmorphism effect
            backdropFilter: "blur(10px)", // Blurring the background
            padding: "40px",

            maxWidth: "600px",
            boxShadow: "0 20px 50px rgba(0, 0, 0, 0.2)", // Deeper shadow for a more elevated look
            transition: "all 0.6s ease", // Smooth transition for opening/closing
          },
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.8)" }, // Darker overlay for a stronger focus
        }}
      >
        <div className="text-center space-y-5 p-6">
          <h2 className="text-2xl font-extrabold text-blue-900">
            Request Reimbursement
          </h2>
          <p className="text-lg text-gray-700">
            You have raised a total of{" "}
            <span className="font-bold text-green-600">
              {" "}
              <br /> {totalMoneyRaised}
            </span>
            .
          </p>
          <p className="text-lg text-gray-700">
            The available reimbursement amount is{" "}
            <span className="font-bold text-green-600">
              {moneyAvailableForReimbursement}
            </span>
            .
          </p>
          <TextInput
            label="Enter Reimbursement Amount"
            size="lg"
            value={reimbursementAmount}
            onChange={formatCurrencyWhileTyping}
            onBlur={handleReimbursementInputChange}
            placeholder="$0.00"
            classNames={{
              input:
                "border border-gray-300 focus:ring-blue-600 focus:border-blue-600 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out",
              label: "text-gray-800 font-semibold",
            }}
          />
          <div className="flex justify-center space-x-8">
            <Button
              variant="outline"
              onClick={() => setReimbursementModalOpen(false)}
              className="border border-gray-300 text-gray-800 px-8 py-3  hover:bg-gray-200 transition-all duration-300 ease-in-out "
            >
              Cancel
            </Button>
            <Button
              onClick={handleReimbursementSubmit}
              // className="bg-gradient-to-r from-green-500 to-blue-500 text-white font-bold px-8 py-3 rounded-full shadow-xl hover:from-blue-600 hover:to-green-600 hover:shadow-2xl transition-all duration-300 ease-in-out transform hover:-translate-y-2"
              className="primary-bg-color text-white font-bold px-8 py-3  shadow-xl   hover:shadow-2xl transition-all duration-300 ease-in-out "
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>

      {/* Activate Deactivate fundraiser Modal */}
      <Modal
        opened={isModalOpen}
        onClose={handleCloseModal}
        centered
        size="md"
        overlayOpacity={0.5}
        overlayBlur={3}
        classNames={{ modal: "bg-gray-100 rounded-lg p-6" }} // Consistent light grey background
      >
        {!confirmationStep ? (
          isActivating ? (
            <div className="flex flex-col items-center space-y-6">
              <h2 className="text-xl font-semibold text-gray-800">
                Are you sure you want to activate this fundraiser?
              </h2>
              <div className="flex justify-center space-x-4">
                <Button
                  onClick={() => handleConfirm(true)}
                  className="bg-green-600 text-white font-semibold px-6 py-2 rounded-lg"
                >
                  Yes
                </Button>
                <Button
                  variant="outline"
                  onClick={() => handleConfirm(false)}
                  className="border-gray-300 text-gray-600 font-semibold px-6 py-2 rounded-lg"
                >
                  No
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col space-y-4">
              <h2 className="text-lg font-semibold text-gray-800">
                Enter reason to deactivate the fundraiser
              </h2>
              <Textarea
                placeholder="Enter the reason here"
                value={reason}
                onChange={(event) => setReason(event.currentTarget.value)}
                className="w-full flex-1 p-4 rounded-lg border-gray-300"
              />
              <div className="flex justify-end space-x-4">
                <Button
                  onClick={handleSubmit}
                  className="bg-blue-600 text-white font-semibold px-4 py-2 rounded-lg"
                >
                  Submit
                </Button>
                <Button
                  variant="outline"
                  onClick={handleSkip}
                  className="border-gray-300 text-gray-600 font-semibold px-4 py-2 rounded-lg"
                >
                  Skip
                </Button>
              </div>
            </div>
          )
        ) : (
          <div className="flex flex-col items-center justify-center space-y-6">
            {isSubmitting ? (
              <div className="flex justify-center items-center w-full h-full">
                <Triangle
                  height="90"
                  width="90"
                  color="blue"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  visible={true}
                />
              </div>
            ) : (
              <>
                <h2 className="text-xl font-semibold text-gray-800">
                  Are you sure you want to proceed?
                </h2>
                <div className="flex justify-center space-x-4">
                  <Button
                    onClick={() => handleConfirm(true)}
                    className="bg-green-600 text-white font-semibold px-6 py-2 rounded-lg"
                  >
                    Yes
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => handleConfirm(false)}
                    className="border-gray-300 text-gray-600 font-semibold px-6 py-2 rounded-lg"
                  >
                    No
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default AllFundraisers;

function calculateReimbursedAmount(item) {
  const amount = sanitizeAmount(item.totalMoneyRaised) || 0;

  return formatCurrency(amount * 0.1); // Example: 10% of the amount
}

const sanitizeAmount = (amount) => {
  // Keep only digits and the period
  console.log("===========>", amount);

  const cleanedAmount = amount.replace(/[^0-9.]/g, "");
  // Convert to number
  return parseFloat(cleanedAmount);
};

// Function to format a number as currency
const formatCurrency = (amount, locale = "en-US", currency = "USD") => {
  if (!amount) return "";
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  });
  return formatter.format(amount);
};
