import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import Tour from "reactour";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TQ_URL } from "../../utility/baseUrl";

const stepStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
};
export const PaymentMethods = () => {
  const [isTourOpen, setIsTourOpen] = useState(true);
  const navigate = useNavigate();
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const partnerId = partnerData.partnerId;
  const moduleId = 6;

  const [tourCompletedFlag, setTourCompletedFlag] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(true);

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchTourStatus = async () => {
      try {
        const response = await axios.get(
          `${TQ_URL}/user-tour-status/${partnerId}/${moduleId}`
        );
        console.log(response.data.tourCompleted);
        setTourCompletedFlag(response.data.tourCompleted); // Assuming your API returns the status in 'tourCompleted'
        localStorage.setItem(
          "donorTourCompletedFlag",
          response.data.tourCompleted
        );
      } catch (error) {
        console.error("Error fetching tour status:", error);
      } finally {
        setIsTableLoading(false); // Set loading to false after fetching the flag
      }
    };
    fetchTourStatus();
  }, []);

  useEffect(() => {
    console.log("Tour status:", isTourOpen);
  }, [isTourOpen]);

  const steps = [
    {
      selector: "#stripe-payment", // Stripe payment card selector
      content: (
        <div className="step-content">
          <span className="emoji-large">💳</span>
          <div>
            Stripe Pay allows secure online transactions with recurring billing
            and multi-currency support.
          </div>
          <div>It's fast and reliable!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#paypal-payment", // PayPal payment card selector
      content: (
        <div className="step-content">
          <span className="emoji-large">💰</span>
          <div>
            PayPal allows secure money transfers and online shopping payments.
          </div>
          <div>Quick and easy to use!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#applepay-payment", // Apple Pay card selector
      content: (
        <div className="step-content">
          <span className="emoji-large">🍏</span>
          <div>
            Apple Pay makes secure payments with your iPhone, iPad, and Mac.
          </div>
          <div>Effortless and secure!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#googlepay-payment", // Google Pay card selector
      content: (
        <div className="step-content">
          <span className="emoji-large">💳</span>
          <div>
            Google Pay lets you store payment methods for secure transactions.
          </div>
          <div>Seamless across devices!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#cashapp-payment", // Cash App card selector
      content: (
        <div className="step-content">
          <span className="emoji-large">💵</span>
          <div>
            Cash App provides effortless money transfers and quick payments.
          </div>
          <div>Safe and convenient!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#card-payment", // Card payment selector
      content: (
        <div className="step-content">
          <span className="emoji-large">💳</span>
          <div>
            Card payments allow you to use your credit or debit card for
            transactions.
          </div>
          <div>Convenient and widely accepted!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
  ];

  const handleFinishRedirection = async () => {
    console.log("finish clicked");

    try {
      // Call the API to mark the module as finished
      const response = await axios.put(
        `${TQ_URL}/complete/${partnerId}/${moduleId}`
      );

      if (response.status === 200) {
        console.log("Module marked as complete");

        // Redirect to the services dashboard after completion
        navigate("/services-dashboard");
      } else {
        console.error("Failed to complete the module", response.data);
      }
    } catch (error) {
      console.error("Error marking module as complete:", error);
    }
  };
  const handleCloseRequest = async () => {
    console.log("close clicked");

    try {
      // Call the API to mark the module as finished
      const response = await axios.put(
        `${TQ_URL}/complete/${partnerId}/${moduleId}`
      );

      if (response.status === 200) {
        console.log("Module marked as complete");
        setIsTourOpen(false);
      } else {
        console.error("Failed to complete the module", response.data);
      }
    } catch (error) {
      console.error("Error marking module as complete:", error);
    }
  };

  if (isTableLoading) {
    return null; // Don't render anything while loading
  }

  return (
    <>
      <div>
        <DonorManagementTop />
        <div className="absolute top-17 left-2 m-2">
          <button
            className="btn-style-1 text-white hover:bg-blue-600  rounded-full"
            onClick={(e) => {
              e.preventDefault();
              window.history.back();
            }}
          >
            <svg
              enableBackground="new 0 0 256 256"
              height="40px"
              viewBox="0 0 256 256"
              width="40px"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <path
                fill="white"
                d="M203.313,128c0,3.534-2.865,6-6.4,6H74.878l47.141,45.956c2.538,2.463,2.597,6.616,0.138,9.149  c-1.256,1.295-2.925,1.995-4.594,1.995c-1.606,0-3.216-0.576-4.456-1.782l-58.466-56.737c-1.244-1.206-1.943-2.855-1.943-4.587  s0.699-3.385,1.943-4.591l58.466-56.743c2.528-2.459,6.584-2.401,9.05,0.139c2.459,2.534,2.4,6.789-0.138,9.251L74.878,122h122.034  C200.447,122,203.313,124.466,203.313,128z M256,128C256,57.422,198.578,0,128,0S0,57.422,0,128s57.422,128,128,128  S256,198.578,256,128z M243.2,128c0,63.521-51.679,115.2-115.2,115.2S12.8,191.521,12.8,128S64.479,12.8,128,12.8  S243.2,64.479,243.2,128z"
              />
            </svg>
          </button>
        </div>
        <div className="container mx-auto mb-10">
          <div className="md:mt-10 mt-20 text-center">
            <h1 className="text-xl md:text-2xl font-bold md:w-2/3 mx-auto">
              Payment Methods for Seamless Transactions
            </h1>
          </div>
          <div className="mt-8 flex flex-wrap items-start justify-center md:space-x-4">
            {/* First style */}
            <div
              id="stripe-payment"
              className="w-full sm:w-[48%] md:w-[24%] mt-4 flex flex-col items-center"
            >
              <div className="flex justify-center">
                <div className="h-24 w-24 bg-gray-200 rounded-full flex items-center justify-center">
                  <img
                    src="/assets/images/stripePay.png"
                    className="h-16 w-16 rounded-full"
                    alt="icon-1"
                  />
                </div>
              </div>
              <div className="mt-8 flex-grow paymentBlock bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0 transition-opacity duration-300 ease-in-out group-hover:opacity-50">
                <h3 className="font-bold text-lg md:text-xl text-center">
                  Stripe Pay: Secure Online Payment Processing with Stripe
                </h3>
                <p className="mt-4 text-sm md:text-base leading-relaxed text-center">
                  Stripe is a technology company offering APIs for secure
                  payment processing, allowing businesses to accept various
                  forms of online payments. It supports features like recurring
                  billing and multi-currency transactions.
                </p>
              </div>
            </div>

            {/* Second style */}
            <div
              id="paypal-payment"
              className="w-full sm:w-[48%] md:w-[24%] mt-4 flex flex-col items-center"
            >
              <div className="flex justify-center">
                <div className="h-24 w-24 bg-gray-200 rounded-full flex items-center justify-center">
                  <img
                    src="/assets/images/pp.jpg"
                    className="h-17 w-20 rounded-full"
                    alt="icon-2"
                  />
                </div>
              </div>
              <div className="mt-8 flex-grow bg-gray-200 paymentBlock rounded-md shadow-md p-8 mx-6 md:mx-0 transition-opacity duration-300 ease-in-out group-hover:opacity-50">
                <h3 className="font-bold text-lg md:text-xl text-center">
                  PayPal: Streamlined Online Payments with PayPal
                </h3>
                <p className="mt-4 text-sm md:text-base leading-relaxed text-center">
                  PayPal is a widely-used online payment system that allows
                  individuals and businesses to send and receive money securely
                  over the internet. It offers a range of services, including
                  online shopping payments, money transfers.
                </p>
              </div>
            </div>

            {/* Third style */}
            <div
              id="applepay-payment"
              className="w-full sm:w-[48%] md:w-[24%] mt-4 flex flex-col items-center"
            >
              <div className="flex justify-center">
                <div className="h-30 w-30 bg-gray-200 rounded-full flex items-center justify-center">
                  <img
                    src="/assets/images/applePay1.png"
                    className="h-20 w-20 rounded-full"
                    alt="icon-3"
                  />
                </div>
              </div>
              <div className="md:mt-12 mt-8 paymentBlock flex-grow bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0 transition-opacity duration-300 ease-in-out group-hover:opacity-50">
                <h3 className="font-bold text-lg md:text-xl text-center">
                  Apple Pay: Effortless and Secure Payments with Apple Pay
                </h3>
                <p className="mt-4 text-sm md:text-base leading-relaxed text-center">
                  Apple Pay is a mobile payment and digital wallet service by
                  Apple that allows users to make secure payments using their
                  iPhone, iPad, Apple Watch, or Mac.
                </p>
              </div>
            </div>

            {/* Fourth style */}
            <div
              id="googlepay-payment"
              className="w-full sm:w-[48%] md:w-[24%] mt-4 flex flex-col items-center"
            >
              <div className="flex justify-center">
                <div className="h-24 w-24 bg-gray-200 rounded-full flex items-center justify-center">
                  <img
                    src="/assets/images/gpay.png"
                    className="h-19 w-24 rounded-full"
                    alt="icon-4"
                  />
                </div>
              </div>
              <div className="mt-8 flex-grow bg-gray-200 paymentBlock rounded-md shadow-md p-8 mx-6 md:mx-0 transition-opacity duration-300 ease-in-out group-hover:opacity-50">
                <h3 className="font-bold text-lg md:text-xl text-center">
                  Google Pay: Convenient and Secure Payments with Google Pay
                </h3>
                <p className="mt-4 text-sm md:text-base leading-relaxed text-center">
                  Google Pay is a digital wallet and payment platform that
                  allows users to store payment methods and make secure
                  transactions across devices. It supports in-store payments and
                  online purchases.
                </p>
              </div>
            </div>
          </div>

          <div className="mt-12 flex flex-wrap items-center justify-center space-y-8 md:space-y-0 md:space-x-2">
            {/* First style */}
            <div
              id="cashapp-payment"
              className="w-full sm:w-[48%] md:w-[24%] flex flex-col items-center"
            >
              <div className="flex justify-center">
                <div className="h-24 w-24 bg-gray-200 rounded-full flex items-center justify-center">
                  <img
                    src="/assets/images/cashApp.png"
                    className="h-16 w-16 rounded-full items-center"
                    alt="icon-5"
                  />
                </div>
              </div>
              <div className="mt-8 pb-12 bg-gray-200 paymentBlock1 rounded-md shadow-md p-8 mx-6 md:mx-0 transition-opacity duration-300 ease-in-out group-hover:opacity-50">
                <h3 className="font-bold text-lg md:text-xl text-center">
                  Cash App: Effortless Money Transfers with Cash App
                </h3>
                <p className="mt-4 text-sm md:text-base leading-relaxed text-center">
                  Cash App is a mobile payment service that enables users to
                  send and receive money instantly, buy and sell Bitcoin, and
                  invest in stocks.
                </p>
              </div>
            </div>

            {/* Second style */}
            <div
              id="card-payment"
              className="w-full sm:w-[48%] md:w-[24%] flex flex-col items-center"
            >
              <div className="flex justify-center">
                <div className="h-24 w-24 bg-gray-200 rounded-full flex items-center justify-center">
                  <img
                    src="/assets/images/cardImg.png"
                    className="h-07 w-16 rounded-full items-center"
                    alt="icon-6"
                  />
                </div>
              </div>
              <div className="mt-8 pb-16 bg-gray-200 rounded-md paymentBlock1 shadow-md p-8 mx-6 md:mx-0 transition-opacity duration-300 ease-in-out group-hover:opacity-50 ">
                <h3 className="font-bold text-lg md:text-xl text-center">
                  Seamless Card Payments: Effortless Transactions at Your
                  Fingertips
                </h3>
                <p className="mt-4 text-sm md:text-base leading-relaxed text-center">
                  Seamless card payments ensure efficient and secure
                  transactions using credit or debit cards, enhancing
                  convenience for both consumers and businesses in retail and
                  online environments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!tourCompletedFlag && isLargeScreen && (
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          scrollDuration={900}
          onRequestClose={handleCloseRequest}
          disableDotsNavigation={true}
          nextButton={<button className="tour-button">Next ➡️</button>}
          prevButton={<button className="tour-button">⬅️ Back</button>}
          lastStepNextButton={
            <button className="tour-button" onClick={handleFinishRedirection}>
              Finish 🎉
            </button>
          }
        />
      )}
    </>
  );
};
