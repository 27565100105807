import React, { useState, useEffect } from "react";
import axios from "axios";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const [config, setConfig] = useState({});
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const isUserLoggedIn = () => {
    return userData !== null;
  };
  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    console.log("email value", email);
    const jsonData = {
      inputEmail: email,
    };

    axios
      .post(`${TQ_URL}/saveNewsLetterEmailId`, jsonData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data === "News Letter Email id saved")
          toast.success("Thank you for the subscription");

        setEmail("");
        setErrorMessage("");
      });
  };

  const handleCreateFundraiserClick = () => {
    if (localStorage.getItem("accessToken") !== null) {
      navigate("/createfundraiser");
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        // Check and replace the logo URL if necessary
        if (data.logoUrl.includes("/home/tqadmin/public_html")) {
          data.logoUrl = data.logoUrl.replace(
            "/home/tqadmin/public_html",
            "https://prabalta.org"
          );
        }
        setConfig(data);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  return (
    <>
      <div className="top-footer-sec justify-between py-10">
        <div className="container flex flex-col sm:flex-row items-center justify-between mx-auto">
          <h3 className="text-xl font-bold text-center mb-4 sm:mb-0 ml-8">
            Follow Us For Further Information
          </h3>
          <div className="flex items-center justify-center mt-4 sm:mt-0 mr-8">
            <input
              type="email"
              placeholder="Enter Your Email"
              onChange={handleChange}
              value={email}
              pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
              title="Please enter a valid email address."
              className="border rounded-l p-2 outline-none w-full mr-4 text-center text-black"
            />

            <button className="btn-style-4 px-8 py-2" onClick={handleSubmit}>
              Subscribe
            </button>

            {errorMessage && (
              <p className="text-white-500 ml-2">{errorMessage}</p>
            )}
          </div>
        </div>
      </div>
      <div className="footer text-white p-8">
        <div className="container mx-auto">
          {/* Follow Us Section */}
          <img
            src={config.logoUrl}
            alt="Logo"
            className="h-10 mx-auto sm:mx-0 mb-10"
          />

          {/* Contact Information Section */}
          <div className="flex flex-col sm:flex-row mb-8 space-y-10 sm:space-y-0">
            {/* Phone */}
            <div className="w-full sm:w-2/5 pr-4 mb-4 sm:mb-0">
              <h6 className=" font-semibold mb-4">
                " At Prabalta, we're all about <br /> empowering change and
                lifting <br /> communities. As a non-profit, <br /> we link
                donors with fundraisers, <br /> simplifying the process of
                giving <br /> back and creating real-world impact. "
              </h6>
            </div>

            {/* Email */}
            <div className="w-full sm:w-2/5 pr-4 mb-4 sm:mb-0">
              <h4 className="text-lg font-semibold mb-10">General Enquiry</h4>
              <ul className="list-footer">
                <li className="list-phone">
                  <a
                    href={`tel:${config.phoneNumber}`}
                    className="flex items-center"
                  >
                    <svg
                      className="w-5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#ffffff"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z"
                          fill="#ffffff"
                        ></path>{" "}
                      </g>
                    </svg>
                    <span className="ml-2">{config.phoneNumber}</span>
                  </a>
                </li>
                <li className="list-mail mt-3">
                  <a
                    href={`mailto:${config.email}`}
                    className="flex items-center"
                  >
                    <svg
                      className="w-6"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="1"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g clip-path="url(#clip0_15_17)">
                          <rect width="24" height="24"></rect>
                          <path
                            d="M3 17.5V6.5C3 5.94772 3.44772 5.5 4 5.5H12H20C20.5523 5.5 21 5.94772 21 6.5V17.5C21 18.0523 20.5523 18.5 20 18.5H4C3.44772 18.5 3 18.0523 3 17.5Z"
                            stroke="#ffffff"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M3 6L12 12L21 6"
                            stroke="#ffffff"
                            stroke-linejoin="round"
                          ></path>{" "}
                        </g>{" "}
                        <defs>
                          <clipPath id="clip0_15_17">
                            {" "}
                            <rect
                              width="24"
                              height="24"
                              fill="white"
                            ></rect>{" "}
                          </clipPath>{" "}
                        </defs>{" "}
                      </g>
                    </svg>
                    <span className="ml-2">{config.email}</span>
                  </a>
                </li>
              </ul>
            </div>

            {/* Address */}
            <div className="w-full sm:w-1/5 pr-4 mb-4 sm:mb-0">
              <h4 className="text-lg font-semibold mb-10">Quick Links</h4>
              <ul className="list-footer">
                {/* <li className="mt-3">
                  <a to="/#" onClick={handleCreateFundraiserClick}>
                    Create Fundraiser
                  </a>
                </li> */}
                <Link to="/aboutus-user">
                  <li className="mt-3">About Us</li>
                </Link>
                <Link to="/support-user">
                  <li className="mt-3">Contact Us</li>
                </Link>
                <Link to="/privacy">
                  <li className="mt-3">Privacy Policy</li>
                </Link>
                <Link to="/terms">
                  <li className="mt-3">Terms and Conditions</li>
                </Link>
                <Link to="/LegalPolicy">
                  <li className="mt-3">Legal Policy</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />

      {/* <div className="footer flex justify-center text-white px-4 sm:px-10 2xl:px-24 italic !py-6">
        <div className="container md:flex md:justify-between space-y-4 text-center">
          <p className="text-sm sm:text-base">
            &copy; Copyright 2024 by Prabalta
          </p>
        </div>
      </div> */}
      <div className="footer flex justify-center text-white px-4 sm:px-10 2xl:px-24 italic !py-6">
        <div className="container flex flex-col md:flex-row md:justify-between items-center text-center space-y-4 md:space-y-0">
          <p className="text-sm sm:text-base">
            &copy; Copyright 2024 by Prabalta
          </p>
          {/* <div className="flex space-x-4">
            <a href="/terms" className="underline hover:text-gray-300">
              Terms
            </a>
            <a href="/privacy" className="underline hover:text-gray-300">
              Privacy
            </a>
            <a href="/legal" className="underline hover:text-gray-300">
              Legal
            </a>
          </div> */}
        </div>
      </div>

      <ToastContainer theme="colored" />
    </>
  );
};

export default Footer;
