import React, { useState, useEffect } from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from "yup";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { getRequestWithTokenWithoutData } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";

const schema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email("Invalid email").required("Email is required"),
  subject: yup.string().required("Subject is required"),
  description: yup.string().required("Description is required"),
});

const Support = () => {
  const [config, setConfig] = useState({});
  const [causeValue, setCauseValue] = useState("");
  const [errorCause, setErrorCause] = useState("");
  const [causeList, setCauseList] = useState([]);
  const [verifiedMsg, setVerifiedMsg] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    window.scrollTo(0, 0); // Ensures the page starts from the top
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    getCauseList();
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch("/config.json");
      const data = await response.json();
      setConfig(data);
    } catch (error) {
      console.error("Error loading config.json:", error);
    }
  };

  const [captchaResponse, setCaptchaResponse] = useState(null);

  const handleCaptchaResponseChange = (response) => {
    setCaptchaResponse(response);
    setVerifiedMsg("");
  };

  useEffect(() => {
    if (captchaResponse === null && window.grecaptcha) {
      window.grecaptcha.reset();
    }
  }, [captchaResponse]);


  const getCauseList = async () => {
    try {
      const response = await axios.get(`${TQ_URL}/getAllIssueTypeList`);
      console.log(response.data);
      setCauseList(response.data);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Error:", error.response.data.error);
        //  toast.error(error.response.data.error);
      } else {
        console.error("Error in fetching the issuetype::", error);
        toast.error("Error in fetching the issuetype:");
      }
    }
  };

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const onSubmit = async (data, event) => {
    try {
      const partnerId = config.partnerId;
      console.log(partnerId);
      if (!partnerId) {
        throw new Error("Partner ID is not defined");
      }
      if (!captchaResponse) {
        setVerifiedMsg("Please complete the reCAPTCHA verification.");
        return;
      }

      setVerifiedMsg("");

      data.captchaResponse = captchaResponse;
      console.log("Form data:", data);

      if (!causeValue) {
        setErrorCause("Please select a CauseType");
        return;
      }

      event.preventDefault();
      const response = await fetch(`${TQ_URL_USPROJECT}/createNewTicket`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: toInitCap(data.name),
          email: data.email,
          title: data.subject,
          subject: data.subject,
          causeTypeId: parseInt(causeValue),
          description: data.description,
          partnerId: parseInt(partnerId),
          queryPageIndex: "SUPPORT_PAGE",
          captchaResponse: captchaResponse,
        }),
      });

      const res = await response.json();

      console.log("BODY >>", res);
      console.log(response);

      if (response.status === 200) {
        tikectCreated(res.message);

        // Resetting states after successful form submission
        setCaptchaResponse(null); // Reset reCAPTCHA response
        setCauseValue(""); // Reset dropdown value
        reset(); // Reset form fields
      } else {
        throw new Error(res.message || "Failed to create ticket");
      }
    } catch (error) {
      console.error("Error in creating ticket:", error);
      toast.error("Error in creating ticket");
    }
  };



  const tikectCreated = (msg) => {
    toast.success(msg, {
      position: "top-right",
    });
    reset();
  };

  const onErrors = (error) => {
    console.log("page is hitting ERROR", error);
  };

  const getCauseValue = (event) => {
    const selectedCauseValue = event.target.value;
    console.log("Selected cause value>>", selectedCauseValue);
    setCauseValue(selectedCauseValue);
    setErrorCause(selectedCauseValue ? "" : "Please select a Cause");
  };

  return (
    <>
      <div className="min-h-screen bg-gradient-to-r from-blue-50 to-white">
        <Header />
        <div className="h-[400px] contact-us-banner flex items-center justify-center">
          <h2 className="md:text-4xl md:mt-100 text-xl font-bold text-white">
            Customer Support Service
          </h2>
        </div>

        <div className="container mx-auto md:flex md:flex-col lg:flex-row justify-center items-center my-10">
          {/* How can We help section */}
          <div className="flex-shrink-0 md:w-full lg:w-1/2 self-start md:order-1 lg:order-none">
            <div className="md:p-8 mx-4 md:mx-0 flex flex-col justify-start pb-20">
              <h2 className="txt-style-7 font-family-poppins text-left text-xl">
                How can We help?
              </h2>
              <div className="flex space-y-6 flex-col">
                <div className="flex flex-col md:flex-row md:space-x-10 mt-6 md:mt-0 items-center md:items-start text-center md:text-left">
                  <div className="icon-box flex justify-center md:justify-start">
                    <img
                      src="assets/images/email.png"
                      alt="Email"
                      className="h-14"
                    />
                    <p className="text-sm font-semibold mt-2">Mail</p>
                  </div>
                  <div className="flex flex-col justify-center mt-4 md:mt-0">
                    <h1 className="text-xl font-semibold">{config.email}</h1>
                    <h1 className="text-sm font-normal">
                      Send us an email for any assistance or inquiry.
                    </h1>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row md:space-x-10 mt-6 md:mt-0 items-center md:items-start text-center md:text-left">
                  <div className="icon-box flex justify-center md:justify-start">
                    <img
                      src="assets/images/call.png"
                      alt="Phone"
                      className="h-14"
                    />
                    <p className="text-sm font-semibold mt-2">Phone</p>
                  </div>
                  <div className="flex flex-col justify-center mt-4 md:mt-0">
                    <h1 className="text-xl font-semibold">{config.phoneNumber}</h1>
                    <h1 className="text-sm font-normal">
                      Call us to get in touch with our team.
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Form Section */}
          <div className="flex-grow md:w-full md:order-2 lg:ml-8 m-2">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="max-w-lg mx-auto border shadow-2xl py-10 px-12 rounded-2xl bg-gradient-to-br from-white to-blue-50"
            >
              <h2 className="text-4xl font-extrabold text-center text-blue-700 mb-10">
                Create New Ticket
              </h2>

              <div className="mb-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-800 mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  {...register("name")}
                  className={`w-full p-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none ${errors.name ? "border-red-500" : "border-gray-300"
                    }`}
                  placeholder="Enter your full name"
                />
                <p className="text-red-500 text-xs mt-2">{errors.name?.message}</p>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-800 mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  {...register("email")}
                  className={`w-full p-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none ${errors.email ? "border-red-500" : "border-gray-300"
                    }`}
                  placeholder="Enter your email address"
                />
                <p className="text-red-500 text-xs mt-2">{errors.email?.message}</p>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="issueType"
                  className="block text-sm font-medium text-gray-800 mb-2"
                >
                  Issue Type
                </label>
                <select
                  id="issueType"
                  onChange={getCauseValue}
                  value={causeValue}
                  className={`w-full p-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none ${errorCause ? "border-red-500" : "border-gray-300"
                    }`}
                >
                  <option value="">Select Issue Type</option>
                  {causeList.map((cause) => (
                    <option key={cause.issueId} value={cause.issueId}>
                      {cause.issueName}
                    </option>
                  ))}
                </select>
                <p className="text-red-500 text-xs mt-2">{errorCause}</p>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="subject"
                  className="block text-sm font-medium text-gray-800 mb-2"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  {...register("subject")}
                  className={`w-full p-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none ${errors.subject ? "border-red-500" : "border-gray-300"
                    }`}
                  placeholder="Enter the subject of your issue"
                />
                <p className="text-red-500 text-xs mt-2">{errors.subject?.message}</p>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-800 mb-2"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  rows={5}
                  {...register("description")}
                  className={`w-full p-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none ${errors.description ? "border-red-500" : "border-gray-300"
                    }`}
                  placeholder="Describe your issue in detail"
                ></textarea>
                <p className="text-red-500 text-xs mt-2">{errors.description?.message}</p>
              </div>

              <div className="mb-8">
                <ReCAPTCHA
                  sitekey="6LeaQ4QpAAAAACwPHgef7yz2B3tgaNqtNwsevXi9"
                  onChange={handleCaptchaResponseChange}
                />
                <p className="text-red-500 text-xs mt-2">{verifiedMsg}</p>
              </div>

              <div className="flex justify-center">
                <button
                  type="submit"
                  className="py-3 px-8 bg-[rgb(14,57,143)] text-white font-bold rounded-lg shadow-lg hover:bg-blue-900 transition-transform transform hover:scale-105"
                >
                  Submit
                </button>
              </div>
            </form>
            <ToastContainer theme="colored" />
          </div>

        </div>

        <Footer />
      </div>
    </>
  );
};

export default Support;
