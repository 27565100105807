import React, { useEffect, useState } from "react";
import Pagination from "../Pagination";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { getRequestWithTokenWithoutDataPartner } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import MailCampaignSideBar from "./MailCampaignSideBar";
import CreateMailingList from "./CreateMailingList";
import AddEmailsMailList from "./AddEmailsMailList";
import { useNavigate } from "react-router-dom";
import EmailCampaignTour from "./EmailCampaignTour";
import axios from "axios";

function MailingList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState([]);
  const [modalopen, setModalOpen] = useState(false);
  const [modalEmailOpen, setModalEmailOpen] = useState(false);
  const [mailingList, setMailingList] = useState([]);
  const [mailingListId, setMailingListId] = useState(0);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  let PageSize = 3;

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId;

  const [tourCompletedFlag, setTourCompletedFlag] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(true);

  const moduleId = 4;

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchTourStatus = async () => {
      try {
        const response = await axios.get(
          `${TQ_URL}/user-tour-status/${partnerId}/${moduleId}`
        );
        console.log(response.data.tourCompleted);
        setTourCompletedFlag(response.data.tourCompleted); // Assuming your API returns the status in 'tourCompleted'
        localStorage.setItem(
          "emailTourCompletedFlag",
          response.data.tourCompleted
        );
      } catch (error) {
        console.error("Error fetching tour status:", error);
      } finally {
        setIsTableLoading(false); // Set loading to false after fetching the flag
      }
    };
    fetchTourStatus();
  }, []);

  useEffect(() => {
    getMailingList(currentPage);
  }, []);
  useEffect(() => {
    if (searchText) {
      search(searchText, currentPage);
    } else {
      getMailingList(currentPage);
    }
  }, [currentPage, searchText]); // Ensure searchText is included in dependencies

  useEffect(() => {
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getMailingList = async (page) => {
    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        `${TQ_URL}/getMailingListNames?partnerId=${partnerId}&page=${page}`
      );
      console.log(response);
      setMailingList(response.mailingList);
      setTotalCount(response.totalCount);
      localStorage.setItem("mailingListCount", response.totalCount);
    } catch (error) {
      console.error("Error in fetching the mailing List:", error);
    }
  };

  const currentMailingList = mailingList.slice(
    (currentPage - 1) * PageSize,
    currentPage * PageSize
  );
  //const totalCount = mailingList.length;

  const handleAddEmails = () => {
    console.log("Add email  calls");
  };

  const handleModalEmailClose = () => {
    setModalEmailOpen(false);
    setModalOpen(false);

    getMailingList(currentPage);
  };

  const handleMailListClick = (id) => {
    console.log("view mail list  calls: ", id);
    localStorage.setItem("mailingListId", id);
    // navigate("/emailList", { state: { id } });
    navigate("/emailList");
  };

  function addMailingListModal() {
    setModalOpen(true);
  }

  function addMailingListEmailModal(id) {
    setMailingListId(id);
    localStorage.setItem("mailingListId", id);
    setModalEmailOpen(true);
  }

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const search = async (query, page) => {
    console.log("searchText:", query);

    const url = new URL(`${TQ_URL}/searchMailingList`);
    url.search = new URLSearchParams();
    url.searchParams.set("page", `${page}`);
    url.searchParams.set("keyword", `${query}`);
    url.searchParams.set("partnerId", `${partnerId}`);

    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );

      console.log(response);

      setMailingList(response.dataList);
      setTotalCount(response.totalCount);
    } catch (error) {
      console.error("Error in fetching the searched Mailing List:", error);
      return;
    }
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      await search(searchText, currentPage);
    }
  };

  if (isTableLoading) {
    return null; // Don't render anything while loading
  }

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed h-full md:overflow-y-auto">
          <MailCampaignSideBar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px] overflow-x-hidden">
          {/* <div className="mx-auto shadow-md">
            <div className="md:flex justify-between items-center px-10 py-6">
              <div className="text-center md:text-left">
                <h2 className="text-2xl font-bold">Mailing List</h2>
              </div>
              <div className="flex items-center md:ml-8 w-full md:w-auto">
                <input
                  type="text"
                  placeholder="Search..."
                  className="py-2 pr-8 pl-4 block w-full md:w-96 rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300 md:mr-4"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
                <button
                  id="createMailingListButton"
                  className="btn-style-1 text-white py-1 px-1 md:py-2 md:px-4 rounded   md:block mx-2 max-sm:text-xs"
                  onClick={() => addMailingListModal()}
                >
                  Create Mailing list
                </button>
              </div>
            </div>
          </div> */}

          <div className="mx-auto shadow-md">
            <div className="flex flex-col md:flex-row justify-between items-center container px-10 py-6">
              <div className="text-center md:text-left">
                <h2 className="text-2xl font-bold">Mailing List</h2>
              </div>

              <div className="flex flex-row items-center md:ml-4 mt-4 md:mt-0 w-full md:w-auto">
                <div className="md:mx-2 mx-2 py-2 md:py-0">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="py-2 pr-8 pl-4 block w-full rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </div>

                <div
                  id="createMailingListButton"
                  className="md:mx-2 mx-2 py-2 md:py-0"
                >
                  <button
                    className="btn-style-1 text-white py-2 px-4 rounded"
                    onClick={() => addMailingListModal()}
                  >
                    Create Mailing list
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            id="displayMailingList"
            className="channel-list campaign-blocks w-full mx-auto mt-8"
          >
            {mailingList.length === 0 ? (
              <div className="text-center text-gray-500 font-bold text-xl italic">
                No mailing lists found.
              </div>
            ) : (
              mailingList.map((mailList, index) => (
                <div key={index} className="channel-item flex justify-between">
                  <div className="flex md:mt-4">
                    <div className="ml-[50px] md:mb-4">
                      {mailList.emailCount > 0 ? (
                        <a
                          href="#"
                          className="font-semibold text-gray-800 hover:underline"
                          onClick={() =>
                            handleMailListClick(mailList.mailingListId)
                          }
                        >
                          {mailList.mailListName}
                        </a>
                      ) : (
                        <span className="font-semibold text-gray-800">
                          {mailList.mailListName}
                        </span>
                      )}
                      <span className="text-gray-500 text-sm block">
                        {mailList.createdDate}
                      </span>
                      {mailList.emailCount === 0 && (
                        <span
                          id="addEmailsModal"
                          className="text-gray-500 text-sm block"
                        >
                          No emails{" "}
                          <span>
                            <a
                              className="text-blue-500"
                              href="#"
                              onClick={() =>
                                addMailingListEmailModal(mailList.mailingListId)
                              }
                            >
                              Add Email
                            </a>
                          </span>
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="mr-[50px]">
                    <span className="font-semibold text-gray-500 text-sm block">
                      Emails : {mailList.emailCount}
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>

          <Pagination
            className="pagination-bar mt-12"
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </main>
        {modalopen && <CreateMailingList onClose={handleModalEmailClose} />}
        {modalEmailOpen && (
          <AddEmailsMailList
            // onClose={() => setModalEmailOpen(false)}
            onClose={handleModalEmailClose}
            mailingListId={mailingListId}
          />
        )}
      </div>

      {!tourCompletedFlag && isLargeScreen && (
        <EmailCampaignTour mailListCount={mailingList.length} />
      )}
    </>
  );
}

export default MailingList;
