import React from "react";
import DonorManagementSideBar from "./DonorManagementSideBar";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";

import { Triangle } from "react-loader-spinner";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";

import { ActionIcon, Box } from "@mantine/core";
import TopHeader from "../Home/TopHeader";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
  getRequestWithOutToken,
  getRequestWithTokenWithoutDataPartner,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import ImportExportModal from "./ImportExportModal";
import DonorManagementTop from "./DonorManagementTop";
import MultipleTour from "./MultipleTour";

const DonorList = () => {
  const navigate = useNavigate();

  const [donorData, setDonorData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);

  const [sorting, setSorting] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [value, setValue] = React.useState(0);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [modalopen, setModalOpen] = useState(false);

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const [tourCompletedFlag, setTourCompletedFlag] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId;
  const moduleId = 1;

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchTourStatus = async () => {
      try {
        const response = await axios.get(
          `${TQ_URL}/user-tour-status/${partnerId}/${moduleId}`
        );
        console.log(response.data.tourCompleted);
        setTourCompletedFlag(response.data.tourCompleted); // Assuming your API returns the status in 'tourCompleted'
        localStorage.setItem(
          "donorTourCompletedFlag",
          response.data.tourCompleted
        );
      } catch (error) {
        console.error("Error fetching tour status:", error);
      } finally {
        setIsTableLoading(false); // Set loading to false after fetching the flag
      }
    };
    fetchTourStatus();
  }, []);

  useEffect(() => {
    if (!donorData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    getAllDonorList();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting, //refetch when sorting changes
    activeTab,
    columnFilters,
  ]);

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      console.log("searchText:", searchText);

      const url = new URL(`${TQ_URL}/searchImportedDonorList`);
      url.search = new URLSearchParams();
      url.searchParams.set("start", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("activeTab", `${activeTab}`);
      url.searchParams.set("keyword", `${searchText}`);
      url.searchParams.set("partnerId", `${partnerData.partnerId}`);

      try {
        const response = await getRequestWithTokenWithoutDataPartner(
          url.toString()
        );

        //  setDonationData(response);
        console.log(response);

        console.log(response.dataList);

        const dataWithSerialNumber = response.dataList.map((item, index) => ({
          ...item,
          id: index + 1,
        }));

        console.log(response.dataList);

        setDonorData(dataWithSerialNumber);
        setRowCount(response.totalCount);

        setIsRefetching(false);
      } catch (error) {
        console.error("Error in fetching the searched donor's history:", error);
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const getAllDonorList = async () => {
    console.log(activeTab);
    const url = new URL(`${TQ_URL}/getAllDonorsList`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("activeTab", `${activeTab}`);
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );

      //  setDonationData(response);
      console.log(response);

      console.log(response.dataList);

      const dataWithSerialNumber = response.dataList.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log(response.dataList);

      setDonorData(dataWithSerialNumber);
      setRowCount(response.totalCount);

      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the donor's history:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    //  setIsRefetching(false);
  };
  const formatCurrency = (value) => {
    if (!value) return "$0.00";
    const amount = parseFloat(value);
    if (isNaN(amount)) return "$0.00";
    return `$${amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        sortable: true,
      },
      {
        accessorKey: "donorName",
        header: "Donor name",
        sortable: true,
        Cell: ({ cell }) => toInitCap(cell.getValue()),
      },
      {
        accessorKey: "donorEmail",
        header: "Donor Email",
        sortable: true,
      },
      {
        accessorKey: "contactNo",
        header: "Donor phone Number",
        Cell: ({ row }) => row.original.contactNo || "---",
        sortable: true,
      },
      {
        accessorKey: "donatedAmt",
        header: "Total donated amount",
        Cell: ({ cell }) => <Box>{formatCurrency(cell.getValue()) || 0.0}</Box>,
        sortable: true,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: donorData,

    initialState: { showColumnFilters: false },
    rowCount,

    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const rowData = row.original; // Assuming your data is stored in the original property
        console.log(rowData);
        console.log(activeTab);
        localStorage.setItem("activeTab", activeTab);
        navigate("/donorInfo", { state: { rowData } });
      },
      sx: { cursor: "pointer" },
    }),

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,

    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const handleTabs = (e, value) => {
    console.log(e);
    setValue(e);
    console.log("Value:", value);
    if (e == 0) {
      setActiveTab(1);
      getAllDonorList();
      console.log("data from imported donors");
    } else {
      console.log("data from website donors");
      getAllDonorList();
      setActiveTab(2);
    }
  };

  function importModal() {
    setModalOpen(true);
  }

  function redirectToDonationList() {
    console.log("redirectTodonationList");
    navigate("/allDonationList"); // Adjust the path as needed
  }

  if (isTableLoading) {
    return null; // Don't render anything while loading
  }

  return (
    <>
      {/* <TopHeader /> */}
      <DonorManagementTop />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <DonorManagementSideBar />
        </div>

        <main className="w-full verify-user1">
          <div className="mx-auto shadow-md">
            <div className="flex flex-col lg:flex-row justify-between items-center container px-10 py-4">
              <div className="text-center">
                <h2 className="second-element text-2xl font-bold">
                  Donors List
                </h2>
              </div>
              <div className="flex flex-col lg:flex-row items-center lg:ml-4 mt-4 lg:mt-0 w-full lg:w-auto">
                <div className="w-full lg:w-auto mx-1 py-1 lg:py-0">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="w-full lg:w-auto py-2 pr-8 pl-4 block rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className="flex flex-row sm:flex-row items-center w-full sm:w-full lg:w-auto mt-2 sm:mt-4 lg:mt-0">
                  {" "}
                  {/* Changed to flex-row */}
                  <div
                    id="donor-importDonor"
                    className="w-full sm:w-1/2 lg:w-auto mx-1 py-1 lg:py-0"
                  >
                    <button
                      className="w-full lg:w-auto btn-style-1 text-white py-2 px-4 rounded"
                      onClick={importModal}
                    >
                      Import/Export
                    </button>
                  </div>
                  <div
                    id="donor-addDonor"
                    className="w-full sm:w-1/2 lg:w-auto mx-1 py-1 lg:py-0"
                  >
                    <Link to={`/addDonorManually`}>
                      <button className="w-full lg:w-auto btn-style-1 text-white py-2 px-4 rounded">
                        +Add Donor
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:mx-8 md:py-8 py-6">
            <div sx={{ width: "100%" }}>
              <div style={{ borderBottom: "1px solid #ddd" }}>
                <Tabs
                  value={value}
                  onChange={(event, newValue) => handleTabs(newValue)}
                  aria-label="basic tabs example"
                >
                  <Tab id="donor-step2" label="Imported Donor" value={0} />
                  <Tab id="donor-step3" label="Website Donors" value={1} />
                </Tabs>
              </div>

              <div className="md:mx-2 md:py-4 py-6">
                {value === 0 && <MantineReactTable table={table} />}
                {value === 1 && (
                  <MantineReactTable table={table} /> // Replace `table2` with your data for tab 2
                )}
              </div>
            </div>
            {/* <MantineReactTable table={table} /> */}
          </div>
        </main>
      </div>
      {modalopen && (
        <ImportExportModal
          onClose={() => {
            getAllDonorList();
            setModalOpen(false);
          }}
        />
      )}

      {!tourCompletedFlag && isLargeScreen && <MultipleTour />}
    </>
    //   )}
    // </div>
  );
};

export default DonorList;
