import React, { useRef, useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment/moment";
import { FacebookShareButton } from "react-share";
import { Triangle } from "react-loader-spinner"; // Import Triangle loader

const ReceiptPopup = ({ onClose, rowData, loading }) => {
  const componentRef = useRef(null);

  const [loadingDownload, setLoadingDownload] = useState(false);

  var pageUrl;

  console.log("DATE TODAY>>>", moment(new Date()).format("MMM Do YYYY"));
  console.log("rowData in pop up >>:", rowData);
  console.log("rowData loading >>:", loading);

  const shareOnTwitter = (fundraiserId) => {
    console.log("sharing the content on twitter");
    const pageBaseUrl = "www.prabalta.org/fundraiser";

    if (fundraiserId == null) pageUrl = "www.prabalta.org";
    else pageUrl = `${pageBaseUrl}/${fundraiserId}`;
    const shareText = encodeURIComponent(
      `I have Successfully donated $${rowData.amount.toLocaleString(
        "en-US"
      )} to ${
        rowData.fundraiserCausename
      }. Check out this fundraiser on website!`
    );
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareText}&url=${encodeURIComponent(
      pageUrl
    )}`;
    window.open(twitterShareUrl, "_blank");
  };

  const shareOnFacebook = (fundraiserId) => {
    const pageBaseUrl = "http://www.prabalta.org/fundraiser";
    if (fundraiserId == null) pageUrl = "www.prabalta.org";
    else pageUrl = `${pageBaseUrl}/${fundraiserId}`;
    const shareText = encodeURIComponent(
      `I have Successfully donated $${rowData.amount.toLocaleString(
        "en-US"
      )} to ${
        rowData.fundraiserCausename
      }. Check out this fundraiser on website!`
    );

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      pageUrl
    )}&quote=${encodeURIComponent(shareText)}`;

    window.open(facebookLink, "_blank");
  };

  const handleExportPDF = () => {
    setLoadingDownload(true);
    const pdf = new jsPDF("p", "mm", "a4");
    const divToExclude = document.getElementById("share");
    // Hide the div to exclude
    const originalDisplayStyle = divToExclude.style.display;
    divToExclude.style.display = "none";

    // Get all table cells in Column 3
    const columnThreeCells = document.querySelectorAll(
      '[data-column="excludeSection"]'
    );

    // Hide Column 3 content for PDF export by adding inline style
    columnThreeCells.forEach((cell) => {
      cell.style.display = "none";
    });

    if (componentRef.current) {
      html2canvas(componentRef.current, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pageWidth = 210;
        const pageHeight = 297;
        const padding = 10;
        const componentWidth = canvas.width;
        const componentHeight = canvas.height;
        const scaleFactor = Math.min(
          (pageWidth - 2 * padding) / componentWidth,
          (pageHeight - 2 * padding) / componentHeight
        );

        const x = padding;
        const y = padding;

        // Add the image as a PNG to the PDF with padding
        pdf.addImage(
          imgData,
          "PNG",
          x,
          y,
          componentWidth * scaleFactor,
          componentHeight * scaleFactor
        );

        // Show Column 3 content after generating the PDF
        columnThreeCells.forEach((cell) => {
          cell.style.display = "";
        });

        divToExclude.style.display = originalDisplayStyle;

        const d = moment(new Date()).format("MMM Do YYYY");
        // Save or display the PDF
        pdf.save(`Donation Receipt_${d}.pdf`);
        setLoadingDownload(false);
      });
    }
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-scroll">
      <div className="fade-in-bottom">
        <div
          className="bg-white border-4 border-black max-w-2xl w-full  lg:mx-0 p-4 lg:p-6 how-we-work-modal relative"
          ref={componentRef}
        >
          <button
            onClick={onClose}
            className="mt-2 p-2 cursor-pointer absolute right-2 top-2"
          >
            <svg
              className="w-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                fill="#0F0F0F"
              ></path>
            </svg>
          </button>

          {loading ? (
            // <div className="flex justify-center items-center h-48">
            //   <p className="text-lg font-semibold">Loading...</p>
            // </div>
            <div className="fixed top-0 left-0 right-0 flex items-center justify-center h-full bg-black bg-opacity-50 z-50">
              <Triangle
                height="120"
                width="120"
                color="blue"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                visible={true}
              />
            </div>
          ) : (
            <div>
              <div className="mb-4 text-center">
                <img
                  src="/assets/images/main-logo.svg"
                  alt="Logo"
                  className="h-10 mx-auto"
                />
              </div>

              <div className="text-center">
                <img
                  src="/assets/images/tick-png.png"
                  className="w-14 mx-auto"
                />
                <h2 className="text-2xl font-bold mb-4">
                  A great big thank you!
                </h2>
                <p className="text-sm font-semibold mb-4 px-4 sm:px-14">
                  Your donation will go directly to supporting local libraries
                  in our communities. We've sent your donation receipt to{" "}
                  <b>{rowData.email}</b>.
                </p>
                <div id="share" className="bg-gray-100 border py-6 mt-3">
                  <p className="text-xs font-semibold mb-4">
                    Tell the world about your generosity and help spread the
                    word!
                  </p>
                  <div className="flex justify-between space-x-4 px-4">
                    <button
                      className="share-fb-div flex items-center space-x-2"
                      onClick={() => {
                        shareOnFacebook(rowData.fundraiserId);
                      }}
                    >
                      Share on Facebook
                      <svg
                        viewBox="-5 0 20 20"
                        className="w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                      >
                        <path d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"></path>
                      </svg>
                    </button>
                    <button
                      className="share-twitter-div flex items-center space-x-2"
                      onClick={() => {
                        shareOnTwitter(rowData.fundraiserId);
                      }}
                    >
                      Share on Twitter
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-8"
                        viewBox="0 0 72 72"
                      >
                        <path d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div className="px-4 lg:px-16 pt-6">
                <div className="border p-4 space-y-3 rounded-lg">
                  <div className="flex justify-between items-center">
                    <div className="flex">
                      <p className="text-sm text-gray-500 font-semibold">
                        DONOR NAME :
                      </p>
                    </div>
                    <p className="text-sm text-gray-500 font-semibold">
                      {rowData.donorName}
                    </p>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="flex">
                      <p className="text-sm text-gray-500 font-semibold">
                        EMAIL ADDRESS :
                      </p>
                    </div>
                    <p className="text-sm text-gray-500 font-semibold">
                      {rowData.email}
                    </p>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="flex">
                      <p className="text-sm text-gray-500 font-semibold">
                        BILLING ADDRESS:
                      </p>
                    </div>
                    <p className="text-sm text-gray-500 font-semibold text-right">
                      {rowData.donorAddress !== null
                        ? rowData.address
                        : "No Address Provided"}
                    </p>
                  </div>
                </div>

                <div className="border rounded-lg mt-5">
                  <div className="p-4 space-y-3">
                    <div className="flex justify-between items-center">
                      <p className="text-sm text-gray-500 font-semibold">
                        PAYMENT METHOD:
                      </p>
                      <p className="text-sm text-gray-500 font-semibold">
                        {rowData.paymentMethod}
                      </p>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className="text-sm text-gray-500 font-semibold">
                        PAYMENT STATUS :
                      </p>
                      <p className="text-sm text-gray-500 font-semibold">
                        {rowData.donationStatus}
                      </p>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className="text-sm text-gray-500 font-semibold">
                        DONATION AMOUNT:
                      </p>
                      <p className="text-sm text-gray-500 font-semibold text-right">
                        ${rowData.amount.toLocaleString("en-US")}
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-between items-center bg-gray-200 p-4">
                    <p className="text-sm text-gray-800 font-semibold">
                      DONATION TOTAL:
                    </p>
                    <p className="text-sm text-black font-bold text-right">
                      ${rowData.amount.toLocaleString("en-US")}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="flex justify-center mt-6"
                data-column="excludeSection"
              >
                <button
                  className="w-full lg:w-1/3 text-white p-2 rounded-sm btn-style-1 font-semibold"
                  onClick={handleExportPDF}
                  disabled={loading}
                >
                  {loadingDownload ? "Downloading..." : "Download Receipt"}
                </button>
              </div>

              {loading && (
                <div className="fixed top-0 left-0 right-0 flex items-center justify-center h-full bg-black bg-opacity-50 z-50">
                  <Triangle
                    height="120"
                    width="120"
                    color="blue"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              )}
              <h2 className="text-sm font-semibold text-gray-500 mb-4 text-center py-4 bg-gray-100 border-t-2 flex justify-center items-center mt-2">
                <svg
                  className="w-3 me-3"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.25 10.0546V8C5.25 4.27208 8.27208 1.25 12 1.25C15.7279 1.25 18.75 4.27208 18.75 8V10.0546C19.8648 10.1379 20.5907 10.348 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.40931 10.348 4.13525 10.1379 5.25 10.0546ZM6.75 8C6.75 5.10051 9.10051 2.75 12 2.75C14.8995 2.75 17.25 5.10051 17.25 8V10.0036C16.867 10 16.4515 10 16 10H8C7.54849 10 7.13301 10 6.75 10.0036V8Z"
                      fill="#7a7a7a"
                    ></path>{" "}
                  </g>
                </svg>
                Secure Donation
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>

    // <div className="fixed inset-0 bg-black px-2 bg-opacity-50 flex items-center justify-center overflow-hidden">
    //   <div className="fade-in-bottom">
    //     <div
    //       className="bg-white border-4 border-black max-w-2xl w-full  lg:mx-0 p-4 lg:p-6 how-we-work-modal relative max-h-[90vh] overflow-y-auto"
    //       ref={componentRef}
    //     >
    //       <button
    //         onClick={onClose}
    //         className="mt-2 p-2 cursor-pointer absolute right-2 top-2"
    //       >
    //         <svg
    //           className="w-8"
    //           viewBox="0 0 24 24"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path
    //             d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
    //             fill="#0F0F0F"
    //           ></path>
    //         </svg>
    //       </button>

    //       <div className="mb-4 text-center">
    //         <img
    //           src="/assets/images/main-logo.svg"
    //           alt="Logo"
    //           className="h-10 mx-auto"
    //         />
    //       </div>

    //       <div className="text-center">
    //         <img src="/assets/images/tick-png.png" className="w-14 mx-auto" />
    //         <h2 className="text-2xl font-bold mb-4">A great big thank you!</h2>
    //         <p className="text-sm font-semibold mb-4 px-4 sm:px-14">
    //           Your donation will go directly to supporting local libraries in
    //           our communities. We've sent your donation receipt to{" "}
    //           <b>{rowData.email}</b>.
    //         </p>
    //         <div id="share" className="bg-gray-100 border py-6 mt-3">
    //           <p className="text-xs font-semibold mb-4">
    //             Tell the world about your generosity and help spread the word!
    //           </p>
    //           <div className="flex justify-between space-x-4 px-4">
    //             <button
    //               className="share-fb-div flex items-center space-x-2"
    //               onClick={() => {
    //                 shareOnFacebook(rowData.fundraiserId);
    //               }}
    //             >
    //               Share on Facebook
    //               <svg
    //                 viewBox="-5 0 20 20"
    //                 className="w-6"
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 fill="#000000"
    //               >
    //                 <path d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"></path>
    //               </svg>
    //             </button>
    //             <button
    //               className="share-twitter-div flex items-center space-x-2"
    //               onClick={() => {
    //                 shareOnTwitter(rowData.fundraiserId);
    //               }}
    //             >
    //               Share on Twitter
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 className="w-8"
    //                 viewBox="0 0 72 72"
    //               >
    //                 <path d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"></path>
    //               </svg>
    //             </button>
    //           </div>
    //         </div>
    //       </div>

    //       <div className="px-4 lg:px-16 pt-6">
    //         <div className="border p-4 space-y-3 rounded-lg">
    //           <div className="flex justify-between items-center">
    //             <div className="flex">
    //               <p className="text-sm text-gray-500 font-semibold">
    //                 DONOR NAME :
    //               </p>
    //             </div>
    //             <p className="text-sm text-gray-500 font-semibold">
    //               {rowData.donorName}
    //             </p>
    //           </div>

    //           <div className="flex justify-between items-center">
    //             <div className="flex">
    //               <p className="text-sm text-gray-500 font-semibold">
    //                 EMAIL ADDRESS :
    //               </p>
    //             </div>
    //             <p className="text-sm text-gray-500 font-semibold">
    //               {rowData.email}
    //             </p>
    //           </div>

    //           <div className="flex justify-between items-center">
    //             <div className="flex">
    //               <p className="text-sm text-gray-500 font-semibold">
    //                 BILLING ADDRESS:
    //               </p>
    //             </div>
    //             <p className="text-sm text-gray-500 font-semibold text-right">
    //               {rowData.donorAddress !== null
    //                 ? rowData.address
    //                 : "No Address Provided"}
    //             </p>
    //           </div>
    //         </div>

    //         <div className="border rounded-lg mt-5">
    //           <div className="p-4 space-y-3">
    //             <div className="flex justify-between items-center">
    //               <p className="text-sm text-gray-500 font-semibold">
    //                 PAYMENT METHOD:
    //               </p>
    //               <p className="text-sm text-gray-500 font-semibold">
    //                 {rowData.paymentMethod}
    //               </p>
    //             </div>
    //             <div className="flex justify-between items-center">
    //               <p className="text-sm text-gray-500 font-semibold">
    //                 PAYMENT STATUS :
    //               </p>
    //               <p className="text-sm text-gray-500 font-semibold">
    //                 {rowData.donationStatus}
    //               </p>
    //             </div>
    //             <div className="flex justify-between items-center">
    //               <p className="text-sm text-gray-500 font-semibold">
    //                 DONATION AMOUNT:
    //               </p>
    //               <p className="text-sm text-gray-500 font-semibold text-right">
    //                 ${rowData.amount.toLocaleString("en-US")}
    //               </p>
    //             </div>
    //           </div>

    //           <div className="flex justify-between items-center bg-gray-200 p-4">
    //             <p className="text-sm text-gray-800 font-semibold">
    //               DONATION TOTAL:
    //             </p>
    //             <p className="text-sm text-black font-bold text-right">
    //               ${rowData.amount.toLocaleString("en-US")}
    //             </p>
    //           </div>
    //         </div>
    //       </div>

    //       <div className="flex justify-center mt-6">
    //         <button
    //           className="w-full text-center bg-gray-700 text-white rounded-md px-4 py-2"
    //           onClick={handleExportPDF}
    //         >
    //           Download Receipt
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ReceiptPopup;
