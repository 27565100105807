import React from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

import { getRequestWithTokenWithData, getRequestWithTokenWithoutDataPartner } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import MailCampaignSideBar from "./MailCampaignSideBar";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import AddEmailsMailList from "./AddEmailsMailList";
import { ToastContainer, toast } from "react-toastify";

import { IconEdit, IconEye, IconTrash } from "@tabler/icons-react";
import EditmailList from "./EditmailList";

const EmailList = () => {
  const navigate = useNavigate();

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const { state } = useLocation();
  const [modalopen, setModalOpen] = useState(false);

  //const mailingListId = state?.id;
  const mailingListId = localStorage.getItem("mailingListId");
  console.log(mailingListId);

  const [emailData, setEmailData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);

  const [sorting, setSorting] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [modalopenForEdit, setModalOpenForEdit] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!mailingListId || mailingListId == "undefined") {
      console.error("mailingListId is not available");
      return;
    }
    if (!emailData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getAllEmailList();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting, //refetch when sorting changes
    columnFilters,
  ]);

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      console.log("searchText:", searchText);

      const url = new URL(`${TQ_URL}/searchMailingEmailList`);
      url.search = new URLSearchParams();
      url.searchParams.set("start", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("mailingListId", `${mailingListId}`);

      url.searchParams.set("keyword", `${searchText}`);

      try {
        const response = await getRequestWithTokenWithoutDataPartner(
          url.toString()
        );

        //  setDonationData(response);
        console.log(response);

        console.log(response.dataList);

        const dataWithSerialNumber = response.dataList.map((item, index) => ({
          ...item,
          id: index + 1,
        }));

        console.log(response.dataList);

        setEmailData(dataWithSerialNumber);
        setRowCount(response.totalCount);

        setIsRefetching(false);
      } catch (error) {
        console.error("Error in fetching the searched email list:", error);
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
  };
  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleModalEmailClose = () => {
    setModalOpen(false);

    getAllEmailList();
  };

  const getAllEmailList = async () => {
    const url = new URL(`${TQ_URL}/getAllEmailList`);
    url.search = new URLSearchParams();
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("mailingListId", `${mailingListId}`);
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    //localStorage.setItem("mailingListId", mailingListId);
    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );

      //  setDonationData(response);
      console.log(response);

      console.log(response.dataList);

      const dataWithSerialNumber = response.dataList.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log(response.dataList);

      setEmailData(dataWithSerialNumber);
      setRowCount(response.totalCount);

      setIsRefetching(false);
    } catch (error) {
      console.error("Error in fetching the donor's history:", error);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    //  setIsRefetching(false);
  };

  const handleEdit = (row) => {
    // Implement your edit logic here
    console.log("Edit clicked for row:", row);
    setSelectedEmail(row);
    setModalOpenForEdit(true)
    // Example: Open a modal or navigate to an edit page
  };

  const handleDelete = async (row,status) => {
    // Implement your delete logic here
    const mailingListId = localStorage.getItem("mailingListId");
    let request = {
      email: row.email,
      maillingListId: mailingListId,
      status: status
    }
    console.log("Our request ----->", request);

    try {
      const response = await getRequestWithTokenWithData(
        `${TQ_URL}/deleteMailFromMailingList`, request
      );
      console.log("------------>",response.success);
      
      if (response.success == "Email is activate") {
        toast.success(response.success);

        // setTimeout(() => {
        //   setTimeout(onClose(), 2000);
        // }, 3000);
      } else {
        toast.error(response.success);
      }
    } catch (error) {
      console.error("Error adding fund:", error);
      toast.error("An error occurred while adding email.");
    }
    finally{
      getAllEmailList();
    }

    // Example: Call an API to delete the item
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "userName",
        header: "User name",
        sortable: true,
      },
      {
        accessorKey: "email",
        header: "User Email",
        sortable: true,
      },
      {
        accessorKey: "addedOn",
        header: "Added On",
        Cell: ({ row }) => row.original.addedOn || "---",
        sortable: true,
      },
      {
        accessorKey: "action",
        header: "Action",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              onClick={() => handleEdit(row.original)}
              className="bg-blue-300 text-gray-500 border border-blue-300 hover:bg-white hover:text-blue-300 hover:border-blue-300 px-4 py-1 rounded"
              title="Edit"
            >
             <b> Edit</b>
            </button>
            {row.original.status === 0 ? (
            <button
              onClick={() => handleDelete(row.original,1)}
              className="bg-red-300 text-gray-500 border border-red-300 hover:bg-white hover:text-red-300 hover:border-red-300 px-4 py-1 rounded"
              title="Deactive"
            >
             <b> Deactive</b>
            </button>
          ) : (
            <button
              onClick={() => handleDelete(row.original,0)}
              className="bg-green-300 text-gray-500 border border-green-300 hover:bg-white hover:text-green-300 hover:border-green-300 px-4 py-1 rounded"
              title="Active"
            >
              <b>&nbsp; Active   &nbsp; </b>
            </button>
          )}
          </div>
        ),
        sortable: false,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: emailData,

    initialState: { showColumnFilters: false },
    rowCount,

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,

    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  function addMailingListModal() {
    setModalOpen(true);
  }

  const handleModalEditClose = () => {
    setModalOpenForEdit(false);
    getAllEmailList();  // Fetch updated email list when edit modal closes
  };

  return (
    <>
      {/* <TopHeader /> */}
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed h-full md:overflow-y-auto">
          <MailCampaignSideBar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px] overflow-x-hidden">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Email List</h2>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row items-center md:mt-4">
            <div className="md:mx-8 rounded  p-1 flex  w-full  justify-between md:flex-grow-0 md:w-6/12">
              <input
                type="text"
                placeholder="Search..."
                className="py-2 pr-8 pl-4 block w-full rounded-lg bg-gray-200 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                onChange={handleChange}
                onKeyDown={handleKeyPress}
              />
            </div>

            <div className="flex mx-8 my-2">
              <button
                className="btn-style-1 text-white py-1 px-1 md:py-2  md:px-4 rounded  md:block mx-2"
                onClick={() => addMailingListModal()}
              >
                +Add Email
              </button>
            </div>
          </div>
          <div className="md:mx-8 md:py-8 py-6">
            <MantineReactTable table={table} />
          </div>
        </main>

        {modalopen && (
          <AddEmailsMailList
            onClose={handleModalEmailClose}
          // mailingListId={mailingListId}
          />
        )}

{modalopenForEdit && <EditmailList onClose={handleModalEditClose} emailData={selectedEmail} />}
      </div>
      <ToastContainer theme="colored" />
    </>
  );
};

export default EmailList;
