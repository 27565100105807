import React, { useState, useRef } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { TQ_URL } from "../../utility/baseUrl";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import Tooltip from "../Common/Tooltip";
import { useEffect } from "react";
import {
  postRequestWithoutTokenWithoutData,
  getRequestWithTokenWithData,
} from "../../utility/apiRequest";

const PaypalStatus = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("Complete");

  const { session_id } = useParams();
  const isMounted = useRef(true);
  const [config, setConfig] = useState({}); // State to store config data
  const [pageBaseUrl, setPageBaseUrl] = useState("");

  const location = useLocation();
  const {
    fundraiserId,
    amount,
    fullName,
    email,
    phone,
    anonymously,
    fundraiserName,
  } = location.state || {};

  const navigate = useNavigate();

  console.log(session_id);

  const urlParams = new URLSearchParams(window.location.search);
  const extractedSessionId = urlParams.get("session_id");

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        const baseUrl = data.redirect_uri.replace(
          "oauth2/redirect",
          "fundraiser"
        );
        setPageBaseUrl(baseUrl);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  console.log(session_id);

  // useEffect(() => {
  //   const storedData = localStorage.getItem("donationPaypalData");

  //   if (storedData) {
  //     const parsedData = JSON.parse(storedData); // Parse the JSON string

  //     setFundraiserName(parsedData.fundraiserName);
  //     setFundraiserId(parsedData.fundraiserId);
  //     setDonarName(parsedData.fullName);
  //   }
  // }, []);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleResponse = () => {
    const isAuthenticated = localStorage.getItem("accessToken") !== null;

    let url = `/fundraiser/${fundraiserId}`;
    navigate(url);
    //}
  };

  const shareOnWhatsApp = (fundraiserId) => {
    const pageUrl = `${pageBaseUrl}` + "/fundraiser/" + `${fundraiserId}`;

    // const shareText = "Check out this fundraiser on my website!";

    const shareText = encodeURIComponent(
      `I have Successfully donated ${amount} to ${fundraiserName}. Check out this fundraiser on website!`
    );

    const whatsappLink = `https://api.whatsapp.com/send?text=${`${shareText} ${pageUrl}`}`;

    window.open(whatsappLink, "_blank");
  };

  const shareOnTwitter = (fundraiserId) => {
    console.log("sharing the content on twitter");
    const pageUrl = `${pageBaseUrl}` + "/fundraiser/" + `${fundraiserId}`;
    const shareText = encodeURIComponent(
      `I have Successfully donated ${amount} to ${fundraiserName}. Check out this fundraiser on website!`
    );

    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareText}&url=${pageUrl}`;

    window.open(twitterShareUrl, "_blank");
  };

  const shareOnFacebook = (fundraiserId) => {
    const pageUrl = `${pageBaseUrl}/fundraiser/${fundraiserId}`;

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`;

    window.open(facebookLink, "_blank");
  };

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <div className="flex flex-col md:flex-row min-h-screen ">
      {/* Left Section */}
      <div className="md:flex-1 bg-cover bg-center relative fade-in-left verify-addr-bg">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center md:px-20 px-5 ">
          <div className="w-full">
            <div className="success-message">
              <h1 className="txt-style-5">{toInitCap(paymentStatus)} !</h1>

              <h1 className="txt-style-6">
                {toInitCap(fullName)} Your Donation to{" "}
                {toInitCap(fundraiserName)} is successful.
              </h1>
              <p className="para-style-1 text-center">
                Thank you. We are incredibly grateful for your generous donation
                to the {fundraiserName}. We look forward to a continued
                partnership with you to make a lasting impact.
              </p>
              <div className="flex  justify-between mt-5">
                {/* <Link to="/donorshistory"> */}

                <button
                  className=" text-white px-10 py-2 rounded-sm  btn-style-1 ms-5"
                  onClick={handleResponse}
                  disabled={paymentStatus == "Pending" ? true : false}
                >
                  Continue
                </button>

                {/* </Link> */}
              </div>

              <div className=" flex justify-center items-center space-x-3 social-icon-box mt-8">
                {/* twitter Icon */}

                <Tooltip text="Share on Twitter">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 72 72"
                    viewBox="0 0 72 72"
                    className="w-8"
                    id="twitter-x"
                    onClick={() => {
                      shareOnTwitter(fundraiserId);
                    }}
                  >
                    <switch>
                      <g>
                        <path
                          d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66
			h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"
                        ></path>
                      </g>
                    </switch>
                  </svg>
                </Tooltip>

                <div>
                  <Tooltip text="Share on WhatsApp">
                    <svg
                      viewBox="0 0 48 48"
                      className="w-6"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                      onClick={() => {
                        shareOnWhatsApp(fundraiserId);
                      }}
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <title>Whatsapp-color</title>{" "}
                        <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                        <g
                          id="Icons"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          {" "}
                          <g
                            id="Color-"
                            transform="translate(-700.000000, -360.000000)"
                            fill="#67C15E"
                          >
                            {" "}
                            <path
                              d="M723.993033,360 C710.762252,360 700,370.765287 700,383.999801 C700,389.248451 701.692661,394.116025 704.570026,398.066947 L701.579605,406.983798 L710.804449,404.035539 C714.598605,406.546975 719.126434,408 724.006967,408 C737.237748,408 748,397.234315 748,384.000199 C748,370.765685 737.237748,360.000398 724.006967,360.000398 L723.993033,360.000398 L723.993033,360 Z M717.29285,372.190836 C716.827488,371.07628 716.474784,371.034071 715.769774,371.005401 C715.529728,370.991464 715.262214,370.977527 714.96564,370.977527 C714.04845,370.977527 713.089462,371.245514 712.511043,371.838033 C711.806033,372.557577 710.056843,374.23638 710.056843,377.679202 C710.056843,381.122023 712.567571,384.451756 712.905944,384.917648 C713.258648,385.382743 717.800808,392.55031 724.853297,395.471492 C730.368379,397.757149 732.00491,397.545307 733.260074,397.27732 C735.093658,396.882308 737.393002,395.527239 737.971421,393.891043 C738.54984,392.25405 738.54984,390.857171 738.380255,390.560912 C738.211068,390.264652 737.745308,390.095816 737.040298,389.742615 C736.335288,389.389811 732.90737,387.696673 732.25849,387.470894 C731.623543,387.231179 731.017259,387.315995 730.537963,387.99333 C729.860819,388.938653 729.198006,389.89831 728.661785,390.476494 C728.238619,390.928051 727.547144,390.984595 726.969123,390.744481 C726.193254,390.420348 724.021298,389.657798 721.340985,387.273388 C719.267356,385.42535 717.856938,383.125756 717.448104,382.434484 C717.038871,381.729275 717.405907,381.319529 717.729948,380.938852 C718.082653,380.501232 718.421026,380.191036 718.77373,379.781688 C719.126434,379.372738 719.323884,379.160897 719.549599,378.681068 C719.789645,378.215575 719.62006,377.735746 719.450874,377.382942 C719.281687,377.030139 717.871269,373.587317 717.29285,372.190836 Z"
                              id="Whatsapp"
                            >
                              {" "}
                            </path>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </Tooltip>
                </div>

                {/* Facebook Icon */}

                <Tooltip text="Share on Facebook">
                  <svg
                    viewBox="-5 0 20 20"
                    className="w-6"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    fill="#000000"
                    onClick={() => {
                      shareOnFacebook(fundraiserId);
                    }}
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>facebook [#176]</title>{" "}
                      <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        {" "}
                        <g
                          id="Dribbble-Light-Preview"
                          transform="translate(-385.000000, -7399.000000)"
                          fill="#4267b2"
                        >
                          {" "}
                          <g
                            id="icons"
                            transform="translate(56.000000, 160.000000)"
                          >
                            {" "}
                            <path
                              d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                              id="facebook-[#176]"
                            >
                              {" "}
                            </path>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaypalStatus;
