import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";


const HeaderNew = () => {
  const location = useLocation();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const navigate = useNavigate();
 
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setSticky(offset > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const isActiveRoute = (path) => {
    return location.pathname === path;
  };

  const [userData, setUserData] = useState(null);
  const isUserLoggedIn = () => {
    return userData !== null;
  };

  const logLocalData = () => {
    const localStorageData = localStorage.getItem("userData");
    console.log("Local Storage Data:", JSON.parse(localStorageData));

    const localStorageAddressData = localStorage.getItem("userAddressDetails");
    console.log(
      "Local Storage Address Data:",
      JSON.parse(localStorageAddressData)
    );

    setUserData(JSON.parse(localStorageData));
  };

  useEffect(() => {
    logLocalData();
  }, []);

 

  const handleCreateFundraiserClick = () => {
    if (isUserLoggedIn() && localStorage.getItem("accessToken") !== null) {
      navigate("/createfundraiser");
    } else {
      navigate("/login");
    }
    setIsMobileMenuOpen(false);
  };

  const handleDonateNowClick = () => {
    if (isUserLoggedIn() && localStorage.getItem("accessToken") !== null) {
      navigate("/donate");
    } else {
      navigate("/login");
    }
    setIsMobileMenuOpen(false);
  };

 

  return (
    <div
      className={`w-full z-50 bg-white ${
        isSticky ? "sticky-header slide-in-up" : ""
      }`}
    >
      <div className="bg-white p-2 flex flex-col md:flex-row justify-between items-center px-4 md:px-10 2xl:px-20">
        <div className="mb-4 md:mb-0 flex justify-between w-full md:w-2/12 ">
          {/* <Link to="/"> */}
          <img
            src="/assets/images/main-logo.svg"
            alt="Logo"
            className="h-10  mx-auto sm:mx-0"
          />
          {/* </Link> */}
          {/* Donate Now Button */}
          {/* <Link to='/donate'>
                        <button className="btn-style-1 text-white text-sm py-2 px-6 rounded block md:hidden">
                            Donate
                        </button>
                    </Link> */}

          <a
            to="#"
            onClick={handleDonateNowClick}
            className="btn-style-1 text-white text-sm py-2 px-6 rounded block md:hidden"
          >
            Donate
          </a>
          {/* Hamburger Toggle for Mobile */}
          <div className="md:hidden flex items-center">
            <button
              className="text-dark-500 font-semibold focus:outline-none"
              onClick={toggleMobileMenu}
            >
              <FiMenu size={24} />
            </button>
          </div>
        </div>

        {isMobileMenuOpen && (
          <div className="md:hidden fixed inset-0 bg-opacity-75 bg-gray-800 z-50">
            <AnimatePresence>
              <motion.div
                key="modal"
                initial={{ x: "100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: "-100%", opacity: 0 }}
                transition={{ duration: 0.4 }}
                className="absolute right-0 bg-white border rounded shadow-md w-2/3 px-5 py-2 h-full"
              >
                <button
                  className="text-dark-500 font-semibold focus:outline-none absolute top-4 right-4"
                  onClick={toggleMobileMenu}
                >
                  <FiX size={24} />
                </button>

                <ul className="list-none p-2 text-black mt-6">
                  <li className="py-1 mb-4">Home</li>
                  <li className="py-1 mb-4">About us</li>
                  <li className="py-1 mb-4">Services</li>
                  <li className="py-1 mb-4">Contact Us</li>
                </ul>
              </motion.div>
            </AnimatePresence>
          </div>
        )}

        {/* Search Bar */}
        <div className="rounded  p-1 flex w-full  justify-between md:flex-grow-0 md:w-2/12">
          {/* <input
            type="text"
            placeholder="Search...(Use enter key to search)"
            className="border outline-none py-2 ps-2 w-full md:w-4/5 header-search-box"
            onChange={handleChange}
            onKeyDown={handleKeyPress}
          /> */}
        </div>

        {/* Navigation Menus */}
        <div
          className={`text-sm 2xl:text-base flex space-x-4 md:space-x-8 md:flex ${
            isDropdownOpen ? "flex-col" : "hidden"
          }`}
        >
          <Link
            to="/"
            className={`text-dark-500 font-semibold nav-item ${
              isActiveRoute("/") && "active-link"
            }`}
          >
            Home
          </Link>
          {/* <Link
            to="/ngoServices"
            className={`text-dark-500 font-semibold nav-item ${
              isActiveRoute("/") && "active-link"
            }`}
          >
            Ngo Services
          </Link> */}
          <a
            to="/"
            onClick={handleCreateFundraiserClick}
            className={`text-dark-500 cursor-pointer font-semibold nav-item ${
              isActiveRoute("/") && "active-link"
            }`}
          >
            About us
          </a>

          <Link
            to="/"
            className={`text-dark-500 font-semibold nav-item ${
              isActiveRoute("/") && "active-link"
            }`}
          >
            Services
          </Link>

          <Link
            to="/support"
            className={`text-dark-500 font-semibold nav-item ${
              isActiveRoute("/support") && "active-link"
            }`}
          >
            Contact us
          </Link>
        </div>

        <a
          to="#"
          onClick={handleDonateNowClick}
          className="btn-style-1 text-white py-2 px-4 rounded hidden md:block"
          style={{ borderRadius: "20px" }}
        >
          Get Started
        </a>
      </div>
     
    </div>
  );
};

export default HeaderNew;
