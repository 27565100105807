import React, { useRef, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import { getRequestWithTokenWithData } from "../../utility/apiRequest";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import EditFundraiser from "./EditFundraiser";
import { Button, Modal } from "@mantine/core";
import { Triangle } from "react-loader-spinner";

const Fundraiser = ({ onComplete }) => {
  const [fund, setFund] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const [editPopupOpen, setEditPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to track if the admin-disabled fundraiser modal is open
  const [isFundRaisedModalOpen, setIsFundRaisedModalOpen] = useState(false); // State to track if the fund raised modal is open
  const [isStatusDisabledModalOpen, setIsStatusDisabledModalOpen] =
    useState(false); // State to track if the status is 4 modal is open
  const [isLoading, setIsLoading] = useState(true);
  const [isTargetDateModalOpen, setIsTargetDateModalOpen] = useState(false);
  const [disableDonateButton, setDisableDonateButton] = useState(false); // New state to manage button disabling



  const videoRef = useRef(null);
  const isMounted = useRef(true);

  const handleSubmit = () => {
    // Convert the target date to a local date and set it to 11:59:59 PM
    const targetDate = parseDateAsLocal(fund.targetDate);
    targetDate.setHours(23, 59, 59, 999); // Set time to 11:59:59 PM
  
    // Get the current date in the browser's timezone
    const currentDate = new Date();
  
    // Check if the target date has passed
    if (targetDate < currentDate) {
      // Disable the button immediately if the target date has passed
      setDisableDonateButton(true);
      toast.warn("The fundraiser has ended and donations are no longer accepted.");
      return;
    }
  
    // Proceed if the target date has not passed
    const remainingAmount = fund.goalAmt - fund.amtAchieved;
    const values = {
      fundraiserId: id,
      fundraiserName: fund.fundraiserName,
      fundraiserTitle: fund.title,
      remainingAmount, // Pass the remaining amount
    };
  
    navigate("/transactiondetails-user", { state: values });
  };
  
  

  useEffect(() => {
    onComplete();
  }, [onComplete]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequestWithTokenWithData(
          `${TQ_URL_USPROJECT}/getFundraiserProfileDetailsForPage`,
          {
            fundraiserId: id,
          }
        );
        console.log(response);
        setFund(response.data);
  
        // Parse the target date as a local date
        const targetDate = parseDateAsLocal(response.data.targetDate);
        targetDate.setHours(23, 59, 59, 999); // Set time to 11:59:59 PM
  
        // Get the current date in the browser's timezone
        const currentDate = new Date();
  
        // Store other conditions in variables
        const isAdminApprovedFlag = response.data.isAdminApprovedFlag === 0;
        const isFundRaised = response.data.amtAchieved >= response.data.goalAmt;
        const isStatusDisabled = response.data.status === 4;
  
        // Disable the Donate Now button if any condition is met
        if (
          targetDate < currentDate ||
          isAdminApprovedFlag ||
          isFundRaised ||
          isStatusDisabled
        ) {
          setDisableDonateButton(true);
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          console.error("Error:", error.response.data.error);
          toast.error("Failed to fetch data for fundraiser id.");
        } else {
          console.error("Error fetching data for fundraiser id:", error.message);
          toast.error("Error fetching data for fundraiser id.");
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    if (isMounted.current) {
      fetchData();
      isMounted.current = false;
    }
  }, [id]);
  

  const parseDateAsLocal = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // Month is 0-based in JavaScript
  };
  
  
  
  const createMarkup = (content) => {
    return { __html: content };
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getFileExtension = (url) => {
    return url.split(".").pop().toLowerCase();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate("/browsefundraiser-user"); // Redirect to another page if needed
  };

  const handleFundRaisedModalClose = () => {
    setIsFundRaisedModalOpen(false);
    navigate("/browsefundraiser-user"); // Redirect to browse fundraiser page
  };

  const handleStatusDisabledModalClose = () => {
    setIsStatusDisabledModalOpen(false);
    navigate("/browsefundraiser-user"); // Redirect to browse fundraiser page
  };

  return (
    <div
      className={`relative ${isModalOpen || isFundRaisedModalOpen || isStatusDisabledModalOpen
        ? "blur-sm"
        : ""
        }`}
    >
      <Header />
      <div className="fundraiser-banner-section">
        <div className="container mx-auto md:flex items-center justify-between">
          {/* <div className="md:w-2/5 md:mx-4">
            {fund?.imageList && fund.imageList.length > 0 ? (
              <Slider {...sliderSettings}>
                {fund.imageList.map((image, index) => (
                  <div
                    key={index}
                    className="flex justify-center items-center h-96 w-full bg-black overflow-hidden"
                  >
                    {console.log(getFileExtension(image))}
                    {(getFileExtension(image) === "jpg" ||
                      getFileExtension(image) === "jpeg" ||
                      getFileExtension(image) === "png") && (
                      <img
                        src={image}
                        alt={`Slide ${index}`}
                        className="object-contain h-full w-auto mx-auto"
                        // onError={(e) => {
                        //   e.target.src = "/assets/images/default.jpg";
                        // }}
                      />
                    )}
                    {getFileExtension(image) === "mp4" && (
                      <video
                        ref={videoRef}
                        controls
                        controlsList="nodownload"
                        className="object-contain h-full w-auto mx-auto"
                      >
                        <source src={image} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {(getFileExtension(image) === "doc" ||
                      getFileExtension(image) === "text") && (
                      <div className="bg-white border p-4 rounded-md h-full flex items-center justify-center">
                        <p className="text-sm font-normal">{image}</p>
                      </div>
                    )}
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="flex justify-center items-center h-96 w-full bg-black">
                <img
                  src="/assets/images/default.jpg"
                  alt="Fallback Image"
                  className="object-contain h-full w-auto mx-auto"
                />
              </div>
            )}
          </div> */}

          <div className="md:w-2/5 md:mx-4">
            {isLoading ? (
              // You can show a loading spinner here if desired
              <div className="flex justify-center items-center h-96 w-full bg-black">
                <Triangle
                  height="70"
                  width="70"
                  color="white"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  visible={true}
                />
              </div>
            ) : fund && fund.imageList && fund.imageList.length > 0 ? (
              <Slider {...sliderSettings}>
                {fund.imageList.map((image, index) => (
                  <div
                    key={index}
                    className="flex justify-center items-center h-96 w-full bg-black overflow-hidden"
                  >
                    {(getFileExtension(image) === "jpg" ||
                      getFileExtension(image) === "jpeg" ||
                      getFileExtension(image) === "png") && (
                        <img
                          src={image}
                          alt={`Slide ${index}`}
                          className="object-contain h-full w-auto mx-auto"
                          onError={(e) => {
                            e.target.src = "/assets/images/default.jpg";
                          }}
                        />
                      )}
                    {getFileExtension(image) === "mp4" && (
                      <video
                        ref={videoRef}
                        controls
                        controlsList="nodownload"
                        className="object-contain h-full w-auto mx-auto"
                      >
                        <source src={image} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {(getFileExtension(image) === "doc" ||
                      getFileExtension(image) === "text") && (
                        <div className="bg-white border p-4 rounded-md h-full flex items-center justify-center">
                          <p className="text-sm font-normal">{image}</p>
                        </div>
                      )}
                  </div>
                ))}
              </Slider>
            ) : (
              // Show fallback image if no images are found
              <div className="flex justify-center items-center h-96 w-full bg-black">
                <img
                  src="/assets/images/default.jpg"
                  alt="Fallback Image"
                  className="object-contain h-full w-auto mx-auto"
                />
              </div>
            )}
          </div>

          <div className="md:w-1/2 pr-8 md:py-20 py-10 mx-4 md:mx-0">
            <h2 className="text-2xl font-semibold mb-4 md:w-2/3 ">
              {fund.title}
            </h2>
            <h1 className="banner-text-2 leading-none">
              {fund.amountAchivedInPercetage}% Funded
              <br />
              In{" "}
              <span className="yellow-texture-bg text-blue-800">
                {fund.noOfDaysofAchivment} Days
              </span>
            </h1>
            <h1 className="banner-text-2 !text-white  w-full textremSize">
              Fundraiser{" "}
            </h1>
            {/* <h2 className="text-2xl font-semibold mb-4 md:w-1/2">
              {fund.amtAchieved !== undefined && fund.goalAmt !== undefined
                ? `$${fund.amtAchieved.toLocaleString(
                    "en-US"
                  )} raised of $${fund.goalAmt.toLocaleString("en-US")}`
                : ""}
            </h2> */}

            <h2 className="text-2xl font-semibold mb-4 md:w-1/2">
              {fund.amtAchieved !== undefined && fund.goalAmt !== undefined
                ? `$${fund.amtAchieved.toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} raised of $${fund.goalAmt.toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`
                : ""}
            </h2>
            <h2 className="text-2xl font-semibold mb-4 w-1/2 text-blue-800 ">
              {fund.donorsCount} Donors
            </h2>

            <button
              style={{
                backgroundColor: disableDonateButton ? "grey" : "#0E398F", // Grey when disabled, blue when enabled
                color: "white", // White text in both states
                cursor: disableDonateButton ? "not-allowed" : "pointer", // Pointer style changes when disabled
              }}
              className="btn-style-1 px-4 py-2 rounded"
              onClick={handleSubmit}
              disabled={disableDonateButton}
            >
              Donate Now
            </button>



            {editPopupOpen && (
              <EditFundraiser
                onClose={() => setEditPopupOpen(false)}
                fundraiserData={fund}
              />
            )}
          </div>
        </div>
      </div>

      <div className="container mx-auto md:py-20 md:flex justify-between overflow-x-hidden">
        <div className="md:w-4/6 mx-4 md:mx-4">
          <h2 className="text-2xl font-bold mb-4 w-1/2 font-family-poppins">
            Story
          </h2>

          <p
            className="font-normal font-family-poppins leading-8 mt-10"
            dangerouslySetInnerHTML={createMarkup(fund.desc)}
          ></p>
        </div>
        <div className="2xl:w-1/5 md:w-1/4 shadow-lg ps-14 py-6 w-9/12 mx-auto md:mx-0 my-6">
          <h2 className="text-2xl font-bold mb-4 font-family-poppins">
            All Donors ({fund.donorsCount})
          </h2>
          <div className="max-h-[55rem] overflow-y-auto pe-6">
            {fund?.donors?.map((item, index) => (
              <div key={index}>
                <h2 className="text-lg font-bold mb-1 font-family-poppins">
                  {" "}
                  $
                  {item.donatedAmount.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}{" "}
                  {/* From {item.donorName} <br />{" "} */}
                  {item.donorName
                    ? `From ${item.donorName}`
                    : "From Generous Anonymous Donor"}{" "}
                  <br />
                </h2>
                <h2 className="text-sm font-normal mb-4 font-family-poppins">
                  {" "}
                  {item.title}{" "}
                </h2>
                <hr className="w-2/3 mb-6" />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal for Admin Disabled Fundraiser */}
      <Modal
        opened={isModalOpen}
        onClose={() => { }} // Disable the default close behavior
        centered
        size="lg"
        overlayOpacity={0.8}
        overlayBlur={5}
        transition="fade"
        transitionDuration={500}
        withCloseButton={false}
        closeOnClickOutside={false} // Prevent closing on outside click
        classNames={{
          modal: "bg-gradient-to-r from-purple-500 to-blue-600 p-8 rounded-lg",
        }}
        styles={{
          modal: {
            height: "400px", // Increase height
          },
        }}
      >
        <div className="flex flex-col items-center justify-center text-center h-full">
          <div className="text-6xl mb-4">⚠️</div>
          <h2 className="text-3xl font-bold mb-4 text-black drop-shadow-lg">
            This Fundraiser Has Been Disabled
          </h2>
          <p className="text-lg mb-6 text-black drop-shadow-lg">
            If you want to donate to another fundraiser, please continue.
          </p>
          <Button
            className="bg-white text-blue-800 hover:bg-gray-200 px-6 py-2 rounded-full"
            onClick={handleModalClose}
          >
            Continue
          </Button>
        </div>
      </Modal>

      {/* Modal for Fund Raised Successfully */}
      <Modal
        opened={isFundRaisedModalOpen}
        onClose={() => { }} // Disable the default close behavior
        centered
        size="lg"
        overlayOpacity={0.8}
        overlayBlur={5}
        transition="fade"
        transitionDuration={500}
        withCloseButton={false}
        closeOnClickOutside={false} // Prevent closing on outside click
        classNames={{
          modal: "bg-gradient-to-r from-green-500 to-teal-600 p-8 rounded-lg",
        }}
        styles={{
          modal: {
            height: "400px", // Increase height
          },
        }}
      >
        <div className="flex flex-col items-center justify-center text-center h-full">
          <div className="text-6xl mb-4 animate-bounce">😊</div>
          <h2 className="text-3xl font-bold mb-4 text-black drop-shadow-lg">
            {fund.fundraiserName} has raised the funds they needed!
          </h2>
          <p className="text-lg mb-6 text-black drop-shadow-lg">
            Please click continue to support other fundraisers.
          </p>
          <Button
            className="bg-white text-blue-800 hover:bg-gray-200 px-6 py-2 rounded-full"
            onClick={handleFundRaisedModalClose}
          >
            Continue
          </Button>
        </div>
      </Modal>

      {/* Modal for Status 4 (Disabled) Fundraiser */}
      <Modal
        opened={isStatusDisabledModalOpen}
        onClose={() => { }} // Disable the default close behavior
        centered
        size="lg"
        overlayOpacity={0.8}
        overlayBlur={5}
        transition="fade"
        transitionDuration={500}
        withCloseButton={false}
        closeOnClickOutside={false} // Prevent closing on outside click
        classNames={{
          modal: "bg-gradient-to-r from-red-500 to-pink-600 p-8 rounded-lg",
        }}
        styles={{
          modal: {
            height: "400px", // Increase height
          },
        }}
      >
        <div className="flex flex-col items-center justify-center text-center h-full">
          <div className="text-6xl mb-4">🚫</div>
          <h2 className="text-3xl font-bold mb-4 text-black drop-shadow-lg">
            This Fundraiser Has Been Disabled
          </h2>
          <p className="text-lg mb-6 text-black drop-shadow-lg">
            If you want to donate to another fundraiser, please continue.
          </p>
          <Button
            className="bg-white text-blue-800 hover:bg-gray-200 px-6 py-2 rounded-full"
            onClick={handleStatusDisabledModalClose}
          >
            Continue
          </Button>
        </div>
      </Modal>

      {/* Modal for Expired Target Date */}
      <Modal
        opened={isTargetDateModalOpen}
        onClose={() => { }} // Disable the default close behavior
        centered
        size="lg"
        overlayOpacity={0.8}
        overlayBlur={5}
        transition="fade"
        transitionDuration={500}
        withCloseButton={false} // Remove close button
        closeOnClickOutside={false} // Prevent closing on outside click
        classNames={{
          modal: "bg-gradient-to-r from-red-500 to-pink-600 p-8 rounded-lg",
        }}
        styles={{
          modal: {
            height: "400px", // Same size and height
          },
        }}
      >
        <div className="flex flex-col items-center justify-center text-center h-full">
          <div className="text-6xl mb-4">⏳</div>
          <h2 className="text-3xl font-bold mb-4 text-black drop-shadow-lg">
            Fundraiser Has Ended
          </h2>
          <p className="text-lg mb-6 text-black drop-shadow-lg">
            The fundraiser has reached its target date and is no longer active.
          </p>
          <Button
            className="bg-white text-blue-800 hover:bg-gray-200 px-6 py-2 rounded-full"
            onClick={() => navigate("/browsefundraiser-user")} // Redirect to browse fundraiser page
          >
            Continue
          </Button>
        </div>
      </Modal>




      <ToastContainer theme="colored" />
      <Footer />
    </div>
  );
};

export default Fundraiser;
