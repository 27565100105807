import React, { useState, useEffect } from "react";
import { getRequestWithTokenWithoutDataPartner } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import Pagination from "../Pagination";
import MailCampaignSideBar from "./MailCampaignSideBar";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import EmailCampaignTour from "./EmailCampaignTour";

const TemplateList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId;
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  let PageSize = 8;

  const tourCompletedFlag = localStorage.getItem("emailTourCompletedFlag");
  console.log("emailTourCompletedFlag templatelist", tourCompletedFlag);

  useEffect(() => {
    fetchTemplates(currentPage);
  }, [currentPage]);

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getUrlParameter(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  const fetchTemplates = async (page) => {
    setLoading(true);
    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        `${TQ_URL}/getAllTemplates?partnerId=${partnerId}&page=${page}`
      );

      console.log(response);
      setTemplates(response.templateList);
      setTotalCount(response.totalCount);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching templates:", error);
      setLoading(false);
    }
  };

  const handleTemplateClick = (id) => {
    console.log("template clicked ...", id);

    var source = getUrlParameter("source");
    if (source === "newCampaign") {
      navigate("/templateBuilder", { state: { id, source } });
    } else {
      navigate("/templateBuilder", { state: { id } });
    }
  };

  const handleAddTemplateclick = () => {
    var source = getUrlParameter("source");
    if (source === "newCampaign") {
      navigate("/templateBuilder", { state: { source } });
    } else {
      source = "templateList";
      navigate("/templateBuilder", { state: { source } });
    }
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-12">
        <div className="md:w-64 w-1/12 absolute md:fixed h-full md:overflow-y-auto">
          <MailCampaignSideBar />
        </div>

        <main
          id="emailTemplateList"
          className="w-full md:ml-64 md:pb-24 overflow-x-hidden"
        >
          <div className="mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center md:text-left">
                <h2 className="text-2xl font-bold">Template List</h2>
              </div>
              <div id="addNewTemplate" className="mt-4 md:mt-0">
                {/* <Link to="/templateBuilder?source=templateList"> */}
                <button
                  className="text-white p-2 rounded-md bg-blue-500 hover:bg-blue-600"
                  onClick={handleAddTemplateclick}
                >
                  Add New Template
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>

          {loading ? (
            <div className="flex items-center justify-center h-52">
              <TailSpin
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="loading"
              />
            </div>
          ) : (
            <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-4">
              {templates.length === 0 ? (
                <div className="text-center text-gray-500 font-bold text-xl italic">
                  No Template lists found.
                </div>
              ) : (
                templates.map((template) => (
                  <div
                    key={template.templateId}
                    className="template-preview-box hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer border rounded-lg overflow-hidden"
                    onClick={() => handleTemplateClick(template.templateId)}
                  >
                    <div className="template-preview-title p-4 bg-gray-100">
                      <p className="text-gray-500 font-bold">
                        {template.templateName?.length > 15
                          ? `${template.templateName.slice(0, 10)}...`
                          : template.templateName}
                      </p>
                    </div>
                    <div
                      className="template-preview-content h-96 w-full overflow-hidden flex justify-center items-center"
                      dangerouslySetInnerHTML={{
                        __html: template.templateHtml,
                      }}
                    />
                    <div className="template-preview-status p-4 bg-gray-100">
                      <p
                        className={`font-bold ${
                          template.statusName === "Active"
                            ? "text-green-500"
                            : template.statusName === "Draft"
                            ? "text-yellow-500"
                            : "text-gray-500"
                        }`}
                      >
                        {template.statusName}
                      </p>
                      <p className="text-gray-400">
                        <span>&#128339;</span> {template.formattedDate}
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}

          <Pagination
            className="pagination-bar mt-12"
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </main>

        {tourCompletedFlag === "false" && isLargeScreen && (
          <EmailCampaignTour />
        )}
      </div>
    </>
  );
};

export default TemplateList;
