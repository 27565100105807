import React, { useEffect, useState } from "react";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import AnalyticsSidebar from "./AnalyticsSidebar";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

import { TQ_URL } from "../../utility/baseUrl";
import {
  getRequestWithTokenWithoutDataPartner,
  getRequestWithOutToken,
} from "../../utility/apiRequest";

const DonationConversionReport = () => {
  const [value, setValue] = useState(null);
  const [freqValue, setFreqValue] = useState("yearly");

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const [causeList, setCauseList] = useState([]);
  const [monthDropdown, setMonthDropdown] = useState(false);
  const [yearDropdown, setYearDropdown] = useState(true);
  const [monthValue, setMonthValue] = useState("");
  const [yearValue, setYearValue] = useState(new Date().getFullYear());
  const [graphData, setGraphData] = useState({
    totalDonorCount: 0,
    totalVisitorCount: 0,
  });

  const [fundraiserDetails, setFundraiserDetails] = useState({
    totalDonorCount: 0,
    totalVisitorCount: 0,
    totalDonorCntInPercentage: 0,
    totalVisitorCntInPercentage: 0,
  });

  const yearList = [2020, 2021, 2022, 2023, 2024];

  const monthList = [
    {
      id: "01",
      name: "January",
    },
    {
      id: "02",
      name: "February",
    },
    {
      id: "03",
      name: "March",
    },
    {
      id: "04",
      name: "April",
    },
    {
      id: "05",
      name: "May",
    },
    {
      id: "06",
      name: "June",
    },
    {
      id: "07",
      name: "July",
    },
    {
      id: "08",
      name: "August",
    },
    {
      id: "09",
      name: "September",
    },
    {
      id: "10",
      name: "October",
    },
    {
      id: "11",
      name: "November",
    },
    {
      id: "12",
      name: "December",
    },
  ];

  useEffect(() => {
    handleAnalyticsDataFetching(freqValue, monthValue, yearValue);
  }, [freqValue, monthValue, yearValue]);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      fetchFundraiserInfo();
    }
  }, [value]);

  function handleAnalyticsDataFetching(typeValue, monthValue, yearValue) {
    if (typeValue === "yearly") {
      fetchRecurringDataInfo("yearly", monthValue, yearValue);
    } else if (typeValue === "weekly" && monthValue && yearValue) {
      fetchRecurringDataInfo("weekly", monthValue, yearValue);
    } else if (typeValue === "monthly" && monthValue && yearValue) {
      fetchRecurringDataInfo("monthly", monthValue, yearValue);
    } else {
      console.error("Invalid parameters provided.");
    }
  }

  const fetchRecurringDataInfo = async () => {
    const url = new URL(`${TQ_URL}/getRecurringDonorConversionForGraph`);
    url.search = new URLSearchParams();
    url.searchParams.set("typeValue", freqValue);
    if (monthValue != "" && !isNaN(monthValue)) {
      url.searchParams.set("monthValue", parseInt(monthValue));
    }
    if (yearValue != "" && !isNaN(yearValue)) {
      url.searchParams.set("yearValue", parseInt(yearValue));
    }
    url.searchParams.set("partnerId", `${partnerData.partnerId}`);

    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log(response);
      setGraphData(response.data);
    } catch (error) {
      console.error(
        "Error in fetching the details for analytics  data:",
        error
      );

      return;
    }
  };

  const fetchFundraiserInfo = async () => {
    const url = new URL(`${TQ_URL}/getDonationConversionReportForFundraiser`);
    url.search = new URLSearchParams();

    url.searchParams.set("partnerId", `${partnerData.partnerId}`);
    url.searchParams.set("fundraiserId", value);

    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        url.toString()
      );
      console.log(response.data);
      setFundraiserDetails(response.data);
    } catch (error) {
      console.error("Error in fetching data for fundraiser id:", error);
    }
  };

  useEffect(() => {
    getCauseList();
  }, []);

  async function getCauseList() {
    const url = new URL(`${TQ_URL}/getFundraiserList`);
    url.search = new URLSearchParams();

    url.searchParams.set("partnerId", `${partnerData.partnerId}`);

    try {
      const response = await getRequestWithOutToken(url.toString());
      console.log(response.dataList);
      setCauseList(response.dataList);
    } catch (error) {
      console.error("Error in fetching the Cause data:", error);
    }
  }

  const getTypeValue = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value>>", selectedValue);

    setValue(selectedValue);
  };

  const getMonthValue = (event) => {
    const selectedMonthValue = event.target.value;
    console.log("Selected month value>>", selectedMonthValue);
    setMonthValue(selectedMonthValue);
  };

  const getYearValue = (event) => {
    const selectedYearValue = event.target.value;
    console.log("Selected year value>>", selectedYearValue);
    setYearValue(selectedYearValue);
    console.log(monthValue);
  };

  const getFreqTypeValue = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value>>", selectedValue);
    setFreqValue(selectedValue);

    if (selectedValue === "weekly") {
      setMonthDropdown(true);
      if (yearDropdown) setYearDropdown(false);
    } else if (selectedValue === "monthly") {
      setMonthDropdown(true);
      setYearDropdown(true);
    } else if (selectedValue === "yearly") {
      if (monthDropdown) setMonthDropdown(false);
      setYearDropdown(true);
    }
  };

  const data = [
    {
      id: 0,
      value: fundraiserDetails.totalDonorCount,
      label: "Donor",
    },
    {
      id: 1,
      value: fundraiserDetails.totalVisitorCount,
      label: "Visitors",
    },
  ];

  const graphData1 = [
    {
      id: 0,
      value: graphData.totalDonorCount,
      label: "Donor",
    },
    {
      id: 1,
      value: graphData.totalVisitorCount,
      label: "Visitors",
    },
  ];

  const hasData1 = data.some((entry) => entry.value > 0);
  const hasGraphData = graphData1.some((entry) => entry.value > 0);

  const total = data.reduce((sum, entry) => sum + entry.value, 0);

  const graphDataWithPercentages = data.map((entry) => ({
    ...entry,
    percentage: ((entry.value / total) * 100).toFixed(2),
  }));

  const totalGraph = graphData1.reduce((sum, entry) => sum + entry.value, 0);

  const graphDataWithPercentages1 = graphData1.map((entry) => ({
    ...entry,
    percentage: ((entry.value / totalGraph) * 100).toFixed(2),
  }));

  const renderCustomLabel = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, index } =
      props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    const outerRadiusPlusMargin = outerRadius + 50;
    const labelX =
      cx + outerRadiusPlusMargin * Math.cos(-midAngle * (Math.PI / 180));
    const labelY =
      cy + outerRadiusPlusMargin * Math.sin(-midAngle * (Math.PI / 180));

    return (
      <>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={14}
        >
          {`${graphDataWithPercentages[index].percentage}%`}
        </text>
        <text
          x={labelX}
          y={labelY}
          fill="black"
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={14}
        >
          {`Count: ${data[index].value}`}
        </text>
      </>
    );
  };

  const renderCustomLabelForGraph = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, index } =
      props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    const outerRadiusPlusMargin = outerRadius + 50;
    const labelX =
      cx + outerRadiusPlusMargin * Math.cos(-midAngle * (Math.PI / 180));
    const labelY =
      cy + outerRadiusPlusMargin * Math.sin(-midAngle * (Math.PI / 180));

    return (
      <>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={14}
        >
          {`${graphDataWithPercentages1[index].percentage}%`}
        </text>
        <text
          x={labelX}
          y={labelY}
          fill="black"
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={14}
        >
          {`Count: ${graphData1[index].value}`}
        </text>
      </>
    );
  };
  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed h-full md:overflow-y-auto">
          <AnalyticsSidebar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px] overflow-x-hidden">
          {" "}
          {/* <div className="mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Analytics and Reports</h2>
              </div>
            </div>
          </div>
          <div className="md:flex md:py-4 container mx-auto mt-[30px] ">
            <div className="md:w-1/2 2xl:w-6/12  mx-4 mb-4 fade-in-left">
              <h2 className="text-lg px-10 font-bold md:mt-8">
                Donation Page Conversion Reports
              </h2>
            </div>
            <div className="md:w-1/2 2xl:w-6/12 flex flex-row items-start justify-start  md:text-left mx-4 md:mx-2 fade-in-right">
              <div className="mr-4 md:ml-12">
                <select
                  onChange={getTypeValue}
                  value={value}
                  className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block md:mt-7 w-full custom-padding"
                >
                  <option value="">Select Fundraiser</option>
                  {causeList.map((type, index) => (
                    <option key={index} value={type.fundraiserId}>
                      {type.fundraiserName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div> */}
          <div className="mx-auto shadow-md w-full">
            <div className="flex flex-col md:flex-row justify-between container items-center px-4 sm:px-6 md:px-10 py-4 sm:py-6 md:py-6">
              <div className="text-center md:text-left">
                <h2 className="text-xl sm:text-2xl md:text-3xl font-bold">
                  Analytics and Reports
                </h2>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row py-4 container mx-auto mt-6 md:mt-8">
            <div className="w-full md:w-1/2 xl:w-6/12 mx-4 mb-4 fade-in-left">
              <h2 className="text-lg sm:text-xl md:text-2xl px-4 md:px-10 font-bold mt-4 md:mt-8">
                Donation Page Conversion Reports
              </h2>
            </div>
            <div className="w-full md:w-1/2 xl:w-6/12 flex items-start justify-start mx-4 md:mx-2 fade-in-right">
              <div className="w-full mr-4 md:ml-12">
                <select
                  onChange={getTypeValue}
                  value={value}
                  className="bg-gray-50 border border-gray-300 text-black font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block mt-4 md:mt-7 w-full px-3 py-2"
                >
                  <option value="">Select Fundraiser</option>
                  {causeList.map((type, index) => (
                    <option key={index} value={type.fundraiserId}>
                      {type.fundraiserName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="md:mt-8 mx-4 md:mx-[150px]">
            {hasData1 ? (
              <div className="flex justify-center">
                <PieChart
                  width={window.innerWidth < 768 ? 300 : 650}
                  height={window.innerWidth < 768 ? 200 : 400}
                >
                  <Pie
                    data={data}
                    dataKey="value"
                    nameKey="label"
                    cx="50%"
                    cy="50%"
                    outerRadius={window.innerWidth < 768 ? 80 : 180}
                    innerRadius={30}
                    paddingAngle={2}
                    label={renderCustomLabel}
                    labelLine={true}
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={index === 0 ? "#03008D" : "#02B2AF"}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    align="right"
                    verticalAlign="middle"
                    layout="vertical"
                  />
                </PieChart>
              </div>
            ) : (
              <p className="text-lg font-bold text-center md:ml-8">
                No Visitor or donor found
              </p>
            )}
          </div>
          <div className="container mx-auto mt-[80px]">
            <div className="flex flex-col md:flex-row py-4">
              <div className="w-full md:w-1/2 xl:w-6/12 mx-4 mb-4 fade-in-left">
                <h2 className="text-lg sm:text-xl md:text-2xl px-4 md:px-10 font-bold mt-4 md:mt-8">
                  Donation Page Conversion Reports (Recurring)
                </h2>
              </div>

              <div className="w-full md:w-1/2 xl:w-6/12 flex flex-col md:flex-row items-start justify-start mx-4 md:mx-2 fade-in-right">
                <div className="w-full md:w-1/3 mr-4 md:mr-2 mb-4 md:mb-0">
                  <select
                    onChange={getFreqTypeValue}
                    value={freqValue}
                    className="bg-gray-50 border border-gray-300 text-black font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block mt-4 md:mt-7 w-full px-3 py-2"
                  >
                    <option value="">Select frequency type</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>

                {monthDropdown && (
                  <div className="w-full md:w-1/3 mr-4 md:mr-2 mb-4 md:mb-0">
                    <select
                      onChange={getMonthValue}
                      value={monthValue}
                      className="bg-gray-50 border border-gray-300 text-black font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block mt-4 md:mt-7 w-full px-3 py-2"
                    >
                      <option value="">Select Month</option>
                      {monthList.map((month, index) => (
                        <option key={month.id} value={month.id}>
                          {month.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {(yearDropdown || monthValue) && (
                  <div className="w-full md:w-1/3">
                    <select
                      onChange={getYearValue}
                      value={yearValue}
                      className="bg-gray-50 border border-gray-300 text-black font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block mt-4 md:mt-7 w-full px-3 py-2"
                    >
                      <option value="">Select Year</option>
                      {yearList.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="md:mt-8 mx-4 md:mx-[150px]">
            {hasGraphData ? (
              <div className="flex justify-center">
                <PieChart
                  width={
                    window.innerWidth > 1024
                      ? 650
                      : window.innerWidth < 768
                      ? 300
                      : 500
                  }
                  height={
                    window.innerWidth > 1024
                      ? 400
                      : window.innerWidth < 768
                      ? 200
                      : 300
                  }
                >
                  <Pie
                    data={graphData1}
                    dataKey="value"
                    nameKey="label"
                    cx="50%"
                    cy="50%"
                    outerRadius={
                      window.innerWidth > 1024
                        ? 150
                        : window.innerWidth < 768
                        ? 70
                        : 100
                    }
                    innerRadius={20}
                    paddingAngle={2}
                    label={renderCustomLabelForGraph}
                    labelLine={true}
                  >
                    {graphData1.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={index === 0 ? "#03008D" : "#02B2AF"}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    align="right"
                    verticalAlign="middle"
                    layout="vertical"
                  />
                </PieChart>
              </div>
            ) : (
              <p className="text-center">No data to show</p>
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default DonationConversionReport;
