import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import confetti from 'canvas-confetti';

const BankStatus = () => {
  const location = useLocation();
  const [animate, setAnimate] = useState(false);
  const [timer, setTimer] = useState(5); // 5-second countdown
  const [shakeEffect, setShakeEffect] = useState(false); // For unknown status shake effect
  const [showDefaultPrompt, setShowDefaultPrompt] = useState(false); // Show default account prompt

  useEffect(() => {
    setAnimate(true);

    const params = new URLSearchParams(location.search);
    const status = params.get('status');

    if (status === 'success') {
      fireConfetti(); // Success confetti effect
      setShowDefaultPrompt(true); // Show prompt for default account option

      // Start countdown for 5 seconds if no interaction
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      // Close popup after 5 seconds if no interaction
      setTimeout(() => {
        clearInterval(countdown);
        if (!showDefaultPrompt) {
          window.close(); // Close the popup after 5 seconds
        }
      }, 5000);
    } else if (status === 'cancel') {
      fireCancelEffect(); // Red fade effect for cancel

      // Start countdown for 5 seconds
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      // Close popup after 5 seconds
      setTimeout(() => {
        clearInterval(countdown);
        window.close(); // Close the popup after 5 seconds
      }, 5000);
    } else if (status === 'unknown') {
      setShakeEffect(true); // Apply shake effect for unknown

      // Start countdown for 5 seconds
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      // Close popup after 5 seconds
      setTimeout(() => {
        clearInterval(countdown);
        window.close(); // Close the popup after 5 seconds
      }, 5000);
    }
  }, [location, showDefaultPrompt]);

  const handleMakeDefault = () => {
    localStorage.setItem('isDefaultAccount', 'true'); // Save response as 'true'
    window.close(); // Close the popup after saving the response
  };

  const handleSkipDefault = () => {
    localStorage.setItem('isDefaultAccount', 'false'); // Save response as 'false'
    window.close(); // Close the popup after saving the response
  };

  // Success confetti effect (unchanged)
  const fireConfetti = () => {
    const duration = 2 * 1000;
    const end = Date.now() + duration;

    const frame = () => {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    };

    frame();
  };

  // Cancel effect (red fade overlay)
  const fireCancelEffect = () => {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(255, 0, 0, 0.7)'; // Red overlay
    overlay.style.zIndex = '9999';
    overlay.style.opacity = '1';
    overlay.style.transition = 'opacity 1.5s ease-out';
    document.body.appendChild(overlay);

    // Fade out the overlay after a short delay
    setTimeout(() => {
      overlay.style.opacity = '0';
      setTimeout(() => {
        document.body.removeChild(overlay); // Remove the overlay from the DOM
      }, 1500);
    }, 1000); // Keep the red overlay visible for 1 second
  };

  const renderMessage = () => {
    const params = new URLSearchParams(location.search);
    const status = params.get('status');

    if (status === 'success') {
      return (
        <div
          className={`${
            animate ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
          } bg-white p-6 rounded-xl shadow-md text-center transition-all duration-700 ease-out`}
        >
          <h2 className="text-2xl font-semibold text-green-500 mb-4">🎉 Success!</h2>
          <p className="text-gray-700">Your bank account has been successfully linked.</p>
          {showDefaultPrompt ? (
            <div className="mt-4">
              <p className="text-gray-700">Would you like to make this your default account?</p>
              <div className="flex justify-center space-x-4 mt-4">
                <button
                  onClick={handleMakeDefault}
                  className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
                >
                  Yes
                </button>
                <button
                  onClick={handleSkipDefault}
                  className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
                >
                  No
                </button>
              </div>
            </div>
          ) : (
            <p className="text-sm text-gray-500 mt-2">
              This window will close in <span className="text-green-500">{timer}</span> seconds.
            </p>
          )}
        </div>
      );
    } else if (status === 'cancel') {
      return (
        <div
          className={`${
            animate ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
          } bg-white p-6 rounded-xl shadow-md text-center transition-all duration-700 ease-out`}
        >
          <h2 className="text-2xl font-semibold text-red-500 mb-4">❌ Canceled</h2>
          <p className="text-gray-700">The bank account setup was canceled.</p>
          <p className="text-sm text-gray-500 mt-2">
            This window will close in <span className="text-red-500">{timer}</span> seconds.
          </p>
        </div>
      );
    } else {
      return (
        <div
          className={`${
            animate ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
          } ${shakeEffect ? 'animate-shake' : ''} bg-white p-6 rounded-xl shadow-md text-center transition-all duration-700 ease-out`}
        >
          <h2 className="text-2xl font-semibold text-yellow-500 mb-4">⚠️ Unknown Status</h2>
          <p className="text-gray-700">Something went wrong. Please try again.</p>
          <p className="text-sm text-gray-500 mt-2">
            This window will close in <span className="text-yellow-500">{timer}</span> seconds.
          </p>
        </div>
      );
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      {renderMessage()}
    </div>
  );
};

export default BankStatus;
