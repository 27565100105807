import React, { useState, useEffect } from "react";
import TopHeader from "../Home/TopHeader";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

import { getRequestWithTokenWithDataWithAuth } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import Tour from "reactour";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

const stepStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
};

const MoneyDetails = () => {
  const [isTourOpen, setIsTourOpen] = useState(true);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const [open, setOpen] = useState(null);
  const [money, setMoney] = useState("");
  const navigate = useNavigate();

  const partnerId = partnerData.partnerId;
  const userType = partnerData.userType;

  const handleOpen = (value) => setOpen(open === value ? null : value);

  const getTotalMoney = async () => {
    try {
      const response = await getRequestWithTokenWithDataWithAuth(
        `${TQ_URL}/getbalance`
      );
      setMoney(response.availableAmount);
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  const FAQData = [
    {
      id: 101,
      question: "Checkout the list of all transactions",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac fringilla dolor. Vestibulum efficitur dui a urna accumsan, at tincidunt quam semper. Curabitur bibendum, justo et dapibus pharetra, libero tortor hendrerit libero.",
    },
    {
      id: 102,
      question: "Cause Specific Donations",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac fringilla dolor. Vestibulum efficitur dui a urna accumsan, at tincidunt quam semper. Curabitur bibendum, justo et dapibus pharetra, libero tortor hendrerit libero.",
    },
    {
      id: 103,
      question: "Reports",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac fringilla dolor. Vestibulum efficitur dui a urna accumsan, at tincidunt quam semper. Curabitur bibendum, justo et dapibus pharetra, libero tortor hendrerit libero.",
    },
  ];

  const moduleId = 3;

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [tourCompletedFlag, setTourCompletedFlag] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(true);

  useEffect(() => {
    const fetchTourStatus = async () => {
      try {
        const response = await axios.get(
          `${TQ_URL}/user-tour-status/${partnerId}/${moduleId}`
        );
        console.log(response.data.tourCompleted);
        setTourCompletedFlag(response.data.tourCompleted); // Assuming your API returns the status in 'tourCompleted'
        localStorage.setItem(
          "donorTourCompletedFlag",
          response.data.tourCompleted
        );
      } catch (error) {
        console.error("Error fetching tour status:", error);
      } finally {
        setIsTableLoading(false); // Set loading to false after fetching the flag
      }
    };
    fetchTourStatus();
  }, []);

  useEffect(() => {
    console.log("Tour status:", isTourOpen);
  }, [isTourOpen]);

  const steps = [
    {
      selector: "#view-causes", // View Causes button
      content: (
        <div className="step-content">
          <span className="emoji-large">📚</span>
          <div>
            Here you can browse and view all causes that need support and
            donations.
          </div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#all-transactions", // All Transactions button
      content: (
        <div className="step-content">
          <span className="emoji-large">📊</span>
          <div>Track all your transactions related to donations or funds.</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#all-fundraisers", // All Fundraisers button
      content: (
        <div className="step-content">
          <span className="emoji-large">🎯</span>
          <div>View all fundraisers you have participated in or created.</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#all-subscriptions", // All Subscriptions button
      content: (
        <div className="step-content">
          <span className="emoji-large">🔁</span>
          <div>Manage all your subscription-based donations here.</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#all-support-queries", // All Support Queries button
      content: (
        <div className="step-content">
          <span className="emoji-large">❓</span>
          <div>
            Submit or view support queries related to your donations and
            fundraisers.
          </div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },

    partnerData.partnerId == 1 && {
      selector: "#all-enquries", // All Support Queries button
      content: (
        <div className="step-content">
          <span className="emoji-large">❓</span>
          <div>
            Submit or view All queries related to the website and other
            services.
          </div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },

    {
      selector: "#create-fundraiser", // Create Fundraiser button
      content: (
        <div className="step-content">
          <span className="emoji-large">💡</span>
          <div>
            Start a new fundraiser to support a cause that you believe in.
          </div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },

    partnerData.partnerId == 1 && {
      selector: "#payment-request", // Create Fundraiser button
      content: (
        <div className="step-content">
          <span className="emoji-large">💡</span>
          <div>raised the request.</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
  ].filter(Boolean);

  const handleFinishRedirection = async () => {
    console.log("finish clicked");

    try {
      // Call the API to mark the module as finished
      const response = await axios.put(
        `${TQ_URL}/complete/${partnerId}/${moduleId}`
      );

      if (response.status === 200) {
        console.log("Module marked as complete");

        // Redirect to the services dashboard after completion
        navigate("/services-dashboard");
      } else {
        console.error("Failed to complete the module", response.data);
      }
    } catch (error) {
      console.error("Error marking module as complete:", error);
    }
  };
  const handleCloseRequest = async () => {
    console.log("close clicked");

    try {
      // Call the API to mark the module as finished
      const response = await axios.put(
        `${TQ_URL}/complete/${partnerId}/${moduleId}`
      );

      if (response.status === 200) {
        console.log("Module marked as complete");
        setIsTourOpen(false);
      } else {
        console.error("Failed to complete the module", response.data);
      }
    } catch (error) {
      console.error("Error marking module as complete:", error);
    }
  };

  if (isTableLoading) {
    return null; // Don't render anything while loading
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <DonorManagementTop />
      <div className="flex">
        <div className=""></div>
        <main className="w-full verify-user">
          <div className="mx-auto bg-white shadow-lg rounded-lg">
            <div className="md:flex justify-between container items-center px-4 md:px-10 py-6">
              <div className="md:text-left text-center">
                <h2 className="text-3xl font-bold text-gray-700">
                  Money Details
                </h2>
                {/* <p className="text-black font-medium md:leading-10 mt-4 md:mt-0">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
              </div>
              <div className="flex items-center space-x-4 mt-6 md:mt-0 justify-center">
                {/* <div className="user-img-box w-24 h-24 mb-4 overflow-hidden rounded-full relative cursor-pointer">
                  <img
                    src="assets/images/user-1.jpg"
                    alt="Document Preview"
                    className="object-cover w-full h-full bg-grey-500 cursor-pointer"
                  />
                </div> */}
                {/* <p>John Doe</p> */}
                {/* <svg
                  viewBox="0 0 16 16"
                  className="w-4"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                    <polygon points="14.586,3.586 8,10.172 1.414,3.586 0,5 8,13 16,5"></polygon>
                  </g>
                </svg> */}
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* Main content Section */}
      <div className="py-10 container mx-auto px-4 md:px-0">
        {/* First Row */}
        <div className="flex flex-wrap justify-center gap-6">
          {/* <div className="bg-[#F0F2F7] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-10 rounded-2xl mx-4">
            <h1 className="primary-text-color text-3xl font-semibold">
              <img
                src="assets/images/dollar.svg"
                className="absolute ml-[-70px] w-10"
              ></img>
              $ {money}
            </h1>
            <h1 className="primary-text-color text-lg font-normal pt-2">
              Total Balance
            </h1>
            <button className="btn-style-1 text-white px-8 py-1 rounded-2xl mt-6">
              Check
            </button>
          </div> */}
          {/* <div className="bg-gradient-to-b from-[#5E9DE4] to-[#E9CFED] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-48 rounded-2xl flex flex-col justify-center items-center text-center py-6">
            <img src="assets/images/check-list.svg" className="w-6"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              Verify Users
            </h1>
            <p className="w-1/2 text-xs mt-4"></p>
            <Link to="/verifyusers">
              <button className="btn-style-1 text-white px-8 py-1 rounded-2xl mt-6">
                Continue
              </button>
            </Link>
          </div> */}
          <div
            id="view-causes"
            className="bg-gradient-to-b from-[#FFA797] to-[#FFC999] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-56 rounded-2xl flex flex-col justify-center items-center text-center p-6 shadow-lg transform transition-transform duration-300 hover:scale-105"
          >
            <img src="assets/images/layer-plus.svg" className="w-12 mb-4"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              View Causes
            </h1>
            <Link to="/list-of-causes-user">
              <button className="btn-style-1 text-white px-8 py-2 rounded-2xl mt-6">
                Check
              </button>
            </Link>
          </div>
          {/* <div
            id="all-transactions"
            className="bg-gradient-to-b from-[#5E9DE4] to-[#E9CFED] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-56 rounded-2xl flex flex-col justify-center items-center text-center p-6 shadow-lg transform transition-transform duration-300 hover:scale-105"
          >
            <img src="assets/images/check-list.svg" className="w-12 mb-4"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              All Transactions
            </h1>
            <p className="w-1/2 text-xs mt-4"></p>
            <Link to="/all-transactions-user">
              <button className="btn-style-1 text-white px-8 py-2 rounded-2xl mt-6">
                Continue
              </button>
            </Link>
          </div> */}
          <div
            id="all-fundraisers"
            className="bg-gradient-to-b from-[#FFA797] to-[#FFC999] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-56 rounded-2xl flex flex-col justify-center items-center text-center p-6 shadow-lg transform transition-transform duration-300 hover:scale-105"
          >
            <img src="assets/images/layer-plus.svg" className="w-12 mb-4"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              All Fundraisers
            </h1>
            <Link to="/all-fundraiser">
              <button className="btn-style-1 text-white px-8 py-2 rounded-2xl mt-6">
                Check
              </button>
            </Link>
          </div>
          <div
            id="all-subscriptions"
            className="bg-gradient-to-b from-[#5E9DE4] to-[#E9CFED] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-56 rounded-2xl flex flex-col justify-center items-center text-center p-6 shadow-lg transform transition-transform duration-300 hover:scale-105"
          >
            <img src="assets/images/check-list.svg" className="w-12 mb-4"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              All Subscriptions
            </h1>
            <p className="w-1/2 text-xs mt-4"></p>
            <Link to="/all-money-user">
              <button className="btn-style-1 text-white px-8 py-2 rounded-2xl mt-6">
                Continue
              </button>
            </Link>
          </div>

          <div
            id="view-causes"
            className="bg-gradient-to-b from-[#FFA797] to-[#FFC999] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-56 rounded-2xl flex flex-col justify-center items-center text-center p-6 shadow-lg transform transition-transform duration-300 hover:scale-105"
          >
            <img src="assets/images/layer-plus.svg" className="w-12 mb-4"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              Add Donation
            </h1>
            <Link to="/add-donation-admin">
              <button className="btn-style-1 text-white px-8 py-2 rounded-2xl mt-6">
                Continue
              </button>
            </Link>
          </div>

          <div
            id="all-support-queries"
            className="bg-gradient-to-b from-[#5E9DE4] to-[#E9CFED] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-56 rounded-2xl flex flex-col justify-center items-center text-center p-6 shadow-lg transform transition-transform duration-300 hover:scale-105"
          >
            <img src="assets/images/check-list.svg" className="w-12 mb-4"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              All Support Queries
            </h1>
            <p className="w-1/2 text-xs mt-4"></p>
            <Link to="/all-supportQueries">
              <button className="btn-style-1 text-white px-8 py-2 rounded-2xl mt-6">
                Continue
              </button>
            </Link>
          </div>

          {partnerData.partnerId == 1 && (
            <div
              id="all-enquiries"
              className="bg-gradient-to-b from-[#5E9DE4] to-[#E9CFED] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-56 rounded-2xl flex flex-col justify-center items-center text-center p-6 shadow-lg transform transition-transform duration-300 hover:scale-105"
            >
              <img
                src="assets/images/check-list.svg"
                className="w-12 mb-4"
              ></img>
              <h1 className="text-white font-semibold text-xl mt-2">
                All Enquiries
              </h1>
              <p className="w-1/2 text-xs mt-4"></p>
              <Link to="/all-enquiries">
                <button className="btn-style-1 text-white px-8 py-2 rounded-2xl mt-6">
                  Continue
                </button>
              </Link>
            </div>
          )}

          {/* New Create Fundraiser Section */}
          <div
            id="create-fundraiser"
            className="bg-gradient-to-b from-[#67C3F3] to-[#87E8D5] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-56 rounded-2xl flex flex-col justify-center items-center text-center p-6 shadow-lg transform transition-transform duration-300 hover:scale-105"
          >
            <img src="assets/images/layer-plus.svg" className="w-12 mb-4"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              Create Fundraiser
            </h1>
            <Link to="/createfundraiser-admin">
              <button className="btn-style-1 text-white px-8 py-2 rounded-2xl mt-6">
                Start
              </button>
            </Link>
          </div>
          {/* {partnerData.partnerId == 1 && (
            <div
              id="payment-request"
              className="bg-gradient-to-b from-[#67C3F3] to-[#87E8D5] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-56 rounded-2xl flex flex-col justify-center items-center text-center p-6 shadow-lg transform transition-transform duration-300 hover:scale-105"
            >
              <img
                src="assets/images/check-list.svg"
                className="w-12 mb-4"
              ></img>
              <h1 className="text-white font-semibold text-xl mt-2">
                Payment Request
              </h1>
              <Link to="/paymentrequest">
                <button className="btn-style-1 text-white px-8 py-2 rounded-2xl mt-6">
                  Continue
                </button>
              </Link>
            </div>
          )} */}

          <div className="bg-gradient-to-b from-[#67C3F3] to-[#87E8D5] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-56 rounded-2xl flex flex-col justify-center items-center text-center p-6 shadow-lg transform transition-transform duration-300 hover:scale-105">
            <img src="assets/images/check-list.svg" className="w-12 mb-4"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              Reimbursement Request
            </h1>
            <Link to="/paymentrequest">
              <button className="btn-style-1 text-white px-8 py-2 rounded-2xl mt-6">
                Continue
              </button>
            </Link>
          </div>
          {userType == "partner" ? (
            <div className="bg-gradient-to-b from-[#67C3F3] to-[#87E8D5] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-56 rounded-2xl flex flex-col justify-center items-center text-center p-6 shadow-lg transform transition-transform duration-300 hover:scale-105">
              <img
                src="assets/images/check-list.svg"
                className="w-12 mb-4"
              ></img>
              <h1 className="text-white font-semibold text-xl mt-2">
                Manage SubAdmin
              </h1>
              <Link to="/manageSubAdmins">
                <button className="btn-style-1 text-white px-8 py-2 rounded-2xl mt-6">
                  Continue
                </button>
              </Link>
            </div>
          ) : null}
          {userType == "partner" ? (
            <div className="bg-gradient-to-b from-[#67C3F3] to-[#87E8D5] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 h-56 rounded-2xl flex flex-col justify-center items-center text-center p-6 shadow-lg transform transition-transform duration-300 hover:scale-105">
              <img
                src="assets/images/check-list.svg"
                className="w-12 mb-4"
              ></img>
              <h1 className="text-white font-semibold text-xl mt-2">
                Edit your domain
              </h1>
              <Link to="/createDomain">
                <button className="btn-style-1 text-white px-8 py-2 rounded-2xl mt-6">
                  Continue
                </button>
              </Link>
            </div>
          ) : null}
        </div>
        {/* Second Row */}
        {/* <div>
          <div className="container mx-auto py-10 px-4">
            {FAQData.map((faq, index) => (
              <Accordion
                key={index}
                open={index === open}
                icon={<Icon id={index} open={open} />}
              >
                <AccordionHeader
                  onClick={() => handleOpen(index)}
                  className="money-details-accordion bg-white md:px-16 px-3 shadow-md"
                >
                  <p className="text-base">
                    <span className="font-normal me-6">{faq.id}</span>{" "}
                    {faq.question}
                  </p>
                </AccordionHeader>
                <AccordionBody>
                  <p className="text-base font-normal">{faq.answer}</p>
                </AccordionBody>
              </Accordion>
            ))}
          </div>
        </div> */}
      </div>

      {!tourCompletedFlag && isLargeScreen && (
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          scrollDuration={900}
          onRequestClose={handleCloseRequest}
          disableDotsNavigation={true}
          nextButton={<button className="tour-button">Next ➡️</button>}
          prevButton={<button className="tour-button">⬅️ Back</button>}
          lastStepNextButton={
            <button className="tour-button" onClick={handleFinishRedirection}>
              Finish 🎉
            </button>
          }
        />
      )}
    </div>
  );
};

export default MoneyDetails;
