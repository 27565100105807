import React, { useState, useEffect } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  Button,
  Tooltip,
  Modal,
  Textarea,
  ActionIcon,
  Box,
  TextInput,
} from "@mantine/core";
import { IconBan, IconCircleCheck } from "@tabler/icons-react";
import {
  getRequestWithTokenWithDataWithAuthentication,
  postWithTokenWithDataReturnDate,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { FaCheck } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { IoCheckmarkOutline } from "react-icons/io5";

const PaymentRequest = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [selectedFundraiser, setSelectedFundraiser] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAcceptReq, setIsAcceptReq] = useState(false);
  const [isCancelReq, setIsCancelReq] = useState(false);
  const [isReReq, setIsReReq] = useState(false);

  const [partnerId, setPartnerId] = useState(null);

  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [accountLink, setAccountLink] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const partnerData = JSON.parse(localStorage.getItem("partnerData"));
    setPartnerId(partnerData ? partnerData.partnerId : null); // Set partnerId
    if (!data.length) setIsLoading(true);
    else setIsRefetching(true);
    getAllTransaction();
  }, [pagination.pageIndex, pagination.pageSize, sorting, columnFilters]);

  const getAllTransaction = async () => {
    const partnerData = JSON.parse(localStorage.getItem("partnerData"));
    const partnerId = partnerData ? partnerData.partnerId : null;
    const url = new URL(`${TQ_URL}/getPaymentRequest`);
    url.searchParams.set("start", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("partnerId", `${partnerId}`);

    if (sorting.length) {
      url.searchParams.set("sortField", sorting[0].id);
      url.searchParams.set("sortDirection", sorting[0].desc ? "desc" : "asc");
    }
    if (columnFilters.length) {
      url.searchParams.set("filterField", columnFilters[0].id);
      url.searchParams.set("filterValue", columnFilters[0].value);
    }

    try {
      const response = await getRequestWithTokenWithDataWithAuthentication(
        url.toString()
      );
      console.log(response.datalist);
      const dataWithSerialNumber = response.datalist.map((item, index) => ({
        ...item,
        id: index + 1,
        fundraiserId: item.fundraiserId,
        modifyTitle:
          item.title.split(" ").slice(0, 5).join(" ") +
          (item.title.split(" ").length > 5 ? "..." : ""),
        status:
          item.status === 0
            ? "Pending"
            : item.status === 1
            ? "Complete"
            : item.status === 3
            ? "Cancelled"
            : "Rejected", // Mapping the status value
      }));
      console.log("-------<", response);

      console.log("-------<", response);
      setData(dataWithSerialNumber);

      setRowCount(response.totalCount);
    } catch (error) {
      console.error("Error in fetching All Transactions:", error);
      setIsError(true);
      toast.error("Error loading data", { position: toast.POSITION.TOP_RIGHT });
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const handleAcceptRequest = (data) => {
    setSelectedFundraiser(data);
    setIsAcceptReq(true);
    setIsModalOpen(true);
  };

  const handleRejectRequest = (data) => {
    setSelectedFundraiser(data);
    setIsAcceptReq(false);
    setIsReReq(false);
    setIsCancelReq(false);
    setIsModalOpen(true);
  };

  const handleCancelRequest = (data) => {
    setSelectedFundraiser(data);
    setIsCancelReq(true);
    setIsAcceptReq(false);
    setIsModalOpen(true);
    setIsReReq(false);
  };

  const handleReRequest = (data) => {
    setSelectedFundraiser(data);
    setIsReReq(true);
    setIsCancelReq(false);
    setIsAcceptReq(false);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setReason(""); // Clear the reason field if the user skips
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    if (!reason.trim()) {
      toast.warn("Please enter a reason or click Cancel.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    let status = 2; // reject
    handleConfirm(status);
    setIsModalOpen(false);
  };

  const handleConfirm = async (status) => {
    setIsSubmitting(true); // Start the loader

    const partnerData = JSON.parse(localStorage.getItem("partnerData"));
    const partnerId = partnerData ? partnerData.partnerId : null;

    const requestPayload = {
      partnerId: partnerId,
      fundraiserId: selectedFundraiser.fundraiserId,
      paymentId: selectedFundraiser.paymentId,
      status: status, // status based on accept/reject
      reason: isAcceptReq ? "" : reason, // include reason if rejected
    };

    console.log(selectedFundraiser);

    try {
      const endPoint = "acceptPaymentRequest";
      const response = await postWithTokenWithDataReturnDate(
        `${TQ_URL}/${endPoint}`,
        requestPayload
      );

      if (response.status === 200) {
        console.log("--------------- link========>", response.data);
        if (response.data.accountLink) {
          // Set accountLink to state and open modal
          setAccountLink(response.data.accountLink);
          setIsLinkModalOpen(true); // Open the modal
        } else {
          // Handle the case where there's no accountLink
          console.log(response.data.message);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        getAllTransaction(); // Refresh the table data after a successful request
      } else {
        toast.error("Failed to process the request", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error processing payment request:", error);
      toast.error("An error occurred. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsSubmitting(false); // Stop the loader
      setIsModalOpen(false);
      setReason(""); // Clear reason field
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setReason("");
  };

  const columns = [
    { accessorKey: "id", header: "ID", size: 70 },
    { accessorKey: "modifyTitle", header: "Title" },
    { accessorKey: "fundraiserName", header: "Fundraiser Name" },
    { accessorKey: "totalMoneyRaised", header: "Total Money Raised" },
    { accessorKey: "targetAmount", header: "Target Amount" },
    { accessorKey: "remainingAmount", header: "Remaining Amount" },
    { accessorKey: "reimbusementAmountString", header: "Reimbursed Amount" },
    { accessorKey: "fundraiserName", header: "SubDomain Name" },
    {
      accessorKey: "status",
      header: "Status",

      Cell: ({ cell, row }) => {
        const status = cell.getValue();
        const reason = row.original.reason;
        const getStatusStyles = () => {
          if (status === "Pending") {
            return {
              backgroundColor: "rgba(255, 165, 0, 0.6)",
              color: "white",
              fontWeight: "bold",
            }; // Faint orange background for Pending
          } else if (status === "Complete") {
            return {
              backgroundColor: "rgba(0, 128, 0, 0.6)",
              color: "white",
              fontWeight: "bold",
            }; // Faint green background for Complete
          } else if (status === "Rejected") {
            return {
              backgroundColor: "rgba(255, 0, 0, 0.6)",
              color: "white",
              fontWeight: "bold",
            }; // Faint red background for Rejected
          } else if (status === "Cancelled") {
            return {
              backgroundColor: "rgba(128, 128, 128, 0.6)",
              color: "white",
              fontWeight: "bold",
            }; // Faint gray background for Cancelled
          }
        };

        // return (
        // <span
        //   style={{
        //     ...getStatusStyles(),
        //     padding: "5px 10px",
        //     borderRadius: "4px",
        //     display: "inline-block",
        //   }}
        // >
        //   {status}
        // </span>
        // <Tooltip
        //   label={reason || ""} // Show reason in tooltip if available
        //   styles={(theme) => ({
        //     tooltip: {
        //       backgroundColor: theme.colors.blue[9],
        //       color: theme.white,
        //     },
        //   })}
        // >
        //   <span
        //     style={{
        //       ...getStatusStyles(),
        //       padding: "5px 10px",
        //       borderRadius: "4px",
        //       display: "inline-block",
        //     }}
        //   >
        //     {status}
        //   </span>
        // </Tooltip>
        //);

        return reason ? (
          <Tooltip
            label={reason} // Show reason in tooltip
            styles={(theme) => ({
              tooltip: {
                backgroundColor: theme.colors.blue[9],
                color: theme.white,
              },
            })}
          >
            <span
              style={{
                ...getStatusStyles(),
                padding: "5px 10px",
                borderRadius: "4px",
                display: "inline-block",
              }}
            >
              {status}
            </span>
          </Tooltip>
        ) : (
          <span
            style={{
              ...getStatusStyles(),
              padding: "5px 10px",
              borderRadius: "4px",
              display: "inline-block",
            }}
          >
            {status}
          </span>
        );
      },
    },
  ];
  if (partnerId === 1) {
    columns.push({
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ row }) => {
        const status = row.original.status; // Get the current status of the row
        const isDisabled = status === "Complete"; // Disable actions if status is "Complete"

        const isDisabled1 = status === "Complete" || status === "Rejected"; // Disable actions if status is "Complete"

        return (
          <Box sx={{ display: "flex", gap: "8px" }}>
            <Tooltip label="Accept Request" position="top" withArrow>
              <ActionIcon
                color="green"
                onClick={() => handleAcceptRequest(row.original)}
                disabled={isDisabled1} // Disable button if status is "Complete"
              >
                <b>
                  <IoCheckmarkOutline size={30} />
                </b>
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Reject Request" position="top" withArrow>
              <ActionIcon
                color="red"
                onClick={() => handleRejectRequest(row.original)}
                disabled={isDisabled} // Disable button if status is "Complete"
              >
                <IoMdClose size={42} />
              </ActionIcon>
            </Tooltip>
          </Box>
        );
      },
    });
  } else {
    columns.push({
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ row }) => {
        const status = row.original.status; // Get the current status of the row
        const isDisabled =
          status === "Complete" ||
          status === "Rejected" ||
          status === "Cancelled"; // Disable actions if status is "Complete"
        const isDisabled1 =
          status === "Complete" ||
          status === "Pending" ||
          status === "Cancelled"; // Disable actions if status is "Complete"

        return (
          <Box sx={{ display: "flex", gap: "8px" }}>
            <Tooltip label="Cancel Request" position="top" withArrow>
              <ActionIcon
                color="red"
                onClick={() => handleCancelRequest(row.original)}
                disabled={isDisabled}
              >
                <IoMdClose size={30} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Re-Request" position="top" withArrow>
              <ActionIcon
                color="green"
                onClick={() => handleReRequest(row.original)}
                disabled={isDisabled1} // Disable button if status is "Complete"
              >
                <b>
                  <IoCheckmarkOutline size={30} />
                </b>
              </ActionIcon>
            </Tooltip>
          </Box>
        );
      },
    });
  }

  const table = useMantineReactTable({
    columns,
    data,
    initialState: { showColumnFilters: false },
    rowCount,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,
  });

  return (
    <div>
      <DonorManagementTop />
      <div className="flex flex-col md:flex-row">
        <main className="w-full verify-user">
          <div className="mx-auto shadow-md p-4 md:p-8">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="text-center md:text-left">
                <h2 className="text-2xl font-bold">
                  Reimbursement Request List
                </h2>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto px-4 md:px-8 py-8 container mx-auto">
            <MantineReactTable table={table} />
          </div>
        </main>
      </div>

      {/* <Modal
        opened={isModalOpen}
        onClose={handleCloseModal}
        centered
        size="md"
        overlayOpacity={0.7}
        transition="fade"
        transitionDuration={300}
        className="p-6"
        styles={{
          modal: { backgroundColor: "#fff", padding: "40px", borderRadius: "10px", maxWidth: "500px" },
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
      {isAcceptReq ? (
            <div className="flex flex-col items-center space-y-6">
              <h2 className="text-xl font-semibold text-gray-800">Are you sure you want to confirm this payment request?</h2>
              <div className="flex justify-center space-x-4">
                <Button onClick={() => handleConfirm(1)} className="bg-green-600 text-white font-semibold px-6 py-2 rounded-lg">
                  Yes
                </Button>
                <Button variant="outline" onClick={() => setIsModalOpen(false)} className="border-gray-300 text-gray-600 font-semibold px-6 py-2 rounded-lg">
                  No
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col space-y-4">
              <h2 className="text-lg font-semibold text-gray-800">Enter reason to reject this payment request</h2>
              <Textarea
                placeholder="Enter the reason here"
                value={reason}
                onChange={(event) => setReason(event.currentTarget.value)}
                className="w-full flex-1 p-4 rounded-lg border-gray-300"
              />
              <div className="flex justify-end space-x-4">
                <Button onClick={handleSubmit} className="bg-blue-600 text-white font-semibold px-4 py-2 rounded-lg">
                  Submit
                </Button>
                <Button variant="outline" onClick={handleCancel} className="border-gray-300 text-gray-600 font-semibold px-4 py-2 rounded-lg">
                  Cancel
                </Button>
              </div>
            </div>
          )}

      </Modal> */}
      <Modal
        opened={isModalOpen}
        onClose={handleCloseModal}
        centered
        size="md"
        overlayOpacity={0.8}
        transition="scale"
        transitionDuration={600}
        className="p-6"
        closeOnClickOutside={false} // Prevent modal from closing on outside click
        withCloseButton={false}
        styles={{
          modal: {
            background: "rgba(255, 255, 255, 0.9)", // Slight transparency for a glassmorphism effect
            backdropFilter: "blur(15px)", // Blurring the background
            padding: "40px",
            borderRadius: "20px", // Rounded corners for a modern look
            maxWidth: "550px",
            boxShadow: "0 30px 60px rgba(0, 0, 0, 0.2)", // Deeper shadow for a more elevated look
            transition: "all 0.6s ease", // Smooth transition for opening/closing
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Darker overlay for a stronger focus
          },
        }}
      >
        {/* {isAcceptReq ? (
          <div className="flex flex-col items-center space-y-8 p-4">
            <h2 className="text-2xl font-bold text-gray-900">
              Confirm Payment Request
            </h2>
            <p className="text-lg text-gray-700">
              Are you sure you want to confirm this payment request?
            </p>
            <div className="flex space-x-6">
              <Button
                variant="outline"
                onClick={() => setIsModalOpen(false)}
                className="border border-gray-300 text-gray-700 px-8 py-3  hover:bg-gray-100 "
              >
                No
              </Button>
              <Button
                onClick={() => handleConfirm(1)}
                className="primary-bg-color text-white font-bold px-8 py-3  shadow-lg "
              >
                Yes
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col space-y-6 p-3">
            <h2 className="text-xl font-bold text-gray-900">
              Enter Reason for Rejection
            </h2>
            <Textarea
              placeholder="Enter the reason here"
              value={reason}
              onChange={(event) => setReason(event.currentTarget.value)}
              className="w-full p-4 rounded-lg border-gray-300 shadow-md focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 ease-in-out"
            />
            <div className="flex justify-end space-x-6">
              <Button
                variant="outline"
                onClick={handleCancel}
                className="border border-gray-300 text-gray-700 px-8 py-3  "
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                className="primary-bg-color text-white font-bold px-8 py-3  shadow-lg "
              >
                Submit
              </Button>
            </div>
          </div>
        )} */}

        {/* {isCancelReq ? (
          <div className="flex flex-col items-center space-y-8 p-4">
            <h2 className="text-2xl font-bold text-gray-900">
              Confirm Cancel Request
            </h2>
            <p className="text-lg text-gray-700">
              Are you sure you want to cancel this request?
            </p>
            <div className="flex space-x-6">
              <Button
                variant="outline"
                onClick={() => setIsModalOpen(false)}
                className="border border-gray-300 text-gray-700 px-8 py-3 hover:bg-gray-100"
              >
                No
              </Button>
              <Button
                onClick={() => handleConfirm(3)}
                className="primary-bg-color text-white font-bold px-8 py-3 shadow-lg"
              >
                Yes
              </Button>
            </div>
          </div>
        ) : isAcceptReq ? (
          <div className="flex flex-col items-center space-y-8 p-4">
            <h2 className="text-2xl font-bold text-gray-900">
              Confirm Payment Request
            </h2>
            <p className="text-lg text-gray-700">
              Are you sure you want to confirm this payment request?
            </p>
            <div className="flex space-x-6">
              <Button
                variant="outline"
                onClick={() => setIsModalOpen(false)}
                className="border border-gray-300 text-gray-700 px-8 py-3 hover:bg-gray-100"
              >
                No
              </Button>
              <Button
                onClick={() => handleConfirm(1)}
                className="primary-bg-color text-white font-bold px-8 py-3 shadow-lg"
              >
                Yes
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col space-y-6 p-3">
            <h2 className="text-xl font-bold text-gray-900">
              Enter Reason for Rejection
            </h2>
            <Textarea
              placeholder="Enter the reason here"
              value={reason}
              onChange={(event) => setReason(event.currentTarget.value)}
              className="w-full p-4 rounded-lg border-gray-300 shadow-md focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 ease-in-out"
            />
            <div className="flex justify-end space-x-6">
              <Button
                variant="outline"
                onClick={handleCancel}
                className="border border-gray-300 text-gray-700 px-8 py-3"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                className="primary-bg-color text-white font-bold px-8 py-3 shadow-lg"
              >
                Submit
              </Button>
            </div>
          </div>
        )} */}

        {isCancelReq ? (
          <div className="flex flex-col items-center space-y-8 p-4">
            <h2 className="text-2xl font-bold text-gray-900">
              Confirm Cancel Request
            </h2>
            <p className="text-lg text-gray-700">
              Are you sure you want to cancel this request?
            </p>
            <div className="flex space-x-6">
              <Button
                variant="outline"
                onClick={() => setIsModalOpen(false)}
                className="border border-gray-300 text-gray-700 px-8 py-3 hover:bg-gray-100"
              >
                No
              </Button>
              <Button
                onClick={() => handleConfirm(3)}
                className="primary-bg-color text-white font-bold px-8 py-3 shadow-lg"
              >
                Yes
              </Button>
            </div>
          </div>
        ) : isReReq ? (
          <div className="flex flex-col items-center space-y-8 p-4">
            <h2 className="text-2xl font-bold text-gray-900">
              Confirm Re-Request
            </h2>
            <p className="text-lg text-gray-700">
              Are you sure you want to send the re-request for reimbursement?
            </p>
            <div className="flex space-x-6">
              <Button
                variant="outline"
                onClick={() => setIsModalOpen(false)}
                className="border border-gray-300 text-gray-700 px-8 py-3 hover:bg-gray-100"
              >
                No
              </Button>
              <Button
                onClick={() => handleConfirm(0)} // You can add a function for handling re-requests
                className="primary-bg-color text-white font-bold px-8 py-3 shadow-lg"
              >
                Yes
              </Button>
            </div>
          </div>
        ) : isAcceptReq ? (
          <div className="flex flex-col items-center space-y-8 p-4">
            <h2 className="text-2xl font-bold text-gray-900">
              Confirm Payment Request
            </h2>
            <p className="text-lg text-gray-700">
              Are you sure you want to confirm this payment request?
            </p>
            <div className="flex space-x-6">
              <Button
                variant="outline"
                onClick={() => setIsModalOpen(false)}
                className="border border-gray-300 text-gray-700 px-8 py-3 hover:bg-gray-100"
              >
                No
              </Button>
              <Button
                onClick={() => handleConfirm(1)}
                className="primary-bg-color text-white font-bold px-8 py-3 shadow-lg"
              >
                Yes
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col space-y-6 p-3">
            <h2 className="text-xl font-bold text-gray-900">
              Enter Reason for Rejection
            </h2>
            <Textarea
              placeholder="Enter the reason here"
              value={reason}
              onChange={(event) => setReason(event.currentTarget.value)}
              className="w-full p-4 rounded-lg border-gray-300 shadow-md focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 ease-in-out"
            />
            <div className="flex justify-end space-x-6">
              <Button
                variant="outline"
                onClick={handleCancel}
                className="border border-gray-300 text-gray-700 px-8 py-3"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                className="primary-bg-color text-white font-bold px-8 py-3 shadow-lg"
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        opened={isLinkModalOpen}
        onClose={() => setIsModalOpen(false)}
        centered
        title="Complete Account Setup"
      >
        <div className="flex flex-col items-center">
          <p>To complete your account setup, click the link below:</p>
          <a
            href={accountLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            Complete Account Setup
          </a>
        </div>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default PaymentRequest;

// function to get ReimbursedAmount dummy data for testing
function calculateReimbursedAmount(item) {
  const amount = sanitizeAmount(item.totalMoneyRaised) || 0;

  return formatCurrency(amount * 0.1); // Example: 10% of the amount
}

const sanitizeAmount = (amount) => {
  // Keep only digits and the period
  const cleanedAmount = amount.replace(/[^0-9.]/g, "");
  // Convert to number
  return parseFloat(cleanedAmount);
};

// Function to format a number as currency
const formatCurrency = (amount, locale = "en-US", currency = "USD") => {
  if (!amount) return "";
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  });
  return formatter.format(amount);
};
