import React, { useState, useEffect } from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from "yup";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { getRequestWithTokenWithoutData } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderNew from "../HomeNew/HeaderNew";

const schema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email("Invalid email").required("Email is required"),
  // title: yup.string().required("Title is required"),
  subject: yup.string().required("Subject is required"),
  description: yup.string().required("Description is required"),
});

const Support = () => {
  const [causeValue, setCauseValue] = useState("");
  const [errorCause, setErrorCause] = useState("");
  const [causeList, setCauseList] = useState([]);
  const [verifiedMsg, setVerifiedMsg] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getCauseList();
  }, []);

  const [captchaResponse, setCaptchaResponse] = useState(null);

  const handleCaptchaResponseChange = (response) => {
    setCaptchaResponse(response);
    setVerifiedMsg("");
  };

  const getCauseList = async () => {
    try {
      // const response = await getRequestWithTokenWithoutData(
      //   `${TQ_URL}/getCauseList`
      // );
      // console.log(response);
      // setCauseList(response);

      const response = await axios.get(`${TQ_URL_USPROJECT}/getAllIssueTypeList`);
      console.log(response.data);
      setCauseList(response.data);
    } catch (error) {
      console.error("Error in fetching the causeList:", error);
    }
  };

  const onSubmit = async (data, event) => {
    // Check if captchaResponse is empty
    if (!captchaResponse) {
      setVerifiedMsg("Please complete the reCAPTCHA verification.");
      // toast.error("Please complete the reCAPTCHA verification.");
      return;
    }

    setVerifiedMsg("");

    data.captchaResponse = captchaResponse;
    console.log("Form data:", data);

    if (!causeValue) {
      setErrorCause("Please select a CauseType");
      return;
    }

    event.preventDefault();
    const response = await fetch(`${TQ_URL_USPROJECT}/createNewTicket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: data.name,
        email: data.email,
        title: data.subject,
        subject: data.subject,
        causeTypeId: parseInt(causeValue),
        description: data.description,
        partnerId : 0,
        queryPageIndex :  "SUPPORT_PAGE",
        captchaResponse: captchaResponse, // Include captchaResponse in the request body
      }),
    });

    const res = await response.json();

    console.log("BODY >>", res);

    if (response.status === 200) {
      tikectCreated(res.message);
    } else {
      onErrors();
    }
  };

  const tikectCreated = (msg) => {
    toast.success(msg, {
      position: "top-right",
    });
    reset();
  };

  const onErrors = (error) => {
    console.log("page is hitting  ERRORORORORO", error);
  };

  const getCauseValue = (event) => {
    const selectedCauseValue = event.target.value;
    console.log("Selected cause value>>", selectedCauseValue);
    setCauseValue(selectedCauseValue);
    setErrorCause(selectedCauseValue ? "" : "Please select a Cause");
  };

  return (
    <>
      <HeaderNew />
      <div className="h-[400px] contact-us-banner   flex items-center justify-center ">
        <h2 className="md:text-4xl text-xl font-bold  text-white ">
          Customer Support Service
        </h2>
      </div>
      <div className="container mx-auto md:flex justify-center items-center my-10">
        {/* Left side: Image */}
        <div className="flex-shrink-0 md:w-1/2">
          <div className="md:p-8 mx-4 md:mx-0 flex flex-col  justify-start pb-20 ">
            <h2 className="txt-style-7  font-family-poppins text-left  text-xl">
              How can We help?
            </h2>
            <div className="flex space-y-6  flex-col ">
              {/* Icon Box 1 */}
              <div className="md:flex md:space-x-10 mt-6 md:mt-0">
                <div className="text-center icon-box">
                  <img
                    src="assets/images/email.png"
                    alt="Logo"
                    className="h-14 "
                  />
                  <p className="text-sm font-semibold">Mail</p>
                </div>
                <div className="flex flex-col justify-center">
                  <h1 className="text-xl font-semibold mt-2">
                    info@prabalta.org
                  </h1>
                  {/* <h1 className="text-sm font-normal">
                    Lorem ipsum is a dummy Text
                  </h1> */}
                </div>
              </div>

              {/* Icon Box 2 */}
              <div className="md:flex md:space-x-10 mt-6 md:mt-0">
                <div className="text-center icon-box">
                  <img
                    src="assets/images/call.png"
                    alt="Logo"
                    className="h-14 "
                  />
                  <p className="text-sm font-semibold">Phone</p>
                </div>
                <div className="flex flex-col justify-center">
                  <h1 className="text-xl font-semibold">+1-800-Prabalta</h1>
                </div>
              </div>

              {/* Icon Box 3 */}
              <div className="md:flex md:space-x-10 mt-6 md:mt-0">
                <div className="text-center icon-box">
                  <img
                    src="assets/images/location.png"
                    alt="Logo"
                    className="h-14 "
                  />
                  <p className="text-sm font-semibold">Location</p>
                </div>
                <div className="flex flex-col justify-center">
                  <h1 className="text-xl font-semibold">
                    Lorem ipsum is a dummy Text
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right side: Contact Form */}
        <div className="flex-grow md:ml-8 m-2">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-lg mx-auto border py-20 px-10 rounded-lg  bg-white"
          >
            <h2 className="text-3xl font-extrabold mb-8 ">Create New Ticket</h2>

            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-bold mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                {...register("name")}
                className={`border p-2 w-full shadow-md rounded-lg ${
                  errors.name && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.name?.message}
              </p>
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-bold mb-1">
                Email
              </label>
              <input
                type="text"
                id="email"
                {...register("email")}
                className={`border p-2 w-full shadow-md rounded-lg ${
                  errors.email && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.email?.message}
              </p>
            </div>

            {/* <div className="mb-4">
              <label htmlFor="title" className="block text-sm font-bold mb-1">
                Title
              </label>
              <input
                type="text"
                id="title"
                {...register("title")}
                className={`border p-2 w-full shadow-md rounded-lg ${
                  errors.title && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.title?.message}
              </p>
            </div> */}

            <div className="mb-4">
              <select
                onChange={getCauseValue}
                value={causeValue}
                className={`bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block mt-7 w-full p-2.5 ${
                  errorCause && "border-red-500"
                }`}
              >
                <option value="">Select IssueType</option>
                {causeList.map((cause) => (
                  <option key={cause.issueId} value={cause.issueId}>
                    {cause.issueName}
                  </option>
                ))}
              </select>

              {errorCause && <p style={{ color: "red" }}>{errorCause}</p>}
            </div>

            <div className="mb-4">
              <label htmlFor="subject" className="block text-sm font-bold mb-1">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                {...register("subject")}
                className={`border p-2 w-full shadow-md rounded-lg ${
                  errors.subject && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.subject?.message}
              </p>
            </div>

            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-bold mb-1"
              >
                Description
              </label>
              <textarea
                id="description"
                rows={3}
                style={{ resize: "none" }}
                {...register("description")}
                className={`border p-2 w-full shadow-md rounded-lg ${
                  errors.description && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.description?.message}
              </p>
            </div>
            <ReCAPTCHA
              sitekey="6LeaQ4QpAAAAACwPHgef7yz2B3tgaNqtNwsevXi9"
              onChange={handleCaptchaResponseChange}
            />
            <span className="text-red-500 text-sm mt-1">{verifiedMsg}</span>

            <div className="flex justify-center mt-10">
              <button
                type="submit"
                className="bg-blue-500  text-white mt-4 px-6 py-2 rounded btn-style-4"
              >
                Submit
              </button>
            </div>
          </form>

          <ToastContainer theme="colored" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Support;
