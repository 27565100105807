import React from "react";
import { Modal, Button } from "@mantine/core";

const SpamWarningModal = ({ spammyWords, onContinue, onChangeContent }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-md">
        <p className="mb-4">
          The content contains the following spammy words:{" "}
          <span className="font-bold">{spammyWords.join(", ")}</span>
        </p>
        <p className="mb-4">
          Are you sure you want to save the template with these words?
        </p>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
          onClick={onContinue}
        >
          Continue to Save Template
        </button>
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded"
          onClick={onChangeContent}
        >
          Change Content
        </button>
      </div>
    </div>
  );
};

export default SpamWarningModal;
