import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { IconEdit } from "@tabler/icons-react";
import { getRequestWithTokenWithoutDataPartner } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import MailCampaignSideBar from "./MailCampaignSideBar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Checkbox, CheckboxGroup } from "@mantine/core";

import { useNavigate, useLocation, useParams, Link } from "react-router-dom";

const schema = Yup.object().shape({
  mailingList: Yup.string().required("Mailing list is required"),
  campaignName: Yup.string().required("campaign name is required."),
  senderEmail: Yup.string()
    .email("Invalid email address")
    .required("Sender email is required"),
  senderName: Yup.string().required("Sender name is required"),
  subjectLine: Yup.string().required("Subject line is required"),
  deliveryOption: Yup.string().required("Please select a delivery option"),
});
var emailListFlag = 0;

function EditCampaign() {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [remainingCount, setRemainingCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMailingList, setSelectedMailingList] = useState(null);

  const { campaignId } = useParams();


  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control: controlSend,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const partnerId = partnerData.partnerId;
  const [mailingListData, setMailingListData] = useState([]);
  const [templateId, setTemplateId] = useState(null);
  const [campaingInfo, setCampaignInfo] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);

  const [formData, setFormData] = useState({
    mailingList: "",
    senderEmail: "",
    senderName: "",
    subjectLine: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("Remaining Count:", remainingCount);
    console.log("Selected Emails:", selectedEmails);
  }, [remainingCount, selectedEmails]);

  function toInitCap(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  useEffect(() => {
    if (mailingListData.length > 0) {
      getCampaignInfo();
    }
  }, [mailingListData]);

  const handleMailingListChange = (event) => {
    const selectedId = parseInt(event.target.value, 10);
    console.log("inside handle mailing list : ", selectedId);

    console.log(mailingListData);

    const selectedList = mailingListData.find(
      (list) => list.mailingListId === selectedId
    );

    console.log(selectedList);

    if (selectedList) {
      const mailingListCount = selectedList.emailCount; // Assuming this is how you get the count

      setSelectedMailingList(selectedList);
      console.log(mailingListCount);
      console.log(remainingCount);

      const allEmailIds = selectedList.emailList.map((email) =>
        email.mailListEmailId.toString()
      );

      setSelectedEmails(allEmailIds);

      if (mailingListCount > remainingCount) {
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }
    }
  };

  const handleSubmitForModal = () => {
    emailListFlag = 1;

    console.log("Selected Email IDs:", selectedEmails);
    localStorage.setItem("selectedEmails", JSON.stringify(selectedEmails));
    setIsModalOpen(false);
  };

  const getCampaignInfo = async () => {
    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        `${TQ_URL}/getCampaignInfo?campaignId=${campaignId}`
      );
      console.log(response.campaignData);
      const campaignData = response.campaignData;
      if (campaignData) {
        const matchingList = mailingListData.find(
          (list) => list.mailingListId === campaignData.mailingListId
        );
        console.log("Matching List:", matchingList); // Debugging
        setValue("mailingList", campaignData.mailingListId);
        setValue("campaignName", campaignData.campaignName);
        setValue("senderEmail", campaignData.senderEmail);
        setValue("senderName", campaignData.senderName);
        setValue("subjectLine", campaignData.subjectLine);
        setValue("deliveryOption", String(campaignData.statusId));
        setValue("scheduleDate", new Date(campaignData.scheduleAt));
        //setSelectedDate(campaignData.scheduleAt);
      }

      setCampaignInfo(response.campaignData);
    } catch (error) {
      console.error("Error in fetching campaign data:", error);
    }
  };

  const getMailingList = async () => {
    try {
      const response = await getRequestWithTokenWithoutDataPartner(
        `${TQ_URL}/getAllMailingListNames?partnerId=${partnerId}`
      );
      console.log(response);
      setMailingListData(response.mailingList);
      setRemainingCount(response.remainingCount);
    } catch (error) {
      console.error("Error in fetching all the mailing List:", error);
    }
  };

  useEffect(() => {
    getMailingList();
    getCampaignInfo();
  }, []);

  const handleEmailChange = (selected) => {
    setSelectedEmails(selected);
  };

  const handleBackFunction = () => {
    navigate("/campaignList");
  };

  const onSubmit = async (data) => {
    const deliveryOptionValue = watch("deliveryOption");

    console.log(data);
    var flag = 0;
    if (deliveryOptionValue) flag = 1;

    try {
      console.log("inside edit campaign");
      const response = await axios.post(`${TQ_URL}/editCampaignInfo`, {
        senderName: toInitCap(data.senderName),
        campaignId: campaignId,
        senderEmail: data.senderEmail,
        subjectLine: data.subjectLine,
        mailingListId: data.mailingList,
        campaignName: toInitCap(data.campaignName),
        sendType: data.deliveryOption,
        scheduleAt: data.scheduleDate,
        scheduleFlag: flag,
      });

      console.log("Campaign Information Updated successfully:", response);
      if (response.status == 200) {
        toast.success("Campaign Information Updated Successfully.");
        navigate("/campaignList");
      } else {
        toast.error("Failed to edit Campaign Information.");
      }
    } catch (error) {
      console.error("Error editing Campaign Information:", error);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed h-full md:overflow-y-auto">
          <MailCampaignSideBar />
        </div>

        <main className="w-full verify-user md:ml-[250px] md:pb-[100px] overflow-x-hidden">
          <div className=" mx-auto shadow-md">
            <div className="container flex justify-center items-center px-10 py-6 md:py-6"></div>
          </div>

          <div className="mt-8">
            <form
              className="fundraiser-for-other-form md:py-8 md:px-8 p-4 mx-auto w-full max-w-4xl"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="text-center mb-4">
                <h2 className="text-xl font-bold">Edit campaign Information</h2>
              </div>

              <div className="flex flex-row items-center justify-start mx-2 mb-4 fade-in-right">
                <label
                  htmlFor="mailing-list"
                  className="mr-4 text-sm font-semibold text-black-900"
                >
                  Mailing List
                </label>
                <div className="w-full">
                  <select
                    id="mailing-list"
                    {...register("mailingList", {
                      onChange: handleMailingListChange,
                    })}
                    className="bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                  >
                    <option value="">Select Mailing List</option>
                    {mailingListData.map((type, index) => (
                      <option key={index} value={type.mailingListId}>
                        {type.mailListName}
                      </option>
                    ))}
                  </select>
                  {errors.mailingList && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.mailingList.message}
                    </p>
                  )}
                </div>
                {isModalOpen && (
                  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="relative bg-white p-6 rounded-lg max-w-2xl w-full shadow-lg">
                      <button
                        onClick={() => setIsModalOpen(false)}
                        className="mt-2 p-2 cursor-pointer absolute top-2 right-2"
                      >
                        <svg
                          className="w-8"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                              fill="#0F0F0F"
                            ></path>{" "}
                          </g>
                        </svg>{" "}
                      </button>
                      <h2 className="text-xl font-semibold mb-4 text-center">
                        Warning
                      </h2>

                      <p
                        className="text-md font-semibold mb-4 text-center"
                        style={{
                          color: "orange",
                        }}
                      >
                        You have only {remainingCount} Email Send Remaining.
                        Please edit the list accordingly
                      </p>

                      <div className="flex justify-between space-x-4  m-8">
                        <div>
                          <strong>Remaining Email Count:</strong>{" "}
                          {remainingCount}
                        </div>
                        <div>
                          <strong>Selected Emails:</strong>{" "}
                          {selectedEmails.length}
                        </div>
                      </div>

                      <div>
                        <h2 className="mb-4 text-lg font-semibold"></h2>
                        <div className="max-h-64 overflow-y-auto">
                          {/* {remainingCount > 0 && (
                          <div className="flex justify-between mb-2">
                            <button
                              onClick={handleSelectAll}
                              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                            >
                              Select All
                            </button>
                            <button
                              onClick={handleDeselectAll}
                              className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
                            >
                              Deselect All
                            </button>
                          </div>
                        )} */}
                          {remainingCount > 0 ? (
                            <>
                              <Checkbox.Group
                                value={selectedEmails}
                                onChange={handleEmailChange}
                                className="flex flex-col space-y-2"
                              >
                                {selectedMailingList.emailList.map(
                                  (email, index) => (
                                    <Checkbox
                                      key={index}
                                      value={email.mailListEmailId.toString()} // Assuming email.id is unique
                                      label={email.email}
                                      className="flex items-center p-2 bg-gray-50 rounded-md hover:bg-gray-100"
                                    />
                                  )
                                )}
                              </Checkbox.Group>
                            </>
                          ) : (
                            <h1 className="text-red-600 font-semibold text-center">
                              You cannot send emails now. You have reached the
                              limit.
                            </h1>
                          )}
                        </div>

                        <button
                          className={`mt-4 px-4 py-2 rounded-lg justify-center ${
                            remainingCount >= selectedEmails.length
                              ? "bg-blue-500 text-white cursor-pointer"
                              : "bg-gray-400 text-gray-200 cursor-not-allowed"
                          }`}
                          onClick={handleSubmitForModal}
                          // onClick={() => setIsModalOpen(false)}
                          disabled={remainingCount < selectedEmails.length}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-row items-center justify-start mx-2 mb-4 fade-in-right">
                <label
                  htmlFor="campaignName"
                  className="mr-4 text-sm font-semibold text-black-900"
                >
                  Campaign Name
                </label>
                <div className="w-full">
                  <input
                    id="campaignName"
                    {...register("campaignName")}
                    type="text"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.campaignName && "border-red-500"
                    }`}
                    placeholder="Enter Campaign Name"
                  />
                  {errors.campaignName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.campaignName.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col md:flex-row items-start justify-start mx-2 mb-4 fade-in-right">
                <div className="w-full md:w-6/12 mr-4">
                  <label
                    htmlFor="sender-email"
                    className="block text-sm font-semibold text-black-900 mb-4"
                  >
                    Sender Email
                  </label>
                  <input
                    id="sender-email"
                    {...register("senderEmail")}
                    type="email"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.senderEmail && "border-red-500"
                    }`}
                    placeholder="Enter sender email"
                  />
                  {errors.senderEmail && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.senderEmail.message}
                    </p>
                  )}
                </div>
                <div className="w-full md:w-6/12 ml-4 mt-4 md:mt-0">
                  <label
                    htmlFor="sender-name"
                    className="block text-sm font-semibold text-black-900 mb-4"
                  >
                    Sender Name
                  </label>
                  <input
                    id="sender-name"
                    type="text"
                    {...register("senderName")}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.senderName && "border-red-500"
                    }`}
                    placeholder="Enter sender name"
                  />
                  {errors.senderName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.senderName.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col items-start justify-start mx-2 mb-4 fade-in-right">
                <label
                  htmlFor="subject-line"
                  className="block text-sm font-semibold text-black-900 mb-4"
                >
                  Subject Line
                </label>
                <textarea
                  id="subject-line"
                  {...register("subjectLine")}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.subjectLine && "border-red-500"
                  }`}
                  placeholder="Enter subject line"
                  rows="3"
                />
                {errors.subjectLine && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.subjectLine.message}
                  </p>
                )}
              </div>

              <div className="border border-gray-300 p-4 rounded-lg mb-4 flex flex-col items-start mx-2 mb-4 fade-in-right">
                <div className="flex items-center mb-4">
                  <input
                    type="radio"
                    value="1"
                    {...register("deliveryOption")}
                    className="form-radio text-blue-500 h-4 w-4"
                  />
                  <span className="ml-2">Send Now</span>
                </div>

                <div className="mt-2 flex items-center">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="2"
                      {...register("deliveryOption")}
                      className="form-radio text-blue-500 h-4 w-4"
                    />
                    <span className="ml-2">Schedule At:</span>
                  </label>
                  <Controller
                    control={controlSend}
                    name="scheduleDate"
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        selected={field.value || selectedDate}
                        onChange={(date) => {
                          setSelectedDate(date);
                          field.onChange(date);
                        }}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="ml-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2 outline-none"
                      />
                    )}
                  />
                </div>

                {errors.deliveryOption && (
                  <p className="text-red-500 text-sm mb-4">
                    {errors.deliveryOption.message}
                  </p>
                )}
              </div>

              {/* <div className="flex justify-center items-center h-screen">
                <div className="flex justify-center items-center space-x-4">
                  <Link to="/campaignList" className="flex w-1/3">
                    <button
                      type="button"
                      className="w-full text-white p-2 rounded-md btn-style-1"
                    >
                      Cancel
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="w-1/3 text-white p-2 rounded-md btn-style-1"
                  >
                    Edit
                  </button>
                </div>
              </div> */}
              <div className="mb-4 flex justify-center mt-4">
                <button
                  type="submit"
                  className="w-1/3 text-white p-2 rounded-md btn-style-1 mr-2"
                  onClick={handleBackFunction}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-1/3 text-white p-2 rounded-md btn-style-1 ml-2"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </main>
      </div>
      <ToastContainer theme="colored" />
    </>
  );
}

export default EditCampaign;
